import React from "react";
import { OpeningFeature, OpeningInput } from "@mapmaker/core";
import { FeatureComponent } from "../FeatureNode";
import OpeningGroup from "./OpeningGroup";
import OpeningImages from "./OpeningImages";
import SvgNode from "../SvgNode";
import { useMapmakerSvgContext } from "../MapmakerSvgContext";
import { useOpeningGroupContext } from "./OpeningGroupContext";

/*
 * Renders an opening as it will look when assembled physically.
 */
const Opening: FeatureComponent<OpeningFeature, OpeningInput> = ({
  feature: opening,
  input: openingInput,
}) => {
  // We don't show openings unless they have input, or are outlined.
  if (!openingInput && !opening.outlined) {
    return null;
  }
  // If an opening has been disabled by the user, do not show it.
  if (
    openingInput?.enabled === false ||
    (!openingInput && opening.enabled === false)
  ) {
    return null;
  }

  return (
    <OpeningGroup key={opening.id} opening={opening}>
      <OpeningWithGroupContext opening={opening} openingInput={openingInput} />
    </OpeningGroup>
  );
};

type OpeningWithGroupContextProps = {
  opening: OpeningFeature;
  openingInput?: OpeningInput;
};

function OpeningWithGroupContext({
  opening,
  openingInput,
}: OpeningWithGroupContextProps) {
  const { svgScale } = useMapmakerSvgContext();
  const { imageClipLayerWithoutBuffer, outermostVisibleLayer } =
    useOpeningGroupContext();
  return (
    <>
      <defs>
        <filter id={`drop-shadow-${opening.id}`}>
          <feDropShadow
            dx={0}
            dy={0}
            stdDeviation={1 / svgScale}
            floodColor="#000000"
            floodOpacity={0.5}
          />
        </filter>
      </defs>
      {/*
      Not sure why this was here. But it makes file previews show white where the images don't fully cover the opening.
      <SvgNode
        nodeData={outermostVisibleLayer}
        style={{ fill: "#FFFFFF", filter: `url(#drop-shadow-${opening.id})` }}
      />
      */}
      {opening.outlined && !openingInput?.images.length ? (
        /* Outlined nodes with no images should demarcate where the image will go. */
        <>
          <SvgNode
            nodeData={outermostVisibleLayer}
            style={{ fill: "#DDDDDD", stroke: "#AAAAAA", strokeWidth: "1" }}
          />
          <SvgNode
            nodeData={imageClipLayerWithoutBuffer}
            style={{ fill: "#FFFFFF", stroke: "#AAAAAA", strokeWidth: "1" }}
          />
        </>
      ) : null}
      {opening.outlined && openingInput?.images.length ? (
        /* Outlined nodes with images should get their extents drawn around them to match the style of other openings. */
        <SvgNode
          nodeData={outermostVisibleLayer}
          style={{ fill: "#FFFFFF", stroke: "#000000", strokeWidth: "1" }}
        />
      ) : null}
      {openingInput?.background ? (
        <SvgNode
          nodeData={imageClipLayerWithoutBuffer}
          style={{ fill: openingInput?.background }}
        />
      ) : null}
      <OpeningImages
        opening={opening}
        openingInput={openingInput}
        clipLayer={imageClipLayerWithoutBuffer}
      />
    </>
  );
}
export default Opening;
