import * as yup from "yup";
import { RenderableForm } from ".";

export const AllOpeningsSvgForm: RenderableForm = {
  initialValues: {
    width: 1024,
    height: 1024,
  },
  inputs: [
    {
      type: "file",
      id: "file",
      name: "Project",
      validation: yup.string().required(),
    },
    {
      type: "number",
      id: "width",
      name: "Width",
    },
    {
      type: "number",
      id: "height",
      name: "Height",
    },
    {
      type: "select",
      id: "layer",
      name: "Clip Layer",
      options: [
        {
          label: "Cut",
          value: "cut",
        },
        {
          label: "Print",
          value: "print",
        },
        {
          label: "Inner",
          value: "inner",
        },
        {
          label: "Outer",
          value: "outer",
        },
      ],
    },
  ],
};
