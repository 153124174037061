import {
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useRef,
  useState,
  Children,
  cloneElement,
  useMemo,
} from "react";
import clsx from "clsx";
import { TinyText } from "@mapmaker/ui";
import useClickAway from "react-use/lib/useClickAway";
import { Icon, SemanticICONS } from "semantic-ui-react";
import "./MapmakerToolbarButton.css";
import useKeyPress from "../../../../lib/hooks/useKeypress";

export type IMapmakerToolbarButtonProps = {
  hidden?: boolean;
  icon: SemanticICONS;
  badge?: ReactNode;
  label?: string;
  tooltip?: string;
  selected?: boolean;
  mode?: "modal" | "popover" | "action";
  disabled?: boolean;
  onClickWhileDisabled?(): any;
  action?(): any;
  children?: ReactElement<{ onClose?(): any }>;
};

const OPEN_WAITING_PERIOD = 100;

export default function({
  hidden = false,
  icon,
  label,
  tooltip,
  mode = "action",
  selected = false,
  disabled = false,
  onClickWhileDisabled,
  action,
  badge,
  children,
}: IMapmakerToolbarButtonProps) {
  const [open, setOpenDirect] = useState(false);
  const lastOpenTime = useRef<number>(0);

  const setOpen = useCallback(
    open => {
      const now = new Date().getTime();
      const timeSinceLastOpen = now - lastOpenTime.current;
      if (timeSinceLastOpen > OPEN_WAITING_PERIOD) {
        lastOpenTime.current = now;
        setOpenDirect(open);
      }
    },
    [setOpenDirect]
  );

  function onClick() {
    if (disabled) {
      onClickWhileDisabled && onClickWhileDisabled();
      return;
    }
    switch (mode) {
      case "action":
        action();
        break;
      case "modal":
        if (!open) {
          setOpen(true);
        }
        break;
      case "popover":
        if (!open) {
          setOpen(true);
        }
        break;
    }
  }

  const childrenWithCloseListener = useMemo(
    () =>
      Children.map(children, child => {
        switch (mode) {
          case "action":
            return child;
          case "modal":
            return cloneElement(child, {
              onClose: () => setOpen(false),
            });
          case "popover":
            return (
              <CloseOnClickAway
                className="popover-content"
                onClose={() => setOpen(false)}
              >
                {child}
              </CloseOnClickAway>
            );
        }
      }),
    [children, setOpen]
  );

  if (hidden) {
    return null;
  }

  return (
    <div
      className={clsx({
        "mapmaker-toolbar-button": true,
        [mode]: true,
        open,
        disabled,
        selected,
      })}
      onClick={onClick}
    >
      {badge && <div className={`badge`}>{badge}</div>}
      <Icon className="button-icon" name={icon} size="large" />
      {label && <TinyText className="label">{label}</TinyText>}
      {open
        ? childrenWithCloseListener
        : tooltip && <div className="tooltip">{tooltip}</div>}
    </div>
  );
}

type CloseOnClickAwayProps = {
  className: string;
  onClose(): any;
};

function CloseOnClickAway({
  onClose,
  className,
  children,
}: PropsWithChildren<CloseOnClickAwayProps>) {
  const ref = useRef<HTMLElement>();
  useClickAway(ref, e => {
    e.stopImmediatePropagation();
    onClose();
  });

  // If we're closing on a click-away, then we also want to close on escape.
  useKeyPress("Escape", e => {
    onClose();
  });

  return (
    <div ref={r => (ref.current = r)} className={className}>
      {children}
    </div>
  );
}
