import SettingSection from "./SettingSection";
import useProjectState, { useProjectFile } from "../../useProjectState";
import { useMapmakerAppConfig } from "../../../../client";
import { getOutputTypeDetails } from "../../shared/OutputTypeLibrary";
import { Icon } from "semantic-ui-react";
import "./OutputScaleSettings.css";

export default function OutputScaleSettings() {
  const {
    selectedProjectOutput,
    file: { outputType, outputScale },
  } = useProjectState();
  const outputTypeDetails = getOutputTypeDetails(outputType);
  const { gotoContact } = useMapmakerAppConfig();

  // For now we don't even show the projects scale unless the project has a custom scale (one not defined in the output options.)
  if (selectedProjectOutput) {
    return null;
  }

  return (
    <SettingSection
      id="output-scale-settings"
      label="Sizing"
      description="When you order stickers or print your photos they will be created at the size specified here."
    >
      <div className="alert">
        <Icon name="warning sign" size="large" />
        <div className="info">
          This project has a custom output scale of{" "}
          <strong>{Math.round(outputScale * 1000) / 10}%</strong> and an output
          type of <strong>{outputTypeDetails.name}</strong>. If this is not
          expected please{" "}
          <span className="link" onClick={() => gotoContact()}>
            contact us
          </span>
          .
        </div>
      </div>
    </SettingSection>
  );
}
