import { useEffect, useState } from "react";
import { useMapmakerAppConfig } from "../../client/MapmakerAppConfig";

export function useMapmakerTitle(title: string) {
  const { pageTitleFormat, businessName } = useMapmakerAppConfig();
  const [originalTitle] = useState(document.title);

  useEffect(() => {
    document.title = formatTitle(pageTitleFormat, title, businessName);
    return () => {
      document.title = originalTitle;
    };
  }, [title, pageTitleFormat, businessName]);
}

function formatTitle(template: string, value: string, fallback): string {
  if (!value.trim()) {
    return fallback;
  }
  return template.replace("{TITLE}", value);
}
