import { produce } from "immer";
import { OpeningImageInput, TimezoneIndependentDate } from "@mapmaker/core";

const TRANSFORM_IMAGE = "opening_image.transform_image";
const ROTATE_IMAGE_ABSOLUTE = "opening_image.rotate_image_absolute";
const SCALE_IMAGE_ABSOLUTE = "opening_image.scale_image_absolute";
const RESIZE_IMAGE = "opening_image.resize_image";
const FLIP_IMAGE = "opening_image.flip_image";
const SET_TIME_TAKEN_USER = "opening_image.set_time_taken_user";

export type OpeningImageTransform = {
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
};

export function flipImage(
  imageId: string,
  flipX: boolean,
  flipY: boolean = false
) {
  return {
    type: FLIP_IMAGE,
    __IMAGE_ID__: imageId,
    flipX,
    flipY,
  };
}

export function transformImage(
  imageId: string,
  transform: Partial<OpeningImageTransform>,
  rotationStep: number = 0
) {
  return {
    type: TRANSFORM_IMAGE,
    __IMAGE_ID__: imageId,
    transform,
    rotationStep,
  };
}

export function setTimeTakenUser(
  imageId: string,
  timeTakenUser: TimezoneIndependentDate
) {
  return {
    type: SET_TIME_TAKEN_USER,
    __IMAGE_ID__: imageId,
    timeTakenUser,
  };
}

export default produce(
  (draft: OpeningImageInput, action): OpeningImageInput => {
    if (draft === undefined) {
      return null;
    }

    switch (action.type) {
      case FLIP_IMAGE:
        draft.flipX = action.flipX;
        draft.flipY = action.flipY;
        break;
      case SCALE_IMAGE_ABSOLUTE:
        const scale = action.width / draft.width;
        const aspect = draft.width / draft.height;
        draft.x -= (draft.width * (scale - 1)) / 2;
        draft.y -= (draft.height * (scale - 1)) / 2;
        draft.width = action.width;
        draft.height = action.width / aspect;
        break;
      case RESIZE_IMAGE:
        const diffX = action.width - draft.width;
        const diffY = action.height - draft.height;
        //draft.x -= diffX / 2;
        //draft.y -= diffY / 2;
        draft.width = action.width + 1;
        draft.height = action.height + 1;
        break;
      case ROTATE_IMAGE_ABSOLUTE:
        draft.rotation = action.rotation % 360;
        break;
      case TRANSFORM_IMAGE:
        const transform: OpeningImageTransform = {
          x: draft.x,
          y: draft.y,
          rotation: draft.rotation,
          width: draft.width,
          height: draft.height,
          ...action.transform,
        };
        // Position
        draft.x = transform.x;
        draft.y = transform.y;
        // Size
        draft.width = transform.width;
        draft.height = transform.height;
        // Rotation includes wrap-around and step functionality
        draft.rotation = transform.rotation % 360;
        break;
      case SET_TIME_TAKEN_USER:
        draft.timeTakenUser = action.timeTakenUser;
        break;
      default:
        break;
    }
  }
);
