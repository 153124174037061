import { ReactNode, HTMLAttributes } from "react";
import "./CenteredPage.css";

export interface ICenteredPageProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "id"> {
  children: ReactNode;
}

export default function CenteredPage({
  children,
  ...props
}: ICenteredPageProps) {
  return (
    <div id="centered-page" {...props}>
      <span className="content">{children}</span>
    </div>
  );
}
