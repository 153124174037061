import { ReactNode, useCallback, useState } from "react";
import { Icon } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import "./MapmakerMessage.css";

export interface IMapmakerMessageProps {
  children: ReactNode;
  severity?: "info" | "warning" | "error";
  closeable?: boolean;
}

const SeverityIconMap: { [key: string]: SemanticICONS } = {
  info: "info circle",
  warning: "warning circle",
  error: "warning",
};

export default function MapmakerMessage({
  children,
  severity = "info",
  closeable = true,
}: IMapmakerMessageProps) {
  const [open, setOpen] = useState(true);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  if (!open) {
    return null;
  }

  return (
    <div id="mapmaker-message" className={severity}>
      <div className="icon">
        <Icon name={SeverityIconMap[severity]} size="large" />
      </div>
      <div className="content">{children}</div>
      {closeable ? (
        <div className="close">
          <span className="close-btn" onClick={onClose}>
            ✕
          </span>
        </div>
      ) : null}
    </div>
  );
}
