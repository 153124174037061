import { useCallback, useState } from "react";
import { Select } from "semantic-ui-react";
import { ProductPageOption } from "../..";
import {
  StorefrontProductOption,
  StorefrontProductPageProductFragment,
  StorefrontSelectedOption,
} from "../../../client/MapmakerApi";
import {
  areAllOutOfStock,
  findOption,
  getPossiblePriceRange,
  getPossibleVariants,
  isDefaultVariantOption,
  moneyRangeToString,
  moneyV2Equals,
  moneyV2Range,
} from "../../../lib";
import "./VariantSelectors.css";

export interface IVariantSelectorsProps {
  product: StorefrontProductPageProductFragment;
  defaultToFirstVariant?: boolean;
  excludedSelections?: string[];
  onSelectionsChange?: (selectedOptions: StorefrontSelectedOption[]) => any;
}

export default function VariantSelectors({
  product,
  defaultToFirstVariant = true,
  excludedSelections = [],
  onSelectionsChange = () => {},
}: IVariantSelectorsProps) {
  const initialSelections = defaultToFirstVariant
    ? product.variants.edges[0].node.selectedOptions
    : [];
  const [selectedOptions, setSelectedOptions] = useState<
    StorefrontSelectedOption[]
  >(initialSelections);
  function setOption(name: string, value: string) {
    const newSelectedOptions = selectedOptions.map(selectedOption => {
      if (selectedOption.name === name) {
        return {
          name,
          value,
        };
      } else {
        return selectedOption;
      }
    });
    setSelectedOptions(newSelectedOptions);
    onSelectionsChange(newSelectedOptions);
  }

  return (
    <>
      {product.options.map(option => {
        // There is only one option, no need to show a selector.
        if (isDefaultVariantOption(option.name, option.values[0])) {
          return null;
        }
        if (excludedSelections.includes(option.name)) {
          return null;
        }
        return (
          <VariantOptionSelector
            key={option.name}
            product={product}
            option={option}
            onChange={setOption}
            selectedOptions={selectedOptions}
            initialValue={findOption(selectedOptions, option.name)?.value}
          />
        );
      })}
    </>
  );
}

interface IVariantOptionSelectorProps {
  product: StorefrontProductPageProductFragment;
  option: StorefrontProductOption;
  initialValue?: string;
  selectedOptions: StorefrontSelectedOption[];
  onChange(name: string, value: string | undefined): any;
}

function VariantOptionSelector({
  product,
  option,
  initialValue,
  selectedOptions,
  onChange,
}: IVariantOptionSelectorProps) {
  const onSelectChanged = useCallback(
    (_, { value }) => {
      onChange(option.name, value);
    },
    [onChange, option.name]
  );
  const selectedOptionsWithoutThisOption = selectedOptions.filter(
    selectedOption => selectedOption.name !== option.name
  );
  const possiblePriceRange = getPossiblePriceRange(
    product,
    selectedOptionsWithoutThisOption
  );
  const selectionCanAlterPrice = !moneyV2Equals(
    possiblePriceRange.minVariantPrice,
    possiblePriceRange.maxVariantPrice
  );
  return (
    <ProductPageOption>
      <label htmlFor={option.name}>{option.name}</label>
      <Select
        fluid
        labeled
        defaultValue={initialValue}
        onChange={onSelectChanged}
        options={option.values.map(value => {
          const possibleVariants = getPossibleVariants(product, [
            ...selectedOptionsWithoutThisOption,
            { name: option.name, value },
          ]);
          const outOfStock = areAllOutOfStock(possibleVariants);
          const priceRange = moneyV2Range(possibleVariants.map(v => v.priceV2));

          return {
            text: (
              <>
                <span>{value}</span>
                {outOfStock && (
                  <span className="outOfStockLabel">SOLD OUT</span>
                )}
              </>
            ),
            value,
            disabled: outOfStock,
            label: selectionCanAlterPrice
              ? moneyRangeToString(
                  priceRange.minVariantPrice,
                  priceRange.maxVariantPrice
                )
              : null,
          };
        })}
      />
    </ProductPageOption>
  );
}
