import { useMemo } from "react";
import { IRectangularPrintState } from "./ReduxPrintStates";
import { useSelector } from "react-redux";
import { getFeatures } from "@mapmaker/core";
import {
  ProjectOutputType,
  RectangularPrintLayoutInput,
} from "../../../client/MapmakerApi";
import {
  packOpenings,
  fullDesignPrint,
} from "../../../lib/prints/openingPacker";
import {
  idToSize,
  getPaperSize,
  PaperSize,
  PaperSizeId,
  PrintSizeModeId,
} from "../../../lib/prints/printSizes";
import { getPrintFilename } from "../other/downloadPrintUtils";

type PrintSizeGroup = {
  paperSize: PaperSize;
  printLayouts: RectangularPrintLayoutInput[];
};

export default function useRectangularPrintsSelector() {
  const {
    design,
    file,
    printOptions,
    printQuantities,
    printSizeSettings,
  } = useSelector((state: IRectangularPrintState) => ({
    design: state.design,
    file: state.file,
    printOptions: state.printOptions,
    printQuantities: state.printQuantities,
    printService: state.printService,
    printSizeSettings: state.printSizeSettings,
  }));

  const singlePrintMode =
    file.outputType === ProjectOutputType.MatboardWallMapSingle;

  const hasSelectedOpenings: boolean = useMemo(
    () =>
      Object.values(printQuantities).reduce(
        (sum: number, qty: number) => sum + qty,
        0
      ) > 0,
    [printQuantities]
  );

  const openingIdsToPrint: string[] = useMemo(() => {
    return Object.entries(printQuantities)
      .filter(([, qty]) => qty > 0)
      .map(([openingId]) => openingId);
  }, [printQuantities]);

  const printLayouts: RectangularPrintLayoutInput[] = useMemo(() => {
    const openings = openingIdsToPrint.reduce((map, openingId) => {
      map[openingId] = design.features[openingId];
      return map;
    }, {});
    const printLayouts =
      singlePrintMode &&
      printSizeSettings.printSizeModeId === PrintSizeModeId.FULL_SIZE
        ? fullDesignPrint(openings, printOptions, design)
        : packOpenings(
            openings,
            printOptions,
            printSizeSettings.printSizeModeId,
            printSizeSettings.packingMode,
            file.outputScale
          );

    // Add the download filename to the layouts.
    return printLayouts.map(printLayout => ({
      ...printLayout,
      downloadFilename: getPrintFilename(printLayout, design),
    }));
  }, [
    design,
    singlePrintMode,
    printOptions,
    openingIdsToPrint,
    printSizeSettings,
  ]);

  // Groups of prints "{WxH=>[print1, print2, ...], ...}"
  const printSizeGroups: PrintSizeGroup[] = useMemo(() => {
    const groupedPrints: {
      [key: string]: RectangularPrintLayoutInput[];
    } = printLayouts.reduce((groups, printLayout) => {
      // Sort the group as w|h so we can parse it below.
      const paperSize = getPaperSize(printLayout.width, printLayout.height);
      if (!groups[paperSize.id]) {
        groups[paperSize.id] = [];
      }
      groups[paperSize.id].push(printLayout);
      return groups;
    }, {});
    return Object.entries(groupedPrints)
      .sort((a, b) => {
        const sizeA = idToSize(a[0] as PaperSizeId);
        const sizeB = idToSize(b[0] as PaperSizeId);
        const areaA = sizeA.width * sizeA.height;
        const areaB = sizeB.width * sizeB.height;
        if (areaA === areaB) return 0;
        return areaA < areaB ? -1 : 1;
      })
      .map(([paperSizeId, printLayouts]) => {
        return {
          paperSize: idToSize(paperSizeId as PaperSizeId),
          printLayouts,
        };
      });
  }, [printLayouts]);

  // List of opening IDs which cannot be printed due to size settings.
  const unprintableOpeningIds: string[] = useMemo(() => {
    const printableOpeningIds = printSizeGroups
      .map(group =>
        group.printLayouts.flatMap(print =>
          print.printOpenings.map(opening => opening.openingId)
        )
      )
      .flat();
    return openingIdsToPrint.filter(
      openingId => !printableOpeningIds.includes(openingId)
    );
  }, [printSizeGroups]);

  return {
    singlePrintMode,
    hasSelectedOpenings,
    printLayouts,
    printSizeGroups,
    unprintableOpeningIds,
  };
}
