import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import clsx from "clsx";
import LoadingSpinner from "../loaders/LoadingSpinner";
import styles from "./Button.module.scss";
export default function Button(_a) {
    var { children, size = "medium", color = "normal", outlined = false, group = "none", disabled = false, loading = false, text = false, fluid = false, alignContent = "center", onClickWhileDisabled = () => { } } = _a, props = __rest(_a, ["children", "size", "color", "outlined", "group", "disabled", "loading", "text", "fluid", "alignContent", "onClickWhileDisabled"]);
    const onClick = useMemo(() => (disabled || loading ? onClickWhileDisabled : props.onClick), [disabled, loading, props.onClick, onClickWhileDisabled]);
    return (_jsxs("button", Object.assign({}, props, { className: clsx(styles.button, props.className, {
            [styles[`color-${color}`]]: true,
            [styles[`size-${size}`]]: true,
            [styles[`group-${group}`]]: true,
            [styles[`align-content-${alignContent}`]]: true,
            [styles.outlined]: outlined,
            [styles.text]: text,
            [styles.disabled]: disabled,
            [styles.loading]: loading,
            [styles.fluid]: fluid,
        }), onClick: onClick }, { children: [_jsx(LoadingSpinner, { className: styles.loader, active: true, size: "tiny", color: outlined ? "var(--grey)" : "var(--white)" }), _jsx("div", Object.assign({ className: styles.children }, { children: children }))] })));
}
