import React, { ReactNode, useMemo } from "react";
import { BoundingBox, OpeningFeature, OpeningInput } from "@mapmaker/core";
import { getLayerByPriority, LayerPriority } from "./openingUtils";
import { OpeningGroupContextProvider } from "./OpeningGroupContext";

type Point = {
  x: number;
  y: number;
};

/**
 * Rotates a single point around a 0,0 origin.
 */
function rotatePoint({ x, y }: Point, rotation: number): Point {
  const angle = (rotation * Math.PI) / 180.0;
  return {
    x: Math.cos(angle) * x - Math.sin(angle) * y,
    y: Math.sin(angle) * x + Math.cos(angle) * y,
  };
}

/**
 * Gets the x,y amount a bbox needs to be offset by to maintain the same top-left point given a certain
 * rotation.
 */
function getOffset(bbox: BoundingBox, rotation: number): Point {
  const tl = rotatePoint({ x: 0, y: 0 }, rotation);
  const tr = rotatePoint({ x: bbox.width, y: 0 }, rotation);
  const br = rotatePoint({ x: bbox.width, y: bbox.height }, rotation);
  const bl = rotatePoint({ x: 0, y: bbox.height }, rotation);
  return {
    x: Math.min(tl.x, tr.x, br.x, bl.x),
    y: Math.min(tl.y, tr.y, br.y, bl.y),
  };
}

export type OpeningGroupProps = {
  children: ReactNode;
  opening: OpeningFeature;
  openingInput?: OpeningInput;
  layer?: LayerPriority;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  scale?: number;
  rotation?: number;
  centered?: boolean;
} & React.SVGProps<SVGGElement>;

export default function OpeningGroup(props: OpeningGroupProps) {
  if (props.centered) {
    return <OpeningGroupCentered {...props} />;
  } else {
    return <OpeningGroupTopLeft {...props} />;
  }
}

function OpeningGroupTopLeft({
  children,
  opening,
  openingInput,
  layer = ["cut", "print", "inner"],
  x,
  y,
  rotation = 0,
  scale = 1,
  ...props
}: OpeningGroupProps) {
  const layerNode = getLayerByPriority(opening, layer);
  if (!layerNode) {
    return null;
  }
  const svgMatrix = useMemo(() => {
    const bbox = layerNode.bbox;
    const bboxOffset = getOffset(bbox, rotation);
    x = x ?? layerNode.bbox.x;
    y = y ?? layerNode.bbox.y;

    let a = rotation * (Math.PI / 180);
    const sin = Math.sin;
    const cos = Math.cos;
    const rotationMatrix: Matrix = [
      [cos(a), -sin(a), -bbox.x * cos(a) + bbox.y * sin(a) + bbox.x],
      [sin(a), cos(a), -bbox.x * sin(a) - bbox.y * cos(a) + bbox.y],
      [0, 0, 1],
    ];
    const translationMatrix: Matrix = [
      [1, 0, x / scale - bbox.x - bboxOffset.x],
      [0, 1, y / scale - bbox.y - bboxOffset.y],
      [0, 0, 1],
    ];
    const scalingMatrix: Matrix = [
      [scale, 0, 0],
      [0, scale, 0],
      [0, 0, 1],
    ];
    let m = multiplyMatrices(
      multiplyMatrices(scalingMatrix, translationMatrix),
      rotationMatrix
    );
    return [m[0][0], m[1][0], m[0][1], m[1][1], m[0][2], m[1][2]];
  }, [layerNode, x, y, rotation, scale]);

  return (
    <OpeningGroupContextProvider
      opening={opening}
      openingInput={openingInput}
      scale={scale}
    >
      <g {...props} transform={`matrix(${svgMatrix.join(",")})`}>
        {children}
      </g>
    </OpeningGroupContextProvider>
  );

  /*
  return (
    <OpeningGroupContextProvider opening={opening} openingInput={openingInput}>
        <g
          {...props}
          transform={`translate(${gx} ${gy}) rotate(${rotation} ${bbox.x} ${bbox.y})`}
          >
          {children}
        </g>
    </OpeningGroupContextProvider>
  );
  */
}

/**
 * This one is much simpler and more logical. We should probably try to migrate to it eventually.
 */
function OpeningGroupCentered({
  children,
  opening,
  openingInput,
  layer = ["cut", "print", "inner"],
  x,
  y,
  rotation = 0,
  width,
  height,
}: OpeningGroupProps) {
  const layerNode = getLayerByPriority(opening, layer);
  if (!layerNode) {
    return null;
  }
  const bbox = layerNode.bbox;
  x = x ?? bbox.x;
  y = y ?? bbox.y;
  width = width ?? bbox.width;
  height = height ?? bbox.height;
  const scale = Math.min(width / bbox.width, height / bbox.height);
  const cx = bbox.x + bbox.width / 2;
  const cy = bbox.y + bbox.height / 2;

  return (
    <OpeningGroupContextProvider
      opening={opening}
      openingInput={openingInput}
      scale={scale}
    >
      <g
        transform={`
        translate(${x - cx} ${y - cy})
        rotate(${rotation}, ${cx}, ${cy})
        matrix(${scale}, 0, 0, ${scale}, ${cx - scale * cx}, ${cy -
          scale * cy})`}
      >
        {children}
      </g>
    </OpeningGroupContextProvider>
  );
}

type Matrix = [
  [number, number, number],
  [number, number, number],
  [number, number, number]
];
function multiplyMatrices(matrixA: Matrix, matrixB: Matrix): Matrix {
  let aNumRows = matrixA.length;
  let aNumCols = matrixA[0].length;
  let bNumCols = matrixB[0].length;
  let newMatrix = new Array(aNumRows);

  for (let r = 0; r < aNumRows; ++r) {
    newMatrix[r] = new Array(bNumCols);

    for (let c = 0; c < bNumCols; ++c) {
      newMatrix[r][c] = 0;

      for (let i = 0; i < aNumCols; ++i) {
        newMatrix[r][c] += matrixA[r][i] * matrixB[i][c];
      }
    }
  }

  return newMatrix as Matrix;
}
