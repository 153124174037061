import { Field, useFormikContext } from "formik";
import { RenderableInputProps } from ".";
import { useEffect, useState } from "react";
import { useValidateRenderableInput } from "./renderableInputUtils";
import {
  RectangularPrintLayoutInput,
  RectangularPrintOptions,
  useGetFileWithDesignQuery,
} from "../../../client/MapmakerApi";
import { PackingMode, packOpenings } from "../../../lib/prints/openingPacker";
import {
  PrintSizeModeId,
  PrintSizeModes,
} from "../../../lib/prints/printSizes";
import {
  FeatureMap,
  getFeatures,
  getOpeningFeature,
  OpeningFeature,
} from "@mapmaker/core";

export type RenderableRectangularPrintLayoutInputProps = RenderableInputProps<
  "rectangular-print-layout",
  RectangularPrintLayoutInput
>;

type PresetSize = "4x6" | "8.5x11" | "24x36";

/**
 * NOTE - This is just a stub. In order to use the renders with Print Layouts we'll need to improve it.
 * A specialized tool to make a rectangular print layout.
 */
export default function RenderableRectangularPrintLayoutInput({
  name,
  id,
  description,
  validation,
}: RenderableRectangularPrintLayoutInputProps) {
  const { setFieldValue, values } = useFormikContext();
  const validate = useValidateRenderableInput(id, validation);
  const [sizeMode, setSizeMode] = useState<PrintSizeModeId>(
    PrintSizeModeId.LETTER
  );

  const fileId = values && values["file"]?.FILE;
  const { loading, data: { file } = {} } = useGetFileWithDesignQuery({
    variables: {
      id: fileId,
    },
    skip: !fileId,
  });

  useEffect(() => {
    if (!file || !values) return;

    const printOptions = values["printOptions"] as RectangularPrintOptions;
    const openingIds =
      values["openingId"] && !Array.isArray(values["openingId"])
        ? [values["openingId"]]
        : (values["openingId"] as string[]);

    if (!openingIds || openingIds.length === 0) {
      return;
    }

    const featureMap = openingIds.reduce<FeatureMap<OpeningFeature>>(
      (acc, openingId) => {
        acc[openingId] = getOpeningFeature(file.design, openingId);
        return acc;
      },
      {}
    );

    const layouts = packOpenings(
      featureMap,
      printOptions,
      sizeMode,
      PackingMode.MULTI,
      1
    );

    setFieldValue(id, layouts[0]);
  }, [sizeMode, file, values["openingId"], values["printOptions"]]);

  return (
    <Field type="string" name={id} validate={validate}>
      {({ field, meta }) => (
        <div
          id="renderable-input"
          className={meta.touched && meta.error ? "error" : undefined}
        >
          <div className="above">
            <label>{name}</label>
          </div>
          <select
            onChange={(e) =>
              setSizeMode(e.currentTarget.value as PrintSizeModeId)
            }
            defaultValue={PrintSizeModeId.LETTER}
          >
            {Object.values(PrintSizeModes).map((mode) => (
              <option key={mode.id} value={mode.id}>
                {mode.name}
              </option>
            ))}
          </select>
          {meta.touched && meta.error && meta.value && (
            <div className="error">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
}
