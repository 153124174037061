import { PropsWithChildren } from "react";
import "./SettingSection.css";

type SettingSectionProps = PropsWithChildren<{
  id?: string;
  label: string;
  hideLabel?: boolean;
  description?: string;
}>;

export default function SettingSection({
  id,
  label,
  hideLabel = false,
  description,
  children,
}: SettingSectionProps) {
  return (
    <div className="map-setting-section" id={id}>
      {!hideLabel && <h3 className="section-label">{label}</h3>}
      <p className="description">{description}</p>
      <div className="content">{children}</div>
    </div>
  );
}
