import { Button, LinkButton } from "@mapmaker/ui";
import OrderLineItem from "./OrderLineItem";
import { gql } from "@apollo/client";
import { OrderItemDetailsFragment } from "../../client/MapmakerApi";
import { useMapmakerAppConfig } from "../../client";

gql`
  fragment MapLineItem on OrderItem {
    ... on MapLineItem {
      blueprintId
    }
  }
`;

type MapLineItemProps = {
  item: Extract<OrderItemDetailsFragment, { __typename?: "MapLineItem" }>;
};

export default function MapLineItem({ item }: MapLineItemProps) {
  const { gotoFindMap } = useMapmakerAppConfig();
  return (
    <OrderLineItem {...item}>
      {/* Would be sweet to create a new file base on the line items design ID and output type */}
      <Button
        onClick={gotoFindMap}
        outlined
        size="small"
        style={{ alignSelf: "end" }}
      >
        Create stickers for this Map
      </Button>
    </OrderLineItem>
  );
}
