import { MaxRectsPacker, IRectangle, Bin } from "maxrects-packer";
import { FeatureMap, OpeningFeature } from "@mapmaker/core";
import {
  PrintSizeModeId,
  getBestPrintSize,
  PrintSizeMode,
  idToMode,
} from "./printSizes";
import {
  RectangularPrintOptions,
  RectangularPrintOpening,
  RectangularPrintLayoutInput,
  MapmakerDesignHeavyFragment,
} from "../../client/MapmakerApi";

export enum PackingMode {
  SINGLE = "single",
  MULTI = "multi",
}

type OpeningMap = FeatureMap<OpeningFeature>;

function mapOpenings<T>(
  openings: OpeningMap,
  fn: (openingId: string, opening: OpeningFeature) => T
): T[] {
  return Object.entries(openings).map(([openingId, opening]) =>
    fn(openingId, opening)
  );
}

// Puts all openings in a single print, in their actual locations, at the size of the design.
export function fullDesignPrint(
  openings: OpeningMap,
  printOptions: RectangularPrintOptions,
  design: MapmakerDesignHeavyFragment
): RectangularPrintLayoutInput[] {
  const printOpenings = mapOpenings<RectangularPrintOpening>(
    openings,
    (openingId, opening) => {
      const bbox = printOptions.includeBuffer
        ? opening.layout.print?.bbox ||
          opening.layout.outer?.bbox ||
          opening.layout.cut.bbox
        : opening.layout.cut?.bbox || opening.layout.inner.bbox;

      return {
        openingId,
        x: bbox.x,
        y: bbox.y,
        rotation: 0,
      };
    }
  );

  return [
    {
      width: design.width,
      height: design.height,
      printOpenings,
    },
  ];
}

// Return the prints all packed up nicely
export function packOpenings(
  openings: OpeningMap,
  printOptions: RectangularPrintOptions,
  printSizeModeId: PrintSizeModeId,
  packingMode: PackingMode,
  scale: number
): RectangularPrintLayoutInput[] {
  const printSizeMode = idToMode(printSizeModeId);
  return packingMode === PackingMode.SINGLE
    ? getSinglePrints(openings, printOptions, printSizeMode, scale)
    : getPackedPrints(openings, printSizeMode, scale);
}

// Single centered opening
function getSinglePrints(
  openings: OpeningMap,
  printOptions: RectangularPrintOptions,
  printSizeMode: PrintSizeMode,
  scale: number
): RectangularPrintLayoutInput[] {
  return mapOpenings<RectangularPrintLayoutInput>(
    openings,
    (openingId, opening) => {
      const bbox = printOptions.includeBuffer
        ? opening.layout.print?.bbox || opening.layout.outer.bbox
        : opening.layout.cut?.bbox || opening.layout.inner.bbox;
      const normalPrintSize = getBestPrintSize(
        bbox.width * scale,
        bbox.height * scale,
        printSizeMode.id
      );
      const rotatedPrintSize = getBestPrintSize(
        bbox.height * scale,
        bbox.width * scale,
        printSizeMode.id
      );

      let rotation = 0;
      let printSize;
      if (normalPrintSize) {
        printSize = normalPrintSize;
      } else if (rotatedPrintSize) {
        printSize = rotatedPrintSize;
        rotation = 90;
      } else {
        // No size can accommodate this print.
        return null;
      }

      const centerX = (printSize.width * scale - bbox.width * scale) / 2;
      const centerY = (printSize.height * scale - bbox.height * scale) / 2;

      return {
        printOpenings: [
          {
            openingId,
            x: centerX,
            y: centerY,
            rotation: rotation,
          },
        ],
        width: printSize.width * scale,
        height: printSize.height * scale,
        scale,
      };
    }
  ).filter((print) => !!print);
}

// Multiple bin-packed openings

/*
We use a pretty naive strategy for bin-packing here. This is the basic algorithm:
    1. Pack everything at the smallest bin size using landscape orientation.
    2. Pack everything at the smallest bin size portrait using portrait orientation.
    3. Choose whichever of the first two options fits the most openings
        3b. If they fit the same number of openings choose the one with the fewest prints.
        3c. If they are still the same use landscape because it looks nicer.
    4. Remove all packed openings from the list and repeat from step 1 with the next size.
*/
function getPackedPrints(
  openings: OpeningMap,
  printSizeMode: PrintSizeMode,
  scale: number
): RectangularPrintLayoutInput[] {
  // Map the correct size library to the SVG point size (72 DPI).
  const sizes: [number, number, string][] = printSizeMode.sizes.map((size) => [
    size.width,
    size.height,
    "portrait",
  ]);

  const unusedOpenings = { ...openings };
  const printLayouts = [];

  sizes.forEach((size) => {
    // Pack the prints at this size
    const printsAtSize = getPackedPrintsAtSize(
      unusedOpenings,
      size[0],
      size[1],
      scale
    );

    // Add the prints we just made to the layouts
    printLayouts.push(...printsAtSize);
    // Remove those prints from the unused openings list
    printsAtSize.forEach((printLayout) => {
      printLayout.printOpenings.forEach((printOpening) => {
        delete unusedOpenings[printOpening.openingId];
      });
    });
  });
  return printLayouts;
}

type OpeningRectangle = IRectangle & {
  openingId: string;
  manuallyRotated?: boolean;
  customRotation?: number;
  customOffsetX?: number;
  customOffsetY?: number;
};

function getPackedPrintsAtSize(
  openings: OpeningMap,
  printWidth: number,
  printHeight: number,
  scale: number
): RectangularPrintLayoutInput[] {
  const OUTER_PADDING = (1 / 4) * 72; // Home printers usually have 1/4" forced margins
  const INNER_PADDING = (1 / 32) * 72;

  const packableOpenings: OpeningRectangle[] = Object.entries(
    openings
  ).map<OpeningRectangle>(([openingId, opening]) => {
    // Always use the larger print layer for sizing because otherwise people might want to pack them
    // into fewer prints not understanding what the buffer is for.
    const bbox =
      opening.layout.print?.bbox ||
      opening.layout.outer?.bbox ||
      opening.layout.cut?.bbox;

    const fitsNormal =
      bbox.width <= printWidth * scale - 2 * OUTER_PADDING &&
      bbox.height <= printHeight * scale - 2 * OUTER_PADDING;
    const fitsRotated =
      bbox.width <= printHeight * scale - 2 * OUTER_PADDING &&
      bbox.height <= printWidth * scale - 2 * OUTER_PADDING;
    const rotate = !fitsNormal && fitsRotated;

    const texasWorkaround = getTexasWorkaround(
      opening,
      printWidth * scale,
      printHeight * scale
    );
    if (texasWorkaround) {
      return texasWorkaround;
    }

    return {
      openingId,
      width: rotate ? bbox.height * scale : bbox.width * scale,
      height: rotate ? bbox.width * scale : bbox.height * scale,
      scale,
      x: 0,
      y: 0,
      // Max-rects packer will immediately reject a rect that doesn't fit the bin before even trying
      // to rotate it, so this flag lets us note if we rotated the rect prior to packing.
      manuallyRotated: rotate,
    };
  });

  // pot:false needed so it doesn't scale the bin to a power of 2.
  const packer = new MaxRectsPacker<OpeningRectangle>(
    printWidth,
    printHeight,
    INNER_PADDING,
    {
      pot: false,
      smart: true,
      allowRotation: true,
      border: OUTER_PADDING,
    }
  );
  packer.addArray(packableOpenings);

  const printLayouts: RectangularPrintLayoutInput[] = packer.bins
    .map((bin) => {
      if (bin.rects.length === 0 || bin.rects[0]?.oversized) {
        return null;
      }
      // The max-rects library doesn't calculate bin sizes correctly, so we do it here.
      const contentSize = getTrueBinSize(bin);
      const offsetX = (printWidth - contentSize.width) / 2;
      const offsetY = (printHeight - contentSize.height) / 2;
      const printOpenings = bin.rects.map((rect) => {
        return {
          openingId: rect.openingId,
          rotation:
            rect.customRotation || (rect.rot || rect.manuallyRotated ? 90 : 0),
          x: rect.x + offsetX + (rect.customOffsetX || 0),
          y: rect.y + offsetY + (rect.customOffsetY || 0),
          scale: rect.scale,
        };
      });
      return {
        printOpenings,
        width: printWidth,
        height: printHeight,
      };
    })
    .filter((x) => x !== null);

  return printLayouts;
}

function getTrueBinSize<T extends OpeningRectangle>(bin: Bin<T>) {
  let width = 0;
  let height = 0;
  bin.rects.forEach((rect) => {
    width = Math.max(rect.x + (rect.rot ? rect.height : rect.width), width);
    height = Math.max(rect.y + (rect.rot ? rect.width : rect.height), height);
  });
  return {
    width: width + bin.options.border,
    height: height + bin.options.border,
  };
}

/**
 * Texas is special.  It fits but only when rotated about 55 degrees. Since it is common and
 * the only opening of the USA map over 8x10 we treat it special.
 */
function getTexasWorkaround(
  opening: OpeningFeature,
  printWidth: number,
  printHeight: number
): OpeningRectangle {
  // These criteria should catch only the scenario where we're printing Texas for the USA map at
  // 8x10
  const layer = opening.layout.cut || opening.layout.inner;
  if (
    layer &&
    opening.id == "Texas" &&
    ((layer.bbox.width > 597 && layer.bbox.width < 598) ||
      (layer.bbox.width > 583 && layer.bbox.width < 584)) &&
    printWidth === 8 * 72 &&
    printHeight === 10 * 72
  ) {
    return {
      openingId: opening.id,
      customRotation: 55,
      customOffsetX: -212.7,
      customOffsetY: -125.45,
      width: 6.41 * 72,
      height: 8.06 * 72,
      x: 0,
      y: 0,
    };
  }
}
