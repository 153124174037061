import { IconBaseProps } from "@react-icons/all-files";
import { useMapmakerAppConfig } from "../../..";

/**
 * This icon class is made to be compatible with the React-Icons IconType. It also switched between PM and TBL icons based on the context.
 */
export function StickerTokenIcon(props: IconBaseProps) {
  const { businessId } = useMapmakerAppConfig();
  switch (businessId) {
    case "pm":
      return <PhotomapsStickerTokenIcon {...props} />;
    case "tbl":
      return <TblStickerTokenIcon {...props} />;
  }
}

export function StickerTokenIconGrayscale(props: IconBaseProps) {
  /* A carefully tweaked filter to make this look good in grayscale. */
  return (
    <StickerTokenIcon
      style={{ filter: "grayscale(1) brightness(0.68) contrast(3)" }}
      {...props}
    />
  );
}

function PhotomapsStickerTokenIcon({
  size = "1em",
  children,
  color,
  title,
  ...svgProps
}: IconBaseProps) {
  const primaryColor = "#00BAF2";
  const primaryColorDark = "#0092AA";
  const secondaryColor = "#C4E8EF";
  const secondaryColorDark = "#A5A4A4";

  return (
    <svg
      height={size}
      width={size}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 71.56 64.828"
      {...svgProps}
    >
      <ellipse
        style={{ fill: primaryColorDark }}
        cx="35.78"
        cy="34.914"
        rx="35.78"
        ry="29.914"
      />
      <path
        style={{ fill: secondaryColorDark }}
        d="M29.767,64.399c1.956,0.277,3.963,0.429,6.013,0.429c1.154,0,2.293-0.05,3.419-0.139v-5.536h-9.432
  V64.399z"
      />
      <ellipse
        style={{ fill: primaryColor }}
        cx="35.78"
        cy="29.914"
        rx="35.78"
        ry="29.914"
      />
      <path
        style={{ fill: secondaryColor }}
        d="M36.386,19.745c-6.896,0-12.486,4.674-12.486,10.439c0,5.765,5.59,10.439,12.486,10.439
  c6.896,0,12.486-4.674,12.486-10.439C48.872,24.419,43.282,19.745,36.386,19.745z M36.426,37.257c-4.534,0-8.821-3.678-8.821-7.567
  c0-0.89,0.722-1.614,1.614-1.614c0.891,0,1.614,0.723,1.614,1.614c0,2.11,2.875,4.339,5.594,4.339c0.891,0,1.614,0.723,1.614,1.614
  C38.04,36.534,37.318,37.257,36.426,37.257z"
      />
      <path
        style={{ fill: secondaryColor }}
        d="M36.386,9.231c-13.841,0-25.061,9.381-25.061,20.952c0,4.06,1.404,7.836,3.794,11.046l-0.077,0.05
  l-0.01,0.004l14.585,18.092c2.003,0.291,4.06,0.451,6.163,0.451c1.152,0,2.289-0.05,3.413-0.139l-7.734-8.963
  c1.594,0.266,3.24,0.41,4.927,0.41c13.841,0,25.061-9.381,25.061-20.952S50.227,9.231,36.386,9.231z M36.386,45.026
  c-10.449,0-18.951-6.658-18.951-14.842c0-8.184,8.501-14.842,18.951-14.842c10.45,0,18.951,6.658,18.951,14.842
  C55.337,38.368,46.835,45.026,36.386,45.026z"
      />
    </svg>
  );
}

function TblStickerTokenIcon({
  size = "1em",
  children,
  color,
  title,
  ...svgProps
}: IconBaseProps) {
  const primaryColor = "#87CD21";
  const primaryColorDark = "#69961B";
  const secondaryColor = "#FFFFFF";
  const secondaryColorDark = "#DCE2C8";

  return (
    <svg
      height={size}
      width={size}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 95.4 95.4"
      {...svgProps}
    >
      <ellipse
        style={{ fill: primaryColorDark }}
        cx="47.7"
        cy="51"
        rx="47.7"
        ry="39.9"
      />
      <path
        opacity="0.7"
        style={{ fill: secondaryColorDark }}
        d="M5.7,63.3v6.7c1,1.6,2.2,3.1,3.5,4.6v-6.7C7.9,66.4,6.7,64.9,5.7,63.3z"
      />
      <ellipse
        style={{ fill: primaryColor }}
        cx="47.7"
        cy="44.4"
        rx="47.7"
        ry="39.9"
      />
      <path
        style={{ fill: secondaryColor }}
        d="M91.2,28c-0.8-1.6-1.8-3.1-2.9-4.5c-7.4,3.9-20.2,14.3-28.6,15.7c-1,0.1-2.2-0.2-3.1-0.9c0,0,0,0,0,0
	c-4.2-3.2,0.5-12.9-4.8-17.2c-2.4-2-16.7,9.8-30.1,24c-5.9,6.1-11.6,12.7-16,18.2c1,1.6,2.2,3.2,3.5,4.7c8-7.3,18.1-16.7,24.9-18.5
	c1.1-0.3,2.3-0.1,3.2,0.5c0,0,0,0,0,0c4.3,2.8-0.5,12.6,4.9,16.7c2.4,1.8,16.5-9.1,29-19.7C79.6,39.7,87.2,32.7,91.2,28z"
      />
    </svg>
  );
}
