import { useState } from "react";
import { Input, Header } from "semantic-ui-react";
import isPostalCode from "validator/es/lib/isPostalCode";
import WalgreensCheckoutStepContainer from "./WalgreensCheckoutStepContainer";
import useWalgreensCheckout from "./useWalgreensCheckout";
import WalgreensStoreListItem from "./WalgreensStoreListItem";
import { useDispatch } from "react-redux";
import { setSelectedStore } from "./walgreensCheckoutReducer";
import LoadingPage from "../../shared/LoadingPage";
import "./WalgreensSelectStore.css";
import { gql } from "@apollo/client";
import { useWalgreensStoresLazyQuery } from "../../../client/MapmakerApi";

gql`
  fragment WalgreensStore on WalgreensStore {
    storeNum
    promiseTime
    zip
    city
    state
    street
    phone
    distance
    distanceUnit
    openTime
    closeTime
    timeZone
    tax
  }

  query walgreensStores($input: WalgreensStoresInput!) {
    walgreensStores(input: $input) {
      ...WalgreensStore
    }
  }
`;

export default function WalgreensSelectStore() {
  const dispatch = useDispatch();
  const [getStores, { loading, error, data }] = useWalgreensStoresLazyQuery();
  const [zip, setZip] = useState();
  const { productDetailsInput } = useWalgreensCheckout();
  const stores = data?.walgreensStores;

  function zipChange(e) {
    const zip = e.target.value;
    if (isPostalCode(zip, "US")) {
      setZip(zip);
      getStores({
        variables: {
          input: {
            zip,
            productDetails: productDetailsInput,
          },
        },
      });
    }
  }

  function selectStore(store) {
    dispatch(setSelectedStore(store, zip));
  }

  function renderStores() {
    return (
      <>
        <Header sub>
          {stores.length} {stores.length == 1 ? "store" : "stores"} nearby
          stores
        </Header>
        <div className="store-list">
          {stores.map(store => {
            return (
              <WalgreensStoreListItem
                key={store.storeNum}
                store={store}
                onSelect={selectStore}
              />
            );
          })}
        </div>
      </>
    );
  }

  function renderError() {
    return <p>{error.message}</p>;
  }

  function renderWaitingOnInput() {
    return <p className="note">Enter your ZIP code to search for stores.</p>;
  }

  function renderResults() {
    if (error) {
      return renderError();
    } else if (loading) {
      return (
        <LoadingPage
          style={{ height: "300px" }}
          message="Finding nearby stores"
        />
      );
    } else if (stores) {
      return renderStores();
    } else {
      return renderWaitingOnInput();
    }
  }

  return (
    <WalgreensCheckoutStepContainer header="Find a Walgreens near you">
      <div id="walgreens-select-store">
        <Input
          name="zip"
          fluid
          error={!!error}
          placeholder="ZIP Code"
          defaultValue={""}
          icon={{
            name: "search",
            circular: true,
            link: true,
          }}
          onChange={zipChange}
        />
        <div className="result">{renderResults()}</div>
      </div>
    </WalgreensCheckoutStepContainer>
  );
}
