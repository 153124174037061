import { formatDistanceToNow } from "date-fns/esm";
import { useState } from "react";
import useInterval from "../../lib/hooks/useInterval";

export interface ITimeAgoProps {
  date: Date;
  prefix?: string;
  suffix?: string;
}

export default function TimeAgo({
  date,
  prefix = "",
  suffix = "",
}: ITimeAgoProps) {
  // This forces a rerender every few seconds to update the time accordingly.
  const [, setState] = useState<any>();
  useInterval(() => setState({}), 10 * 1000);

  return (
    <span title={date.toLocaleString()}>
      {prefix}
      {formatDistanceToNow(date, { addSuffix: true })}
      {suffix}
    </span>
  );
}
