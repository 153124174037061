import { useDispatch } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import {
  setAllPrintQuantities,
  setOpeningPrintQuantities,
} from "../shared/printQuantitiesReducer";
import { useOrderStickersStore } from "./useOrderStickersStore";
import { ucWords } from "../../../lib/stringUtils";
import { setSort, StickerSortMethod } from "./stickerOrderSettingsReducer";
import { useMemo } from "react";
import isToday from "date-fns/isToday";
import "./StickerOrderControls.css";

export default function StickerOrderControls() {
  const dispatch = useDispatch();
  const {
    offer,
    printQuantities,
    settings,
    file,
    design,
  } = useOrderStickersStore();
  const regionsSelected = Object.values(printQuantities).filter(v => !!v)
    .length;
  const modifiedTodayIds = useMemo(() => {
    const openingInputs = offer.stickers.map(stickerOffer => ({
      id: stickerOffer.openingId,
      ...file.inputs[stickerOffer.openingId],
    }));
    return openingInputs
      .filter(openingInput => isToday(new Date(openingInput?.modifiedAt)))
      .map(openingInput => openingInput.id);
  }, [design, file]);

  function selectAll() {
    dispatch(setAllPrintQuantities(1));
  }
  function selectNone() {
    dispatch(setAllPrintQuantities(0));
  }

  return (
    <div id="sticker-order-controls">
      {/* Select option */}
      <div className="menu-option">
        <label>Select</label>
        <Dropdown
          inline
          header={`Select the items to print`}
          text={`${regionsSelected} ${ucWords(
            regionsSelected === 1 ? design.regionType : design.regionTypePlural
          )}`}
        >
          <Dropdown.Menu>
            <Dropdown.Header>
              Select which {design.regionTypePlural} to order
            </Dropdown.Header>
            <div className="menu-note">
              You can also select and deselect each {design.regionType}{" "}
              individually.
            </div>
            <Dropdown.Divider />
            <Dropdown.Item
              description={`${offer.stickers.length} ${
                offer.stickers.length === 1
                  ? design.regionType
                  : design.regionTypePlural
              }`}
              text="All"
              onClick={selectAll}
            />
            <Dropdown.Item
              description={`${modifiedTodayIds.length} ${
                modifiedTodayIds.length === 1
                  ? design.regionType
                  : design.regionTypePlural
              }`}
              disabled={modifiedTodayIds.length === 0}
              text="Modified Today"
              onClick={() =>
                dispatch(setOpeningPrintQuantities(modifiedTodayIds, 1, true))
              }
            />
            <Dropdown.Item text="None" onClick={selectNone} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/* Sort options */}
      <div className="menu-option">
        <label>Sort</label>
        <Dropdown
          inline
          header={`Sort ${design.regionTypePlural} by`}
          onChange={(_, e) => {
            dispatch(setSort(e.value as StickerSortMethod));
          }}
          options={[
            {
              text: "Modified",
              value: "modified",
              content: "Date Modified",
              icon: "pencil",
            },
            {
              text: "Timeline",
              value: "timeline",
              content: "Timeline",
              icon: "clock",
            },
            {
              text: `${ucWords(design.regionType)} Name`,
              value: "name",
              content: `${ucWords(design.regionType)} Name`,
              icon: "sort alphabet down",
            },
          ]}
          defaultValue={settings.sort}
        />
      </div>
    </div>
  );
}
