import * as React from "react";
import { IContextPanelSectionProps } from "./ContextPanelSection";
import useContextPanel from "./useContextPanel";
import "./ContextPanelCompactSection.css";
import { Icon } from "semantic-ui-react";

export default function ContextPanelCompactSection({
  label,
  children,
}: IContextPanelSectionProps) {
  const { closeSection } = useContextPanel();
  return (
    <div id="context-panel-compact-section">
      <div className="header" onClick={closeSection}>
        <span className="label">{label}</span>
        <Icon name="chevron down" />
      </div>
      <div className="content">{children}</div>
    </div>
  );
}
