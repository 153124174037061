import { useState } from "react";
import { gql } from "@apollo/client";
import dateFormat from "dateformat";
import clsx from "clsx";
import {
  StickerTokenTransaction,
  useAccountStickerTokenDataQuery,
} from "../../client/MapmakerApi";
import Suspender from "react-suspender";
import "./StickerTokenTransactionList.css";

gql`
  query accountStickerTokenData {
    account {
      id
      ... on MapmakerAuthenticatedAccount {
        stickerTokenBalance
      }
    }
    stickerTokenTransactions {
      id
      createdAt
      reason
      tokens
      startingBalance
      endingBalance
      note
    }
  }
`;

export default function StickerTokenTransactionList() {
  const { data, loading } = useAccountStickerTokenDataQuery({
    fetchPolicy: "no-cache",
  });

  if (loading || !data) {
    return <Suspender />;
  }

  const transactions = data.stickerTokenTransactions as StickerTokenTransaction[];

  return (
    <table id="sticker-token-transaction-list">
      <thead>
        <tr>
          <th className="date">Date</th>
          <th className="note">Description</th>
          <th className="tokens">Tokens</th>
          <th className="balance">Balance</th>
        </tr>
      </thead>
      <tbody>
        {transactions.map(transaction => (
          <StickerTokenTransactionRow
            key={transaction.id}
            transaction={transaction}
          />
        ))}
        {transactions.length === 0 && (
          <tr className="no-transactions-message">
            <td colSpan={100}>You have not received any sticker tokens yet.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

interface IStickerTokenTransactionRowProps {
  transaction: StickerTokenTransaction;
}

function StickerTokenTransactionRow({
  transaction,
}: IStickerTokenTransactionRowProps) {
  const [open, setOpen] = useState(false);
  const date = new Date(transaction.createdAt);
  return (
    <>
      <tr
        className={clsx("transaction", { open })}
        onClick={() => setOpen(!open)}
      >
        <td className="date">{dateFormat(date, "mmmm dS, yyyy")}</td>
        <td className="note">{transaction.note}</td>
        <td className="tokens">
          {transaction.tokens > 0 ? "+" : ""}
          {transaction.tokens}
        </td>
        <td className="balance">{transaction.endingBalance}</td>
      </tr>
      {open && (
        <tr className="transaction-details">
          <td colSpan={100}>
            <div className="details">
              <div>
                <label>Transaction ID</label>
                <span>{transaction.id}</span>
              </div>
              <div>
                <label>Time</label>
                <span>{dateFormat(date, "mmmm dS, yyyy h:MM:ss TT")}</span>
              </div>
              <div>
                <label>Reason</label>
                <span>{transaction.reason}</span>
              </div>
              <div>
                <label>Tokens</label>
                <span>{transaction.tokens}</span>
              </div>
              <div>
                <label>Starting Balance</label>
                <span>{transaction.startingBalance}</span>
              </div>
              <div>
                <label>Ending Balance</label>
                <span>{transaction.endingBalance}</span>
              </div>
              <div>
                <label>Description</label>
                <span>{transaction.note}</span>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
