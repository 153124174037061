import { Graphic } from "@mapmaker/svg";
import { useSvgContext } from "../../../lib/svg/useSvgContext";
import { useGraphic } from "../features/graphic/useGraphic";

export interface IGraphicSvgNodeProps {
  graphicId: string;
}

export default function GraphicSvgNode({ graphicId }: IGraphicSvgNodeProps) {
  const { graphic, graphicInput } = useGraphic(graphicId);
  const { scale: svgScale } = useSvgContext();
  return <Graphic feature={graphic} input={graphicInput} svgScale={svgScale} />;
}
