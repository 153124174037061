import { useParams } from "react-router-dom";
import { useMapmakerAppConfig } from "../../../client";
import { useProjectFile } from "../useProjectState";
import OpeningView from "./opening/OpeningView";

export default function FeatureView() {
  const { gotoEditFile } = useMapmakerAppConfig();
  const { featureId } = useParams<{ featureId: string }>();
  const file = useProjectFile();
  const feature = file.design.features[featureId];

  switch (feature?.type) {
    case "OPENING":
      return <OpeningView openingId={featureId} />;
    default:
      // Anything that makes it here should be redirected.
      gotoEditFile(file.id);
      return null;
  }
}
