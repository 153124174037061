import { format } from "date-fns";
import Suspender from "react-suspender";
import { gql } from "@apollo/client";
import StickerLineItem from "./StickerLineItem";
import StickerTokenLineItem from "./StickerTokenLineItem";
import MapLineItem from "./MapLineItem";
import OrderLineItem from "./OrderLineItem";
import { Fragment, ReactNode } from "react";
import useGroupedLineItems from "./useGroupedItems";
import FormattedAddress from "./FormattedAddress";
import { useMapmakerTitle } from "../../lib/hooks/useMapmakerTitle";
import ErrorPage from "../../lib/errors/ErrorPage";
import {
  FulfillmentFragment,
  Order,
  OrderItemDetailsFragment,
  useGetOrderQuery,
  useGetStickerOrdersForOrderDetailsQuery,
} from "../../client/MapmakerApi";
import "./OrderDetails.css";
import MapmakerMessage from "../shared/messages/MapmakerMessage";
import { useParams } from "react-router-dom";

gql`
  fragment TrackingInfo on TrackingInfo {
    company
    number
    url
  }

  fragment Fulfillment on Fulfillment {
    id
    createdAt
    deliveredAt
    trackingInfo {
      ...TrackingInfo
    }
  }

  fragment Refund on Refund {
    id
    createdAt
    totalRefunded
  }

  fragment OrderDetails on Order {
    id
    number
    createdAt
    cancelledAt
    subtotal
    totalShipping
    totalTax
    total
    billingAddressMatchesShippingAddress
    billingAddressFormatted
    shippingAddressFormatted
    note
    fulfillments {
      ...Fulfillment
    }
    items {
      ...OrderItemDetails
    }
    refunds {
      ...Refund
    }
  }

  fragment OrderItemDetails on OrderItem {
    id
    name
    attributes
    originalUnitPrice
    discountedUnitPrice
    quantity
    handle
    description
    image {
      url
      altText
    }
    ... on MapLineItem {
      blueprintId
    }
    ... on StickerLineItem {
      stickerOrderId
      tokensUsed
    }
    ... on StickerTokenLineItem {
      numTokens
    }
  }

  query getOrder($number: String!) {
    order(number: $number) {
      ...OrderDetails
    }
  }

  query getStickerOrdersForOrderDetails($ids: [String!]!) {
    stickerOrders(ids: $ids) {
      ...StickerOrderForStickerLineItem
    }
  }
`;

type OrderPageUrlParams = {
  orderNumber: string;
};

type OrderDetailsProps = {
  orderNumber?: string;
};

export default function OrderDetails({ orderNumber }: OrderDetailsProps) {
  const params = useParams<OrderPageUrlParams>();
  orderNumber = orderNumber ?? params.orderNumber;
  useMapmakerTitle(`Order #${orderNumber}`);
  const { data, loading, error } = useGetOrderQuery({
    variables: {
      number: orderNumber,
    },
  });
  const order = data?.order;

  const { stickerGroups = {}, items = [] } = useGroupedLineItems(order?.items);

  const {
    data: stickerOrderData,
    loading: loadingStickerOrders,
  } = useGetStickerOrdersForOrderDetailsQuery({
    variables: {
      ids: Object.keys(stickerGroups),
    },
  });
  const stickerOrders = stickerOrderData?.stickerOrders;
  const numLineItems = Object.keys(stickerGroups).length + items.length;

  if (loading || loadingStickerOrders) {
    return <Suspender />;
  } else if (error || !order) {
    return (
      <ErrorPage
        message={`Could not find order #${orderNumber}. Please note the order is only visible if you are logged in using the same email address as the order was placed with.`}
      />
    );
  }

  return (
    <div id="order-details">
      {order.cancelledAt && (
        <MapmakerMessage severity="error" closeable={false}>
          This order was cancelled on{" "}
          {format(new Date(order.cancelledAt), "PPPP")}
        </MapmakerMessage>
      )}
      <div className="order">
        <section className="items">
          <h5>{numLineItems === 1 ? `1 Item` : `${numLineItems} Items`}</h5>
          <div className="section-content">
            {items.map(item => (
              <OrderItemDetails key={item.id} item={item} />
            ))}
            {Object.entries(stickerGroups).map(
              ([stickerOrderId, stickerLineItems]) => (
                <StickerLineItem
                  key={stickerOrderId}
                  items={stickerLineItems}
                  stickerOrder={stickerOrders?.find(
                    stickerOrder => stickerOrder.id === stickerOrderId
                  )}
                />
              )
            )}
          </div>
        </section>
        <section className="summary">
          <h5>Order Summary</h5>
          <div className="section-content">
            <div>
              <h6 style={{ marginTop: 0 }}>Order Date</h6>
              {format(new Date(order.createdAt), "MMMM do, yyyy")}
            </div>
            <OrderAddress order={order} />
            <OrderFulfillments fulfillments={order.fulfillments} />
            <OrderNote note={order.note} />
            <div>
              <h6>Price</h6>
              <ul className="totals">
                <li>
                  <span className="key">Subtotal</span>
                  <span className="value">
                    <PrettyMoney amount={order.subtotal} />
                  </span>
                </li>
                <li>
                  <span className="key">Shipping</span>
                  <span className="value">
                    <PrettyMoney amount={order.totalShipping} onZero="FREE" />
                  </span>
                </li>
                <li>
                  <span className="key">Tax</span>
                  <span className="value">
                    <PrettyMoney amount={order.totalTax} />
                  </span>
                </li>
                <li className="total">
                  <span className="key">Total</span>
                  <span className="value">
                    <PrettyMoney amount={order.total} onZero={"$0.00"} />
                  </span>
                </li>
                {order.refunds &&
                  order.refunds.map(refund => (
                    <li key={refund.id} className="refund">
                      <span className="key">
                        Refund {format(new Date(refund.createdAt), "P")}
                      </span>
                      <span className="value">
                        <PrettyMoney
                          amount={refund.totalRefunded}
                          onZero={"$0.00"}
                        />
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

type OrderAddressProps = {
  order: Pick<
    Order,
    | "billingAddressMatchesShippingAddress"
    | "shippingAddressFormatted"
    | "billingAddressFormatted"
  >;
};

function OrderAddress({ order }: OrderAddressProps) {
  if (order.billingAddressMatchesShippingAddress) {
    return (
      <>
        <h6>Address</h6>
        <FormattedAddress
          address={
            order.billingAddressFormatted ?? order.shippingAddressFormatted
          }
        />
      </>
    );
  } else {
    return (
      <>
        {order.billingAddressFormatted && <h6>Billing Address</h6>}
        <FormattedAddress address={order.billingAddressFormatted} />
        {order.billingAddressFormatted && <h6>Shipping Address</h6>}
        <FormattedAddress address={order.shippingAddressFormatted} />
      </>
    );
  }
}

type PrettyMoneyProps = {
  amount: number;
  onZero?: ReactNode;
};

function PrettyMoney({ amount, onZero = "-" }: PrettyMoneyProps) {
  if (amount === 0) {
    return <>{onZero}</>;
  } else if (amount < 0) {
    return <>(${amount.toFixed(2)})</>;
  } else {
    return <>${amount.toFixed(2)}</>;
  }
}

type OrderItemDetailsProps = {
  item: OrderItemDetailsFragment;
};

function OrderItemDetails({ item }: OrderItemDetailsProps) {
  switch (item.__typename) {
    case "MapLineItem":
      return <MapLineItem item={item} />;
    case "StickerTokenLineItem":
      return <StickerTokenLineItem item={item} />;
    case "BasicLineItem":
      return <OrderLineItem {...item} />;
  }
  return null;
}

type OrderFulfillmentsProps = {
  fulfillments: FulfillmentFragment[];
};

function OrderFulfillments({ fulfillments }: OrderFulfillmentsProps) {
  if (fulfillments.length === 0) {
    return (
      <div>
        <h6>Shipping</h6>
        <p className="subtle">There are no shipments for this order.</p>
      </div>
    );
  }
  return (
    <>
      <h6>Shipping</h6>
      {fulfillments.map(fulfillment => (
        <Fragment key={fulfillment.id}>
          {fulfillment.trackingInfo.map(trackingInfoSingle => (
            <a
              key={trackingInfoSingle.number}
              href={trackingInfoSingle.url ?? "#"}
              target="_blank"
              rel="noreferrer"
            >
              {trackingInfoSingle.company} {trackingInfoSingle.number}
            </a>
          ))}
        </Fragment>
      ))}
    </>
  );
}

type OrderNoteProps = {
  note?: string | null;
};

function OrderNote({ note }: OrderNoteProps) {
  if (!note) {
    return null;
  } else {
    return (
      <>
        <h6>Note</h6>
        <div className="subtle">{note}</div>
      </>
    );
  }
}
