import {
  GraphicFeature,
  GraphicOption,
  MapmakerDesign,
  ProjectOutputType,
} from "../types";

type GetGraphicOptions = {
  require?: boolean;
  outputType?: ProjectOutputType;
};

export function getGraphic(
  design: Pick<MapmakerDesign, "features">,
  id: string,
  { require = false, outputType }: GetGraphicOptions = {}
): GraphicFeature {
  const graphic = design.features[id];
  if (graphic?.type === "GRAPHIC") {
    if (outputType) {
      return getGraphicForOutputType(graphic, outputType);
    } else {
      return graphic;
    }
  } else {
    if (require) {
      throw new Error(`No graphic found with the id ${id}`);
    } else {
      return undefined;
    }
  }
}

/**
 * Filters a graphics options by what is available given the current output type.
 */
export function getGraphicForOutputType(
  graphic: GraphicFeature,
  outputType: ProjectOutputType
): GraphicFeature {
  return {
    ...graphic,
    options: graphic.options.filter(option =>
      graphicOptionIsAvailable(option, outputType)
    ),
  };
}

/**
 * Determines whether a graphic option should be available given an output type.
 */
export function graphicOptionIsAvailable(
  option: GraphicOption,
  outputType: ProjectOutputType
): boolean {
  return !option.outputTypes || option.outputTypes.includes(outputType);
}
