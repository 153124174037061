import { OpeningImage, IOpeningImageProps } from "@mapmaker/svg";
import { useProjectUpload } from "../../useProjectState";

export default function ProjectOpeningImage(props: IOpeningImageProps) {
  const { dataUrl, status } = useProjectUpload(props.image.id);
  return (
    <OpeningImage
      {...props}
      dataUrl={dataUrl}
      doNotLoadRemote={status === "IN_PROGRESS"}
    />
  );
}
