import { ModalActions, ModalContent } from "semantic-ui-react";
import MapmakerButton from "../../../shared/buttons/MapmakerButton";
import MapmakerModal from "../../../shared/modals/MapmakerModal";

export interface IRemoveOpeningImageModalProps {
  onRemove(): any;
  onClose?(): any;
}

export default function RemoveOpeningImageModal({
  onRemove,
  onClose = () => {},
}: IRemoveOpeningImageModalProps) {
  function close(remove: boolean) {
    if (remove) {
      onRemove();
    }
    onClose();
  }

  return (
    <MapmakerModal
      open
      title="Remove Photo?"
      size="tiny"
      onClose={() => close(false)}
    >
      <ModalContent>Are you sure you want to remove this photo?</ModalContent>
      <ModalActions>
        <MapmakerButton onClick={() => close(false)}>Cancel</MapmakerButton>
        <MapmakerButton color="delete" onClick={() => close(true)}>
          Remove
        </MapmakerButton>
      </ModalActions>
    </MapmakerModal>
  );
}
