import { RenderableType } from "@mapmaker/renderable";
import { MapmakerBusinessId } from "@mapmaker/core";
import { AllOpeningsSvgForm } from "./AllOpeningsSvgForm";
import { CutSvgForm } from "./CutSvgForm";
import { DebugSvgForm } from "./DebugSvgForm";
import { FilePreviewSvgForm } from "./FilePreviewSvgForm";
import { OpeningsPrintSvgForm } from "./OpeningsPrintSvgForm";
import { OpeningStickerPreviewSvgForm } from "./OpeningStickerPreviewSvgForm";
import { OutputTypeDemonstrationForm } from "./OutputTypeDemonstrationForm";
import { PhotomapsDotComProductSvgForm } from "./PhotomapsDotComProductSvgForm";
import { RegionCompletionSvgForm } from "./RegionCompletionSvgForm";
import { SimpleMessageForm } from "./SimpleMessageForm";
import { TblProductSvgForm } from "./TblProductSvgForm";
import { RenderableInput } from "..";
import { ucWords } from "../../../../lib/stringUtils";
import { OpeningSuggestionSvgForm } from "./OpeningSuggestionSvgForm";

/**
 * Renderable Inputs
 *
 * How to add a new set of inputs (form) for a renderable (eg. a map preview SVG).
 *  Step 1. Create a new form in this directory folder with the name <RenderableName>Form.tsx.
 *  Step 2. Add the form to the RenderableTypesToFormMap map here.
 */

const RenderableTypesToFormMap: Record<RenderableType, RenderableForm> = {
  all_openings: AllOpeningsSvgForm,
  cut: CutSvgForm,
  debug: DebugSvgForm,
  file_preview: FilePreviewSvgForm,
  opening_sticker_preview: OpeningStickerPreviewSvgForm,
  openings_print: OpeningsPrintSvgForm,
  output_type_demonstration: OutputTypeDemonstrationForm,
  opening_suggestion: OpeningSuggestionSvgForm,
  photomaps_product: PhotomapsDotComProductSvgForm,
  region_completion: RegionCompletionSvgForm,
  simple_message: SimpleMessageForm,
  tbl_product: TblProductSvgForm,
};

const BusinessSpecificRenderableType: Record<
  MapmakerBusinessId,
  RenderableType[]
> = {
  pm: ["photomaps_product"],
  tbl: ["tbl_product"],
};

export type RenderableForm = {
  initialValues?: object;
  inputs: RenderableInput[];
};

export function getRenderableFormTypeOptions(
  businessId?: MapmakerBusinessId
): {
  label: string;
  value: string;
}[] {
  return Object.keys(RenderableTypesToFormMap)
    .filter(type => isAvailableForBusiness(type as RenderableType, businessId))
    .map(type => ({
      label: ucWords(type.replace(/_/g, " ")),
      value: type,
    }));
}

export function getFormFromType(type: RenderableType): RenderableForm {
  return RenderableTypesToFormMap[type];
}

function isAvailableForBusiness(
  testType: RenderableType,
  testBusinessId: MapmakerBusinessId
): boolean {
  if (
    Object.entries(BusinessSpecificRenderableType).some(
      ([businessId, types]) =>
        businessId != testBusinessId && types.includes(testType)
    )
  ) {
    return false;
  }
  return true;
}
