import { createStore, combineReducers, applyMiddleware, Store } from "redux";
import thunk from "redux-thunk";
import { StickerSizeClass } from "@mapmaker/core";
import {
  MapmakerDesignHeavyFragment,
  MapmakerFileHeavyFragment,
  StickerOfferFragment,
} from "../../../client/MapmakerApi";
import createPrintQuantitiesReducer, {
  PrintQuantitiesState,
} from "../shared/printQuantitiesReducer";
import { IBasePrintState } from "../shared/ReduxPrintStates";
import { StickerOrderSettings } from "./stickerOrderSettingsReducer";
import stickerOrderSettingsReducer from "./stickerOrderSettingsReducer";
import { ShopifyStickerSetMap } from "../../../client/storefront/stickerHooks";

// Types
export type OrderStickersStore = IBasePrintState & {
  offer: StickerOfferFragment;
  shopifyStickerSetMap: ShopifyStickerSetMap[];
  printQuantities: PrintQuantitiesState;
  cartState: StickerCartState;
  settings: StickerOrderSettings;
  shippingCost: string;
  freeShippingThreshold: number;
};

export type StickerSizeGroup = {
  sizeClass: StickerSizeClass;
  name: string;
  tokens: number;
  price: number;
};

export type StickerQuantityDiscount = {
  id: string;
  discount: number;
  minimumQuantity: number;
};

export type StickerCartState = {
  groups: StickerCartOrder[];
  stickerTokensInCart: number;
};

export type StickerCartOrder = {
  fileId: string;
  stickerCount: number;
};

const immutableReducer = initialState => state => state || initialState;

export function createOrderStickersStore(
  design: MapmakerDesignHeavyFragment,
  file: MapmakerFileHeavyFragment,
  offer: StickerOfferFragment,
  shopifyStickerSetMap: ShopifyStickerSetMap,
  cartState: StickerCartState,
  shippingCost: string,
  freeShippingThreshold: number
): Store<OrderStickersStore> {
  return createStore(
    combineReducers<OrderStickersStore>({
      design: immutableReducer(design),
      file: immutableReducer(file),
      offer: immutableReducer(offer),
      shopifyStickerSetMap: immutableReducer(shopifyStickerSetMap),
      cartState: immutableReducer(cartState),
      printQuantities: createPrintQuantitiesReducer(file),
      settings: stickerOrderSettingsReducer,
      shippingCost: immutableReducer(shippingCost),
      freeShippingThreshold: immutableReducer(freeShippingThreshold),
    }),
    applyMiddleware(thunk)
  );
}
