import { produce } from "immer";
import { ReactNode } from "react";
import { IconType } from "@react-icons/all-files";
import { trackGtmEvent } from "../../lib/gtm";

const DEFAULT_MESSAGE_DURATION = 10;

let currentId = 0;

type MessageID = number | string;

type MessagePersistanceLevel = "none" | "session" | "persistent";

export type Message = {
  id?: MessageID;
  type: "info" | "warning" | "error";
  content: string | ReactNode;
  persistance?: MessagePersistanceLevel;
  // Only one message from a layer will be visible at any given time.
  layer?: string;
  title?: string;
  icon?: IconType;
  duration?: number;
};

export type MessagesState = Message[];

const ADD_MESSAGE = "editorState.add_message";
const REMOVE_MESSAGE = "editorState.remove_message";
const CLEAR_MESSAGES = "editorState.clear_messages";

// Track the timeouts so if we overwrite ID's then we can clear the existing timeout.
const removeMessageTimeouts: Record<MessageID, number> = {};

export function addMessage(message: Message) {
  function cancelTimeout() {
    window.clearTimeout(removeMessageTimeouts[message.id]);
    delete removeMessageTimeouts[message.id];
  }

  return dispatch => {
    const id = message.id || currentId++;
    dispatch(addMessageInternal({ ...message, id }));
    // Remove existing timeout.
    cancelTimeout();
    const timeoutId = window.setTimeout(() => {
      dispatch(removeMessage(id));
      cancelTimeout();
    }, (message.duration ?? DEFAULT_MESSAGE_DURATION) * 1000);
    removeMessageTimeouts[message.id] = timeoutId;
  };
}

function addMessageInternal(message: Message) {
  return {
    type: ADD_MESSAGE,
    message,
  };
}

export function removeMessage(id: MessageID) {
  return {
    type: REMOVE_MESSAGE,
    id,
  };
}

export function clearMessages() {
  return {
    type: CLEAR_MESSAGES,
  };
}

const messageReducer = produce(
  (draft: MessagesState, action): MessagesState => {
    if (draft === undefined) {
      return [];
    }

    switch (action.type) {
      case ADD_MESSAGE:
        // If this message is persistent and was already shown - do not show it again.
        if (!getMessageShown(action.message.id, action.message.persistance)) {
          // If this message is on a layer, only show it if no other messages are showing.
          if (
            !action.message.layer ||
            !draft.find(message => action.message.layer === message.layer)
          ) {
            // If a message with this ID exists we want to update that one instead of adding a new one.
            const currentIndex = draft.findIndex(
              message => message.id === action.message.id
            );
            if (currentIndex === -1) {
              draft.push(action.message);
            } else {
              draft[currentIndex] = action.message;
            }

            setMessageShown(action.message.id, action.message.persistance);

            trackGtmEvent({
              event: "mapmaker.show-toast-message",
              id: action.message.id,
              type: action.message.type,
              duration: action.message.duration,
            });
          }
        }
        break;
      case REMOVE_MESSAGE:
        const messageIndex = draft.findIndex(
          message => message.id === action.id
        );
        draft.splice(messageIndex, 1);
        break;
      case CLEAR_MESSAGES:
        return [];
    }
  }
);

export default messageReducer;

// Persistent messages
const MESSAGE_PREFIX = "mm-message.";
const storageId = id => MESSAGE_PREFIX + id;

function getMessageShown(
  id: MessageID,
  persistance: MessagePersistanceLevel = "none"
): boolean {
  if (typeof id === "number") {
    return;
  }
  switch (persistance) {
    case "none":
      return false;
    case "session":
      return Boolean(sessionStorage.getItem(storageId(id)));
    case "persistent":
      return Boolean(localStorage.getItem(storageId(id)));
  }
}

function setMessageShown(
  id: MessageID,
  persistance: MessagePersistanceLevel = "none"
): boolean {
  if (typeof id === "number") {
    return;
  }
  switch (persistance) {
    case "none":
      return;
    case "session":
      sessionStorage.setItem(storageId(id), "1");
      return;
    case "persistent":
      localStorage.setItem(storageId(id), "1");
      return;
  }
}
