import { useState } from "react";
import { ModalContent, Icon, ModalActions } from "semantic-ui-react";
import clsx from "clsx";
import { Button } from "@mapmaker/ui";
import { OutputTypeDemonstration } from "@mapmaker/svg";
import {
  ProjectOutputOption,
  useGetDesignQuery,
} from "../../client/MapmakerApi";
import { useMapmakerAppConfig } from "../..";
import LoadingPage from "../shared/LoadingPage";
import { getOutputTypeDetails } from "../project/shared/OutputTypeLibrary";
import "./MapmakerFinderOutputTypeSelection.css";

type MapmakerFinderOutputTypeSelectionProps = {
  designId: string;
  onBack?(): any;
};

export default function MapmakerFinderOutputTypeSelection({
  designId,
  onBack,
}: MapmakerFinderOutputTypeSelectionProps) {
  const { gotoNewFile } = useMapmakerAppConfig();
  const [selectedOption, setSelectedOption] = useState<ProjectOutputOption>();
  const { data, loading, error } = useGetDesignQuery({
    variables: {
      id: designId,
    },
  });
  const design = data?.design;

  function createProject() {
    gotoNewFile(design.id, {
      outputType: selectedOption.type,
      outputScale: selectedOption.scale,
      inputs: selectedOption.inputs,
    });
  }

  if (error) {
    return <div>{error.message}</div>;
  } else if (loading || !design) {
    return <LoadingPage />;
  }

  return (
    <>
      <ModalContent className="mapmaker-finder-output-type-selection">
        <h2>What type of map are you filling?</h2>

        <div className="output-options">
          {design.outputOptions.map(outputOption => {
            const selected =
              selectedOption?.type === outputOption.type &&
              selectedOption?.scale === outputOption.scale;
            const { name, description } = getOutputTypeDetails(
              outputOption.type
            );
            return (
              <div
                key={outputOption.type + outputOption.scale}
                className={clsx("option", { selected })}
                onClick={() => setSelectedOption(outputOption)}
              >
                <OutputTypeDemonstration
                  outputType={outputOption.type}
                  design={design}
                  height={150}
                  width={210}
                  primaryColor={selected ? "var(--mm-black)" : "var(--mm-grey)"}
                  highlightColor={
                    selected ? "var(--mm-accent-color)" : undefined
                  }
                />
                <h3>{outputOption.name}</h3>
                <p className="description">{description}</p>
              </div>
            );
          })}
        </div>
        <p>
          Your selection will be used to choose the best output settings for
          your project. Normally this would match with the type of map you
          already have or are planning to purchase.
        </p>
        <p>
          If you're undecided or using the project for something else entirely
          just select either one. You can always change these settings later in
          the project settings panel.
        </p>
      </ModalContent>
      <ModalActions>
        <Button onClick={onBack}>Back</Button>
        <Button
          color="accent"
          disabled={!selectedOption}
          onClick={createProject}
        >
          Create
        </Button>
      </ModalActions>
    </>
  );
}
