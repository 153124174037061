import React from "react";
import { Image } from "semantic-ui-react";
import "./WalgreensContextPanelHeader.css";
import MapmakerConfig from "@mapmaker/config";

export default function() {
  return (
    <div id="walgreens-context-panel-header">
      <Image
        src={`${MapmakerConfig.staticUrl}/images/third_party/wag-brand-text-25h.png`}
      />
    </div>
  );
}
