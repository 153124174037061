import clsx from "clsx";
import { useCallback } from "react";
import Money from "../../shared/Money";
import {
  useOrderStickersStore,
  useStickerOfferItem,
} from "./useOrderStickersStore";
import "./StickerOfferGridItem.css";
import { useSelector } from "react-redux";
import { OpeningFeature, OpeningInput } from "@mapmaker/core";

export interface IStickerOfferGridItemProps {
  openingId: string;
}

export default function StickerOfferGridItem({
  openingId,
}: IStickerOfferGridItemProps) {
  const { quantity, setQuantity, ...sticker } = useStickerOfferItem(openingId);
  const { file, design } = useOrderStickersStore();
  const opening = design.features[openingId] as OpeningFeature;
  const openingInput = file.inputs[openingId] as OpeningInput;
  const selected = quantity > 0;
  const deselected = !selected;

  const incrementQuantity = useCallback(() => setQuantity(quantity + 1), [
    quantity,
    setQuantity,
  ]);
  const decrementQuantity = useCallback(() => setQuantity(quantity - 1), [
    quantity,
    setQuantity,
  ]);

  if ((openingInput?.enabled ?? opening.enabled) === false) {
    return null;
  }

  return (
    <div
      id="sticker-offer-grid-item"
      className={clsx({
        selected,
        deselected,
      })}
    >
      <img
        src={sticker.render.url}
        onClick={() => (quantity === 0 ? incrementQuantity() : setQuantity(0))}
      />
      <div
        className={clsx("details", {
          selected,
          deselected,
        })}
      >
        <div className={"qty-btn subtract"} onClick={decrementQuantity}>
          -
        </div>
        <div className="info">
          <div className={clsx("quantity", { selected, deselected })}>
            {quantity || "0"}
          </div>
          <div>
            <h4>{opening.name}</h4>
            <div className="size">
              {sticker.shopifyStickerVariant.name.toUpperCase()}{" "}
              <Money amount={sticker.shopifyStickerVariant.price} shorten />
              /ea
            </div>
          </div>
        </div>
        <div className={"qty-btn add"} onClick={incrementQuantity}>
          +
        </div>
      </div>
    </div>
  );
}
