import { useState } from "react";
import { Button } from "@mapmaker/ui";
import clsx from "clsx";
import { useProductPage } from "../ProductPageContext";
import { FaGift } from "@react-icons/all-files/fa/FaGift";
import GiftNoteModal from "./GiftNoteModal";
import { StickerTokenIcon } from "../../shared";
import StickerTokensModal from "./StickerTokensModal";
import { purchaseStickerTokensQuantity } from "../../../lib";
import "./GiftOptions.css";

export default function GiftOptions() {
  const {
    giftNote,
    setGiftNote,
    stickerTokenAddon,
    setStickerTokenAddon,
    freeStickerTokens,
    mapmakerBlueprintId,
    mapmakerOutputScale,
    mapmakerOutputType,
  } = useProductPage();
  const upsellStickerTokens = !!mapmakerBlueprintId;
  const [giftNoteModalOpen, setGiftNoteModalOpen] = useState(false);
  const [stickerTokenModalOpen, setStickerTokenModalOpen] = useState(false);
  const numStickerTokens = stickerTokenAddon
    ? purchaseStickerTokensQuantity(stickerTokenAddon)
    : 0;

  return (
    <>
      <StickerTokensModal
        open={stickerTokenModalOpen}
        designId={mapmakerBlueprintId}
        outputType={mapmakerOutputType}
        scale={mapmakerOutputScale ?? 1}
        onClose={() => setStickerTokenModalOpen(false)}
        showRemove={!!stickerTokenAddon}
        onRemove={() => setStickerTokenAddon(undefined)}
        onSelect={variant => setStickerTokenAddon(variant)}
        freeTokens={freeStickerTokens}
      />
      {upsellStickerTokens && (
        <GiftNoteModal
          open={giftNoteModalOpen}
          initialGiftNote={giftNote}
          onChange={setGiftNote}
          onClose={() => setGiftNoteModalOpen(false)}
        />
      )}
      <div id="gift-options">
        {upsellStickerTokens && (
          <Button
            id="gift-button"
            color={!!stickerTokenAddon ? "dark" : "normal"}
            outlined={!stickerTokenAddon}
            fluid
            onClick={() => setStickerTokenModalOpen(true)}
          >
            {stickerTokenAddon ? (
              <>
                <StickerTokenIcon />{" "}
                {numStickerTokens === 1
                  ? "1 Sticker Token"
                  : `${numStickerTokens} Sticker Tokens`}
              </>
            ) : (
              <>
                <StickerTokenIcon /> Add Sticker Tokens
              </>
            )}
          </Button>
        )}
        <Button
          id="gift-button"
          className={clsx({ used: !!giftNote })}
          color={!!giftNote ? "dark" : "normal"}
          outlined={!giftNote}
          fluid
          onClick={() => setGiftNoteModalOpen(true)}
        >
          {giftNote ? (
            <>
              <FaGift /> Edit Gift Note
            </>
          ) : (
            <>
              <FaGift /> Add a Gift Note
            </>
          )}
        </Button>
      </div>
    </>
  );
}
