import { FeatureType, Feature } from "../types/Features";
import { FeatureInput } from "../types/FeatureInputs";
import {
  MapmakerDesign,
  MapmakerFile,
  OpeningInput,
  OpeningImageInput,
} from "../types";

export function getInput<T extends FeatureInput>(
  file: Pick<MapmakerFile, "inputs">,
  featureId: string,
  type: T["type"]
): T {
  const featureInput = file.inputs[featureId];
  if (featureInput?.type === type) {
    return featureInput as T;
  } else {
    return null;
  }
}

export function getInputs<T extends FeatureInput>(
  file: Pick<MapmakerFile, "inputs">,
  type: FeatureType
): { [key: string]: T } {
  return Object.entries(file.inputs)
    .filter(([_, input]) => input.type === type)
    .reduce((inputs, [featureId, featureInput]) => {
      inputs[featureId] = featureInput;
      return inputs;
    }, {});
}

export function getInputsList<T extends FeatureInput>(
  file: Pick<MapmakerFile, "inputs">,
  type: T["type"]
): T[] {
  return Object.values(file.inputs)
    .filter((input) => input.type === type)
    .map<T>((input) => input as unknown as T);
}

export function mapInputs<
  T extends FeatureInput,
  F extends Pick<MapmakerFile, "inputs">
>(
  file: F,
  type: T["type"],
  mapper: (featureInput: T, featureId: string) => T,
  allowDeletion?: "ALLOW_DELETION"
): F {
  return {
    ...file,
    inputs: Object.fromEntries(
      Object.entries(file.inputs)
        .map(([featureId, featureInput]) => {
          if (featureInput.type === type) {
            const result = mapper(featureInput as T, featureId);
            if (!result && allowDeletion === "ALLOW_DELETION") {
              return undefined;
            }
            return [featureId, result];
          }
          return [featureId, featureInput];
        })
        .filter((x) => !!x)
    ),
  };
}

export type FeatureAndInput<
  F extends Feature,
  I extends FeatureInput = FeatureInput
> = {
  featureId: string;
  feature: F;
  input: I;
};

type FeatureAndInputFilter<
  F extends Feature,
  I extends FeatureInput = FeatureInput
> = (feature: F, input: I) => boolean;

/**
 * Convenience method to return a feature and it's input at the same time.
 */
export function getFeaturesAndInputList<
  F extends Feature = Feature,
  I extends FeatureInput = FeatureInput
>(
  design: Pick<MapmakerDesign, "features">,
  file: Pick<MapmakerFile, "inputs">,
  type: FeatureType,
  filter?: FeatureAndInputFilter<F, I>
): FeatureAndInput<F, I>[] {
  return Object.entries(design.features)
    .filter(([featureId, feature]) => {
      if (feature.type !== type) {
        return false;
      }
      if (filter) {
        return filter(feature as F, file.inputs[featureId] as I);
      } else {
        return true;
      }
    })
    .map<FeatureAndInput<F, I>>(([featureId, feature]) => {
      return {
        featureId,
        feature,
        input: file.inputs[featureId],
      } as FeatureAndInput<F, I>;
    });
}

export function getOpeningInput(
  file: Pick<MapmakerFile, "inputs">,
  id: string
): OpeningInput {
  return getInput<OpeningInput>(file, id, "OPENING");
}

export function getOpeningInputs(
  file: Pick<MapmakerFile, "inputs">
): OpeningInput[] {
  return getInputsList<OpeningInput>(file, "OPENING");
}

export function mapOpenings<F extends Pick<MapmakerFile, "inputs">>(
  file: F,
  mapper: (openingInput: OpeningInput, openingId: string) => OpeningInput,
  allowDeletions?: "ALLOW_DELETION"
): F {
  return mapInputs(file, "OPENING", mapper, allowDeletions);
}

export function mapOpeningImages<F extends Pick<MapmakerFile, "inputs">>(
  file: F,
  mapper: (
    image: OpeningImageInput,
    opening: OpeningInput,
    openingId: string
  ) => OpeningImageInput,
  allowDeletions?: "ALLOW_DELETION"
): F {
  return mapInputs(
    file,
    "OPENING",
    (openingInput: OpeningInput, openingId: string) => {
      const images = openingInput.images
        .map((imageInput) => mapper(imageInput, openingInput, openingId))
        .filter((x) => !!x);
      if (
        allowDeletions === "ALLOW_DELETION" &&
        !openingShouldBeSaved(openingInput)
      ) {
        return undefined;
      }
      return {
        ...openingInput,
        images,
      };
    },
    allowDeletions
  );
}

/**
 * Whether or not a specific feature input should be saved
 */
export function featureInputShouldBeSaved(featureInput: FeatureInput): boolean {
  switch (featureInput.type) {
    case "OPENING":
      return openingShouldBeSaved(featureInput);
    default:
      return true;
  }
}

/**
 * Whether the given opening input has choices made by the user that should be saved.
 */
export function openingShouldBeSaved(openingInput: OpeningInput): boolean {
  return (
    openingInput.images.length > 0 ||
    openingInput.enabled === true ||
    !!openingInput.background
  );
}
