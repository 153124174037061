import Suspender from "react-suspender";
import { useMapmakerAppConfig } from "../../client";
import { useGetDesignsAndFilesQuery } from "../../client/MapmakerApi";

export default function MapmakerBeginCollaborateEndpoint() {
  const { gotoFindMap, gotoEditFile } = useMapmakerAppConfig();
  const { data, loading } = useGetDesignsAndFilesQuery();

  if (loading) {
    return <Suspender />;
  }

  if (data.files.length === 1) {
    gotoEditFile(data.files[0].id, "collaborate");
  } else {
    gotoFindMap();
  }
  return null;
}
