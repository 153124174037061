import { Button } from "semantic-ui-react";
import { MapmakerDesign } from "../../MapmakerApi";
import individualStateNotice from "./images/IndividualStateNotice.png";
import { MdWarning } from "@react-icons/all-files/md/MdWarning";
import classes from "./MultiSizeGroupWarning.module.scss";

type MultiSizeGroupsMessageProps = {
  onAccept(): any;
  onDecline(): any;
  design: Pick<MapmakerDesign, "thumbnailUrl" | "regionType">;
};

export function MultiSizeGroupsMessage({
  design,
  onAccept,
  onDecline,
}: MultiSizeGroupsMessageProps) {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <MdWarning className={classes.icon} size="3rem" />
        <h3>WARNING</h3>
      </div>
      <p>
        This project will create a photo that fits into an{" "}
        <strong>individual {design.regionType}</strong> photo map. Images
        created from this project <strong>WILL NOT FIT</strong> into our more
        popular photo maps such as the 50 State Photo Map.
      </p>
      <div className={classes.options}>
        <div>
          <img
            alt="A larger 50 State map"
            src={individualStateNotice}
            style={{ width: "100%" }}
          />
          <p>My map looks like this.</p>
          <Button onClick={onDecline}>Go Back</Button>
        </div>
        <div>
          <img
            alt="A individual state map"
            src={design.thumbnailUrl}
            style={{ width: "50%" }}
          />
          <p>My map looks like this.</p>
          <Button onClick={onAccept}>Create Project</Button>
        </div>
      </div>
    </div>
  );
}
