import { Button, IButtonProps } from "@mapmaker/ui";
import { PropsWithChildren, useState } from "react";
import { FaThumbsUp } from "@react-icons/all-files/fa/FaThumbsUp";
import { IoMdSwap } from "@react-icons/all-files/io/IoMdSwap";
import { MdCallMerge } from "@react-icons/all-files/md/MdCallMerge";
import {
  OpeningSuggestionFragment,
  OpeningSuggestionStatus,
  useHideOpeningSuggestionMutation,
} from "../../../../../client/MapmakerApi";
import { updateOpeningSuggestion } from "../../../fileReducer";
import { useProjectDispatch } from "../../../projectStore";
import useAcceptOpeningSuggestion, {
  AcceptSuggestionResult,
} from "./useAcceptOpeningSuggestion";
import { SuggestionAcceptanceStrategy } from "./useSuggestionAcceptance";

type AcceptSuggestionButtonProps = {
  suggestion: OpeningSuggestionFragment;
  onAccept?(result: AcceptSuggestionResult): any;
} & IButtonProps;

type AcceptSuggestionButtonFullProps = AcceptSuggestionButtonProps &
  PropsWithChildren<{
    strategy: SuggestionAcceptanceStrategy;
  }>;

function AcceptSuggestionButton({
  suggestion,
  onAccept,
  strategy,
  children,
  ...buttonProps
}: AcceptSuggestionButtonFullProps) {
  const acceptSuggestion = useAcceptOpeningSuggestion(suggestion.id);
  const [loading, setLoading] = useState(false);
  const [hideOpeningSuggestion] = useHideOpeningSuggestionMutation();
  const projectDispatch = useProjectDispatch();

  async function onClick(e) {
    if (buttonProps.onClick) {
      buttonProps.onClick(e);
    }
    setLoading(true);
    const result = await acceptSuggestion(strategy);
    // We hide suggestions when they are accepted in any way.
    hideOpeningSuggestion({
      variables: {
        input: {
          fileId: suggestion.fileId,
          openingId: suggestion.openingId,
          id: suggestion.id,
          hide: true,
        },
      },
    });
    projectDispatch(
      updateOpeningSuggestion({
        ...suggestion,
        status: OpeningSuggestionStatus.Hidden,
      })
    );
    onAccept && onAccept(result);
    setLoading(false);
  }

  return (
    <Button {...buttonProps} onClick={onClick} loading={loading}>
      {children}
    </Button>
  );
}

export function AddSuggestionButton(props: AcceptSuggestionButtonProps) {
  return (
    <AcceptSuggestionButton strategy="add" {...props}>
      <FaThumbsUp />
      Accept
    </AcceptSuggestionButton>
  );
}

export function OverwriteSuggestionButton(props: AcceptSuggestionButtonProps) {
  return (
    <AcceptSuggestionButton strategy="overwrite" {...props}>
      <IoMdSwap />
      Replace
    </AcceptSuggestionButton>
  );
}

export function MergeSuggestionButton(props: AcceptSuggestionButtonProps) {
  return (
    <AcceptSuggestionButton strategy="merge" {...props}>
      <MdCallMerge style={{ rotate: "90deg" }} />
      Merge
    </AcceptSuggestionButton>
  );
}
