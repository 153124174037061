import { useEffect } from "react";

export default function useLockPageScroll(locked: boolean) {
  useEffect(() => {
    if (locked) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }

    return () => {
      document.body.classList.remove("noscroll");
    };
  }, [locked]);
}
