import { captureMessage } from "@sentry/react";
import { useLocation } from "react-router-dom";
import Suspender from "react-suspender";
import ErrorPage from "../../lib/errors/ErrorPage";
import "./StorefrontCMSPage.css";

interface IPage {
  title: string;
  body: string;
}

interface ICMSPageProps {
  page: IPage | null | undefined;
  loading?: boolean;
}

export default function CMSPage({ page, loading = false }: ICMSPageProps) {
  const location = useLocation();

  const errorMessage =
    !loading && !page ? "We could not find that page." : undefined;
  if (errorMessage) {
    captureMessage(`Page not found: ${location.pathname}`);
    return <ErrorPage message={errorMessage} />;
  } else if (!page) {
    return <Suspender />;
  }

  return (
    <>
      <h1>{page.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: page.body || "" }} />
    </>
  );
}
