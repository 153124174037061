import { ReactNode, Fragment } from "react";
import "./LabelledValueTable.css"

export type LabelledValue = [
  label: ReactNode,
  value: ReactNode,
  note?: ReactNode
];

export type LabelledValueTableProps = {
  values: LabelledValue[];
  style?: "simple" | "complex";
  labelWidth?: string | number;
};

export default function LabelledValueTable({
  values,
  style="complex",
  labelWidth,
}: LabelledValueTableProps) {
  return (
    <table id="labelled-value-table" className={`style-${style}`}>
      <tbody>
        {values.map(([label, value, note]) => {
          return (
            <Fragment key={label.toString()}>
            <tr className='value-row'>
              <td className="label" style={{width: labelWidth}}>{label}</td>
              <td className="value">{value}</td>
            </tr>
            {note && (
              <tr className='note-row'>
                <td colSpan={2}>
                {note}
                </td>
              </tr>
            )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}
