import clsx from "clsx";
import {
  HTMLAttributes,
  ReactNode,
  DetailedHTMLProps,
  Children,
  useMemo,
} from "react";
import MapmakerProjectToastMessages from "../project/shared/toasts/MapmakerProjectToastMessages";
import "./MapmakerLayout.css";
import "./MapmakerTheme.css";

type ChildrenProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "id"
> & {
  children: ReactNode;
};

export default function MapmakerLayout({ children, ...props }: ChildrenProps) {
  return (
    <div id="mapmaker-layout" {...props}>
      {children}
    </div>
  );
}

/**
 * Bar with breadcrumbs showing location and contextual main action button
 */
MapmakerLayout.TopBar = function MapmakerLayoutTopBar({
  children,
  ...props
}: ChildrenProps) {
  return (
    <div id="top-bar" {...props}>
      {children}
    </div>
  );
};

type MapmakerLayoutMainProps = ChildrenProps & {
  nav?: ReactNode;
  contextPanel?: ReactNode;
};

/**
 * Main panel
 */
MapmakerLayout.Main = function MapmakerLayoutMain({
  nav,
  contextPanel,
  children,
  ...props
}: MapmakerLayoutMainProps) {
  // Hack to change the styles slightly for our regular 2-column layout.
  const twoColumnLayout = useMemo(() => {
    return Children.toArray(children).some(child => {
      console.log();
      // @ts-ignore
      return child?.type?.prototype?.IsMainContextPanel;
    });
  }, [children]);
  return (
    <div id="main-and-nav" {...props}>
      {nav && <div id="nav">{nav}</div>}
      {contextPanel && <div id="context-panel">{contextPanel}</div>}
      <div
        id="main"
        {...props}
        className={clsx(props.className, {
          "two-column-layout": twoColumnLayout,
        })}
      >
        {children}
      </div>
    </div>
  );
};

/**
 * Deprecated in favor of MainContextPanel and MainContent below.
 */
MapmakerLayout.ContextPanel = function MapmakerLayoutContextPanel({
  children,
  ...props
}: ChildrenProps) {
  return (
    <div id="context-panel" {...props}>
      {children}
    </div>
  );
};

MapmakerLayout.Content = function MapmakerLayoutContent({
  children,
  ...props
}: ChildrenProps) {
  return (
    <div id="content" {...props}>
      {children}
    </div>
  );
};

type MainContextPanelProps = ChildrenProps & {
  expanded?: boolean;
};

MapmakerLayout.MainContextPanel = function MapmakerLayoutContextPanel({
  children,
  expanded = true,
  ...props
}: MainContextPanelProps) {
  return (
    <div
      {...props}
      id="mapmaker-layout-main-context-panel"
      className={clsx(props.className, {
        expanded,
      })}
    >
      {children}
    </div>
  );
};
// This is used to sniff out when this is a child of the main layout and we want a 2-column layout
// for the parent.
MapmakerLayout.MainContextPanel.prototype.IsMainContextPanel = true;

MapmakerLayout.MainContent = function MapmakerLayoutContent({
  children,
  ...props
}: ChildrenProps) {
  return (
    <div id="mapmaker-layout-main-content" {...props}>
      <MapmakerProjectToastMessages />
      {children}
    </div>
  );
};
