import useFeature from "../useFeature";
import { OpeningFeature, OpeningInput, SVGNode } from "@mapmaker/core";
import { useMemo } from "react";
import { useProjectFile } from "../../useProjectState";

const EmptyOpeningInput: OpeningInput = {
  type: "OPENING",
  images: [],
};

export function useOpeningBase(
  openingFeature: OpeningFeature,
  openingInput: OpeningInput = EmptyOpeningInput
) {
  const file = useProjectFile();
  const hasImages = openingInput?.images.length ?? 0 > 0;

  const enabled = useMemo(
    () => (openingInput?.enabled ?? openingFeature.enabled) !== false,
    [openingInput, openingFeature]
  );

  const cutLayer: SVGNode =
    openingFeature.layout.cut ?? openingFeature.layout.inner;
  const printLayer: SVGNode =
    openingFeature.layout.print ??
    openingFeature.layout.outer ??
    openingFeature.layout.cut;

  return {
    openingId: openingFeature.id,
    openingFeature,
    // Ensure we always get these properties. Several bugs caused by not having them sometimes.
    openingInput: {
      ...EmptyOpeningInput,
      ...openingInput,
    },
    hasImages,
    enabled,
    cutLayer,
    printLayer,
    clipPathId: `${openingFeature.id}-clipper`,
  };
}

export default function useOpening(openingId: string) {
  const { feature: openingFeature, featureInput: openingInput } = useFeature<
    OpeningFeature,
    OpeningInput
  >(openingId, "OPENING");
  return useOpeningBase(openingFeature, openingInput);
}
