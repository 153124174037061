import { PropsWithChildren } from "react";
import "./Steps.css";

type StepsProps = {};

export default function Steps({ children }: PropsWithChildren<StepsProps>) {
  return <div id="instructional-steps">{children}</div>;
}

type StepProps = {
  number: string;
  src: string;
  alt?: string;
};

export function Step({
  src,
  alt,
  number,
  children,
}: PropsWithChildren<StepProps>) {
  return (
    <div>
      <img
        alt={alt ?? (typeof children === "string" ? children : undefined)}
        src={src}
      />
      <p>
        <span className="number">{number}</span>
        {children}
      </p>
    </div>
  );
}
