import { MouseEvent } from "react";
import clsx from "clsx";
import { PieChart, PieFilling, PieSlice } from "@mapmaker/ui";
import { Icon } from "semantic-ui-react";
import TimeAgo from "../shared/TimeAgo";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaUsers } from "@react-icons/all-files/fa/FaUsers";
import { useMapmakerAppConfig, useMapmakerContext } from "../../client";
import {
  useDeleteFileMutation,
  MapmakerFileLiteFragment,
  MapmakerFileViewerPolicy,
} from "../../client/MapmakerApi";
import "./MapmakerFileCard.css";

export interface IMapmakerFileCardProps {
  file: MapmakerFileLiteFragment;
}

export default function MapmakerFileCard({ file }: IMapmakerFileCardProps) {
  const { gotoEditFile } = useMapmakerAppConfig();
  const { refetchAccountData } = useMapmakerContext();
  const [deleteFile] = useDeleteFileMutation();

  const confirmDelete = async (e: MouseEvent) => {
    e.stopPropagation();
    if (
      confirm(
        `Are you sure you want to delete your project "${file.name}"? This cannot be undone.`
      )
    ) {
      try {
        await deleteFile({
          variables: {
            input: {
              fileId: file.id,
            },
          },
          onCompleted: refetchAccountData,
        });
      } catch (e) {
        alert(
          "There was an issue deleting the file. Please refresh the page and try again."
        );
        throw e;
      }
    }
  };

  const completed = file.openingsWithImages >= file.design.regionCount;
  const isOwner = file.viewerPolicy === MapmakerFileViewerPolicy.Owner;

  return (
    <div id="mapmaker-file-card" onClick={() => gotoEditFile(file.id)}>
      <img
        src={file.previewImageUrl || file.design.thumbnailUrl}
        className={clsx({
          smallMap: file.design.width < 1728,
        })}
      />
      <div className="details">
        {file.design.regionCount > 1 && (
          <div className={"progress"}>
            <PieChart
              radius={80}
              innerRadius={62}
              background={
                completed ? "var(--mm-success-color)" : "var(--mm-dark-white)"
              }
            >
              {completed ? (
                <PieSlice
                  start={0}
                  stop={file.openingsWithImages / file.design.regionCount}
                  color="var(--mm-success-color)"
                />
              ) : (
                <PieSlice
                  start={0}
                  stop={file.openingsWithImages / file.design.regionCount}
                  color="var(--mm-accent-color)"
                />
              )}
              <PieFilling background="var(--mm-white)">
                <div className={clsx({ "pie-filling": true, completed })}>
                  <div className="number">
                    {file.openingsWithImages}/
                    {Math.max(file.design.regionCount, file.openingsWithImages)}
                  </div>
                  <div className="label">{file.design.regionTypePlural}</div>
                </div>
              </PieFilling>
            </PieChart>
          </div>
        )}
        <div className="info">
          <h5>{file.name}</h5>
          {isOwner ? (
            <div className="timestamp">
              <Icon name="clock" />
              <TimeAgo date={new Date(file.updatedAt)} />
            </div>
          ) : (
            <FileViewerPolicyBadge viewerPolicy={file.viewerPolicy} />
          )}
        </div>
        <div className="buttons">
          {isOwner && (
            <Icon
              className="delete-button"
              name="trash"
              onClick={confirmDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
}

type FileViewerPolicyBadgeProps = {
  viewerPolicy: MapmakerFileViewerPolicy;
};

function FileViewerPolicyBadge({ viewerPolicy }: FileViewerPolicyBadgeProps) {
  switch (viewerPolicy) {
    case MapmakerFileViewerPolicy.Owner:
      // No badge for owners
      return null;
    case MapmakerFileViewerPolicy.Collaborator:
      return (
        <div id="file-viewer-policy-badge" className="collaborator">
          <div className="label">
            <FaUsers fontSize={16} style={{ marginBottom: "-3px" }} />{" "}
            Collaborator
          </div>
          <div className="description">
            You can suggest photos for this project!
          </div>
        </div>
      );
    case MapmakerFileViewerPolicy.Viewer:
      return (
        <div id="file-viewer-policy-badge" className="collaborator">
          <div className="label">
            <FaEye fontSize={16} style={{ marginBottom: "-3px" }} /> View Only
          </div>
        </div>
      );
    default:
      return null;
  }
}
