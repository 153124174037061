import { RenderablePropsWithRefs } from "@mapmaker/renderable";
import { Button } from "@mapmaker/ui";
import { captureException } from "@sentry/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Icon, ModalActions, ModalContent } from "semantic-ui-react";
import { useRenderMutation } from "../../../../client/MapmakerApi";
import { trackGtmEvent } from "../../../../lib/gtm";
import MapmakerModal from "../../../shared/modals/MapmakerModal";

type ShareRenderableButtonProps = {
  renderableProps: RenderablePropsWithRefs;
  shareText: string;
  shareTitle?: string;
  preload?: boolean;
};

export function ShareRenderableButton({
  renderableProps,
  shareText,
  shareTitle,
  preload = false,
}: ShareRenderableButtonProps) {
  const [callRemoteRender] = useRenderMutation();
  const [userIsWaiting, setUserIsWaiting] = useState(false);
  const render = useRef<ReturnType<typeof callRemoteRender>>();
  const [shareDataWaitingForAction, setShareDataWaitingForAction] = useState<{
    url: string;
    shareData: ShareData;
  }>();
  const [urlWaitingForDownload, setUrlWaitingForDownload] = useState<string>();

  // Not really how this is supposed to be used... but oh well.
  const canShare = !!navigator.canShare;

  /* This method should only be called once if renderableProps do not change, so that we can preload
  renders for the user and avoid most of the wait time after they click "Share". */
  async function doRender() {
    if (!render.current) {
      render.current = callRemoteRender({
        variables: {
          request: {
            output: {
              format: "jpeg",
              downloadFilename: canShare
                ? undefined
                : "SharablePhotoMapFile.jpeg",
            },
            props: renderableProps,
          },
        },
        onError: () => (render.current = undefined),
      });
    }
    return await render.current;
  }

  // Preload the render when needed.
  useEffect(() => {
    // Clear the render if the props change. Yes this clear if preload changes too, but that is an
    // unusual use case.
    render.current = undefined;
    if (preload) {
      doRender();
    }
  }, [preload, renderableProps]);

  const onClick = useCallback(async () => {
    setUserIsWaiting(true);
    const result = await doRender();
    const url = result.data.render.url;
    try {
      trackGtmEvent({
        event: "mapmaker.share-renderable-click",
        url,
        renderableProps,
        download: false,
      });

      if (canShare) {
        const file = await createFile(url);
        const shareData = {
          files: [file],
          text: shareText,
          title: shareTitle,
        };
        try {
          // Try auto-sharing immediately
          await navigator.share(shareData);
          trackGtmEvent({
            event: "mapmaker.share-renderable-completed",
            url,
            renderableProps,
            download: false,
          });
        } catch (e) {
          switch (e?.name) {
            case "AbortError":
              // User did not select anything, nothing to worry about.
              break;
            case "NotAllowedError":
              // Rejected by OS. Expected when rendering took too long so we don't track the error.
              setShareDataWaitingForAction({ url, shareData });
              break;
            default:
              captureException(e);
              setShareDataWaitingForAction({ url, shareData });
              break;
          }
        }
      } else {
        // We can't share here do just download.
        window.open(url, "_blank");
        trackGtmEvent({
          event: "mapmaker.share-renderable-completed",
          url,
          renderableProps,
          download: true,
        });
      }
    } catch (e) {
      // This is not really expected. Should not be a sharing issue.
      captureException(e);
      setUrlWaitingForDownload(url);
    } finally {
      setUserIsWaiting(false);
    }
  }, [renderableProps, render]);

  async function doShare(shareData: ShareData, url: string) {
    try {
      // Try auto-sharing immediately
      await navigator.share(shareData);
    } catch (e) {
      switch (e?.name) {
        case "AbortError":
          // User did not select anything, nothing to worry about.
          break;
        case "NotAllowedError":
        // Rejected by OS. Tracking initially in case there are issues we need to address.
        default:
          captureException(e);
          setUrlWaitingForDownload(url);
          break;
      }
    }
  }

  return (
    <>
      {shareDataWaitingForAction && (
        <SharePromptModal
          url={shareDataWaitingForAction.url}
          onShare={() => {
            doShare(
              shareDataWaitingForAction.shareData,
              shareDataWaitingForAction.url
            );
            setShareDataWaitingForAction(undefined);
          }}
          onClose={() => setShareDataWaitingForAction(undefined)}
        />
      )}
      {urlWaitingForDownload && (
        <DownloadPromptModal
          url={urlWaitingForDownload}
          onClose={() => setUrlWaitingForDownload(undefined)}
        />
      )}
      <Button
        id="share-renderable-button"
        onClick={onClick}
        loading={userIsWaiting}
        color="accent"
        style={{ marginRight: "0.5rem" }}
      >
        {canShare ? (
          <>
            <Icon name="share alternate" />
            Share
          </>
        ) : (
          <>
            <Icon name="download" />
            Download
          </>
        )}
      </Button>
    </>
  );
}

async function createFile(url: string): Promise<File> {
  let response = await fetch(url);
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };
  return new File([data], "temp.jpeg", metadata);
}

type SharePromptModalProps = {
  onClose(): any;
  onShare(): any;
  url: string;
};

function SharePromptModal({ onClose, onShare, url }: SharePromptModalProps) {
  return (
    <MapmakerModal title="Share Your Progress!" open onClose={onClose}>
      <ModalContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={url}
          style={{
            maxWidth: "100%",
            maxHeight: "min(70vh, 100%)",
          }}
        />
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="accent" onClick={onShare}>
          <Icon name="share alternate" /> Share
        </Button>
      </ModalActions>
    </MapmakerModal>
  );
}

type DownloadPromptModalProps = {
  onClose(): any;
  url: string;
};

function DownloadPromptModal({ onClose, url }: DownloadPromptModalProps) {
  function onDownload() {
    // We can't share here do just download.
    window.open(url, "_blank");
  }

  return (
    <MapmakerModal title="Could not share" open onClose={onClose}>
      <ModalContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            background: "var(--mm-dark-white)",
            padding: "1rem",
          }}
        >
          <Icon
            name="warning circle"
            size="big"
            style={{ color: "var(--mm-warning-color" }}
          />
          <p>
            Your device will not allow this image to be shared. Would you like
            to download the image instead?
          </p>
        </div>
        <img
          src={url}
          style={{
            maxWidth: "100%",
            maxHeight: "min(50vh, 100%)",
          }}
        />
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="accent" onClick={onDownload}>
          <Icon name="download" /> Download
        </Button>
      </ModalActions>
    </MapmakerModal>
  );
}
