import { useMemo } from "react";
import { Field, useFormikContext } from "formik";
import clsx from "clsx";
import { getFeaturesList } from "@mapmaker/core";
import useUpdateEffect from "react-use/lib/useUpdateEffect";
import { useValidateRenderableInput } from "./renderableInputUtils";
import { RenderableInputProps } from ".";
import {
  MapmakerFileHeavyFragment,
  useGetFileWithDesignQuery,
} from "../../../client/MapmakerApi";
import "./RenderableInput.css";
import { ucWords } from "../../../lib/stringUtils";

export type RenderableFileOpeningInputProps = RenderableInputProps<
  "file-opening",
  string | string[],
  {
    fileProperty: string;
    multiple?: boolean;
  }
>;

export default function RenderableFileOpeningInput({
  id,
  name,
  description,
  validation,
  fileProperty,
  multiple = false,
}: RenderableFileOpeningInputProps) {
  const validate = useValidateRenderableInput<string | string[]>(
    id,
    validation
  );
  const formik = useFormikContext();
  const fileId = formik.values[fileProperty]?.FILE;
  const { loading, data } = useGetFileWithDesignQuery({
    variables: {
      id: fileId,
    },
    skip: !fileId,
    onCompleted: value => {
      // When loaded, if no openings are selected yet select the first one.
      formik.setFieldError(id, undefined);
      const openings = getOpenings(value.file);
      if (openings.length > 0 && !formik.values[id]) {
        formik.setFieldValue(id, openings[0].id);
      } else if (openings.length > 0 && formik.values[id]) {
        // If we had something selected that is not an opening, clear it.
        if (!openings.find(opening => opening.id === formik.values[id])) {
          formik.setFieldValue(id, undefined);
        }
      } else if (openings.length === 0) {
        formik.setFieldValue(id, undefined);
      }
    },
    onError: error => formik.setFieldError(id, error.message),
  });

  // When the map is changed - clear this selection.
  useUpdateEffect(() => {
    formik.setFieldValue(id, undefined);
  }, [fileId]);

  const openings = useMemo(() => getOpenings(data?.file), [data]);

  return (
    <Field type="string" name={id} validate={validate}>
      {({ field, meta }) => (
        <div
          id="renderable-input"
          className={clsx({ error: meta.touched && meta.error, loading })}
        >
          <div className="above">
            <label>
              {ucWords(
                (multiple
                  ? data?.file?.design.regionTypePlural
                  : data?.file?.design.regionType) ?? name
              )}
            </label>
          </div>
          <select type="text" {...field} multiple={multiple}>
            {!fileId && <option disabled>-</option>}
            {loading && <option disabled>LOADING</option>}
            {openings && (
              <>
                {openings.map(opening => (
                  <option key={opening.id} value={opening.id}>
                    {opening.name}
                  </option>
                ))}
              </>
            )}
          </select>
          {description && <div className="description">{description}</div>}
          {meta.touched && meta.error && meta.value && (
            <div className="error">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
}

type OpeningItem = {
  id: string;
  name: string;
  hasInputs: boolean;
};

function getOpenings(
  file: MapmakerFileHeavyFragment,
  includeEmpty: boolean = false
): OpeningItem[] {
  if (!file) {
    return [];
  }
  const openings = getFeaturesList(file.design, "OPENING");
  return openings
    .map(opening => ({
      id: opening.id,
      name: opening.name,
      hasInputs: !!file.inputs[opening.id],
    }))
    .filter(opening => opening.hasInputs || includeEmpty)
    .sort((a, b) => (a.name < b.name ? -1 : 1));
}
