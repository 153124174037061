import { Field, useFormikContext } from "formik";
import clsx from "clsx";
import { RenderableInputProps } from ".";
import { gql } from "@apollo/client";
import {
  OpeningSuggestion,
  useRenderableFileHeavyQuery,
} from "../../../client/MapmakerApi";
import "./RenderableInput.css";
import { getOpeningFeature } from "@mapmaker/core";

gql`
  query renderableFileHeavy($fileId: String!) {
    mapmakerFile(id: $fileId) {
      ...MapmakerFileHeavy
    }
  }
`;

export type RenderableOpeningSuggestionInputProps = RenderableInputProps<
  "opening_suggestion",
  string
>;

export default function RenderableOpeningSuggestionInput({
  id,
  name,
  description,
}: RenderableOpeningSuggestionInputProps) {
  const { setFieldValue, values } = useFormikContext();
  const selectedFileId = values["file"]?.FILE;

  const { loading, data } = useRenderableFileHeavyQuery({
    skip: !selectedFileId,
    variables: {
      fileId: selectedFileId,
    },
    onCompleted: data => {
      const newValue =
        values[id] ||
        refFromSuggestion(data.mapmakerFile.openingSuggestions[0]);
      setFieldValue(id, newValue || null);
    },
  });

  return (
    <Field type="select" name={id}>
      {({ field, form, meta }) => {
        return (
          <div
            id="renderable-input"
            className={clsx({
              error: meta.touched && meta.error,
              loading,
            })}
          >
            <div className="above">
              <label>{name}</label>
            </div>

            <select
              name={id}
              value={field.value?.OPENING_SUGGESTION}
              onChange={e => {
                const value = e.currentTarget.value;
                form.setFieldValue(field.name, {
                  OPENING_SUGGESTION: value,
                });
              }}
              onBlur={field.onBlur}
            >
              {data?.mapmakerFile.openingSuggestions.map(suggestion => (
                <option
                  key={suggestion.id}
                  value={bundleFromSuggestion(suggestion)}
                >
                  {
                    getOpeningFeature(
                      data?.mapmakerFile.design,
                      suggestion.openingId
                    ).name
                  }{" "}
                  ({suggestion.id})
                </option>
              ))}
            </select>

            {description && <div className="description">{description}</div>}
            {meta.touched && meta.error && meta.value && (
              <div className="error">{meta.error}</div>
            )}
          </div>
        );
      }}
    </Field>
  );
}

function refFromSuggestion(
  suggestion: Pick<OpeningSuggestion, "fileId" | "openingId" | "id">
) {
  const bundle = bundleFromSuggestion(suggestion);
  if (!bundle) {
    return undefined;
  }
  return {
    OPENING_SUGGESTION: bundle,
  };
}

function bundleFromSuggestion(
  suggestion: Pick<OpeningSuggestion, "fileId" | "openingId" | "id">
): string {
  if (!suggestion) {
    return null;
  }
  return [suggestion.fileId, suggestion.openingId, suggestion.id].join(":");
}
