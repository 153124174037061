import { Fragment } from "react";
import { Button } from "@mapmaker/ui";
import { RegionCompletionSvg } from "@mapmaker/svg";
import { useProjectFile } from "../useProjectState";
import TimelineEditorOpeningCard from "./TimelineEditorOpeningCard";
import useTimelineBundle from "./useTimelineBundle";
import { useDismissal } from "../../../lib/hooks/useDismissal";
import { saveFile } from "../fileReducer";
import { useProjectDispatch } from "../projectStore";
import { useBackgroundCallback } from "../../../lib/hooks/useBackgroundCallback";
import CreateRegionCompletionSharableButton from "../sharing/region-completion/CreateRegionCompletionSharableButton";
import "./TimelineEditor.css";

export default function TimelineEditor() {
  const projectDispatch = useProjectDispatch();
  const file = useProjectFile();
  const timelineBundle = useTimelineBundle();
  const mostRecentEdit = timelineBundle[timelineBundle.length - 1];
  const [instructionsDismissed, setInstructionsDismissed] = useDismissal(
    "mapmaker.timeline-editor-instructions-dismissed"
  );

  // Timeline edits the local file, so save it every so often.
  useBackgroundCallback(() => projectDispatch(saveFile(true)));

  return (
    <div id="timeline-editor">
      <div className="create-banner">
        <RegionCompletionSvg
          file={file}
          openingId={mostRecentEdit.openingId}
          responsive
          background="contextual"
        />
        <div className="callout">
          <h3>Share your progress!</h3>
          <p>
            Awesome sharable images from every {file.design.regionType} you've
            completed.
          </p>
          <CreateRegionCompletionSharableButton color="accent">
            Create!
          </CreateRegionCompletionSharableButton>
        </div>
      </div>
      {instructionsDismissed && (
        <div className="buttons">
          <Button
            className="instruction-button"
            outlined
            onClick={() => setInstructionsDismissed(false)}
          >
            How does the timeline work?
          </Button>
        </div>
      )}
      {!instructionsDismissed && (
        <TimelineEditorInstructions
          onClose={() => {
            setInstructionsDismissed(true);
          }}
        />
      )}
      <div className="opening-list">
        {timelineBundle.map((opening, i) => {
          // All this stuff is here to achieve a more even spacing ordering of images over years in
          // cases where people have a lot of years with just 1 photo.
          const { year, isFirstOfYear, isOnlyOfYear } = opening;

          const lastWasOnlyOfYear =
            i === 0 ? false : timelineBundle[i - 1].isOnlyOfYear;
          return (
            <Fragment key={opening.openingId}>
              {isFirstOfYear && !isOnlyOfYear && (
                <div className="year-divider">
                  <h2>{year}</h2>
                </div>
              )}
              <TimelineEditorOpeningCard
                key={opening.openingId}
                openingId={opening.openingId}
                gridColumnStart={
                  isOnlyOfYear && !lastWasOnlyOfYear ? 1 : undefined
                }
                before={
                  isOnlyOfYear && (
                    <div className="year-cell-header">
                      <h2>{year}</h2>
                    </div>
                  )
                }
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

type TimelineEditorInstructionsProps = {
  onClose(): any;
};

function TimelineEditorInstructions({
  onClose,
}: TimelineEditorInstructionsProps) {
  const file = useProjectFile();

  return (
    <div id="timeline-editor-instructions">
      <div className="close" onClick={onClose}>
        ×
      </div>
      <div className="overview">
        <h3>Your Photo Map Timeline</h3>
        <p>
          Organize your photos based on the date you traveled to each{" "}
          {file.design.regionType}. An accurate timeline of your travel will
          allow you to create unique keepsakes of your progress over time!
        </p>
        <p>
          To adjust the dates click on a {file.design.regionType} and choose the
          date which you visited. Once you've added a date, the date below that{" "}
          {file.design.regionType} will turn green.
        </p>
      </div>
      <div className="demos">
        <div className="demo accurate">
          <div className="sample">
            <div className="square" />
            <div className="date" />
          </div>
          <div className="description">
            <h5 className="short">Green - Accurate</h5>
            <div className="details">
              This date was entered by you or pulled from the photos EXIF data
              on upload.
            </div>
          </div>
        </div>
        <div className="demo inaccurate">
          <div className="sample inaccurate">
            <div className="square" />
            <div className="date" />
          </div>
          <div className="description">
            <h5 className="short">Grey - Estimated</h5>
            <div className="details">
              This is the date the image was uploaded, which may not be the day
              the photo was taken.
            </div>
          </div>
        </div>
        <div className="details"></div>
      </div>
    </div>
  );
}
