import gql from "graphql-tag";
import Suspender from "react-suspender";
import { PageNotFoundError } from "../../../lib/errors/ErrorTypes";
import { usePageBySlugQuery } from "../../../client/MapmakerApi";
import SanityContent from "./SanityContent";
import { useMapmakerTitle } from "../../../lib/hooks/useMapmakerTitle";

gql`
  fragment Page on Page {
    _id
    title
    slug {
      current
    }
    contentRaw
  }

  query pageBySlug($slug: String!) {
    allPage(where: { slug: { current: { eq: $slug } } }) {
      ...Page
    }
  }
`;

export interface ISanityPageProps {
  slug: string;
  useAsPageTitle?: boolean;
}

export default function SanityPage({
  slug,
  useAsPageTitle = false,
}: ISanityPageProps) {
  const { data, loading, error } = usePageBySlugQuery({
    variables: {
      slug,
    },
  });
  const page = data?.allPage[0];
  useMapmakerTitle(
    page?.title && useAsPageTitle ? page.title : window.document.title
  );

  if (loading) {
    return <Suspender />;
  } else if (error || !page) {
    throw new PageNotFoundError({ message: "We could not find this page." });
  }
  return (
    <div>
      <h1>{page.title}</h1>
      <SanityContent content={page.contentRaw} />
    </div>
  );
}
