import { LinkButton } from "@mapmaker/ui";
import { OrderItemDetailsFragment } from "../../client/MapmakerApi";
import { StickerTokenIcon } from "../shared/icons/StickerTokenIcon";
import OrderLineItem from "./OrderLineItem";

type StickerTokenLineItemProps = {
  item: Extract<
    OrderItemDetailsFragment,
    { __typename?: "StickerTokenLineItem" }
  >;
};

export default function StickerTokenLineItem({
  item,
}: StickerTokenLineItemProps) {
  const prettyQuantity =
    item.numTokens === 1
      ? "1 Sticker Token"
      : `${item.numTokens} Sticker Tokens`;

  return (
    <OrderLineItem
      {...item}
      image={undefined}
      imageNode={<StickerTokenIcon size="96px" />}
      name={prettyQuantity}
    >
      <p>
        After placing this order{" "}
        {item.quantity === 1
          ? "a unique code"
          : `${item.quantity} unique codes`}{" "}
        redeemable for {prettyQuantity} will be emailed to you. You can use the{" "}
        {item.quantity === 1 ? "code" : "codes"} to redeem the tokens on your
        own account or you can give {item.quantity === 1 ? "it" : "them"} to
        someone else to redeem on their account.
      </p>
      <p>
        If a physical item was also purchased with this order, the unique{" "}
        {item.quantity === 1 ? "code" : "codes"} and instructions on how to
        redeem {item.quantity === 1 ? "it" : "them"} will also be included in
        the box.
      </p>
      <LinkButton to="/redeem" outlined size="small">
        Redeem a Code
      </LinkButton>{" "}
      <LinkButton to="/sticker-tokens-faq" outlined size="small">
        More Info
      </LinkButton>
    </OrderLineItem>
  );
}
