import { useOpeningImageBase } from "../useOpeningImage";
import useEditableOpening from "./useEditableOpening";

export function useEditableOpeningImage(imageId: string) {
  const { openingFeature, openingInput } = useEditableOpening();
  const openingImageState = openingInput.images.find(
    image => image.id === imageId
  );
  const openingImageBase = useOpeningImageBase(
    openingFeature,
    openingInput,
    imageId
  );
  return {
    ...openingImageBase,
    image: openingImageState,
  };
}
