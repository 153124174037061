import { useAuthContext } from "@tbl/aws-auth";
import { Suspense, useState } from "react";
import Suspender from "react-suspender";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { gql } from "@apollo/client";
import {
  SanityContextProvider,
  SanityUrlCreator,
} from "./sanity/SanityContext";
import LoadingPage from "../../components/shared/LoadingPage";
import SanityNavigation from "./sanity/SanityNavigation";
import SanityNavigationBreadcrumbs from "./sanity/SanityNavigationBreadcrumbs";
import SanityPage from "./sanity/SanityPage";
import SanityNavigationRelativeLinks from "./sanity/SanityNavigationRelativeLinks";
import HelpPageErrorBoundary from "./HelpPageErrorBoundary";
import { UserError } from "../../lib/errors/ErrorTypes";
import {
  NavigationFragment,
  useNavigationByIdQuery,
} from "../../client/MapmakerApi";
import "./MapmakerHelpPage.css";

gql`
  query navigationById($id: String!, $pageSlug: String) {
    allNavigation(where: { id: { current: { eq: $id } } }) {
      ...Navigation
    }
    allPage(where: { slug: { current: { eq: $pageSlug } } }) {
      ...Page
    }
  }
`;

type MapmakerHelpPageProps = {
  urlCreator: SanityUrlCreator;
  slug: string;
};

export default function MapmakerHelpPage({
  urlCreator,
  slug,
}: MapmakerHelpPageProps) {
  const { user } = useAuthContext();
  const [showHidden, setShowHidden] = useLocalStorage<boolean>(
    "showHidden",
    false
  );
  // Messy query here but we want to prevent refetching on variable changes because the page is
  // only fetched as convenience.
  const [navigation, setNavigation] = useState<NavigationFragment>();
  const { loading, error } = useNavigationByIdQuery({
    variables: {
      id: "help",
      pageSlug: slug,
    },
    skip: !!navigation,
    onCompleted: data => setNavigation(data?.allNavigation[0]),
  });
  if (error) {
    throw new UserError({
      message: `Error loading help page.`,
      capture: true,
    });
  } else if (loading) {
    return <Suspender />;
  } else if (!navigation) {
    return null;
  }

  return (
    <SanityContextProvider
      navigation={navigation}
      selectedTargetSlug={slug}
      urlCreator={urlCreator}
      showHidden={!!showHidden}
    >
      <div id="mapmaker-help-page">
        <div className="breadcrumbContainer">
          <SanityNavigationBreadcrumbs />
          {user?.email?.endsWith("@thunderbunnylabs.com") && (
            <div
              style={{
                position: "absolute",
                top: "1rem",
                right: "1rem",
                userSelect: "none",
              }}
            >
              <span
                className="link"
                onClick={() => setShowHidden(!showHidden)}
                title={showHidden ? "Hide Hidden" : "Show Hidden"}
              >
                {showHidden ? "X" : "O"}
              </span>
            </div>
          )}
        </div>
        <div className="mainContainer">
          <div className="main">
            <div className="nav">
              <SanityNavigation />
            </div>
            <section className="page">
              <HelpPageErrorBoundary key={slug}>
                <Suspense fallback={<LoadingPage />}>
                  <SanityPage slug={slug} useAsPageTitle />
                  <SanityNavigationRelativeLinks />
                </Suspense>
              </HelpPageErrorBoundary>
            </section>
          </div>
        </div>
      </div>
    </SanityContextProvider>
  );
}
