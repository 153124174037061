import * as yup from "yup";
import { RenderableForm } from ".";

export const OpeningSuggestionSvgForm: RenderableForm = {
  initialValues: {},
  inputs: [
    {
      type: "file",
      id: "file",
      name: "Project",
      validation: yup.string().required(),
    },
    {
      type: "opening_suggestion",
      id: "suggestion",
      name: "Suggestion",
      validation: yup.string().required(),
    },
    {
      type: "number",
      id: "width",
      name: "Width",
      validation: yup.number(),
    },
    {
      type: "number",
      id: "height",
      name: "Height",
      validation: yup.number(),
    },
    {
      type: "number",
      id: "maxWidth",
      name: "Max Width",
      validation: yup.number(),
    },
    {
      type: "number",
      id: "maxHeight",
      name: "Max Height",
      validation: yup.number(),
    },
    {
      type: "string",
      id: "backgroundColor",
      name: "Background Color",
      validation: yup.string(),
    },
  ],
};
