import { ReactNode } from "react";
import clsx from "clsx";
import ContextPanelSection from "../../project/context/ContextPanelSection";
import ContextPanelContent from "../../project/context/ContextPanelContent";
import WalgreensContextPanelHeader from "./WalgreensContextPanelHeader";
import { Icon } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import useWalgreensCheckout from "./useWalgreensCheckout";
import {
  clearPrintBatch,
  clearSelectedStore,
  clearCustomerInformation,
} from "./walgreensCheckoutReducer";
import "./WalgreensCheckoutContextPanel.css";

export default function WalgreensCheckoutContextPanel() {
  const dispatch = useDispatch();
  const { currentStep, checklist } = useWalgreensCheckout();
  const allowClicks = currentStep !== "complete";

  // A little click action wrapper which makes sure we can click it, and closes the context
  // panel before executing the action.
  const clickAction = (fn = () => {}) => {
    if (!allowClicks) {
      return () => {};
    } else {
      return fn;
    }
  };

  function gotoEditPrints() {
    dispatch(clearPrintBatch());
  }

  function gotoSelectStore() {
    dispatch(clearSelectedStore());
  }

  function gotoGetCustomerInformation() {
    dispatch(clearCustomerInformation());
  }

  return (
    <ContextPanelContent>
      <ContextPanelSection
        sectionId="checklist"
        shortLabel="progress"
        hideTitleBar={true}
        label="Progress"
        icon="unordered list"
      >
        <div id="walgreens-checkout-context-panel">
          <WalgreensContextPanelHeader />
          <ChecklistItem
            completed={checklist["create-prints"]}
            active={currentStep === "create-prints"}
            onClick={clickAction(gotoEditPrints)}
          >
            Select photos to print
          </ChecklistItem>
          <ChecklistItem
            completed={checklist["select-store"]}
            active={currentStep === "select-store"}
            onClick={clickAction(gotoSelectStore)}
          >
            Find a Walgreens near you
          </ChecklistItem>
          <ChecklistItem
            completed={checklist["get-customer-info"]}
            active={currentStep === "get-customer-info"}
            onClick={clickAction(gotoGetCustomerInformation)}
          >
            Enter your information
          </ChecklistItem>
          <ChecklistItem
            completed={checklist["review-order"]}
            active={currentStep === "review-order"}
          >
            Review order
          </ChecklistItem>
          <ChecklistItem
            completed={checklist.complete}
            active={currentStep === "complete"}
          >
            Order Complete
          </ChecklistItem>
        </div>
      </ContextPanelSection>
    </ContextPanelContent>
  );
}

interface IChecklistItemProps {
  children: ReactNode;
  active: boolean;
  completed: boolean;
  onClick?: () => any;
}

function ChecklistItem({
  children,
  completed,
  active,
  onClick = () => {},
}: IChecklistItemProps) {
  function renderIcon() {
    if (completed) {
      return (
        <Icon
          name="check circle"
          size="big"
          style={{ color: "var(--mm-accent-color)" }}
        />
      );
    } else {
      return <Icon name="circle outline" size="big" />;
    }
  }

  function clicked() {
    if (completed) {
      onClick();
    }
  }

  return (
    <div
      className={clsx({
        "checklist-item": true,
        completed: completed,
        active: active,
        clickable: completed && !!onClick,
      })}
      onClick={clicked}
    >
      {renderIcon()} <span className="task">{children}</span>{" "}
      <Icon name="pencil" color="black" />
    </div>
  );
}
