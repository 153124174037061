import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { ImSpinner9 } from "react-icons/im";
import clsx from "clsx";
import styles from "./LoadingSpinner.module.scss";
export default function LoadingSpinner(_a) {
    var { size = "medium", color = "normal", active = true, inline = false } = _a, props = __rest(_a, ["size", "color", "active", "inline"]);
    const customColor = !["normal", "accent"].includes(color);
    return (_jsx("div", Object.assign({}, props, { className: clsx(styles.container, props.className, {
            [styles.inline]: inline,
        }) }, { children: _jsx(ImSpinner9, { className: clsx({
                [styles.spinner]: true,
                [styles[size]]: true,
                [styles[color]]: !customColor,
                [styles.active]: active,
            }), color: customColor ? color : undefined }) })));
}
