import React from "react";
import {
  MapmakerBusinessId,
  OpeningFeature,
  OpeningInput,
} from "@mapmaker/core";
import { getLayerByPriority, LayerPriority } from "./openingUtils";
import OpeningImages from "./OpeningImages";
import { MapmakerSvgContextProvider } from "../MapmakerSvgContext";
import SvgNode from "../SvgNode";
import { OpeningStickerPreviewSvgProps } from "@mapmaker/renderable";
import { viewBoxForBBox } from "../../utils/ViewBoxUtils";

/*
 * Renders an SVG showing the photos in every opening of the blueprint,
 * and optionally borders around those openings.
 */
export default function OpeningStickerPreviewSvg({
  file,
  openingId,
  width,
  height,
  responsive,
  padding = 10,
  backgroundColor = "#FFFFFF",
  dropShadow = true,
}: OpeningStickerPreviewSvgProps<"resource">) {
  const opening: OpeningFeature = file.design.features[
    openingId
  ] as OpeningFeature;
  const openingInput = file.inputs[openingId] as OpeningInput;

  // The layer which the sticker will be physically cut
  function getStickerCutLayerPriority(): LayerPriority {
    if (file.design.businessId === "tbl" && !opening.outlined) {
      // TBL stickers should get cut as far as the print extends (edges get hidden under inner mat)
      // Outlined openings do not use this strategy.
      // Sports/NP maps have a separate inner and cut
      return ["print", "outer"];
    } else {
      return "cut";
    }
  }

  // The images, made to appear as they will after the sticker has been cut.
  function getImageClipLayerPriority(): LayerPriority {
    if (opening.outlined) {
      // Outlined openings have a print clipped smaller than the cut.
      return "print";
    } else if (file.design.businessId === "tbl") {
      // TBL maps should extend the image to the print layer as well.
      // Sports/NP maps have a separate print layer
      return ["print", "outer"];
    } else {
      return "cut";
    }
  }

  const stickerCutLayerPriority = getStickerCutLayerPriority();
  const imageClipLayerPriority = getImageClipLayerPriority();
  const stickerCutLayer = getLayerByPriority(opening, stickerCutLayerPriority);
  const imageClipLayer = getLayerByPriority(opening, imageClipLayerPriority);

  const bbox = stickerCutLayer.bbox;

  if (!height && !width) {
    width = bbox.width;
    height = bbox.height;
  } else if (!width) {
    width = (height * bbox.width) / bbox.height;
  } else if (!height) {
    height = (width * bbox.height) / bbox.width;
  }

  const viewBox = viewBoxForBBox(bbox, width, height, padding);
  const svgScale = Math.max(width / viewBox[2], height / viewBox[3]);

  // Only render the background if we don't have it set to transparent.
  function Background() {
    if (backgroundColor) {
      return (
        <rect
          x={viewBox[0]}
          y={viewBox[1]}
          width="100%"
          height="100%"
          fill={backgroundColor}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox.join(" ")}
      width={responsive ? undefined : width}
      height={responsive ? undefined : height}
      preserveAspectRatio={responsive ? "xMidYMid meet" : undefined}
    >
      <MapmakerSvgContextProvider
        value={{
          svgScale,
          businessId: file.design.businessId as MapmakerBusinessId,
        }}
      >
        {dropShadow && (
          <defs>
            <filter id={`ospsvg-${openingId}`}>
              <feDropShadow
                dx={0}
                dy={5 / svgScale}
                stdDeviation={4 / svgScale}
                floodColor="#000000"
                floodOpacity={0.5}
              />
            </filter>
          </defs>
        )}
        <Background />
        {/** Clip area background color - Think of this as the color of media it is printed on. */}
        <SvgNode
          nodeData={stickerCutLayer}
          style={{
            fill: "#FFFFFF",
            filter: dropShadow ? `url(#ospsvg-${openingId})` : undefined,
          }}
        />
        {/** Solid background fill for image - if there is one. Note because some openings have a
         * fill area smaller than the clip area, we cannot combine this with the background color
         * above. */}
        {openingInput?.background ? (
          <SvgNode
            nodeData={imageClipLayer}
            style={{ fill: openingInput?.background }}
          />
        ) : null}
        {/** Printed images */}
        <OpeningImages
          clipLayer={imageClipLayer}
          opening={opening}
          openingInput={openingInput}
        />
        {/** Subtle outline to help visualize the edge of the cut */}
        <SvgNode
          nodeData={stickerCutLayer}
          style={{
            stroke: "#CCCCCC",
            vectorEffect: "non-scaling-stroke",
            strokeWidth: 1,
            fill: "none",
          }}
        />
      </MapmakerSvgContextProvider>
    </svg>
  );
}
