import {
  StorefrontProductPriceRange,
  StorefrontProductVariant,
  StorefrontSelectedOption,
} from "../../client/MapmakerApi";
import { moneyV2Range } from "./moneyUtils";

export type VariantWithOptions = Pick<
  StorefrontProductVariant,
  "selectedOptions"
>;
export type VariantWithPrice = Pick<StorefrontProductVariant, "priceV2">;
export type VariantWithAvailableForSale = Pick<
  StorefrontProductVariant,
  "availableForSale"
>;

export interface IProductWithVariants<V> {
  variants: {
    edges: {
      node: V;
    }[];
  };
}

function getVariants<T>(product: {
  variants: {
    edges: {
      node: T;
    }[];
  };
}): T[] {
  return product.variants.edges.map(edge => edge.node);
}

export function getPossibleVariants<V extends VariantWithOptions>(
  product: IProductWithVariants<V>,
  selectedOptions: StorefrontSelectedOption[]
): V[] {
  // If every option selected matches one of the variants selected options then the variant might
  // match.
  return getVariants(product).filter(variant =>
    variantCanMatch(variant, selectedOptions)
  );
}

export function getPossiblePriceRange<
  V extends VariantWithPrice & VariantWithOptions
>(
  product: IProductWithVariants<V>,
  selectedOptions: StorefrontSelectedOption[]
): StorefrontProductPriceRange {
  return moneyV2Range(
    getPossibleVariants<V>(product, selectedOptions).map(
      variant => variant.priceV2
    )
  );
}

export function variantCanMatch(
  variant: VariantWithOptions,
  selectedOptions: StorefrontSelectedOption[]
): boolean {
  // If every option selected matches one of the variants selected options then the variant might
  // match.
  return selectedOptions.every(selectedOption =>
    variant.selectedOptions.some(variantOption =>
      optionsAreEqual(selectedOption, variantOption)
    )
  );
}

export function variantDoesMatch(
  variant: VariantWithOptions,
  selectedOptions: StorefrontSelectedOption[]
): boolean {
  // If every option in the variant has that option selected then it is a match.
  return variant.selectedOptions.every(variantOption =>
    selectedOptions.some(selectedOption =>
      optionsAreEqual(selectedOption, variantOption)
    )
  );
}

export function optionsAreEqual(
  a: StorefrontSelectedOption,
  b: StorefrontSelectedOption
): boolean {
  return a.name === b.name && a.value === b.value;
}

export function findOption(
  selectedOptions: StorefrontSelectedOption[],
  name: string
): StorefrontSelectedOption | undefined {
  return selectedOptions.find(selectedOption => selectedOption.name === name);
}

export function areAllOutOfStock(
  variants: Pick<StorefrontProductVariant, "availableForSale">[]
): boolean {
  return variants.every(variant => !variant.availableForSale);
}

export function getDefaultVariantSelection<
  V extends VariantWithOptions & VariantWithAvailableForSale
>(product?: IProductWithVariants<V> | null): V | null {
  if (!product) {
    return null;
  }

  // First available variant if it exists...
  const firstAvailable = product.variants.edges
    .map(edge => edge.node)
    .find(variant => variant.availableForSale);
  if (firstAvailable) {
    return firstAvailable;
  }
  const first =
    product.variants.edges.length > 0 ? product.variants.edges[0].node : null;
  if (first) {
    return first;
  }
  // Nothing here?
  return null;
}

export function getVariantForSelectedOptions<
  T extends IProductWithVariants<V>,
  V extends VariantWithOptions
>(
  product: T | undefined,
  selectedOptions: StorefrontSelectedOption[]
): V | undefined {
  if (!product) {
    return;
  }
  return product.variants.edges
    .map(edge => edge.node)
    .find(variant => variantDoesMatch(variant, selectedOptions));
}

export function selectedOptionsToMap(
  selectedOptions: StorefrontSelectedOption[]
): Record<string, string> {
  return Object.fromEntries(
    selectedOptions.map(attribute => [attribute.name, attribute.value])
  );
}

export function isDefaultVariantOption(name: string, value: string): boolean {
  return name === "Title" && value === "Default Title";
}
