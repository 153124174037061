import { useEffect, useState } from "react";
import { SerializerProps } from "./shared";
import { useSanityImageUrlBuilder } from "./utils";
import "./ScreenshotSerializer.css";

export type ScreenshotSerializerProps = SerializerProps<{
  caption?: string;
  mobile: any;
  mobileCaption?: string;
  desktop: any;
  desktopCaption?: string;
}>;

type DeviceType = "mobile" | "desktop";

const useDeviceType = function(): DeviceType {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  return width <= 768 ? "mobile" : "desktop";
};

export default function ScreenshotSerializer({
  node: {
    caption,
    mobile,
    mobileCaption = caption,
    desktop,
    desktopCaption = caption,
  },
}: ScreenshotSerializerProps) {
  const deviceType = useDeviceType();
  const builder = useSanityImageUrlBuilder();
  return (
    <div id="sanity-screenshot">
      {mobile && deviceType === "mobile" && (
        <>
          <img
            src={builder.image(mobile?.asset).url()}
            alt={mobileCaption}
            className="image"
          />
          <div className="caption">{mobileCaption}</div>
        </>
      )}
      {desktop && deviceType === "desktop" && (
        <>
          <img
            src={builder.image(desktop?.asset).url()}
            alt={desktopCaption}
            className="image"
          />
          <div className="caption">{desktopCaption}</div>
        </>
      )}
    </div>
  );
}
