import clsx from "clsx";
import { FaLink } from "@react-icons/all-files/fa/FaLink";
import useCopyToClipboard from "react-use/lib/useCopyToClipboard";
import { Icon } from "semantic-ui-react";
import { useMapmakerAppConfig } from "../../../client/MapmakerAppConfig";
import { useProjectFile } from "../useProjectState";
import "./SharingLink.css";

type SharingLinkProps = {};

export function SharingLink({}: SharingLinkProps) {
  const file = useProjectFile();
  const { createProjectSharingLink } = useMapmakerAppConfig();

  const [copyState, copy] = useCopyToClipboard();
  const copied = !!copyState.value;
  const sharingUrl = createProjectSharingLink(file.id);

  return (
    <div id="sharing-link">
      <div>
        <FaLink style={{ margin: "0 2px -3px 2px" }} />
      </div>
      <input value={sharingUrl} readOnly />
      <div
        className={clsx("copy-btn", { copied })}
        onClick={() => copy(sharingUrl)}
      >
        {copied ? (
          <>
            <Icon name="check" style={{ color: "var(--mm-success-color)" }} />
            <span>copied</span>
          </>
        ) : (
          <>
            <Icon name="copy" />
            <span>copy</span>
          </>
        )}
      </div>
    </div>
  );
}
