import { useMemo } from "react";
import { Provider } from "react-redux";
import { createDownloadPrintsStore } from "./downloadPrintsStore";
import DownloadPrintsPanelContent from "./DownloadPrintsPanelContent";
import RectangularPrintMainPanel from "../shared/RectangularPrintMainPanel";
import RectangularPrintListGroupHeader from "../shared/RectangularPrintListGroupHeader";
import DownloadPrintListHeader from "./DownloadPrintListHeader";
import DownloadPrintCard from "./DownloadPrintCard";
import MapmakerLayout from "../../layout/MapmakerLayout";
import MapmakerTopBar from "../../layout/MapmakerTopBar";
import CustomSizeWarning from "../shared/CustomSizeWarning";
import { useProjectFile } from "../../project/useProjectState";
import ProjectToolbar from "../../project/ProjectToolbar";
import "../../project/context/DeprecatedContextPanelLayout.css";
import { useMapmakerAppConfig } from "../../../client";

export default function MapmakerDownloadPrints() {
  const file = useProjectFile();
  const config = useMapmakerAppConfig();

  // Once we're done loading we want to create the store once and be done with it.  Otherwise our
  // file data from the above methods may refresh causing the store to reset.
  const store = useMemo(
    () =>
      createDownloadPrintsStore(
        file.design,
        file,
        config.downloadConfig.bufferArea
      ),
    [file, config.downloadConfig]
  );

  return (
    <Provider store={store}>
      <MapmakerLayout>
        <MapmakerLayout.TopBar>
          <MapmakerTopBar
            breadcrumbs={[
              {
                label: "Download Files",
              },
            ]}
          />
        </MapmakerLayout.TopBar>
        <MapmakerLayout.Main
          className="deprecated-context-panel-layout"
          nav={<ProjectToolbar selected="output" />}
        >
          <MapmakerLayout.ContextPanel>
            <DownloadPrintsPanelContent />
          </MapmakerLayout.ContextPanel>
          <MapmakerLayout.Content>
            <CustomSizeWarning file={file} />
            <RectangularPrintMainPanel
              slots={{
                header: <DownloadPrintListHeader />,
                card: DownloadPrintCard,
                "group-header": RectangularPrintListGroupHeader,
              }}
            />
          </MapmakerLayout.Content>
        </MapmakerLayout.Main>
      </MapmakerLayout>
    </Provider>
  );
}
