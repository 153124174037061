import React from "react";
import { Field, useFormikContext } from "formik";
import FieldErrorMessage from "./FieldErrorMessage";

export type AutoCompleteTypes =
  | "off"
  | "on"
  | "name"
  | "honorific-prefix"
  | "given-name"
  | "additional-name"
  | "family-name"
  | "honorific-suffix"
  | "nickname"
  | "email"
  | "username"
  | "new-password"
  | "current-password"
  | "one-time-code"
  | "organization-title"
  | "organization"
  | "street-address"
  | "address-line1"
  | "address-line2"
  | "address-line3"
  | "address-level4"
  | "address-level3"
  | "address-level2"
  | "address-level1"
  | "country"
  | "country-name"
  | "postal-code"
  | "cc-name"
  | "cc-given-name"
  | "cc-additional-name"
  | "cc-family-name"
  | "cc-number"
  | "cc-exp"
  | "cc-exp-month"
  | "cc-exp-year"
  | "cc-csc"
  | "cc-type"
  | "transaction-currency"
  | "transaction-amount"
  | "language"
  | "bday"
  | "bday-day"
  | "bday-month"
  | "bday-year"
  | "sex"
  | "tel"
  | "tel-country-code"
  | "tel-national"
  | "tel-area-code"
  | "tel-local"
  | "tel-extension"
  | "impp"
  | "url"
  | "photo";

export interface ITextFieldProps {
  name: string;
  label: string;
  type?: "text" | "password" | "email";
  placeholder?: string;
  autoFocus?: boolean;
  autoComplete?: AutoCompleteTypes;
}

export default function TextField({
  name,
  label,
  placeholder,
  type = "text",
  autoFocus = false,
  autoComplete,
}: ITextFieldProps) {
  const { errors, touched } = useFormikContext();
  const hasError = touched[name] && errors[name];

  return (
    <div key={name} className={`field ${hasError ? "error" : ""}`}>
      <label htmlFor={name}>{label}</label>
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
      />
      <FieldErrorMessage name={name} />
    </div>
  );
}
