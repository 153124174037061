import { gql } from "@apollo/client";
import format from "date-fns/format";
import { Link } from "react-router-dom";
import Suspender from "react-suspender";
import { useMapmakerAppConfig } from "../../client";
import { useBlogPostQuery } from "../../client/MapmakerApi";
import ErrorPage from "../../lib/errors/ErrorPage";
import SanityContent from "../help/sanity/SanityContent";
import { useSanityImageUrlBuilder } from "../help/sanity/serializers/utils";
import BlogPostCardList from "./BlogPostCardList";
import "./MapmakerBlogPost.css";

gql`
  fragment BlogPost on Post {
    _id
    title
    slug {
      current
    }
    author {
      name
      slug {
        current
      }
    }
    mainImage {
      asset {
        _id
        url
        path
        assetId
        extension
      }
    }
    publishedAt
    bodyRaw
  }

  query blogPost($slug: String!) {
    post: allPost(where: { slug: { current: { eq: $slug } } }) {
      ...BlogPost
    }
    recentPosts: allPost(sort: [{ publishedAt: DESC }]) {
      ...BlogPostCard
    }
  }
`;

type MapmakerBlogPostProps = {
  slug: string;
};

export default function MapmakerBlogPost({ slug }: MapmakerBlogPostProps) {
  const { gotoBlogPosts } = useMapmakerAppConfig();
  const builder = useSanityImageUrlBuilder();
  const { loading, data, error } = useBlogPostQuery({
    variables: {
      slug,
    },
  });

  const post = data?.post[0];
  const recentPosts = data?.recentPosts;

  if (loading) {
    return <Suspender />;
  } else if (error || !post) {
    return <ErrorPage message="Could not find blog post." />;
  }

  return (
    <div id="mapmaker-blog-post">
      <img
        className="hero"
        src={builder
          .image(post.mainImage.asset)
          .crop("focalpoint")
          .width(1200)
          .height(400)
          .fit("crop")
          .url()}
      />
      <div className="info">
        <h1>{post.title}</h1>
        <h5>
          {format(new Date(post.publishedAt), "PPPP")} by {post.author.name}
        </h5>
      </div>
      <div className="body">
        <SanityContent content={post.bodyRaw} />
      </div>
      <div className="recent-posts">
        <div className="top-info">
          <h3>Recent Articles</h3>
          <span className="link" onClick={gotoBlogPosts}>
            View All
          </span>
        </div>
        <BlogPostCardList posts={recentPosts} />
      </div>
    </div>
  );
}
