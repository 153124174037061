import React from "react";
import Frame from "./Frame";
import { Mat, MatShadowFilter } from "../../mats";
import { getFrame } from "./FrameLibrary";
import {
  getMatboard,
  MatboardPackingSlipColorOverrides,
} from "./MatboardLibrary";
import { TblProductSvgProps } from "@mapmaker/renderable";

const LARGEST_FRAME_THICKNESS = 3 * 72;

export default function TblProductSvg({
  design,
  backgroundColor = "white",
  backgroundPrintUrl,
  excludeBackground = false,
  innerColor = "white",
  innerPrintUrl,
  excludeInner = false,
  outerColor,
  outerPrintUrl,
  excludeOuter = false,
  frame,
  excludeFrame = false,
  useCase,
  width,
  height,
}: TblProductSvgProps<"resource">) {
  const viewBox = [
    -LARGEST_FRAME_THICKNESS,
    -LARGEST_FRAME_THICKNESS,
    design.width + LARGEST_FRAME_THICKNESS * 2,
    design.height + LARGEST_FRAME_THICKNESS * 2,
  ];

  function getFill(color) {
    const mat = getMatboard(color);
    if (
      useCase === "packing-slip" &&
      MatboardPackingSlipColorOverrides[color]
    ) {
      return MatboardPackingSlipColorOverrides[color];
    }
    if (mat) {
      if (mat.hex) {
        return mat.hex;
      } else {
        return "#FFFFFF";
      }
    } else {
      return color;
    }
  }

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox.join(" ")}
      width={width || viewBox[2]}
      height={height || viewBox[3]}
    >
      <defs>
        <MatShadowFilter id="matShadow" />
      </defs>
      <Mat
        design={design}
        layer="background"
        fillColor={getFill(backgroundColor)}
        fillUrl={backgroundPrintUrl}
        filter="url(#matShadow)"
        exclude={excludeBackground}
      />
      <Mat
        design={design}
        layer="inner"
        fillColor={getFill(innerColor)}
        fillUrl={innerPrintUrl}
        filter="url(#matShadow)"
        exclude={excludeInner}
      />
      <Mat
        design={design}
        layer="outer"
        fillColor={getFill(outerColor)}
        fillUrl={outerPrintUrl}
        filter="url(#matShadow)"
        exclude={excludeOuter}
      />
      {!excludeFrame && (
        <Frame
          width={design.width}
          height={design.height}
          frame={getFrame(frame)}
        />
      )}
    </svg>
  );
}
