import { gql } from "@apollo/client";
import { Icon, Input, ModalContent } from "semantic-ui-react";
import MapmakerModal from "../shared/modals/MapmakerModal";
import {
  MapmakerDesignLiteFragment,
  ProjectOutputType,
  useGetDesignsQuery,
} from "../../client/MapmakerApi";
import LoadingPage from "../shared/LoadingPage";
import ErrorPage from "../../lib/errors/ErrorPage";
import LabelledValueTable from "../shared/tables/LabelledValueTable";
import { useMemo, useState } from "react";
import { useMapmakerAppConfig } from "../..";
import MapmakerFinderGroupSelection, {
  getGroupIdAndSize,
} from "./MapmakerFinderGroupSelection";
import "./MapmakerFinderModal.css";
import MapmakerFinderOutputTypeSelection from "./MapmakerFinderOutputTypeSelection";

gql`
  query getDesigns($request: GetMapmakerDesignsRequest) {
    designs: mapmakerDesigns(request: $request) {
      ...MapmakerDesignLite
    }
  }
`;

export type FinderDesignGroup = {
  baseId: string;
  name: string;
  multipleSizes: boolean;
  design: MapmakerDesignLiteFragment;
  designs: MapmakerDesignLiteFragment[];
};

type MapmakerFinderModalProps = {
  open: boolean;
  onClose?(): any;
};

export default function MapmakerFinderModal({
  open,
  onClose,
}: MapmakerFinderModalProps) {
  const { loading, data, error } = useGetDesignsQuery();
  const [query, setQuery] = useState<string>();
  const { gotoNewFile } = useMapmakerAppConfig();
  const [selectedGroup, setSelectedGroup] = useState<FinderDesignGroup>();
  const [
    selectedMultiOutputDesignId,
    setSelectedMultiOutputDesignId,
  ] = useState<string>();

  function close() {
    setSelectedGroup(undefined);
    onClose();
  }

  const groups = useMemo<FinderDesignGroup[]>(() => {
    return getDesignGroups(data?.designs ?? []);
  }, [data?.designs]);

  const filteredGroups = useMemo<FinderDesignGroup[]>(() => {
    if (!groups) {
      return [];
    } else if (!query) {
      return groups;
    } else {
      return (
        groups
          // Score all the designs based on their match to the query.
          .map<[FinderDesignGroup, number]>(group => [
            group,
            matchDesign(query, group.design),
          ])
          // Filter anything that does not match.
          .filter(([, score]) => score > 0)
          // Sort by score.
          .sort((a, b) => b[1] - a[1])
          // Map back to just the design.
          .map(([group, _]) => group)
      );
    }
  }, [groups, query]);

  return (
    <MapmakerModal
      key="search-component"
      className="mapmaker-finder-modal"
      open={open}
      title="Create a Project"
      onClose={close}
    >
      {selectedGroup ? (
        <MapmakerFinderGroupSelection
          group={selectedGroup}
          onBack={() => setSelectedGroup(undefined)}
        />
      ) : selectedMultiOutputDesignId ? (
        <MapmakerFinderOutputTypeSelection
          designId={selectedMultiOutputDesignId}
          onBack={() => setSelectedMultiOutputDesignId(undefined)}
        />
      ) : (
        <ModalContent className="mapmaker-finder-content">
          {loading && <LoadingPage style={{ minHeight: "25vh" }} />}
          {error && <ErrorPage message="An error ocurred. Please try again." />}
          {!loading && !error && data && (
            <>
              <p className="help">
                Select the photo map that you want to create from the list
                below.
              </p>
              <Input
                className="search"
                value={query}
                type="search"
                fluid
                placeholder="Search by a location name"
                onChange={e => setQuery(e.currentTarget.value)}
                autoFocus
              />
              <ul className="design-list">
                {filteredGroups.map(group => {
                  return (
                    <li
                      key={group.design.id}
                      onClick={() => {
                        if (group.multipleSizes) {
                          setSelectedGroup(group);
                        } else if (group.design.outputOptions.length > 1) {
                          setSelectedMultiOutputDesignId(group.design.id);
                        } else {
                          gotoNewFile(group.design.id);
                        }
                      }}
                    >
                      <div className="thumbnail">
                        <img src={group.design.thumbnailUrl} alt={group.name} />
                      </div>
                      <div className="info">
                        <h3>{group.name}</h3>
                        <LabelledValueTable
                          style="simple"
                          labelWidth="80px"
                          values={[
                            [
                              "Size",
                              group.multipleSizes
                                ? `${group.designs.length} Sizes`
                                : `${ptToIn(group.design.width)} × ${ptToIn(
                                    group.design.height
                                  )}`,
                            ],
                            [
                              "Photos",
                              `${group.design.regionCount} ${group.design.regionTypePlural}`,
                            ],
                          ]}
                        />
                      </div>
                      <div>
                        <Icon
                          className="add-icon"
                          name="chevron right"
                          size="large"
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
              {filteredGroups.length === 0 && query && (
                <div className="no-matches">
                  No matches found for search <strong>{query}</strong>
                </div>
              )}
            </>
          )}
        </ModalContent>
      )}
    </MapmakerModal>
  );
}

const ptToIn = (pt: number) => Math.round(pt / 72) + '"';

function getDesignGroups(
  designs: MapmakerDesignLiteFragment[]
): FinderDesignGroup[] {
  const groupMap: {
    [key: string]: FinderDesignGroup;
  } = designs.reduce((groups, design) => {
    const [baseId] = getGroupIdAndSize(design);
    if (!groups[baseId]) {
      const name = design.name.split(" - ")[0].replace(" Photo Map", "");
      groups[baseId] = {
        baseId,
        name,
        design,
        designs: [],
        multipleSizes: false,
      };
    } else {
      groups[baseId].multipleSizes = true;
    }
    groups[baseId].designs.push(design);
    return groups;
  }, {});
  return Object.values(groupMap).sort(sortDesignGroups);
}

/* Very basic search relevance matcher. */
function matchStrength(content: string, query: string): number {
  content = content.toLowerCase();
  const index = content.indexOf(query);
  if (index === -1) {
    return 0;
  } else if (index === 0) {
    return 1;
  } else if (content[index - 1] === " ") {
    return 0.95;
  } else {
    // Matched somewhere, tiny match bonus if the query is short though.
    return (Math.max(query.length, 4) / 4) * 0.5;
  }
}

function matchDesign(
  query: string,
  design: MapmakerDesignLiteFragment
): number {
  query = query.toLowerCase();
  return (
    1 * matchStrength(design.name, query) ||
    0.5 * matchStrength(design.regionType, query) ||
    0.5 * matchStrength(design.regionTypePlural, query) ||
    0.5 * matchStrength(design.id, query) ||
    0.25 * matchStrength(design.keywords.join(" "), query)
  );
}

const SORT_FIRST = [
  "tbl.usa_states.2",
  "tbl.national_parks",
  "tbl.baseball_stadiums",
  "tbl.football_stadiums",
  "tbl.hockey_stadiums",
  "tbl.basketball_stadiums",
  "tbl.seven_continents",
  "tbl.caribbean_countries",
  "tbl.canada_provinces",
  "tbl.europe_countries",
  "tbl.australia_states",
  "tbl.south_america_countries",
  "tbl.asia_countries",
  "tbl.africa_countries",
];

function sortDesignGroups(a: FinderDesignGroup, b: FinderDesignGroup): number {
  const aSort = SORT_FIRST.indexOf(a.baseId);
  const bSort = SORT_FIRST.indexOf(b.baseId);
  if (aSort === -1 && bSort === -1) {
    return a.name < b.name ? -1 : 1;
  } else if (aSort === -1) {
    return 1;
  } else if (bSort === -1) {
    return -1;
  } else {
    return aSort - bSort;
  }
}
