import React from "react";
import { Header } from "semantic-ui-react";

export default function RectangularPrintHelp() {
  return (
    <>
      <Header sub>Using your own printer</Header>
      <ul>
        <li>
          On this page, select the paper size that matches the size of the paper
          in your printer.
        </li>
        <li>
          When printing the downloaded file use <strong>Actual Size</strong> or{" "}
          <strong>100% Scale</strong>. Be sure "Fit to Page" is <i>not</i>{" "}
          checked.
        </li>
        <li>
          Test with a single print to verify your size settings are correct. If
          possible use cheaper paper and black and white ink while testing.
        </li>
      </ul>
      <Header sub>Ordering from another service</Header>
      <ul>
        <li>
          Be sure to order the correct size shown for each print (4x6, 5x7,
          etc...).
        </li>
        <li>Do not crop or resize the images when ordering.</li>
      </ul>
    </>
  );
}
