import React from "react";
import { getOpeningList } from "@mapmaker/core";
import { OutputTypeDemonstrationProps } from "@mapmaker/renderable";
import { getLayerByPriority } from "../opening";
import SvgNode from "../SvgNode";

const WIRE_HEIGHT = 4 * 72;
const BAR_HEIGHT = 1 * 72;
const BAR_OVERHANG = 1 * 72;

export default function VinylWallMapDemonstration({
  design,
  width,
  height,
  primaryColor,
  highlightColor = primaryColor,
  lowlightColor = primaryColor,
  highlightedOpeningIds = Object.keys(design.features),
  backgroundColor = "#FFFFFF",
}: Omit<OutputTypeDemonstrationProps<"resource">, "outputType">) {
  const viewBox = [
    -BAR_OVERHANG - 5,
    -WIRE_HEIGHT - 5,
    design.width + BAR_OVERHANG * 2 + 10,
    design.height + WIRE_HEIGHT * 2 + 10,
  ];

  const scale = Math.min(width / viewBox[2], height / viewBox[3]);
  const strokeWidth = 1 / scale;
  const openings = getOpeningList(design);

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox.join(" ")}
      width={width}
      height={height}
    >
      {/* Wire */}
      <line
        x1={design.width * 0.1}
        y1={0}
        x2={design.width * 0.5}
        y2={-WIRE_HEIGHT}
        stroke={primaryColor}
        strokeWidth={strokeWidth}
      />
      <line
        x1={design.width * 0.5}
        y1={-WIRE_HEIGHT}
        x2={design.width * 0.9}
        y2={0}
        stroke={primaryColor}
        strokeWidth={strokeWidth}
      />
      {/* Map Sheet */}
      <rect
        x={0}
        y={0}
        width={design.width}
        height={design.height}
        fill={backgroundColor}
        stroke={primaryColor}
        strokeWidth={strokeWidth}
      />
      {/* Hanger bars */}
      <rect
        x={-BAR_OVERHANG}
        y={-BAR_HEIGHT / 2}
        width={design.width + 2 * BAR_OVERHANG}
        height={BAR_HEIGHT}
        fill={primaryColor}
      />
      <rect
        x={-BAR_OVERHANG}
        y={design.height - BAR_HEIGHT / 2}
        width={design.width + 2 * BAR_OVERHANG}
        height={BAR_HEIGHT}
        fill={primaryColor}
      />
      {/* Map */}
      {openings
        .filter(opening => opening.enabled !== false)
        .map(opening => {
          return (
            <SvgNode
              key={opening.id}
              nodeData={getLayerByPriority(opening, ["cut", "print", "inner"])}
              style={{
                fill: highlightedOpeningIds.includes(opening.id)
                  ? highlightColor
                  : lowlightColor,
                stroke: "#FFFFFF",
                strokeWidth: strokeWidth / 2,
              }}
            />
          );
        })}
    </svg>
  );
}
