import { ReactNode } from "react";
import { Header } from "semantic-ui-react";
import "./WalgreensCheckoutStepContainer.css";

interface IWalgreensCheckoutStepContainerProps {
  header?: string;
  children: ReactNode;
}

export default function WalgreensCheckoutStepContainer({
  header = null,
  children,
}: IWalgreensCheckoutStepContainerProps) {
  return (
    <div id="walgreens-checkout-step-container">
      {header && (
        <Header as="h3" className="main-header">
          {header}
        </Header>
      )}
      {children}
    </div>
  );
}
