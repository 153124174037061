import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  addMessage,
  clearMessages,
  Message,
} from "../../../shared/messageReducer";
import { useProjectDispatch } from "../../projectStore";

type ContentOrMessage =
  | Message["content"]
  | Pick<Message, "content" | "icon" | "title" | "persistance">;

function getMessageFromContentOrMessage(
  contentOrMessage
): Pick<Message, "content"> & Partial<Omit<Message, "content">> {
  if (typeof contentOrMessage === "object" && contentOrMessage.content) {
    return contentOrMessage;
  } else {
    return {
      content: contentOrMessage,
    };
  }
}

export function useAddToastMessage() {
  const dispatch = useProjectDispatch();
  const location = useLocation();

  // Clear all toasts whenever the URL changes.
  useEffect(() => {
    clearAllToasts();
  }, [location]);

  const addToastMessage = useCallback(
    (message: Message) => {
      dispatch(addMessage(message));
    },
    [dispatch]
  );

  const addToastInfo = useCallback(
    (
      contentOrMessage: ContentOrMessage,
      id: string = undefined,
      duration: number = undefined
    ) => {
      addToastMessage({
        type: "info",
        ...getMessageFromContentOrMessage(contentOrMessage),
        id,
        duration,
      });
    },
    [addToastMessage]
  );

  const addToastWarning = useCallback(
    (
      contentOrMessage: ContentOrMessage,
      id: string = undefined,
      duration: number = undefined
    ) => {
      addToastMessage({
        type: "warning",
        ...getMessageFromContentOrMessage(contentOrMessage),
        id,
        duration,
      });
    },
    [addToastMessage]
  );

  const addToastError = useCallback(
    (
      contentOrMessage: ContentOrMessage,
      id: string = undefined,
      duration: number = undefined
    ) => {
      addToastMessage({
        type: "error",
        ...getMessageFromContentOrMessage(contentOrMessage),
        id,
        duration,
      });
    },
    [addToastMessage]
  );

  const clearAllToasts = useCallback(() => {
    dispatch(clearMessages());
  }, [dispatch]);

  return {
    addToastMessage,
    addToastInfo,
    addToastWarning,
    addToastError,
    clearAllToasts,
  };
}
