import sanityImageUrlBuilder from "@sanity/image-url";
import { useMapmakerAppConfig } from "../../../../client/MapmakerAppConfig";

export function useSanityImageUrlBuilder() {
  const { sanity } = useMapmakerAppConfig();
  return sanityImageUrlBuilder({
    dataset: sanity.dataset,
    projectId: sanity.projectId,
  });
}
