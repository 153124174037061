import { Fragment } from "react";
import { OpeningImages, SvgNode, getLayerByPriority } from "@mapmaker/svg";
import useOpening from "./useOpening";
import { useSvgContext } from "../../../../lib/svg/useSvgContext";
import { OpeningFeature, OpeningInput } from "@mapmaker/core";

export interface IReadOnlyOpeningProps {
  openingId: string;
}

export default function ReadOnlyOpening({ openingId }: IReadOnlyOpeningProps) {
  const { openingFeature: opening, openingInput } = useOpening(openingId);
  return (
    <ReadOnlyOpeningDirect
      openingFeature={opening}
      openingInput={openingInput}
    />
  );
}

interface IReadOnlyOpeningDirectProps {
  openingFeature: OpeningFeature;
  openingInput: OpeningInput;
  opacity?: number;
}

export function ReadOnlyOpeningDirect({
  openingFeature,
  openingInput,
}: IReadOnlyOpeningDirectProps) {
  const clipLayer = getLayerByPriority(openingFeature, ["cut", "inner"]);
  const { scale } = useSvgContext();
  return (
    <Fragment key={openingFeature.id}>
      {/* User-defined background for opening. */}
      <SvgNode
        nodeData={clipLayer}
        style={{
          fill: openingInput?.background ?? "var(--mm-dark-white)",
          strokeWidth: 2 / scale,
          stroke: "var(--mm-black)",
        }}
      />
      <OpeningImages
        opening={openingFeature}
        openingInput={openingInput}
        clipLayer={clipLayer}
      />
    </Fragment>
  );
}
