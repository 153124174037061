import SelectableSetting from "./SelectableSetting";
import SettingSection from "./SettingSection";
import useProjectState, { useProjectDesign } from "../../useProjectState";
import { getOutputTypeDetails } from "../../shared/OutputTypeLibrary";
import { OutputTypeDemonstration } from "@mapmaker/svg";
import { useProjectDispatch } from "../../projectStore";
import "./OutputTypeSettings.css";
import { setOutputOption } from "../../fileReducer";

export default function OutputTypeSettings() {
  const dispatch = useProjectDispatch();
  const design = useProjectDesign();
  const { selectedProjectOutput } = useProjectState();

  // No need to show options if there are none.
  if (design.outputOptions.length <= 1) {
    return null;
  }

  // In the future we should still show the output type here (because all projects have an output
  // type) but for now this will keep anyone with a custom size from accidentally changing the map.
  // The output scale currently shows a message when a customer selection is picked.
  if (!selectedProjectOutput) {
    return null;
  }

  return (
    <SettingSection
      id="output-type-settings"
      label="Project Output"
      description="What type of photo map are you filling in? This will determine what output options you have."
    >
      {design.outputOptions.map(option => {
        const selected = option.name === selectedProjectOutput.name;
        const outputTypeDetails = getOutputTypeDetails(option.type);
        return (
          <SelectableSetting
            key={option.type + option.scale}
            className="option"
            selected={selected}
            onSelect={() => dispatch(setOutputOption(option))}
          >
            <div className="image">
              <OutputTypeDemonstration
                outputType={option.type}
                design={design}
                height={100}
                width={140}
                primaryColor={selected ? "var(--mm-black)" : "var(--mm-grey)"}
                highlightColor={selected ? "var(--mm-accent-color)" : undefined}
              />
            </div>
            <div className="info">
              <h4>{option.name}</h4>
              <p className="description">{outputTypeDetails.description}</p>
            </div>
          </SelectableSetting>
        );
      })}
    </SettingSection>
  );
}
