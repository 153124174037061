import React from "react";

type BackdropProps = {
  width: number;
  height: number;
  x?: number;
  y?: number;
  color?: string;
};

export default function Backdrop({
  height,
  width,
  x = 0,
  y = 0,
  color = "#FFFFFF",
}: BackdropProps) {
  return <rect x={x} y={y} width={width} height={height} fill={color} />;
}
