import { getNavigationPath } from "./navigationUtils";
import { useSanityContext } from "./SanityContext";
import { Breadcrumb, Breadcrumbs } from "@mapmaker/ui";
import "./SanityNavigationBreadcrumbs.css";

export default function SanityNavigationBreadcrumbs() {
  const { urlCreator, navigation, selectedTargetSlug } = useSanityContext();
  const path = getNavigationPath(navigation, selectedTargetSlug);

  return (
    <Breadcrumbs>
      <Breadcrumb to={urlCreator()}>{navigation.title}</Breadcrumb>
      {path?.map((link, i) => (
        <Breadcrumb
          key={link.target?.slug?.current ?? i}
          to={urlCreator(link.target?.slug?.current)}
        >
          {link.target?.title ?? link.title}
        </Breadcrumb>
      ))}
    </Breadcrumbs>
  );
  /*
    <ul id="sanity-navigation-breadcrumbs">
      <li>
        <MaybeLink to={urlCreator()}>{navigation.title}</MaybeLink>
      </li>
    </ul>*/
}
