import { LoadingSpinner } from "@mapmaker/ui";
import React from "react";

export interface IGenericLoaderProps {
  size?: "small" | "medium" | "large";
}

export default function GenericLoader({
  size = "medium",
}: IGenericLoaderProps) {
  return <LoadingSpinner active size={size} />;
}
