import { ProjectOutputType, StickerSizeClass } from "../types";
import { OpeningFeature } from "../types/Features";

type StickerSizeAreaLimits = { [key in StickerSizeClass]: number };

const SizeClassMaxAreas: Record<ProjectOutputType, StickerSizeAreaLimits> = {
  MATBOARD_WALL_MAP: {
    SMALL: 6.5,
    MEDIUM: 23,
    LARGE: 10000,
  },
  MATBOARD_WALL_MAP_SINGLE: {
    SMALL: 6,
    MEDIUM: 20,
    LARGE: 10000,
  },
  VEHICLE_MAP: {
    SMALL: 4,
    MEDIUM: 13.3,
    LARGE: 10000,
  },
  VINYL_WALL_MAP: {
    SMALL: 6,
    MEDIUM: 20,
    LARGE: 10000,
  },
};

const SIZE_CLASS_TOKEN_PRICES: { [key in StickerSizeClass]: number } = {
  SMALL: 0.5,
  MEDIUM: 1,
  LARGE: 2,
};

export function getStickerSizeClass(
  opening: OpeningFeature,
  scale: number,
  outputType: ProjectOutputType
): StickerSizeClass {
  if (opening.stickerSizeClass) {
    // If the opening has an explicit size class then use that.
    return opening.stickerSizeClass;
  } else {
    // Otherwise calculate it.
    const bbox = opening.layout.cut?.bbox ?? opening.layout.print?.bbox;
    const area = (bbox.width * bbox.height * scale) / (72 * 72);

    return getStickerSizeClassByArea(area, outputType);
  }
}

function getStickerSizeClassByArea(
  areaSqIn: number,
  outputType: ProjectOutputType
): StickerSizeClass {
  const sizeClassLimits = SizeClassMaxAreas[outputType];
  const group = Object.keys(sizeClassLimits).find(
    sizeClass => areaSqIn <= sizeClassLimits[sizeClass]
  ) as StickerSizeClass;
  if (group) {
    return group;
  } else {
    return "LARGE";
  }
}

export function getTokensForSizeClass(sizeClass: StickerSizeClass): number {
  return SIZE_CLASS_TOKEN_PRICES[sizeClass];
}
