import { getOpeningFeature, MapmakerBusinessId } from "@mapmaker/core";
import { OpeningSuggestionFragment } from "../../../../../client/MapmakerApi";
import { useProjectDesign } from "../../../useProjectState";
import { OpeningSvg } from "@mapmaker/svg";
import useOpening from "../useOpening";
import { CSSProperties } from "react";
import ProjectOpeningImage from "../ProjectOpeningImage";

type OpeningSuggestionThumbnailProps = {
  openingSuggestion: OpeningSuggestionFragment;
  width?: number;
  height?: number;
  padding?: number;
  strokeStyle?: CSSProperties;
  fillStyle?: CSSProperties;
};

export default function OpeningSuggestionThumbnail({
  openingSuggestion,
  width,
  height = width,
  padding = 0,
  strokeStyle,
  fillStyle,
}: OpeningSuggestionThumbnailProps) {
  const design = useProjectDesign();
  const openingFeature = getOpeningFeature(design, openingSuggestion.openingId);
  const { openingInput } = useOpening(openingSuggestion.openingId);
  const suggestionOpeningInput = {
    ...openingInput,
    ...openingSuggestion.input,
  };

  return (
    <OpeningSvg
      businessId={design.businessId as MapmakerBusinessId}
      openingInput={suggestionOpeningInput}
      openingFeature={openingFeature}
      width={width}
      height={height}
      padding={padding}
      strokeStyle={strokeStyle}
      fillStyle={fillStyle}
      OpeningImageComponent={ProjectOpeningImage}
    />
  );
}
