import { useMemo } from "react";
import { useStorefrontCheckoutHeavy } from "../..";
import {
  StickerOrder,
  StorefrontAttributeInput,
  StorefrontCheckoutHeavyFragment,
  StorefrontCheckoutLineItem,
  StorefrontProduct,
  StorefrontCheckoutLineItemHeavyFragment,
} from "../../client/MapmakerApi";
import { getAttributeValueByKey } from "./attributeUtils";
import { isPurchaseStickerTokensProduct } from "./purchaseStickerTokenUtils";

export type StickerOrderLineItemGroup = {
  fileId: string;
  orderId: string;
  lineItems: StorefrontCheckoutLineItemHeavyFragment[];
};

export function isStickerLineItem(
  lineItem: StorefrontCheckoutLineItemHeavyFragment
): boolean {
  return (
    lineItem.variant?.sku.startsWith("mapmaker.sticker.") ||
    lineItem.variant?.sku.startsWith("pm.sticker.")
  );
}

type GroupStickerLineItemsResult = {
  standardLineItems: StorefrontCheckoutLineItemHeavyFragment[];
  purchaseStickerTokensLineItems: StorefrontCheckoutLineItemHeavyFragment[];
  stickerOrderLineItems: StickerOrderLineItemGroup[];
};

export function groupStickerLineItems(
  lineItems: StorefrontCheckoutLineItemHeavyFragment[] | undefined
): GroupStickerLineItemsResult {
  return (lineItems || []).reduce<GroupStickerLineItemsResult>(
    (result, lineItem) => {
      const orderId = getOrderId(lineItem);
      if (isStickerLineItem(lineItem) && orderId) {
        const existingGroup = result.stickerOrderLineItems.find(
          existingLineItem => existingLineItem.orderId === orderId
        );
        if (existingGroup) {
          existingGroup.lineItems.push(lineItem);
        } else {
          result.stickerOrderLineItems.push({
            fileId: orderId.split("-")[0],
            orderId,
            lineItems: [lineItem],
          });
        }
      } else if (
        isPurchaseStickerTokensProduct(
          lineItem.variant?.product as StorefrontProduct
        )
      ) {
        result.purchaseStickerTokensLineItems.push(lineItem);
      } else {
        result.standardLineItems.push(lineItem);
      }
      return result;
    },
    {
      standardLineItems: [],
      purchaseStickerTokensLineItems: [],
      stickerOrderLineItems: [],
    }
  );
}

const ORDER_ID_KEY = "Order ID";

export function getCustomAttributes(
  order: StickerOrder
): StorefrontAttributeInput[] {
  return [
    {
      key: "Map",
      value: order.file.name,
    },
    {
      key: ORDER_ID_KEY,
      value: order.id,
    },
  ];
}

function getOrderId(
  lineItem: Pick<StorefrontCheckoutLineItem, "customAttributes">
): string | undefined {
  const orderIdAttribute = lineItem.customAttributes.find(
    attribute => attribute.key === ORDER_ID_KEY
  );
  if (orderIdAttribute) {
    return orderIdAttribute.value ?? undefined;
  } else {
    return undefined;
  }
}

export function useStickerOrderLineItems() {
  const { checkout, loading, error } = useStorefrontCheckoutHeavy();
  const { stickerOrderLineItems } = useMemo(
    () =>
      groupStickerLineItems(checkout?.lineItems.edges.map(({ node }) => node)),
    [checkout]
  );

  const stickerTokensInCart = useMemo(() => {
    return (
      checkout?.lineItems.edges.reduce((tokens, { node: lineItem }) => {
        return (
          tokens +
          parseFloat(
            getAttributeValueByKey(
              lineItem.customAttributes,
              "Tokens Used",
              "0"
            )
          )
        );
      }, 0) ?? 0
    );
  }, [checkout?.lineItems]);

  return {
    stickerOrderLineItems,
    stickerTokensInCart,
    loading: loading,
    error: error,
  };
}

export function totalStickerTokensInCart(
  checkout: StorefrontCheckoutHeavyFragment
): number {
  return checkout.lineItems.edges.reduce(
    (total, { node: lineItem }) =>
      total +
      parseFloat(
        getAttributeValueByKey(lineItem.customAttributes, "Tokens Used", "0")
      ),
    0
  );
}

export function totalStickersWithoutTokenInCart(
  checkout: StorefrontCheckoutHeavyFragment
): number {
  return checkout.lineItems.edges.reduce(
    (total, { node: lineItem }) =>
      total +
      (isStickerLineItem(lineItem) &&
      getAttributeValueByKey(lineItem.customAttributes, "Tokens Used", "0") ===
        "0"
        ? lineItem.quantity
        : 0),
    0
  );
}
