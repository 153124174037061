import { createStore, combineReducers, applyMiddleware, Store } from "redux";
import thunk from "redux-thunk";
import {
  MapmakerDesignHeavyFragment,
  MapmakerFileHeavyFragment,
} from "../../../client/MapmakerApi";
import createPrintQuantitiesReducer from "../shared/printQuantitiesReducer";
import createPrintSizeSettingsReducer from "../shared/printSizeSettingsReducer";
import { MapmakerPrintServiceId } from "../PrintServiceIds";
import { getPrintingService } from "../PrintServices";
import { IRectangularPrintState } from "../shared/ReduxPrintStates";
import { WalgreensProduct } from "../../../client/MapmakerApi";
import walgreensCheckoutReducer, {
  WalgreensCheckoutState,
} from "./walgreensCheckoutReducer";
import createPrintOptionsReducer from "../shared/printOptionsReducer";

// Types
export type WalgreensPrintsStore = IRectangularPrintState & {
  products: WalgreensProduct[];
  checkout: WalgreensCheckoutState;
};

const immutableReducer = initialState => state => state || initialState || null;

export function createWalgreensPrintsStore(
  design: MapmakerDesignHeavyFragment,
  file: MapmakerFileHeavyFragment,
  products: WalgreensProduct[]
): Store<WalgreensPrintsStore> {
  const service = getPrintingService(MapmakerPrintServiceId.WALGREENS);

  return createStore(
    combineReducers<WalgreensPrintsStore>({
      // Base reducers
      design: immutableReducer(design),
      file: immutableReducer(file),
      printService: immutableReducer(service),
      allowBufferArea: immutableReducer(true),
      // Rectangular print reducers
      printQuantities: createPrintQuantitiesReducer(file),
      printOptions: createPrintOptionsReducer(true),
      printSizeSettings: createPrintSizeSettingsReducer(file, service),
      // Walgreens reducers
      products: immutableReducer(products),
      checkout: walgreensCheckoutReducer,
    }),
    applyMiddleware(thunk)
  );
}
