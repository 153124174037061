export function addKeysToValues(obj: object, keyField: string = "id") {
  return Object.fromEntries(
    Object.entries(obj).map(([key, val]) => [
      key,
      {
        [keyField]: key,
        ...val,
      },
    ])
  );
}

/**
 * Note: This method modifies the original object.
 */
export function removeUndefined<T extends object>(obj: T): T {
  for (let prop in obj) {
    if (obj[prop] === undefined) {
      delete obj[prop];
    }
  }
  return obj;
}
