import React, { useCallback, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";
import AuthBoxLayout from "./AuthBoxLayout";
import { useAuthContext } from "../AWSAuthContext";
import { AuthBoxState, useAuthBoxContext } from "./AuthBox";
import {
  NotAuthorizedExceptionName,
  PasswordResetRequiredExceptionName,
  UserNotConfirmedExceptionName,
  UserNotFoundExceptionName,
} from "../AWSAuthErrors";
import FormErrorMessage from "./shared/FormErrorMessage";
import UserNotFoundMessage from "./shared/UserNotFoundMessage";
import TextField from "./shared/TextField";
import { Button } from "@mapmaker/ui";

interface SignInFormValues {
  email: string;
  password: string;
}

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Enter your email"),
  password: Yup.string().required("Enter your password"),
});

export default function AuthBoxSignIn() {
  const { signIn, sendPasswordReset } = useAuthContext();
  const {
    setAuthBoxState,
    authenticationDetails,
    setAuthenticationDetails,
  } = useAuthBoxContext();
  const [signInError, setSignInError] = useState<string | React.ReactNode>();

  async function onSubmit(values: SignInFormValues) {
    try {
      setSignInError(undefined);
      setAuthenticationDetails({
        email: values.email,
        password: values.password,
      });
      await signIn(values.email, values.password);
    } catch (e) {
      if (e.name === UserNotConfirmedExceptionName) {
        await setAuthBoxState(AuthBoxState.VERIFY_EMAIL);
      } else if (e.name === PasswordResetRequiredExceptionName) {
        await sendPasswordReset(values.email);
        setAuthBoxState(AuthBoxState.RESET_PASSWORD);
      } else if (e.name === UserNotFoundExceptionName) {
        setSignInError(<UserNotFoundMessage email={values.email} />);
      } else if (e.name === NotAuthorizedExceptionName) {
        setSignInError(e.message);
      } else {
        Sentry.captureException(e);
        setSignInError(e.message);
      }
    }
  }

  return (
    <Formik
      initialValues={{
        email: authenticationDetails?.email || "",
        password: "",
      }}
      validationSchema={SignInSchema}
      onSubmit={onSubmit}
    >
      <AuthBoxSignUpForm signInError={signInError} />
    </Formik>
  );
}

function AuthBoxSignUpForm({ signInError }) {
  const { setAuthBoxState, setAuthenticationDetails } = useAuthBoxContext();
  const { isSubmitting, values, isValid } = useFormikContext<
    SignInFormValues
  >();

  const gotoSignUp = useCallback(() => {
    setAuthBoxState(AuthBoxState.SIGN_UP);
  }, [setAuthBoxState]);

  const gotoSendPasswordResetCode = useCallback(() => {
    setAuthenticationDetails({
      email: values.email,
    });

    setAuthBoxState(AuthBoxState.SEND_PASSWORD_RESET_CODE);
  }, [setAuthBoxState, values]);

  const RegisterInstead = (
    <>
      <span className="link" onClick={gotoSignUp}>
        Register
      </span>{" "}
      instead?
    </>
  );

  return (
    <AuthBoxLayout>
      <AuthBoxLayout.Header title="Log In" alternative={RegisterInstead} />
      <AuthBoxLayout.Form>
        <Form>
          <TextField
            name="email"
            label="Email"
            type="email"
            placeholder="bob@example.com"
            autoFocus
            autoComplete="email"
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            placeholder="your password"
            autoComplete="current-password"
          />
          <div className="buttons">
            <Button
              color="accent"
              fluid
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
            >
              Log In
            </Button>
            <FormErrorMessage message={signInError} />
            <p style={{ marginTop: "1em" }}>
              <span className="link" onClick={gotoSendPasswordResetCode}>
                Forgot Password?
              </span>
            </p>
          </div>
        </Form>
      </AuthBoxLayout.Form>
    </AuthBoxLayout>
  );
}
