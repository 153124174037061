import { OpeningFeature, OpeningInput } from "@mapmaker/core";
import { useMemo } from "react";
import { getPrintDPIFromOpeningImage } from "../../../printing/PrintDPI";
import { useProjectFile } from "../../useProjectState";
import useOpening, { useOpeningBase } from "./useOpening";

/**
 * The limits we place for the size an image can actually be scaled up or down in relation to the
 * size of the opening it is in.
 */
const MIN_RELATIVE_SCALE = 0.05;
const MAX_RELATIVE_SCALE = 10;

export function useOpeningImageBase(
  openingFeature: OpeningFeature,
  openingInput: OpeningInput,
  imageId: string
) {
  const { outputScale, design } = useProjectFile();
  const { printLayer } = useOpeningBase(openingFeature, openingInput);
  const depth = openingInput.images.findIndex(
    openingImage => openingImage.id === imageId
  );
  const image = openingInput.images[depth];
  /*
  const selected = openingInput.selectedImageId === image?.id;
  const highlighted = openingInput.highlightedImageId === image?.id;
  const lowlighted =
  openingInput.highlightedImageId &&
  openingInput.highlightedImageId !== image?.id;
  */
  const selected = false;
  const highlighted = false;
  const lowlighted = false;

  /* The scale of the image relative to the opening it is in. */
  const extremes = useMemo(() => {
    if (!image) {
      return {
        minWidth: 0,
        minHeight: 0,
        maxWidth: 0,
        maxHeight: 0,
      };
    }
    const imageAspectRatio = image.width / image.height;
    const printLayerAspectRatio =
      printLayer.bbox.width / printLayer.bbox.height;
    if (imageAspectRatio < printLayerAspectRatio) {
      // Use opening width as basis
      return {
        minWidth: printLayer.bbox.width * MIN_RELATIVE_SCALE,
        minHeight:
          (printLayer.bbox.width * MIN_RELATIVE_SCALE) / imageAspectRatio,
        maxWidth: printLayer.bbox.width * MAX_RELATIVE_SCALE,
        maxHeight:
          (printLayer.bbox.width * MAX_RELATIVE_SCALE) / imageAspectRatio,
      };
    } else {
      // Use opening height as basis
      return {
        minWidth:
          printLayer.bbox.height * MIN_RELATIVE_SCALE * imageAspectRatio,
        minHeight: printLayer.bbox.height * MIN_RELATIVE_SCALE,
        maxWidth:
          printLayer.bbox.height * MAX_RELATIVE_SCALE * imageAspectRatio,
        maxHeight: printLayer.bbox.height * MAX_RELATIVE_SCALE,
      };
    }
  }, [image, printLayer]);

  /* The DPI of the image when printed at it's current scale. */
  const printDpi = useMemo(() => {
    if (!image) {
      return 0;
    }
    return getPrintDPIFromOpeningImage(image, outputScale);
  }, [image]);

  return {
    image,
    depth,
    selected,
    highlighted,
    lowlighted,
    printDpi,
    ...extremes,
  };
}

export default function useOpeningImage(openingId: string, imageId: string) {
  const { openingInput, openingFeature } = useOpening(openingId);
  return useOpeningImageBase(openingFeature, openingInput, imageId);
}
