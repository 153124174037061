import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import { createContext, useCallback, useContext, } from "react";
import styles from "./ToggleButton.module.scss";
export default function ToggleButton(_a) {
    var { selected, onToggle, children } = _a, props = __rest(_a, ["selected", "onToggle", "children"]);
    return (_jsx("div", Object.assign({}, props, { className: clsx(styles.toggle, props.className, selected ? styles.selected : undefined), onClick: () => onToggle(!selected) }, { children: children })));
}
const ToggleGroupContext = createContext({
    selectedValue: undefined,
    setValue: () => { },
});
export function ToggleGroup({ value, onChange, children, }) {
    const setValue = useCallback((value) => {
        onChange(value);
    }, [onChange]);
    return (_jsx(ToggleGroupContext.Provider, Object.assign({ value: { selectedValue: value, setValue } }, { children: _jsx("div", Object.assign({ className: styles.toggleGroup }, { children: children })) })));
}
export function ToggleGroupButton(_a) {
    var { value, allowNoSelection = false, children } = _a, props = __rest(_a, ["value", "allowNoSelection", "children"]);
    const { selectedValue, setValue } = useContext(ToggleGroupContext);
    const onToggle = useCallback((toggled) => {
        const isSelected = selectedValue === value;
        if (!isSelected && toggled) {
            setValue(value);
        }
        else if (allowNoSelection && !toggled) {
            setValue(undefined);
        }
    }, [allowNoSelection, value, selectedValue, setValue]);
    return (_jsx(ToggleButton, Object.assign({ selected: selectedValue === value, onToggle: onToggle }, props, { children: children })));
}
