import { useMapmakerAppConfig } from "../../../client";
import { MapmakerDesign, MapmakerFile } from "../../../client/MapmakerApi";
import {
  getOutputTypeDetails,
  getSelectedOutputOption,
} from "../../project/shared/OutputTypeLibrary";
import MapmakerMessage from "../../shared/messages/MapmakerMessage";

type CustomSizeWarningProps = {
  file: Pick<MapmakerFile, "outputType" | "outputScale"> & {
    design: Pick<MapmakerDesign, "outputOptions" | "regionTypePlural">;
  };
};

export default function CustomSizeWarning({ file }: CustomSizeWarningProps) {
  const { gotoContact } = useMapmakerAppConfig();
  const selectedOutputOption = getSelectedOutputOption(
    file.outputType,
    file.outputScale,
    file.design.outputOptions
  );
  const outputTypeDetails = getOutputTypeDetails(file.outputType);

  // No need to show a warning if this is the standard size.
  if (selectedOutputOption) {
    return null;
  }
  return (
    <MapmakerMessage severity="warning">
      This project has a custom output scale of{" "}
      <strong>{Math.round(file.outputScale * 1000) / 10}%</strong> and an output
      type of <strong>{outputTypeDetails.name}</strong>. The printed{" "}
      {file.design.regionTypePlural} may not fit into our standard maps. If this
      is not expected please{" "}
      <span className="link" onClick={() => gotoContact()}>
        contact us
      </span>
      .
    </MapmakerMessage>
  );
}
