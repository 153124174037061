import { useMemo } from "react";
import { gql } from "@apollo/client";
import { Provider, useSelector } from "react-redux";
import Suspender from "react-suspender";
import WalgreensPrintsPanelContent from "./WalgreensPrintPanelContent";
import WalgreensPrintMainPanel from "./WalgreensPrintMainPanel";
import WalgreensCheckoutContextPanel from "./WalgreensCheckoutContextPanel";
import WalgreensSelectStore from "./WalgreensSelectStore";
import WalgreensGetCustomerInformation from "./WalgreensGetCustomerInformation";
import WalgreensOrderReview from "./WalgreensOrderReview";
import WalgreensOrderSubmitted from "./WalgreensOrderSubmitted";
import useWalgreensCheckout from "./useWalgreensCheckout";
import {
  createWalgreensPrintsStore,
  WalgreensPrintsStore,
} from "./walgreensPrintsStore";
import MessagePage from "../../shared/MessagePage";
import MapmakerLayout from "../../layout/MapmakerLayout";
import MapmakerTopBar from "../../layout/MapmakerTopBar";
import {
  MapmakerFileHeavyFragment,
  useWalgreensProductsQuery,
  WalgreensProduct,
} from "../../../client/MapmakerApi";
import { useProjectFile } from "../../project/useProjectState";
import "../../project/context/DeprecatedContextPanelLayout.css";
import "./MapmakerWalgreensPrints.css";
import ProjectToolbar from "../../project/ProjectToolbar";
import MapmakerButton from "../../shared/buttons/MapmakerButton";
import { Icon } from "semantic-ui-react";
import { Button } from "@mapmaker/ui";

gql`
  query walgreensProducts {
    walgreensProducts {
      productId
      productPrice
      productSize
    }
  }
`;

export default function MapmakerWalgreensPrints() {
  const {
    data: { walgreensProducts } = {},
    loading,
    error: productsError,
  } = useWalgreensProductsQuery();

  if (loading) {
    return <Suspender />;
  } else if (productsError) {
    return (
      <MessagePage icon="warning circle">
        Error communicating with Walgreens. Please try again in a few minutes.
      </MessagePage>
    );
  } else {
    return (
      <MapmakerWalgreensPrintsWithData walgreensProducts={walgreensProducts} />
    );
  }
}

type MapmakerWalgreensPrintsWithDataProps = {
  walgreensProducts: WalgreensProduct[];
};

function MapmakerWalgreensPrintsWithData({
  walgreensProducts,
}: MapmakerWalgreensPrintsWithDataProps) {
  const file = useProjectFile();

  const store = useMemo(
    () => createWalgreensPrintsStore(file.design, file, walgreensProducts),
    []
  );

  return (
    <Provider store={store}>
      <MapmakerWalgreensPrintsWithContext />
    </Provider>
  );
}

function MapmakerWalgreensPrintsWithContext() {
  const file = useSelector<WalgreensPrintsStore, MapmakerFileHeavyFragment>(
    store => store.file
  );
  const { currentStep, beginCheckout, canCheckout } = useWalgreensCheckout();
  const topBarProps = {
    file,
    breadcrumbs: [
      {
        label: "Print to Walgreens",
      },
    ],
  };

  if (currentStep === "create-prints") {
    return (
      <MapmakerLayout>
        <MapmakerLayout.TopBar>
          <MapmakerTopBar
            {...topBarProps}
            after={
              <Button
                color="accent"
                onClick={beginCheckout}
                disabled={!canCheckout}
              >
                Checkout{" "}
                <Icon name="arrow right" style={{ marginLeft: "0.5rem" }} />
              </Button>
            }
          />
        </MapmakerLayout.TopBar>
        <MapmakerLayout.Main
          className="deprecated-context-panel-layout"
          nav={<ProjectToolbar selected="output" />}
        >
          <MapmakerLayout.ContextPanel>
            <WalgreensPrintsPanelContent />
          </MapmakerLayout.ContextPanel>
          <MapmakerLayout.Content>
            <WalgreensPrintMainPanel />
          </MapmakerLayout.Content>
        </MapmakerLayout.Main>
      </MapmakerLayout>
    );
  } else {
    return (
      <MapmakerLayout>
        <MapmakerLayout.TopBar>
          <MapmakerTopBar {...topBarProps} />
        </MapmakerLayout.TopBar>
        <MapmakerLayout.Main
          className="deprecated-context-panel-layout"
          nav={<ProjectToolbar selected="output" />}
        >
          <MapmakerLayout.ContextPanel>
            <WalgreensCheckoutContextPanel />
          </MapmakerLayout.ContextPanel>
          <MapmakerLayout.Content>
            {(() => {
              switch (currentStep) {
                case "select-store":
                  return <WalgreensSelectStore />;
                case "get-customer-info":
                  return <WalgreensGetCustomerInformation />;
                case "review-order":
                  return <WalgreensOrderReview />;
                case "complete":
                  return <WalgreensOrderSubmitted />;
              }
            })()}
          </MapmakerLayout.Content>
        </MapmakerLayout.Main>
      </MapmakerLayout>
    );
  }
}
