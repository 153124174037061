import { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { RenderableInputProps } from ".";
import { useValidateRenderableInput } from "./renderableInputUtils";
import "./RenderableInput.css";

export type RenderableSelectInputProps = RenderableInputProps<
  "select",
  string,
  {
    options: {
      value: string;
      label: string;
    }[];
  }
>;

export default function RenderableSelectInput({
  id,
  name,
  description,
  validation,
  options,
}: RenderableSelectInputProps) {
  const formik = useFormikContext();
  const validate = useValidateRenderableInput(id, validation);

  useEffect(() => {
    if (!formik.values[id]) {
      formik.setFieldValue(id, options[0].value);
    }
  }, []);

  return (
    <Field type="" name={id} validate={validate}>
      {({ field, meta }) => (
        <div
          id="renderable-input"
          className={meta.touched && meta.error ? "error" : undefined}
        >
          <div className="above">
            <label>{name}</label>
          </div>
          <select {...field}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {description && <div className="description">{description}</div>}
          {meta.touched && meta.error && meta.value && (
            <div className="error">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
}
