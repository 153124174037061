import ToastMessages from "../../../shared/ToastMessages";
import { MapmakerProjectStore, useProjectSelector } from "../../projectStore";

function useToastMessages() {
  return useProjectSelector((state: MapmakerProjectStore) => state.messages);
}

export default function MapmakerProjectToastMessages() {
  const messages = useToastMessages();
  return <ToastMessages messages={messages} />;
}
