import MapmakerConfig from "@mapmaker/config";
import type { IPrintService } from "../PrintServices";
import { PrintSizeModeId, idsToModes } from "../../../lib/prints/printSizes";
import { MapmakerPrintServiceId } from "../PrintServiceIds";

const DownloadPrintService: IPrintService = {
  id: MapmakerPrintServiceId.DOWNLOAD,
  name: "Download Files",
  image: `${MapmakerConfig.staticUrl}/images/printing-services/DOWNLOAD-illustration.png`,
  imageSmall: `${MapmakerConfig.staticUrl}/images/printing-services/DOWNLOAD-illustration-no-padding.png`,
  directive: "Download Files",
  description:
    "Download image files to print on your own.",
    detailedDescription: (
      <p>
        Download the image files as JPEGs and print from a home printer or send them to any printing service you choose. Follow the instructions to ensure they print at the correct size, and cut them out with scissors before adding them to your map.
      </p>
    ),
  
  printSizeModes: idsToModes([
    PrintSizeModeId.STANDARD_PHOTO,
    PrintSizeModeId.LETTER,
    PrintSizeModeId.LEGAL,
    PrintSizeModeId.PHOTO_4x6,
    PrintSizeModeId.PHOTO_5x7,
    PrintSizeModeId.PHOTO_8x10,
    PrintSizeModeId.PHOTO_11x14,
    PrintSizeModeId.PHOTO_11x17,
    PrintSizeModeId.PHOTO_16x20,
    PrintSizeModeId.A1,
    PrintSizeModeId.A2,
    PrintSizeModeId.A3,
    PrintSizeModeId.A4,
    PrintSizeModeId.FULL_SIZE,
  ]),
};
export default DownloadPrintService;
