import React from "react";
import { useMapmakerSvgContext } from "../../MapmakerSvgContext";

type WatermarkProps = {
  x?: number;
  y?: number;
  height?: number;
  fill?: string;
  opacity?: number;
};

export default function Watermark(props: WatermarkProps) {
  const { businessId } = useMapmakerSvgContext();
  switch (businessId) {
    case "pm":
      return <PMWatermark {...props} />;
    case "tbl":
      return <TBLWatermark {...props} />;
  }
}

function PMWatermark({
  x,
  y,
  height = 25,
  fill = "#FFFFFF",
  opacity = 1,
}: WatermarkProps) {
  height = height * 2.7;
  // The base path is 100 x 25 so this will preserve the aspect ratio.
  const width = height / 0.25;
  const scale = height / 25;
  return (
    <g
      style={{
        fill,
        opacity,
        transform: `translate(${x - width / 2}px, ${y -
          height / 2}px) scale(${scale})`,
      }}
    >
      <path
        d="M29.338,10.132c0.173-0.602-6.539-8.446-6.948-9.069c-2.458-2.679-5.124,0.304-4.217,2.366
        C12.806,4.325,7.535,7.214,4.264,11.613c-1.897-2.964-5.396,0.629-3.902,2.873c0,0,6.074,8.223,6.074,8.223
        c0.051,0.069,0.108,0.119,0.168,0.158c1.597,2.405,4.113,1.605,5.521-0.265c3.186-4.647,7.59-7.521,13.252-8.206
        C27.525,14.195,30.346,12.954,29.338,10.132z M21.505,1.849l4.892,6.066c-0.904,0.148-1.723,0.79-2.069,1.545l-4.952-6.31
        C18.426,1.936,20.215,0.443,21.505,1.849z M6.136,20.31l-4.804-6.502c-1.192-1.737,1.382-2.164,1.783-1.744l5.149,6.62
        C7.389,18.824,6.495,19.43,6.136,20.31z M25.241,13.221c-3.816,0.488-7.115,1.855-9.882,4.091l-1.756-2.02
        c6.917,0.966,8.265-7.794,0.981-8.138c-3.624-0.07-6.279,3.446-4.172,6.276c-0.001,0.001-0.016,0.012-0.016,0.012l3.881,4.814
        c-1.085,1.02-2.115,2.236-2.984,3.512c-0.821,1.063-2.155,2.075-3.347,0.855c-2.063-2.11,0.537-3.186,1.36-2.599
        c0.042,0.058,0.095,0.113,0.147,0.152c0.144,0.223,0.126,0.568-0.179,1.049c-0.422,0.667,0.578,1.3,1,0.633
        c0.686-1.086,0.547-1.862-0.009-2.53c0,0-5.246-6.744-5.246-6.744c3.157-4.424,8.506-7.324,13.917-8.075l5.487,6.991
        c0.45,0.728,1.134,0.867,2.091,0.788c0.785-0.087,0.654-1.264-0.131-1.177c-0.575,0.051-0.828-0.015-1.065-0.388
        c-0.359-0.957,1.873-3.079,2.904-0.194C29.023,12.771,25.678,13.186,25.241,13.221z M11.65,13.046
        c-1.973-2.008,0.076-4.759,2.934-4.692c2.05,0,3.717,1.306,3.717,2.911C18.35,14.136,13.477,15.119,11.65,13.046z M14.663,9.378
        c-1.248,0-2.26,0.845-2.26,1.887s1.012,1.887,2.26,1.887c1.248,0,2.26-0.845,2.26-1.887S15.911,9.378,14.663,9.378z M15.048,12.595
        c-0.148,0.038-0.302,0.057-0.456,0.057c-0.889,0-1.73-0.721-1.73-1.484c0-0.363,0.266-0.796,0.594-0.964
        c0.156-0.081,0.346-0.019,0.426,0.137c0.08,0.155,0.019,0.346-0.137,0.426c-0.117,0.06-0.25,0.274-0.25,0.402
        c0,0.414,0.564,0.851,1.097,0.851c0.101,0,0.202-0.013,0.299-0.037c0.17-0.043,0.342,0.059,0.385,0.228
        C15.319,12.379,15.217,12.552,15.048,12.595z M36.483,10.147c-0.857,0-1.476,0.356-1.867,0.957h-0.022l-0.044-0.845h-0.857
        c0.022,0.468,0.043,0.978,0.043,1.609v5.337h0.944v-2.679h0.022c0.315,0.489,0.922,0.774,1.617,0.774
        c1.237,0,2.388-0.876,2.388-2.648C38.708,11.155,37.753,10.147,36.483,10.147z M36.158,14.598c-0.684,0-1.259-0.428-1.432-1.049
        c-0.022-0.112-0.044-0.234-0.044-0.367v-0.846c0-0.122,0.033-0.255,0.065-0.367c0.185-0.672,0.792-1.11,1.444-1.11
        c1.009,0,1.563,0.846,1.563,1.834C37.753,13.823,37.167,14.598,36.158,14.598z M39.37,15.188h0.955v-2.974
        c0-0.173,0.011-0.306,0.065-0.428c0.174-0.489,0.662-0.897,1.281-0.897c0.901,0,1.216,0.672,1.216,1.467v2.831h0.955v-2.934
        c0-1.701-1.129-2.108-1.834-2.108c-0.358,0-0.695,0.102-0.977,0.255c-0.293,0.153-0.532,0.377-0.684,0.631h-0.022V7.957H39.37
        V15.188z M47.075,10.147c-1.454,0-2.605,0.968-2.605,2.617c0,1.559,1.096,2.536,2.518,2.536c1.27,0,2.616-0.794,2.616-2.618
        C49.603,11.175,48.583,10.147,47.075,10.147z M47.031,14.628c-0.933,0-1.595-0.815-1.595-1.895c0-0.937,0.488-1.915,1.617-1.915
        c1.129,0,1.574,1.059,1.574,1.894C48.627,13.823,47.943,14.628,47.031,14.628z M50.482,9.077v1.182h-0.814v0.682h0.814v2.689
        c0,0.581,0.098,1.019,0.369,1.283c0.228,0.244,0.586,0.377,1.031,0.377c0.369,0,0.662-0.051,0.846-0.122l-0.043-0.672
        c-0.119,0.031-0.304,0.061-0.553,0.061c-0.532,0-0.716-0.346-0.716-0.957v-2.658h1.367v-0.682h-1.367V8.843L50.482,9.077z
         M55.474,10.147c-1.454,0-2.605,0.968-2.605,2.617c0,1.559,1.096,2.536,2.518,2.536c1.27,0,2.616-0.794,2.616-2.618
        C58.003,11.175,56.983,10.147,55.474,10.147z M55.431,14.628c-0.933,0-1.595-0.815-1.595-1.895c0-0.937,0.488-1.915,1.617-1.915
        s1.574,1.059,1.574,1.894C57.026,13.823,56.342,14.628,55.431,14.628z M58.664,15.188h0.933v-2.974c0-0.153,0.022-0.306,0.076-0.438
        c0.152-0.448,0.586-0.896,1.183-0.896c0.727,0,1.096,0.57,1.096,1.355v2.954h0.933v-3.046c0-0.163,0.033-0.326,0.076-0.448
        c0.163-0.438,0.586-0.815,1.129-0.815c0.771,0,1.139,0.57,1.139,1.517v2.791h0.933v-2.903c0-1.711-1.031-2.139-1.726-2.139
        c-0.499,0-0.846,0.122-1.161,0.346c-0.217,0.153-0.423,0.367-0.586,0.642h-0.022c-0.228-0.581-0.771-0.988-1.487-0.988
        c-0.868,0-1.356,0.438-1.65,0.906H59.5l-0.043-0.794h-0.836c0.033,0.407,0.043,0.825,0.043,1.334V15.188z M70.862,14.007v-1.844
        c0-0.988-0.391-2.016-1.997-2.016c-0.662,0-1.292,0.173-1.726,0.438l0.217,0.591c0.369-0.224,0.879-0.367,1.367-0.367
        c1.074,0,1.194,0.733,1.194,1.141v0.102c-2.03-0.01-3.158,0.642-3.158,1.834c0,0.713,0.543,1.416,1.606,1.416
        c0.749,0,1.313-0.346,1.606-0.733h0.032l0.076,0.621h0.868C70.884,14.852,70.862,14.435,70.862,14.007z M69.94,13.528
        c0,0.091-0.022,0.193-0.055,0.285c-0.152,0.417-0.586,0.825-1.27,0.825c-0.488,0-0.901-0.275-0.901-0.855
        c0-0.958,1.183-1.131,2.225-1.11V13.528z M74.606,10.147c-0.857,0-1.476,0.356-1.867,0.957h-0.022l-0.043-0.845h-0.857
        c0.022,0.468,0.043,0.978,0.043,1.609v5.337h0.944v-2.679h0.022c0.315,0.489,0.923,0.774,1.617,0.774
        c1.237,0,2.388-0.876,2.388-2.648C76.831,11.155,75.876,10.147,74.606,10.147z M74.28,14.598c-0.684,0-1.259-0.428-1.433-1.049
        c-0.022-0.112-0.043-0.234-0.043-0.367v-0.846c0-0.122,0.033-0.255,0.065-0.367c0.185-0.672,0.792-1.11,1.443-1.11
        c1.01,0,1.563,0.846,1.563,1.834C75.876,13.823,75.29,14.598,74.28,14.598z M77.123,14.954c0.369,0.204,0.901,0.336,1.476,0.336
        c1.248,0,1.964-0.611,1.964-1.477c0-0.734-0.466-1.161-1.378-1.487c-0.684-0.245-0.999-0.428-0.999-0.835
        c0-0.367,0.315-0.672,0.879-0.672c0.488,0,0.868,0.163,1.075,0.285l0.239-0.652c-0.293-0.163-0.76-0.305-1.292-0.305
        c-1.129,0-1.813,0.652-1.813,1.446c0,0.591,0.445,1.08,1.389,1.395c0.705,0.245,0.977,0.479,0.977,0.907
        c0,0.407-0.326,0.733-1.02,0.733c-0.478,0-0.977-0.184-1.259-0.357L77.123,14.954z M81.54,15.3c0.402,0,0.651-0.275,0.651-0.642
        c0-0.377-0.26-0.642-0.64-0.642S80.9,14.282,80.9,14.659C80.9,15.025,81.16,15.3,81.54,15.3z M86.413,14.333
        c-0.272,0.112-0.63,0.245-1.172,0.245c-1.042,0-1.823-0.703-1.823-1.844c0-1.028,0.651-1.864,1.856-1.864
        c0.521,0,0.879,0.112,1.107,0.234l0.217-0.693c-0.261-0.122-0.759-0.255-1.324-0.255c-1.715,0-2.822,1.1-2.822,2.618
        c0,1.507,1.031,2.516,2.616,2.516c0.706,0,1.259-0.163,1.509-0.285L86.413,14.333z M89.31,10.147c-1.454,0-2.605,0.968-2.605,2.617
        c0,1.559,1.096,2.536,2.518,2.536c1.27,0,2.616-0.794,2.616-2.618C91.839,11.175,90.819,10.147,89.31,10.147z M89.267,14.628
        c-0.933,0-1.595-0.815-1.595-1.895c0-0.937,0.488-1.915,1.617-1.915c1.129,0,1.574,1.059,1.574,1.894
        C90.862,13.823,90.178,14.628,89.267,14.628z M92.501,15.188h0.933v-2.974c0-0.153,0.022-0.306,0.076-0.438
        c0.152-0.448,0.586-0.896,1.183-0.896c0.727,0,1.096,0.57,1.096,1.355v2.954h0.933v-3.046c0-0.163,0.033-0.326,0.076-0.448
        c0.163-0.438,0.586-0.815,1.129-0.815c0.771,0,1.139,0.57,1.139,1.517v2.791H100v-2.903c0-1.711-1.031-2.139-1.726-2.139
        c-0.499,0-0.846,0.122-1.161,0.346c-0.217,0.153-0.423,0.367-0.586,0.642h-0.022c-0.228-0.581-0.771-0.988-1.487-0.988
        c-0.868,0-1.356,0.438-1.65,0.906h-0.032l-0.043-0.794h-0.836c0.033,0.407,0.043,0.825,0.043,1.334V15.188z"
      />
    </g>
  );
}

function TBLWatermark({
  x,
  y,
  height = 5.74,
  fill = "#FFFFFF",
  opacity = 1,
}: WatermarkProps) {
  const width = height / 0.0573;
  const scale = height / 5.74;
  return (
    <g
      style={{
        opacity,
        transform: `translate(${x - width / 2}px, ${y -
          height / 2}px) scale(${scale})`,
      }}
    >
      <path
        id="White_Text"
        style={{ fill }}
        d="M89.056,5.416c0-0.188,0.127-0.321,0.305-0.321c0.178,0,0.301,0.132,0.301,0.321
	c0,0.183-0.117,0.321-0.306,0.321C89.178,5.737,89.056,5.599,89.056,5.416z M92.63,5.569c-0.163,0.081-0.489,0.163-0.906,0.163
	c-0.968,0-1.695-0.611-1.695-1.736c0-1.074,0.728-1.802,1.792-1.802c0.428,0,0.698,0.092,0.815,0.153l-0.107,0.361
	c-0.168-0.082-0.407-0.143-0.692-0.143c-0.804,0-1.339,0.514-1.339,1.415c0,0.84,0.484,1.38,1.319,1.38
	c0.27,0,0.545-0.056,0.723-0.143L92.63,5.569z M96.012,3.929c0,1.181-0.718,1.807-1.593,1.807c-0.906,0-1.542-0.702-1.542-1.741
	c0-1.09,0.677-1.802,1.593-1.802C95.406,2.194,96.012,2.911,96.012,3.929z M93.349,3.986c0,0.733,0.397,1.39,1.095,1.39
	c0.703,0,1.099-0.646,1.099-1.425c0-0.682-0.356-1.395-1.094-1.395C93.716,2.555,93.349,3.232,93.349,3.986z M99.481,4.174
	c-0.025-0.479-0.056-1.054-0.051-1.482h-0.015c-0.117,0.402-0.259,0.83-0.432,1.303L98.376,5.66H98.04l-0.555-1.634
	c-0.163-0.484-0.301-0.926-0.397-1.334h-0.01c-0.01,0.428-0.036,1.003-0.066,1.517L96.92,5.681h-0.423l0.24-3.431h0.565l0.585,1.66
	c0.143,0.423,0.259,0.799,0.346,1.156h0.015c0.087-0.346,0.209-0.723,0.361-1.156l0.611-1.66h0.565L100,5.681h-0.433L99.481,4.174z
	 M71.525,5.077h2.531v0.491h-3.083v-5.43h0.552V5.077z M75.109,5.568h-0.606l2.347-5.43h0.537l2.332,5.43h-0.614l-0.598-1.427H75.7
	L75.109,5.568z M75.907,3.636H78.3l-1.197-2.884L75.907,3.636z M80.252,0.138h1.618c0.828,0,1.749,0.299,1.749,1.388
	c0,0.568-0.383,1.035-0.959,1.166v0.015c0.706,0.077,1.227,0.568,1.227,1.358c0,0.905-0.775,1.503-2.025,1.503h-1.611V0.138z
	 M80.805,2.516h1.112c0.529,0,1.15-0.23,1.15-0.951c0-0.69-0.591-0.951-1.304-0.951h-0.959V2.516z M80.805,5.093h1.028
	c0.797,0,1.496-0.299,1.496-1.058c0-0.713-0.514-1.066-1.396-1.066h-1.127V5.093z M87.761,1.012c-0.23-0.33-0.606-0.545-1.12-0.545
	c-0.537,0-1.158,0.322-1.158,1.02c0,0.69,0.575,0.866,1.181,1.066c0.767,0.253,1.611,0.499,1.611,1.588s-0.913,1.565-1.772,1.565
	c-0.659,0-1.319-0.268-1.71-0.805l0.475-0.353c0.238,0.383,0.675,0.682,1.243,0.682c0.56,0,1.204-0.353,1.204-1.051
	c0-0.759-0.637-0.951-1.327-1.166c-0.721-0.222-1.457-0.537-1.457-1.519C84.931,0.445,85.867,0,86.664,0
	c0.728,0,1.273,0.307,1.534,0.66L87.761,1.012z M9.931,5.568H9.378V0.629H7.561V0.138h4.188v0.491H9.931V5.568z M12.561,0.138h0.552
	v2.355h2.961V0.138h0.552v5.43h-0.552V2.999h-2.961v2.569h-0.552V0.138z M18.037,3.544V0.138h0.552v3.344
	c0,0.874,0.376,1.733,1.48,1.733c1.104,0,1.48-0.859,1.48-1.733V0.138h0.552v3.405c0,0.905-0.406,2.163-2.032,2.163
	S18.037,4.448,18.037,3.544z M27.364,4.763h0.015V0.138h0.552v5.43h-0.69l-3.16-4.671h-0.015v4.671h-0.552v-5.43h0.69L27.364,4.763z
	 M29.373,0.138h1.802c1.358,0,2.853,0.905,2.853,2.715c0,1.81-1.496,2.715-2.853,2.715h-1.802V0.138z M29.925,5.077h1.097
	c1.634,0,2.424-1.104,2.424-2.224s-0.79-2.224-2.424-2.224h-1.097V5.077z M35.731,5.077h2.907v0.491h-3.459v-5.43h3.367v0.491
	h-2.815V2.5h2.631v0.483h-2.631V5.077z M40.271,5.568h-0.552v-5.43h1.595c1.074,0,1.856,0.391,1.856,1.45
	c0,0.805-0.598,1.312-1.388,1.396l1.588,2.585h-0.675L41.192,3.03h-0.92V5.568z M40.271,2.554h0.959
	c0.882,0,1.388-0.322,1.388-0.966c0-0.713-0.544-0.974-1.365-0.974h-0.982V2.554z"
      />
      <path
        id="Green_Text"
        style={{ fill: "#87CD21" }}
        d="M44.313,0.138h1.618c0.828,0,1.749,0.299,1.749,1.388
	c0,0.568-0.383,1.035-0.959,1.166v0.015c0.706,0.077,1.227,0.568,1.227,1.358c0,0.905-0.775,1.503-2.025,1.503h-1.611V0.138z
	 M44.865,2.516h1.112c0.529,0,1.15-0.23,1.15-0.951c0-0.69-0.591-0.951-1.304-0.951h-0.959V2.516z M44.865,5.093h1.028
	c0.797,0,1.496-0.299,1.496-1.058c0-0.713-0.514-1.066-1.396-1.066h-1.127V5.093z M49.16,3.544V0.138h0.552v3.344
	c0,0.874,0.376,1.733,1.48,1.733c1.104,0,1.48-0.859,1.48-1.733V0.138h0.552v3.405c0,0.905-0.407,2.163-2.032,2.163
	C49.567,5.706,49.16,4.448,49.16,3.544z M58.486,4.763h0.016V0.138h0.552v5.43h-0.69l-3.16-4.671h-0.015v4.671h-0.552v-5.43h0.69
	L58.486,4.763z M64.346,4.763h0.016V0.138h0.552v5.43h-0.69l-3.16-4.671h-0.015v4.671h-0.552v-5.43h0.69L64.346,4.763z
	 M68.219,5.568h-0.552V3.229l-2.032-3.091h0.69l1.634,2.631l1.649-2.631h0.644l-2.032,3.091V5.568z"
      />
      <path
        id="Bolt"
        style={{ fill: "#87CD21" }}
        d="M5.274,0.035H0.286C0.128,0.035,0,0.163,0,0.321v4.988c0,0.158,0.128,0.286,0.286,0.286
	h4.988c0.158,0,0.286-0.128,0.286-0.286V0.321C5.559,0.163,5.432,0.035,5.274,0.035z M2.848,3.84
	C2.498,3.683,2.625,3.066,2.349,2.969c0,0-0.001,0-0.001,0c-0.059-0.02-0.127-0.013-0.181,0.019C1.551,3.336,0.709,5.17,0.526,5.057
	c-0.21-0.207,1.88-4.168,2.212-3.921C3.087,1.298,2.96,1.911,3.236,2.033c0,0,0.001,0,0.001,0c0.059,0.027,0.126,0.03,0.181,0.007
	c0.615-0.275,1.458-1.664,1.642-1.408C5.269,0.813,3.18,3.887,2.848,3.84z"
      />
    </g>
  );
}
