import { Button } from "@mapmaker/ui";
import { PropsWithChildren } from "react";
import { BiCheckCircle } from "@react-icons/all-files/bi/BiCheckCircle";
import { ModalActions, ModalContent } from "semantic-ui-react";
import MapmakerModal from "./MapmakerModal";
import "./MapmakerSuccessModal.css";

type MapmakerSuccessModalProps = {
  open?: boolean;
  onClose(): any;
  acknowledgeButtonLabel?: string;
};

export function MapmakerSuccessModal({
  open = true,
  acknowledgeButtonLabel = "Done",
  onClose,
  children = <h1>Success!</h1>,
}: PropsWithChildren<MapmakerSuccessModalProps>) {
  return (
    <MapmakerModal open={open}>
      <ModalContent id="mapmaker-success-modal">
        <div className="main-icon">
          <BiCheckCircle
            className="icon"
            size="200px"
            color="var(--mm-accent-color)"
          />
          {children}
        </div>
      </ModalContent>
      <ModalActions>
        <Button color="accent" onClick={onClose}>
          {acknowledgeButtonLabel}
        </Button>
      </ModalActions>
    </MapmakerModal>
  );
}
