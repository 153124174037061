import React from "react";
import { useFormikContext } from "formik";
import "./FieldErrorMessage.css";

interface IFieldErrorMessageProps {
  name: string;
}

export default function FieldErrorMessage({ name }: IFieldErrorMessageProps) {
  const { errors, touched } = useFormikContext();
  const display = errors[name] && touched[name];
  return (
    <div
      id="field-error-message"
      className={`error ${display ? "visible" : "hidden"}`}
    >
      {errors[name]}
    </div>
  );
}
