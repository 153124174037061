import MapmakerLayout from "../../layout/MapmakerLayout";
import MapmakerTopBar from "../../layout/MapmakerTopBar";
import ProjectToolbar from "../ProjectToolbar";
import { useProjectFile } from "../useProjectState";
import { OpeningSuggestionList } from "./OpeningSuggestionList";
import { useMapmakerAppConfig } from "../../../client";
import { getOpeningFeature } from "@mapmaker/core";
import { useParams } from "react-router-dom";
import { Button, Label, ToggleButton } from "@mapmaker/ui";
import useProjectOpeningSuggestions from "../features/opening/suggestions/useOpeningSuggestions";
import { useAuthContext } from "@tbl/aws-auth";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash";
import { FaFilter } from "@react-icons/all-files/fa/FaFilter";
import { FaLightbulb } from "@react-icons/all-files/fa/FaLightbulb";
import { useAddToastMessage } from "../shared/toasts/useProjectToastMessages";
import MapmakerProjectToastMessages from "../shared/toasts/MapmakerProjectToastMessages";
import useSessionStorage from "react-use/lib/useSessionStorage";
import "./CollaboratorSharingView.css";

export default function CollaboratorSharingView() {
  const { identityId } = useAuthContext();
  const file = useProjectFile();
  const { gotoEditFile } = useMapmakerAppConfig();
  const { openingId } = useParams<{ openingId: string }>();
  const selectedOpening = getOpeningFeature(file.design, openingId);
  const [includeSubmitted, setIncludeSubmitted] = useSessionStorage(
    "collaborator-sharing-view.include-submitted",
    true
  );
  const toast = useAddToastMessage();

  const allSuggestions = useProjectOpeningSuggestions({
    includeCurrentlyInMap: true,
    includeHidden: true,
    includeSubmitted: true,
    openingId,
    suggesterId: identityId,
  });
  const allMatchingSuggestions = useProjectOpeningSuggestions({
    includeCurrentlyInMap: true,
    includeHidden: true,
    includeSubmitted,
    openingId,
    suggesterId: identityId,
  });

  function SuggestionsHeader() {
    return (
      <div className="header">
        <div className="title">
          <h3>Suggestions</h3>
          {selectedOpening && (
            <Label
              onClose={() => {
                gotoEditFile(file.id, "collaborate");
              }}
              className="label"
            >
              for <strong>{selectedOpening.name}</strong>
            </Label>
          )}
        </div>
        <ToggleButton
          onToggle={() => {
            if (includeSubmitted) {
              toast.addToastInfo(
                {
                  icon: FaEyeSlash,
                  title: "Filter Active",
                  content: "Suggestions you have submitted are not shown.",
                },
                "suggestion-list.filter",
                6
              );
            } else {
              toast.addToastInfo(
                {
                  icon: FaEye,
                  title: "Filter Removed",
                  content:
                    "All suggestions are shown including those you have already submitted.",
                },
                "suggestion-list.filter",
                6
              );
            }
            setIncludeSubmitted(!includeSubmitted);
          }}
          selected={!includeSubmitted}
        >
          <FaFilter />
        </ToggleButton>
      </div>
    );
  }

  function NoMatchingSuggestions() {
    return (
      <div className="no-suggestions">
        <FaLightbulb fontSize={80} />
        {allSuggestions.length === 0 ? (
          <>
            <h3>You haven't made any suggestions yet</h3>
            <p>
              To get started go to the{" "}
              <span className="link" onClick={() => gotoEditFile(file.id)}>
                main project view
              </span>{" "}
              and click any {file.design.regionType}.
            </p>
          </>
        ) : (
          <>
            <h3>All suggestions have been submitted.</h3>
            <Button onClick={() => setIncludeSubmitted(true)}>
              Show Submitted Suggestions
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <MapmakerLayout>
      <MapmakerLayout.TopBar>
        <MapmakerTopBar breadcrumbs={[{ label: "Suggest" }]} />
      </MapmakerLayout.TopBar>
      <MapmakerLayout.Main nav={<ProjectToolbar selected="collaborate" />}>
        <MapmakerProjectToastMessages />
        <div id="collaborator-sharing-view">
          <SuggestionsHeader />
          {allMatchingSuggestions.length === 0 && <NoMatchingSuggestions />}
          <OpeningSuggestionList
            suggestions={allMatchingSuggestions}
            onClick={suggestion =>
              gotoEditFile(
                suggestion.fileId,
                "collaborate",
                suggestion.openingId,
                suggestion.id
              )
            }
          />
        </div>
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}
