import * as Sentry from "@sentry/react";
import { IRectangularPrintActionButtonProps } from "../shared/RectangularPrintCard";
import { Icon, SemanticSIZES } from "semantic-ui-react";
import {
  CreateRectangularPrintsRequest,
  CreateRectangularPrintsResponse,
} from "../../../client/MapmakerApi";
import { useSelector } from "react-redux";
import { DownloadPrintsStore } from "./downloadPrintsStore";
import MapmakerButton from "../../shared/buttons/MapmakerButton";
import { useCreateRectangularPrintsForDownloadMutation } from "../../../client/MapmakerApi";
import { gql } from "@apollo/client";

gql`
  mutation createRectangularPrintsForDownload(
    $input: CreateRectangularPrintsRequest
  ) {
    createRectangularPrints(input: $input) {
      prints {
        key
        url
      }
    }
  }
`;

type ImageDownloadButtonProps = IRectangularPrintActionButtonProps & {
  label?: string;
  size?: SemanticSIZES;
};

export default function ImageDownloadButton({
  printLayouts,
  label = "Download",
}: ImageDownloadButtonProps) {
  //const [createPrints, { loading, error }] = useMutation(CREATE_PRINTS);
  const [createPrints, { loading, error }] =
    useCreateRectangularPrintsForDownloadMutation();
  const { file, printOptions, downloadOptions } = useSelector(
    (state: DownloadPrintsStore) => ({
      file: state.file,
      printOptions: state.printOptions,
      downloadOptions: state.downloadOptions,
    })
  );

  const onClick = async () => {
    const input: CreateRectangularPrintsRequest = {
      fileId: file.id,
      format: downloadOptions.format,
      dpi: 300,
      printOptions,
      printLayouts,
    };
    try {
      const result = await createPrints({
        variables: {
          input,
        },
      });
      const response = result.data
        .createRectangularPrints as CreateRectangularPrintsResponse;
      response.prints.forEach((print, i) => downloadResult(print.url));
    } catch (e) {
      // Error will be caught by apollo hook, but we do want to record it.
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <MapmakerButton
        color="accent"
        size="small"
        loading={loading}
        onClick={onClick}
      >
        <Icon name="download" />
        {label}
      </MapmakerButton>
      {error ? (
        <div className="error">Error downloading image, please try again.</div>
      ) : null}
    </>
  );
}

function downloadResult(url) {
  // Opens the image link which triggers the browser to download the image
  // Note: Safari doesn't respect the content-disposition header when opening a URL and
  // opens it up right in the webpage.  Users then need to either drag-drop it to their
  // file system or use a 3rd party app.  A better solution would be to make a page to
  // display the photo without leaving the website and show instructions there.
  window.location = url;
}
