import gql from "graphql-tag";
import { ReactNode, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSanityContext } from "../SanityContext";
import { useGetReferenceLazyQuery } from "../../../../client/MapmakerApi";
import Suspender from "react-suspender";

gql`
  query getReference($ref: String!) {
    sanityReference(input: { ref: $ref }) {
      id
      slug
    }
  }
`;

export type InternalLinkSerializerProps = {
  mark: {
    reference: {
      _ref: string;
    };
  };
  children: ReactNode;
};

export default function InternalLinkSerializer({
  mark,
  children,
}: InternalLinkSerializerProps) {
  const navigate = useNavigate();
  const { urlCreator } = useSanityContext();
  const [getReference, { loading }] = useGetReferenceLazyQuery({
    variables: {
      ref: mark.reference._ref,
    },
  });
  const onClick = useCallback(async () => {
    const { data } = await getReference();
    const url = urlCreator(data?.sanityReference.slug);
    if (url) {
      navigate(url);
    }
  }, [urlCreator, getReference, history]);

  if (loading) {
    return <Suspender />;
  }

  return (
    <span className="link" style={{ fontWeight: "normal" }} onClick={onClick}>
      {children}
    </span>
  );
}
