import { OpeningFeature, OpeningLayerName, SVGNode } from "@mapmaker/core";

export type LayerPriority = OpeningLayerName | OpeningLayerName[] | SVGNode;

export function getLayerByPriority(
  opening: OpeningFeature,
  layerOrLayers: LayerPriority | SVGNode
): SVGNode {
  if (Array.isArray(layerOrLayers)) {
    // An array of strings means find the first one that is defined.
    const layerNameToUse = layerOrLayers.find(
      layerName => opening.layout[layerName]
    );
    return layerNameToUse ? opening.layout[layerNameToUse] : null;
  } else if (typeof layerOrLayers === "string") {
    // A single string means use that layer
    return opening.layout[layerOrLayers];
  } else {
    // An SVG node means use that node.
    return layerOrLayers;
  }
}
