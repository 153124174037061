import { Button, IButtonProps } from "@mapmaker/ui";
import { PropsWithChildren, useState } from "react";
import { FaUserPlus } from "@react-icons/all-files/fa/FaUserPlus";
import AddFileAccessModal from "./AddFileAccessModal";

type FileAccessInviteButtonProps = IButtonProps;

export function FileAccessInviteButton({
  children = "Invite",
  ...buttonProps
}: PropsWithChildren<FileAccessInviteButtonProps>) {
  const [addFileAccessModalOpen, setAddFileAccessModalOpen] = useState(false);

  return (
    <>
      <AddFileAccessModal
        open={addFileAccessModalOpen}
        onClose={() => setAddFileAccessModalOpen(false)}
      />
      <Button
        /* ID for GTM tracking. Do not change. */
        id="begin-invite-button"
        color="accent"
        onClick={() => setAddFileAccessModalOpen(true)}
        {...buttonProps}
      >
        <FaUserPlus />
        <span>{children}</span>
      </Button>
    </>
  );
}
