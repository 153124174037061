import React, { useCallback, useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../AWSAuthContext";
import { useAuthBoxContext } from "./AuthBox";
import AuthBoxLayout from "./AuthBoxLayout";
import FormErrorMessage from "./shared/FormErrorMessage";
import TextField from "./shared/TextField";
import { Button } from "@mapmaker/ui";

interface VerifyEmailFormValues {
  verificationCode: string;
}

const VerifyEmailSchema = Yup.object().shape({
  verificationCode: Yup.string().required("Enter your verification code"),
});

export default function AuthBoxVerifyEmail() {
  const { verifyEmail } = useAuthContext();
  const { authenticationDetails, presetVerificationCode } = useAuthBoxContext();
  const { email, password } = authenticationDetails;
  const [verifyEmailError, setVerifyEmailError] = useState<string>();

  async function onSubmit(values: VerifyEmailFormValues) {
    try {
      setVerifyEmailError(undefined);
      await verifyEmail(email, password, values.verificationCode);
    } catch (e) {
      setVerifyEmailError(e.message);
    }
  }

  return (
    <Formik
      initialValues={{
        verificationCode: presetVerificationCode ?? "",
      }}
      validationSchema={VerifyEmailSchema}
      onSubmit={onSubmit}
    >
      <AuthBoxVerifyEmailForm verifyEmailError={verifyEmailError} />
    </Formik>
  );
}

function AuthBoxVerifyEmailForm({ verifyEmailError }) {
  const { resendEmailVerification } = useAuthContext();
  const { authenticationDetails } = useAuthBoxContext();
  const { email } = authenticationDetails;
  const { isSubmitting, isValid } = useFormikContext<VerifyEmailFormValues>();
  const [resendCodeStatus, setResendCodeStatus] = useState<
    "NONE" | "SENDING" | "SENT"
  >("NONE");
  const [resendCodeError, setResendCodeError] = useState<string>();

  const resendCodeClick = useCallback(() => {
    async function sendCode() {
      try {
        setResendCodeError(undefined);
        setResendCodeStatus("SENDING");
        await resendEmailVerification(email);
        setResendCodeStatus("SENT");
      } catch (e) {
        setResendCodeError(e.message);
        setResendCodeStatus("NONE");
      }
    }
    sendCode();
  }, [resendEmailVerification, setResendCodeError]);

  return (
    <AuthBoxLayout>
      <AuthBoxLayout.Header title="Verify Your Email Address">
        A verification code was sent to <strong>{email}</strong>
      </AuthBoxLayout.Header>
      <AuthBoxLayout.Form>
        <Form>
          <TextField
            name="verificationCode"
            label="Verification Code"
            placeholder="123456"
            autoComplete="one-time-code"
            autoFocus
          />
          <div className="buttons">
            <FormErrorMessage message={verifyEmailError} />
            <Button
              color="accent"
              fluid
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
            >
              Verify
            </Button>
          </div>
          <div className="buttons">
            <Button
              type="button"
              outlined
              onClick={resendCodeClick}
              loading={resendCodeStatus === "SENDING"}
              style={{ marginTop: "2rem" }}
            >
              Resend Code
            </Button>
            {resendCodeStatus === "SENT" ? (
              <div style={{ marginTop: "0.5em" }}>
                A code has been sent to <strong>{email}</strong>. Please check
                your spam folder if you do not see it.
              </div>
            ) : null}
            <FormErrorMessage message={resendCodeError} />
          </div>
        </Form>
      </AuthBoxLayout.Form>
    </AuthBoxLayout>
  );
}
