import { getResizedImageUrl } from "@mapmaker/svg";
import { Icon, ModalActions, ModalContent } from "semantic-ui-react";
import MapmakerModal from "../../../shared/modals/MapmakerModal";
import LabelledValueTable from "../../../shared/tables/LabelledValueTable";
import PrintQualityIcon from "../../../printing/PrintQualityIcon";
import { useProjectDesign, useProjectFile } from "../../useProjectState";
import useEditableOpening from "./editable/useEditableOpening";
import { useEditableOpeningImage } from "./editable/useEditableOpeningImage";
import MapmakerButton from "../../../shared/buttons/MapmakerButton";
import "./OpeningImageInfoModal.css";

export interface IOpeningImageInfoModalProps {
  imageId: string;
  onClose?(): any;
}

export default function OpeningImageInfoModal({
  imageId,
  onClose,
}: IOpeningImageInfoModalProps) {
  const { outputScale } = useProjectFile();
  const {
    openingFeature,
    topImageIsActive,
    bottomImageIsActive,
    setActiveImageNext,
    setActiveImagePrevious,
  } = useEditableOpening();
  const { image, printDpi } = useEditableOpeningImage(imageId);
  const { regionType } = useProjectDesign();

  return (
    <MapmakerModal
      className="opening-image-info-modal"
      open
      title="Image Information"
      size="tiny"
      onClose={onClose}
    >
      <ModalContent>
        <div className="image-container">
          {!topImageIsActive && (
            <div
              className="arrow left"
              title="Previous Image"
              onClick={setActiveImagePrevious}
            >
              <Icon name="chevron left" />
            </div>
          )}
          <img
            src={getResizedImageUrl(image.id, 300, 200)}
            alt={`Image in ${openingFeature.name}`}
          />
          {!bottomImageIsActive && (
            <div
              className="arrow right"
              title="Next Image"
              onClick={setActiveImageNext}
            >
              <Icon name="chevron right" />
            </div>
          )}
        </div>
        <div className="details-container">
          <LabelledValueTable
            values={[
              [
                "Print Quality",
                <PrintQualityIcon printDpi={printDpi} showWhenGood showDPI />,
                "The dots per inch (DPI) when printed. Higher is better.",
              ],
              [
                "Original Resolution",
                prettyResolution(
                  image.imageWidth * outputScale,
                  image.imageHeight * outputScale
                ),
                "The resolution of the originally uploaded image file.",
              ],
              [
                "Physical Print Size",
                prettySize(
                  image.width * outputScale,
                  image.height * outputScale
                ),
                `The actual size this image will cover if printed. Ignoring the clipping by the ${regionType}.`,
              ],
            ]}
          />
          <h4>How do I Increase the DPI?</h4>
          <p>
            The DPI is a result of the original resolution of the image (number
            of pixels) and the physical area it must cover to fit in the{" "}
            {regionType} as it is positioned and scaled. Therefore the two ways
            to increase the DPI are:
            <ul>
              <li>Start with a higher resolution image file.</li>
              <li>Scale the image down.</li>
            </ul>
            If you decide to scale the image down you can add multiple photos to
            fill in the rest of the {regionType}, or use the fill tool to set a
            background color for this {regionType}.
          </p>
        </div>
      </ModalContent>
      <ModalActions>
        <MapmakerButton onClick={onClose} color="accent">
          GOT IT
        </MapmakerButton>
      </ModalActions>
    </MapmakerModal>
  );
}

function prettyResolution(width: number, height: number): string {
  return `${width.toFixed(0)} px × ${height.toFixed(0)} px`;
}

function prettySize(width: number, height: number): string {
  return `${(width / 72).toFixed(1)} in. × ${(height / 72).toFixed(1)} in.`;
}
