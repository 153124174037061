import { useSelector } from "react-redux";
import { WalgreensPrintsStore } from "./walgreensPrintsStore";
import { Icon } from "semantic-ui-react";
import "./WalgreensPrintListHeader.css";
import { getPrintingService } from "../PrintServices";
import { MapmakerPrintServiceId } from "../PrintServiceIds";

export default function WalgreensPrintListHeader() {
  const { design } = useSelector((state: WalgreensPrintsStore) => ({
    design: state.design,
  }));
  const service = getPrintingService(MapmakerPrintServiceId.WALGREENS);
  return (
    <div id="walgreens-print-list-header">
      <img className="walgreens-logo" src={service.imageSmall} />
      <div>
        <h3>Print to Walgreens</h3>
        <p>
          Your photos will be sent directly to a Walgreens near you and ready
          for pickup in as little as 1 hour!
        </p>
        <ol style={{ margin: 0, padding: "0 1em" }}>
          <li>
            Review your photos below. Use the settings panel to select which{" "}
            {design.regionTypePlural} to print.
          </li>
          <li>
            Click{" "}
            <strong>
              Checkout <Icon name="arrow right" />
            </strong>{" "}
            to choose your pickup location
          </li>
          <li>Pick up your photos in as little as one hour!</li>
        </ol>
      </div>
    </div>
  );
}
