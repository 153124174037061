import { produce } from "immer";

export type StickerOrderSettings = {
  useTokens: boolean;
  sort: StickerSortMethod;
  showOutputHelp: boolean;
};

export type StickerSortMethod = "modified" | "name" | "timeline";

const SET_USE_TOKENS = "sticker_order_settings.use_tokens";
const SET_SORT = "sticker_order_settings.sort";
const SET_SHOW_OUTPUT_HELP = "sticker_order_settings.show_output_help";

export function setUseTokens(useTokens: boolean) {
  return {
    type: SET_USE_TOKENS,
    useTokens,
  };
}

const SET_SORT_LOCAL_STORAGE_KEY = "mapmaker.preferredPrintSortMethod";
export function setSort(sort: StickerSortMethod) {
  return {
    type: SET_SORT,
    sort,
  };
}

export function setShowOutputHelp(show: boolean) {
  return {
    type: SET_SHOW_OUTPUT_HELP,
    show,
  };
}

const initialState: StickerOrderSettings = {
  useTokens: true,
  sort: safeGetSavedSort(),
  showOutputHelp: false,
};

export default produce(
  (draft: StickerOrderSettings, action): StickerOrderSettings => {
    if (draft === undefined) {
      return initialState;
    }

    switch (action.type) {
      case SET_USE_TOKENS:
        draft.useTokens = action.useTokens;
        break;
      case SET_SORT:
        draft.sort = action.sort;
        window.localStorage.setItem(SET_SORT_LOCAL_STORAGE_KEY, action.sort);
        break;
      case SET_SHOW_OUTPUT_HELP:
        draft.showOutputHelp = action.show;
        break;
      default:
        break;
    }
  }
);

function safeGetSavedSort(): StickerSortMethod {
  // Just to make sure we add any new values here.
  const StickerSortMap: Record<StickerSortMethod, true> = {
    modified: true,
    name: true,
    timeline: true,
  };
  const savedValue = window.localStorage.getItem(SET_SORT_LOCAL_STORAGE_KEY);
  if (StickerSortMap[savedValue]) {
    return savedValue as StickerSortMethod;
  }
  return "modified";
}
