export type FrameId = "black" | "grey" | "white" | "none";

export type FrameType = {
  id: string;
  name: string;
  color: string;
  thickness: number;
};

export function getFrame(id: string): FrameType {
  return Frames.find(frame => frame.id === id);
}

export const Frames: FrameType[] = [
  {
    id: "black",
    name: "Black",
    color: "#000000",
    thickness: 1.75,
  },
  {
    id: "white",
    name: "White",
    color: "#FFFFFF",
    thickness: 1.75,
  },
  {
    id: "grey",
    name: "grey",
    color: "#A79895",
    thickness: 1.75,
  },
];
