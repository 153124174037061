import { gql } from "@apollo/client";
import { useAuthContext } from "@tbl/aws-auth";
import { Button } from "@mapmaker/ui";
import { OpeningInput, OpeningSuggestionInput } from "@mapmaker/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Suspender from "react-suspender";
import { captureException } from "@sentry/react";
import { useMapmakerAppConfig } from "../../../../../client";
import {
  OpeningSuggestionStatus,
  useCreateOpeningSuggestionMutation,
  useUpdateOpeningSuggestionMutation,
  useGetOpeningSuggestionQuery,
  OpeningSuggestionFragment,
  OpeningSuggestion,
} from "../../../../../client/MapmakerApi";
import ErrorPage from "../../../../../lib/errors/ErrorPage";
import MapmakerTopBar from "../../../../layout/MapmakerTopBar";
import { addMessage } from "../../../../shared/messageReducer";
import { useProjectDispatch } from "../../../projectStore";
import { useProjectFile } from "../../../useProjectState";
import OpeningEditorView from "../editable/OpeningEditorView";
import useOpening from "../useOpening";
import { useBackgroundCallback } from "../../../../../lib/hooks/useBackgroundCallback";
import {
  addOpeningSuggestion,
  updateOpeningSuggestion,
} from "../../../fileReducer";
import { useAddToastMessage } from "../../../shared/toasts/useProjectToastMessages";
import SubmitSuggestionModal from "./SubmitSuggestionModal";
import ReadOnlySuggestionView from "./ReadOnlySuggestionView";
import FeatureExitButton from "../../../shared/FeatureExitButton";
import OpeningEditorViewToolbar from "../OpeningEditorViewToolbar";

gql`
  query getOpeningSuggestion(
    $fileId: String!
    $openingId: String!
    $suggestionId: String!
  ) {
    openingSuggestion(
      fileId: $fileId
      openingId: $openingId
      suggestionId: $suggestionId
    ) {
      ...OpeningSuggestion
    }
  }

  mutation createOpeningSuggestion($input: CreateOpeningSuggestionInput!) {
    createOpeningSuggestion(input: $input) {
      suggestion {
        ...OpeningSuggestion
      }
    }
  }

  mutation updateOpeningSuggestion($input: UpdateOpeningSuggestionInput!) {
    updateOpeningSuggestion(input: $input) {
      suggestion {
        ...OpeningSuggestion
      }
    }
  }
`;

export default function OpeningSuggestionEditorView() {
  const file = useProjectFile();
  const { identityId } = useAuthContext();
  const { openingId, suggestionId } = useParams<{
    openingId: string;
    suggestionId: string;
  }>();
  const [suggestion, setSuggestion] = useState<OpeningSuggestionFragment>();
  const navigate = useNavigate();
  const { error } = useGetOpeningSuggestionQuery({
    skip: !!suggestion || suggestionId === "new",
    variables: {
      fileId: file.id,
      openingId,
      suggestionId,
    },
    onCompleted: data => setSuggestion(data.openingSuggestion),
  });

  useEffect(() => {
    if (suggestionId === "new") {
      setSuggestion({
        id: "new",
        fileId: file.id,
        fileOwnerId: file.ownerId,
        openingId,
        suggesterId: identityId,
        input: {
          images: [],
          modifiedAt: new Date().toISOString(),
        },
        status: OpeningSuggestionStatus.Draft,
        name: undefined,
      });
    }
  }, []);

  function onCreateNew(suggestion: OpeningSuggestionFragment) {
    setSuggestion(suggestion);
    navigate(`../${openingId}/${suggestion.id}`, { replace: true });
  }

  if (error) {
    return (
      <ErrorPage
        message={`Suggestion could not be found. Make sure you are logged in with the same account that created the suggestion.`}
      />
    );
  } else if (!suggestion) {
    return <Suspender />;
  }

  // If this has already been submitted, bail out to a read-only view.
  if (suggestion.status === OpeningSuggestionStatus.Submitted) {
    return <ReadOnlySuggestionView suggestionId={suggestion.id} />;
  }

  return (
    <OpeningSuggestionEditorViewWithSuggestion
      initialSuggestion={suggestion}
      onCreateNew={onCreateNew}
    />
  );
}

type OpeningSuggestionEditorViewWithSuggestionProps = {
  onCreateNew(suggestion: OpeningSuggestionFragment): any;
  initialSuggestion: OpeningSuggestionFragment;
};

function OpeningSuggestionEditorViewWithSuggestion({
  initialSuggestion,
  onCreateNew,
}: OpeningSuggestionEditorViewWithSuggestionProps) {
  const file = useProjectFile();
  const projectDispatch = useProjectDispatch();
  const { openingFeature } = useOpening(initialSuggestion.openingId);
  const { gotoEditFile } = useMapmakerAppConfig();
  const { addToastInfo } = useAddToastMessage();
  const editedSuggestionRef = useRef<OpeningSuggestion>(initialSuggestion);
  const isNotSavedYet = editedSuggestionRef.current.id === "new";
  const isSavingRef = useRef<boolean>(false);
  const [dirty, setDirty] = useState(false);

  const [submitSuggestionModalOpen, setSubmitSuggestionModalOpen] = useState(
    false
  );
  const [
    submitSuggestionButtonWaiting,
    setSubmitSuggestionButtonWaiting,
  ] = useState(false);

  const [createSuggestion] = useCreateOpeningSuggestionMutation();

  const [updateSuggestion] = useUpdateOpeningSuggestionMutation();

  const saveLatest = useCallback(async (): Promise<boolean> => {
    const isNew = editedSuggestionRef.current.id === "new";
    const hasImages = editedSuggestionRef.current.input.images.length > 0;
    if (!hasImages) {
      return false;
    }
    if (!editedSuggestionRef.current || isSavingRef.current || !dirty) {
      // We always save when it's new (and we have images).
      if (!isNew) {
        return false;
      }
    }

    if (isNew) {
      setDirty(false);
      isSavingRef.current = true;
      const result = await createSuggestion({
        variables: {
          input: {
            fileId: initialSuggestion.fileId,
            openingId: initialSuggestion.openingId,
            input: editedSuggestionRef.current.input,
          },
        },
      });
      isSavingRef.current = false;
      if (result.errors?.length > 0) {
        editedSuggestionRef.current.id = "new";
        projectDispatch(
          addMessage({
            type: "error",
            content: result.errors[0].message,
          })
        );
      } else {
        const newSuggestion = result.data.createOpeningSuggestion.suggestion;
        // We only overwrite the ID because other things may have been modified.
        editedSuggestionRef.current.id = newSuggestion.id;
        projectDispatch(addOpeningSuggestion(newSuggestion));
        onCreateNew(newSuggestion);
      }
    } else {
      // This is an existing suggestion. Update it.
      // Save the latest copy locally so the UI is updated.
      projectDispatch(updateOpeningSuggestion(editedSuggestionRef.current));
      setDirty(false);
      isSavingRef.current = true;
      const result = await updateSuggestion({
        variables: {
          input: {
            id: initialSuggestion.id,
            fileId: initialSuggestion.fileId,
            openingId: initialSuggestion.openingId,
            input: {
              modifiedAt: editedSuggestionRef.current.input.modifiedAt,
              images: editedSuggestionRef.current.input.images,
              background: editedSuggestionRef.current.input.background,
            },
          },
        },
      });
      isSavingRef.current = false;

      if (result.errors?.length > 0) {
        captureException(result.errors);
        projectDispatch(
          addMessage({
            id: "opening-suggestion-save-error",
            type: "error",
            content: `Error saving changes. If the problem persists please contact us.`,
          })
        );
        return false;
      }
      return true;
    }
  }, [projectDispatch, dirty, updateSuggestion, createSuggestion]);

  // Save at regular intervals.
  useBackgroundCallback(saveLatest);

  async function onChange(openingInput: OpeningInput) {
    const suggestionInput: OpeningSuggestionInput = {
      images: openingInput.images,
      background: openingInput.background,
      modifiedAt: new Date().toISOString(),
    };
    // Important with the ref to overwrite the entire object, not just the input property.
    editedSuggestionRef.current = {
      ...editedSuggestionRef.current,
      input: suggestionInput,
    };
    setDirty(true);
    if (editedSuggestionRef.current.id === "new") {
      // Force save the first time we have data.
      await saveLatest();
    }
  }

  async function openSubmitSuggestionModal() {
    setSubmitSuggestionButtonWaiting(true);
    try {
      await saveLatest();
      setSubmitSuggestionModalOpen(true);
    } finally {
      setSubmitSuggestionButtonWaiting(false);
    }
  }

  function onClickWhileDisabled() {
    addToastInfo(
      "Add at least one photo before submitting your suggestion.",
      "suggestion.add-photo-to-suggest"
    );
  }

  return (
    <OpeningEditorView
      openingId={initialSuggestion.openingId}
      initialOpeningInput={{ type: "OPENING", ...initialSuggestion.input }}
      onChange={onChange}
      mainAddons={
        <>
          <FeatureExitButton
            onClick={() => gotoEditFile(file.id, "collaborate")}
          />
          <OpeningEditorViewToolbar />
        </>
      }
      topBar={
        <MapmakerTopBar
          breadcrumbs={[
            {
              label: "Suggestions",
              onClick: () => gotoEditFile(file.id, "collaborate"),
            },
            {
              label: openingFeature.name,
              onClick: () =>
                gotoEditFile(file.id, "collaborate", openingFeature.id),
            },
            {
              label: isNotSavedYet ? "Create" : "Edit",
            },
          ]}
          after={
            <>
              <SubmitSuggestionModal
                suggestion={editedSuggestionRef.current}
                onClose={() => setSubmitSuggestionModalOpen(false)}
                open={submitSuggestionModalOpen}
              />
              <Button
                color="accent"
                onClick={openSubmitSuggestionModal}
                onClickWhileDisabled={onClickWhileDisabled}
                disabled={
                  initialSuggestion.id === "new" ||
                  editedSuggestionRef.current.input.images.length === 0
                }
                loading={submitSuggestionButtonWaiting}
              >
                Submit
              </Button>
            </>
          }
        />
      }
    />
  );
}
