import {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useLayoutEffect,
} from "react";

export type HeaderMode = "normal" | "inverse";

type GlobalLayoutContext = {
  headerMode: HeaderMode;
  setHeaderMode(headerMode: HeaderMode): any;
};

const Context = createContext<GlobalLayoutContext>({
  headerMode: "normal",
  setHeaderMode: () => {},
});

export const GlobalLayoutProvider = function({
  children,
}: PropsWithChildren<{}>) {
  const [headerMode, setHeaderMode] = useState<HeaderMode>("normal");

  return (
    <Context.Provider
      value={{
        headerMode,
        setHeaderMode,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export function useGlobalLayout(): GlobalLayoutContext {
  return useContext(Context);
}

/**
 * Utilities
 */
export function useHeaderMode(newHeaderMode: HeaderMode) {
  const { headerMode, setHeaderMode } = useGlobalLayout();
  useLayoutEffect(() => {
    setHeaderMode(newHeaderMode);
    return () => {
      setHeaderMode("normal");
    };
  }, [headerMode, newHeaderMode, setHeaderMode]);
  return {
    headerMode,
  };
}
