import {
  MapmakerDesign,
  OpeningFeature,
  RectangularPrintLayoutInputWithStickerOrderIds,
} from "@mapmaker/core";
import { Bin, Rectangle, MaxRectsPacker } from "maxrects-packer";
import {
  BinShrinkMode,
  NestableStickerOrder,
  NestingOptions,
  OpeningRectangle,
} from ".";
import { containBboxes } from "./utils";

// @ts-ignore
const log = console.log;

export function nestStickers(
  orderOrOrders: NestableStickerOrder | NestableStickerOrder[],
  design: Pick<MapmakerDesign, "features">,
  nestingOptions: NestingOptions
): {
  printLayouts: RectangularPrintLayoutInputWithStickerOrderIds[];
  overflow: boolean;
} {
  const {
    width,
    minWidth = 0,
    height,
    minHeight = 0,
    border = 0,
    padding = 0,
    shrinkMode = BinShrinkMode.None,
    shrinkModeSinglePage = shrinkMode,
    center = true,
  } = nestingOptions;
  const orders = Array.isArray(orderOrOrders) ? orderOrOrders : [orderOrOrders];

  // Make sure these can be nested together
  const nestGroupMap = getNestGroupMap(orders);
  if (Object.keys(nestGroupMap).length > 1) {
    throw new Error(
      `Cannot nest stickers from disparate nest groups: ${Object.keys(
        nestGroupMap
      ).join(",")}`
    );
  }

  const packer = new MaxRectsPacker<OpeningRectangle>(width, height, padding, {
    // Do not use smallest power of 2 for side lengths.
    pot: false,
    allowRotation: true,
    border,
    smart: true,
  });

  log("nestingOptions", width / 72, height / 72, padding / 72, border / 72);

  const stickerRectangles: OpeningRectangle[] = orders.flatMap(
    (stickerOrder) => {
      return stickerOrder.stickers.flatMap((sticker) => {
        const opening = design.features[sticker.openingId] as OpeningFeature;
        // We want padding which contains both the print and cut (either can be larger)
        const extentsBBox = containBboxes(
          opening.layout.cut?.bbox,
          opening.layout.print?.bbox
        );
        const openingWidth = extentsBBox.width * (sticker.scale ?? 1);
        const openingHeight = extentsBBox.height * (sticker.scale ?? 1);
        const alreadyRotated =
          openingWidth > width &&
          openingHeight <= width &&
          openingHeight <= height;

        const stickerRectangle = {
          /**
           * Sticker orders from before June 2022 did not have a scale on the stickers. Hence the
           * defaults.
           */
          width: alreadyRotated ? openingHeight : openingWidth,
          height: alreadyRotated ? openingWidth : openingHeight,
          scale: sticker.scale ?? 1,
          stickerOrderId: stickerOrder.id,
          openingId: sticker.openingId,
          alreadyRotated,
        };
        // Handles multiple quantities by return an array of "quantity" rects for this opening.
        return Array.apply(null, new Array(sticker.quantity)).map((_, i) => ({
          ...stickerRectangle,
          key: i,
        }));
      });
    }
  );

  packer.addArray(stickerRectangles);

  const result =
    packer.bins.map<RectangularPrintLayoutInputWithStickerOrderIds>(
      (bin, _, bins) => {
        const { width, height, offsetX, offsetY } = getBinDimensions(
          bin,
          bins.length === 1 ? shrinkModeSinglePage : shrinkMode,
          minWidth,
          minHeight
        );

        return {
          width,
          height,
          printOpenings: bin.rects.map((rect) => {
            function getRotation() {
              if (rect.rot) {
                return rect.alreadyRotated ? 0 : 90;
              } else {
                return rect.alreadyRotated ? 90 : 0;
              }
            }
            const rotation = getRotation();
            return {
              openingId: rect.openingId,
              x: (rect.x ?? 0) + (center ? offsetX : 0),
              y: (rect.y ?? 0) + (center ? offsetY : 0),
              rotation,
              scale: rect.scale,
              stickerOrderId: rect.stickerOrderId,
            };
          }),
        };
      }
    );

  // Sorting the result by size means the smaller pages will be first, which makes physically
  // sorting them easier because you know the last sheet is the largest and not covering up smaller
  // sheets.
  const sortedResult = result.sort(
    (a, b) => a.width * a.height - b.width * b.height
  );

  // Despite what the max-rects-packer docs say, oversized rects are simply discarded. So to find
  // out if anything was oversized we have to check how many rects were in the input vs. the output,
  // and also check each bin to see if it is larger than the requested size.
  const numRequested = stickerRectangles.length;
  const numPacked = packer.bins.reduce((tot, bin) => tot + bin.rects.length, 0);
  const hasOversized = packer.bins.some(
    // It seems that rect-packer sometimes goes over by the size of padding?
    (bin) => {
      // It won't rotate stickers intelligently when they are oversized, but we can rotate the sheet
      // when we put it in the envelope. So check if it fits either way.
      const allowedMin = Math.min(width + padding, height + padding);
      const allowedMax = Math.max(width + padding, height + padding);
      const actualMin = Math.min(bin.width, bin.height);
      const actualMax = Math.max(bin.width, bin.height);
      return actualMin > allowedMin || actualMax > allowedMax;
    }
  );
  log(
    "numRequested",
    numRequested,
    "numPacked",
    numPacked,
    "hasOversized",
    hasOversized,
    "bins",
    packer.bins
  );
  const overflow = numRequested > numPacked || hasOversized;

  return {
    printLayouts: sortedResult,
    overflow,
  };
}

// Returns a string signifying which nest group this sticker order can go with. A nest group is a
// group or stickers that can be nested together. In order to be nested together sticker must:
//   1. Be printed on the same type of media
export function getNestGroup(stickerOrder: NestableStickerOrder): string {
  return [stickerOrder.outputType].join("/");
}

type NestGroupMap<T extends NestableStickerOrder = NestableStickerOrder> = {
  [group: string]: T[];
};

export function getNestGroupMap<
  T extends NestableStickerOrder = NestableStickerOrder
>(stickerOrders: T[]): NestGroupMap<T> {
  return stickerOrders.reduce<NestGroupMap<T>>((nestGroupMap, stickerOrder) => {
    const nestGroup = getNestGroup(stickerOrder);
    if (!(nestGroup in nestGroupMap)) {
      nestGroupMap[nestGroup] = [];
    }
    nestGroupMap[nestGroup].push(stickerOrder);
    return nestGroupMap;
  }, {});
}

function getBinDimensions<T extends Rectangle>(
  bin: Bin<T>,
  shrinkMode: BinShrinkMode,
  minWidth: number = 0,
  minHeight: number = 0
) {
  if (bin.rects[0]?.oversized) {
    // Oversized stickers cannot fit on the requested sheet sizes, and the MaxRectsPacker doesn't
    // really fill in the details on the bins (padding, rotation, etc...) so it is easier just to
    // bail out here and manually return the correct dimensions.
    const rect = bin.rects[0];
    // For oversized pages we just do a 1/4 padding for safety.
    const oversizedPadding = (1 / 4) * 72;
    return {
      width: rect.width + 2 * oversizedPadding,
      height: rect.height + 2 * oversizedPadding,
      offsetX: oversizedPadding,
      offsetY: oversizedPadding,
    };
  }

  const trueBinContentSize = getTrueBinSize(bin);

  const binContentSize = {
    width: Math.max(trueBinContentSize.width, minWidth),
    height: Math.max(trueBinContentSize.height, minHeight),
  };
  // If the bin size is smaller than the area available, we need to offset it to the center
  const offsetX = (bin.maxWidth - binContentSize.width) / 2;
  const offsetY = (bin.maxHeight - binContentSize.height) / 2;

  // If we artificially enlarge it after shrinking, these offsets will apply
  const minWidthOffset = (binContentSize.width - trueBinContentSize.width) / 2;
  const minHeightOffset =
    (binContentSize.height - trueBinContentSize.height) / 2;

  switch (shrinkMode) {
    case BinShrinkMode.None:
      return {
        width: bin.maxWidth,
        height: bin.maxHeight,
        offsetX,
        offsetY,
      };
    case BinShrinkMode.Height:
      return {
        width: bin.maxWidth,
        height: binContentSize.height,
        offsetX,
        offsetY: minHeightOffset,
      };
    case BinShrinkMode.Width:
      return {
        width: binContentSize.width,
        height: bin.maxHeight,
        offsetX: minWidthOffset,
        offsetY,
      };
    case BinShrinkMode.Both:
      return {
        width: binContentSize.width,
        height: binContentSize.height,
        offsetX: minWidthOffset,
        offsetY: minHeightOffset,
      };
  }
}

function getTrueBinSize<T extends Rectangle>(bin: Bin<T>) {
  let width = 0;
  let height = 0;
  bin.rects.forEach((rect) => {
    width = Math.max(rect.x + (rect.rot ? rect.height : rect.width), width);
    height = Math.max(rect.y + (rect.rot ? rect.width : rect.height), height);
  });
  return {
    width: width + bin.options.border * 2,
    height: height + bin.options.border * 2,
  };
}
