/**
 * Occurs when attempting to sign in to an account without a verified email.
 */
export const UserNotConfirmedExceptionName: string =
  "UserNotConfirmedException";
export class UserNotConfirmedException extends Error {
  public readonly name: string = UserNotConfirmedExceptionName;
  constructor(public readonly message: string = "User is not confirmed.") {
    super(message);
  }
}

/**
 * Occurs when attempting to sign in to an account when the account has a forced password reset.
 */
export const PasswordResetRequiredExceptionName: string =
  "PasswordResetRequiredException";
export class PasswordResetRequiredException extends Error {
  public readonly name: string = PasswordResetRequiredExceptionName;
  constructor(
    public readonly message: string = "Password reset required for the user."
  ) {
    super(message);
  }
}

export const UserNotFoundExceptionName: string = "UserNotFoundException";
export class UserNotFoundException extends Error {
  public readonly name: string = UserNotFoundExceptionName;
  constructor(public readonly message: string = "Username not found.") {
    super(message);
  }
}

export const NotAuthorizedExceptionName: string = "NotAuthorizedException";
export class NotAuthorizedException extends Error {
  public readonly name: string = NotAuthorizedExceptionName;
  constructor(public readonly message: string = "Incorrect password.") {
    super(message);
  }
}
