import { FeatureInputMap, ProjectOutputType } from "../types";

// Will be needed when creating a new files
export type InitialFileProperties = {
  outputType?: ProjectOutputType;
  outputScale?: number;
  inputs?: FeatureInputMap;
};

// Needs to be exported so clients can call it.
export function serializeInitialFileProperties(
  state: InitialFileProperties
): string {
  // @ts-ignore
  return window.btoa(JSON.stringify(state));
}

export function deserializeInitialFileProperties(
  str: string
): InitialFileProperties {
  // @ts-ignore
  return JSON.parse(window.atob(str)) as InitialEditorState;
}
