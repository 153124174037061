import { InitialFileProperties } from "@mapmaker/core";
import { useEffect, useMemo } from "react";
import Suspender from "react-suspender";
import MessagePage from "../shared/MessagePage";
import {
  MapmakerFileHeavyFragment,
  ProjectOutputType,
  useCreateFileMutation,
  useGetFileWithDesignQuery,
} from "../../client/MapmakerApi";
import LoadingPage from "../shared/LoadingPage";
import { Route, Routes } from "react-router-dom";
import {
  createProjectStore,
  MapmakerProjectContext,
  useProjectDispatch,
} from "./projectStore";
import { Provider } from "react-redux";
import FullMapView from "./fullmap/FullMapView";
import FeatureView from "./features/FeatureView";
import ProjectOutputRouter from "../printing/ProjectOutputRouter";
import { useMapmakerAppConfig } from "../../client";
import { useProjectFile } from "./useProjectState";
import { saveFile } from "./fileReducer";
import { useBackgroundCallback } from "../../lib/hooks/useBackgroundCallback";
import MapmakerProjectNotFoundPage from "./MapmakerProjectNotFoundPage";
import TimelineView from "./timeline/TimelineView";
import CollaborateRouter from "./collaboration/CollaborateRouter";
import { trackGtmEvent, useGtmContext } from "../../lib/gtm";

/**
 * Create a new file from a design ID
 */
type MapmakerNewProjectProps = {
  designId: string;
  initialProperties?: InitialFileProperties;
};

function getInitialEditorStateFromURL(): InitialFileProperties {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    return JSON.parse(
      window.atob(urlParams.get("ies"))
    ) as InitialFileProperties;
  } catch (e) {
    return {};
  }
}

export function MapmakerNewProject({
  designId,
  initialProperties = getInitialEditorStateFromURL(),
}: MapmakerNewProjectProps) {
  const { onMapmakerFileCreated } = useMapmakerAppConfig();
  const [createFile, { data, loading, error, called }] = useCreateFileMutation({
    variables: {
      input: {
        designId,
        inputs: initialProperties?.inputs,
        outputScale: initialProperties?.outputScale,
        outputType: initialProperties?.outputType as ProjectOutputType,
      },
    },
    onCompleted: data => {
      onMapmakerFileCreated(data.createMapmakerFile.file.id);
      trackGtmEvent({
        event: "mapmaker.create-project",
        designId,
        outputScale: initialProperties?.outputScale,
        outputType: initialProperties?.outputType as ProjectOutputType,
      });
    },
  });

  useEffect(() => {
    createFile();
  }, []);

  if (loading || !called) {
    return <Suspender />;
  } else if (error) {
    return <MessagePage icon="warning circle">{error.message}</MessagePage>;
  }

  return <MapmakerProjectWithFile file={data.createMapmakerFile.file} />;
}

/**
 * Load an existing file from it's ID
 */
type MapmakerProjectProps = {
  fileId: string;
};

export function MapmakerProject({ fileId }: MapmakerProjectProps) {
  const { data, loading, error } = useGetFileWithDesignQuery({
    variables: {
      id: fileId,
    },
  });

  if (error) {
    return <MapmakerProjectNotFoundPage />;
  } else if (loading || !data.file) {
    return (
      <LoadingPage
        message="Adventure is Out There!"
        secondary="It may take a moment for our servers to find it though."
      />
    );
  }

  return <MapmakerProjectWithFile file={data.file} />;
}

/**
 * Once we have a file object loaded
 */
type MapmakerProjectWithFileProps = {
  file: MapmakerFileHeavyFragment;
};

export function MapmakerProjectWithFile({
  file,
}: MapmakerProjectWithFileProps) {
  // Set some useful GTM data
  useGtmContext({
    fileId: file.id,
    designId: file.designId,
  });

  // We only want to recreate the store if the file ID changes.
  const store = useMemo(() => createProjectStore(file), [file.id]);
  return (
    <Provider store={store} context={MapmakerProjectContext}>
      <MapmakerProjectWithContext />
    </Provider>
  );
}

export function MapmakerProjectWithContext() {
  const dispatch = useProjectDispatch();
  const file = useProjectFile();

  const doSave = () => {
    // If we have had 3 or more consecutive unsuccessful saves, then stop auto-saving.
    if (file.updateErrorCounter <= 3) {
      dispatch(saveFile());
    } else {
      console.log(
        `Skipping auto-save due to ${file.updateErrorCounter} consecutive failures.`
      );
    }
  };

  useBackgroundCallback(doSave);

  return (
    <Routes>
      <Route path="collaborate/*" element={<CollaborateRouter />} />
      <Route path="timeline" element={<TimelineView />} />
      <Route path="output/*" element={<ProjectOutputRouter />} />
      <Route path=":featureId/*" element={<FeatureView />} />
      <Route path="/" element={<FullMapView />} />
    </Routes>
  );
}
