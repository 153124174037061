import MapmakerConfig from "@mapmaker/config";
import { btoa } from "isomorphic-base64";

// Edits info: https://sharp.pixelplumbing.com/api-resize
// Filter info: https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/appendix-d.html
export function getContentImageUrl(
  key: string,
  width?: number,
  height?: number,
  fit: "cover" | "contain" | "inside" | "fill" | "outside" = "inside"
): string {
  const imageRequest = JSON.stringify({
    bucket: MapmakerConfig.internalBucket,
    key,
    edits: {
      resize: {
        width,
        height,
        fit,
        background: { r: 255, g: 255, b: 255, alpha: 1 },
      },
    },
  });

  return getContentUrl(btoa(imageRequest));
}

export function getContentUrl(path: string): string {
  return `${MapmakerConfig.contentUrl}/${path}`;
}

export function getMapmakerStaticUrl(path: string): string {
  return `${MapmakerConfig.staticUrl}/${path}`;
}
