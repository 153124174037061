import { useMemo } from "react";
import { Icon, IconProps } from "semantic-ui-react";
import { getPrintDPIQualityGroup } from "./PrintDPI";
import "./PrintQualityIcon.css";

type PrintQualityIconProps = IconProps & {
  printDpi: number;
  showWhenGood?: boolean;
  showDPI?: boolean;
};

export default function PrintQualityIcon({
  printDpi,
  showWhenGood = false,
  showDPI = false,
  ...props
}: PrintQualityIconProps) {
  const quality = useMemo(() => getPrintDPIQualityGroup(printDpi), [printDpi]);

  if (!showWhenGood && quality.severity === "ok") {
    return null;
  }
  return (
    <>
      <Icon
        id="print-quality-icon"
        className={quality.severity}
        name={quality.icon}
        {...props}
      />
      {showDPI && (
        <>
          {printDpi.toFixed(0)} DPI - {quality.name}
        </>
      )}
    </>
  );
}
