import React, { FunctionComponent, useMemo } from "react";
import { OpeningFeature, OpeningInput } from "@mapmaker/core";
import { LayerPriority } from "./openingUtils";
import { getLayerByPriority } from "./openingUtils";
import ClipPath from "../ClipPath";
import OpeningImage, { IOpeningImageProps } from "./OpeningImage";
import { openingImageContains } from "./imageUtils";

export interface IOpeningImagesProps {
  opening: OpeningFeature;
  openingInput: OpeningInput;
  clipLayer: LayerPriority;
  OpeningImageComponent?: FunctionComponent<IOpeningImageProps>;
}
export default function OpeningImages({
  opening,
  openingInput,
  clipLayer,
  OpeningImageComponent = OpeningImage,
}: IOpeningImagesProps) {
  const clipLayerNode = getLayerByPriority(opening, clipLayer);
  const clipPathId = useMemo(
    () => opening.id + "-clipper-" + Math.random(),
    []
  );

  return (
    <>
      <ClipPath id={clipPathId} nodeData={clipLayerNode} />
      {openingInput?.images.map((image, imageDepth) => (
        <>
          <OpeningImageComponent
            key={imageDepth}
            image={image}
            clipPath={clipLayerNode ? "url(#" + clipPathId + ")" : null}
            isOccluded={openingInput?.images.some(
              (occluder, occluderDepth) =>
                occluderDepth > imageDepth &&
                openingImageContains(occluder, image)
            )}
          />
        </>
      ))}
    </>
  );
}
