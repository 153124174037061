import React from "react";
import { IPrintService } from "../PrintServices";
import { MapmakerPrintServiceId } from "../PrintServiceIds";
import { PrintSizeModeId, idsToModes } from "../../../lib/prints/printSizes";
import MapmakerConfig from "@mapmaker/config";

const WalgreensPrintService: IPrintService = {
  id: MapmakerPrintServiceId.WALGREENS,
  name: "Walgreens",
  image: `${MapmakerConfig.staticUrl}/images/printing-services/WALGREENS-illustration.png`,
  imageSmall: `${MapmakerConfig.staticUrl}/images/printing-services/WALGREENS-illustration-no-padding.png`,
  directive: "Print to Walgreens",
  description:
    "Order directly from Walgreens and pick up your prints within an hour!",
  detailedDescription: (
    <>
      <p>
        Send your photos straight to Walgreens and don't worry about print size
        or paper settings. We handle all of that for you. Just select a nearby
        Walgreens location to pick them up from and they will be ready usually
        within an hour!
      </p>
      <p>Standard Walgreens print prices apply.</p>
    </>
  ),
  printSizeModes: idsToModes([
    PrintSizeModeId.STANDARD_PHOTO,
    PrintSizeModeId.WALGREENS_CHEAP,
    PrintSizeModeId.PHOTO_8x10,
    PrintSizeModeId.FULL_SIZE,
  ]),
};

export default WalgreensPrintService;
