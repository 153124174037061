import { OpeningFeature } from "@mapmaker/core";
import React from "react";
import { getLayerByPriority } from "..";
import SvgNode from "../SvgNode";

type DebugOpeningProps = {
  opening: OpeningFeature;
};

/**
 * Renders a debug view of the opening so we know what's going on.
 */
export default function DebugOpening({ opening }: DebugOpeningProps) {
  return (
    <g>
      <SvgNode
        nodeData={getLayerByPriority(opening, ["print", "outer", "cut"])}
        style={{
          fill: "#FFFFFF",
        }}
      />
      <SvgNode
        nodeData={opening.layout.cut}
        style={{
          stroke: "red",
          strokeWidth: 1,
          fill: "none",
        }}
      />
      <SvgNode
        nodeData={opening.layout.inner}
        style={{
          stroke: "green",
          strokeWidth: 1,
          fill: "none",
        }}
      />
      <SvgNode
        nodeData={opening.layout.print}
        style={{
          stroke: "blue",
          strokeWidth: 1,
          fill: "none",
        }}
      />
      <SvgNode
        nodeData={opening.layout.outer}
        style={{
          stroke: "purple",
          strokeWidth: 1,
          fill: "none",
        }}
      />
    </g>
  );
}
