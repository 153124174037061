import { OpeningsPrintSvg } from "@mapmaker/svg";
import { RectangularPrintLayoutInput } from "../../../client/MapmakerApi";
import { IRectangularPrintState } from "./ReduxPrintStates";
import { useSelector } from "react-redux";
import "./RectangularPrintThumbnail.css";

export interface IRectangularPrintThumbnailProps {
  printLayout: RectangularPrintLayoutInput;
  maxSvgWidth: number;
  maxSvgHeight: number;
}

export default function RectangularPrintThumbnail({
  printLayout,
  maxSvgWidth,
  maxSvgHeight,
}: IRectangularPrintThumbnailProps) {
  const { file, design, printOptions } = useSelector(
    (state: IRectangularPrintState) => ({
      file: state.file,
      design: state.design,
      printOptions: state.printOptions,
    })
  );

  const printRatio = printLayout.width / printLayout.height;
  const displayRatio = maxSvgWidth / maxSvgHeight;

  let svgWidth;
  let svgHeight;
  if (printRatio > displayRatio) {
    svgWidth = maxSvgWidth;
    svgHeight = maxSvgWidth / printRatio;
  } else {
    svgHeight = maxSvgHeight;
    svgWidth = maxSvgHeight * printRatio;
  }

  return (
    <span className="rectangular-print-thumbnail">
      <OpeningsPrintSvg
        file={{
          ...file,
          design,
        }}
        printLayout={printLayout}
        printOptions={printOptions}
        svgWidth={svgWidth}
        svgHeight={svgHeight}
      />
    </span>
  );
}
/*
OpeningPrintThumbnail.propTypes = {
  design: PropTypes.object.isRequired,
  blueprint: PropTypes.object.isRequired,
  print: PropTypes.object.isRequired,
  openingPrintSettings: PropTypes.object.isRequired,
  maxSvgWidth: PropTypes.number.isRequired,
  maxSvgHeight: PropTypes.number.isRequired,
};

/*****************************************
 Container
*****************************************/
