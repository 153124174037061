import { useState } from "react";
import { Message, Form } from "semantic-ui-react";
import { gql, useApolloClient } from "@apollo/client";
import { useWalgreensSubmitOrderMutation } from "../../../client/MapmakerApi";
import WalgreensCheckoutStepContainer from "./WalgreensCheckoutStepContainer";
import WalgreensOrderInformation from "./WalgreensOrderInformation";
import { useDispatch, useSelector } from "react-redux";
import {
  createPrintBatch,
  setSubmittedOrder,
} from "./walgreensCheckoutReducer";
import useWalgreensCheckout from "./useWalgreensCheckout";
import LoadingPage from "../../shared/LoadingPage";
import { useMapmakerAppConfig } from "../../../client";
import { IRectangularPrintState } from "../shared/ReduxPrintStates";
import useRectangularPrintsSelector from "../shared/useRectangularPrintsSelector";
import { Button } from "@mapmaker/ui";
import "./WalgreensOrderReview.css";
import { trackGtmEvent } from "../../../lib/gtm";

gql`
  mutation walgreensSubmitOrder($input: WalgreensSubmitOrderInput) {
    walgreensSubmitOrder(input: $input) {
      walgreensOrderId
    }
  }
`;

export default function WalgreensOrderReview() {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { gotoContact } = useMapmakerAppConfig();
  const {
    checkout,
    orderProductDetailsInput,
    subtotal,
  } = useWalgreensCheckout();
  const [agreementAccepted, setAgreementAccepted] = useState(false);
  const { printLayouts } = useRectangularPrintsSelector();
  const [submitOrder, submitOrderStatus] = useWalgreensSubmitOrderMutation();
  const { file } = useSelector((state: IRectangularPrintState) => ({
    file: state.file,
  }));

  function onAgreementChange(e, data) {
    setAgreementAccepted(data.checked);
  }

  async function doSubmitOrder() {
    const openingIds = printLayouts.reduce<Set<string>>(
      (openingIds, printLayout) => {
        printLayout.printOpenings.map(printOpening =>
          openingIds.add(file.id + "/" + printOpening.openingId)
        );
        return openingIds;
      },
      new Set()
    );
    const result = await submitOrder({
      variables: {
        input: {
          firstName: checkout.customerInformation?.firstName,
          lastName: checkout.customerInformation?.lastName,
          email: checkout.customerInformation?.email,
          phone: checkout.customerInformation?.phone,
          productDetails: orderProductDetailsInput,
          promiseTime: checkout.selectedStore?.promiseTime,
          storeNum: checkout.selectedStore?.storeNum,
          openingIds: Array.from(openingIds),
        },
      },
    });

    dispatch(
      setSubmittedOrder({
        walgreensOrderId: result.data.walgreensSubmitOrder?.walgreensOrderId,
      })
    );

    try {
      trackGtmEvent({
        event: "mapmaker.place-walgreens-order",
        numPrints: printLayouts.length,
        numOpenings: printLayouts.reduce(
          (tot, printLayout) => tot + printLayout.printOpenings.length,
          0
        ),
        subtotal,
      });
    } catch (e) {
      // Don't want to mess anything up with the tracking calculation.
    }
  }

  function renderAgreement() {
    return (
      <label>
        I acknowledge that I have read, understand and agree to be bound by the{" "}
        <a
          href="http://www.walgreens.com/topic/help/generalhelp/termsofuse.jsp"
          target="_blank"
        >
          Terms of Use
        </a>{" "}
        and{" "}
        <a
          href="http://www.walgreens.com/topic/help/generalhelp/privacyandsecurity.jsp"
          target="_blank"
        >
          Online Privacy & Security Policy
        </a>
        .
      </label>
    );
  }

  function renderOrderSubmitError() {
    if (submitOrderStatus.error) {
      return (
        <Message negative>
          <Message.Header>Error Submitting Order</Message.Header>
          {submitOrderStatus.error.message}
        </Message>
      );
    } else {
      return null;
    }
  }

  function retryPrintBatch() {
    const input = checkout.printBatch.input;
    dispatch(
      // @ts-ignore Typescript does not like our promise inn place of an action
      createPrintBatch(
        client,
        input.fileId,
        input.printOptions,
        input.printLayouts
      )
    );
  }

  // If there was a print batch error show that instead...
  if (checkout.printBatch.error) {
    return (
      <WalgreensCheckoutStepContainer header="Error Generating Prints">
        <div id="walgreens-order-error">
          <div className="message">
            An error ocurred while generating the prints. If the problem
            persists please{" "}
            <span className="link" onClick={() => gotoContact("bug")}>
              contact us
            </span>
            .
          </div>
          <Button color="accent" onClick={retryPrintBatch}>
            Retry
          </Button>
        </div>
      </WalgreensCheckoutStepContainer>
    );
  } else if (checkout.printBatch.loading) {
    return <LoadingPage message="Generating Prints" />;
  }

  return (
    <WalgreensCheckoutStepContainer header="Complete Order">
      <div id="walgreens-order-review">
        {renderOrderSubmitError()}
        <WalgreensOrderInformation />
        <Form onSubmit={doSubmitOrder}>
          <Form.Group widths="equal">
            <Form.Checkbox
              name="agreement"
              defaultValue={false}
              label={renderAgreement()}
              onChange={onAgreementChange}
            />
          </Form.Group>
          <div className="centered">
            <Button
              className="walgreens"
              color="accent"
              type="submit"
              loading={submitOrderStatus.loading}
              disabled={!agreementAccepted}
            >
              Print to Walgreens
            </Button>
          </div>
        </Form>
      </div>
    </WalgreensCheckoutStepContainer>
  );
}
