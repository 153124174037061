import { ReactNode, useEffect } from "react";
import { Menu, MenuProps, MenuItemProps } from "semantic-ui-react";
import useLockPageScroll from "../lib/useLockPageScroll";
import { NavLink, useLocation } from "react-router-dom";
import TBLLogoText from "../layout/TBLLogoText";
import "./SlideoutMenu.scss";

type SlideoutMenuProps = MenuProps & {
  open: boolean;
  onClose: () => any;
  children: ReactNode;
};

export default function SlideoutMenu({
  open,
  onClose,
  children,
  ...menuProps
}: SlideoutMenuProps) {
  //open = true;
  useLockPageScroll(open);
  const location = useLocation();
  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <div id="slideout-menu" className={open ? "open" : "closed"}>
      <div className="backdrop" onClick={onClose} />
      <div className="close-button" onClick={onClose}>
        ✕
      </div>
      <Menu
        className="menu"
        vertical
        fixed="left"
        size="huge"
        borderless
        {...menuProps}
      >
        <TBLLogoText className="logo" />
        {children}
      </Menu>
    </div>
  );
}

type SlideoutMenuLinkProps = MenuItemProps & {
  path: string;
  exact?: boolean;
  badge?: string | number;
  children: ReactNode;
};

export function SlideoutMenuLink({
  path,
  exact = false,
  badge,
  children,
  ...menuItemProps
}: SlideoutMenuLinkProps) {
  return (
    <NavLink to={path} end>
      <Menu.Item as="div" {...menuItemProps}>
        <div className="content">{children}</div>
        {badge && <div className="badge">{badge}</div>}
      </Menu.Item>
    </NavLink>
  );
}
