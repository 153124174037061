import CMSPage from "./StorefrontCMSPage";
import { gql } from "@apollo/client";
import { useRefundPolicyQuery } from "../../client/MapmakerApi";

gql`
  query refundPolicy {
    storefrontShop {
      refundPolicy {
        title
        body
      }
    }
  }
`;

export default function RefundPolicyPage() {
  const { data, loading } = useRefundPolicyQuery();
  return <CMSPage page={data?.storefrontShop.refundPolicy} loading={loading} />;
}
