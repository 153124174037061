import {
  MapmakerDesign,
  MapmakerFile,
  OpeningSuggestion,
  RectangularPrintLayoutInput,
  RectangularPrintOptions,
} from "@mapmaker/core";
import { ComponentType } from "react";
import { DebugSvgProps } from "./props/DebugSvgProps";
import { FilePreviewSvgProps } from "./props/FilePreviewSvgProps";
import {
  AllOpeningsSvgProps,
  CutSvgProps,
  OpeningsPrintSvgProps,
  OpeningStickerPreviewSvgProps,
  OpeningSuggestionSvgProps,
  OutputTypeDemonstrationProps,
  PhotomapsDotComProductSvgProps,
  RegionCompletionSvgProps,
  SimpleMessageProps,
  TblProductSvgProps,
} from "./props";

/**
 * Resources
 */

/**
 * Designs
 */
export type RenderablePropMode = "resource" | "ref" | "immutable_ref";
type ResourceOrRefObject<Ref, ImmutableRef, Resource> = {
  ref: Ref;
  resource: Resource;
  immutable_ref: ImmutableRef;
};

export type RenderableDesignRef = { DESIGN: string };
export type RenderableDesignImmutableRef = RenderableDesignRef;
export type RenderableDesignResource = Pick<
  MapmakerDesign,
  | "id"
  | "businessId"
  | "height"
  | "width"
  | "features"
  | "regionCount"
  | "regionType"
  | "regionTypePlural"
>;
export type RenderableDesign = ResourceOrRefObject<
  RenderableDesignRef,
  RenderableDesignImmutableRef,
  RenderableDesignResource
>;

/**
 * Files
 */
export type RenderableFileRef = { FILE: string };
export type RenderableFileImmutableRef = RenderableFileRef & {
  updatedAt: string;
};
export type RenderableFileResource = Pick<
  MapmakerFile,
  "id" | "name" | "updatedAt" | "inputs"
> & {
  // We also need the design, but only what's needed for a RenderableDesignResource
  design: RenderableDesignResource;
};
export type RenderableFile = ResourceOrRefObject<
  RenderableFileRef,
  RenderableFileImmutableRef,
  RenderableFileResource
>;

/**
 * Opening Suggestions
 */
export type RenderableOpeningSuggestionRef = { OPENING_SUGGESTION: string };
export type RenderableOpeningSuggestionImmutableRef = RenderableOpeningSuggestionRef & {
  submittedAt: string;
};
export type RenderableOpeningSuggestionResource = Pick<
  OpeningSuggestion,
  "submittedAt" | "fileId" | "openingId" | "id" | "input"
>;
export type RenderableOpeningSuggestion = ResourceOrRefObject<
  RenderableOpeningSuggestionRef,
  RenderableOpeningSuggestionImmutableRef,
  RenderableOpeningSuggestionResource
>;

/**
 * General resource config
 */
export type RenderablePropValue =
  | RenderableDesign
  | RenderableFile
  | RenderableOpeningSuggestion;
export type RenderableRef = RenderablePropValue["ref"];
export type RenderableResource = RenderablePropValue["resource"];

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type RenderableRefKey = KeysOfUnion<RenderableRef>;
export const RenderableRefKeyStrings: RenderableRefKey[] = [
  "DESIGN",
  "FILE",
  "OPENING_SUGGESTION",
];

/**
 * Shared type for client/server
 */

// Allowed prop types for any renderable component.
interface RenderableComponentProps<Mode extends RenderablePropMode> {
  [x: string]: RenderableJSONValue<Mode>;
}
export type RenderableJSONValue<Mode extends RenderablePropMode> =
  | string
  | number
  | boolean
  | RenderableJSONObject<Mode>
  | RenderableJSONArray<Mode>
  | RenderablePropValue[Mode]
  // Known complex types which serialize and deserialize fine from JSON
  | RectangularPrintLayoutInput
  | RectangularPrintOptions;

interface RenderableJSONObject<Mode extends RenderablePropMode> {
  [x: string]: Mode;
}

interface RenderableJSONArray<Mode extends RenderablePropMode>
  extends Array<RenderableJSONValue<Mode>> {}

export type RenderablePropsType<
  Mode extends RenderablePropMode,
  Type extends RenderableType,
  Props extends RenderableComponentProps<Mode>
> = {
  type: Type;
} & Props;

/**
 * A list of all the renderable component props types
 */
export type RenderableProps<Mode extends RenderablePropMode> =
  | RenderablePropsType<Mode, "all_openings", AllOpeningsSvgProps<Mode>>
  | RenderablePropsType<Mode, "debug", DebugSvgProps<Mode>>
  | RenderablePropsType<Mode, "cut", CutSvgProps<Mode>>
  | RenderablePropsType<Mode, "file_preview", FilePreviewSvgProps<Mode>>
  | RenderablePropsType<
      Mode,
      "output_type_demonstration",
      OutputTypeDemonstrationProps<Mode>
    >
  | RenderablePropsType<Mode, "openings_print", OpeningsPrintSvgProps<Mode>>
  | RenderablePropsType<
      Mode,
      "opening_sticker_preview",
      OpeningStickerPreviewSvgProps<Mode>
    >
  | RenderablePropsType<
      Mode,
      "opening_suggestion",
      OpeningSuggestionSvgProps<Mode>
    >
  | RenderablePropsType<
      Mode,
      "region_completion",
      RegionCompletionSvgProps<Mode>
    >
  | RenderablePropsType<
      Mode,
      "photomaps_product",
      PhotomapsDotComProductSvgProps<Mode>
    >
  | RenderablePropsType<Mode, "simple_message", SimpleMessageProps<Mode>>
  | RenderablePropsType<Mode, "tbl_product", TblProductSvgProps<Mode>>;

export type RenderableType = RenderableProps<"resource">["type"];

/**
 * The types to be used when sending over the wire.
 */
export type RenderablePropsWithRefs = RenderableProps<"ref">;

/**
 * The types to be used when sending over the wire.
 */
export type RenderablePropsWithImmutableRefs = RenderableProps<"immutable_ref">;

/**
 * The types to be used when rendering the components.
 */
export type RenderablePropsWithResources = RenderableProps<"resource">;

/**
 * The components
 */
export type RenderableComponentType = ComponentType<
  RenderableComponentProps<"resource">
>;
