import clsx from "clsx";
import {
  ClipPath,
  SvgNode,
  OpeningImage,
  useOpeningGroupContext,
  OpeningGroup,
} from "@mapmaker/svg";
import { getOpeningFeature } from "@mapmaker/core";
import useOpening, { useOpeningBase } from "../features/opening/useOpening";
import { useProjectFile } from "../useProjectState";
import { useSvgContext } from "../../../lib/svg/useSvgContext";
import { useMapmakerAppConfig } from "../../../client/MapmakerAppConfig";
import { OpeningFeature, OpeningInput } from "@mapmaker/core";
import { useFullMapViewContext } from "./FullMapViewContext";
import { useCallback } from "react";
import { MapmakerFileViewerPolicy } from "../../../client/MapmakerApi";
import "./OpeningSvgNode.css";
import useProjectOpeningSuggestions from "../features/opening/suggestions/useOpeningSuggestions";
import ProjectOpeningImage from "../features/opening/ProjectOpeningImage";

type OpeningSvgNodeProps = {
  openingId: string;
};

export function OpeningSvgNode({ openingId }: OpeningSvgNodeProps) {
  const { mode } = useFullMapViewContext();
  switch (mode) {
    case "live":
    case "live-with-suggestions":
      return <OpeningLiveSVGNode openingId={openingId} />;
    case "suggestions":
      return <OpeningSuggestionSvgNode openingId={openingId} />;
    default:
      return null;
  }
}

type OpeningSvgNodeBaseProps = {
  openingFeature: OpeningFeature;
  openingInput: OpeningInput;
  onClick?(): any;
  className?: string;
};

function OpeningSvgNodeBase({
  openingFeature,
  openingInput,
  onClick,
  className = "",
}: OpeningSvgNodeBaseProps) {
  const hasImages = openingInput?.images.length > 0;

  return (
    <OpeningGroup
      opening={openingFeature}
      id={openingFeature.id}
      className={clsx(className, "opening-svg-node", {
        "is-outlined": openingFeature.outlined,
        "is-optional": openingFeature.optional,
        "has-image": hasImages,
      })}
      onClick={onClick}
    >
      <OpeningSvgNodeWithContext
        openingInput={openingInput}
        openingFeature={openingFeature}
      />
    </OpeningGroup>
  );
}

type OpeningSvgNodWithContextProps = {
  openingFeature: OpeningFeature;
  openingInput: OpeningInput;
};

function OpeningSvgNodeWithContext({
  openingFeature,
  openingInput,
}: OpeningSvgNodWithContextProps) {
  const { scale: svgScale } = useSvgContext();
  const { enabled, clipPathId } = useOpeningBase(openingFeature, openingInput);

  const {
    imageClipLayerWithoutBuffer,
    outermostVisibleLayer,
    isMatboardOuterOpening,
  } = useOpeningGroupContext();

  // If this node is optional and not enabled, then we won't show it.
  if (enabled === false) {
    return null;
  }

  // Bail out if this is an old matboard outer opening.
  if (isMatboardOuterOpening) {
    return null;
  }

  return (
    <>
      <ClipPath id={clipPathId} nodeData={imageClipLayerWithoutBuffer} />
      {openingFeature.optional && (
        <SvgNode
          className="outermost-visible-layer"
          nodeData={outermostVisibleLayer}
        />
      )}
      <SvgNode
        className="background"
        nodeData={imageClipLayerWithoutBuffer}
        style={{
          fill: openingInput?.background ?? "none",
          colorScheme: "only light",
        }}
      />
      {openingInput?.images?.map(image => {
        return (
          <ProjectOpeningImage
            key={image.id}
            image={image}
            svgScale={svgScale}
            clipPath={`url(#${clipPathId})`}
          />
        );
      })}
      <SvgNode className="highlight" nodeData={outermostVisibleLayer} />
    </>
  );
}

/**
 * Live Node - Shows the current project as-is
 */

type OpeningLiveSvgNodeProps = {
  openingId: string;
};
export function OpeningLiveSVGNode({ openingId }: OpeningLiveSvgNodeProps) {
  const file = useProjectFile();
  const { mode } = useFullMapViewContext();
  const { gotoEditFile } = useMapmakerAppConfig();
  const { openingFeature, openingInput } = useOpening(openingId);
  const openingSuggestions = useProjectOpeningSuggestions({
    openingId: openingFeature.id,
  });

  const onClick = useCallback(() => {
    switch (file.viewerPolicy) {
      case MapmakerFileViewerPolicy.Owner:
        if (mode === "live-with-suggestions" && openingSuggestions.length > 0) {
          gotoEditFile(file.id, "collaborate", openingId);
        } else {
          gotoEditFile(file.id, openingId);
        }
        return;
      case MapmakerFileViewerPolicy.Collaborator:
        if (openingSuggestions.length === 0) {
          gotoEditFile(file.id, "collaborate", openingId, "new");
        } else if (openingSuggestions.length === 1) {
          gotoEditFile(
            file.id,
            "collaborate",
            openingId,
            openingSuggestions[0].id
          );
        } else {
          gotoEditFile(file.id, "collaborate", openingId);
        }
        return;
      default:
        gotoEditFile(file.id, openingId);
        return;
    }
  }, [file, openingId, gotoEditFile, mode]);

  return (
    <OpeningSvgNodeBase
      openingInput={openingInput}
      openingFeature={openingFeature}
      onClick={onClick}
      className={clsx({
        live: true,
        "has-suggestions": openingSuggestions.length > 0,
        "has-one-suggestion": openingSuggestions.length === 1,
        "has-multiple-suggestions": openingSuggestions.length > 1,
      })}
    />
  );
}

/**
 * Suggestion Node - Shows suggestions as nodes instead of the project data.
 */
type OpeningSuggestionSvgNodeProps = {
  openingId: string;
};
export function OpeningSuggestionSvgNode({
  openingId,
}: OpeningSuggestionSvgNodeProps) {
  const file = useProjectFile();
  const { gotoEditFile } = useMapmakerAppConfig();
  const { suggestions } = useFullMapViewContext();
  const isOwner = file.viewerPolicy === MapmakerFileViewerPolicy.Owner;
  const openingSuggestions = suggestions.filter(
    suggestion => suggestion.openingId === openingId
  );
  const suggestion = openingSuggestions[0];

  const { openingFeature, openingInput } = useOpeningBase(
    getOpeningFeature(file.design, openingId),
    suggestion?.input ? { type: "OPENING", ...suggestion.input } : undefined
  );

  function onClick() {
    if (!suggestion) {
      if (!isOwner) {
        gotoEditFile(file.id, "collaborate", openingId, "new");
      } else {
        // Do nothing?
      }
    } else if (openingSuggestions.length === 1) {
      gotoEditFile(file.id, "collaborate", openingId, suggestion.id);
    } else {
      gotoEditFile(file.id, "collaborate", openingId);
    }
  }

  return (
    <OpeningSvgNodeBase
      openingInput={openingInput}
      openingFeature={openingFeature}
      onClick={onClick}
    />
  );
}
