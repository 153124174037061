import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import PageSection from "./PageSection";
import classes from "./TwoColumnPageSection.module.scss";
export default function TwoColumnPageSection(_a) {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (_jsx(PageSection, Object.assign({}, props, { children: _jsx("div", Object.assign({ className: clsx(classes.container) }, { children: children })) })));
}
export function TwoColumnPageSectionMedia({ children }) {
    return _jsx("div", Object.assign({ className: classes.media }, { children: children }));
}
export function TwoColumnPageSectionInfo({ title, children, }) {
    return (_jsxs("div", Object.assign({ className: classes.info }, { children: [title && _jsx("h1", Object.assign({ className: classes.title }, { children: title })), children] })));
}
