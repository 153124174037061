import ZoomPan from "zoom-pan";
import { useState } from "react";
import { HiOutlinePhotograph } from "@react-icons/all-files/hi/HiOutlinePhotograph";
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";
import { FaCog } from "@react-icons/all-files/fa/FaCog";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import { FaList } from "@react-icons/all-files/fa/FaList";
import { FaRegCheckCircle } from "@react-icons/all-files/fa/FaRegCheckCircle";
import { GiAirplane } from "@react-icons/all-files/gi/GiAirplane";
import {
  Button,
  Label,
  ToggleButton,
  ToggleGroup,
  ToggleGroupButton,
} from "@mapmaker/ui";
import { useParams } from "react-router-dom";
import { getOpeningFeature } from "@mapmaker/core";
import MapmakerLayout from "../../layout/MapmakerLayout";
import MapmakerTopBar from "../../layout/MapmakerTopBar";
import ProjectToolbar from "../ProjectToolbar";
import CollaboratorList from "./CollaboratorList";
import CenteredPage from "../../shared/CenteredPage";
import { useProjectFile } from "../useProjectState";
import {
  OpeningSuggestionFragment,
  OpeningSuggestionStatus,
} from "../../../client/MapmakerApi";
import { FileAccessInviteButton } from "./FileAccessInviteButton";
import { OpeningSuggestionList } from "./OpeningSuggestionList";
import { useMapmakerAppConfig } from "../../../client";
import FullMapSVG from "../fullmap/FullMapSVG";
import { FullMapViewProvider } from "../fullmap/FullMapViewContext";
import useProjectOpeningSuggestions from "../features/opening/suggestions/useOpeningSuggestions";
import useSharingStatus from "./useSharingStatus";
import { Collaborator } from "./useCollaborators";
import SharingSettingsModal from "../fullmap/modals/SharingSettingsModal";
import "./OwnerSharingView.css";

type SuggestionViewMode = "list" | "map";

export default function OwnerSharingView() {
  const file = useProjectFile();
  const { gotoEditFile } = useMapmakerAppConfig();
  const { openingId } = useParams<{ openingId: string }>();
  const selectedOpening = getOpeningFeature(file.design, openingId);
  const { nobodyCanCollaborate } = useSharingStatus();
  const [contextPanelExpanded, setContextPanelExpanded] = useState(false);
  const [viewMode, setViewMode] = useState<SuggestionViewMode>("list");
  const [showHidden, setShowHidden] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState<
    Collaborator
  >();

  const sharedSettings = {
    includeCurrentlyInMap: true,
    openingId,
    suggesterId:
      selectedCollaborator?.type === "inactive-invited"
        ? "DO_NOT_MATCH"
        : selectedCollaborator?.userId,
  };

  const allMatchingSuggestions = useProjectOpeningSuggestions({
    ...sharedSettings,
    includeHidden: true,
  });
  const visibleSuggestions = useProjectOpeningSuggestions({
    ...sharedSettings,
    includeHidden: showHidden,
  });
  const hasHiddenSuggestions = allMatchingSuggestions.some(
    suggestion => suggestion.status === OpeningSuggestionStatus.Hidden
  );

  function SuggestionsHeader() {
    return (
      <div className="header">
        <div className="title">
          <h3>Suggestions</h3>
          <span className="count">({visibleSuggestions.length})</span>
          {selectedOpening && (
            <Label
              onClose={() => {
                setViewMode("list");
                gotoEditFile(file.id, "collaborate");
              }}
              className="label"
            >
              for <strong>{selectedOpening.name}</strong>
            </Label>
          )}
          {selectedCollaborator && (
            <Label
              onClose={() => setSelectedCollaborator(undefined)}
              className="label"
            >
              by <strong>{selectedCollaborator.displayName}</strong>
            </Label>
          )}
        </div>
        {hasHiddenSuggestions && (
          <ToggleButton onToggle={setShowHidden} selected={showHidden}>
            {showHidden ? <FaEye /> : <FaEyeSlash />}
          </ToggleButton>
        )}
        <SuggestionViewToggle
          onChange={view => setViewMode(view)}
          view={viewMode}
        />
      </div>
    );
  }

  return (
    <MapmakerLayout>
      <MapmakerLayout.TopBar>
        <MapmakerTopBar breadcrumbs={[{ label: "Collaborate" }]} />
      </MapmakerLayout.TopBar>
      <MapmakerLayout.Main
        nav={<ProjectToolbar selected="collaborate" />}
        style={{ overflowY: "hidden" }}
      >
        <MapmakerLayout.MainContextPanel
          className="owner-sharing-context-panel"
          expanded={contextPanelExpanded}
          style={{
            display: nobodyCanCollaborate ? "none" : undefined,
          }}
        >
          <div
            className="file-access-header"
            onClick={() => setContextPanelExpanded(!contextPanelExpanded)}
          >
            <div className="expander">
              {contextPanelExpanded ? (
                <FaChevronDown fontSize={24} />
              ) : (
                <FaChevronUp fontSize={24} />
              )}
            </div>
            <h3>Collaborators</h3>
            <FileAccessInviteButton />
          </div>
          <CollaboratorList
            onSelect={collaborator => setSelectedCollaborator(collaborator)}
            selected={selectedCollaborator}
          />
        </MapmakerLayout.MainContextPanel>
        <MapmakerLayout.MainContent className="owner-sharing-view">
          {visibleSuggestions.length === 0 ? (
            <>
              <SuggestionsHeader />
              <NoSuggestionMessage
                selectedFileAccessDisplayName={
                  selectedCollaborator?.displayName
                }
                hasHiddenSuggestions={hasHiddenSuggestions}
                onShowHidden={() => setShowHidden(true)}
              />
            </>
          ) : viewMode === "map" ? (
            <>
              <SuggestionsHeader />
              <SuggestionsMapView suggestions={visibleSuggestions} />
            </>
          ) : (
            /* Set up this way so that we get scrolling only for this list, and also toast
               notifications show properly in all 3 views (none, map, and list) */
            <div style={{ overflowY: "auto" }}>
              <SuggestionsHeader />
              <SuggestionsListView suggestions={visibleSuggestions} />
              {hasHiddenSuggestions &&
                (showHidden ? (
                  <div className="hidden-suggestions-message">
                    <span className="link" onClick={() => setShowHidden(false)}>
                      Do not Show Hidden Suggestions
                    </span>
                  </div>
                ) : (
                  <div className="hidden-suggestions-message">
                    <span className="link" onClick={() => setShowHidden(true)}>
                      Show Hidden Suggestions
                    </span>
                    <p>
                      Suggestions are hidden once you add them to your map or
                      manually hide them. You can un-hide them at any time.
                    </p>
                  </div>
                ))}
            </div>
          )}
        </MapmakerLayout.MainContent>
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}

type NoSuggestionMessageProps = {
  hasHiddenSuggestions: boolean;
  onShowHidden(): any;
  selectedFileAccessDisplayName: string;
};

function NoSuggestionMessage({
  hasHiddenSuggestions,
  onShowHidden,
  selectedFileAccessDisplayName,
}: NoSuggestionMessageProps) {
  const file = useProjectFile();
  const { nobodyCanCollaborate } = useSharingStatus();
  const [projectSettingsModalOpen, setProjectSettingsModalOpen] = useState(
    false
  );

  return (
    <CenteredPage>
      <SharingSettingsModal
        open={projectSettingsModalOpen}
        onClose={() => setProjectSettingsModalOpen(false)}
      />
      <div className="no-suggestions-message">
        {nobodyCanCollaborate ? (
          <>
            <HiOutlinePhotograph fontSize="200" color="var(--mm-dark-grey)" />
            <h2>Get started with sharing!</h2>
            <p>
              Invite your friends and family to add their photos to any{" "}
              {file.design.regionType} in your project. You can then review and
              add them to your map with a single click!
            </p>
            <div className="getting-started-buttons">
              <FileAccessInviteButton>Invite Someone</FileAccessInviteButton>
              <Button onClick={() => setProjectSettingsModalOpen(true)}>
                <FaCog /> Sharing Settings
              </Button>
            </div>
          </>
        ) : hasHiddenSuggestions ? (
          <>
            <FaRegCheckCircle
              fontSize="200"
              style={{
                marginBottom: -10,
              }}
              color="var(--mm-light-black)"
            />
            <h2>
              {selectedFileAccessDisplayName
                ? `You handled all the suggestions from ${selectedFileAccessDisplayName}`
                : `You have handled all the suggestions.`}
            </h2>
            <p>
              Suggestions get hidden once you handle them, but you can always
              un-hide them if you decide to.
            </p>
          </>
        ) : (
          <>
            <GiAirplane
              fontSize="200"
              style={{
                marginBottom: -50,
                marginTop: -50,
                transform: "rotate(-20deg)",
              }}
              color="var(--mm-light-black)"
            />
            <h2>
              {selectedFileAccessDisplayName
                ? `${selectedFileAccessDisplayName} has not made any suggestions yet`
                : "Nobody has made any suggestions yet"}
            </h2>
            <p>
              They're probably out traveling. Once they return and make
              suggestions you will be able to review them here.
            </p>
          </>
        )}
        {hasHiddenSuggestions && (
          <div className="hidden-suggestions-message">
            <span className="link" onClick={onShowHidden}>
              Show Hidden Suggestions
            </span>
          </div>
        )}
      </div>
    </CenteredPage>
  );
}

type SuggestionsViewProps = {
  suggestions: OpeningSuggestionFragment[];
};

function SuggestionsMapView({ suggestions }: SuggestionsViewProps) {
  return (
    <FullMapViewProvider
      name="owner-sharing"
      initialMode="suggestions"
      suggestions={suggestions}
    >
      <ZoomPan maxScale={20}>
        <FullMapSVG />
      </ZoomPan>
    </FullMapViewProvider>
  );
}

function SuggestionsListView({ suggestions }: SuggestionsViewProps) {
  const { gotoEditFile } = useMapmakerAppConfig();
  return (
    <OpeningSuggestionList
      suggestions={suggestions}
      onClick={(suggestion: OpeningSuggestionFragment) =>
        gotoEditFile(
          suggestion.fileId,
          "collaborate",
          suggestion.openingId,
          suggestion.id
        )
      }
    />
  );
}

type SuggestionsViewToggleProps = {
  view: SuggestionViewMode;
  onChange(viewMode: SuggestionViewMode): any;
};

function SuggestionViewToggle({ onChange, view }: SuggestionsViewToggleProps) {
  return (
    <ToggleGroup onChange={onChange} value={view}>
      <ToggleGroupButton value="list">
        <FaList />
      </ToggleGroupButton>
      <ToggleGroupButton value="map">
        <FaGlobe />
      </ToggleGroupButton>
    </ToggleGroup>
  );
}
