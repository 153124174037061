import * as yup from "yup";
import { RenderableForm } from ".";

export const TblProductSvgForm: RenderableForm = {
  initialValues: {},
  inputs: [
    {
      type: "design",
      id: "design",
      name: "Design",
      validation: yup.string().required(),
    },
    {
      type: "select",
      id: "frame",
      name: "Frame",
      description: "A TBL style frame",
      validation: yup.string().required(),
      options: [
        {
          label: "None",
          value: "none",
        },
        {
          label: "Black",
          value: "black",
        },
        {
          label: "Grey",
          value: "grey",
        },
        {
          label: "White",
          value: "white",
        },
      ],
    },
    {
      type: "select",
      id: "outerColor",
      name: "Outer Color",
      description: "Color fo the outer mat.",
      validation: yup.string(),
      options: [
        {
          label: "White",
          value: "white",
        },
        {
          label: "Black",
          value: "black",
        },
        {
          label: "Caribbean",
          value: "caribbean",
        },
        {
          label: "Driftwood",
          value: "driftwood",
        },
        {
          label: "Sienna",
          value: "sienna",
        },
        {
          label: "Zucchini",
          value: "zucchini",
        },
      ],
    },
  ],
};
