import React, { ReactNode } from "react";
import { StorefrontMoneyV2 } from "../../../client/MapmakerApi";
import { moneyV2Equals } from "../../../lib/storefront";
import MoneyV2, { IMoneyProps } from "./MoneyV2";

export interface IMoneyV2RangeProps extends Omit<IMoneyProps, "moneyV2"> {
  min: StorefrontMoneyV2;
  max: StorefrontMoneyV2;
  separator?: ReactNode;
}

const MoneyV2Range = React.memo(
  ({ min, max, separator = " - ", ...moneyProps }: IMoneyV2RangeProps) => {
    if (!min) {
      return null;
    } else if (moneyV2Equals(min, max)) {
      return <MoneyV2 moneyV2={min} {...moneyProps} />;
    } else {
      return (
        <>
          <span className="min">
            <MoneyV2 moneyV2={min} {...moneyProps} />
          </span>
          <span className="separator">{separator}</span>
          <span className="max">
            <MoneyV2 moneyV2={max} {...moneyProps} />
          </span>
        </>
      );
    }
  }
);

export default MoneyV2Range;
