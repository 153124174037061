import { useState } from "react";
import { gql } from "@apollo/client";
import clsx from "clsx";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import SharingSettingsModal from "../fullmap/modals/SharingSettingsModal";
import { useDeleteMapmakerFileAccessMutation } from "../../../client/MapmakerApi";
import {
  Collaborator,
  useCollaborator,
  useCollaborators,
} from "./useCollaborators";
import { MapmakerConfirmModal } from "../../shared/modals/MapmakerConfirmModal";
import { useProjectDispatch } from "../projectStore";
import { deleteFileAccess } from "../fileReducer";
import { useAddToastMessage } from "../shared/toasts/useProjectToastMessages";
import "./CollaboratorList.css";
import useSharingStatus from "./useSharingStatus";
import { SharingLink } from "../shared/SharingLink";

type CollaboratorListProps = {
  onSelect(collaborator: Collaborator): any;
  selected?: Collaborator;
};

export default function CollaboratorList({
  onSelect,
  selected,
}: CollaboratorListProps) {
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const collaborators = useCollaborators();
  const { everyoneCanCollaborate } = useSharingStatus();

  return (
    <>
      <SharingSettingsModal
        open={settingsModalOpen}
        onClose={() => setSettingsModalOpen(false)}
      />
      <ul id="collaborator-list">
        {collaborators.length === 0 &&
          (everyoneCanCollaborate ? (
            <div className="empty">
              <h3>Nobody has added anything yet.</h3>
              <p>
                Share the link below with others and they will be able to add
                their photos as suggestions.
              </p>
              <SharingLink />
            </div>
          ) : (
            <div className="empty">
              <h3>You have not invited anyone to collaborate yet.</h3>
            </div>
          ))}
        {collaborators.map(collaborator => {
          const key =
            collaborator.type === "inactive-invited"
              ? collaborator.email
              : collaborator.userId;
          return (
            <CollaboratorListItem
              key={key}
              collaborator={collaborator}
              selected={collaboratorsAreEqual(collaborator, selected)}
              onSelect={() => onSelect(collaborator)}
              onDeselect={() => onSelect(undefined)}
            />
          );
        })}
      </ul>
    </>
  );
}

function collaboratorsAreEqual(a: Collaborator, b: Collaborator): boolean {
  if (!a || !b) {
    return false;
  }
  if (a.type === "inactive-invited" && b.type === "inactive-invited") {
    return a.email === b.email;
  } else if (a.type === "active-invited" && b.type === "active-invited") {
    return a.userId === b.userId;
  } else if (a.type === "active-public" && b.type === "active-public") {
    return a.userId === b.userId;
  } else {
    return false;
  }
}

gql`
  mutation deleteMapmakerFileAccess($input: DeleteMapmakerFileAccessInput!) {
    deleteMapmakerFileAccess(input: $input) {
      success
    }
  }
`;

type CollaboratorListItemProps = {
  collaborator: Collaborator;
  selected: boolean;
  onSelect(collaborator: Collaborator): any;
  onDeselect(collaborator: Collaborator): any;
};

function CollaboratorListItem({
  collaborator,
  selected,
  onSelect,
  onDeselect,
}: CollaboratorListItemProps) {
  const {
    fileAccess,
    displayName,
    displayNameIsEmail,
    visibleSuggestions,
  } = useCollaborator(collaborator);
  const toast = useAddToastMessage();
  const projectDispatch = useProjectDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [
    deleteFileAccessMutation,
    { loading: deleting },
  ] = useDeleteMapmakerFileAccessMutation({
    onCompleted: () => {
      setDeleteModalOpen(false);
      projectDispatch(
        deleteFileAccess({
          id: fileAccess.id,
        })
      );
      toast.addToastInfo({
        title: "Access Removed",
        content: `${displayName} no longer has access to this project.`,
      });
    },
    onError: error => {
      toast.addToastError(error.message);
      setDeleteModalOpen(false);
    },
  });

  async function onConfirmDelete() {
    await deleteFileAccessMutation({
      variables: {
        input: {
          id: fileAccess.id,
          fileId: fileAccess.fileId,
        },
      },
    });
  }

  return (
    <>
      {fileAccess && (
        <MapmakerConfirmModal
          open={deleteModalOpen}
          onConfirm={onConfirmDelete}
          onClose={() => setDeleteModalOpen(false)}
          loading={deleting}
          confirmLabel="Remove Access"
          confirmProps={{
            color: "delete",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <FaTrash fontSize={40} />
            <h2>Remove access for {fileAccess.granteeEmail}</h2>
            <p style={{ color: "var(--mm-dark-grey)" }}>
              They will no longer be able to view or collaborate on your map.
              Any suggestions they have already made will remain until you
              handle them.
            </p>
          </div>
        </MapmakerConfirmModal>
      )}

      <li
        id="collaborator-list-item"
        onClick={() => {
          if (selected) {
            onDeselect(collaborator);
          } else {
            onSelect(collaborator);
          }
        }}
        className={clsx({
          selected,
        })}
      >
        {visibleSuggestions.length === 0 ? (
          <FaEnvelope className="icon" size="24px" />
        ) : (
          <div className="icon">
            <div className="number">{visibleSuggestions.length}</div>
          </div>
        )}
        <div className="name">
          <div className="email">{displayName}</div>
          <div className="status">
            {collaborator.type === "active-public"
              ? "PUBLIC COLLABORATOR"
              : displayNameIsEmail
              ? "INVITED"
              : collaborator.email}
          </div>
        </div>
        {fileAccess && (
          <FaTrash
            onClick={() => setDeleteModalOpen(true)}
            fontSize={20}
            className="edit"
          />
        )}
      </li>
    </>
  );
}
