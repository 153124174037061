import { useProductPage } from "./ProductPageContext";
import { Select } from "semantic-ui-react";
import { Button } from "@mapmaker/ui";
import { FaCartPlus } from "@react-icons/all-files/fa/FaCartPlus";
import { FaTruck } from "@react-icons/all-files/fa/FaTruck";
import {
  useMapmakerAppConfig,
  useMapmakerContext,
  useStorefrontAddCheckoutLineItems,
} from "../../client";
import VariantSelectors from "./options/VariantSelectors";
import GiftOptions from "./options/GiftOptions";
import { MoneyV2, VariantPriceRange } from "../shared";
import { ProductPageOption } from "./options";
import "./ProductBuyBox.css";

export default function ProductBuyBox() {
  const { gotoCart } = useMapmakerAppConfig();
  const {
    dispatch,
    product,
    quantity,
    addingToCart,
    addToCartError,
    selectedOptions,
    totalPrice,
    selectedVariant,
    canBeAddedToCart,
    checkoutLineItems,
    plugin,
  } = useProductPage();
  const { SelectOptionsRenderer } = plugin;
  const {
    appConfig: { businessId },
  } = useMapmakerContext();
  const [addCheckoutLineItems] = useStorefrontAddCheckoutLineItems();

  async function addToCartClick() {
    if (!selectedVariant) {
      // Button should not be able to be pressed at this point.
      dispatch({
        type: "setAddToCartError",
        error: new Error("Please select all options."),
      });
      return;
    }
    try {
      dispatch({ type: "setAddingToCart" });
      await addCheckoutLineItems(checkoutLineItems);
      gotoCart();
    } catch (e) {
      dispatch({ type: "setAddToCartError", error: e as Error });
    }
  }

  let descriptionHtml = product.descriptionHtml;
  if (businessId === "tbl" && product.descriptionHtml.length > 200) {
    descriptionHtml = getOnlyFirstParagraph(product.descriptionHtml);
  }

  return (
    <div id="product-buy-box">
      <h1 className="title">{product?.title}</h1>
      <h3 className="price">
        <VariantPriceRange
          product={product}
          selectedOptions={selectedOptions}
        />
        {quantity > 1 ? <span className="each">/each</span> : null}
      </h3>
      <div className="free-shipping">
        <FaTruck /> FREE SHIPPING
      </div>
      <div
        className="description"
        dangerouslySetInnerHTML={{
          __html: descriptionHtml,
        }}
      />
      <div className="option-inputs">
        <VariantSelectors
          product={product}
          excludedSelections={plugin.managedSelections}
          onSelectionsChange={selectedOptions =>
            dispatch({
              type: "setSelectedOptions",
              selectedOptions,
            })
          }
        />
        <SelectOptionsRenderer />
        {!product.hideQuantity?.value && plugin.showQuantity && (
          <ProductPageOption>
            <label htmlFor="quantity">Quantity</label>
            <Select
              fluid
              labeled
              value={quantity}
              onChange={(_, { value }) =>
                dispatch({
                  type: "setQuantity",
                  quantity: typeof value === "number" ? value : 1,
                })
              }
              options={Array(25)
                .fill(0)
                .map((_, i) => {
                  return {
                    text: i + 1,
                    value: i + 1,
                    selected: quantity === i + 1,
                  };
                })}
            />
          </ProductPageOption>
        )}
      </div>
      <GiftOptions />
      <Button
        color="accent"
        id="add-to-cart-button"
        loading={addingToCart}
        fluid
        disabled={!canBeAddedToCart}
        onClick={addToCartClick}
      >
        {selectedVariant?.availableForSale === false ? (
          "Out of Stock"
        ) : (
          <>
            <FaCartPlus fontSize={16} /> Add to Cart -{" "}
            <MoneyV2 moneyV2={totalPrice} />
          </>
        )}
      </Button>
      {addToCartError && (
        <div className="addToCartError">
          {addToCartError?.message || "An error ocurred. Please try again."}
        </div>
      )}
    </div>
  );
}

// Temporary hack while we transfer over TBL so that we don't show the entire long product description.
function getOnlyFirstParagraph(str: string) {
  if (str.indexOf("</p>") === -1) {
    return str;
  }
  return str.substring(str.indexOf("<p>") + 3, str.indexOf("</p>"));
}
