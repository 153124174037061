import { useAuthContext } from "@tbl/aws-auth";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { RiAccountBoxLine } from "@react-icons/all-files/ri/RiAccountBoxLine";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { MdMap } from "@react-icons/all-files/md/MdMap";
import { GoPackage } from "@react-icons/all-files/go/GoPackage";
import { IconType } from "@react-icons/all-files";
import { Breadcrumbs, Breadcrumb, Button } from "@mapmaker/ui";
import { Suspense } from "react";
import LoadingPage from "../shared/LoadingPage";
import OrderDetails from "../orders/OrderDetails";
import OrderHistory from "../orders/OrderHistory";
import { MapmakerAccountStickerTokens } from ".";
import { StickerTokenIconGrayscale } from "../shared";
import AccountGeneralSettings from "./AccountGeneralSettings";
import "./AccountPage.css";

export default function AccountPage() {
  const { user, signOut } = useAuthContext();
  const { pathname } = useLocation();

  if (!user) {
    return <Navigate to="/account/login" />;
  }

  function AccountBreadcrumbs() {
    const [...paths] = pathname.split("/").filter(x => !!x);
    return (
      <div className="breadcrumbs">
        <FaUser className="user-icon" />
        <div>
          <Breadcrumbs>
            {paths.map(path => {
              if (path === "account") {
                return (
                  <Breadcrumb key={path} to="/account">
                    Your Account
                  </Breadcrumb>
                );
              } else {
                const option = Options.find(option => option.to === path);
                if (option) {
                  return (
                    <Breadcrumb key={path} to={option?.to}>
                      {option?.label}
                    </Breadcrumb>
                  );
                } else {
                  return <Breadcrumb key={path}>{path}</Breadcrumb>;
                }
              }
            })}
          </Breadcrumbs>
          <div className="account-email">{user.email}</div>
        </div>
      </div>
    );
  }

  return (
    <div id="mapmaker-account-page">
      <AccountBreadcrumbs />
      <Suspense fallback={<LoadingPage />}>
        <div className="content">
          <Routes>
            <Route path="" element={<AccountPageOptions />} />
            <Route path="general" element={<AccountGeneralSettings />} />
            <Route
              path="sticker-tokens"
              element={<MapmakerAccountStickerTokens />}
            />
            <Route path="orders" element={<OrderHistory />} />
            <Route path="orders/:orderNumber" element={<OrderDetails />} />
          </Routes>
        </div>
        <div className="bottom">
          <Button outlined onClick={signOut} style={{ marginLeft: 0 }}>
            <FaSignOutAlt /> Log Out
          </Button>
        </div>
      </Suspense>
    </div>
  );
}

const Options: AccountOptionProps[] = [
  {
    to: "general",
    label: "General Settings",
    description: "Basic account settings.",
    Icon: RiAccountBoxLine,
  },
  {
    to: "orders",
    label: "Orders",
    description: "View past orders.",
    Icon: GoPackage,
  },
  {
    to: "sticker-tokens",
    label: "Sticker Tokens",
    description: "Your sticker token balance and history.",
    Icon: StickerTokenIconGrayscale,
  },
  {
    to: "/mapmaker",
    label: "Map Maker Projects",
    description: "Create, view, and edit your Map maker projects.",
    Icon: MdMap,
  },
];

function AccountPageOptions() {
  return (
    <div id="mapmaker-account-page-options">
      {Options.map(option => (
        <AccountOption key={option.label} {...option} />
      ))}
    </div>
  );
}

type AccountOptionProps = {
  label: string;
  description: string;
  to: string;
  Icon: IconType;
};

function AccountOption({ label, description, to, Icon }: AccountOptionProps) {
  return (
    <Link to={to}>
      <div className="option">
        <Icon className="icon" />
        <div className="content">
          <h4>{label}</h4>
          <p>{description}</p>
        </div>
      </div>
    </Link>
  );
}
