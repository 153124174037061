import { ComponentType } from "react";
import { Card, Image } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { IBasePrintState } from "./ReduxPrintStates";
import { OpeningFeature, OpeningInput } from "@mapmaker/core";
import RectangularPrintThumbnail from "./RectangularPrintThumbnail";
import { RectangularPrintLayoutInput } from "../../../client/MapmakerApi";
import "./RectangularPrintCard.css";

export interface IRectangularPrintCardProps {
  printLayout: RectangularPrintLayoutInput;
  ActionButton?: ComponentType<IRectangularPrintActionButtonProps>;
}

export interface IRectangularPrintActionButtonProps {
  printLayouts: RectangularPrintLayoutInput[];
}

export default function RectangularPrintCard({
  printLayout,
  ActionButton = () => null,
}: IRectangularPrintCardProps) {
  const { design, file } = useSelector((state: IBasePrintState) => ({
    design: state.design,
    file: state.file,
  }));
  const openingsWithDetails = printLayout.printOpenings.map(printOpening => ({
    printOpening,
    opening: design.features[printOpening.openingId] as OpeningFeature,
    openingInputs: file.inputs[printOpening.openingId] as OpeningInput,
  }));
  const name =
    printLayout.printOpenings.length == 1
      ? openingsWithDetails[0].opening.name
      : `${printLayout.printOpenings.length} ${design.regionTypePlural}`;

  const allNames = openingsWithDetails
    .map(({ opening }) => opening.name)
    .join("\n");

  const maxSide = Math.max(printLayout.width, printLayout.height);
  const size = 8 * Math.pow(maxSide, 0.5);

  return (
    <Card id="rectangular-print-card" fluid>
      {/*this._renderMessagesModal()*/}
      <Image>
        <div className="svg-container" style={{ height: `${size}px` }}>
          <div className="zoom-catcher" title={allNames} onClick={() => {}}>
            <RectangularPrintThumbnail
              printLayout={printLayout}
              maxSvgWidth={size}
              maxSvgHeight={size}
            />
          </div>
        </div>
      </Image>
      <Card.Content>
        <div className="title" title={allNames}>
          {name}
        </div>
        <ActionButton printLayouts={[printLayout]} />
      </Card.Content>
      {/*this._renderMessages()*/}
    </Card>
  );
}
