import { useMemo } from "react";
import MaybeLink from "../../../components/shared/widgets/MaybeLink";
import { getNavigationPath } from "./navigationUtils";
import { useSanityContext } from "./SanityContext";
import "./SanityNavigationRelativeLinks.css";

export default function SanityNavigationRelativeLinks() {
  const {
    urlCreator,
    navigation,
    selectedTargetSlug,
    showHidden,
  } = useSanityContext();
  const path = getNavigationPath(navigation, selectedTargetSlug);
  const { childLinks, siblingLinks } = useMemo(() => {
    const children =
      path.length === 0 ? navigation.children : path[path.length - 1].children;
    const siblings =
      path.length === 0
        ? []
        : path.length === 1
        ? navigation.children
        : path[path.length - 2].children;
    return {
      childLinks: children?.filter(link => showHidden || !link?.hidden),
      siblingLinks: siblings?.filter(link => showHidden || !link?.hidden),
    };
  }, [path, navigation, showHidden]);

  if (childLinks) {
    // This is a section, show all the children
    return (
      <div id="sanity-navigation-child-links">
        <h2>In This Section</h2>
        <ul>
          {childLinks.map(link => (
            <li key={link?.target?.slug?.current}>
              <MaybeLink to={urlCreator(link?.target?.slug?.current)}>
                {link?.title ?? link?.target?.title}
              </MaybeLink>
            </li>
          ))}
        </ul>
      </div>
    );
  } else if (siblingLinks && siblingLinks.length > 0) {
    // This is a leaf, show previous and next if they exist.
    const index = siblingLinks.findIndex(
      link => link?.target?.slug?.current === selectedTargetSlug
    );
    const previousLink = index > 0 ? siblingLinks[index - 1] : null;
    const nextLink =
      index < siblingLinks.length - 1 ? siblingLinks[index + 1] : null;
    return (
      <div id="sanity-navigation-sibling-links">
        <div>
          {previousLink && (
            <MaybeLink to={urlCreator(previousLink?.target?.slug?.current)}>
              « {previousLink?.title ?? previousLink?.target?.title}
            </MaybeLink>
          )}
        </div>
        <div>
          {nextLink && (
            <MaybeLink to={urlCreator(nextLink?.target?.slug?.current)}>
              {nextLink?.title ?? nextLink?.target?.title} »
            </MaybeLink>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
