import { createContext, ReactNode, useContext, useState } from "react";

// Types
type ContextPanelState = {
  openSectionId?: string;
  setOpenSectionId: (sectionId: string) => void;
};

export type ContextPanelProviderProps = {
  children: ReactNode;
};

// Reducer
export default function useContextPanel() {
  const { openSectionId, setOpenSectionId } = useContext<ContextPanelState>(
    ContextPanelContext
  );
  const open = !!openSectionId;

  function openSection(sectionId: string) {
    setOpenSectionId(sectionId);
  }

  function closeSection() {
    setOpenSectionId(null);
  }

  return {
    open,
    openSectionId,
    openSection,
    closeSection,
  };
}

// Context creation
const initialData: ContextPanelState = {
  openSectionId: null,
  setOpenSectionId: () => {},
};
const ContextPanelContext = createContext<ContextPanelState>(initialData);

// Provider
export const ContextPanelProvider = ({
  children,
}: ContextPanelProviderProps) => {
  const [openSectionId, setOpenSectionId] = useState(null);

  return (
    <ContextPanelContext.Provider
      value={{
        openSectionId,
        setOpenSectionId,
      }}
    >
      {children}
    </ContextPanelContext.Provider>
  );
};
