import { Header, Icon } from "semantic-ui-react";
import ContextPanelContent from "../../project/context/ContextPanelContent";
import ContextPanelSection from "../../project/context/ContextPanelSection";
import MapmakerButton from "../../shared/buttons/MapmakerButton";
import RectangularPrintSettings from "../shared/RectangularPrintSettings";
import RectangularPrintSettingsAdvanced from "../shared/RectangularPrintSettingsAdvanced";
import useWalgreensCheckout from "./useWalgreensCheckout";

export default function WalgreensPrintsPanelContent() {
  const {
    formattedSubtotal,
    beginCheckout,
    canCheckout,
  } = useWalgreensCheckout();

  return (
    <ContextPanelContent>
      <ContextPanelSection
        sectionId="checkout-button"
        label="Checkout"
        hideTitleBar
        icon="arrow right"
        primary
      >
        <div style={{ padding: "2em" }}>
          <Header>Subtotal {formattedSubtotal || "-"}</Header>
          <MapmakerButton
            fluid
            onClick={beginCheckout}
            color="accent"
            disabled={!canCheckout}
          >
            Checkout <Icon name="arrow right" />
          </MapmakerButton>
        </div>
      </ContextPanelSection>
      <ContextPanelSection sectionId="settings" label="Settings" icon="setting">
        <RectangularPrintSettings />
      </ContextPanelSection>
      <ContextPanelSection
        sectionId="advanced"
        label="Advanced"
        icon="lab"
        initialContentOpen={false}
      >
        <RectangularPrintSettingsAdvanced />
      </ContextPanelSection>
    </ContextPanelContent>
  );
}
