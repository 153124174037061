import prod from "./MapmakerConfig.prod";
import type { MapmakerConfig } from ".";

const config:MapmakerConfig = {
  ...prod,
  stage: "gamma",
  apiGateway: {
    url: "https://api-gamma.photomaps.com",
  },
  staticUrl: "https://static-gamma.photomaps.com",
};

export default config;