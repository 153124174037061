import { ReactNode } from "react";
import "./ContextPanelFull.css";

export interface IContextPanelFullProps {
  children: ReactNode;
}

export default function ContextPanelFull({ children }: IContextPanelFullProps) {
  return <div id="context-panel-full">{children}</div>;
}
