import MapmakerConfig from "@mapmaker/config";
import { useSelector } from "react-redux";
import { IBasePrintState } from "../shared/ReduxPrintStates";
import { getPrintFilename, getPrintFilenameSize } from "./downloadPrintUtils";
import { DownloadPrintsStore } from "./downloadPrintsStore";
import useRectangularPrintsSelector from "../shared/useRectangularPrintsSelector";
import { useMapmakerAppConfig } from "../../..";
import { getPrintingService } from "../PrintServices";
import { MapmakerPrintServiceId } from "../PrintServiceIds";
import "./DownloadPrintListHeader.css";

export default function DownloadPrintListHeader() {
  const design = useSelector((state: IBasePrintState) => state.design);
  const downloadOptions = useSelector(
    (state: DownloadPrintsStore) => state.downloadOptions
  );
  const service = getPrintingService(MapmakerPrintServiceId.DOWNLOAD);
  const { gotoPrintSizingHelp } = useMapmakerAppConfig();
  const { printLayouts } = useRectangularPrintsSelector();
  const example = printLayouts[0];
  const exampleFilename =
    getPrintFilename(example, design) + "." + downloadOptions.format;
  const exampleSize = getPrintFilenameSize(example);
  return (
    <div id="download-print-list-header">
      <img className="printer" src={service.imageSmall} />
      <div>
        <h3>Download and Print</h3>
        <p>
          Download and print the files{" "}
          <strong>at the sizes shown below.</strong> Each filename will start
          with the size it needs to be printed at. Eg. <i>{exampleFilename}</i>{" "}
          needs to be printed onto <i>{exampleSize}</i> paper. Printing at any
          other size, or with a margin or padding will cause the prints not to
          fit in your photo map.
        </p>
        {gotoPrintSizingHelp && (
          <p>
            If you are printing the photos yourself and having trouble with the
            sizing, please see our{" "}
            <span className="link" onClick={gotoPrintSizingHelp}>
              print sizing guide
            </span>{" "}
            for help.
          </p>
        )}
      </div>
    </div>
  );
}
