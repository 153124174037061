import React from "react";
import SvgNode from "./SvgNode";
import { SVGNode } from "@mapmaker/core";

const CLIPPABLE_SHAPES = {
  path: true,
  text: true,
  rect: true,
  circle: true,
  ellipse: true,
  polyline: true,
  line: true,
  polygon: true,
};

type Props = {
  id: string;
  nodeData?: SVGNode;
};

/*
 * Renders a path (usually a compound path) of a layer with the openings cut out (eg. a piece of
 * matboard which would be cut).
 */
export default function ClipPath({ id, nodeData = null }: Props) {
  /*
   * An SVG clipPath element can only contain certain elements. So eliminate
   * any non-clippable elements flatten their children.
   */
  function getPathElements(rootNodeData) {
    function addNode(nodeData, accumulator) {
      if (nodeData.name in CLIPPABLE_SHAPES) {
        accumulator.push(nodeData);
      } else if (nodeData.children) {
        nodeData.children.forEach(childNodeData =>
          addNode(childNodeData, accumulator)
        );
      }
    }

    const clippable = [];
    addNode(rootNodeData, clippable);
    return clippable;
  }

  return (
    <clipPath id={id}>
      {getPathElements(nodeData).map((pathElement, i) => {
        return <SvgNode key={i} nodeData={pathElement} />;
      })}
    </clipPath>
  );
}
