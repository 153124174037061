import clsx from "clsx";
import { Field, useFormikContext } from "formik";
import { RenderableInputProps } from ".";
import { useGetDesignsQuery } from "../../../client/MapmakerApi";
import "./RenderableInput.css";

export type RenderableDesignInputProps = RenderableInputProps<"design", string>;

export default function RenderableDesignInput({
  id,
  name,
  description,
}: RenderableDesignInputProps) {
  const { setFieldValue, values } = useFormikContext();

  const { loading, data } = useGetDesignsQuery({
    variables: {
      request: {
        includeHidden: true,
      },
    },
    onCompleted: data => {
      const newValue = values[id] || { DESIGN: data.designs[0].id };
      setFieldValue(id, newValue || null);
    },
  });

  return (
    <Field type="select" name={id}>
      {({ field, form, meta }) => (
        <div
          id="renderable-input"
          className={clsx({ error: meta.touched && meta.error, loading })}
        >
          <div className="above">
            <label>{name}</label>
          </div>
          <select
            name={id}
            value={field.value?.DESIGN}
            onChange={e =>
              form.setFieldValue(field.name, {
                DESIGN: e.currentTarget.value,
              })
            }
            onBlur={field.onBlur}
          >
            {data?.designs.map(design => (
              <option key={design.id} value={design.id}>
                {design.name}
              </option>
            ))}
          </select>
          {description && <div className="description">{description}</div>}
          {meta.touched && meta.error && meta.value && (
            <div className="error">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
}
