import {
  getFeaturesList,
  MapmakerDesign,
  OpeningFeature,
  SVGNode,
} from "@mapmaker/core";
import { getNodePathsRecursive } from "../../utils/nodeUtils";

/**
 * Return the best match for a layer or a prioritized list of layers in an opening.
 * undefined if no layers match.
 */
/*
export function getBestMatchedLayer(opening, layerOrLayers) {
  const layers = Array.isArray(layerOrLayers) ? layerOrLayers : [layerOrLayers];
  const matchedLayerName = layers.find(
    layerName => !!opening.getIn(["layout", layerName])
  );
  if (matchedLayerName) {
    return opening.getIn(["layout", matchedLayerName]);
  } else {
    return undefined;
  }
}
*/

/**
 * Returns an array of all of a openings in a blueprint.
 */
/*
export function getOpenings(blueprint) {
  return blueprint
    .get("features")
    .filter(feature => feature.get("type") == "OPENING");
}
*/

/**
 * Returns an immutable array of all of a openings in a blueprint which have a node on the
 * provided layer/layers.  layerOrLayers can either be a single string layer, or a prioritized
 * list of layers to look for in each opening.
 */
/*
export function getOpeningsWithLayer(blueprint, layerOrLayers) {
  return getOpenings(blueprint).reduce((openings, opening) => {
    // If there is a matched layer, add the opening to the list.
    if (getBestMatchedLayer(opening, layerOrLayers)) {
      openings.push(opening);
    }
    return openings;
  }, []);
}
*/

/**
 * Returns an immutable array of all of a particular opening layer from a given blueprint.
 * layerOrLayers can either be a single string layer, or a prioritized list of layers to look
 * for in each opening.
 */
/*
export function getOpeningLayerNodes(blueprint, layerOrLayers) {
  const openings = getOpeningsWithLayer(blueprint, layerOrLayers);
  if (!openings) {
    return null;
  }
  return openings.map(opening => getBestMatchedLayer(opening, layerOrLayers));
}
*/

/**
 * Returns a compound path built from all the layers of a given node.
 */
/*
export function getAllOpeningsLayerPath(blueprint, layerOrLayers) {
  const nodes = getOpeningLayerNodes(blueprint, layerOrLayers);
  if (!nodes) {
    return null;
  }
  return nodes
    .map(node => (getNodePathsRecursive(node) || []).join(" "))
    .join(" ");
}
*/

/**
 * Returns the complete compund path for the opening of a single node.
 */
/*
export function getOpeningLayerPath(opening, layerOrLayers) {
  const nodePaths = getNodePathsRecursive(
    getBestMatchedLayer(opening, layerOrLayers)
  );
  if (!nodePaths) {
    return null;
  } else {
    return nodePaths.join(" ");
  }
}
*/

function getCompoundPathIncludingChildren(nodeData: SVGNode): string {
  let compoundPath = "";
  if (nodeData.d) {
    compoundPath += nodeData.d;
  }
  if (nodeData.children?.length > 0) {
    compoundPath += nodeData.children.reduce((childrenPath, child) => {
      return childrenPath + getCompoundPathIncludingChildren(child);
    }, compoundPath);
  }
  return compoundPath;
}

export function getAllOpeningsLayerPath(
  design: Pick<MapmakerDesign, "height" | "width" | "features">,
  layer: string,
  shrinkBorderPt: number
): string {
  const w = design.width;
  const h = design.height;
  const layers = getFeaturesList<OpeningFeature>(design, "OPENING")
    .map((feature: OpeningFeature) => feature.layout[layer])
    .filter(layer => layer);
  if (layers.length === 0 && layer !== "background") {
    return null;
  }
  const s = shrinkBorderPt;
  const s2 = s * 2;
  const path = layers.reduce(
    (path, layer) => {
      return path + getCompoundPathIncludingChildren(layer);
    },
    [
      // Comment to keep this from going to one line on lint
      `M ${s} ${s}`,
      `L ${s} ${h - s2}`,
      `L ${w - s2} ${h - s2}`,
      `L ${w - s2} ${s}`,
      `L ${s} ${s} z`,
    ].join("")
  );
  return path;
}
