import * as yup from "yup";
import { RenderableForm } from ".";

export const OpeningsPrintSvgForm: RenderableForm = {
  initialValues: {
    printOptions: {
      backgroundColor: "#FFFFFF",
      borderColor: undefined,
      borderWidth: undefined,
      clipImage: false,
      includeBuffer: true,
      printingGuides: false,
    },
  },
  inputs: [
    {
      type: "file",
      id: "file",
      name: "Project",
      validation: yup.string().required(),
    },
    {
      type: "file-opening",
      id: "openingId",
      name: "Regions",
      validation: yup.array().of(yup.string()).required(),
      fileProperty: "file",
      multiple: true,
    },
    {
      type: "rectangular-print-layout",
      id: "printLayout",
      name: "Print Layout",
    },
    {
      type: "rectangular-print-options",
      id: "printOptions",
      name: "Print Options",
    },
  ],
};
