import React, { useCallback, useState } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";
import AuthBoxLayout from "./AuthBoxLayout";
import { useAuthContext } from "../AWSAuthContext";
import { AuthBoxState, useAuthBoxContext } from "./AuthBox";
import FieldErrorMessage from "./shared/FieldErrorMessage";
import FormErrorMessage from "./shared/FormErrorMessage";
import UserNotFoundMessage from "./shared/UserNotFoundMessage";
import { UserNotConfirmedExceptionName } from "../AWSAuthErrors";
import { Button } from "@mapmaker/ui";

interface SendPasswordResetCodeFormValues {
  email: string;
}

const SendPasswordResetCodeSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Enter your email"),
});

export default function AuthBoxSendPasswordResetCode() {
  const { sendPasswordReset } = useAuthContext();
  const {
    authenticationDetails,
    setAuthBoxState,
    setAuthenticationDetails,
  } = useAuthBoxContext();
  const [sendPasswordResetCodeError, setSendPasswordResetCodeError] = useState<
    string | React.ReactNode
  >();

  async function onSubmit(values: SendPasswordResetCodeFormValues) {
    try {
      await sendPasswordReset(values.email);
      setAuthenticationDetails({ email: values.email });
      setAuthBoxState(AuthBoxState.RESET_PASSWORD);
    } catch (e) {
      if (e.name === UserNotConfirmedExceptionName) {
        setSendPasswordResetCodeError(
          <UserNotFoundMessage email={values.email} />
        );
      } else {
        setSendPasswordResetCodeError(e.message);
      }
    }
  }

  return (
    <Formik
      initialValues={{
        email: authenticationDetails?.email || "",
      }}
      validationSchema={SendPasswordResetCodeSchema}
      onSubmit={onSubmit}
    >
      <AuthBoxSendPasswordResetCodeForm
        sendPasswordResetCodeError={sendPasswordResetCodeError}
      />
    </Formik>
  );
}

function AuthBoxSendPasswordResetCodeForm({ sendPasswordResetCodeError }) {
  const { setAuthBoxState } = useAuthBoxContext();
  const { isSubmitting, isValid } = useFormikContext<
    SendPasswordResetCodeFormValues
  >();

  const gotoSignIn = useCallback(() => {
    setAuthBoxState(AuthBoxState.SIGN_IN);
  }, [setAuthBoxState]);

  const gotoSignUp = useCallback(() => {
    setAuthBoxState(AuthBoxState.SIGN_UP);
  }, [setAuthBoxState]);

  const Alternatives = (
    <>
      <span className="link" onClick={gotoSignUp}>
        Register
      </span>{" "}
      /{" "}
      <span className="link" onClick={gotoSignIn}>
        Log In
      </span>
    </>
  );

  return (
    <AuthBoxLayout>
      <AuthBoxLayout.Header title="Reset Password" alternative={Alternatives} />
      <AuthBoxLayout.Form>
        <Form>
          <div className="field">
            <label htmlFor="email">Email</label>
            <Field
              name="email"
              type="email"
              placeholder="bob@example.com"
              autoComplete="email"
              autoFocus
            />
            <FieldErrorMessage name="email" />
          </div>
          <div className="buttons">
            <Button
              color="accent"
              fluid
              type="submit"
              disabled={!isValid}
              loading={isSubmitting}
            >
              Reset Password
            </Button>
            <FormErrorMessage message={sendPasswordResetCodeError} />
          </div>
        </Form>
      </AuthBoxLayout.Form>
    </AuthBoxLayout>
  );
}
