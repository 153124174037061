import React from "react";
import {
  MapmakerBusinessId,
  MapmakerDesign,
  OpeningFeature,
  OpeningInput,
  RectangularPrintLayoutInputWithFiles,
} from "@mapmaker/core";
import OpeningGroup from "./OpeningGroup";
import OpeningImages from "./OpeningImages";
import SvgNode from "../SvgNode";
import {
  MapmakerSvgContextProvider,
  MapmakerSvgContextValue,
} from "../MapmakerSvgContext";
import { getLayerByPriority } from "./openingUtils";
import { Barcode128Bars } from "../shared/Barcode128";

/*
 * Renders an SVG which is suitable for printing a photo which has 1 or more images that when cut
 * out will fit into the opening of a design.
 */
export interface IOpeningsStickerSheetStickersSvgProps {
  design: Pick<MapmakerDesign, "features" | "businessId">;
  printLayout: RectangularPrintLayoutInputWithFiles;
  width: number;
  height: number;
  sheetInfoHeight: number;
  sheetBleed: number;
  sheetNumber: number;
  totalSheets: number;
  primaryColor?: string;
  secondaryColor?: string;
  primaryText?: string;
  secondaryText?: string;
  identifierText?: string;
  barcodeData?: string;
  onImageLoadError?: MapmakerSvgContextValue["onImageLoadError"];
}

export default function OpeningsStickerSheetStickersSvg({
  design,
  printLayout,
  width,
  height,
  sheetInfoHeight,
  sheetBleed,
  primaryColor,
  sheetNumber,
  totalSheets,
  secondaryColor,
  secondaryText,
  identifierText,
  primaryText,
  barcodeData,
  onImageLoadError,
}: IOpeningsStickerSheetStickersSvgProps) {
  // Always use 300 DPI images for sticker sheets.
  const viewboxWidth = printLayout.width;
  const viewboxHeight = printLayout.height + sheetInfoHeight;
  const svgScale = Math.max(
    ((width / viewboxWidth) * 300) / 72,
    ((height / viewboxHeight) * 300) / 72
  );
  const barcodeBounds = {
    x: 16,
    y: sheetInfoHeight - 0.2 * 72 - 6,
    width: 1.5 * 72,
    height: 0.2 * 72,
  };

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`${-sheetBleed} ${-sheetBleed} ${
        viewboxWidth + sheetBleed * 2
      } ${viewboxHeight + sheetBleed * 2}`}
      width={width}
      height={height}
      style={{
        position: "relative",
        pageBreakAfter: "always",
      }}
    >
      <rect
        x={-sheetBleed}
        y={-sheetBleed}
        width={viewboxWidth + sheetBleed * 2 + 1}
        height={sheetInfoHeight + sheetBleed}
        fill={primaryColor}
      />
      <text
        x={barcodeBounds.x}
        y={barcodeBounds.y - 2}
        fontFamily="Lato"
        fill={secondaryColor}
        fontSize={11}
      >
        {identifierText}-{getSheetContextDigits(sheetNumber, totalSheets)}
      </text>
      <Barcode128Bars
        value={barcodeData ?? "STICKERS"}
        darkColor={primaryColor}
        lightColor={secondaryColor}
        paddingX={8}
        cornerRadius={3}
        {...barcodeBounds}
      />
      <text
        x={viewboxWidth - 0.1 * 72}
        y={0.08 * 72}
        fontFamily="Lato"
        fontWeight="bold"
        fill={secondaryColor}
        fontSize={14}
        textAnchor="end"
        dominantBaseline="hanging"
      >
        {primaryText ?? "PRIMARY TEXT"}
      </text>
      <text
        x={viewboxWidth - 0.1 * 72}
        y={sheetInfoHeight - 0.1 * 72}
        fontFamily="Lato"
        fill={secondaryColor}
        fontSize={10}
        textAnchor="end"
        dominantBaseline="baseline"
      >
        {secondaryText ?? "SECONDARY TEXT"}
      </text>

      <MapmakerSvgContextProvider
        value={{
          svgScale,
          onImageLoadError,
          businessId: design.businessId as MapmakerBusinessId,
        }}
      >
        {printLayout.printOpenings.map((printOpening, i) => {
          if (!printOpening.file) {
            throw new Error(
              "Nested opening does not contain file data, so we cannot create the sticker."
            );
          }
          const opening = design.features[
            printOpening.openingId
          ] as OpeningFeature;
          const openingInput = printOpening.file.inputs[
            printOpening.openingId
          ] as OpeningInput;

          const cutLayer = getLayerByPriority(
            opening,
            design.businessId === "tbl" && !opening.outlined
              ? ["print", "cut"]
              : ["cut", "print"]
          );

          return (
            <OpeningGroup
              key={`${opening.id}-${i}`}
              opening={opening}
              layer={opening.outlined ? "cut" : "print"}
              x={printOpening.x}
              y={printOpening.y + sheetInfoHeight}
              rotation={printOpening.rotation}
              scale={printOpening.scale}
            >
              {openingInput.background ? (
                <SvgNode
                  nodeData={opening.layout.print}
                  fill={openingInput.background}
                />
              ) : null}
              <OpeningImages
                opening={opening}
                openingInput={openingInput}
                clipLayer="print"
              />
              {/* This path gives us our sticker cutter data. */}
              <SvgNode
                nodeData={cutLayer}
                /* Important. Special color code that the sticker generator looks for. */
                stroke="#FF00FF"
                strokeWidth={1}
                fill="none"
              />
            </OpeningGroup>
          );
        })}
      </MapmakerSvgContextProvider>
      {/* Through Cut */}
      <rect
        x={0}
        y={0}
        width={viewboxWidth}
        height={viewboxHeight}
        fill="none"
        rx={0.25 * 72}
        /* Important. Special color code that the sticker generator looks for. */
        stroke="#00AB4E"
      />
    </svg>
  );
}

function getSheetContextDigits(sheetIndex: number, sheetCount: number): string {
  if (sheetCount === 1) {
    return "★";
  }
  const digits = sheetCount.toString().length;
  return sheetCount + (sheetIndex + 1).toString().padStart(digits, "0");
}
