import { Header, Icon } from "semantic-ui-react";
import useWalgreensCheckout from "./useWalgreensCheckout";
import MapmakerButton from "../../shared/buttons/MapmakerButton";
import "./WalgreensPrintListFooter.css";

export default function WalgreensPrintListFooter() {
  const {
    formattedSubtotal,
    canCheckout,
    beginCheckout,
  } = useWalgreensCheckout();

  return (
    <div id="walgreens-print-list-footer">
      <div className="action">
        <Header>Subtotal {formattedSubtotal}</Header>
        <MapmakerButton
          color="accent"
          onClick={beginCheckout}
          disabled={!canCheckout}
        >
          Checkout <Icon name="arrow right" />
        </MapmakerButton>
      </div>
    </div>
  );
}
