import React from "react";

type Props = {
  id: string;
};

export default function MatShadowFilter({ id }: Props) {
  return (
    <filter id={id}>
      <feDropShadow
        dx={4}
        dy={4}
        stdDeviation={6}
        floodColor="#000000"
        floodOpacity={0.5}
      />
      <feDropShadow
        dx={0}
        dy={0}
        stdDeviation={2}
        floodColor="#000000"
        floodOpacity={0.5}
      />
    </filter>
  );
}
