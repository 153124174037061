import { Field, useFormikContext } from "formik";
import clsx from "clsx";
import { RenderableInputProps } from ".";
import { gql } from "@apollo/client";
import { useState } from "react";
import { useRenderableFileListQuery } from "../../../client/MapmakerApi";
import "./RenderableInput.css";

gql`
  query renderableFileList {
    mapmakerFiles {
      ...MapmakerFileLite
    }
  }
`;

export type RenderableFileInputProps = RenderableInputProps<"file", string>;

export default function RenderableFileInput({
  id,
  name,
  description,
}: RenderableFileInputProps) {
  const { setFieldValue, values } = useFormikContext();
  const [custom, setCustom] = useState(false);

  const { loading, data } = useRenderableFileListQuery({
    onCompleted: data => {
      const newValue = values[id] || { FILE: data.mapmakerFiles[0]?.id ?? "" };
      setFieldValue(id, newValue || null);
      if (!data.mapmakerFiles.find(file => file.id === newValue.FILE)) {
        setCustom(true);
      }
    },
  });

  return (
    <Field type="select" name={id}>
      {({ field, form, meta }) => {
        return (
          <div
            id="renderable-input"
            className={clsx({
              error: meta.touched && meta.error,
              loading,
            })}
          >
            <div className="above">
              <label>{name}</label>
            </div>
            {custom ? (
              <div className="input-container">
                <input
                  {...field}
                  value={field.value?.FILE}
                  onChange={e => {
                    const value = e.currentTarget.value;
                    if (value.length === 6) {
                      setFieldValue(id, { FILE: value });
                    } else {
                      setFieldValue(id, null);
                    }
                  }}
                  maxLength={6}
                />
                <span
                  className="decoration"
                  onClick={() => {
                    setCustom(false);
                    setFieldValue(id, null);
                  }}
                >
                  ×
                </span>
              </div>
            ) : (
              <select
                name={id}
                value={field.value?.FILE}
                onChange={e => {
                  const value = e.currentTarget.value;
                  if (value === "__CUSTOM__") {
                    setCustom(true);
                    setFieldValue(id, null);
                  } else {
                    form.setFieldValue(field.name, {
                      FILE: value,
                    });
                  }
                }}
                onBlur={field.onBlur}
              >
                {data?.mapmakerFiles.map(file => (
                  <option key={file.id} value={file.id}>
                    {file.name}
                  </option>
                ))}
                {!loading && <option value="__CUSTOM__">OTHER</option>}
              </select>
            )}
            {description && <div className="description">{description}</div>}
            {meta.touched && meta.error && meta.value && (
              <div className="error">{meta.error}</div>
            )}
          </div>
        );
      }}
    </Field>
  );
}
