import SettingSection from "./SettingSection";
import { useProjectFile } from "../../useProjectState";
import { useProjectDispatch } from "../../projectStore";
import { updateFileName } from "../../fileReducer";
import "./GeneralSettings.css";

export default function GeneralSettings() {
  const dispatch = useProjectDispatch();
  const file = useProjectFile();

  return (
    <SettingSection id="general-settings" label="General Settings">
      <form>
        <div className="input-row">
          <label htmlFor="fileName">Map Name</label>
          <input
            name="fileName"
            defaultValue={file.name}
            onChange={e => dispatch(updateFileName(e.target.value))}
          />
        </div>
      </form>
    </SettingSection>
  );
}
