import {
  StickerOrderForSandboxFragment,
  useGetStickerOrdersForSandboxQuery,
} from "../../client/MapmakerApi";
import Suspender from "react-suspender";
import { getNestGroupMap, nestStickers, BinShrinkMode } from "@mapmaker/nester";
import { OpeningStickerSheet } from "@mapmaker/svg";
import ErrorPage from "../../lib/errors/ErrorPage";
import { gql } from "@apollo/client";
import "./MapmakerStickerSheet.css";

gql`
  fragment StickerOrderForSandbox on StickerOrder {
    id
    businessId
    createdAt
    outputType
    stickers {
      openingId
      sizeClass
      quantity
      quantityWithMoney
      quantityWithTokens
      scale
      render {
        url
      }
    }
    file {
      id
      inputs
      design {
        id
        businessId
        height
        width
        features
      }
    }
  }

  query getStickerOrdersForSandbox($ids: [String!]!) {
    stickerOrders(ids: $ids) {
      ...StickerOrderForSandbox
    }
  }
`;

export interface IMapmakerStickerSheetProps {
  orderIds: string[];
}

export default function MapmakerStickerSheet({
  orderIds,
}: IMapmakerStickerSheetProps) {
  const { data: { stickerOrders } = {}, error } =
    useGetStickerOrdersForSandboxQuery({
      variables: {
        ids: orderIds,
      },
    });
  if (error) {
    return <ErrorPage message={error.message} />;
  } else if (!stickerOrders) {
    return <Suspender />;
  }

  const nestGroupMap = getNestGroupMap(stickerOrders);

  return (
    <div id="mapmaker-sticker-sheets">
      <div className="sheets">
        {Object.values(nestGroupMap).map((stickerOrdersForGroup) => (
          <MapmakerStickerSheetWithOrders
            key={stickerOrdersForGroup[0].id}
            stickerOrders={stickerOrdersForGroup}
            recipientName="Recipient Name"
            storeOrderId="#order-id"
          />
        ))}
      </div>
    </div>
  );
}

interface MapmakerStickerSheetWithOrdersProps {
  stickerOrders: StickerOrderForSandboxFragment[];
  recipientName: string;
  storeOrderId: string;
}

function MapmakerStickerSheetWithOrders({
  stickerOrders,
  recipientName,
}: MapmakerStickerSheetWithOrdersProps) {
  const design = stickerOrders[0].file.design;
  const pageWidth = 5.3 * 72;
  const pageHeight = 10.9 * 72;
  const { printLayouts } = nestStickers(
    stickerOrders,
    stickerOrders[0].file.design,
    {
      width: pageWidth,
      height: pageHeight - OpeningStickerSheet.SheetInfoHeight,
      border: (1 / 16) * 72,
      padding: (1 / 16) * 72,
      shrinkMode: BinShrinkMode.None,
      shrinkModeSinglePage: BinShrinkMode.None,
    }
  );

  return (
    <div id="mapmaker-sticker-sheet-group">
      <div className="group-details">
        <h3>Sticker Group Details</h3>
        <div className="datum">
          <label>Order IDs</label>
          <span>{stickerOrders.map((o) => o.id).join(", ")}</span>
        </div>
        <div className="datum">
          <label>Output Type</label>
          <span>{stickerOrders[0].outputType}</span>
        </div>
        <div className="datum">
          <label>Scale</label>
          <span>{stickerOrders[0].stickers[0].scale}</span>
        </div>
        <div className="datum">
          <label>Sheets</label>
          <span>{printLayouts.length}</span>
        </div>
      </div>

      {printLayouts.map((printLayout, i) => {
        return (
          <div key={i} className="sheet">
            <OpeningStickerSheet
              sheetIndex={i}
              totalSheets={printLayouts.length}
              design={design}
              dpiScaleFactor={1}
              printLayout={printLayout}
              stickerOrderFileMap={stickerOrders}
              primaryText={recipientName}
              secondaryText="Photo Map Stickers"
              primaryColor={"var(--mm-accent-color)"}
              secondaryColor={"var(--mm-accent-text-color)"}
              identifierText="IDENTITY"
            />
          </div>
        );
      })}
    </div>
  );
}
