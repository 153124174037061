import { getOpeningInputs } from "@mapmaker/core";
import { Button } from "@mapmaker/ui";
import { useAuthContext } from "@tbl/aws-auth";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { FaExclamationCircle } from "@react-icons/all-files/fa/FaExclamationCircle";
import { FaSignInAlt } from "@react-icons/all-files/fa/FaSignInAlt";
import { useMapmakerAppConfig } from "../../../client";
import { MapmakerFileViewerPolicy } from "../../../client/MapmakerApi";
import useDelayed from "../../../lib/hooks/useDelayed";
import { useAddToastMessage } from "../shared/toasts/useProjectToastMessages";
import { useProjectFile } from "../useProjectState";

export function useGuestModeMessage() {
  const { isAuthenticated } = useAuthContext();
  const toast = useAddToastMessage();
  const file = useProjectFile();

  useDelayed(() => {
    // Don't need to warn unless they are a guest.
    if (isAuthenticated) {
      return;
    }
    // Don't show this warning if they are not viewing their own map
    if (file.viewerPolicy !== MapmakerFileViewerPolicy.Owner) {
      return;
    }
    // Wait to warn until they've done at least a little work.
    if (
      getOpeningInputs(file).reduce(
        (sum, input) => sum + input.images?.length,
        0
      ) > 1
    ) {
      return;
    }
    toast.addToastMessage({
      id: "guest-mode-message",
      type: "info",
      title: "You are in Guest Mode",
      icon: FaExclamationCircle,
      content: <GuestModeMessage />,
      duration: 30,
      persistance: "session",
      layer: "help",
    });
  }, 2000);
}

function GuestModeMessage() {
  const { gotoSignUp, gotoSignIn } = useMapmakerAppConfig();

  return (
    <div id="not-logged-in-message">
      <p>
        Your project will only be saved in this browser. It may be lost if you
        clear your browser history. Good news though, accounts are absolutely
        free! Register now and this project will be saved permanently to the new
        account and accessible from any device.
      </p>
      <Button color="accent" onClick={() => gotoSignUp()}>
        Register <FaArrowRight />
      </Button>{" "}
      <Button onClick={() => gotoSignIn()}>
        Log In <FaSignInAlt />
      </Button>
    </div>
  );
}
