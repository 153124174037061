import { captureException } from "@sentry/react";
import clsx from "clsx";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { Icon } from "semantic-ui-react";
import "./OpeningColorPicker.css";
import useEditableOpening from "./editable/useEditableOpening";

interface EyeDropperConstructor {
  new (): EyeDropper;
}

interface ColorSelectionOptions {
  signal: AbortSignal;
}

interface ColorSelectionResult {
  sRGBHex: string;
}

interface EyeDropper extends EyeDropperConstructor {
  open: (options?: ColorSelectionOptions) => Promise<ColorSelectionResult>;
}

declare global {
  interface Window {
    EyeDropper: EyeDropper | undefined;
  }
}

export interface IOpeningColorPickerProps {
  value: string;
  onChange(color: string): any;
  onClose?(): any;
}

export default function OpeningColorPicker({
  value,
  onChange,
}: IOpeningColorPickerProps) {
  const hasEyeDropper = !!window.EyeDropper;
  const { openingInput } = useEditableOpening();
  async function onEyeDropperClick() {
    if (window.EyeDropper) {
      try {
        /* @ts-ignore */
        const eyeDropper = new EyeDropper();
        const color = await eyeDropper.open();
        onChange(color.sRGBHex);
      } catch (e) {
        captureException(e);
      }
    }
  }

  return (
    <div id="opening-color-picker">
      <HexColorPicker color={value} onChange={onChange} />
      <div
        className={clsx({
          "hex-input": true,
          "has-background": !!openingInput.background,
        })}
      >
        {hasEyeDropper && (
          <Icon
            name="eye dropper"
            className="eye-dropper"
            onClick={onEyeDropperClick}
          />
        )}
        <HexColorInput color={value} onChange={onChange} placeholder="000000" />
        <Icon
          className="trash"
          name="trash"
          onClick={() => onChange(undefined)}
        />
      </div>
    </div>
  );
}
