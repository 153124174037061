import { PropsWithChildren } from "react";
import { useAuthContext } from "@tbl/aws-auth";
import clsx from "clsx";
import { MapmakerFileAccessPolicy } from "../../../../client/MapmakerApi";
import useCopyToClipboard from "react-use/lib/useCopyToClipboard";
import { Icon } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { useMapmakerAppConfig } from "../../../../client/MapmakerAppConfig";
import { saveFile, updateAccessPolicy } from "../../fileReducer";
import { useProjectFile } from "../../useProjectState";
import SelectableSetting from "./SelectableSetting";
import SettingSection from "./SettingSection";
import { useProjectDispatch } from "../../projectStore";
import { Button } from "@mapmaker/ui";
import useMapmakerModals from "../../../shared/modals/useMapmakerModals";
import "./SharingSettings.css";
import { SharingLink } from "../../shared/SharingLink";

type SharingSettingsProps = {
  onLeave(): any;
  hideLabel?: boolean;
};

export default function SharingSettings({
  onLeave,
  hideLabel,
}: SharingSettingsProps) {
  const dispatch = useProjectDispatch();
  const file = useProjectFile();
  const { isAuthenticated } = useAuthContext();
  const { gotoEditFile, gotoSignUp } = useMapmakerAppConfig();

  async function selectAccessPolicy(accessPolicy: MapmakerFileAccessPolicy) {
    dispatch(updateAccessPolicy(accessPolicy));
    dispatch(saveFile(true));
  }

  function AuthenticateOnlyWarning() {
    return (
      <div className="warning unauthenticated-only">
        <Icon name="warning circle" />
        You must have an account in order to make your map public. Good news
        though, it is free!{" "}
        <span className="link" onClick={() => gotoSignUp(window.location.href)}>
          Register here
        </span>
        .
      </div>
    );
  }

  return (
    <SettingSection
      id="sharing-settings"
      label="Sharing"
      hideLabel={hideLabel}
      description={`Control who can view and collaborate your map. Collaborators can add suggestions to any ${file.design.regionType} but they will not be added to the project until you approve them.`}
    >
      <AccessPolicyOption
        accessPolicy={MapmakerFileAccessPolicy.Private}
        label="Private"
        icon="lock"
        onSelect={selectAccessPolicy}
      >
        Only people you invite directly can view or collaborate on this project.
        <div className="visible-when-selected">
          <Button
            size="small"
            color="accent"
            className="invite-button"
            onClick={() => {
              onLeave && onLeave();
              gotoEditFile(file.id, "collaborate");
            }}
          >
            <Icon name="envelope" />
            Invite Someone
          </Button>
        </div>
      </AccessPolicyOption>
      <AccessPolicyOption
        accessPolicy={MapmakerFileAccessPolicy.Public}
        label="Public Viewing"
        icon="eye"
        onSelect={selectAccessPolicy}
        disabled={!isAuthenticated}
      >
        Anyone with the link can view your project.
        <div className="sharing-link authenticated-only visible-when-selected">
          <SharingLink />
        </div>
        <AuthenticateOnlyWarning />
      </AccessPolicyOption>
      <AccessPolicyOption
        accessPolicy={MapmakerFileAccessPolicy.PublicCollaborators}
        label="Public Collaboration"
        icon="users"
        onSelect={selectAccessPolicy}
        disabled={!isAuthenticated}
      >
        Anyone with the link can view and collaborate on your project.
        <div className="sharing-link authenticated-only visible-when-selected">
          <SharingLink />
        </div>
        <AuthenticateOnlyWarning />
      </AccessPolicyOption>
    </SettingSection>
  );
}

type AccessPolicyOptionProps = {
  accessPolicy: MapmakerFileAccessPolicy;
  label: string;
  icon: SemanticICONS;
  disabled?: boolean;
  onSelect(accessPolicy: MapmakerFileAccessPolicy): any;
};

function AccessPolicyOption({
  accessPolicy,
  label,
  icon,
  onSelect,
  disabled = false,
  children,
}: PropsWithChildren<AccessPolicyOptionProps>) {
  const { isAuthenticated } = useAuthContext();
  const { accessPolicy: currentAccessPolicy } = useProjectFile();
  const selected = currentAccessPolicy === accessPolicy;

  return (
    <SelectableSetting
      selected={selected}
      disabled={disabled}
      onSelect={() => onSelect(accessPolicy)}
      className={clsx("access-policy-option", {
        authenticated: isAuthenticated,
        unauthenticated: !isAuthenticated,
      })}
    >
      <Icon name={icon} size="big" />
      <div>
        <h4>{label}</h4>
        <div className="content">{children}</div>
      </div>
    </SelectableSetting>
  );
}
