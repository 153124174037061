import { ReactNode } from "react";
import { Icon } from "semantic-ui-react";
import { SerializerProps } from "./shared";
import SanityContent from "../SanityContent";
import "./AsideSerializer.css";

export type AsideReason = "tip";

type ReasonDetails = {
  icon: ReactNode;
  text: ReactNode;
};

const ReasonMap: { [key in AsideReason]: ReasonDetails } = {
  tip: {
    icon: <Icon name="pointing up" />,
    text: "Tip",
  },
};

export type AsideSerializerProps = SerializerProps<{
  reason: AsideReason;
  header: string;
  content: any;
}>;

export default function AsideSerializer({
  node: { reason, header, content },
}: AsideSerializerProps) {
  const { icon, text } = ReasonMap[reason];

  return (
    <div id="sanity-aside" className={reason}>
      <div className="heading">
        {icon}
        {header ?? text}
      </div>
      <SanityContent content={content} />
    </div>
  );
}
