import { useProjectFile } from "../../useProjectState";
import { MapmakerFileViewerPolicy } from "../../../../client/MapmakerApi";
import ReadOnlyOpeningView from "./ReadOnlyOpeningView";
import CollaboratorOpeningView from "./CollaboratorOpeningView";
import { Navigate, Route, Routes } from "react-router-dom";
import OpeningLiveEditorView from "./OpeningLiveEditorView";

type OpeningViewProps = {
  openingId: string;
};

export default function OpeningView({ openingId }: OpeningViewProps) {
  const { viewerPolicy } = useProjectFile();
  switch (viewerPolicy) {
    case MapmakerFileViewerPolicy.Owner:
      return (
        <Routes>
          <Route
            path=""
            element={<OpeningLiveEditorView openingId={openingId} />}
          />
          <Route path="*" element={<Navigate to="" />} />
        </Routes>
      );
    case MapmakerFileViewerPolicy.Collaborator:
      return (
        <Routes>
          <Route
            path="*"
            element={<CollaboratorOpeningView openingId={openingId} />}
          />
        </Routes>
      );

    case MapmakerFileViewerPolicy.Viewer:
      return <ReadOnlyOpeningView openingId={openingId} />;
    default:
      return null;
  }
}
