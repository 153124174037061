import { gql } from "@apollo/client";
import format from "date-fns/format";
import { useMapmakerAppConfig } from "../../client";
import { BlogPostCardFragment } from "../../client/MapmakerApi";
import { useSanityImageUrlBuilder } from "../help/sanity/serializers/utils";
import "./BlogPostCard.css";

gql`
  fragment BlogPostCard on Post {
    _id
    title
    slug {
      current
    }
    author {
      name
      slug {
        current
      }
    }
    mainImage {
      asset {
        _id
        url
        path
        assetId
        extension
      }
    }
    publishedAt
  }
`;

type BlogPostCardProps = {
  post: BlogPostCardFragment;
};

export default function BlogPostCard({ post }: BlogPostCardProps) {
  const builder = useSanityImageUrlBuilder();
  const { gotoBlogPost } = useMapmakerAppConfig();
  return (
    <div id="blog-post-card" onClick={() => gotoBlogPost(post.slug.current)}>
      <img
        src={builder
          .image(post.mainImage.asset)
          .crop("focalpoint")
          .width(512)
          .height(512)
          .fit("crop")
          .url()}
      />
      <div className="info">
        <h3>{post.title}</h3>
        <div className="date">
          {format(Date.parse(post.publishedAt), "PPP")}
        </div>
      </div>
    </div>
  );
}
