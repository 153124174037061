import { MapmakerAppConfig, MapmakerPrintServiceId } from "@mapmaker/frontend";
import { NavigateFunction } from "react-router-dom";
import { ProjectOutputType } from "./MapmakerApi";
import { MultiSizeGroupsMessage } from "./pages/mapmaker/MultiSizeGroupWarning";

function createProjectSharingLink(fileId: string): string {
  return `${window.location.origin}/mapmaker/${fileId}?s=1`;
}

let navigate: NavigateFunction;
export function setNavigate(n: NavigateFunction) {
  navigate = n;
}

function gotoSignUp(
  redirectTo: string = window.location.pathname + window.location.search
) {
  navigate(`/account/register?redirectTo=${encodeURIComponent(redirectTo)}`);
}

function gotoSignIn(
  redirectTo: string = window.location.pathname + window.location.search
) {
  navigate(`/account/login?redirectTo=${encodeURIComponent(redirectTo)}`);
}

function gotoFindMap() {
  navigate(`/mapmaker`);
}

const stripBusinessId = (designId: string) => designId.replace(/^tbl./, "");

function gotoNewFile(designId: string) {
  navigate(`/mapmaker/new/${stripBusinessId(designId)}`);
}

function gotoEditFile(fileId: string, ...path: string[]) {
  if (path.length === 0) {
    navigate(`/mapmaker/${fileId}`);
  } else {
    navigate(`/mapmaker/${fileId}/${path.filter((x) => !!x).join("/")}`);
  }
}

// Called after the first save of a new map when the file ID is available.
function onMapmakerFileCreated(fileId: string) {
  window.history.replaceState(null, "", `/mapmaker/${fileId}`);
}

function gotoSelectPrintService(fileId: string) {
  navigate(`/mapmaker/${fileId}/output`);
}

function gotoPrintSizingHelp() {
  navigate(`/help/printing-at-home`);
}

function gotoProduct(handle: string) {
  navigate(`/shop/product/${handle}`);
}

function gotoCart() {
  navigate(`/shop/cart`);
}

function gotoContact(category?: "bug") {
  switch (category) {
    case "bug":
      navigate(`/contact/mapmaker/bugs`);
      break;
    default:
      navigate(`/contact`);
      break;
  }
}

function gotoChangeEmail() {
  navigate(`/contact/mapmaker/change-email`);
}

function gotoStickerTokenRedeem() {
  navigate(`/redeem`);
}

function gotoStickerTokenHelp() {
  navigate(`/help/sticker-tokens`);
}

function gotoStickerTokenBalance() {
  navigate(`/account/sticker-tokens`);
}

function gotoCreatePrints(service: MapmakerPrintServiceId, fileId: string) {
  switch (service) {
    case MapmakerPrintServiceId.DOWNLOAD:
      navigate(`/mapmaker/${fileId}/output/download`);
      break;
    case MapmakerPrintServiceId.WALGREENS:
      navigate(`/mapmaker/${fileId}/output/walgreens`);
      break;
    case MapmakerPrintServiceId.STICKERS:
      navigate(`/mapmaker/${fileId}/output/stickers`);
      break;
  }
}

const TBLMapmakerAppConfig: MapmakerAppConfig = {
  businessId: "tbl",
  businessName: "Thunder Bunny Labs",
  pageTitleFormat: "{TITLE} | Thunder Bunny Labs",
  printServiceIds: [
    MapmakerPrintServiceId.STICKERS,
    MapmakerPrintServiceId.WALGREENS,
    MapmakerPrintServiceId.DOWNLOAD,
  ],
  primaryPrintServiceId: MapmakerPrintServiceId.STICKERS,
  stickersConfig: {
    shippingCost: "$1.75",
    freeShippingThreshold: 5,
  },
  downloadConfig: {
    bufferArea: true,
  },
  defaultProjectOutputType: ProjectOutputType.MatboardWallMap,
  sanity: {
    dataset: "tbl",
    projectId: "6bdy1j1i",
  },
  MultiSizeGroupWarningComponent: MultiSizeGroupsMessage,
  onMapmakerFileCreated,
  // URLs
  createProjectSharingLink,
  // Navigation
  gotoCreatePrints,
  gotoEditFile,
  gotoFindMap,
  gotoNewFile,
  gotoSelectPrintService,
  gotoSignIn,
  gotoSignUp,
  gotoContact,
  gotoChangeEmail,
  gotoCart,
  gotoProduct,
  gotoPrintSizingHelp,
  gotoStickerTokenHelp,
  gotoStickerTokenBalance,
  gotoStickerTokenRedeem,
};
export default TBLMapmakerAppConfig;
