import { ReactElement } from "react";
import ContextPanelCompact from "./ContextPanelCompact";
import ContextPanelSection from "./ContextPanelSection";
import { ContextPanelProvider } from "./useContextPanel";
import "./ContextPanelContent.css";
import ContextPanelFull from "./ContextPanelFull";

export interface IContextPanelContentProps {
  children:
    | ReactElement<typeof ContextPanelSection>
    | ReactElement<typeof ContextPanelSection>[];
}

export default function ContextPanelContent({
  children,
}: IContextPanelContentProps) {
  return (
    <ContextPanelProvider>
      <ContextPanelCompact>{children}</ContextPanelCompact>
      <ContextPanelFull>{children}</ContextPanelFull>
    </ContextPanelProvider>
  );
}
