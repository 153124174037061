import React from "react";
import { OutputTypeDemonstrationProps } from "@mapmaker/renderable";
import VinylWallMapDemonstration from "./VinylWallMapDemonstration";
import VehicleMapDemonstration from "./VehicleMapDemonstration";
import MatboardWallMapDemonstration from "./MatboardWallMapDemonstration";

/*
 * Renders an SVG showing useful information about a design.
 */
export default function OutputTypeDemonstration({
  outputType,
  ...props
}: OutputTypeDemonstrationProps<"resource">) {
  switch (outputType) {
    case "VINYL_WALL_MAP":
      return (
        <VinylWallMapDemonstration {...{ primaryColor: "#000000", ...props }} />
      );
    case "VEHICLE_MAP":
      return (
        <VehicleMapDemonstration {...{ primaryColor: "#000000", ...props }} />
      );
    case "MATBOARD_WALL_MAP":
    case "MATBOARD_WALL_MAP_SINGLE":
      return (
        <MatboardWallMapDemonstration
          {...{ primaryColor: "#000000", ...props }}
        />
      );
    default:
      return null;
  }
}
