import * as yup from "yup";
import { RenderableForm } from ".";

export const OutputTypeDemonstrationForm: RenderableForm = {
  initialValues: {
    width: 300,
    height: 200,
  },
  inputs: [
    {
      type: "design",
      id: "design",
      name: "Design",
      validation: yup.string().required(),
    },
    {
      type: "select",
      id: "outputType",
      name: "Output Type",
      validation: yup.string().required(),
      options: [
        {
          label: "Matboard Wall Map",
          value: "MATBOARD_WALL_MAP",
        },
        {
          label: "Matboard Wall Map - Single",
          value: "MATBOARD_WALL_MAP_SINGLE",
        },
        {
          label: "Vinyl Wall Map",
          value: "VINYL_WALL_MAP",
        },
        {
          label: "Vehicle Map",
          value: "VEHICLE_MAP",
        },
      ],
    },
    {
      type: "number",
      id: "width",
      name: "Width",
      validation: yup.number().required(),
    },
    {
      type: "number",
      id: "height",
      name: "Height",
      validation: yup.number().required(),
    },
  ],
};
