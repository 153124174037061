import { getOpeningInput, OpeningInput } from "@mapmaker/core";
import { trackGtmEvent } from "../../../../../lib/gtm";
import { saveFile, updateFeatureInput } from "../../../fileReducer";
import { useProjectDispatch } from "../../../projectStore";
import { useProjectFile } from "../../../useProjectState";
import useSuggestion from "./useSuggestion";
import { SuggestionAcceptanceStrategy } from "./useSuggestionAcceptance";

export type AcceptSuggestionResult = any;
type AcceptSuggestionMethod = (
  strategy: SuggestionAcceptanceStrategy
) => Promise<AcceptSuggestionResult>;

export default function useAcceptOpeningSuggestion(
  suggestionId: string
): AcceptSuggestionMethod {
  const suggestion = useSuggestion(suggestionId);
  const file = useProjectFile();
  const currentOpeningInput = getOpeningInput(file, suggestion.openingId);
  const projectDispatch = useProjectDispatch();

  const acceptSuggestion: AcceptSuggestionMethod = async (
    strategy: SuggestionAcceptanceStrategy
  ) => {
    function getNewOpeningInput(): OpeningInput {
      switch (strategy) {
        case "add":
        case "overwrite":
          return {
            type: "OPENING",
            ...currentOpeningInput,
            ...suggestion.input,
            modifiedAt: new Date().toISOString(),
          };
        case "merge":
          // We only merge in unique images.
          const newImagesFromSuggestion = suggestion.input.images.filter(
            suggestionImage =>
              currentOpeningInput.images.every(
                currentImage => suggestionImage.id !== currentImage.id
              )
          );
          return {
            type: "OPENING",
            ...currentOpeningInput,
            images: [...currentOpeningInput.images, ...newImagesFromSuggestion],
            modifiedAt: new Date().toISOString(),
          };
      }
    }

    projectDispatch(
      updateFeatureInput(suggestion.openingId, getNewOpeningInput())
    );
    projectDispatch(saveFile(true));

    trackGtmEvent({
      event: "mapmaker.accept-suggestion",
      openingId: file.designId,
      strategy,
    });
  };

  return acceptSuggestion;
}
