export function clamp(value: number, min: number, max: number): number {
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  } else {
    return value;
  }
}

export function roundTo(value: number, places: number): number {
  const multiplier = Math.pow(10, places);
  return Math.round(value * multiplier) / multiplier;
}
