import MapmakerConfig from "@mapmaker/config";
import { Button } from "@mapmaker/ui";
import { Label } from "semantic-ui-react";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { FaInfoCircle } from "@react-icons/all-files/fa/FaInfoCircle";
import { getPrintServicesForOutputType } from "./PrintServices";
import { useProjectFile } from "../project/useProjectState";
import { useMapmakerAppConfig } from "../../client";
import MapmakerLayout from "../layout/MapmakerLayout";
import MapmakerTopBar from "../layout/MapmakerTopBar";
import { usePreferredPrintingService } from "./shared/usePreferredPrintingService";
import { MapmakerPrintServiceId } from "./PrintServiceIds";
import ProjectToolbar from "../project/ProjectToolbar";
import { MapmakerFileViewerPolicy } from "../../client/MapmakerApi";
import "./MapmakerPrintServiceSelector.css";

export default function MapmakerPrintServiceSelector() {
  const { gotoCreatePrints } = useMapmakerAppConfig();
  const file = useProjectFile();
  const [, setPreferredPrintingService] = usePreferredPrintingService(
    file.outputType
  );

  function selectService(id: MapmakerPrintServiceId) {
    setPreferredPrintingService(id);
    gotoCreatePrints(id, file.id);
  }

  const availablePrintServices = getPrintServicesForOutputType(file.outputType);

  return (
    <MapmakerLayout>
      <MapmakerLayout.TopBar>
        <MapmakerTopBar
          breadcrumbs={[
            {
              label: "Print Photos",
            },
          ]}
        />
      </MapmakerLayout.TopBar>
      <MapmakerLayout.Main nav={<ProjectToolbar selected="output" />}>
        <div className="mapmaker-print-service-selector">
          {file.viewerPolicy !== MapmakerFileViewerPolicy.Owner && (
            <div className="message">
              <FaInfoCircle fontSize={60} />
              <div>
                <h3>Heads Up!</h3>
                <p>
                  You are printing photos for someone else's project. You can do
                  this since you have access to the project but generally the
                  project owner prints their own photos.
                </p>
              </div>
            </div>
          )}
          <div className="header">
            <h1>Choose a Printing Method</h1>
            <p>
              Feel free to try them all. You can always change your selection
              later.
            </p>
          </div>
          <div className="cards">
            {availablePrintServices.map(service => {
              return (
                <div
                  className="card"
                  key={service.id}
                  onClick={() => selectService(service.id)}
                >
                  {service.bannerText && (
                    <Label id="ribbon" color="blue" ribbon as="span">
                      {service.bannerText}
                    </Label>
                  )}
                  <img
                    className="image"
                    src={`${MapmakerConfig.staticUrl}/images/printing-services/${service.id}-illustration.png`}
                    alt={service.directive}
                  />
                  <div className="content">
                    <h3>{service.directive}</h3>
                    <div
                      className="description"
                      style={{ textAlign: "left", margin: "1em 0" }}
                    >
                      {service.detailedDescription}
                    </div>
                  </div>
                  <div className="extra">
                    <Button onClick={() => selectService(service.id)}>
                      Choose
                      <FaArrowRight />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}
