import { SVGNode } from "@mapmaker/core";
import "./EditableOpeningOverlay.css";

export interface IEditableOpeningOverlayProps {
  layer: SVGNode;
  layer2: SVGNode;
  fill?: string;
  stroke?: string;
}

export default function EditableOpeningOverlay({
  layer,
  layer2,
  fill = "RGBA(255, 255, 255, 0.85)",
  stroke = "#000000",
}: IEditableOpeningOverlayProps) {
  if (!layer || !layer2) {
    return null;
  }
  function getCompoundPathIncludingChildren(nodeData: SVGNode) {
    var compoundPath = "";
    if (nodeData.d) {
      compoundPath += nodeData.d;
    }
    if (nodeData.children?.length) {
      compoundPath += nodeData.children.reduce(
        (childrenPath, child) =>
          childrenPath + getCompoundPathIncludingChildren(child),
        compoundPath
      );
    }
    return compoundPath;
  }

  function getPath(layer: SVGNode) {
    const excess = 2000;
    const bbox = layer.bbox;
    const width = bbox.width;
    const height = bbox.height;
    const left = bbox.x - excess;
    const right = left + width + excess * 2;
    const top = bbox.y - excess;
    const bottom = top + height + excess * 2;
    return (
      getCompoundPathIncludingChildren(layer) +
      `M ${left} ${top} L ${right} ${top} L ${right} ${bottom} L ${left} ${bottom} L ${left} ${top} z`
    );
  }

  return (
    <g id="editable-opening-overlay">
      {/* First layer */}
      <path className="first" d={getPath(layer)} />
      <path
        className="second"
        style={{
          fill: fill,
          stroke: stroke,
        }}
        d={getPath(layer2)}
      />
    </g>
  );
}
