import { gql } from "@apollo/client";
import { useAuthContext } from "@tbl/aws-auth";
import { useCallback, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  PresignedPostFragment,
  useCreatePresignedPostMutation,
} from "../../client/MapmakerApi";

gql`
  fragment PresignedPost on PresignedPost {
    url
    fields
    expires
  }

  mutation createPresignedPost($folder: String) {
    createPresignedPost(folder: $folder) {
      ...PresignedPost
    }
  }
`;

// A slight buffer so that the presigned URL doesn't expire between when we check it and when we start the upload.
const EXPIRATION_BUFFER = 60 * 1000;
let cachedPresignedPost: PresignedPostFragment | null = null;

function setPresignedPost(presignedPost: PresignedPostFragment) {
  cachedPresignedPost = presignedPost;
}

async function getPresignedPost(
  getNewPresignedPost: () => Promise<PresignedPostFragment>
) {
  if (
    !cachedPresignedPost ||
    new Date(cachedPresignedPost.expires).getTime() - EXPIRATION_BUFFER <
      new Date().getTime()
  ) {
    const result = await getNewPresignedPost();
    setPresignedPost(result);
  }
  return cachedPresignedPost;
}

function useGetPresignedPost(folder: string) {
  const [getPresignedPost] = useCreatePresignedPostMutation({
    variables: {
      folder,
    },
  });
  return async function(): Promise<PresignedPostFragment> {
    const result = await getPresignedPost();
    return result.data?.createPresignedPost as PresignedPostFragment;
  };
}

type FileUploadState = "uploading" | "complete" | "error";

export type FileUpload = {
  key: string;
  filename: string;
  status: FileUploadState;
  result?: string;
  error?: String;
};

export function useUploadFiles(folder: string) {
  const getNewPresignedPost = useGetPresignedPost(folder);
  const uploadsRef = useRef<FileUpload[]>([]);
  const { identityId } = useAuthContext();
  const [uploads, setUploads] = useState<FileUpload[]>(uploadsRef.current);

  const results = useMemo(() => {
    return uploads
      .filter(upload => upload.status === "complete")
      .map(upload => upload.result);
  }, [uploads]);

  const uploadInProgress = useMemo(
    () => uploads.some(upload => upload.status === "uploading"),
    [uploads]
  );

  const addUpload = useCallback(
    (newUpload: FileUpload) => {
      uploadsRef.current = [...uploadsRef.current, newUpload];
      setUploads(uploadsRef.current);
    },
    [setUploads]
  );

  const updateUpload = useCallback(
    (updatedUpload: FileUpload) => {
      uploadsRef.current = uploadsRef.current.map(upload => {
        if (upload.key === updatedUpload.key) {
          return updatedUpload;
        } else {
          return upload;
        }
      });
      setUploads(uploadsRef.current);
    },
    [setUploads]
  );

  function removeUpload(key: string) {
    uploadsRef.current = uploadsRef.current.filter(
      upload => upload.key !== key
    );
    setUploads(uploadsRef.current);
  }

  const uploadFiles = useCallback(
    async (files: File[]) => {
      const uploadPromises = files.map(async file => {
        const rawExtension = (file.name.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
        const extensionToAdd = rawExtension ? `.${rawExtension}` : "";
        const key = `${identityId}/uploads/${folder}/${uuidv4()}${extensionToAdd}`;
        try {
          addUpload({
            key,
            filename: file.name,
            status: "uploading",
          });
          const presignedPost = await getPresignedPost(getNewPresignedPost);
          if (!presignedPost) {
            throw new Error("Error uploading.");
          }
          const doUpload = new Promise((resolve, reject) => {
            const formData = new FormData();
            Object.keys(presignedPost.fields).forEach(fieldKey => {
              formData.append(fieldKey, presignedPost.fields[fieldKey]);
            });
            formData.append("Key", key);
            formData.append("Content-Type", file.type);
            formData.append("acl", "public-read");

            // Actual file has to be appended last.
            formData.append("file", file);

            const xhr = new XMLHttpRequest();
            xhr.open("POST", presignedPost.url, true);
            xhr.send(formData);
            xhr.onload = function() {
              this.status === 204
                ? resolve(this.responseText)
                : reject(this.responseText);
            };
          });

          await doUpload;
          updateUpload({
            key,
            filename: file.name,
            status: "complete",
            result: `${presignedPost.url}/${key}`,
          });
        } catch (e) {
          updateUpload({
            key,
            filename: file.name,
            status: "error",
            error: "There was an issue uploading your file.",
          });
        }
      });
      await Promise.allSettled(uploadPromises);
    },
    [folder, getNewPresignedPost, addUpload, updateUpload]
  );

  return {
    uploadFiles,
    removeUpload,
    uploads,
    uploadInProgress,
    results,
  };
}
