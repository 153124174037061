import { useState } from "react";
import clsx from "clsx";
import gql from "graphql-tag";
import { Icon } from "semantic-ui-react";
import { NavigationLinkFragment } from "../../../client/MapmakerApi";
import { useSanityContext } from "./SanityContext";
import { getNavigationPath } from "./navigationUtils";
import MaybeLink from "../../../components/shared/widgets/MaybeLink";
import "./SanityNavigation.css";

gql`
  fragment Navigation on Navigation {
    _id
    title
    children {
      ...NavigationLink
    }
  }
`;

export default function SanityNavigation() {
  const {
    urlCreator,
    showHidden,
    navigation,
    selectedTargetSlug,
  } = useSanityContext();
  const [open, setOpen] = useState(false);

  const selectionPath = getNavigationPath(navigation, selectedTargetSlug);
  const section = selectionPath
    ? selectionPath[selectionPath.length - 1]
    : null;
  const sectionChildren = (section?.children || []).filter(
    child => !child?.hidden || showHidden
  );

  return (
    <>
      {sectionChildren.length > 0 && (
        <div id="sanity-navigation-lite">
          <div className="opener" onClick={() => setOpen(!open)}>
            <span className="icon">{open ? "-" : "+"}</span>
            In This Section
          </div>
          {open && (
            <ul>
              {sectionChildren.map((link, i) => (
                <li key={link?.target?.slug?.current ?? i}>
                  <MaybeLink to={urlCreator(link?.target?.slug?.current)}>
                    {link?.title ?? link?.target?.title}
                  </MaybeLink>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      <ul id="sanity-navigation">
        {(navigation.children ?? [])
          .filter(child => !child?.hidden || showHidden)
          .map(
            child =>
              child && (
                <NavigationLink
                  key={child.title ?? child.target?.title}
                  link={child}
                  selectionPath={selectionPath}
                />
              )
          )}
      </ul>
    </>
  );
}

gql`
  fragment NavigationLinkTarget on Page {
    _id
    title
    slug {
      current
    }
  }

  fragment NavigationLink on NavigationLink {
    _key
    title
    hidden
    target {
      ...NavigationLinkTarget
    }
    children {
      title
      hidden
      target {
        ...NavigationLinkTarget
      }
      children {
        title
        hidden
        target {
          ...NavigationLinkTarget
        }
      }
    }
  }
`;

type NavigationLinkProps = {
  link: NavigationLinkFragment;
  forceOpen?: boolean;
  selectionPath: NavigationLinkFragment[];
};

export function NavigationLink({
  link,
  forceOpen = false,
  selectionPath,
}: NavigationLinkProps) {
  const { urlCreator } = useSanityContext();
  const children = (link.children ?? []).filter(link => !link?.hidden);
  const hasChildren = children.length > 0;
  const slug = link.target?.slug?.current ?? "___NOTHING___";
  const selected =
    selectionPath.length === 1 &&
    selectionPath[0].target?.slug?.current === slug;
  const ancestorSelected = !!selectionPath.find(
    link => link.target?.slug?.current === slug
  );
  const [expanded, setExpanded] = useState(false);
  const open = forceOpen || expanded || selected || ancestorSelected;
  const allowToggle = !(forceOpen || selected || ancestorSelected);

  const toggleExpanded = (e: MouseEvent) => {
    setExpanded(!expanded);
  };

  return (
    <>
      <li
        className={clsx(
          "link",
          selected && "selected",
          hasChildren && "hasChildren",
          allowToggle && "allowToggle"
        )}
      >
        <Icon
          className="icon"
          name={open ? "chevron down" : "chevron right"}
          size="small"
          onClick={toggleExpanded}
        />
        <div className="label">
          <MaybeLink to={urlCreator(link.target?.slug?.current)}>
            {link.title ?? link.target?.title}
          </MaybeLink>
        </div>
      </li>
      {hasChildren && (
        <ul className={clsx({ open })}>
          {children?.map(
            child =>
              child && (
                <NavigationLink
                  link={child}
                  forceOpen={selected}
                  selectionPath={[...selectionPath].splice(1)}
                />
              )
          )}
        </ul>
      )}
    </>
  );
}
