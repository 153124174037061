import { useMemo } from "react";
import { IGroupHeaderProps } from "../shared/RectangularPrintList";
import RectangularPrintListGroupHeader from "../shared/RectangularPrintListGroupHeader";
import { WalgreensProduct } from "../../../client/MapmakerApi";
import useWalgreensCheckout from "./useWalgreensCheckout";

type IWalgreensPrintListGroupHeaderProps = IGroupHeaderProps & {
  products: WalgreensProduct[];
};

export default function WalgreensPrintListGroupHeader({
  paperSize,
  printLayouts,
}: IWalgreensPrintListGroupHeaderProps) {
  const { products } = useWalgreensCheckout();

  const product = useMemo(() => {
    return products.find(product => {
      const [productWidth, productHeight] = product.productSize
        .split("x")
        .map(x => parseInt(x));
      return (
        (paperSize.width / 72 === productWidth &&
          paperSize.height / 72 === productHeight) ||
        (paperSize.width / 72 === productHeight &&
          paperSize.height / 72 === productWidth)
      );
    });
  }, [products, paperSize]);

  const printCountText =
    printLayouts.length == 1 ? "1 print" : `${printLayouts.length} prints`;
  const printPrice = product.productPrice;

  return (
    <RectangularPrintListGroupHeader
      paperSize={paperSize}
      printLayouts={printLayouts}
      printPrice={printPrice}
    />
  );
}
