import { Icon } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import WalgreensCheckoutStepContainer from "./WalgreensCheckoutStepContainer";
import WalgreensOrderInformation from "./WalgreensOrderInformation";
import useWalgreensCheckout from "./useWalgreensCheckout";
import {
  clearSubmittedOrder,
  clearPrintBatch,
} from "./walgreensCheckoutReducer";
import "./WalgreensOrderSubmitted.css";
import { Button } from "@mapmaker/ui";

export default function WalgreensOrderSubmitted() {
  const dispatch = useDispatch();
  const { checkout } = useWalgreensCheckout();

  function onDone() {
    dispatch(clearPrintBatch());
    dispatch(clearSubmittedOrder());
  }

  return (
    <WalgreensCheckoutStepContainer>
      <div id="walgreens-order-submitted">
        <div className="success">
          <Icon size="huge" name="check circle" />
          <h2>
            Order <strong>#{checkout.submittedOrder.walgreensOrderId}</strong>{" "}
            has been received.
          </h2>
          <p>
            A confirmation email will be sent to{" "}
            <strong>{checkout.customerInformation.email}</strong>.
          </p>
        </div>
        <WalgreensOrderInformation readOnly />
        <div className="done">
          <Button onClick={onDone}>Done</Button>
        </div>
      </div>
    </WalgreensCheckoutStepContainer>
  );
}
