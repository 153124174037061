import Suspender from "react-suspender";
import RectangularPrintMainPanel from "../shared/RectangularPrintMainPanel";
import RectangularPrintCard from "../shared/RectangularPrintCard";
import WalgreensPrintListGroupHeader from "./WalgreensPrintListGroupHeader";
import WalgreensPrintListHeader from "./WalgreensPrintListHeader";
import WalgreensPrintListFooter from "./WalgreensPrintListFooter";
import { useWalgreensProductsQuery } from "../../../client/MapmakerApi";

export default function WalgreensPrintMainPanel() {
  // This is pretty bad, but we load the products here so that children can use the cached version
  // with the same hook.
  const { loading } = useWalgreensProductsQuery();

  if (loading) {
    return <Suspender />;
  }

  return (
    <RectangularPrintMainPanel
      slots={{
        header: <WalgreensPrintListHeader />,
        footer: <WalgreensPrintListFooter />,
        card: RectangularPrintCard,
        "group-header": WalgreensPrintListGroupHeader,
      }}
    ></RectangularPrintMainPanel>
  );
}
