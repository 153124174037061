import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import useLatest from "./useLatest";

type KeypressHandler = (e: KeyboardEvent) => any;

export default function useKeyPress(
  targetKey,
  handler?: KeypressHandler,
  enabled = true
) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  const handlerRef = useLatest<KeypressHandler>(handler);

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === targetKey) {
        setKeyPressed(true);
        handlerRef.current && handlerRef.current(event);
      }
    },
    [targetKey, setKeyPressed]
  );

  // If released key is our target key then set to false
  const upHandler = useCallback(() => {
    setKeyPressed(false);
  }, [setKeyPressed]);

  // Add event listeners
  useEffect(() => {
    if (enabled) {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
    } else {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    }
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [enabled]);

  return keyPressed;
}
