import clsx from "clsx";
import { PropsWithChildren } from "react";
import "./SelectableSetting.css";

type SelectableSettingProps = PropsWithChildren<{
  selected: boolean;
  disabled?: boolean;
  className?: string;
  onToggle?(selected: boolean): any;
  onSelect?(): any;
  onDeselect?(): any;
}>;

export default function SelectableSetting({
  selected,
  disabled,
  className,
  onToggle,
  onDeselect,
  onSelect,
  children,
}: SelectableSettingProps) {
  function onClick() {
    if (!disabled) {
      onToggle && onToggle(!selected);
      selected && onDeselect && onDeselect();
      !selected && onSelect && onSelect();
    }
  }

  return (
    <div
      id="selectable-setting"
      className={clsx(className, {
        selected,
        disabled,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
