type Matboard = {
  id: string;
  name: string;
  hex?: string;
  active: boolean;
};

export function getMatboard(id: string): Matboard {
  return Matboards.find((matboard) => matboard.id === id);
}

export const Matboards = [
  {
    id: "beige",
    name: "Beige",
    hex: "#D3BE99",
    active: false,
  },
  {
    id: "black",
    name: "Black",
    hex: "#222222",
    active: true,
  },
  {
    id: "cactus",
    name: "Cactus",
    hex: "#A6E6C6",
    active: false,
  },
  {
    id: "caribbean",
    name: "Caribbean",
    hex: "#C2D2D2",
    active: true,
  },
  {
    id: "lilac",
    name: "Lilac",
    hex: "#D8A0EA",
    active: false,
  },
  {
    id: "driftwood",
    name: "Driftwood",
    hex: "#ACAA9E",
    active: true,
  },
  {
    id: "printed",
    name: "Printed Map",
    active: true,
  },
  {
    id: "sienna",
    name: "Sienna",
    hex: "#734649",
    active: true,
  },
  {
    id: "white",
    name: "White",
    hex: "#FFFFFF",
    active: true,
  },
  {
    id: "zucchini",
    name: "Zucchini",
    hex: "#545F51",
    active: true,
  },
];

export const MatboardPackingSlipColorOverrides = {
  zucchini: "#4C7040",
};
