import { useDispatch, useSelector } from "react-redux";
import { WalgreensPrintsStore } from "./walgreensPrintsStore";
import useRectangularPrintsSelector from "../shared/useRectangularPrintsSelector";
import {
  ProductDetailsInput,
  OrderProductDetailsInput,
  ImageDetails,
} from "../../../client/MapmakerApi";
import { useCallback, useMemo } from "react";
import { getWalgreensProduct } from "./walgreensLib";
import { getPaperSize } from "../../../lib/prints/printSizes";
import { WalgreensCheckoutStep } from "./WalgreensTypes";
import { useApolloClient } from "@apollo/client";
import { createPrintBatch } from "./walgreensCheckoutReducer";

export type WalgreensCheckoutChecklist = {
  [key in WalgreensCheckoutStep]: boolean;
};

export default function useWalgreensCheckout() {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { fileId, printOptions } = useSelector(
    (state: WalgreensPrintsStore) => ({
      fileId: state.file.id,
      printOptions: state.printOptions,
    })
  );
  const { printSizeGroups, printLayouts } = useRectangularPrintsSelector();
  const { products, checkout } = useSelector((state: WalgreensPrintsStore) => ({
    products: state.products,
    checkout: state.checkout,
  }));

  const productDetailsInput: ProductDetailsInput[] = useMemo(() => {
    return Object.entries(printSizeGroups).map(([, printSizeGroup]) => {
      const productId = getWalgreensProduct(products, printSizeGroup.paperSize)
        .productId;
      const qty = printSizeGroup.printLayouts.length;
      return {
        productId,
        qty,
      };
    }, {});
  }, [products, printSizeGroups]);

  const subtotal: number = useMemo(() => {
    if (!productDetailsInput) {
      return null;
    }
    return productDetailsInput.reduce((subtotal, productDetails) => {
      const product = products.find(
        product => product.productId === productDetails.productId
      );
      return subtotal + product.productPrice * productDetails.qty;
    }, 0);
  }, [productDetailsInput]);

  const orderProductDetailsInput: OrderProductDetailsInput[] = useMemo(() => {
    const productMap: { [key: string]: ImageDetails[] } = {};
    const files = checkout.printBatch?.printFiles;
    const layouts = checkout.printBatch?.printLayouts;
    if (!files || !layouts) {
      return null;
    }
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      const layout = layouts[i];
      const paperSize = getPaperSize(layout.width, layout.height);
      const productId = getWalgreensProduct(products, paperSize).productId;
      if (!productMap[productId]) {
        productMap[productId] = [];
      }
      productMap[productId].push({
        url: file.url,
        // Quantity is always 1 because we print each individual file regardless of the quantity of
        // each opening that the user wants printed.
        qty: 1,
      });
    }
    return Object.entries(productMap).map(([productId, imageDetails]) => ({
      productId,
      imageDetails,
    }));
  }, [products, checkout.printBatch]);

  const checklist: WalgreensCheckoutChecklist = useMemo(() => {
    return {
      "create-prints":
        checkout.printBatch.loading ||
        !!checkout.printBatch.printFiles ||
        !!checkout.printBatch.error,
      "select-store": !!checkout.selectedStore,
      "get-customer-info": !!checkout.customerInformation,
      "review-order": !!checkout.submittedOrder,
      complete: !!checkout.submittedOrder,
    };
  }, [checkout]);

  const currentStep: WalgreensCheckoutStep = useMemo(() => {
    if (!checklist["create-prints"]) {
      return "create-prints";
    } else if (!checklist["select-store"]) {
      return "select-store";
    } else if (!checklist["get-customer-info"]) {
      return "get-customer-info";
    } else if (!checklist["review-order"]) {
      return "review-order";
    } else {
      return "complete";
    }
  }, [checkout]);

  const beginCheckout = useCallback(() => {
    // @ts-ignore Typescript doesn't like our promise instead of an action.
    dispatch(createPrintBatch(client, fileId, printOptions, printLayouts));
  }, [dispatch, createPrintBatch, client, fileId, printOptions, printLayouts]);

  return {
    currentStep,
    checklist,
    products,
    productDetailsInput,
    orderProductDetailsInput,
    subtotal,
    formattedSubtotal: subtotal ? `$${subtotal.toFixed(2)}` : "",
    canCheckout: printLayouts.length > 0,
    checkout,
    beginCheckout,
  };
}
