import * as Sentry from "@sentry/react";
import { useMemo } from "react";
import {
  ApolloClient,
  ApolloCache,
  InMemoryCache,
  HttpLink,
  from,
  NormalizedCacheObject,
} from "@apollo/client";
import MapmakerConfig from "@mapmaker/config";
import { onError } from "@apollo/client/link/error";
import { Credentials } from "@tbl/aws-auth";
import createSigV4Fetch from "./sigv4Fetch";
import { clearSystemTimeOffset } from "./systemTime";
import MapmakerApi, { MapmakerFile } from "./MapmakerApi";

export type MapmakerClient = ApolloClient<NormalizedCacheObject>;

type UseMapmakerApolloClientOptions = {
  businessId?: "pm" | "tbl";
  uri?: string;
  isLocal?: boolean;
  cache?: ApolloCache<NormalizedCacheObject>;
  currentCredentials: () => Promise<Credentials>;
};

let HAS_LOGGED_SERVER_ERROR = false;

export default function useMapmakerApolloClient({
  businessId,
  currentCredentials,
  uri = `${MapmakerConfig.apiGateway.url}/graphql`,
  isLocal = MapmakerConfig.isLocal,
  cache = new InMemoryCache({
    possibleTypes: MapmakerApi.possibleTypes,
    typePolicies: {
      // File backups have the same file ID (but different backupVersion) so we the cache key to
      // account for that.
      MapmakerFile: {
        keyFields: (file: MapmakerFile) => {
          if (file.backupVersion && file.backupVersion !== "CURRENT") {
            return `${file.id}/${file.backupVersion}`;
          } else {
            return file.id;
          }
        },
      },
    },
  }),
}: UseMapmakerApolloClientOptions): MapmakerClient {
  return useMemo(() => {
    const httpLink = new HttpLink({
      uri,
      headers: businessId
        ? {
            "mm-business-id": businessId,
          }
        : undefined,
      fetch: createSigV4Fetch(
        currentCredentials,
        isLocal ? "cognito-identity-id" : null
      ),
    });

    const errorLink = onError(({ networkError, ...rest }) => {
      // Would be great to have better fidelity here, but for now this is prettier than the alternatives.
      if (networkError && !HAS_LOGGED_SERVER_ERROR) {
        // Clearing once should be sufficient.
        clearSystemTimeOffset();
        function safeLog(data) {
          try {
            console.log(JSON.stringify(data));
          } catch (e) {}
        }
        safeLog(networkError?.message);
        safeLog(networkError?.name);
        safeLog(networkError?.stack);
        safeLog(rest);
        Sentry.captureMessage("Suppressed network error.");
        networkError.message = "Network error.";
        // This flag limits the extreme amount of these errors that we've been seeing.
        HAS_LOGGED_SERVER_ERROR = true;
      }
    });

    return new ApolloClient({
      link: from([errorLink, httpLink]),
      cache,
      connectToDevTools: true,
    });
  }, [businessId, currentCredentials]);
}
