import {
  createStore,
  combineReducers,
  applyMiddleware,
  Store,
  AnyAction,
} from "redux";
import {
  createDispatchHook,
  createSelectorHook,
  ReactReduxContextValue,
} from "react-redux";
import thunk from "redux-thunk";
import fileReducer, { MapmakerFileState } from "./fileReducer";
import messageReducer, { MessagesState } from "../shared/messageReducer";
import { MapmakerFileHeavyFragment } from "../../client/MapmakerApi";
import { createContext } from "react";
import uploadReducer, { UploadsState } from "./uploadsReducer";

// TODO - This should be updatable when we reload from the server.
function serverFileReducer<T>(initialState: T): (arg1: T) => T {
  return (state) => state || initialState;
}

// Types
export type MapmakerProjectStore = {
  serverFile: MapmakerFileHeavyFragment;
  file: MapmakerFileState;
  messages: MessagesState;
  uploads: UploadsState;
};

export function createProjectStore(
  file: MapmakerFileHeavyFragment
): Store<MapmakerProjectStore> {
  return createStore(
    combineReducers<MapmakerProjectStore>({
      serverFile: serverFileReducer(file),
      file: fileReducer(file),
      messages: messageReducer,
      uploads: uploadReducer,
    }),
    applyMiddleware(thunk)
  );
}

export const MapmakerProjectContext = createContext<
  ReactReduxContextValue<any, any>
>({ store: null, subscription: null } as any as ReactReduxContextValue<
  any,
  any
>);

export const useProjectDispatch = createDispatchHook(MapmakerProjectContext);
export const useProjectSelector = createSelectorHook(MapmakerProjectContext);
