import { BoundingBox, Viewbox } from "@mapmaker/core";

export function viewBoxForBBox(
  bbox: BoundingBox,
  padding: number | string = 0
): Viewbox {
  return viewBoxFor(bbox.x, bbox.y, bbox.width, bbox.height, padding);
}

export function viewBoxFor(
  x: number,
  y: number,
  width: number,
  height: number,
  padding: number | string = 0
): Viewbox {
  if (typeof padding === "string") {
    const largestSide = Math.max(width, height);
    const percentage = parseInt(padding.replace("%", "")) / 100;
    padding = largestSide * percentage;
  }
  return [x - padding, y - padding, width + padding * 2, height + padding * 2];
}
