import { PropsWithChildren, useState } from "react";
import clsx from "clsx";
import { Header, Icon } from "semantic-ui-react";
import { Button } from "@mapmaker/ui";
import { useDispatch } from "react-redux";
import Money from "../../shared/Money";
import { useOrderStickersStore } from "./useOrderStickersStore";
import { useMapmakerAppConfig } from "../../../client/MapmakerAppConfig";
import { useMapmakerContext } from "../../../client";
import { setUseTokens } from "./stickerOrderSettingsReducer";
import { StickerTokenIcon } from "../../shared/icons/StickerTokenIcon";
import { useAddStickerOrderToCart } from "../../../client/storefront/storefrontStickerHooks";
import "./OrderStickersContextPanel.css";

export default function OrderStickersContextPanel() {
  const { isMobile } = useMapmakerContext();
  return isMobile ? <MobilePanel /> : <DesktopPanel />;
}

function MobilePanel() {
  const [open, setOpen] = useState(false);

  return (
    <div id="order-stickers-context-panel-mobile" className={clsx({ open })}>
      <div className="details-toggle" onClick={() => setOpen(!open)}>
        <Icon name={open ? "chevron down" : "chevron up"} /> details
      </div>
      <div className="details">
        <Header as="h4">Order Details</Header>
        <OrderBreakdown />
      </div>
      <div className="bar">
        <OrderOverview />
      </div>
    </div>
  );
}

function DesktopPanel() {
  return (
    <div id="order-stickers-context-panel-desktop">
      <div className="totals">
        <OrderOverview />
      </div>
      <div className="breakdown-section">
        <h3>Price Breakdown</h3>
        <OrderBreakdown />
      </div>
    </div>
  );
}

function OrderOverview() {
  const { gotoCart } = useMapmakerAppConfig();
  const [addStickerOrderToCart] = useAddStickerOrderToCart();
  const [loading, setLoading] = useState(false);
  const {
    totalQuantity,
    totalQuantityFormatted,
    totalCostFormatted,
    createStickerOrder,
  } = useOrderStickersStore();

  async function addToCart() {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const result = await createStickerOrder();
      await addStickerOrderToCart(result.data.createStickerOrder);
      gotoCart();
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }

  return (
    <div id="sticker-order-overview">
      <Header as="h1">{totalCostFormatted ? totalCostFormatted : "-"}</Header>
      <Button
        /* This ID is used for GTM tracking */
        id="add-to-cart-button"
        onClick={addToCart}
        color="accent"
        fluid
        loading={loading}
        disabled={totalQuantity === 0}
      >
        <Icon name="cart plus" /> Add {totalQuantityFormatted} to cart
      </Button>
      <UseStickerTokensToggle />
    </div>
  );
}

function UseStickerTokensToggle() {
  const dispatch = useDispatch();
  const {
    settings: { useTokens },
    tokensAvailableForUse,
    tokensToBeSpentIfUsed,
  } = useOrderStickersStore();

  function toggle() {
    dispatch(setUseTokens(!useTokens));
  }

  if (tokensAvailableForUse === 0) {
    return null;
  }

  return (
    <div
      id="use-sticker-tokens-toggle"
      className={clsx({
        selected: useTokens,
      })}
      onClick={toggle}
    >
      <Icon
        className="checkbox"
        size="large"
        name={useTokens ? "check square outline" : "square outline"}
      />
      Use <strong>{tokensToBeSpentIfUsed}</strong> sticker{" "}
      {tokensToBeSpentIfUsed === 1 ? "token" : "tokens"}
      <StickerTokenIcon className="sticker-token-icon" size="30px" />
    </div>
  );
}

function OrderBreakdown() {
  const {
    currentShopifyStickerSizeSet,
    stickerOrderItems,
    totalQuantity,
    totalTokens,
    totalMoney,
    totalMoneyFormatted,
    totalQuantityIncludingCart,
    shippingCost,
    freeShippingThreshold,
  } = useOrderStickersStore();

  const TokenColumn = ({ children }: PropsWithChildren<{}>) =>
    totalTokens === 0 ? null : <>{children}</>;
  const MoneyColumn = ({ children }: PropsWithChildren<{}>) =>
    totalMoney === 0 ? null : <>{children}</>;

  return (
    <div id="sticker-order-breakdown">
      <table>
        <thead>
          <tr>
            <th>Size</th>
            <th>Stickers</th>
            <TokenColumn>
              <th>Tokens</th>
            </TokenColumn>
            <MoneyColumn>
              <th>Cost</th>
            </MoneyColumn>
          </tr>
        </thead>
        <tbody>
          {currentShopifyStickerSizeSet.variants.map(variant => {
            const {
              sizeGroupQuantityWithTokens,
              sizeGroupQuantityWithMoney,
            } = stickerOrderItems.reduce(
              (sum, stickerOrderItem) => {
                if (
                  stickerOrderItem.shopifyStickerVariant.sizeClass ===
                  variant.sizeClass
                ) {
                  return {
                    sizeGroupQuantityWithTokens:
                      sum.sizeGroupQuantityWithTokens +
                      stickerOrderItem.quantityWithTokens,
                    sizeGroupQuantityWithMoney:
                      sum.sizeGroupQuantityWithMoney +
                      stickerOrderItem.quantityWithMoney,
                  };
                } else {
                  return sum;
                }
              },
              { sizeGroupQuantityWithTokens: 0, sizeGroupQuantityWithMoney: 0 }
            );
            const sizeGroupQuantity =
              sizeGroupQuantityWithTokens + sizeGroupQuantityWithMoney;
            return (
              <tr key={variant.sizeClass}>
                <td>{variant.name}</td>
                <td>{sizeGroupQuantity}</td>
                <TokenColumn>
                  <td>{sizeGroupQuantityWithTokens * variant.tokens}</td>
                </TokenColumn>
                <MoneyColumn>
                  <td>
                    <Money
                      amount={sizeGroupQuantityWithMoney * variant.price}
                      valueIfZero="-"
                    />
                  </td>
                </MoneyColumn>
              </tr>
            );
          })}
          <tr className="spacer">
            <td colSpan={3}></td>
          </tr>
          <tr className="total-row">
            <td>Total</td>
            <td>{totalQuantity}</td>
            <TokenColumn>
              <td>{totalTokens}</td>
            </TokenColumn>
            <MoneyColumn>
              <td>{totalMoneyFormatted}</td>
            </MoneyColumn>
          </tr>
        </tbody>
      </table>
      {totalQuantityIncludingCart < freeShippingThreshold ? (
        <div className="shipping-note">
          <Icon name="shipping" />
          Shipping & Handling {shippingCost}
          <p className="note">
            Order {freeShippingThreshold} or more stickers to get free shipping!
          </p>
        </div>
      ) : (
        <div className="shipping-note free">
          <Icon name="shipping" />
          FREE Shipping!
        </div>
      )}
    </div>
  );
}
