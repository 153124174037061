import { ModalContent, ModalActions, Icon } from "semantic-ui-react";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { MapmakerDesign } from "../../client/MapmakerApi";
import { FinderDesignGroup } from "./MapmakerFinderModal";
import { useMapmakerAppConfig } from "../..";
import "./MapmakerFinderGroupSelection.css";
import { getDesignUrl } from "@mapmaker/config";

type MapmakerFinderGroupSelectionProps = {
  group: FinderDesignGroup;
  onBack?(): any;
};

const INDIVIDUAL_MAP_FLAG_NAME = "mapmaker.acknowledgedIndividualMapsWarning";

export default function MapmakerFinderGroupSelection({
  group,
  onBack,
}: MapmakerFinderGroupSelectionProps) {
  const {
    MultiSizeGroupWarningComponent,
    gotoNewFile,
  } = useMapmakerAppConfig();
  const [acknowledgedWarning, setAcknowledgedWarning] = useLocalStorage<
    boolean
  >(INDIVIDUAL_MAP_FLAG_NAME, false);

  // If our app has a warning for multi-sized maps and it has never been acknowledged, do so now.
  if (MultiSizeGroupWarningComponent && !acknowledgedWarning) {
    return (
      <ModalContent>
        <MultiSizeGroupWarningComponent
          design={group.design}
          onDecline={onBack}
          onAccept={() => setAcknowledgedWarning(true)}
        />
      </ModalContent>
    );
  }

  const sizes = group.designs
    .map(design => {
      const [, sizeStr] = getGroupIdAndSize(design);
      const size = sizeStr.split(/\D+/).map(n => parseInt(n));
      return {
        size,
        design,
      };
    })
    .sort((a, b) => a.size[0] * a.size[1] - b.size[0] * b.size[1]);

  return (
    <ModalContent className="mapmaker-finder-group-selection">
      <div className="header">
        <Icon name="chevron left" size="large" onClick={onBack} />
        <h2>{group.name}</h2>
      </div>
      <div className="thumbnail">
        <img src={group.design.thumbnailUrl} alt={group.name} />
      </div>
      <div className="instructions">
        <strong>Select your frame size.</strong>
        <p>
          The size of your frame is measured by the inside dimensions of the
          frame. These dimensions match the outside dimensions of the mat
          boards.
        </p>
      </div>
      <ul className="list">
        {sizes.map(({ size, design }) => {
          return (
            <li key={design.id} onClick={() => gotoNewFile(design.id)}>
              <div className="size">
                {size[0]} × {size[1]}"
              </div>
              <div>
                <Icon name="chevron right" />
              </div>
            </li>
          );
        })}
      </ul>
    </ModalContent>
  );
}

// tbl.com used -LxW to denote size. PM uses dashes.
export function getGroupIdAndSize(
  design: Pick<MapmakerDesign, "id">
): [string, string] {
  const [part1, part2] = design.id.split("-");
  if (part2 && /^\d+x\d+$/.test(part2)) {
    return [part1, part2];
  } else {
    return [design.id, null];
  }
}
