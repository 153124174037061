import {
  NavigationFragment,
  NavigationLinkFragment,
} from "../../../client/MapmakerApi";

export function getNavigationPath(
  root: NavigationFragment,
  targetSlug: string | null | undefined
): NavigationLinkFragment[] {
  if (!targetSlug) {
    return [];
  }
  for (let child of root.children ?? []) {
    if (child) {
      const path = getNavigationPathFromLink(child, targetSlug);
      if (path) {
        return path;
      }
    }
  }
  return [];
}

function getNavigationPathFromLink(
  root: NavigationLinkFragment,
  targetSlug: string
): NavigationLinkFragment[] | null {
  if (root.target?.slug?.current === targetSlug) {
    return [root];
  }
  for (let child of root.children ?? []) {
    if (child) {
      const path = getNavigationPathFromLink(child, targetSlug);
      if (path) {
        return [root, ...path];
      }
    }
  }
  return null;
}
