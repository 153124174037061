import { useMemo } from "react";
import {
  OpeningDateRange,
  getOpeningsWithInputs,
  getOpeningImagesDateRange,
  GetOpeningsWithInputsOptions,
  OpeningImageInput,
} from "@mapmaker/core";
import { useProjectFile } from "../useProjectState";
import useProjectState from "../useProjectState";

export type TimelineOpeningBundle = {
  openingId: string;
  modifiedAt: string;
  images: OpeningImageInput[];
  range: OpeningDateRange;
  year: number;
  isFirstOfYear: boolean;
  isOnlyOfYear: boolean;
  totalInYear: number;
};

type UseTimelineBundleProps = {
  filters: Partial<
    Pick<GetOpeningsWithInputsOptions, "mustCountTowardProgress">
  >;
};

export default function useTimelineBundle(
  { filters }: UseTimelineBundleProps = { filters: {} }
): TimelineOpeningBundle[] {
  const file = useProjectFile();
  const editorState = useProjectState();

  return useMemo<TimelineOpeningBundle[]>(() => {
    return getOpeningsWithInputs(file.design, file, {
      ...filters,
      mustHaveImages: true,
      sort: "timeline",
    })
      .map(({ featureId: openingId, input: openingInput }) => {
        return {
          openingId,
          modifiedAt: openingInput.modifiedAt,
          images: openingInput.images,
          range: getOpeningImagesDateRange(openingInput),
        };
      })
      .map((opening, i, items) => ({
        ...opening,
        ...getDerivedInfo(items, i),
      }));
  }, [getOpeningsWithInputs, file, editorState]);
}

function getDerivedInfo(
  openings: Pick<TimelineOpeningBundle, "range">[],
  indexToMatch: number
): {
  year: number;
  isFirstOfYear: boolean;
  isOnlyOfYear: boolean;
  totalInYear: number;
} {
  const openingToMatch = openings[indexToMatch];
  let firstIndex = Infinity;
  let lastIndex = -1;
  openings.forEach((opening, i) => {
    if (opening.range.first[0] === openingToMatch.range.first[0]) {
      firstIndex = Math.min(i, firstIndex);
      lastIndex = Math.max(i, lastIndex);
    }
  });
  return {
    year: openingToMatch.range.first[0],
    isFirstOfYear: firstIndex === indexToMatch,
    isOnlyOfYear: firstIndex === lastIndex,
    totalInYear: lastIndex - firstIndex,
  };
}
