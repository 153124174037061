import { captureMessage } from "@sentry/react";
import { FunctionComponent } from "react";
import {
  StorefrontAttributeInput,
  StorefrontSelectedOption,
  StorefrontProductImageFragment,
} from "../../client/MapmakerApi";
import { attributesToMap } from "../../lib";
import { ProductPageContextValue } from "./ProductPageContext";

export type ProductPlugin = {
  productType: string;
  SelectOptionsRenderer: FunctionComponent;
  managedSelections: string[];
  defaultCustomAttributes:
    | StorefrontAttributeInput[]
    | DefaultCustomAttributesGetter;
  availableCustomAttributes:
    | AvailableCustomAttribute[]
    | AvailableCustomAttributesGetter;
  images: (context: ProductPageContextValue) => ProductPageImage[];
  showQuantity: boolean;
  lineItemProperties: string[];
  validate: ProductOptionValidator;
};

export type DefaultCustomAttributesGetter = (
  selectedOptions: StorefrontSelectedOption[]
) => StorefrontAttributeInput[];

export type AvailableCustomAttribute = {
  key: string;
  required?: boolean;
};

export type AvailableCustomAttributesGetterProps = {
  selectedOptions: StorefrontSelectedOption[];
  customAttributes: StorefrontAttributeInput[];
};
export type AvailableCustomAttributesGetter = (
  options: AvailableCustomAttributesGetterProps
) => AvailableCustomAttribute[];

export type ProductOptionValidatorArguments = {
  selectedOptions: StorefrontSelectedOption[];
  customAttributes: StorefrontAttributeInput[];
  availableAttributes: AvailableCustomAttribute[];
};
export type ProductOptionValidator = (
  args: ProductOptionValidatorArguments
) => boolean;

export type ProductPageImage = Omit<
  StorefrontProductImageFragment,
  "__typename"
>;

const DefaultProductPlugin: Omit<ProductPlugin, "productType"> = {
  SelectOptionsRenderer: () => null,
  managedSelections: [],
  defaultCustomAttributes: [],
  availableCustomAttributes: [],
  lineItemProperties: [],
  images: () => [],
  validate: defaultValidator,
  showQuantity: false,
};

export function getPlugin(
  plugins: Record<string, Partial<ProductPlugin>>,
  productType: string
): ProductPlugin {
  if (productType && !plugins[productType]) {
    captureMessage(
      `No plugin found for product type "${productType}". Check the metafields of this product.`
    );
  }
  const plugin = plugins[productType] ?? {};
  return {
    productType,
    ...DefaultProductPlugin,
    ...plugin,
  };
}

export function getDefaultAttributes(
  plugin: ProductPlugin,
  selectedOptions: StorefrontSelectedOption[]
) {
  if (Array.isArray(plugin.defaultCustomAttributes)) {
    return plugin.defaultCustomAttributes;
  } else {
    return plugin.defaultCustomAttributes(selectedOptions);
  }
}

export const GIFT_NOTE_KEY = "Gift Note";

const UNIVERSAL_ATTRIBUTES: AvailableCustomAttribute[] = [
  {
    key: GIFT_NOTE_KEY,
    required: false,
  },
];

export function getAvailableAttributes(
  plugin: ProductPlugin,
  selectedOptions: StorefrontSelectedOption[],
  customAttributes: StorefrontAttributeInput[]
) {
  if (Array.isArray(plugin.availableCustomAttributes)) {
    return [...UNIVERSAL_ATTRIBUTES, ...plugin.availableCustomAttributes];
  } else {
    return [
      ...UNIVERSAL_ATTRIBUTES,
      ...plugin.availableCustomAttributes({
        selectedOptions,
        customAttributes,
      }),
    ];
  }
}

function defaultValidator({
  customAttributes,
  availableAttributes,
}: ProductOptionValidatorArguments): boolean {
  const customAttributesMap = attributesToMap(customAttributes);
  return availableAttributes.every(attribute => {
    if (attribute.required !== false && !customAttributesMap[attribute.key]) {
      return false;
    }
    return true;
  });
}
