import { RefObject, useEffect } from "react";

type ClickEvent = TouchEvent | MouseEvent;

export default function useOnClickOutside(
  ref: RefObject<Element>,
  handler: (e: ClickEvent) => any
) {
  useEffect(
    () => {
      const listener = (event: ClickEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mouseup", listener);
      document.addEventListener("touchend", listener);

      return () => {
        document.removeEventListener("mouseup", listener);
        document.removeEventListener("touchend", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}
