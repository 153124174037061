import React from "react";
import { FilePreviewSvgProps } from "@mapmaker/renderable";
import FullProductSvg from "../fullProduct/FullProductSvg";

/*
 * Renders an SVG which is used to preview the file.
 */

export default function FilePreviewSvg({
  file,
  width,
  height,
}: FilePreviewSvgProps<"resource">) {
  return (
    <FullProductSvg
      design={file.design}
      file={file}
      width={width}
      height={height}
    />
  );
}
