import clsx from "clsx";
import { useMemo, useState, FocusEvent, useCallback } from "react";
import { Icon } from "semantic-ui-react";
import { StorefrontCheckoutLineItemHeavyFragment } from "../../client/MapmakerApi";
import { Button } from "@mapmaker/ui";
import {
  updateAttributesWithValue,
  useUpdatePartialLineItem,
} from "../../lib/storefront";
import "./GiftNoteOptions.css";

type GiftNoteDisplayProps = {
  lineItem: StorefrontCheckoutLineItemHeavyFragment;
};

export default function GiftNoteDisplay({ lineItem }: GiftNoteDisplayProps) {
  const [updateCheckoutLineItems, { loading }] = useUpdatePartialLineItem();

  const giftNote = useMemo(
    () => lineItem.customAttributes.find(a => a.key === "Gift Note")?.value,
    [lineItem.customAttributes]
  );
  const [editing, setEditing] = useState(false);
  const [editedNote, setEditedNote] = useState<string>();

  const onNoteChange = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      setEditedNote(e.currentTarget.value || undefined);
    },
    [setEditedNote]
  );

  const saveGiftNote = useCallback(
    async (quantity: number) => {
      await updateCheckoutLineItems(
        lineItem.id,
        updateAttributesWithValue(
          lineItem.customAttributes,
          "Gift Note",
          editedNote ?? undefined
        ),
        quantity
      );
      setEditing(false);
    },
    [editedNote, updateCheckoutLineItems, lineItem]
  );

  if (giftNote || editing) {
    return (
      <div
        className={clsx("gift-note-display", { editing })}
        onClick={editing ? undefined : () => setEditing(true)}
      >
        <div className="header">
          <Icon name="gift" />
          <span>Gift Note</span>
          {!editing && <Icon className="edit-button" name="pencil" />}
        </div>
        {editing ? (
          <div className="note">
            <textarea
              placeholder="Message will be included in the box with this item."
              defaultValue={giftNote ?? ""}
              rows={4}
              onChange={onNoteChange}
              disabled={loading}
            />
            {lineItem.quantity > 1 ? (
              <>
                <Button
                  size="small"
                  outlined
                  onClick={() => saveGiftNote(1)}
                  disabled={loading}
                >
                  <Icon name="gift" />
                  {giftNote ? "Update One" : "Add to One"}
                </Button>
                <Button
                  size="small"
                  outlined
                  onClick={() => saveGiftNote(lineItem.quantity)}
                  disabled={loading}
                >
                  <Icon name="gift" />
                  {giftNote ? "Update" : "Add to"}{" "}
                  {lineItem.quantity === 2
                    ? "Both"
                    : `All ${lineItem.quantity}`}
                </Button>
              </>
            ) : (
              <Button
                size="small"
                outlined
                onClick={() => saveGiftNote(lineItem.quantity)}
                loading={loading}
              >
                <Icon name="gift" />
                {giftNote ? "Update Note" : "Add Note"}
              </Button>
            )}
            <Button
              size="small"
              outlined
              text
              onClick={() => setEditing(false)}
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div className="note display" onClick={() => setEditing(true)}>
            {giftNote}
          </div>
        )}
      </div>
    );
  } else {
    return (
      <Button size="small" outlined onClick={() => setEditing(true)}>
        <Icon name="gift" />
        Add Gift Note
      </Button>
    );
  }
}
