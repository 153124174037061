import ContextPanelSection from "../../project/context/ContextPanelSection";
import ContextPanelContent from "../../project/context/ContextPanelContent";
import RectangularPrintHelp from "../shared/RectangularPrintHelp";
import RectangularPrintSettings from "../shared/RectangularPrintSettings";
import RectangularPrintSettingsAdvanced from "../shared/RectangularPrintSettingsAdvanced";

export default function DownloadPrintsPanelContent() {
  return (
    <ContextPanelContent>
      <ContextPanelSection
        sectionId="help"
        label="How To Print"
        shortLabel="Help"
        icon="help"
        initialContentOpen={false}
      >
        <RectangularPrintHelp />
      </ContextPanelSection>
      <ContextPanelSection sectionId="settings" label="Settings" icon="setting">
        <RectangularPrintSettings />
      </ContextPanelSection>
      <ContextPanelSection
        sectionId="advanced"
        label="Advanced"
        icon="lab"
        initialContentOpen={false}
      >
        <RectangularPrintSettingsAdvanced />
      </ContextPanelSection>
    </ContextPanelContent>
  );
}
