import { EventHandler, SyntheticEvent, useEffect } from "react";
import { Icon, Modal, ModalHeader, StrictModalProps } from "semantic-ui-react";
import useMapmakerModals from "./useMapmakerModals";
import "./MapmakerModal.css";

export type MapmakerModalProps = StrictModalProps & {
  title?: string;
};

const stopProp: EventHandler<SyntheticEvent> = e => e.stopPropagation();

export default function MapmakerModal({
  children,
  title,
  ...props
}: MapmakerModalProps) {
  const { modalRoot, onModalChange } = useMapmakerModals();

  // This allows us to adjust other parts of the editor when a Modal is open.
  useEffect(() => {
    onModalChange && onModalChange(props.open);
  }, [props.open]);

  return (
    <div
      style={{ display: props.open ? undefined : "none" }}
      // We do not want any of this going beyond the modal.
      onClick={stopProp}
      onKeyDown={stopProp}
      onKeyUp={stopProp}
      onKeyDownCapture={stopProp}
      onKeyUpCapture={stopProp}
      onMouseDown={stopProp}
      onMouseUp={stopProp}
      onMouseEnter={stopProp}
      onMouseLeave={stopProp}
      onMouseOver={stopProp}
      onMouseOut={stopProp}
    >
      <Modal
        closeOnDocumentClick={false}
        size="small"
        {...props}
        /* Properties below here cannot be overridden by the passed in props. */
        id="mapmaker-modal"
        mountNode={modalRoot}
        dimmer="inverted"
      >
        {title && (
          <ModalHeader style={{ display: "flex" }}>
            <span style={{ flexGrow: 1 }}>{title}</span>
            <Icon
              name="x"
              onClick={props.onClose}
              style={{ cursor: "pointer" }}
            />
          </ModalHeader>
        )}
        {children}
      </Modal>
    </div>
  );
}
