import { CSSProperties } from "react";
import { Header } from "semantic-ui-react";
import CenteredPage from "./CenteredPage";
import GenericLoader from "./GenericLoader";

interface ILoadingPageProps {
  message?: string;
  secondary?: string;
  style?: CSSProperties;
}

export default function LoadingPage({
  message,
  secondary,
  style,
}: ILoadingPageProps) {
  return (
    <CenteredPage style={style}>
      <div style={{ textAlign: "center", padding: "1rem" }}>
        <GenericLoader />
        {message ? (
          <Header as="h3" content={message} subheader={secondary} />
        ) : null}
      </div>
    </CenteredPage>
  );
}
