import { useEffect } from "react";
import useLatest from "./useLatest";

export type IntervalEventHandler = () => any;

export default function useInterval(
  handler: IntervalEventHandler,
  intervalMs: number
) {
  const handlerRef = useLatest(handler);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handlerRef.current();
    }, intervalMs);

    return () => {
      clearInterval(intervalId);
    };
  }, [intervalMs]);
}
