import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOpeningPrintQuantity } from "../shared/printQuantitiesReducer";
import { OrderStickersStore } from "./orderStickersStore";
import {
  useCreateStickerOrderMutation,
  StickerOfferItemFragment,
} from "../../../client/MapmakerApi";
import { useMapmakerContext } from "../../..";
import {
  getOpeningList,
  getStickerSizeClass,
  StickerSizeClass,
} from "@mapmaker/core";
import {
  ShopifyStickerSet,
  ShopifyStickerVariant,
} from "../../../client/storefront/stickerHooks";

type DecoratedStickerOfferItem = StickerOfferItemFragment & {
  quantity: number;
  quantityWithTokens: number;
  quantityWithMoney: number;
  shopifyStickerVariant: ShopifyStickerVariant;
};

/**
 * Returns a decorated set of sticker items based on how we plan to use tokens.
 */
function getStickersWithTokensUsed(
  stickerSet: ShopifyStickerSet,
  printQuantities: { [key: string]: number },
  stickers: StickerOfferItemFragment[],
  useTokens: boolean,
  tokensAvailableForUse: number
): DecoratedStickerOfferItem[] {
  // Used as a counter in the final part of the array manipulation below
  let tokensRemaining = tokensAvailableForUse;

  return (
    stickers
      .map(sticker => ({
        quantity: printQuantities[sticker.openingId] || 0,
        shopifyStickerVariant: stickerSet.variants.find(
          variant => variant.sizeClass === sticker.sizeClass
        ),
        ...sticker,
      }))
      // Sort based on size in descending order which guarantees tokens are used maximally (as long as
      // each token is worth at lest 2x the previous token)
      .sort((a, b) =>
        a.shopifyStickerVariant.tokens > b.shopifyStickerVariant.tokens
          ? -1
          : a.shopifyStickerVariant.tokens < b.shopifyStickerVariant.tokens
          ? 1
          : 0
      )
      // Determine how much of the item's quantity that sticker tokens will be used for.
      .map(sticker => {
        let quantityWithTokens = 0;
        if (
          useTokens &&
          tokensRemaining >=
            sticker.shopifyStickerVariant.tokens * sticker.quantity
        ) {
          // Tokens used for entire item
          quantityWithTokens = sticker.quantity;
        } else if (useTokens) {
          // Tokens used for some of the item
          quantityWithTokens = Math.floor(
            tokensRemaining / sticker.shopifyStickerVariant.tokens
          );
        }
        tokensRemaining -=
          quantityWithTokens * sticker.shopifyStickerVariant.tokens;
        return {
          quantityWithTokens,
          quantityWithMoney: sticker.quantity - quantityWithTokens,
          ...sticker,
        };
      })
  );
}

export function useOrderStickersStore() {
  const state: OrderStickersStore = useSelector(
    (state: OrderStickersStore) => state
  );
  const { authenticatedAccount } = useMapmakerContext();

  /* Gets the currently selected outputOptions */
  const [selectedOutputOption, otherOutputOptions] = useMemo(() => {
    const selectedOutputOption = state.design.outputOptions.find(
      option =>
        option.type === state.file.outputType &&
        option.scale === state.file.outputScale
    );
    const otherOutputOptions = state.design.outputOptions.filter(
      option =>
        option.type !== state.file.outputType ||
        option.scale !== state.file.outputScale
    );
    return [selectedOutputOption, otherOutputOptions];
  }, [state.file, state.design]);

  /* Get the currently applicable shopifyStickerSet, limited to only size classes that are in the
  design to avoid confusion. */
  const currentShopifyStickerSizeSet: ShopifyStickerSet = useMemo(() => {
    const sizeClassesInThisDesign = getOpeningList(state.design)
      // This filter is because of legacy TBL designs that have openings for the "Outer" layer. Since replaced
      // by graphics.
      .filter(opening => opening.layout.cut || opening.layout.print)
      .reduce((sizeClasses, opening) => {
        const stickerSizeClass = getStickerSizeClass(
          opening,
          state.file.outputScale,
          state.file.outputType
        );
        sizeClasses.add(stickerSizeClass);
        return sizeClasses;
      }, new Set<StickerSizeClass>());

    const currentSet = state.shopifyStickerSetMap[state.file.outputType];
    return {
      ...currentSet,
      variants: currentSet.variants.filter(variant =>
        sizeClassesInThisDesign.has(variant.sizeClass)
      ),
    };
  }, [state.design, selectedOutputOption]);

  const [createStickerOrder] = useCreateStickerOrderMutation();

  // TODO - Needs to take into account anything in the cart!!
  const tokensAvailableForUse =
    (authenticatedAccount?.stickerTokenBalance ?? 0) -
    state.cartState.stickerTokensInCart;

  const [
    stickerOrderItems,
    stickerOrderItemsIfTokensUsed,
  ]: DecoratedStickerOfferItem[][] = useMemo(
    () => [
      getStickersWithTokensUsed(
        currentShopifyStickerSizeSet,
        state.printQuantities,
        state.offer.stickers,
        state.settings.useTokens,
        tokensAvailableForUse
      ),
      getStickersWithTokensUsed(
        currentShopifyStickerSizeSet,
        state.printQuantities,
        state.offer.stickers,
        true,
        tokensAvailableForUse
      ),
    ],
    [
      state.offer.stickers,
      state.settings.useTokens,
      state.printQuantities,
      currentShopifyStickerSizeSet,
    ]
  );

  const totalQuantity = Object.values(state.printQuantities).reduce(
    (sum, qty) => sum + qty,
    0
  );

  const totalQuantityIncludingCart =
    totalQuantity +
    state.cartState.groups.reduce((sum, group) => sum + group.stickerCount, 0);

  const tokensRequiredForFullOrder = stickerOrderItems.reduce(
    (sum, stickerOrderItem) =>
      sum +
      stickerOrderItem.quantity * stickerOrderItem.shopifyStickerVariant.tokens,
    0
  );

  const tokensToBeSpentIfUsed = stickerOrderItemsIfTokensUsed.reduce(
    (tokens, stickerOrderItem) => {
      return (
        tokens +
        stickerOrderItem.quantityWithTokens *
          stickerOrderItem.shopifyStickerVariant.tokens
      );
    },
    0
  );

  const totalTokens = stickerOrderItems.reduce(
    (sum, stickerOrderItem) =>
      sum +
      stickerOrderItem.quantityWithTokens *
        stickerOrderItem.shopifyStickerVariant.tokens,
    0
  );

  const totalMoney = stickerOrderItems.reduce(
    (sum, stickerOrderItem) =>
      sum +
      stickerOrderItem.quantityWithMoney *
        stickerOrderItem.shopifyStickerVariant.price,
    0
  );

  const totalTokensFormatted = useMemo(() => {
    if (totalTokens === 0) {
      return null;
    } else if (totalTokens === 1) {
      return `1 tokens`;
    } else {
      return `${totalTokens} tokens`;
    }
  }, [totalTokens]);
  const totalMoneyFormatted = `$${totalMoney.toFixed(2)}`;

  const totalQuantityFormatted = `${totalQuantity} ${
    totalQuantity === 1 ? "sticker" : "stickers"
  }`;

  const totalCostFormatted = useMemo(() => {
    if (totalMoney === 0) {
      return totalTokensFormatted;
    } else if (totalTokens === 0) {
      return totalMoneyFormatted;
    } else {
      return `${totalTokensFormatted} + ${totalMoneyFormatted}`;
    }
  }, [totalMoney, totalTokens, totalMoneyFormatted, totalTokensFormatted]);

  async function createStickerOrderBound() {
    return await createStickerOrder({
      variables: {
        input: {
          fileId: state.file.id,
          outputType: state.file.outputType,
          stickers: stickerOrderItems
            .filter(i => i.quantity > 0)
            .map(stickerOrderItem => ({
              openingId: stickerOrderItem.openingId,
              scale: state.file.outputScale,
              quantity: stickerOrderItem.quantity,
              quantityWithMoney: stickerOrderItem.quantityWithMoney,
              quantityWithTokens: stickerOrderItem.quantityWithTokens,
            })),
        },
      },
    });
  }

  return {
    ...state,
    totalMoney,
    totalMoneyFormatted,
    totalTokens,
    totalTokensFormatted,
    totalCostFormatted,
    tokensToBeSpentIfUsed,
    totalQuantity,
    totalQuantityFormatted,
    currentShopifyStickerSizeSet,
    stickerOrderItems,
    tokensAvailableForUse,
    tokensRequiredForFullOrder,
    totalQuantityIncludingCart,
    selectedOutputOption,
    otherOutputOptions,
    createStickerOrder: createStickerOrderBound,
  };
}

export function useStickerOfferItem(openingId: string) {
  const dispatch = useDispatch();
  const { stickerOrderItems } = useOrderStickersStore();

  const sticker = useMemo(
    () =>
      stickerOrderItems.find(
        stickerOrderItem => openingId === stickerOrderItem.openingId
      ),
    [openingId, stickerOrderItems]
  );

  function setQuantity(quantity: number) {
    dispatch(setOpeningPrintQuantity(openingId, quantity));
  }

  return {
    ...sticker,
    setQuantity,
  };
}
