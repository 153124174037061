import CMSPage from "./StorefrontCMSPage";
import { gql } from "@apollo/client";
import { usePrivacyPolicyQuery } from "../../client/MapmakerApi";

gql`
  query privacyPolicy {
    storefrontShop {
      privacyPolicy {
        title
        body
      }
    }
  }
`;

export default function PrivacyPolicyPage() {
  const { data, loading } = usePrivacyPolicyQuery();
  return (
    <CMSPage page={data?.storefrontShop.privacyPolicy} loading={loading} />
  );
}
