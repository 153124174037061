import { MapmakerDesign } from "@mapmaker/core";
import { getPaperSize } from "../../../lib/prints/printSizes";
import { roundTo } from "../../../lib/math";
import { RectangularPrintLayoutInput } from "../../../client/MapmakerApi";

export function getPrintFilenameSize(print: { width: number; height: number }) {
  const paperSize = getPaperSize(print.width, print.height);
  if (paperSize) {
    return paperSize.filename;
  } else {
    const [minSide, maxSide] = [print.width, print.height].sort();
    return `${roundTo(minSide / 72, 1)}x${roundTo(maxSide / 72, 2)}`;
  }
}

export function getPrintFilenameOpenings(
  printLayout: RectangularPrintLayoutInput,
  design: Pick<MapmakerDesign, "features">
): string {
  const numOpenings = printLayout.printOpenings.length;
  const prettyOpeningIds = printLayout.printOpenings.map(opening =>
    design.features[opening.openingId].name.replace("_", "")
  );
  if (numOpenings <= 2) {
    return prettyOpeningIds.join("-");
  } else {
    return `${prettyOpeningIds[0]}-and-${numOpenings - 1}-others`;
  }
}

export function getPrintFilename(
  print: RectangularPrintLayoutInput,
  design: Pick<MapmakerDesign, "features">
) {
  return `${getPrintFilenameSize(print)}-${getPrintFilenameOpenings(
    print,
    design
  )}`;
}
