import { useState, useMemo } from "react";
import isEmpty from "validator/es/lib/isEmpty";
import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import { Button, Form } from "semantic-ui-react";
import WalgreensCheckoutStepContainer from "./WalgreensCheckoutStepContainer";
import useWalgreensCheckout from "./useWalgreensCheckout";
import {
  setCustomerInformation,
  WalgreensCustomerInformation,
} from "./walgreensCheckoutReducer";
import { useDispatch, useSelector } from "react-redux";
import "./WalgreensGetCustomerInformation.css";
import { useMapmakerAppConfig } from "../../../client/MapmakerAppConfig";
import { IBasePrintState } from "../shared/ReduxPrintStates";

type FormField = {
  label: string;
  example: string;
  validator: (value: any) => boolean;
  errorMessage: string;
};
type FieldMap = { [key: string]: FormField };

const Fields: FieldMap = {
  firstName: {
    label: "First Name",
    example: "John",
    validator: x => !isEmpty(x),
    errorMessage: "First name is required",
  },
  lastName: {
    label: "Last Name",
    example: "Doe",
    validator: x => !isEmpty(x),
    errorMessage: "Last name is required",
  },
  email: {
    label: "Email",
    example: "you@example.com",
    validator: isEmail,
    errorMessage: "Email address is not valid",
  },
  phone: {
    label: "Phone",
    example: "555-555-5555",
    validator: x => isMobilePhone(x, "en-US"),
    //		transformer: value => value.replace(/[^0-9]/g, ''),
    errorMessage: "Enter phone number as ###-###-####",
  },
};

export default function WalgreensGetCustomerInformation() {
  const dispatch = useDispatch();
  const { checkout } = useWalgreensCheckout();
  const [formData, setFormData] = useState<
    Partial<WalgreensCustomerInformation>
  >(checkout.customerInformation || {});
  const [formErrors, setFormErrors] = useState({});

  const allValid = useMemo(() => {
    return Object.entries(Fields).every(
      ([fieldName, field]) =>
        formData[fieldName] && field.validator(formData[fieldName])
    );
  }, [formData]);

  function setFormField(fieldName) {
    return e => {
      setFormData({
        ...formData,
        [fieldName]: e.target.value,
      });
      // Check if we fixed an error
      if (
        formErrors[fieldName] &&
        Fields[fieldName].validator(e.target.value)
      ) {
        setFormErrors({
          ...formErrors,
          [fieldName]: undefined,
        });
      }
    };
  }

  function fieldBlur(fieldName) {
    return e => {
      const isValid = Fields[fieldName].validator(e.target.value);
      const currentError = formErrors[fieldName];
      const newError = isValid ? undefined : Fields[fieldName].errorMessage;
      if (currentError !== newError) {
        setFormErrors({
          ...formErrors,
          [fieldName]: newError,
        });
      }
    };
  }

  function onSubmit(e) {
    const customerInfo = formData as WalgreensCustomerInformation;
    let phone = customerInfo.phone.replace(/\D/g, "");
    if (phone.startsWith("1") && phone.length === 11) {
      phone = phone.substring(1);
    }
    customerInfo.phone = phone;
    dispatch(setCustomerInformation(customerInfo));
  }

  function Field(name: string) {
    const field = Fields[name];
    return (
      <Form.Input
        name={name}
        defaultValue={checkout.customerInformation?.[name]}
        label={field.label}
        onChange={setFormField(name)}
        onBlur={fieldBlur(name)}
        error={formErrors[name]}
        placeholder={field.example}
      />
    );
  }

  return (
    <WalgreensCheckoutStepContainer header="Customer Information">
      <div id="walgreens-get-customer-information">
        <p>
          Enter the information of the person who will be picking up the order.
        </p>
        <Form onSubmit={onSubmit}>
          <Form.Group widths="equal">
            {Field("firstName")}
            {Field("lastName")}
          </Form.Group>
          <Form.Group widths="equal">
            {Field("email")}
            {Field("phone")}
          </Form.Group>
          <Button
            basic
            className="walgreens"
            type="submit"
            disabled={!allValid}
          >
            Review Order
          </Button>
        </Form>
      </div>
    </WalgreensCheckoutStepContainer>
  );
}
