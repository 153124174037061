import { gql } from "@apollo/client";
import Suspender from "react-suspender";
import { useAllPostsQuery } from "../../client/MapmakerApi";
import MessagePage from "../shared/MessagePage";
import BlogPostCardList from "./BlogPostCardList";

gql`
  query allPosts {
    allPost(sort: [{ publishedAt: DESC }]) {
      ...BlogPostCard
    }
  }
`;

export default function MapmakerBlogPosts() {
  const { loading, error, data } = useAllPostsQuery({
    variables: {},
  });

  if (loading) {
    return <Suspender />;
  } else if (!data || error) {
    return (
      <MessagePage icon="warning circle">
        An unexpected error occurred. Please try again.
      </MessagePage>
    );
  }

  return (
    <div id="mapmaker-blog-posts">
      <BlogPostCardList posts={data.allPost} />
    </div>
  );
}
