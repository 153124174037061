import * as React from "react";
import "./TBLLogoText.scss";

export default function TBLLogoText({ ...props }) {
  return (
    <div id="tbl-logo-text" {...props}>
      THUNDER<span className="green">BUNNY</span>LABS
    </div>
  );
}
