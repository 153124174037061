import useOpening from "./useOpening";
import MapmakerTopBar from "../../../layout/MapmakerTopBar";
import { useProjectDispatch } from "../../projectStore";
import { OpeningInput } from "@mapmaker/core";
import { updateFeatureInput } from "../../fileReducer";
import OpeningEditorView from "./editable/OpeningEditorView";
import { useRef } from "react";
import { useMapmakerAppConfig } from "../../../../client";
import { useProjectFile } from "../../useProjectState";
import ProjectTopBarButton from "../../ProjectTopBarButton";
import OpeningEditorViewToolbar from "./OpeningEditorViewToolbar";
import FeatureExitButton from "../../shared/FeatureExitButton";

type OpeningLiveEditorViewProps = {
  openingId: string;
};

export default function OpeningLiveEditorView({
  openingId,
}: OpeningLiveEditorViewProps) {
  const dispatch = useProjectDispatch();
  const { gotoEditFile } = useMapmakerAppConfig();
  const file = useProjectFile();
  const { openingFeature, openingInput: initialOpeningInput } = useOpening(
    openingId
  );
  const editedOpeningInput = useRef<OpeningInput>(initialOpeningInput);

  // When an opening is changed, we propagate the changes to the main project store.
  function onOpeningInputChange(openingInput: OpeningInput) {
    editedOpeningInput.current = openingInput;
    dispatch(updateFeatureInput(openingId, openingInput));
  }

  return (
    <OpeningEditorView
      openingId={openingId}
      initialOpeningInput={initialOpeningInput}
      onChange={onOpeningInputChange}
      topBar={
        <MapmakerTopBar
          breadcrumbs={[{ label: openingFeature.name }]}
          after={<ProjectTopBarButton />}
        />
      }
      mainAddons={
        <>
          <FeatureExitButton onClick={() => gotoEditFile(file.id)} />
          <OpeningEditorViewToolbar />
        </>
      }
    />
  );
}
