import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classes from "./PieChart.module.scss";
export function PieChart({ radius, innerRadius = 0, border, background, children, }) {
    const style = {
        "--outer-radius": radius + "px",
        "--inner-radius": innerRadius + "px",
    };
    return (_jsxs("div", Object.assign({ className: classes.container, style: style }, { children: [_jsx("div", { className: classes.background, style: { border, background } }), children] })));
}
const deg = (percentage) => (percentage * 360).toFixed(3) + "deg";
export function PieSlice({ start, stop, color }) {
    if (stop <= start) {
        return null;
    }
    if (stop - start > 0.5) {
        // Kinda silly but our method only works up to 180 degrees, so if it's more we just do it twice.
        return (_jsxs(_Fragment, { children: [_jsx(PieSlice, { start: start, stop: start + 0.5, color: color }), _jsx(PieSlice, { start: start + 0.5, stop: stop, color: color })] }));
    }
    return (_jsx("div", Object.assign({ className: classes.hold, style: { transform: `rotate(${deg(start)})` } }, { children: _jsx("div", { className: classes.pie, style: {
                transform: `rotate(${deg(stop - start)})`,
                background: color,
            } }) })));
}
export function PieFilling({ background, border, children }) {
    return (_jsx("div", Object.assign({ className: classes.filling, style: { background, border } }, { children: children })));
}
