import { NumberBadge } from "@mapmaker/ui";
import { useMapmakerAppConfig } from "../../client/MapmakerAppConfig";
import MapmakerToolbar from "./shared/toolbar/MapmakerToolbar";
import MapmakerToolbarButton from "./shared/toolbar/MapmakerToolbarButton";
import { useProjectFile, useProjectDesign } from "./useProjectState";
import ProjectSettingsModal from "./fullmap/modals/ProjectSettingsModal";
import { useAddToastMessage } from "./shared/toasts/useProjectToastMessages";
import { useMemo } from "react";
import { getOpeningList } from "@mapmaker/core";
import useProjectState from "./useProjectState";
import { MapmakerFileViewerPolicy } from "../../client/MapmakerApi";
import useProjectOpeningSuggestions from "./features/opening/suggestions/useOpeningSuggestions";

export type ProjectPage =
  | "home"
  | "collaborate"
  | "timeline"
  | "sharing"
  | "output";

type ProjectToolbarProps = {
  selected: ProjectPage;
};

export default function ProjectToolbar({ selected }: ProjectToolbarProps) {
  const { viewerPolicy } = useProjectFile();
  switch (viewerPolicy) {
    case MapmakerFileViewerPolicy.Owner:
      return <OwnerToolbar selected={selected} />;
    case MapmakerFileViewerPolicy.Collaborator:
      return <CollaboratorToolbar selected={selected} />;
    case MapmakerFileViewerPolicy.Viewer:
      return <ViewerToolbar selected={selected} />;
    default:
      return null;
  }
}

function OwnerToolbar({ selected }: ProjectToolbarProps) {
  const { gotoEditFile } = useMapmakerAppConfig();
  const file = useProjectFile();
  const design = useProjectDesign();
  const { addToastWarning } = useAddToastMessage();
  const { numOpeningsWithImages, uploads } = useProjectState();
  const openingSuggestions = useProjectOpeningSuggestions({
    includeCurrentlyInMap: false,
    includeHidden: false,
  });

  const isSingleOpeningDesign = useMemo(
    () => getOpeningList(design).length <= 1,
    [design]
  );

  return (
    <MapmakerToolbar id="full-map-toolbar">
      <MapmakerToolbarButton
        selected={selected === "home"}
        icon="globe"
        label="Edit"
        action={() => gotoEditFile(file.id)}
      >
        <ProjectSettingsModal />
      </MapmakerToolbarButton>
      <MapmakerToolbarButton
        selected={selected === "collaborate"}
        hidden={isSingleOpeningDesign}
        icon="users"
        label="Collaborate"
        disabled={numOpeningsWithImages === 0}
        badge={<NumberBadge color="accent" count={openingSuggestions.length} />}
        onClickWhileDisabled={() =>
          addToastWarning(
            `Click any ${design.regionType} to add some photos before sharing your photos.`,
            "sharing-disabled"
          )
        }
        action={() => gotoEditFile(file.id, "collaborate")}
      />
      <MapmakerToolbarButton
        selected={selected === "timeline"}
        hidden={isSingleOpeningDesign}
        icon="calendar alternate"
        disabled={numOpeningsWithImages === 0}
        label="Timeline"
        onClickWhileDisabled={() =>
          addToastWarning(
            `Click any ${design.regionType} to add some photos before viewing the timeline.`,
            "timeline-disabled"
          )
        }
        action={() => gotoEditFile(file.id, "timeline")}
      />
      <PrintButton selected={selected} />
      <div className="spacer" />
      <MapmakerToolbarButton mode="modal" icon="setting" label="Settings">
        <ProjectSettingsModal />
      </MapmakerToolbarButton>
    </MapmakerToolbar>
  );
}

function CollaboratorToolbar({ selected }: ProjectToolbarProps) {
  const file = useProjectFile();
  const { gotoEditFile } = useMapmakerAppConfig();
  const draftOpeningSuggestions = useProjectOpeningSuggestions({
    includeSubmitted: false,
  });

  return (
    <MapmakerToolbar id="full-map-toolbar">
      <MapmakerToolbarButton
        selected={selected === "home"}
        icon="globe"
        label="View"
        action={() => gotoEditFile(file.id)}
      />
      <MapmakerToolbarButton
        selected={selected === "collaborate"}
        badge={
          <NumberBadge color="accent" count={draftOpeningSuggestions.length} />
        }
        icon="lightbulb"
        label="Suggestions"
        action={() => gotoEditFile(file.id, "collaborate")}
      />
      <PrintButton selected={selected} />
    </MapmakerToolbar>
  );
}

function ViewerToolbar({ selected }: ProjectToolbarProps) {
  const file = useProjectFile();
  const { gotoEditFile } = useMapmakerAppConfig();

  return (
    <MapmakerToolbar id="full-map-toolbar">
      <MapmakerToolbarButton
        selected={selected === "home"}
        icon="globe"
        label="View"
        action={() => gotoEditFile(file.id)}
      />
      <PrintButton selected={selected} />
    </MapmakerToolbar>
  );
}

/**
 * Individual buttons that get shared
 */
function PrintButton({ selected }: ProjectToolbarProps) {
  const { gotoSelectPrintService } = useMapmakerAppConfig();
  const file = useProjectFile();
  const design = useProjectDesign();
  const { addToastWarning } = useAddToastMessage();
  const { numOpeningsWithImages } = useProjectState();

  return (
    <MapmakerToolbarButton
      icon="print"
      selected={selected === "output"}
      disabled={numOpeningsWithImages === 0}
      label="Print"
      onClickWhileDisabled={() =>
        addToastWarning(
          `Click any ${design.regionType} to add some photos before printing.`,
          "printing-disabled"
        )
      }
      action={() => gotoSelectPrintService(file.id)}
    />
  );
}
