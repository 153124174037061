import { useAuthContext } from "@tbl/aws-auth";
import clsx from "clsx";
import { gql } from "@apollo/client";
import { format } from "date-fns";
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import Suspender from "react-suspender";
import { LinkButton } from "@mapmaker/ui";
import {
  OrderLightFragment,
  useGetOrdersForUserQuery,
} from "../../client/MapmakerApi";
import FormattedAddress from "./FormattedAddress";
import useGroupedLineItems from "./useGroupedItems";
import CenteredPage from "../shared/CenteredPage";
import SafeImage from "../shared/images/SafeImage";
import "./OrderHistory.css";

gql`
  fragment OrderLight on Order {
    id
    number
    createdAt
    cancelledAt
    subtotal
    totalShipping
    totalTax
    total
    shippingAddressFormatted
    items {
      ...OrderItemLight
    }
  }

  query getOrdersForUser {
    orders {
      ...OrderLight
    }
  }
`;

export default function OrderHistory() {
  const { user } = useAuthContext();
  const { loading, data } = useGetOrdersForUserQuery();

  if (loading) {
    return <Suspender />;
  }

  if (data?.orders.length === 0) {
    return (
      <CenteredPage>
        <div style={{ textAlign: "center", maxWidth: "300px" }}>
          <h3>You haven't placed any orders yet.</h3>
          <LinkButton to="/shop" color="accent">
            Go Shopping!
          </LinkButton>
          <p style={{ marginTop: "1rem", color: "var(--mm-grey)" }}>
            Note that only orders placed with the email address{" "}
            <strong>{user.email}</strong> will show up here.
          </p>
        </div>
      </CenteredPage>
    );
  }

  return (
    <div id="order-history">
      {data?.orders.map(order => (
        <OrderRow key={order.id} order={order} />
      ))}
    </div>
  );
}

type OrderRowProps = {
  order: OrderLightFragment;
};

function OrderRow({ order }: OrderRowProps) {
  const orderLink = `/account/orders/${order.number}`;
  const navigate = useNavigate();
  const gotoOrder = useCallback(() => {
    navigate(orderLink);
  }, [orderLink, navigate]);

  const { stickerGroups = {}, items = [] } = useGroupedLineItems(order?.items);

  return (
    <div id="order-row">
      <div className="top">
        <span
          className={clsx({ number: true, cancelled: !!order.cancelledAt })}
        >
          <Link to={orderLink}>Order #{order.number}</Link>
          {order.cancelledAt && <span className="status"> (cancelled)</span>}
        </span>
      </div>
      <div className="details">
        <div className="summary">
          <h6>Ordered</h6>
          <div title={order.createdAt}>
            {format(new Date(order.createdAt), "MMM do, yyyy")}
          </div>
          <h6>Recipient</h6>
          <FormattedAddress address={order.shippingAddressFormatted} />
        </div>
        <div className="items">
          {items.slice(0, 3).map(item => (
            <SafeImage
              key={item.id}
              title={item.name}
              src={item.image?.url}
              alt={item.image?.altText ?? "Preview"}
              onClick={gotoOrder}
            />
          ))}
          {Object.values(stickerGroups).map(stickerGroup => (
            <SafeImage
              key={stickerGroup[0].id}
              title={"Custom photo map stickers"}
              src={stickerGroup[0].image?.url}
              alt={stickerGroup[0].image?.altText ?? "Preview"}
              onClick={gotoOrder}
            />
          ))}
          {items.length + Object.keys(stickerGroups).length > 3 && (
            <div className="more-items" onClick={gotoOrder}>
              <Icon name="plus" size="big" />
              <span>More Items</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
