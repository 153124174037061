import { gql } from "@apollo/client";
import { ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";
import { OrderItemDetailsFragment } from "../../client/MapmakerApi";
import SafeImage from "../shared/images/SafeImage";
import "./OrderLineItem.css";

gql`
  fragment OrderItemLight on OrderItem {
    id
    name
    image {
      url
      altText
    }
    ...MapLineItem
  }
`;

type OrderLineItemProps = Pick<
  OrderItemDetailsFragment,
  "name" | "originalUnitPrice" | "quantity" | "attributes"
> &
  Partial<Pick<OrderItemDetailsFragment, "handle" | "image">> & {
    children?: ReactNode;
    className?: string;
    imageNode?: ReactNode;
    productLink?: string;
  };

export default function OrderLineItem({
  name,
  image,
  imageNode,
  originalUnitPrice,
  handle,
  productLink,
  quantity,
  attributes,
  className,
  children,
}: OrderLineItemProps) {
  const price = useMemo(() => {
    if (quantity === 1) {
      return <>${originalUnitPrice.toFixed(2)}</>;
    } else {
      return (
        <>
          {quantity} x ${originalUnitPrice.toFixed(2)}/ea
        </>
      );
    }
  }, [originalUnitPrice, quantity]);

  return (
    <div id="order-line-item" className={className}>
      <div className="header">
        <div className="name">
          <Link to={productLink ?? `/product/${handle}`}>{name}</Link>
        </div>
        <div className="price">{price}</div>
      </div>
      <div className="content">
        <Link to={productLink ?? `/product/${handle}`}>
          <SafeImage src={image?.url} alt={image?.altText ?? name} />
        </Link>
        {imageNode && (
          <Link to={productLink ?? `/product/${handle}`}>
            <div className="image-node">{imageNode}</div>
          </Link>
        )}
        {attributes && (
          <ul className="attributes">
            {Object.entries(attributes).map(([key, value]) => {
              return (
                <li key={key}>
                  <span className="key">{key}</span>
                  <span className="value">{value as string}</span>
                </li>
              );
            })}
          </ul>
        )}
        {children && <div className="children">{children}</div>}
      </div>
    </div>
  );
}
