import { useState, useEffect } from "react";

// @ts-ignore Instead of using the DOM lib, we ignore the Typescript errors here.
const isBrowser = !!(
  // Extremely silly but we need "true &&" or prettier moves our @ts-ignore to the wrong location
  (
    true &&
    // @ts-ignore
    typeof window !== "undefined" &&
    // @ts-ignore
    window.document &&
    // @ts-ignore
    window.document.createElement
  )
);

type UseLoadedUrlOptions = {
  onError?(url: string): any;
  retries?: number;
  retryDelayMs?: number;
};

/**
 * An isomorphic method, which when used in the browser allows you to pass an image URL and does
 * not return it until it has loaded.
 */
const useLoadedUrl: (
  url: string,
  options?: UseLoadedUrlOptions
) => string | undefined = isBrowser ? useLoadedUrlBrowser : useLoadedUrlServer;

export default useLoadedUrl;

function useLoadedUrlServer(url: string): string {
  return url;
}

function useLoadedUrlBrowser(
  url: string,
  {
    onError: onErrorCallback,
    retries = 0,
    retryDelayMs = 1000,
  }: UseLoadedUrlOptions = {}
): string {
  const [loadedUrl, setLoadedUrl] = useState<string>();
  const [retryNumber, setRetryNumber] = useState(0);
  const [retryTimerId, setRetryTimerId] = useState<number>();

  useEffect(() => {
    return () => {
      // @ts-ignore
      retryTimerId && window.clearTimeout(retryTimerId);
    };
  }, [retryTimerId]);

  useEffect(() => {
    if (!url) {
      return;
    }
    // @ts-ignore
    const imageLoader = new Image();
    imageLoader.onload = onLoad;
    imageLoader.onerror = onError;
    imageLoader.src = url;
    function onLoad() {
      setLoadedUrl(url);
    }
    function onError() {
      if (retryNumber < retries) {
        // @ts-ignore
        const timerId = window.setTimeout(() => {
          setRetryNumber(retryNumber + 1);
        }, retryDelayMs * retryNumber /* Simple "exponential" back off. */);
        setRetryTimerId(timerId);
      } else {
        // Maxed out our retries. Let's give up.
        setLoadedUrl(undefined);
        onErrorCallback && onErrorCallback(url);
      }
    }
    return () => {
      imageLoader.onload = null;
      imageLoader.onerror = null;
    };
  }, [url, setLoadedUrl, retryNumber]);

  return loadedUrl;
}
