import { Button } from "@mapmaker/ui";
import { ModalActions, ModalContent } from "semantic-ui-react";
import MapmakerModal from "../../../shared/modals/MapmakerModal";
import SharingSettings from "./SharingSettings";

type SharingSettingsModalProps = {
  onClose?(): any;
  open?: boolean;
};

export default function SharingSettingsModal({
  onClose,
  open = true,
}: SharingSettingsModalProps) {
  return (
    <MapmakerModal
      className="sharing-settings-modal"
      open={open}
      onClose={onClose}
      title="Sharing Settings"
      size="small"
    >
      <ModalContent>
        <SharingSettings hideLabel onLeave={onClose} />
      </ModalContent>
      <ModalActions>
        <Button color="accent" onClick={onClose}>
          Done
        </Button>
      </ModalActions>
    </MapmakerModal>
  );
}
