import { RenderableForm } from ".";
import * as yup from "yup";

export const FilePreviewSvgForm: RenderableForm = {
  initialValues: {
    width: 1024,
    height: 1024,
  },
  inputs: [
    {
      type: "file",
      id: "file",
      name: "Project",
      validation: yup.string().required(),
    },
    {
      type: "number",
      id: "width",
      name: "Width",
      validation: yup.number(),
    },
    {
      type: "number",
      id: "height",
      name: "Height",
      validation: yup.number(),
    },
  ],
};
