import { ReactNode } from "react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import CenteredPage from "./CenteredPage";
import { Icon } from "semantic-ui-react";
import "./MessagePage.css";

export interface IMessagePageProps {
  icon: SemanticICONS;
  message?: string;
  children?: ReactNode;
}

export default function MessagePage({
  icon,
  message,
  children,
}: IMessagePageProps) {
  return (
    <CenteredPage>
      <div id="message-page">
        <div className="icon-container">
          <Icon name={icon} size="huge" />
        </div>
        <div className="message">{children || message}</div>
      </div>
    </CenteredPage>
  );
}
