import { ApolloError, gql } from "@apollo/client";
import { Button } from "@mapmaker/ui";
import {
  ModalActions,
  ModalContent,
  Form as SemanticForm,
  FormField,
  TextArea,
} from "semantic-ui-react";
import { useState } from "react";
import { HiPlus } from "@react-icons/all-files/hi/HiPlus";
import { MdSend } from "@react-icons/all-files/md/MdSend";
import { FaExclamationTriangle } from "@react-icons/all-files/fa/FaExclamationTriangle";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import useLocalStorage from "react-use/lib/useLocalStorage";
import {
  OpeningSuggestionFragment,
  useSubmitOpeningSuggestionMutation,
} from "../../../../../client/MapmakerApi";
import MapmakerModal from "../../../../shared/modals/MapmakerModal";
import { useAddToastMessage } from "../../../shared/toasts/useProjectToastMessages";
import OpeningSuggestionThumbnail from "./OpeningSuggestionThumbnail";
import { MapmakerSuccessModal } from "../../../../shared/modals/MapmakerSuccessModal";
import { useMapmakerAppConfig } from "../../../../../client";
import { useProjectFile } from "../../../useProjectState";
import { useProjectDispatch } from "../../../projectStore";
import { updateOpeningSuggestion } from "../../../fileReducer";
import { trackGtmEvent } from "../../../../../lib/gtm";
import "./SubmitSuggestionModal.css";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1)
    .max(40)
    .required("You must provide a name."),
});

type SubmitSuggestionModalProps = {
  suggestion: OpeningSuggestionFragment;
  open: boolean;
  onClose: () => any;
};

export default function SubmitSuggestionModal(
  props: SubmitSuggestionModalProps
) {
  const { suggestion, onClose } = props;
  const file = useProjectFile();
  const projectDispatch = useProjectDispatch();
  const { gotoEditFile } = useMapmakerAppConfig();
  const { addToastError } = useAddToastMessage();
  const [
    submitSuggestion,
    { error, reset, data },
  ] = useSubmitOpeningSuggestionMutation({
    onError: data => addToastError(data.message),
  });

  const [defaultName, setDefaultName] = useLocalStorage(
    "suggestion.defaultName"
  );

  const onSubmit = async (values: FormValues) => {
    setDefaultName(values.name);
    await submitSuggestion({
      variables: {
        input: {
          id: suggestion.id,
          fileId: suggestion.fileId,
          openingId: suggestion.openingId,
          name: values.name,
          note: values.note,
        },
      },
    });
    trackGtmEvent({
      event: "mapmaker.submit-suggestion",
      openingId: suggestion.openingId,
    });
  };

  function resetAndClose() {
    reset();
    onClose();
  }

  if (data?.submitOpeningSuggestion) {
    return (
      <MapmakerSuccessModal
        onClose={() => {
          resetAndClose();
          projectDispatch(
            updateOpeningSuggestion(data.submitOpeningSuggestion.suggestion)
          );
          gotoEditFile(file.id, "collaborate");
        }}
      >
        <h1>Success!</h1>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.2rem",
            padding: "0 2rem",
            marginTop: "1rem",
          }}
        >
          The project owner can now review your suggestion.
        </p>
      </MapmakerSuccessModal>
    );
  }

  return (
    <Formik
      initialValues={{
        name: defaultName,
        note: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      <SubmitSuggestionModalWithContext
        {...props}
        error={error}
        onClose={resetAndClose}
      />
    </Formik>
  );
}

gql`
  mutation submitOpeningSuggestion($input: SubmitOpeningSuggestionInput!) {
    submitOpeningSuggestion(input: $input) {
      suggestion {
        ...OpeningSuggestion
      }
    }
  }
`;

type FormValues = {
  name: string;
  note: string;
};

type SubmitSuggestionModalWithContextProps = SubmitSuggestionModalProps & {
  error: ApolloError;
};

function SubmitSuggestionModalWithContext({
  suggestion,
  onClose,
  open,
  error,
}: SubmitSuggestionModalWithContextProps) {
  const [noteOpen, setNoteOpen] = useState(false);
  const {
    handleChange,
    values,
    errors,
    submitForm,
    isSubmitting,
    resetForm,
  } = useFormikContext<FormValues>();

  function close() {
    resetForm();
    onClose();
  }

  return (
    <MapmakerModal
      className="submit-suggestion-modal"
      open={open}
      onClose={close}
      title="Submit Your Suggestion"
      size="tiny"
    >
      <ModalContent>
        <div className="unfinished-form">
          <div className="preview">
            <OpeningSuggestionThumbnail
              width={260}
              height={260}
              openingSuggestion={suggestion}
            />
          </div>
          <p>
            After you submit this suggestion the project owner will review it
            and decide whether to include it in their project.
          </p>
          <SemanticForm id="submit-suggestion-form" onSubmit={submitForm}>
            <FormField>
              <label>Your Name</label>
              <input
                name="name"
                type="name"
                onChange={handleChange}
                value={values.name}
                tabIndex={1}
              />
              <div className="error">{errors.name}</div>
            </FormField>
            {!noteOpen && !values.note ? (
              <span className="add-note" onClick={() => setNoteOpen(true)}>
                <HiPlus style={{ marginBottom: "-2px", marginRight: "2px" }} />
                Include a note
              </span>
            ) : (
              <>
                <label className="textarea-label" htmlFor="note">
                  Include a Note <span className="subtle">optional</span>
                </label>
                <TextArea
                  name="note"
                  placeholder="Photos from our 2022 road trip!"
                  onChange={handleChange}
                  rows={6}
                  tabIndex={2}
                  defaultValue={values.note}
                  onBlur={e => setNoteOpen(e.target.value != "")}
                />
              </>
            )}
          </SemanticForm>
          {error && (
            <div className="error">
              <FaExclamationTriangle size="24px" />
              <p>{error.message}</p>
            </div>
          )}
        </div>
      </ModalContent>
      <ModalActions>
        <Button onClick={close} tabIndex={4} disabled={isSubmitting}>
          Cancel
        </Button>{" "}
        <Button
          /* ID for GTM tracking. Do not change. */
          id="submit-suggestion-button"
          color="accent"
          type="submit"
          form="submit-suggestion-form"
          tabIndex={3}
          loading={isSubmitting}
        >
          Submit <MdSend />
        </Button>
      </ModalActions>
    </MapmakerModal>
  );
}
