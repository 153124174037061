import { SvgNode } from "@mapmaker/svg";
import clsx from "clsx";
import EditableOpeningOverlay from "./EditableOpeningOverlay";
import EditableOpeningImage from "./EditableOpeningImage";
import { SVGNode } from "@mapmaker/core";
import useEditableOpening from "./useEditableOpening";
import { useMemo } from "react";
import "./EditableOpening.css";

function useDarkMode() {
  return useMemo(
    () =>
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches,
    []
  );
}

export default function EditableOpening() {
  const {
    openingFeature,
    openingInput,
    highlightedImageId,
  } = useEditableOpening();
  const hasImages = openingInput.images.length > 0;
  const cutLayer: SVGNode =
    openingFeature.layout.cut ?? openingFeature.layout.inner;
  const printLayer: SVGNode =
    openingFeature.layout.print ??
    openingFeature.layout.outer ??
    openingFeature.layout.cut;
  const darkMode = useDarkMode();

  return (
    <g
      id={openingFeature.id}
      className={clsx("editable-opening", {
        "is-outlined": openingFeature.outlined,
        "has-image": hasImages,
        "no-image": !hasImages,
        "has-own-cut-layer": !!openingFeature.layout.cut,
      })}
    >
      {(openingInput.background || darkMode) && !highlightedImageId ? (
        <SvgNode
          style={{
            fill: openingInput.background ?? "#FFFFFF",
            colorScheme: "only light",
          }}
          nodeData={printLayer}
        />
      ) : null}
      {openingInput.images.map(image => {
        return <EditableOpeningImage key={image.id} image={image} />;
      })}
      <EditableOpeningOverlay
        layer={cutLayer}
        layer2={printLayer}
        fill={hasImages ? undefined : "#EEEEEE"}
        stroke={hasImages ? undefined : "#CCCCCC"}
      />
      <SvgNode className="outline-a" nodeData={cutLayer} />
      <SvgNode className="outline-b" nodeData={cutLayer} />
    </g>
  );
}
