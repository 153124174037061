import { OpeningImageInput, OpeningInput } from "@mapmaker/core";
import { useMemo } from "react";
import { OpeningSuggestionFragment } from "../../../../../client/MapmakerApi";
import { MAX_IMAGES_PER_OPENING } from "../editable/editableOpeningReducer";
import useOpening from "../useOpening";

export type SuggestionInclusionStatus =
  | "none-in-map"
  | "all-in-map-exact"
  | "all-in-map-modified"
  | "partial-in-map";
export type SuggestionAcceptanceStrategy = "add" | "overwrite" | "merge";
export type SuggestionAcceptanceStrategiesAvailable = Record<
  SuggestionAcceptanceStrategy,
  boolean
>;

type SuggestionAcceptance = {
  inclusionStatus: SuggestionInclusionStatus;
  strategiesAvailable: SuggestionAcceptanceStrategiesAvailable;
  totalImagesIfMerged: number;
};

export default function useSuggestionAcceptance(
  suggestion: OpeningSuggestionFragment
): SuggestionAcceptance {
  const { openingInput } = useOpening(suggestion.openingId);
  return useMemo(() => getSuggestionAcceptance(suggestion, openingInput), [
    suggestion,
    openingInput,
  ]);
}

enum ImageSameness {
  NONE = 0,
  MODIFIED = 1,
  FULL = 2,
}

export function getSuggestionAcceptance(
  suggestion: OpeningSuggestionFragment,
  openingInput: OpeningInput
): SuggestionAcceptance {
  if (!openingInput) {
    return {
      inclusionStatus: "none-in-map",
      strategiesAvailable: {
        add: true,
        merge: false,
        overwrite: false,
      },
      totalImagesIfMerged: suggestion.input.images.length,
    };
  }
  const totalImagesIfMerged =
    openingInput?.images.length ?? 0 + suggestion.input.images.length;
  const [minSameness, maxSameness] = getSuggestionSamenessRange(
    suggestion,
    openingInput
  );
  const inclusionStatus = getInclusionStatus(minSameness, maxSameness);

  const strategiesAvailable = getAvailableStrategies(
    suggestion,
    openingInput,
    inclusionStatus
  );

  return {
    inclusionStatus,
    strategiesAvailable,
    totalImagesIfMerged,
  };
}

function getSuggestionSamenessRange(
  suggestion: OpeningSuggestionFragment,
  openingInput: OpeningInput
) {
  if (suggestion.input.images.length === 0) {
    return [ImageSameness.NONE, ImageSameness.NONE];
  }
  const samenesses = suggestion.input.images.map(suggestionImage =>
    getMaxSameness(suggestionImage, openingInput.images)
  );
  return getSamenessRange(samenesses);
}

function getInclusionStatus(
  minSameness: ImageSameness,
  maxSameness: ImageSameness
): SuggestionInclusionStatus {
  if (maxSameness === ImageSameness.NONE) {
    return "none-in-map";
  } else if (minSameness === ImageSameness.FULL) {
    return "all-in-map-exact";
  } else if (minSameness === ImageSameness.NONE) {
    return "partial-in-map";
  } else {
    return "all-in-map-modified";
  }
}

function getAvailableStrategies(
  suggestion: OpeningSuggestionFragment,
  openingInput: OpeningInput,
  inclusionStatus: SuggestionInclusionStatus
): SuggestionAcceptanceStrategiesAvailable {
  const totalImagesIfMerged =
    openingInput.images.length + suggestion.input.images.length;
  const numImagesInProject = openingInput.images.length;
  const numImagesInSuggestion = suggestion.input.images.length;
  return {
    add: numImagesInProject === 0,
    overwrite: numImagesInProject > 0 && inclusionStatus !== "all-in-map-exact",
    merge:
      numImagesInProject > 0 &&
      numImagesInSuggestion > 0 &&
      totalImagesIfMerged <= MAX_IMAGES_PER_OPENING &&
      inclusionStatus !== "all-in-map-exact" &&
      inclusionStatus !== "all-in-map-modified",
  };
}

function getImageSameness(
  a: OpeningImageInput,
  b: OpeningImageInput
): ImageSameness {
  if (a.id !== b.id) {
    return ImageSameness.NONE;
  } else if (
    a.flipX !== b.flipX ||
    a.flipY !== b.flipY ||
    a.height !== b.height ||
    a.width !== b.width ||
    a.rotation !== b.rotation ||
    a.x !== b.x ||
    a.y !== b.y
  ) {
    return ImageSameness.MODIFIED;
  } else {
    return ImageSameness.FULL;
  }
}

// This assumes every image in the provided group has a unique ID. True for now...
function getMaxSameness(
  test: OpeningImageInput,
  group: OpeningImageInput[]
): ImageSameness {
  for (let groupImage of group) {
    const sameness = getImageSameness(test, groupImage);
    if (sameness !== ImageSameness.NONE) {
      return sameness;
    }
  }
  return ImageSameness.NONE;
}

function getSamenessRange(
  samenesses: ImageSameness[]
): [ImageSameness, ImageSameness] {
  return [Math.min(...samenesses), Math.max(...samenesses)];
}
