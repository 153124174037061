import { Route, Routes } from "react-router-dom";
import OpeningSuggestionReviewView from "../features/opening/suggestions/OpeningSuggestionReviewView";
import OwnerSharingView from "./OwnerSharingView";
import OpeningSuggestionEditorView from "../features/opening/suggestions/OpeningSuggestionEditorView";
import CollaboratorSharingView from "./CollaboratorSharingView";
import { useMapmakerAppConfig } from "../../../client";
import { MapmakerFileViewerPolicy } from "../../../client/MapmakerApi";
import { useProjectFile } from "../useProjectState";

export default function CollaborateRouter() {
  const { gotoEditFile } = useMapmakerAppConfig();
  const file = useProjectFile();

  // Only owners can view things here.
  switch (file.viewerPolicy) {
    case MapmakerFileViewerPolicy.Owner:
      return (
        <Routes>
          <Route
            path=":openingId/:suggestionId"
            element={<OpeningSuggestionReviewView />}
          />
          <Route path=":openingId" element={<OwnerSharingView />} />
          <Route path="*" element={<OwnerSharingView />} />
        </Routes>
      );
    case MapmakerFileViewerPolicy.Collaborator:
      return (
        <Routes>
          <Route
            path=":openingId/:suggestionId"
            element={<OpeningSuggestionEditorView />}
          />
          <Route path=":openingId" element={<CollaboratorSharingView />} />
          <Route path="*" element={<CollaboratorSharingView />} />
        </Routes>
      );
    default:
      gotoEditFile(file.id);
      return null;
  }
}
