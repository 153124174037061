import { gql } from "@apollo/client";
import { Icon } from "semantic-ui-react";
import {
  StorefrontAutomaticDiscountApplication,
  StorefrontDiscountApplicationFragment,
  StorefrontDiscountCodeApplication,
  StorefrontMoneyV2,
  StorefrontPricingPercentageValue,
  StorefrontPricingValue,
} from "../../client/MapmakerApi";
import MoneyV2 from "../shared/storefront/MoneyV2";
import "./DiscountApplicationIndicator.css";

gql`
  fragment StorefrontDiscountApplication on StorefrontDiscountApplication {
    targetSelection
    targetType
    allocationMethod
    value {
      ... on StorefrontMoneyV2 {
        amount
        currencyCode
      }
      ... on StorefrontPricingPercentageValue {
        percentage
      }
    }
    ... on StorefrontAutomaticDiscountApplication {
      title
    }
    ... on StorefrontDiscountCodeApplication {
      code
      applicable
    }
  }
`;

interface IDiscountApplicationIndicatorProps {
  discountApplication: StorefrontDiscountApplicationFragment;
}

export function discountApplicationApplies(
  discountApplication: StorefrontDiscountApplicationFragment
): boolean {
  const typename = discountApplication.__typename;
  switch (typename) {
    case "StorefrontDiscountCodeApplication":
      return discountApplication.applicable;
    default:
      return true;
  }
}

export default function DiscountApplicationIndicator({
  discountApplication,
}: IDiscountApplicationIndicatorProps) {
  if (!discountApplicationApplies(discountApplication)) {
    return null;
  }
  return (
    <span className="discount-application-indicator">
      <span className="label">
        <Icon name="tag" />
        <DiscountApplicationText
          discountApplication={discountApplication}
        />{" "}
        <PricingValueText pricingValue={discountApplication.value} />
      </span>
    </span>
  );
}

interface IPricingValueTextProps {
  pricingValue: StorefrontPricingValue;
}

function PricingValueText({ pricingValue }: IPricingValueTextProps) {
  //@ts-ignore
  const typename = pricingValue.__typename as PossiblePricingValueTypeNames;
  switch (typename) {
    case "MoneyV2":
      return (
        <>
          <MoneyV2 moneyV2={pricingValue as StorefrontMoneyV2} /> off
        </>
      );
    case "PricingPercentageValue":
      const percentageValue = pricingValue as StorefrontPricingPercentageValue;
      return <>{percentageValue.percentage}% off</>;
    default:
      return null;
  }
}

interface IDiscountApplicationTextProps {
  discountApplication: StorefrontDiscountApplicationFragment;
}

function DiscountApplicationText({
  discountApplication,
}: IDiscountApplicationTextProps) {
  const typename = discountApplication.__typename;
  switch (typename) {
    case "StorefrontAutomaticDiscountApplication":
      return (
        <>
          {
            (discountApplication as StorefrontAutomaticDiscountApplication)
              .title
          }
        </>
      );
    case "StorefrontDiscountCodeApplication":
      const discountCode = discountApplication as StorefrontDiscountCodeApplication;
      return <>{discountCode.code}</>;
    case "StorefrontManualDiscountApplication":
      return <>Manual Discount</>;
    case "StorefrontScriptDiscountApplication":
      return <>Automatic Discount</>;
    default:
      return null;
  }
}
