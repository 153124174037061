import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";
import {
  OpeningFeature,
  OpeningInput,
  SVGNode,
  isMatboardOuterOpening as isMatboardOuterOpeningTest,
} from "@mapmaker/core";
import { getLayerByPriority } from "./openingUtils";

export type OpeningGroupContextValue = {
  /* The extents of what will be seen for this opening when the map is assembled. */
  outermostVisibleLayer: SVGNode;
  /* The extents that the images will be clipped to, without showing any buffer. */
  imageClipLayerWithoutBuffer: SVGNode;
  /* Whether the opening should be shown on the full map view. */
  showOnFullMap: boolean;
  /* Whether the opening is an outer opening which we use to draw the outer border on matboard maps.
   */
  isMatboardOuterOpening: boolean;
  scale: number;
};

const OpeningGroupContext = createContext<OpeningGroupContextValue>({
  outermostVisibleLayer: null,
  imageClipLayerWithoutBuffer: null,
  showOnFullMap: true,
  isMatboardOuterOpening: true,
  scale: 1,
});

export type OpeningGroupContextProviderProps = {
  opening: OpeningFeature;
  openingInput?: OpeningInput;
  scale?: number;
};

export function OpeningGroupContextProvider({
  opening,
  openingInput,
  scale = 1,
  children,
}: PropsWithChildren<OpeningGroupContextProviderProps>) {
  const outermostVisibleLayer = useMemo(() => {
    return opening.outlined
      ? opening.layout.cut
      : getLayerByPriority(opening, ["inner", "cut"]);
  }, [opening]);
  const imageClipLayerWithoutBuffer = useMemo(() => {
    return opening.outlined
      ? opening.layout.print
      : getLayerByPriority(opening, ["cut", "inner"]);
  }, [opening]);

  const showOnFullMap = useMemo(() => {
    return true;
  }, [opening]);

  const isMatboardOuterOpening = useMemo(
    () => isMatboardOuterOpeningTest(opening),
    [opening]
  );

  return (
    <OpeningGroupContext.Provider
      value={{
        outermostVisibleLayer,
        imageClipLayerWithoutBuffer,
        showOnFullMap,
        isMatboardOuterOpening,
        scale,
      }}
    >
      {children}
    </OpeningGroupContext.Provider>
  );
}

export function useOpeningGroupContext(): OpeningGroupContextValue {
  return useContext<OpeningGroupContextValue>(OpeningGroupContext);
}
