import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
export default function LinkButton(_a) {
    var { to } = _a, props = __rest(_a, ["to"]);
    const navigate = useNavigate();
    const onClick = useCallback(() => navigate(to), [to, navigate]);
    return _jsx(Button, Object.assign({}, props, { onClick: onClick }));
}
