import { ReactNode, SVGProps, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import deepEqual from "fast-deep-equal";
import { OpeningInput } from "@mapmaker/core";
import { viewBoxForBBox } from "../../../../../lib/svg/ViewboxUtils";
import { SvgContextProvider } from "../../../../../lib/svg/useSvgContext";
import OpeningImagesBar from "../OpeningImagesBar";
import OpeningToolbar from "../OpeningToolbar";
import useMapmakerModals from "../../../../shared/modals/useMapmakerModals";
import MapmakerProjectToastMessages from "../../../shared/toasts/MapmakerProjectToastMessages";
import MapmakerLayout from "../../../../layout/MapmakerLayout";
import OpeningViewLayout from "../OpeningViewLayout";
import EditableOpening from "../editable/EditableOpening";
import useEditableOpening from "../editable/useEditableOpening";
import {
  createEditableOpeningStore,
  EditableOpeningContext,
  useEditableOpeningDispatch,
} from "../editable/EditableOpeningStore";
import { Provider } from "react-redux";
import { OpeningEditorZoomLevels } from "./editableOpeningReducer";

type OpeningEditorViewProps = {
  openingId: string;
  initialOpeningInput: OpeningInput;
  topBar: ReactNode;
  mainAddons?: ReactNode;
  onChange(editedOpeningInput: OpeningInput): any;
};

export default function OpeningEditorView(props: OpeningEditorViewProps) {
  const { openingId, initialOpeningInput } = props;
  // Only update the store when the opening ID changes. Otherwise the store gets reset every time we
  // propagate opening input changes to the ProjectStore and they flow back to here.
  const store = useMemo(
    () => createEditableOpeningStore(openingId, initialOpeningInput),
    [openingId]
  );
  return (
    <Provider store={store} context={EditableOpeningContext}>
      <OpeningEditorViewWithContext {...props} />
    </Provider>
  );
}

function OpeningEditorViewWithContext({
  onChange,
  initialOpeningInput,
  topBar,
  mainAddons,
}: OpeningEditorViewProps) {
  const { setModalRoot } = useMapmakerModals();
  const dispatch = useEditableOpeningDispatch();
  const [lastSavedInput, setLastSavedInput] = useState<OpeningInput>(
    initialOpeningInput
  );
  const {
    openingFeature,
    openingInput,
    createAddImagesAction,
    zoomLevel,
  } = useEditableOpening();

  const bbox = openingFeature.outlined
    ? openingFeature.layout.cut.bbox
    : openingFeature.layout.print?.bbox ||
      openingFeature.layout.outer?.bbox ||
      openingFeature.layout.cut.bbox;
  const viewbox = viewBoxForBBox(bbox, OpeningEditorZoomLevels[zoomLevel]);

  const { getRootProps, isDragActive } = useDropzone({
    onDrop: (files: File[]) => {
      dispatch(createAddImagesAction(files));
    },
    noClick: true,
  });

  useEffect(() => {
    if (!deepEqual(lastSavedInput, openingInput)) {
      onChange(openingInput);
      setLastSavedInput(openingInput);
    }
  }, [openingInput]);

  return (
    <MapmakerLayout>
      <MapmakerLayout.TopBar>{topBar}</MapmakerLayout.TopBar>
      <MapmakerLayout.Main style={{ overflowY: "hidden" }}>
        <OpeningViewLayout>
          <OpeningImagesBar />
          <OpeningToolbar />
          <OpeningViewLayout.Main innerRef={setModalRoot}>
            <MapmakerProjectToastMessages />
            {mainAddons}
            <SvgContextProvider
              containerId="opening-editor-svg-container"
              viewbox={viewbox}
              flex
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                /* We have to start the viewbox at 0,0 to work with react-moveable. */
                viewBox={[0, 0, viewbox[2], viewbox[3]].join(" ")}
                width="100%"
                height="100%"
                // SVG not supported, but still works fine. Weird casting avoids typescript errors.
                {...((getRootProps({
                  className: clsx({
                    "file-dragging-over-element": isDragActive,
                  }),
                }) as unknown) as SVGProps<SVGSVGElement>)}
                style={{
                  // react-dropzone adds a tabIndex so this keep it from getting styled funny.
                  outline: "none",
                  // Prevents touch events from doing things like refreshing the browser.
                  touchAction: "none",
                }}
              >
                {/* Part 2 of viewbox hack for react-moveable. */}
                <g transform={`translate(${-viewbox[0]}, ${-viewbox[1]})`}>
                  <EditableOpening />
                </g>
              </svg>
            </SvgContextProvider>
          </OpeningViewLayout.Main>
        </OpeningViewLayout>
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}
