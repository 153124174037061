import { Suspense } from "react";
import MapmakerLayout from "../layout/MapmakerLayout";
import { SandboxContextProvider } from "./SandboxContext";
import SandboxDisplay from "./SandboxDisplay";
import SandboxPanel from "./SandboxPanel";
import LoadingPage from "../shared/LoadingPage";
import "./MapmakerSandbox.css";

type MapmakerSandboxProps = {
  basePath: string;
};

export default function MapmakerSandbox({ basePath }: MapmakerSandboxProps) {
  return (
    <SandboxContextProvider sandboxBasePath={basePath}>
      <MapmakerSandboxWithContext />
    </SandboxContextProvider>
  );
}

function MapmakerSandboxWithContext() {
  return (
    <MapmakerLayout className="mapmaker-sandbox">
      <MapmakerLayout.Main style={{ overflowY: "hidden" }}>
        <MapmakerLayout.ContextPanel>
          <SandboxPanel />
        </MapmakerLayout.ContextPanel>
        <MapmakerLayout.Content>
          <Suspense fallback={<LoadingPage />}>
            <SandboxDisplay />
          </Suspense>
        </MapmakerLayout.Content>
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}
