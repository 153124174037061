import React from "react";
import {
  MapmakerDesign,
  MapmakerFile,
  RectangularPrintLayoutInputWithStickerOrderIds,
  RectangularPrintLayoutInputWithFiles,
} from "@mapmaker/core";
import { MapmakerSvgContextValue } from "../MapmakerSvgContext";
import OpeningsStickerSheetStickersSvg from "./OpeningStickerSheetStickersSvg";

/*
 * Renders an SVG which is suitable for printing a photo which has 1 or more images that when cut
 * out will fit into the opening of a design.
 */
export interface IOpeningStickerSheetProps {
  sheetIndex: number;
  totalSheets: number;
  primaryColor?: string;
  primaryColorSingle?: string;
  secondaryColor?: string;
  secondaryColorSingle?: string;
  primaryText?: string;
  secondaryText?: string;
  identifierText?: string;
  barcodeData?: string;
  design: Pick<MapmakerDesign, "width" | "height" | "features" | "businessId">;
  printLayout: RectangularPrintLayoutInputWithStickerOrderIds;
  stickerOrderFileMap: { id: string; file: Pick<MapmakerFile, "inputs"> }[];
  dpiScaleFactor?: number;
  onImageLoadError?: MapmakerSvgContextValue["onImageLoadError"];
}

// These values must match the value PDF is created with. If this changes, change the respective
// value in stickerSheetUtils.ts.
const SheetInfoHeight = (1 / 2) * 72;
const SheetBleed = 0.06 * 72;

function OpeningStickerSheet({
  design,
  printLayout,
  sheetIndex: sheetNumber,
  primaryText,
  secondaryText,
  identifierText,
  stickerOrderFileMap,
  totalSheets,
  primaryColor,
  primaryColorSingle = primaryColor,
  secondaryColor,
  secondaryColorSingle = secondaryColor,
  barcodeData,
  dpiScaleFactor = 1,
  onImageLoadError,
}: IOpeningStickerSheetProps) {
  const printLayoutWithFiles: RectangularPrintLayoutInputWithFiles = {
    ...printLayout,
    printOpenings: printLayout.printOpenings.map((printOpening) => {
      const file = stickerOrderFileMap.find(
        (order) => order.id === printOpening.stickerOrderId
      )?.file;
      if (!file) {
        throw new Error(
          `Could not find file data for sticker order ID: ${printOpening.stickerOrderId}`
        );
      }
      return {
        ...printOpening,
        file,
      };
    }),
  };

  return (
    <OpeningsStickerSheetStickersSvg
      design={design}
      printLayout={printLayoutWithFiles}
      width={(printLayout.width + SheetBleed * 2) * dpiScaleFactor}
      height={
        (printLayout.height + SheetInfoHeight + SheetBleed * 2) * dpiScaleFactor
      }
      sheetInfoHeight={SheetInfoHeight}
      sheetBleed={SheetBleed}
      onImageLoadError={onImageLoadError}
      sheetNumber={sheetNumber}
      totalSheets={totalSheets}
      primaryColor={totalSheets === 1 ? primaryColorSingle : primaryColor}
      secondaryColor={totalSheets === 1 ? secondaryColorSingle : secondaryColor}
      primaryText={primaryText}
      secondaryText={secondaryText}
      identifierText={identifierText}
      barcodeData={barcodeData}
    />
  );
}

/**
 * Need this upstream to calculate nesting
 */
OpeningStickerSheet.SheetInfoHeight = SheetInfoHeight;
OpeningStickerSheet.SheetBleed = SheetBleed;

export default OpeningStickerSheet;
