import { MapmakerProjectStore, useProjectSelector } from "./projectStore";
import { useMemo } from "react";
import { getOpeningInputs } from "@mapmaker/core";
import {
  MapmakerDesignHeavyFragment,
  ProjectOutputOption,
} from "../../client/MapmakerApi";
import { getSelectedOutputOption } from "./shared/OutputTypeLibrary";
import { MapmakerFileState } from "./fileReducer";
import { Upload } from "./uploadsReducer";

type EnhancedMapmakerProjectStore = MapmakerProjectStore & {
  numOpeningsWithImages: number;
  selectedProjectOutput?: ProjectOutputOption;
};

export default function useProjectState(): EnhancedMapmakerProjectStore {
  const projectState = useProjectSelector((state: MapmakerProjectStore) => {
    return state;
  });
  const { file } = projectState;

  const numOpeningsWithImages = useMemo(() => {
    const openingInputs = getOpeningInputs(file);
    return openingInputs.reduce(
      (count, openingInput) => count + openingInput.images.length,
      0
    );
  }, [file]);

  const selectedProjectOutput = useMemo(() => {
    return getSelectedOutputOption(
      file.outputType,
      file.outputScale,
      file.design.outputOptions
    );
  }, [file.outputScale, file.outputType, file.design]);

  return {
    ...projectState,
    numOpeningsWithImages,
    selectedProjectOutput,
  };
}

export function useProjectDesign(): MapmakerDesignHeavyFragment {
  return useProjectSelector((state: MapmakerProjectStore) => state.file.design);
}

export function useProjectFile(): MapmakerFileState {
  return useProjectSelector((state: MapmakerProjectStore) => state.file);
}

export function useProjectUploads() {
  const { uploads } = useProjectState();
  return useMemo(() => {
    const inProgressUploads = uploads.filter(
      upload => upload.status === "IN_PROGRESS"
    );
    const failedUploads = uploads.filter(upload => upload.status === "FAILED");
    const completedUploads = uploads.filter(
      upload => upload.status === "COMPLETED"
    );
    return {
      uploads,
      inProgressUploads,
      failedUploads,
      completedUploads,
      uploading: inProgressUploads.length > 0,
    };
  }, [uploads]);
}

export function useProjectUpload(key: string): Upload {
  const { uploads } = useProjectState();
  return useMemo(
    () =>
      uploads.find(upload => upload.key === key) ?? {
        key,
        dataUrl: undefined,
        status: "COMPLETED",
      },
    [key, uploads]
  );
}
