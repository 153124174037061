import { useEffect } from "react";
import useBeforeUnload from "./useBeforeUnload";
import useInterval from "./useInterval";
import useLatest from "./useLatest";

type UseBackgroundCallbackOptions = {
  debug?: boolean;
  interval?: number;
};

/**
 * Calls a function fairly reliably
 */
export function useBackgroundCallback(
  callback: () => any,
  { debug = false, interval = 30 * 1000 }: UseBackgroundCallbackOptions = {}
) {
  const latestCallback = useLatest(callback);
  const callbackWithMessage = (reason: string) => {
    return () => {
      if (debug) {
        console.log(`BACKGROUND SAVE - ${reason}`);
      }
      latestCallback.current();
    };
  };

  // Save at regular intervals.
  useInterval(callbackWithMessage("Interval"), interval);
  // Save when this component unloads.
  useEffect(() => callbackWithMessage("Component Unmounting"), []);
  // Save before the page is closed.
  useBeforeUnload(callbackWithMessage("Window Unloading"));
}
