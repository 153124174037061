import {
  MapmakerDesign,
  MapmakerFile,
  OpeningFeature,
  OpeningImageInput,
  OpeningImageTransform,
  OpeningInput,
  SVGNode,
  TimezoneIndependentDate,
} from "../types";
import { deepEqual } from "./helpers";
import { getFeaturesList } from "./MapmakerDesignUtils";
import { FeatureAndInput, getFeaturesAndInputList } from "./MapmakerFileUtils";

export function getOpeningList(
  design: Pick<MapmakerDesign, "features">
): OpeningFeature[] {
  return getFeaturesList<OpeningFeature>(design, "OPENING");
}

export type OpeningDateRange = {
  first: TimezoneIndependentDate;
  last: TimezoneIndependentDate;
  isSameDay: boolean;
  accuracy: OpeningImageDateAccuracy;
};

export type OpeningImageDateAccuracy = "ACCURATE" | "INACCURATE" | "MISSING";

export type OpeningInputFilters = {
  mustHaveInput?: boolean;
  mustHaveImages?: boolean;
  mustBeEnabled?: boolean;
  mustCountTowardTotal?: boolean;
  mustCountTowardProgress?: boolean;
};

export type GetOpeningsWithInputsOptions = OpeningInputFilters & {
  sort?: "name" | "timeline";
};

export function openingInputsEqual(a: OpeningInput, b: OpeningInput): boolean {
  return (
    a.background === b.background &&
    a.enabled === b.enabled &&
    a.modifiedAt === b.modifiedAt &&
    a.images.length === b.images.length &&
    a.images.every((imageA, i) => openingImageInputsEqual(imageA, b.images[i]))
  );
}

export function openingImageInputsEqual(
  a: OpeningImageInput,
  b: OpeningImageInput
): boolean {
  return deepEqual(a, b);
}

export function getOpeningsWithInputs(
  design: Pick<MapmakerDesign, "features">,
  file: Pick<MapmakerFile, "inputs">,
  {
    mustHaveInput = true,
    mustHaveImages = true,
    mustBeEnabled = true,
    mustCountTowardTotal = false,
    mustCountTowardProgress = false,
    sort = "name",
  }: GetOpeningsWithInputsOptions = {}
): FeatureAndInput<OpeningFeature, OpeningInput>[] {
  const unsorted = getFeaturesAndInputList<OpeningFeature, OpeningInput>(
    design,
    file,
    "OPENING",
    (opening, input?: OpeningInput) =>
      filterOpeningInput(opening, input, {
        mustHaveInput,
        mustHaveImages,
        mustBeEnabled,
        mustCountTowardTotal,
        mustCountTowardProgress,
      })
  );
  switch (sort) {
    case "name":
      return [...unsorted].sort((a, b) =>
        a.feature.name < b.feature.name ? -1 : 1
      );
    case "timeline":
      return [...unsorted].sort((a, b) => {
        if (!a.input) {
          return b.input ? -1 : 0;
        }
        if (!b.input) {
          return 1;
        }
        return compareOpeningsByDateRange(
          { ...a.input, id: a.featureId },
          { ...b.input, id: b.featureId }
        );
      });
  }
}

export function filterOpeningInput(
  opening: OpeningFeature | undefined,
  input: OpeningInput,
  {
    mustHaveInput = false,
    mustHaveImages = false,
    mustBeEnabled = false,
    mustCountTowardTotal = false,
    mustCountTowardProgress = false,
  }: OpeningInputFilters
): boolean {
  if (
    opening === undefined &&
    (mustBeEnabled || mustCountTowardProgress || mustCountTowardTotal)
  ) {
    throw new Error(
      "Opening feature must be provided if mustBeEnabled, mustCountTowardProgress, or mustCountTowardTotal are true."
    );
  }
  if (mustHaveInput && !input) {
    return false;
  } else if (mustHaveImages && !input.images?.length) {
    return false;
  } else if (
    (mustBeEnabled && input?.enabled === false) ||
    (opening.enabled === false && input?.enabled !== true)
  ) {
    return false;
  } else if (mustCountTowardTotal && !openingCountsTowardsTotal(opening)) {
    return false;
  } else if (
    mustCountTowardProgress &&
    !openingCountsTowardsProgress(opening, input)
  ) {
    return false;
  } else {
    return true;
  }
}

export function compareOpeningsByDateRange(
  a: Pick<OpeningInput, "images" | "modifiedAt"> & { id: string },
  b: Pick<OpeningInput, "images" | "modifiedAt"> & { id: string }
): number {
  const rangeA = getOpeningImagesDateRange(a);
  const rangeB = getOpeningImagesDateRange(b);
  const compareFirst = compareTimezoneIndependentDate(
    rangeA.first,
    rangeB.first
  );

  if (compareFirst !== 0) {
    return compareFirst;
  } else {
    const compareLast = compareTimezoneIndependentDate(
      rangeA.last,
      rangeB.last
    );
    if (compareLast !== 0) {
      return compareLast;
    } else {
      /* This will at least give us a stable sort. Maybe we add a sort order in the future to break
      ties between days? */
      if (a.modifiedAt < b.modifiedAt) {
        return -1;
      } else if (a.modifiedAt > b.modifiedAt) {
        return 1;
      } else {
        return a.id < b.id ? -1 : 1;
      }
    }
  }
}

/**
 * Gets the date range of the images on an opening input. The strategy is this:
 *  1. Determine the maximum accuracy of any image in the group.
 *  2. Using only the images that match the maximum accuracy, return the first and last dates.
 */
export function getOpeningImagesDateRange(
  openingInput: Pick<OpeningInput, "images" | "modifiedAt">
): OpeningDateRange {
  const now = getTimezoneIndependentDate();
  const relevantAccuracy = maxAccuracy(
    ...openingInput.images.map(getOpeningImageDateAccuracy)
  );

  const { first = now, last = now } =
    openingInput.images
      ?.filter(
        (image) => getOpeningImageDateAccuracy(image) === relevantAccuracy
      )
      .reduce<{
        first?: TimezoneIndependentDate;
        last?: TimezoneIndependentDate;
      }>(
        (range, image) => {
          const imageDate = getOpeningImageDate(
            image,
            getTimezoneIndependentDate(new Date(openingInput.modifiedAt))
          );
          if (!range.first) {
            range.first = imageDate;
          } else if (imageDate < range.first) {
            range.first = imageDate;
          }
          if (!range.last) {
            range.last = imageDate;
          } else if (imageDate > range.last) {
            range.last = imageDate;
          }
          return range;
        },
        { first: undefined, last: undefined }
      ) ?? {};
  const isSameDay = isSameDate(first, last);

  return {
    first,
    last,
    isSameDay,
    accuracy: relevantAccuracy,
  };
}

/**
 * Returns a timezone independent date based on the most accurate date available for the opening
 * image.
 */
export function getOpeningImageDate(
  image: OpeningImageInput,
  defaultValue: TimezoneIndependentDate = getTimezoneIndependentDate()
): TimezoneIndependentDate {
  return (
    image.timeTakenUser ??
    (image.timeTakenExif &&
      getTimezoneIndependentDate(new Date(image.timeTakenExif))) ??
    (image.createdTime &&
      getTimezoneIndependentDate(new Date(image.createdTime))) ??
    defaultValue
  );
}

export function getTimezoneIndependentDate(
  date: Date = new Date()
): TimezoneIndependentDate {
  if (!date) {
    return undefined;
  } else {
    return [date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()];
  }
}

function compareTimezoneIndependentDate(
  a: TimezoneIndependentDate,
  b: TimezoneIndependentDate
): number {
  if (a[0] < b[0]) return -1;
  if (a[0] > b[0]) return 1;
  if (a[1] < b[1]) return -1;
  if (a[1] > b[1]) return 1;
  if (a[2] < b[2]) return -1;
  if (a[2] > b[2]) return 1;
  return 0;
}

function isSameDate(
  a: TimezoneIndependentDate,
  b: TimezoneIndependentDate
): boolean {
  return a[0] === b[0] && a[1] === b[1] && a[2] === b[2];
}

/**
 * Determine how accurate the image date is.
 */
export function getOpeningImageDateAccuracy(
  image: OpeningImageInput
): OpeningImageDateAccuracy {
  if (image.timeTakenUser || image.timeTakenExif) {
    return "ACCURATE";
  } else if (image.createdTime) {
    return "INACCURATE";
  } else {
    return "MISSING";
  }
}

function maxAccuracy(
  ...accuracies: OpeningImageDateAccuracy[]
): OpeningImageDateAccuracy {
  let max: OpeningImageDateAccuracy = "MISSING";
  for (var accuracy of accuracies) {
    if (accuracy === "ACCURATE") {
      return "ACCURATE";
    } else if (accuracy === "INACCURATE") {
      max = accuracy;
    }
  }
  return max;
}

// Determines the correct number of regions to display as the total for completing this map. For
// instance, the USA map should say 50 states - even if they have optional openings enabled.
export function getOpeningCount(
  design: Pick<MapmakerDesign, "features">
): number {
  return getOpeningList(design).reduce(
    (count, opening) => count + (openingCountsTowardsTotal(opening) ? 1 : 0),
    0
  );
}

export function getOpeningProgress(
  design: Pick<MapmakerDesign, "features">,
  file: Pick<MapmakerFile, "inputs">
): number {
  return getOpeningsWithInputs(design, file).reduce(
    (count, opening) =>
      count +
      (openingCountsTowardsProgress(opening.feature, opening.input) ? 1 : 0),
    0
  );
}

export function openingCountsTowardsTotal(opening: OpeningFeature): boolean {
  if (opening.counts !== undefined) {
    // Explicitly labelled openings always use that value.
    return opening.counts;
  } else if (opening.enabled === false) {
    return false;
  } else if (isMatboardOuterOpening(opening)) {
    return false;
  } else {
    return true;
  }
}

/**
 * Whether an opening
 */
export function openingCountsTowardsProgress(
  opening: OpeningFeature,
  openingInput?: OpeningInput
): boolean {
  if (!openingInput) {
    return false;
  }
  const countsTowardTotal = openingCountsTowardsTotal(opening);
  if (!countsTowardTotal) {
    return false;
  } else {
    return openingInput.images.length > 0;
  }
}

/**
 * A quirk of how TBL maps work, the outer border is included in the design as an opening.
 */
export function isMatboardOuterOpening(
  opening: Pick<OpeningFeature, "layout">
): boolean {
  return !opening.layout.inner && !opening.layout.cut;
}

/**
 * Returns the default position of an opening image.
 */
type SizingStrategy = "contain" | "cover";
export function getDefaultImageTransform(
  printLayer: SVGNode,
  image: Pick<OpeningImageInput, "imageWidth" | "imageHeight">
): OpeningImageTransform {
  const bbox = printLayer.bbox;
  const openingAspect = bbox.width / bbox.height;
  const imageAspect = image.imageWidth / image.imageHeight;
  // When the image and target area are close in size we use a "cover" strategy, when they are far
  // away we use a "contain" strategy to avoid super large default sizes.
  const closeAspects =
    Math.max(imageAspect, openingAspect) /
      Math.min(imageAspect, openingAspect) <
    1.5;
  const strategy: SizingStrategy = closeAspects ? "cover" : "contain";
  const matchWidth =
    strategy === "contain"
      ? imageAspect >= openingAspect
      : imageAspect <= openingAspect;
  const width = matchWidth ? bbox.width : bbox.height * imageAspect;
  const height = width / imageAspect;
  return {
    x: matchWidth ? bbox.x : bbox.x - (width - bbox.width) / 2,
    y: matchWidth ? bbox.y - (height - bbox.height) / 2 : bbox.y,
    width,
    height,
    rotation: 0,
  };
}
