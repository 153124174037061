import { gql } from "@apollo/client";
import {
  StorefrontDiscountAllocationFragment,
  StorefrontMoneyV2,
} from "../../client/MapmakerApi";
import {
  addMoneyV2,
  EmptyMoneyV2,
  moneyV2Equals,
  subtractMoneyV2,
} from "../../lib/storefront/moneyUtils";
import MoneyV2 from "../shared/storefront/MoneyV2";
import DiscountApplicationIndicator from "./DiscountApplicationIndicator";

gql`
  fragment StorefrontDiscountAllocation on StorefrontDiscountAllocation {
    allocatedAmount {
      amount
      currencyCode
    }
    discountApplication {
      ...StorefrontDiscountApplication
    }
  }
`;

interface IPriceWithDiscountProps {
  price: StorefrontMoneyV2;
  discountAllocations: StorefrontDiscountAllocationFragment[];
}

export default function PriceWithDiscount({
  price,
  discountAllocations = [],
}: IPriceWithDiscountProps) {
  const totalDiscounts = discountAllocations.reduce(
    (sum, discount) => addMoneyV2(sum, discount.allocatedAmount),
    EmptyMoneyV2
  );
  const discountedPrice = subtractMoneyV2(price, totalDiscounts);
  if (
    discountAllocations.length === 0 ||
    moneyV2Equals(price, discountedPrice)
  ) {
    return (
      <span className="total-price">
        <MoneyV2 moneyV2={price} />
      </span>
    );
  } else {
    // There are discounts
    return (
      <>
        <span className="discounts">
          {discountAllocations.map((discountAllocation, i) => (
            <DiscountApplicationIndicator
              key={i}
              discountApplication={discountAllocation.discountApplication}
            />
          ))}
        </span>{" "}
        <span className="total-price has-discounts">
          <MoneyV2 moneyV2={price} />
        </span>
        <span className="discounted-price">
          {parseFloat(discountedPrice.amount) === 0 ? (
            <>FREE!</>
          ) : (
            <MoneyV2 moneyV2={discountedPrice} />
          )}
        </span>
      </>
    );
  }
}
