import { ReactNode, useState } from "react";
import clsx from "clsx";
import { getOpeningImagesDateRange } from "@mapmaker/core";
import { OpeningStickerPreviewSvg } from "@mapmaker/svg";
import { useProjectFile } from "../useProjectState";
import TimelineEditorOpeningModal from "./TimelineEditorOpeningModal";
import useOpening from "../features/opening/useOpening";
import format from "date-fns/format";
import "./TimelineEditorOpeningCard.css";

type TimelineEditorOpeningCardProps = {
  openingId: string;
  before?: ReactNode;
  gridColumnStart?: number;
};

export default function TimelineEditorOpeningCard({
  openingId,
  before,
  gridColumnStart,
}: TimelineEditorOpeningCardProps) {
  const { openingFeature: opening, openingInput } = useOpening(openingId);
  const file = useProjectFile();
  const [open, setOpen] = useState(false);
  const range = getOpeningImagesDateRange(openingInput);

  return (
    <>
      <TimelineEditorOpeningModal
        open={open}
        openingId={opening.id}
        onClose={() => setOpen(false)}
      />
      <div
        id="timeline-editor-opening-card"
        className={range.accuracy}
        style={{
          gridColumnStart,
        }}
      >
        {before}
        <div className="card" onClick={() => setOpen(true)}>
          <h3 className="name">{opening.name}</h3>
          <div>
            <OpeningStickerPreviewSvg
              file={file}
              openingId={opening.id}
              width={150}
              height={150}
              dropShadow={false}
              padding={0}
              backgroundColor="transparent"
            />
          </div>
          <div className={clsx("date", range.accuracy)}>
            {format(new Date(...range.first), "PPP")}
          </div>
        </div>
      </div>
    </>
  );
}
