import { Button, IButtonProps } from "@mapmaker/ui";
import { MouseEvent, useState } from "react";
import { MdSend } from "@react-icons/all-files/md/MdSend";
import { OpeningSuggestionFragment } from "../../../../../client/MapmakerApi";
import SubmitSuggestionModal from "./SubmitSuggestionModal";

type SubmitSuggestionButtonProps = {
  suggestion: OpeningSuggestionFragment;
} & IButtonProps;

export default function SubmitSuggestionButton({
  suggestion,
  ...buttonProps
}: SubmitSuggestionButtonProps) {
  const [submitSuggestionModalOpen, setSubmitSuggestionModalOpen] = useState(
    false
  );
  async function onClick(e: MouseEvent<HTMLButtonElement>) {
    //e.stopPropagation();
    buttonProps.onClick && (await buttonProps.onClick(e));
    setSubmitSuggestionModalOpen(true);
  }

  return (
    <>
      <SubmitSuggestionModal
        suggestion={suggestion}
        onClose={() => setSubmitSuggestionModalOpen(false)}
        open={submitSuggestionModalOpen}
      />
      <Button {...buttonProps} onClick={onClick}>
        <MdSend /> Submit
      </Button>
    </>
  );
}
