import React, { useCallback, useState } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import AuthBoxLayout from "./AuthBoxLayout";
import * as Yup from "yup";
import { useAuthContext } from "../AWSAuthContext";
import { AuthBoxState, useAuthBoxContext } from "./AuthBox";
import FieldErrorMessage from "./shared/FieldErrorMessage";
import FormErrorMessage from "./shared/FormErrorMessage";
import TextField from "./shared/TextField";
import { Button } from "@mapmaker/ui";

interface SignUpFormValues {
  email: string;
  password: string;
  acceptTerms: boolean;
}

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Enter your email"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Enter a password"),
  acceptTerms: Yup.boolean()
    .isTrue("You must accept the terms and conditions to create an account.")
    .required(),
});

export default function AuthBoxSignUp() {
  const { signUp } = useAuthContext();
  const {
    setAuthBoxState,
    authenticationDetails,
    setAuthenticationDetails,
  } = useAuthBoxContext();
  const [signUpError, setSignUpError] = useState<string>();

  async function onSubmit(values: SignUpFormValues) {
    try {
      setSignUpError(undefined);
      setAuthenticationDetails({
        email: values.email,
        password: values.password,
      });
      await signUp(values.email, values.password);
      setAuthBoxState(AuthBoxState.VERIFY_EMAIL);
    } catch (e) {
      setSignUpError(e.message);
    }
  }

  return (
    <Formik
      initialValues={{
        email: authenticationDetails?.email || "",
        password: "",
        acceptTerms: false,
      }}
      validationSchema={SignUpSchema}
      onSubmit={onSubmit}
    >
      <AuthBoxSignUpForm signUpError={signUpError} />
    </Formik>
  );
}

function AuthBoxSignUpForm({ signUpError }) {
  const { termsAndConditionsUrl, setAuthBoxState } = useAuthBoxContext();
  const { isSubmitting, isValid, dirty } = useFormikContext<SignUpFormValues>();

  const gotoSignIn = useCallback(() => {
    setAuthBoxState(AuthBoxState.SIGN_IN);
  }, [setAuthBoxState]);

  const RegisterInstead = (
    <>
      <span className="link" onClick={gotoSignIn}>
        Log In
      </span>{" "}
      instead?
    </>
  );

  return (
    <AuthBoxLayout>
      <AuthBoxLayout.Header
        title="Create an Account"
        alternative={RegisterInstead}
      >
        Save your Map Maker projects permanently and access them from any device
        with a free account.
      </AuthBoxLayout.Header>
      <AuthBoxLayout.Form>
        <Form>
          <TextField
            name="email"
            label="Email"
            type="email"
            placeholder="joe@example.com"
            autoComplete="email"
          />
          <TextField
            name="password"
            label="Password"
            type="password"
            placeholder="choose a password"
            autoComplete="new-password"
          />
          <div className="field">
            <label htmlFor="acceptTerms">
              <Field name="acceptTerms" type="checkbox" />I agree to the{" "}
              <a href={termsAndConditionsUrl} target="_blank">
                terms and conditions
              </a>
            </label>
            <FieldErrorMessage name="acceptTerms" />
          </div>
          <div className="buttons">
            <Button
              color="accent"
              type="submit"
              fluid
              disabled={!dirty || !isValid}
              loading={isSubmitting}
            >
              Register
            </Button>
            <FormErrorMessage message={signUpError} />
          </div>
        </Form>
      </AuthBoxLayout.Form>
    </AuthBoxLayout>
  );
}
