import { createStore, combineReducers, applyMiddleware, Store } from "redux";
import thunk from "redux-thunk";
import {
  MapmakerDesignHeavyFragment,
  MapmakerFileHeavyFragment,
} from "../../../client/MapmakerApi";
import createPrintQuantitiesReducer from "../shared/printQuantitiesReducer";
import createPrintSizeSettingsReducer from "../shared/printSizeSettingsReducer";
import { MapmakerPrintServiceId } from "../PrintServiceIds";
import { getPrintingService } from "../PrintServices";
import { IRectangularPrintState } from "../shared/ReduxPrintStates";
import downloadOptionsReducer, {
  DownloadOptionsState,
} from "./downloadOptionsReducer";
import createPrintOptionsReducer from "../shared/printOptionsReducer";

// Types
export type DownloadPrintsStore = IRectangularPrintState & {
  downloadOptions: DownloadOptionsState;
};

const immutableReducer = (initialState) => (state) => state || initialState;

export function createDownloadPrintsStore(
  design: MapmakerDesignHeavyFragment,
  file: MapmakerFileHeavyFragment,
  allowBufferArea: boolean
): Store<DownloadPrintsStore> {
  const service = getPrintingService(MapmakerPrintServiceId.DOWNLOAD);

  return createStore(
    combineReducers<DownloadPrintsStore>({
      design: immutableReducer(design),
      file: immutableReducer(file),
      printService: immutableReducer(service),
      allowBufferArea: immutableReducer(allowBufferArea),
      printQuantities: createPrintQuantitiesReducer(file),
      printOptions: createPrintOptionsReducer(allowBufferArea),
      printSizeSettings: createPrintSizeSettingsReducer(file, service),
      downloadOptions: downloadOptionsReducer,
    }),
    applyMiddleware(thunk)
  );
}
