import { gql } from "@apollo/client";
import { Button, IButtonProps } from "@mapmaker/ui";
import { MouseEventHandler } from "react";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaEyeSlash } from "@react-icons/all-files/fa/FaEyeSlash";
import {
  OpeningSuggestionFragment,
  OpeningSuggestionStatus,
  useHideOpeningSuggestionMutation,
} from "../../../../../client/MapmakerApi";
import { trackGtmEvent } from "../../../../../lib/gtm";
import { updateOpeningSuggestion } from "../../../fileReducer";
import { useProjectDispatch } from "../../../projectStore";
import { useAddToastMessage } from "../../../shared/toasts/useProjectToastMessages";

gql`
  mutation hideOpeningSuggestion($input: HideOpeningSuggestionInput!) {
    hideOpeningSuggestion(input: $input) {
      suggestion {
        ...OpeningSuggestion
      }
    }
  }
`;

type HideSuggestionButtonProps = {
  suggestion: OpeningSuggestionFragment;
  onHidden?(): any;
} & Omit<IButtonProps, "children">;

export default function HideSuggestionButton({
  suggestion,
  onHidden = () => {},
  ...buttonProps
}: HideSuggestionButtonProps) {
  const projectDispatch = useProjectDispatch();
  const isHidden = suggestion.status === OpeningSuggestionStatus.Hidden;
  const toast = useAddToastMessage();
  const [hideSuggestion] = useHideOpeningSuggestionMutation({
    onError: () => toast.addToastError("Error hiding suggestion."),
  });

  const onClick: MouseEventHandler<HTMLButtonElement> = async e => {
    e.stopPropagation();
    const hiding = !isHidden;
    // Optimistically hide this.
    projectDispatch(
      updateOpeningSuggestion({
        ...suggestion,
        status: hiding
          ? OpeningSuggestionStatus.Hidden
          : OpeningSuggestionStatus.Submitted,
      })
    );

    trackGtmEvent({
      event: "mapmaker.manually-toggle-suggestion-hidden",
      openingId: suggestion.openingId,
      hidden: hiding,
    });

    if (hiding) {
      toast.addToastInfo(
        {
          content: "Suggestion hidden.",
          icon: FaEyeSlash,
        },
        undefined,
        3
      );
    }

    await hideSuggestion({
      variables: {
        input: {
          openingId: suggestion.openingId,
          fileId: suggestion.fileId,
          id: suggestion.id,
          hide: hiding,
        },
      },
    });
    onHidden();
  };

  return (
    <Button onClick={onClick} {...buttonProps}>
      {isHidden && (
        <>
          <FaEyeSlash /> Unhide
        </>
      )}
      {!isHidden && (
        <>
          <FaEye /> Hide
        </>
      )}
    </Button>
  );
}
