import ZoomPan from "zoom-pan";
import { viewBoxForBBox } from "../../../../../lib/svg/ViewboxUtils";
import { SvgContextProvider } from "../../../../../lib/svg/useSvgContext";
import useOpening from "../useOpening";
import { ReadOnlyOpeningDirect } from "../ReadOnlyOpening";
import MapmakerLayout from "../../../../layout/MapmakerLayout";
import MapmakerTopBar from "../../../../layout/MapmakerTopBar";
import { useMapmakerAppConfig } from "../../../../../client";
import { useProjectFile } from "../../../useProjectState";
import useSuggestion from "./useSuggestion";
import ProjectToolbar from "../../../ProjectToolbar";
import OpeningSuggestionListItem from "../../../collaboration/OpeningSuggestionListItem";
import FeatureExitButton from "../../../shared/FeatureExitButton";

type ReadOnlyOpeningViewProps = {
  suggestionId: string;
};

export default function ReadOnlySuggestionView({
  suggestionId,
}: ReadOnlyOpeningViewProps) {
  const suggestion = useSuggestion(suggestionId);
  const { gotoEditFile } = useMapmakerAppConfig();
  const file = useProjectFile();
  const { openingFeature } = useOpening(suggestion.openingId);
  const bbox = openingFeature.outlined
    ? openingFeature.layout.cut.bbox
    : openingFeature.layout.print?.bbox ||
      openingFeature.layout.outer?.bbox ||
      openingFeature.layout.cut.bbox;
  const viewbox = viewBoxForBBox(bbox, "3%");

  return (
    <MapmakerLayout>
      <MapmakerLayout.TopBar>
        <MapmakerTopBar
          breadcrumbs={[
            {
              label: "Suggest",
              onClick: () => gotoEditFile(file.id, "collaborate"),
            },
            {
              label: openingFeature.name,
              onClick: () =>
                gotoEditFile(file.id, "collaborate", openingFeature.id),
            },
            {
              label: "Submitted",
            },
          ]}
        />
      </MapmakerLayout.TopBar>
      <MapmakerLayout.Main
        style={{ overflowY: "hidden" }}
        nav={<ProjectToolbar selected="collaborate" />}
      >
        <MapmakerLayout.MainContextPanel>
          <OpeningSuggestionListItem
            mode="standalone"
            suggestion={suggestion}
          />
        </MapmakerLayout.MainContextPanel>
        <MapmakerLayout.MainContent>
          <FeatureExitButton
            onClick={() => gotoEditFile(file.id, "collaborate")}
          />
          <ZoomPan>
            <SvgContextProvider viewbox={viewbox} flex>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewbox.join(" ")}
                width="100%"
                height="100%"
              >
                <ReadOnlyOpeningDirect
                  openingFeature={openingFeature}
                  openingInput={{
                    type: "OPENING",
                    ...suggestion.input,
                  }}
                />
              </svg>
            </SvgContextProvider>
          </ZoomPan>
        </MapmakerLayout.MainContent>
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}
