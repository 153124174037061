import { OutputTypeDemonstration } from "@mapmaker/svg";
import { useDispatch } from "react-redux";
import { useMapmakerAppConfig } from "../../../client/MapmakerAppConfig";
import MapmakerMessage from "../../shared/messages/MapmakerMessage";
import { useOrderStickersStore } from "./useOrderStickersStore";
import { getOutputTypeDetails } from "../../project/shared/OutputTypeLibrary";
import StickerOutputDescriptionModal from "./StickerOutputDescriptionModal";
import { setShowOutputHelp } from "./stickerOrderSettingsReducer";
import CustomSizeWarning from "../shared/CustomSizeWarning";
import "./StickerOrderMessages.css";

export default function StickerOrderMessages() {
  const dispatch = useDispatch();
  const {
    file,
    settings: { showOutputHelp },
  } = useOrderStickersStore();

  return (
    <>
      <CustomSizeWarning file={file} />
      <StickerOutputDescriptionModal
        open={showOutputHelp}
        onClose={() => dispatch(setShowOutputHelp(false))}
      />
      <StickersInCartMessage />
      <OutputTypeMessage />
    </>
  );
}

function StickersInCartMessage() {
  const { gotoCart } = useMapmakerAppConfig();
  const { cartState, file } = useOrderStickersStore();

  const groupsForFile = cartState.groups.filter(
    group => group.fileId === file.id
  );
  const numStickers = groupsForFile.reduce(
    (sum, group) => sum + group.stickerCount,
    0
  );

  if (numStickers === 0) {
    return null;
  }
  return (
    <MapmakerMessage>
      You have {numStickers === 1 ? "a sticker" : `${numStickers} stickers`} for
      this map in{" "}
      <span className="link" onClick={gotoCart}>
        your cart
      </span>{" "}
      already. There is no problem with that, we just thought you should know.
      {numStickers === 1 && (
        <>
          Generally it is easiest to edit your project until you're happy with
          it and then add all the stickers you want to order to your cart at
          once.
        </>
      )}
    </MapmakerMessage>
  );
}

function OutputTypeMessage() {
  const dispatch = useDispatch();
  const { businessId } = useMapmakerAppConfig();
  const {
    selectedOutputOption,
    design,
    printQuantities,
  } = useOrderStickersStore();

  // We have a different warning if we have a custom scale/type. In the future we may want to always
  // show the output type and scale in this situation if custom outputs becomes normal.
  if (!selectedOutputOption) {
    return null;
  }

  const details = getOutputTypeDetails(selectedOutputOption.type);

  if (businessId === "tbl") {
    return null;
  }

  return (
    <div id="output-type-message">
      <OutputTypeDemonstration
        design={design}
        outputType={selectedOutputOption.type}
        width={140}
        height={100}
        highlightColor="var(--mm-accent-color)"
        lowlightColor="var(--mm-light-grey)"
        highlightedOpeningIds={Object.entries(printQuantities)
          .filter(entry => entry[1] > 0)
          .map(entry => entry[0])}
      />
      <div>
        <h3>{details.name}</h3>
        <p>
          You are creating stickers for a {selectedOutputOption.name}.{" "}
          <span
            className="link"
            onClick={() => dispatch(setShowOutputHelp(true))}
          >
            Not what you want?
          </span>
        </p>
      </div>
    </div>
  );
}
