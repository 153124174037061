/**
 * A generic error that can be displayed to the user.
 */
export type UserErrorOptions = {
  message: string;
  // Whether to report the error to our error tracking system.
  capture?: boolean;
};

export class UserError extends Error {
  public readonly capture: boolean;

  constructor({
    message = "An unexpected error ocurred",
    capture = true,
  }: UserErrorOptions) {
    super(message);
    this.capture = capture;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, UserError.prototype);
  }
}

/**
 * An error when a page or item cannot be found.
 */
export type PageNotFoundErrorOptions = {
  message?: string;
  fallbackUrl?: string;
  fallbackMessage?: string;
};

export class PageNotFoundError extends Error {
  public readonly fallbackUrl: string;
  public readonly fallbackMessage: string;

  constructor({
    message = "The page you are looking for does not exist.",
    fallbackUrl = "/",
    fallbackMessage = "Return Home",
  }: PageNotFoundErrorOptions) {
    super(message);
    this.fallbackUrl = fallbackUrl;
    this.fallbackMessage = fallbackMessage;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, PageNotFoundError.prototype);
  }
}
