import StickerOfferGridItem from "./StickerOfferGridItem";
import { useOrderStickersStore } from "./useOrderStickersStore";
import { useMemo } from "react";
import {
  compareOpeningsByDateRange,
  OpeningFeature,
  OpeningInput,
} from "@mapmaker/core";
import "./StickerOfferGrid.css";

export default function StickerOfferGrid() {
  const { offer, settings, file, design } = useOrderStickersStore();

  const sorted = useMemo(() => {
    return [...offer.stickers].sort((a, b) => {
      const openingA = design.features[a.openingId] as OpeningFeature;
      const openingInputA = file.inputs[a.openingId] as OpeningInput;
      const openingB = design.features[b.openingId] as OpeningFeature;
      const openingInputB = file.inputs[b.openingId] as OpeningInput;
      switch (settings.sort) {
        case "modified":
          const modifiedA = openingInputA.modifiedAt;
          const modifiedB = openingInputB.modifiedAt;
          if (!modifiedA && !modifiedB) {
            return 0;
          } else if (!modifiedA) {
            return 1;
          } else if (!modifiedB) {
            return -1;
          } else {
            return modifiedA < modifiedB ? 1 : modifiedA > modifiedB ? -1 : 0;
          }
        case "name":
          return openingA.name < openingB.name ? -1 : 1;
        case "timeline":
          // -1 makes it the most recent which is more logical.
          return (
            -1 *
            compareOpeningsByDateRange(
              { id: a.openingId, ...openingInputA },
              { id: b.openingId, ...openingInputB }
            )
          );
      }
    });
  }, [offer.stickers, settings.sort]);

  return (
    <div id="sticker-offer-grid">
      {sorted.map(sticker => (
        <StickerOfferGridItem
          key={sticker.openingId}
          openingId={sticker.openingId}
        />
      ))}
    </div>
  );
}
