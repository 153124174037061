import local from "./MapmakerConfig.local";
import dev from "./MapmakerConfig.dev";
import gamma from "./MapmakerConfig.gamma";
import prod from "./MapmakerConfig.prod";

export type MapmakerStage = "local" | "dev" | "gamma" | "prod";
export type MapmakerDataStage = "dev" | "prod";

export type MapmakerConfig = {
  stage: MapmakerStage;
  dataStage: MapmakerDataStage;
  isLocal?: boolean;
  apiGateway: {
    url: string;
  };
  contentUrl: string;
  userContentUrl: string;
  userContentImageServerUrl: string;
  staticUrl: string;
  imageServerBucket: string;
  internalBucket: string;
  walgreens: {
    production: boolean;
    apiKey: string;
    affiliateId: string;
    publisherId: string;
  };
};

function getStage(): MapmakerStage {
  // For serverless functions running locally.
  if (process.env.IS_LOCAL || process.env.IS_OFFLINE) {
    return "local";
  }
  return (process.env.MAPMAKER_STAGE ||
    process.env.REACT_APP_MAPMAKER_STAGE ||
    process.env.REACT_APP_STAGE ||
    "local") as MapmakerStage;
}

export function getConfig(stage?: MapmakerStage): MapmakerConfig {
  switch (stage || getStage()) {
    case "local":
      return local;
    case "dev":
      return dev;
    case "gamma":
      return gamma;
    case "prod":
      return prod;
    default:
      throw new Error(`Unknown stage ${getStage()}`);
  }
}

const config = getConfig();
try {
  /* @ts-ignore */
  window?.tbl_mm_config = config;
} catch (e) {}
export default config;

/**
 * Reinitialize the config with a new stage.
 */
export function initialize(stage: MapmakerStage) {
  const newConfig = getConfig(stage);
  Object.entries(newConfig).forEach(([key, value]) => {
    config[key] = value;
  });
}

export function getInternalUrl(path: String): string {
  return `https://${config.internalBucket}.s3.amazonaws.com/${path}`;
}

export function getDesignUrl(designId: String): string {
  return getInternalUrl(`designs/${designId}.json`);
}

export function getPrintUrl(
  designId: String,
  layer: String,
  size: string = null
): string {
  const key = [designId, layer, size].filter(x => !!x).join("-");
  return getInternalUrl(`prints/${key}.jpeg`);
}
