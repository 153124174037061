import dateFormat from "dateformat";
import { WalgreensProduct } from "../../../client/MapmakerApi";
import { PaperSize } from "../../../lib/prints/printSizes";

export function getWalgreensProduct(
  products: WalgreensProduct[],
  paperSize: PaperSize
): WalgreensProduct {
  return products.find(product => {
    const [productWidth, productHeight] = product.productSize
      .split("x")
      .map(x => parseInt(x));
    return (
      (paperSize.width / 72 === productWidth &&
        paperSize.height / 72 === productHeight) ||
      (paperSize.width / 72 === productHeight &&
        paperSize.height / 72 === productWidth)
    );
  });
}

// Get the matching Walgreens product from the product library given the size of a print  (in inches).
function parseWalgreensDate(walgreensDate): Date {
  const [date, time, ampm] = walgreensDate.split(" ");
  const [month, day, year] = date.split("-").map(x => parseInt(x));
  const [hours, minutes] = time.split(":").map(x => parseInt(x));
  const addForPm = ampm == "PM" ? 12 : 0;
  // NOTE Walgreens test server returns times in UTC but production returns them in the
  // timezone presumably from the location of the store.
  const result = new Date(year, month - 1, day, hours + addForPm, minutes);
  // Validates that the date is actually a date.
  if (result instanceof Date) {
    return result;
  } else {
    throw new Error(
      `Invalid date string received from Walgreens API: ${walgreensDate}`
    );
  }
}

// Takes a promiseTime from the Walgreens API and formats it to be human readable.
export function prettyPromiseTime(promiseTime: string): string {
  if (promiseTime == "01-01-3000 00:00 AM") {
    return "Within 48 hours";
  } else {
    try {
      const promiseDate = parseWalgreensDate(promiseTime);
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      if (promiseDate.toDateString() == today.toDateString()) {
        return `Today at ${dateFormat(promiseDate, "h:mm tt")}`;
      }
      if (promiseDate.toDateString() == tomorrow.toDateString()) {
        return `Tomorrow at ${dateFormat(promiseDate, "h:mm tt")}`;
      } else {
        return dateFormat(promiseDate, 'mmmm d "at" h:mm tt');
      }
    } catch (e) {
      // If something goes wrong in parsing just display it as they sent it back.
      return "Within 48 hours";
    }
  }
}
