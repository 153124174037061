import {
  StickerTokenProductFragment,
  StickerTokenVariantFragment,
  StorefrontProduct,
  StorefrontProductVariant,
} from "../../client/MapmakerApi";
import { findOption } from "./variantUtils";

const PURCHASE_STICKER_TOKENS_HANDLE_DEPRECATED = "purchase-sticker-tokens";
const PURCHASE_STICKER_TOKENS_HANDLE = "sticker-tokens";
const TOKEN_QUANTITY_OPTION_NAME = "Tokens";

export function isPurchaseStickerTokensProduct(
  product: Pick<StorefrontProduct, "handle">
): boolean {
  return (
    product.handle.startsWith(PURCHASE_STICKER_TOKENS_HANDLE) ||
    product.handle.startsWith(PURCHASE_STICKER_TOKENS_HANDLE_DEPRECATED)
  );
}

export function purchaseStickerTokensQuantity(
  variant: Pick<StorefrontProductVariant, "selectedOptions">
): number {
  const tokensOption = findOption(
    variant.selectedOptions,
    TOKEN_QUANTITY_OPTION_NAME
  );
  if (tokensOption?.value === undefined) {
    throw new Error(
      "Cannot determine the number of sticker tokens for this item."
    );
  }
  return parseFloat(tokensOption.value.replace(/\D/g, ""));
}

export function getStickerTokensVariantFromProduct(
  product: StickerTokenProductFragment,
  tokens: number
): StickerTokenVariantFragment {
  return product.variants.edges.find(
    ({ node: variant }) => purchaseStickerTokensQuantity(variant) === tokens
  )?.node;
}

export function getStickerTokensVariantMap(
  product: StickerTokenProductFragment
): Record<number, StickerTokenVariantFragment> {
  const map: Record<number, StickerTokenVariantFragment> = {};
  (product?.variants?.edges ?? []).forEach(
    edge => (map[purchaseStickerTokensQuantity(edge.node)] = edge.node)
  );
  return map;
}
