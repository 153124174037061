import { useMemo } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox, Header, Segment } from "semantic-ui-react";
import {
  setAllPrintQuantities,
  setOpeningPrintQuantity,
} from "./printQuantitiesReducer";
import { IRectangularPrintState } from "./ReduxPrintStates";
import useRectangularPrintsSelector from "./useRectangularPrintsSelector";
import "./PrintQuantitiesSelector.css";

export default function PrintQuantitiesSelector() {
  const dispatch = useDispatch();
  const { design, printQuantities } = useSelector(
    (state: IRectangularPrintState) => ({
      design: state.design,
      printQuantities: state.printQuantities,
    })
  );

  const disabled = false;

  function selectAll() {
    dispatch(setAllPrintQuantities(1));
  }
  function selectNone() {
    dispatch(setAllPrintQuantities(0));
  }
  function onToggle(openingId: string, checked: boolean) {
    dispatch(setOpeningPrintQuantity(openingId, checked ? 1 : 0));
  }

  function OpeningPrintCheckbox({ openingId }) {
    const checked = printQuantities[openingId] > 0;
    const { unprintableOpeningIds } = useRectangularPrintsSelector();
    const unprintable = unprintableOpeningIds.includes(openingId);

    return (
      <div
        className={clsx("row", {
          checked,
          unchecked: !checked,
          unprintable,
        })}
        title={unprintable ? "Does not fit on the current paper size" : null}
        onClick={() => {
          if (!unprintable) {
            onToggle(openingId, !checked);
          }
        }}
      >
        <Checkbox
          label={design.features[openingId].name}
          checked={checked}
          disabled={unprintable}
        />
      </div>
    );
  }

  const printOpeningIds = useMemo(
    () => Object.keys(printQuantities).sort(),
    [printQuantities]
  );

  const numSelected = useMemo(
    () => Object.values(printQuantities).filter((x) => !!x).length,
    [printQuantities]
  );

  const numTotal = printOpeningIds.length;

  return (
    <div
      key={design.id}
      id="print-quantities-selector"
      className={clsx({
        // TODO - Disable this during loading or something?
        disabled,
      })}
    >
      <Header as="h5" attached="top">
        {numSelected}/{numTotal} {design.regionTypePlural} selected
        <span className="list-toggles">
          <a
            onClick={selectAll}
            className={clsx({
              disabled: disabled || numSelected == numTotal,
            })}
          >
            all
          </a>
          /
          <a
            onClick={selectNone}
            className={clsx({
              disabled: disabled || numSelected == 0,
            })}
          >
            none
          </a>
        </span>
      </Header>
      <Segment attached>
        {Object.keys(printQuantities)
          .sort()
          .map((openingId) => (
            <OpeningPrintCheckbox key={openingId} openingId={openingId} />
          ))}
      </Segment>
    </div>
  );
}
