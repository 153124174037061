import { useMemo } from "react";
import {
  MapmakerFileAccessFragment,
  OpeningSuggestionStatus,
} from "../../../client/MapmakerApi";
import { useProjectFile } from "../useProjectState";

type BaseCollaborator = {
  displayName: string;
};
export type InactiveInvitedCollaborator = BaseCollaborator & {
  type: "inactive-invited";
  email: string;
};
export type ActiveInvitedCollaborator = BaseCollaborator & {
  type: "active-invited";
  email: string;
  userId: string;
};
export type ActivePublicCollaborator = BaseCollaborator & {
  type: "active-public";
  userId: string;
};
export type Collaborator =
  | InactiveInvitedCollaborator
  | ActiveInvitedCollaborator
  | ActivePublicCollaborator;

export function useCollaborators(): Collaborator[] {
  const { fileAccesses, openingSuggestions } = useProjectFile();

  return useMemo<Collaborator[]>(() => {
    const suggestionsById = openingSuggestions.reduce((map, suggestion) => {
      if (!map[suggestion.suggesterId]) {
        map[suggestion.suggesterId] = [];
      }
      map[suggestion.suggesterId].push(suggestion);
      return map;
    }, {});

    // Pretty bad sort here since it looks through the whole suggestion list on every comparison.
    const invitedCollaborators = fileAccesses.map<Collaborator>(fileAccess => {
      if (!fileAccess.granteeId) {
        return {
          type: "inactive-invited",
          displayName: fileAccess.granteeEmail,
          email: fileAccess.granteeEmail,
        };
      } else {
        const suggestions = suggestionsById[fileAccess.granteeId] ?? [];
        return {
          type: "active-invited",
          displayName:
            suggestions.length > 0
              ? suggestions[0].name
              : fileAccess.granteeEmail,
          email: fileAccess.granteeEmail,
          userId: fileAccess.granteeId,
        };
      }
    });
    const publicCollaborators = Object.entries(suggestionsById)
      .filter(([suggesterId]) =>
        fileAccesses.every(fileAccess => fileAccess.granteeId !== suggesterId)
      )
      .map<ActivePublicCollaborator>(([suggesterId, suggestions]) => {
        return {
          type: "active-public",
          userId: suggesterId,
          displayName: suggestions[0].name,
        };
      });

    return [...invitedCollaborators, ...publicCollaborators].sort((a, b) => {
      if (a.displayName < b.displayName) return -1;
      if (a.displayName > b.displayName) return 1;
      // Could do something fancier with emails and user ID's but it's tough.
      return 0;
    });
  }, [fileAccesses, openingSuggestions]);
}

type UseCollaboratorOptions = {
  email?: string;
  userId?: string;
};

export function useCollaborator({ userId, email }: UseCollaboratorOptions) {
  const { fileAccesses, openingSuggestions } = useProjectFile();
  const fileAccess = useMemo<MapmakerFileAccessFragment>(
    () => fileAccesses.find(fileAccess => fileAccess.granteeEmail === email),
    [fileAccesses, email]
  );

  const { allSuggestions = [], visibleSuggestions = [] } = useMemo(() => {
    const allSuggestions = openingSuggestions.filter(
      suggestion => suggestion.suggesterId === (userId ?? fileAccess.granteeId)
    );
    const visibleSuggestions = allSuggestions.filter(
      suggestion => suggestion.status !== OpeningSuggestionStatus.Hidden
    );
    return { allSuggestions, visibleSuggestions };
  }, [fileAccess, userId]);

  const displayNameIsEmail = allSuggestions.length === 0;
  const displayName = displayNameIsEmail
    ? fileAccess.granteeEmail
    : allSuggestions[0].name;

  return {
    displayName,
    displayNameIsEmail,
    fileAccess,
    visibleSuggestions,
    allSuggestions,
  };
}

/*
export function getDisplayNameFromFileAccess(
  openingSuggestions: MapmakerFileHeavyFragment["openingSuggestions"],
  fileAccess: Pick<MapmakerFileAccess, "granteeEmail" | "granteeId">
): string {
  const suggestions = openingSuggestions.filter(
    suggestion => suggestion.suggesterId === fileAccess.granteeId
  );
  if (suggestions.length > 0) {
    return suggestions[0].name;
  } else {
    return fileAccess.granteeEmail;
  }
}\*/
