import { ModalActions, ModalContent } from "semantic-ui-react";
import MapmakerModal from "../../../shared/modals/MapmakerModal";
import { saveFile } from "../../fileReducer";
import OptionalOpeningToggles from "./OptionalOpeningsToggles";
import SharingSettings from "./SharingSettings";
import BackgroundSettings from "./BackgroundSettings";
import GeneralSettings from "./GeneralSettings";
import OutputTypeSettings from "./OutputTypeSettings";
import OutputScaleSettings from "./OutputScaleSettings";
import { useProjectDispatch } from "../../projectStore";
import PrintServiceSettings from "./PrintServiceSettings";
import { Button } from "@mapmaker/ui";

type ProjectSettingsModalProps = {
  onClose?(): any;
  open?: boolean;
};

export default function ProjectSettingsModal({
  onClose,
  open = true,
}: ProjectSettingsModalProps) {
  const dispatch = useProjectDispatch();

  // Try to save when closed.
  const saveSettings = async () => {
    dispatch(saveFile());
    onClose();
  };

  return (
    <MapmakerModal
      className="project-settings-modal"
      open={open}
      onClose={saveSettings}
      size="small"
      title="Project Settings"
    >
      <ModalContent>
        <GeneralSettings />
        <OutputTypeSettings />
        <OutputScaleSettings />
        <PrintServiceSettings />
        <SharingSettings onLeave={saveSettings} />
        <OptionalOpeningToggles />
        <BackgroundSettings />
      </ModalContent>
      <ModalActions>
        <Button color="accent" onClick={saveSettings}>
          Done
        </Button>
      </ModalActions>
    </MapmakerModal>
  );
}
