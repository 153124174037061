type VersionedLocalItem<T> = {
  version: number;
  item: T;
};

interface ILocalItem<T> {
  put: (item: T) => void;
  get: () => T;
}

export function LocalItem<T>(key: string, version: number = 0): ILocalItem<T> {
  return {
    put: item => putLocalItem<T>(key, item, version),
    get: () => getLocalItem<T>(key, version),
  };
}

export function getLocalItem<T extends any>(
  key: string,
  version: number = 0
): T {
  try {
    const versionedItem = JSON.parse(
      window.localStorage.getItem(key)
    ) as VersionedLocalItem<T>;
    if (versionedItem.version === version) {
      return versionedItem.item;
    } else {
      return null;
    }
  } catch (e) {
    // TODO - Send error to server?
    return null;
  }
}

function putLocalItem<T extends any>(
  key: string,
  item: T,
  version: number = 0
): void {
  const versionedItem: VersionedLocalItem<T> = {
    version,
    item,
  };
  window.localStorage.setItem(key, JSON.stringify(versionedItem));
}
