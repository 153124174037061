import { Link } from "react-router-dom";
import { Divider, Icon, Menu } from "semantic-ui-react";
import SlideoutMenu, { SlideoutMenuLink } from "../components/SlideoutMenu";
import { useAuthContext } from "@tbl/aws-auth";
import "./MobileMenu.scss";
import {
  useMapmakerContext,
  useStorefrontCheckoutLight,
} from "@mapmaker/frontend";

interface IMobileMenuProps {
  open: boolean;
  onClose: () => any;
}

export default function MobileMenu({ open, onClose }: IMobileMenuProps) {
  const { account } = useMapmakerContext();

  return (
    <SlideoutMenu id="mobile-menu" open={open} onClose={onClose}>
      {<AuthMenu />}
      {<CartMenu />}
      <Divider />
      <Menu.Header>Photo Maps</Menu.Header>
      <SlideoutMenuLink path="/" exact>
        Home
      </SlideoutMenuLink>
      <SlideoutMenuLink path="/shop" exact>
        Products
      </SlideoutMenuLink>
      <SlideoutMenuLink path="/mapmaker" badge={account?.mapmakerFileCount}>
        Your Projects
      </SlideoutMenuLink>
      <SlideoutMenuLink path="/gift-guide">Gift Guide</SlideoutMenuLink>
      <SlideoutMenuLink path="/about">Our Story</SlideoutMenuLink>
      <Divider />
      <Menu.Header>Support</Menu.Header>
      <SlideoutMenuLink path="/help">Help Center</SlideoutMenuLink>
      <SlideoutMenuLink path="/contact">Contact Us</SlideoutMenuLink>
      <SlideoutMenuLink path="/privacy">Privacy</SlideoutMenuLink>
    </SlideoutMenu>
  );
}

function AuthMenu() {
  const { user, signOut } = useAuthContext();
  if (user) {
    return (
      <>
        <Menu.Item>
          <Link to="/account" className="email">
            <Icon name="user" />
            {user.email}
          </Link>
        </Menu.Item>
        <SlideoutMenuLink path="/account">Your Account</SlideoutMenuLink>
        <Menu.Item onClick={signOut} borderless="true">
          Sign Out
        </Menu.Item>
      </>
    );
  } else {
    return (
      <>
        <SlideoutMenuLink path="/account/login">Log In</SlideoutMenuLink>
        <SlideoutMenuLink path="/account/register">Register</SlideoutMenuLink>
      </>
    );
  }
}

function CartMenu() {
  const { checkout } = useStorefrontCheckoutLight();
  const totalItems = (checkout?.lineItems.edges ?? []).reduce(
    (total, { node }) => total + node.quantity,
    0
  );
  if (totalItems === 0) {
    return null;
  } else {
    return (
      <SlideoutMenuLink path="/shop/cart" badge={totalItems}>
        Your Cart
      </SlideoutMenuLink>
    );
  }
}
