import React from "react";
import { MapmakerBusinessId, OpeningFeature } from "@mapmaker/core";
import { getLayerByPriority } from "./openingUtils";
import OpeningImages from "./OpeningImages";
import { MapmakerSvgContextProvider } from "../MapmakerSvgContext";
import SvgNode from "../SvgNode";
import { OpeningSuggestionSvgProps } from "@mapmaker/renderable";
import { viewBoxForBBox } from "../../utils/ViewBoxUtils";

/*
 * Renders an SVG showing a
 */
export default function OpeningSuggestionSvg({
  file,
  suggestion,
  width,
  height,
  maxWidth = Infinity,
  maxHeight = Infinity,
  backgroundColor,
  responsive = false,
}: OpeningSuggestionSvgProps<"resource">) {
  const opening: OpeningFeature = file.design.features[
    suggestion.openingId
  ] as OpeningFeature;
  const openingInput = suggestion.input;

  const imageClipLayer = getLayerByPriority(
    opening,
    opening.outlined ? "print" : ["cut", "print"]
  );

  const bbox = imageClipLayer.bbox;

  if (!height && !width) {
    width = bbox.width;
    height = bbox.height;
  } else if (!width) {
    width = (height * bbox.width) / bbox.height;
  } else if (!height) {
    height = (width * bbox.height) / bbox.width;
  }

  if (width > maxWidth) {
    const mwRatio = maxWidth / width;
    width = width * mwRatio;
    height = height * mwRatio;
  }

  if (height > maxHeight) {
    const mhRatio = maxHeight / height;
    width = width * mhRatio;
    height = height * mhRatio;
  }

  const viewBox = viewBoxForBBox(bbox, width, height, 10);
  const svgScale = Math.max(width / viewBox[2], height / viewBox[3]);

  // Only render the background if we don't have it set to transparent.
  function Background() {
    if (backgroundColor) {
      return (
        <rect
          x={viewBox[0]}
          y={viewBox[1]}
          width="100%"
          height="100%"
          fill={backgroundColor}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox.join(" ")}
      width={responsive ? undefined : width}
      height={responsive ? undefined : height}
      preserveAspectRatio={responsive ? "xMidYMid meet" : undefined}
    >
      <MapmakerSvgContextProvider
        value={{
          svgScale,
          businessId: file.design.businessId as MapmakerBusinessId,
        }}
      >
        <defs>
          <filter id={`osuggsvg-${suggestion.openingId}`}>
            <feDropShadow
              dx={0}
              dy={1 / svgScale}
              stdDeviation={2 / svgScale}
              floodColor="#000000"
              floodOpacity={0.5}
            />
          </filter>
        </defs>
        <Background />
        {/** Clip area background color - Think of this as the color of media it is printed on. */}
        <SvgNode
          nodeData={imageClipLayer}
          style={{
            fill: "#FFFFFF",
            filter: `url(#osuggsvg-${suggestion.openingId})`,
          }}
        />
        {/** Solid background fill for image - if there is one. Note because some openings have a
         * fill area smaller than the clip area, we cannot combine this with the background color
         * above. */}
        {openingInput?.background ? (
          <SvgNode
            nodeData={imageClipLayer}
            style={{ fill: openingInput?.background }}
          />
        ) : null}
        {/** Printed images */}
        <OpeningImages
          clipLayer={imageClipLayer}
          opening={opening}
          openingInput={openingInput}
        />
        {/** Subtle outline to help visualize the edge of the cut */}
      </MapmakerSvgContextProvider>
    </svg>
  );
}
