import {
  getFeaturesList,
  MapmakerDesign,
  OpeningFeature,
} from "@mapmaker/core";
import React from "react";
import { MatImageFillFilter } from "./MatImageFillFilter";

type IMatProps = {
  design: Pick<MapmakerDesign, "height" | "width" | "features">;
  layer: string;
  // Deprecated - Use fillColor and fillUrl instead.
  fill?: string;
  fillColor?: string;
  fillUrl?: string;
  strokeWidth?: number;
  filter?: string;
  exclude?: boolean;
};

/*
 * Renders a path (usually a compound path) of a layer with the openings cut out (eg. a piece of
 * matboard which would be cut).
 */
export default function Mat({
  design,
  layer,
  strokeWidth = 0,
  fill = "#FFFFFF",
  fillUrl,
  fillColor,
  filter,
  exclude = false,
}: IMatProps) {
  if (exclude) {
    return null;
  }

  function getCompoundPathIncludingChildren(nodeData) {
    var compoundPath = "";
    if (nodeData.d) {
      compoundPath += nodeData.d;
    }
    if (nodeData.children?.length > 0) {
      compoundPath += nodeData.children.reduce((childrenPath, child) => {
        return childrenPath + getCompoundPathIncludingChildren(child);
      }, compoundPath);
    }
    return compoundPath;
  }

  function getPath() {
    const w = design.width;
    const h = design.height;
    const layers = getFeaturesList<OpeningFeature>(design, "OPENING")
      .map((feature: OpeningFeature) => feature.layout[layer])
      .filter(layer => layer);
    if (layers.length === 0 && layer !== "background") {
      return null;
    }
    const path = layers.reduce((path, layer) => {
      return path + getCompoundPathIncludingChildren(layer);
    }, `M 0 0 L ${w} 0 L ${w} ${h} L 0 ${h} L 0 0 z`);
    return path;
  }

  const path = getPath();
  const fillId = `${layer}-print`;

  if (!path) {
    return null;
  } else {
    function FillFilter() {
      if (fillUrl) {
        return (
          <MatImageFillFilter id={fillId} url={fillUrl} fill={fillColor} />
        );
      } else {
        return null;
      }
    }

    return (
      <>
        <FillFilter />
        <path
          filter={filter}
          style={{
            fill: fillUrl ? `url(#${fillId})` : fillColor ?? fill,
            fillRule: "evenodd",
            stroke: "RGBA(0,0,0,0.6)",
            strokeWidth: strokeWidth * 0, // 0 for now but we need to evaluate all the other places Mat is used
            vectorEffect: "non-scaling-stroke",
          }}
          d={getPath()}
        />
      </>
    );
  }
}
