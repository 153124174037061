import * as yup from "yup";
import { RenderableForm } from ".";

export const SimpleMessageForm: RenderableForm = {
  initialValues: {
    height: 1024,
    width: 1024,
  },
  inputs: [
    {
      type: "string",
      id: "message",
      name: "Message",
      validation: yup.string().required(),
    },
    {
      type: "number",
      id: "width",
      name: "Width",
      validation: yup.number().required(),
    },
    {
      type: "number",
      id: "height",
      name: "Height",
      validation: yup.number().required(),
    },
  ],
};
