import { useCallback } from "react";
import MapmakerToolbar from "../../shared/toolbar/MapmakerToolbar";
import MapmakerToolbarButton from "../../shared/toolbar/MapmakerToolbarButton";
import { setBackground } from "./editable/editableOpeningReducer";
import { useEditableOpeningDispatch } from "./editable/EditableOpeningStore";
import useEditableOpening from "./editable/useEditableOpening";
import OpeningColorPicker from "./OpeningColorPicker";
import OpeningInfoModal from "./OpeningInfoModal";
import OpeningImageToolbar from "./OpeningImageToolbar";

export default function OpeningToolbar() {
  const { openingFeature, openingInput } = useEditableOpening();
  const dispatch = useEditableOpeningDispatch();

  const onChangeColor = useCallback(
    color => {
      dispatch(setBackground(color));
    },
    [dispatch, setBackground]
  );

  return (
    <MapmakerToolbar id="opening-toolbar" color="light">
      <MapmakerToolbarButton mode="modal" tooltip="Help" icon="help circle">
        <OpeningInfoModal openingFeature={openingFeature} />
      </MapmakerToolbarButton>
      <MapmakerToolbarButton
        mode="popover"
        tooltip="Background color"
        icon="tint"
      >
        <OpeningColorPicker
          value={openingInput.background}
          onChange={onChangeColor}
        />
      </MapmakerToolbarButton>
      {/*
       * Items below here are only enabled when an image is selected.
       */}
      <OpeningImageToolbar />
    </MapmakerToolbar>
  );
}
