import { produce } from "immer";
import {
  WalgreensStore,
  CreateRectangularPrintsRequest,
  CreateRectangularPrintsResponse,
  RectangularPrintFile,
  RectangularPrintOptions,
  RectangularPrintLayoutInput,
} from "../../../client/MapmakerApi";
import { LocalItem } from "../../../lib/browser/typedLocalStorage";
import { ApolloClient, gql } from "@apollo/client";

export type WalgreensCustomerInformation = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type PrintBatch = {
  loading: boolean;
  error?: string;
  input?: CreateRectangularPrintsRequest;
  printFiles?: RectangularPrintFile[];
  printLayouts?: RectangularPrintLayoutInput;
};

export type OrderDetails = {
  walgreensOrderId: string;
};

export type WalgreensCheckoutState = {
  selectedStore?: WalgreensStore;
  zip?: string;
  customerInformation?: WalgreensCustomerInformation;
  printBatch: PrintBatch;
  submittedOrder?: OrderDetails;
};

const SET_SELECTED_STORE = "checkout.set_selected_store";
const SET_CUSTOMER_INFORMATION = "checkout.set_customer_information";
const CREATE_PRINT_BATCH_STARTED = "checkout.create_print_batch_started";
const CREATE_PRINT_BATCH_COMPLETED = "checkout.create_print_batch_completed";
const CREATE_PRINT_BATCH_ERROR = "checkout.create_print_batch_error";
const CLEAR_PRINT_BATCH = "checkout.clear_print_batch";
const SET_SUBMITTED_ORDER = "checkout.set_submitted_order";

export function setSelectedStore(selectedStore: WalgreensStore, zip: string) {
  return {
    type: SET_SELECTED_STORE,
    selectedStore,
    zip,
  };
}

export function clearSelectedStore() {
  return {
    type: SET_SELECTED_STORE,
    selectedStore: null,
  };
}

export function setCustomerInformation(
  customerInformation: WalgreensCustomerInformation
) {
  return {
    type: SET_CUSTOMER_INFORMATION,
    customerInformation,
  };
}

export function clearCustomerInformation() {
  return {
    type: SET_CUSTOMER_INFORMATION,
    customerInformation: null,
  };
}

const CREATE_PRINTS = gql`
  mutation createRectangularPrints($input: CreateRectangularPrintsRequest) {
    createRectangularPrints(input: $input) {
      prints {
        key
        url
      }
    }
  }
`;

export function createPrintBatch(
  client: ApolloClient<object>,
  fileId: string,
  printOptions: RectangularPrintOptions,
  printLayouts: RectangularPrintLayoutInput[]
) {
  return async dispatch => {
    const input: CreateRectangularPrintsRequest = {
      fileId,
      format: "jpeg",
      dpi: 300,
      printOptions,
      printLayouts,
    };
    dispatch(createPrintBatchStarted(input));
    try {
      const result = await client.mutate({
        mutation: CREATE_PRINTS,
        variables: {
          input,
        },
        fetchPolicy: "network-only",
      });

      const printResponse = result.data
        .createRectangularPrints as CreateRectangularPrintsResponse;
      dispatch(createPrintBatchCompleted(printLayouts, printResponse.prints));
    } catch (e) {
      dispatch(createPrintBatchError("Error generating prints."));
    }
    // TODO - Handle Errors
  };
}

function createPrintBatchStarted(input: CreateRectangularPrintsRequest) {
  return {
    type: CREATE_PRINT_BATCH_STARTED,
    input,
  };
}

function createPrintBatchCompleted(
  printLayouts: RectangularPrintLayoutInput[],
  printFiles: RectangularPrintFile[]
) {
  return {
    type: CREATE_PRINT_BATCH_COMPLETED,
    printLayouts,
    printFiles,
  };
}

function createPrintBatchError(error: string) {
  return {
    type: CREATE_PRINT_BATCH_ERROR,
    error,
  };
}

export function clearPrintBatch() {
  return {
    type: CLEAR_PRINT_BATCH,
  };
}

export function setSubmittedOrder(submittedOrder: OrderDetails) {
  return { type: SET_SUBMITTED_ORDER, submittedOrder };
}
export function clearSubmittedOrder() {
  return { type: SET_SUBMITTED_ORDER, submittedOrder: null };
}

const localItem = LocalItem<WalgreensCheckoutState>("mapmaker.wgcheckout");

export default produce(
  (draft: WalgreensCheckoutState, action): WalgreensCheckoutState => {
    if (draft === undefined) {
      return {
        ...(localItem.get() || {}),
        printBatch: {
          loading: false,
        },
        submittedOrder: null,
      };
    }

    function saveLocalState() {
      localItem.put({
        ...draft,
        // Never store the print batch or submitted order. Would cause very confusing states.
        printBatch: undefined,
        submittedOrder: undefined,
      });
    }

    switch (action.type) {
      case SET_SELECTED_STORE:
        draft.selectedStore = action.selectedStore;
        draft.zip = action.zip;
        saveLocalState();
        break;
      case SET_CUSTOMER_INFORMATION:
        draft.customerInformation = action.customerInformation;
        saveLocalState();
        break;
      case CREATE_PRINT_BATCH_STARTED:
        draft.printBatch = {
          loading: true,
          input: action.input,
        };
        break;
      case CREATE_PRINT_BATCH_COMPLETED:
        draft.printBatch = {
          loading: false,
          input: draft.printBatch.input,
          printFiles: action.printFiles,
          printLayouts: action.printLayouts,
        };
        break;
      case CREATE_PRINT_BATCH_ERROR:
        draft.printBatch = {
          loading: false,
          error: action.error,
          input: draft.printBatch.input,
        };
        break;
      case CLEAR_PRINT_BATCH:
        draft.printBatch = {
          loading: false,
        };
        break;
      case SET_SUBMITTED_ORDER:
        draft.submittedOrder = action.submittedOrder;
        break;
      default:
        break;
    }
  }
);
