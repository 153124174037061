import dev from "./TBLDotComConfig.dev";
import type {TBLDotComConfig} from ".";

const config:TBLDotComConfig = {
  ...dev,
  stage: "local",
  tblApi: {
    url: "http://localhost:4002"
  }
};
export default config;