import clsx from "clsx";
import { Icon } from "semantic-ui-react";
import { getFeaturesList, OpeningFeature } from "@mapmaker/core";
import useOpening from "../../features/opening/useOpening";
import { SvgNode, viewBoxForBBox } from "@mapmaker/svg";
import { useProjectDesign, useProjectFile } from "../../useProjectState";
import SelectableSetting from "./SelectableSetting";
import SettingSection from "./SettingSection";
import { useProjectDispatch } from "../../projectStore";
import "./OptionalOpeningsToggles.css";
import { updateFeatureInput } from "../../fileReducer";
import { trackGtmEvent } from "../../../../lib/gtm";
import { ProjectOutputType } from "../../../../client/MapmakerApi";
import { useMemo } from "react";

export default function OptionalOpeningToggles() {
  const design = useProjectDesign();
  const file = useProjectFile();

  const optionalOpenings = getFeaturesList<OpeningFeature>(
    design,
    "OPENING"
  ).filter((opening) => opening.optional);
  const count = optionalOpenings.length;

  if (count === 0) {
    return null;
  }

  const description = useMemo(() => {
    const shared = `This project includes optional regions which can be enabled or disabled.`;
    switch (file.outputType) {
      case ProjectOutputType.MatboardWallMap:
      case ProjectOutputType.MatboardWallMapSingle:
        return `${shared} If you print photos or order stickers for these openings they will be printed with a white border to match the white border around other regions. These prints can then be placed on top of the outer matboard since there are not cutouts in the map for the optional images.`;
      case ProjectOutputType.VinylWallMap:
      case ProjectOutputType.VehicleMap:
      default:
        return shared;
    }
  }, [file.outputType]);

  return (
    <SettingSection
      id="optional-opening-toggles"
      label="Optional Regions"
      description={description}
    >
      <div className="content">
        {optionalOpenings.map((opening) => {
          return <OpeningToggle key={opening.id} openingId={opening.id} />;
        })}
      </div>
    </SettingSection>
  );
}

type OpeningToggleProps = {
  openingId: string;
};

function OpeningToggle({ openingId }: OpeningToggleProps) {
  const {
    openingFeature: opening,
    openingInput,
    enabled,
  } = useOpening(openingId);
  const dispatch = useProjectDispatch();

  return (
    <SelectableSetting
      selected={enabled}
      className={clsx("option")}
      onToggle={(enabled) => {
        dispatch(
          updateFeatureInput(opening.id, {
            type: "OPENING",
            images: [],
            ...(openingInput ?? {}),
            enabled,
          })
        );
        trackGtmEvent({
          event: "mapmaker.toggle-optional-opening",
          enabled,
          openingId,
        });
      }}
    >
      <div>
        {enabled ? (
          <Icon className="icon" name="check square" size="large" />
        ) : (
          <Icon className="icon" name="square outline" size="large" />
        )}
      </div>
      <div className="details">
        <div className="name">{opening.name}</div>
        <div className="status">{enabled ? "Included" : "Not Included"}</div>
      </div>
      <svg
        width={64}
        height={32}
        viewBox={viewBoxForBBox(opening.layout.print.bbox, 64, 32, 1).join(" ")}
      >
        <SvgNode className="preview" nodeData={opening.layout.print} />
      </svg>
    </SelectableSetting>
  );
}
