import { useCallback, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { getOpeningFeature, OpeningImageInput } from "@mapmaker/core";
import { useUploadBase64Image } from "../../../../../client/useUpload";
import {
  addImagesToOpeningFromFiles,
  setActiveImage,
} from "./editableOpeningReducer";
import { addMessage } from "../../../../shared/messageReducer";
import { useProjectDispatch } from "../../../projectStore";
import {
  useEditableOpeningDispatch,
  useEditableOpeningState,
} from "./EditableOpeningStore";
import { useProjectDesign } from "../../../useProjectState";
import { useOpeningBase } from "../useOpening";

let imageUploadAttempts = 0;
let imageUploadSucceeded = false;

export default function useEditableOpening() {
  const design = useProjectDesign();
  const {
    openingId,
    openingInputState: openingInput,
    activeImageId,
    highlightedImageId,
    zoomLevel,
  } = useEditableOpeningState();

  const useOpeningHook = useOpeningBase(
    getOpeningFeature(design, openingId),
    openingInput
  );
  const projectDispatch = useProjectDispatch();
  const dispatch = useEditableOpeningDispatch();
  const { upload, getUploadKey } = useUploadBase64Image();

  function createAddImagesAction(files: File[]) {
    return addImagesToOpeningFromFiles(
      files,
      useOpeningHook.printLayer,
      design,
      upload,
      getUploadKey,
      projectDispatch
    );
  }

  const uploadImage = () => {
    // Used for tracking if we've loaded anything yet to handle this bug a little better:
    // https://stackoverflow.com/questions/27000708/file-upload-control-not-working-in-facebook-in-app-browser
    imageUploadAttempts++;
    dispatch(setActiveImage(null));
    var input = document.createElement("input");
    input.multiple = true;
    input.type = "file";
    // We may want to change this to exclude .heic so iPhones will convert automatically. But for
    // now we use a library to do the conversion.
    input.accept = "image/*";
    input.addEventListener("change", async event => {
      // Used for tracking if we've loaded anything yet to handle this bug a little better:
      // https://stackoverflow.com/questions/27000708/file-upload-control-not-working-in-facebook-in-app-browser
      imageUploadSucceeded = true;
      const target = event.target as HTMLInputElement;
      console.log("UPLOAD");
      dispatch(createAddImagesAction(Array.from(target.files)));
      delete window["INPUT_REF"];
    });
    setTimeout(() => {
      // If the user has successfully added an image, or cancelled an image upload, then it works for them.
      if (imageUploadSucceeded) {
        return;
      }
      if (navigator.userAgent.match(/FB/)) {
        // This is Facebook
        projectDispatch(
          addMessage({
            id: "webview_bug",
            type: "warning",
            content: `Images not loading? This is due to a bug in the Facebook in-app browser. Until this bug is fixed you will need to open the website in your device's browser. Click the dots in the top right of your screen and select "Open in Chrome" or open your browser directly and navigate to ${window.location.host}.`,
            duration: 120,
          })
        );
      } else if (navigator.userAgent.includes("wv")) {
        projectDispatch(
          addMessage({
            id: "webview_bug",
            type: "warning",
            content: `Images not loading? This is most likely due to a bug in the Android in-app browser. Until this bug is fixed you will need to open the website in your device's browser. To do that open your browser directly and navigate to ${window.location.host}.`,
            duration: 120,
          })
        );
      } else if (imageUploadAttempts > 2) {
        projectDispatch(
          addMessage({
            id: "webview_bug",
            type: "info",
            content: `Images not loading? If you are using an in-app browser it could be due to a bug in that browser. Until this bug is fixed you will need to open the website in your device's browser. To do that open your browser directly and navigate to ${window.location.host}.`,
            duration: 120,
          })
        );
        // This seems noteworthy enough to log if they tried it twice and did not select anything.
        Sentry.captureMessage(
          "User attempted to upload file but the browser did not receive any file input events.",
          {
            extra: {
              ua: navigator.userAgent,
              imageUploadAttempts,
            },
          }
        );
      }
    }, 3000);
    input.click();
    window["INPUT_REF"] = input;
  };

  const getImageDepth = useCallback(
    (imageId: string) =>
      openingInput.images.findIndex(image => image.id === imageId),
    [openingInput.images]
  );

  const setActiveImageOnOpening = useCallback(
    (imageId: string) => {
      dispatch(setActiveImage(imageId));
    },
    [dispatch, setActiveImage, openingId]
  );

  const setActiveImageRelative = useCallback(
    (delta: number) => {
      const currentDepth = getImageDepth(activeImageId);
      const newActiveImage =
        openingInput.images[
          (currentDepth + delta) % openingInput.images.length
        ];
      dispatch(setActiveImage(newActiveImage.id));
    },
    [dispatch, setActiveImage, openingId, openingInput.images, activeImageId]
  );

  const activeImage = useMemo<OpeningImageInput>(() => {
    return openingInput.images.find(image => image.id === activeImageId);
  }, [openingInput.images, activeImageId]);

  const topImageIsActive = useMemo(
    () => getImageDepth(activeImageId) === openingInput.images.length - 1,
    [openingInput.images.length, activeImageId]
  );

  const bottomImageIsActive = useMemo(
    () => getImageDepth(activeImageId) === 0,
    [activeImageId]
  );

  return {
    ...useOpeningHook,
    openingInput: openingInput,
    uploadImage,
    createAddImagesAction,
    highlightedImageId,
    zoomLevel,
    activeImage,
    activeImageId,
    topImageIsActive,
    bottomImageIsActive,
    setActiveImage: setActiveImageOnOpening,
    // Note these increment backwards because the top image is the last in the images array.
    setActiveImagePrevious: () => setActiveImageRelative(1),
    setActiveImageNext: () => setActiveImageRelative(-1),
  };
}
