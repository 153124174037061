import { MapmakerBusinessId } from "@mapmaker/core";
import { createContext, useContext } from "react";

export type MapmakerSvgContextValue = {
  svgScale: number;
  businessId: MapmakerBusinessId | null;
  onImageLoadError?(url: string, hasDataUrl: boolean): any;
};

const MapmakerSvgContext = createContext<MapmakerSvgContextValue>({
  svgScale: 1,
  businessId: null,
});

export const MapmakerSvgContextProvider = MapmakerSvgContext.Provider;

export function useMapmakerSvgContext(): MapmakerSvgContextValue {
  return useContext(MapmakerSvgContext);
}
