import { useCallback } from "react";
import { useMapmakerAppConfig } from "../../..";
import { ProjectOutputType } from "../../../client/MapmakerApi";
import { trackGtmEvent } from "../../../lib/gtm";
import useLiveLocalStorage from "../../../lib/hooks/useLiveLocalStorage";
import { MapmakerPrintServiceId } from "../PrintServiceIds";
import {
  getPrintingService,
  getPrintServiceIdsForOutputType,
  IPrintService,
} from "../PrintServices";

export function usePreferredPrintingService(
  outputType: ProjectOutputType
): [IPrintService, (id: MapmakerPrintServiceId) => any] {
  const { primaryPrintServiceId } = useMapmakerAppConfig();

  const [
    preferredPrintingServiceId,
    _setPreferredPrintingServiceId,
  ] = useLiveLocalStorage<MapmakerPrintServiceId>(
    "mapmaker.preferredPrintingServiceId"
  );
  const [
    preferredPrintingServiceIdForOutputType,
    _setPreferredPrintingServiceIdForOutputType,
  ] = useLiveLocalStorage<MapmakerPrintServiceId>(
    `mapmaker.preferredPrintingServiceId.${outputType}`
  );

  // If this output type has a preference use that, otherwise use the global preference.
  const preferredPrintingService = getFirstAvailableService(outputType, [
    preferredPrintingServiceIdForOutputType,
    preferredPrintingServiceId,
    primaryPrintServiceId,
  ]);

  const setPreferredPrintingServiceId = useCallback(
    (id: MapmakerPrintServiceId) => {
      if (id === preferredPrintingServiceId) {
        return;
      }
      _setPreferredPrintingServiceId(id);
      _setPreferredPrintingServiceIdForOutputType(id);
      /*
      This is triggering way more than it should.
      trackGtmEvent({
        event: "mapmaker.set-preferred-printing-service",
        service: id,
      });
      */
    },
    [
      preferredPrintingServiceId,
      _setPreferredPrintingServiceId,
      _setPreferredPrintingServiceIdForOutputType,
    ]
  );

  return [preferredPrintingService, setPreferredPrintingServiceId];
}

/*
 * Given an output type and a list of preferences (most preferred first) return the first preferred
 * one that is available.
 */
function getFirstAvailableService(
  outputType: ProjectOutputType,
  ids: (MapmakerPrintServiceId | undefined)[]
): IPrintService {
  if (!outputType) {
    return getPrintingService(MapmakerPrintServiceId.DOWNLOAD);
  }
  const availableServiceIds = getPrintServiceIdsForOutputType(outputType);
  for (var id of ids) {
    if (id && availableServiceIds.includes(id)) {
      return getPrintingService(id);
    }
  }
  return getPrintingService(availableServiceIds[0]);
}
