import YouTube from "react-youtube";
import { SerializerProps } from "./shared";
import "./YouTubeSerializer.css";

export type YouTubeVideoProps = SerializerProps<{
  videoId: string;
}>;

export default function YouTubeSerializer({
  node: { videoId },
}: YouTubeVideoProps) {
  return (
    <YouTube
      videoId={videoId}
      iframeClassName="sanity-youtube-container"
      className="video"
    />
  );
}
