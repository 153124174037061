import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { IRectangularPrintState } from "./ReduxPrintStates";
import { Header, Icon, Message } from "semantic-ui-react";
import useRectangularPrintsSelector from "./useRectangularPrintsSelector";
import RectangularPrintList, {
  RectangularPrintListSlots,
} from "./RectangularPrintList";
import "./RectangularPrintMainPanel.css";
import MapmakerMessage from "../../shared/messages/MapmakerMessage";

export type IRectangularPrintListSlots = RectangularPrintListSlots & {
  header?: ReactNode;
  footer?: ReactNode;
};

interface IRectangularPrintListProps {
  slots: IRectangularPrintListSlots;
}

export default function RectangularPrintMainPanel({
  slots,
}: IRectangularPrintListProps) {
  const design = useSelector((state: IRectangularPrintState) => state.design);
  const {
    hasSelectedOpenings,
    unprintableOpeningIds,
    printSizeGroups,
  } = useRectangularPrintsSelector();

  function EmptyMessage() {
    if (printSizeGroups.length > 0) {
      return null;
    }

    var message: ReactNode;

    if (!hasSelectedOpenings) {
      message = (
        <>
          Select at least one {design.regionType} from the{" "}
          <strong>settings</strong> panel.
        </>
      );
    } else {
      message = (
        <>
          None of the prints fit on the selected paper size. Please choose a
          larger paper size.
        </>
      );
    }

    return (
      <Header as="h3" className="empty-message" textAlign="center">
        No {design.regionTypePlural} can be printed.
        <Header.Subheader>{message}</Header.Subheader>
      </Header>
    );
  }

  function IncompleteMessage() {
    const count = unprintableOpeningIds.length;
    if (count === 0 || printSizeGroups.length === 0) {
      return null;
    }

    return (
      <MapmakerMessage severity="warning" closeable={false}>
        {count === 1
          ? `One ${design.regionType} does not fit`
          : `${count} ${design.regionTypePlural} do not fit`}{" "}
        on to the selected paper size. Choose a larger paper size setting to
        accommodate {count === 1 ? "the larger print" : "these larger prints"}.
      </MapmakerMessage>
    );
  }

  return (
    <div id="rectangular-print-main-panel">
      <EmptyMessage />
      <IncompleteMessage />
      {printSizeGroups.length > 0 && (
        <>
          {slots["header"] || null}
          <RectangularPrintList slots={slots} />
          {slots["footer"] || null}
        </>
      )}
    </div>
  );
}
