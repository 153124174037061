import { Field } from "formik";
import { RenderableInputProps } from ".";
import { useValidateRenderableInput } from "./renderableInputUtils";
import "./RenderableInput.css";

export type RenderableStringInputProps = RenderableInputProps<"string", string>;

export default function RenderableStringInput({
  id,
  name,
  description,
  validation,
}: RenderableStringInputProps) {
  const validate = useValidateRenderableInput(id, validation);

  return (
    <Field type="string" name={id} validate={validate}>
      {({ field, meta }) => (
        <div
          id="renderable-input"
          className={meta.touched && meta.error ? "error" : undefined}
        >
          <div className="above">
            <label>{name}</label>
          </div>
          <input type="text" {...field} />
          {description && <div className="description">{description}</div>}
          {meta.touched && meta.error && meta.value && (
            <div className="error">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
}
