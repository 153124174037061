import { viewBoxForBBox } from "../../../../lib/svg/ViewboxUtils";
import { SvgContextProvider } from "../../../../lib/svg/useSvgContext";
import useOpening from "./useOpening";
import useMapmakerModals from "../../../shared/modals/useMapmakerModals";
import { useProjectFile } from "../../useProjectState";
import ReadOnlyOpening from "./ReadOnlyOpening";
import MapmakerLayout from "../../../layout/MapmakerLayout";
import MapmakerTopBar from "../../../layout/MapmakerTopBar";
import ProjectTopBarButton from "../../ProjectTopBarButton";
import OpeningViewLayout from "./OpeningViewLayout";
import MapmakerToolbar from "../../shared/toolbar/MapmakerToolbar";
import MapmakerToolbarButton from "../../shared/toolbar/MapmakerToolbarButton";
import { useMapmakerAppConfig } from "../../../../client";
import OpeningEditorViewToolbar from "./OpeningEditorViewToolbar";
import FeatureExitButton from "../../shared/FeatureExitButton";

type CollaboratorOpeningViewProps = {
  openingId: string;
};

export default function CollaboratorOpeningView({
  openingId,
}: CollaboratorOpeningViewProps) {
  const { gotoEditFile } = useMapmakerAppConfig();
  const { setModalRoot } = useMapmakerModals();
  const file = useProjectFile();
  const { openingFeature: opening, hasImages } = useOpening(openingId);
  const bbox = opening.outlined
    ? opening.layout.cut.bbox
    : opening.layout.print?.bbox ||
      opening.layout.outer?.bbox ||
      opening.layout.cut.bbox;
  const viewbox = viewBoxForBBox(bbox, "3%");

  if (!hasImages) {
    gotoEditFile(file.id, "collaborate", openingId, "new");
    return null;
  }

  return (
    <MapmakerLayout>
      <MapmakerLayout.TopBar>
        <MapmakerTopBar
          breadcrumbs={[{ label: opening.name }]}
          after={<ProjectTopBarButton />}
        />
      </MapmakerLayout.TopBar>
      <MapmakerLayout.Main style={{ overflowY: "hidden" }}>
        <OpeningViewLayout>
          <MapmakerToolbar>
            <MapmakerToolbarButton
              icon="lightbulb"
              label="Suggest"
              action={() =>
                gotoEditFile(file.id, "collaborate", openingId, "new")
              }
            />
          </MapmakerToolbar>
          <OpeningViewLayout.Main innerRef={setModalRoot}>
            <FeatureExitButton
              onClick={() => gotoEditFile(file.id, "collaborate")}
            />
            <SvgContextProvider viewbox={viewbox} flex>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewbox.join(" ")}
                width="100%"
                height="100%"
                style={{
                  // react-dropzone adds a tabIndex so this keep it from getting styled funny.
                  outline: "none",
                  // Prevents touch events from doing things like refreshing the browser.
                  touchAction: "none",
                }}
              >
                <ReadOnlyOpening openingId={openingId} />
              </svg>
            </SvgContextProvider>
          </OpeningViewLayout.Main>
        </OpeningViewLayout>
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}
