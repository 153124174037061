import { Button, TinyText } from "@mapmaker/ui";
import { useState } from "react";
import clsx from "clsx";
import { useMapmakerAppConfig } from "../../client";
import { saveFile } from "./fileReducer";
import useLatest from "react-use/lib/useLatest";
import { getPrintServicesForOutputType } from "../printing/PrintServices";
import { useAddToastMessage } from "./shared/toasts/useProjectToastMessages";
import useProjectState, { useProjectUploads } from "./useProjectState";
import { Dropdown } from "semantic-ui-react";
import { FaEye } from "@react-icons/all-files/fa/FaEye";
import { FaUsers } from "@react-icons/all-files/fa/FaUsers";
import { usePreferredPrintingService } from "../printing/shared/usePreferredPrintingService";
import { MapmakerPrintServiceId } from "../..";
import MapmakerMessage from "../shared/messages/MapmakerMessage";
import { useProjectDispatch } from "./projectStore";
import { MapmakerFileViewerPolicy } from "../../client/MapmakerApi";
import { MapmakerTopBarMode } from "../layout/MapmakerTopBar";
import "./ProjectTopBarButton.css";

type ProjectTopBarButtonProps = {
  mode?: MapmakerTopBarMode;
};

export default function ProjectTopBarButton({
  mode,
}: ProjectTopBarButtonProps) {
  const dispatch = useProjectDispatch();
  const { addToastWarning } = useAddToastMessage();
  const { file, numOpeningsWithImages } = useProjectState();
  const { uploading } = useProjectUploads();
  const latestFile = useLatest(file);
  const latestUploading = useLatest(uploading);
  const { gotoCreatePrints, gotoSelectPrintService } = useMapmakerAppConfig();
  const [loading, setLoading] = useState(false);
  const [
    preferredPrintingService,
    setPreferredPrintingService,
  ] = usePreferredPrintingService(file.outputType);
  const availablePrintServices = getPrintServicesForOutputType(file.outputType);
  const hasMultiplePrintOptions = availablePrintServices.length > 1;

  function showNoPhotosAddedMessage() {
    addToastWarning(
      `You need to add a photo to at least one ${file.design.regionType} first.`,
      "no-photos-added"
    );
  }

  const proceedToPrinting = async (
    printServiceId: MapmakerPrintServiceId,
    saveBeforeProceeding: boolean = false
  ) => {
    // Once we click it we'll make this the preferred service
    setPreferredPrintingService(printServiceId);

    if (latestUploading.current) {
      // If we're currently waiting on uploads, check again in 100ms
      setLoading(true);
      setTimeout(() => proceedToPrinting(printServiceId), 100);
    } else if (saveBeforeProceeding) {
      // On the actual button click we want to kick off a file save (which will only trigger if
      // there are changes to the inputs) and then proceed
      dispatch(saveFile(true));
      setTimeout(() => proceedToPrinting(printServiceId), 100);
    } else if (latestFile.current.saving) {
      // If we're currently saving the file check again in 100ms
      setLoading(true);
      setTimeout(() => proceedToPrinting(printServiceId), 100);
    } else if (numOpeningsWithImages === 0) {
      showNoPhotosAddedMessage();
      setLoading(false);
    } else {
      // We're not waiting, saving, and we do not need to save
      gotoCreatePrints(printServiceId, file.id);
    }
  };

  const disabled = !file.id || numOpeningsWithImages === 0;

  // If we're not the owner we just render a simple message showing our access level.
  switch (file.viewerPolicy) {
    case MapmakerFileViewerPolicy.Collaborator:
      // If we're viewing our suggestions just say that.
      if (mode === "suggestions") {
        return (
          <div className="top-bar-viewing-mode">
            <FaEye fontSize={16} /> Your Suggestions
          </div>
        );
      } else {
        return (
          <div className="top-bar-access-identifier collaborator">
            <FaUsers fontSize={28} />
            <div>
              <TinyText>YOU ARE A</TinyText>
              <div>Collaborator</div>
            </div>
          </div>
        );
      }
    case MapmakerFileViewerPolicy.Viewer:
      return (
        <div className="top-bar-access-identifier viewer">
          <FaEye fontSize={28} />
          <div>
            <TinyText>YOU ARE A</TinyText>
            <div>Viewer</div>
          </div>
        </div>
      );
  }

  return (
    <div id="project-top-bar-button">
      <Button
        className={clsx({
          topBarButton: true,
          isMenuButton: hasMultiplePrintOptions,
        })}
        loading={loading}
        color="accent"
        onClick={() => proceedToPrinting(preferredPrintingService.id, true)}
        onClickWhileDisabled={showNoPhotosAddedMessage}
        disabled={disabled}
        style={{}}
      >
        <div>{preferredPrintingService.directive}</div>
        {hasMultiplePrintOptions && (
          <Dropdown
            floating
            pointing="top right"
            className="dropdown-button"
            compact
            trigger={<></>}
          >
            <Dropdown.Menu className="dropdown-menu">
              {numOpeningsWithImages === 0 ? (
                <div style={{ marginTop: "1em" }}>
                  <MapmakerMessage severity="info" closeable={false}>
                    Add a photo to at least one {file.design.regionType} to
                    print.
                  </MapmakerMessage>
                </div>
              ) : (
                <Dropdown.Header>
                  <div style={{ textAlign: "center", fontSize: "1.2em" }}>
                    How do you want to print your photos?
                  </div>
                </Dropdown.Header>
              )}
              <Dropdown.Divider />
              {availablePrintServices.map(printService => {
                return (
                  <Dropdown.Item
                    key={printService.id}
                    className="print-service-item"
                    onClick={() => proceedToPrinting(printService.id, true)}
                  >
                    <div className="icon">
                      <img src={printService.imageSmall} />
                    </div>
                    <div className="text">
                      <div className="name">{printService.directive}</div>
                      <div className="description">
                        {printService.description}
                      </div>
                    </div>
                  </Dropdown.Item>
                );
              })}
              <Dropdown.Divider />
              <div style={{ textAlign: "center", margin: "1em" }}>
                <a
                  className="link"
                  onClick={() => {
                    if (disabled) {
                      showNoPhotosAddedMessage();
                    } else {
                      gotoSelectPrintService(file.id);
                    }
                  }}
                >
                  Compare the Options
                </a>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Button>
    </div>
  );
}
