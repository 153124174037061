import { useEffect } from "react";
import useLatest from "./useLatest";

type UseDelayedCallback = () => any;

export default function useDelayed(
  callback: UseDelayedCallback,
  delayMs: number
) {
  const callbackRef = useLatest(callback);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      callbackRef.current();
    }, delayMs);

    return () => {
      clearInterval(timeoutId);
    };
  });
}
