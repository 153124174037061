import * as yup from "yup";
import { RenderableForm } from ".";

export const OpeningStickerPreviewSvgForm: RenderableForm = {
  initialValues: {},
  inputs: [
    {
      type: "file",
      id: "file",
      name: "Project",
      validation: yup.string().required(),
    },
    {
      type: "file-opening",
      id: "openingId",
      name: "Region",
      validation: yup.string().required(),
      fileProperty: "file",
    },
  ],
};
