import type { MapmakerConfig } from ".";

const config:MapmakerConfig = {
  stage: "dev",
  dataStage: "dev",
  apiGateway: {
    url: "https://api-dev.photomaps.com",
  },
  contentUrl: "https://content-dev.photomaps.com",
  userContentUrl: "https://user-content-direct-dev.photomaps.com",
  userContentImageServerUrl: "https://user-content-dev.photomaps.com",
  staticUrl: "https://static-dev.photomaps.com",
  imageServerBucket: "mapmaker-user-content-dev",
  internalBucket: "mapmaker-internal-dev",
  walgreens: {
    production: false,
    apiKey: "DyvrlukqmWDQQW3XKGmqVKtHl6YFHejX",
    affiliateId: "THUNDERLAB",
    publisherId: "8972131",
  },
};
export default config;