import React from "react";
import {
  getMapmakerStaticUrl,
  getOpeningsWithInputs,
  MapmakerBusinessId,
  OpeningFeature,
  OpeningInput,
} from "@mapmaker/core";
import { MapmakerSvgContextProvider } from "../MapmakerSvgContext";
import SvgNode from "../SvgNode";
import { RegionCompletionSvgProps } from "@mapmaker/renderable";
import {
  getLayerByPriority,
  getResizedImageUrl,
  OpeningGroup,
  OpeningImages,
} from "../opening";
import Backdrop from "../shared/Backdrop";
import OpeningDropShadowFilter from "../opening/OpeningDropShadowFilter";
import Minimap from "./minimap/Minimap";
import Watermark from "./watermark/Watermark";

const DEFAULT_WIDTH = 1080;
const DEFAULT_HEIGHT = 1350;

/*
 * Renders an SVG showing the photos in every opening of the blueprint,
 * and optionally borders around those openings.
 */
export default function RegionCompletionSvg({
  file,
  openingId,
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  background = "none",
  responsive = false,
}: RegionCompletionSvgProps<"resource">) {
  const viewBox = [0, 0, width, height];

  const opening: OpeningFeature = file.design.features[
    openingId
  ] as OpeningFeature;
  const timelineOpeningsAll = getOpeningsWithInputs(file.design, file, {
    mustCountTowardProgress: true,
    sort: "timeline",
  });
  const openingTimelineIndex = timelineOpeningsAll.findIndex(
    (timelineOpening) => timelineOpening.featureId === openingId
  );
  const timelineOpeningsUntilCurrent = timelineOpeningsAll.slice(
    0,
    openingTimelineIndex + 1
  );
  const openingInput = file.inputs[openingId] as OpeningInput;
  const outerLayer = getLayerByPriority(
    opening,
    opening.outlined ? "print" : ["cut", "inner"]
  );

  const regionsCompleted = timelineOpeningsUntilCurrent.length;

  function Background() {
    switch (background) {
      case "corkboard":
        {
          /** Background */
        }
        return (
          <foreignObject width="100%" height="100%">
            <div
              style={{
                filter: "brightness(0.7)",
                width,
                height,
                backgroundImage: `url(${getMapmakerStaticUrl(
                  "svg/components/social/region-completion-backgrounds/corkboard.jpg"
                )})`,
                backgroundRepeat: "repeat",
              }}
            />
          </foreignObject>
        );
      case "wood":
        return (
          <foreignObject width="100%" height="100%">
            <div
              style={{
                width,
                height,
                backgroundImage: `url(${getMapmakerStaticUrl(
                  "svg/components/social/region-completion-backgrounds/wood.jpg"
                )})`,
                backgroundRepeat: "repeat",
              }}
            />
          </foreignObject>
        );
      case "chalkboard":
        return (
          <foreignObject width="100%" height="100%">
            <div
              style={{
                width,
                height,
                backgroundImage: `url(${getMapmakerStaticUrl(
                  "svg/components/social/region-completion-backgrounds/chalkboard.jpg"
                )})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
          </foreignObject>
        );
      case "contextual":
        if (openingInput.images[0]) {
          const firstImageUrl = getResizedImageUrl(
            openingInput.images[0].id,
            256,
            256
          );
          return (
            <>
              {/* This fancy filter blurs the image and darkens it while retaining the full size. */}
              <defs>
                <filter
                  id="rcg-contextual-blur"
                  x="0%"
                  y="0%"
                  width="100%"
                  height="100%"
                >
                  <feGaussianBlur dx={0} dy={0} stdDeviation={40} />
                  <feComponentTransfer>
                    <feFuncA type="table" tableValues="1 1" />
                    <feFuncR
                      type="gamma"
                      amplitude="0.4"
                      exponent="1"
                      offset="0.0"
                    />
                    <feFuncG
                      type="gamma"
                      amplitude="0.4"
                      exponent="1.0"
                      offset="0.0"
                    />
                    <feFuncB
                      type="gamma"
                      amplitude="0.4"
                      exponent="1.0"
                      offset="0.0"
                    />
                  </feComponentTransfer>
                </filter>
              </defs>
              <image
                xlinkHref={firstImageUrl}
                x="0"
                y="0"
                width="100%"
                height="100%"
                preserveAspectRatio="xMidYMid slice"
                filter="url(#rcg-contextual-blur)"
              />
            </>
          );
          /* 
            <foreignObject width="100%" height="100%">
              <div
                style={{
                  // Funny width/margins are to avoid whitening around the edges from blurring with
                  nothing
                  width: width * 1.2,
                  height: height * 1.2,
                  marginLeft: -width * 0.1,
                  marginTop: -width * 0.1,
                  backgroundImage: `url(${firstImageUrl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              />
            </foreignObject>
              */
        }
      case "none":
        return <Backdrop width={width} height={height} color="#FFFFFF" />;
    }
  }

  return (
    <svg
      id="region-completion-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox.join(" ")}
      width={responsive ? undefined : width}
      height={responsive ? undefined : height}
    >
      <MapmakerSvgContextProvider
        value={{
          svgScale: 1,
          businessId: file.design.businessId as MapmakerBusinessId,
        }}
      >
        <Background />
        {/** Main Image */}
        <OpeningGroup
          opening={opening}
          x={0.5 * width}
          y={0.325 * height}
          width={width * 0.9}
          height={height * 0.6}
          layer={outerLayer}
          centered
          rotation={0}
        >
          <defs>
            <filter id={`drop-shadow-rcg-${openingId}`}>
              <OpeningDropShadowFilter dx={2} dy={4} blur={8} />
            </filter>
          </defs>
          {/** White background with drop shadow */}
          <SvgNode
            nodeData={outerLayer}
            style={{
              stroke: "#F0F8F8",
              vectorEffect: "non-scaling-stroke",
              strokeWidth: 10,
              fill: "#FFFFFF",
              filter: `url(#drop-shadow-rcg-${openingId})`,
            }}
          />

          {/** Solid background fill for image - if there is one. */}
          {openingInput?.background && (
            <SvgNode
              nodeData={outerLayer}
              style={{ fill: openingInput?.background }}
            />
          )}

          {/** Printed images */}
          <OpeningImages
            clipLayer={outerLayer}
            opening={opening}
            openingInput={openingInput}
          />
          {/** Subtle outline to help visualize the edge of the cut */}
        </OpeningGroup>
        <g transform={`translate(0, ${height * 0.78})`}>
          <rect
            x={0}
            y={-height * 0.13}
            width={width}
            height={height * 0.26}
            fill="#FFFFFF"
            opacity={1}
          />
          <defs>
            <filter id={`drop-shadow-minimap-${openingId}`}>
              <OpeningDropShadowFilter dx={0} dy={2} blur={4} />
            </filter>
          </defs>
          <g style={{ filter: `url(#drop-shadow-minimap-${openingId})` }}>
            <Minimap
              file={file}
              width={width * 0.5}
              height={height * 0.28}
              x={width * 0.28}
              incompleteColor="#F8F8F8"
              openingIds={timelineOpeningsUntilCurrent.map((o) => o.featureId)}
            />
          </g>
          <style>
            {/* This is only for client-side rendering. Make sure to add the font to the @mapmaker/api/font folder too. */}
            {`@import url('https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps&display=swap');`}
          </style>
          <text
            fill="#333333"
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="'Oleo Script Swash Caps', cursive"
            x={width * 0.73}
            y={-10}
          >
            <tspan
              style={{
                fontSize: file.design.regionCount >= 100 ? "90pt" : "125pt",
              }}
            >
              {regionsCompleted}{" "}
            </tspan>
            <tspan style={{ fontSize: "45pt" }}>of</tspan>
            <tspan
              style={{
                fontSize: file.design.regionCount >= 100 ? "90pt" : "125pt",
              }}
            >
              {" "}
              {file.design.regionCount}
            </tspan>
          </text>
          <text
            fill="#333333"
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily="'Open Sans', sans-serif"
            x={width * 0.73}
            y={80}
          >
            <tspan style={{ fontSize: "30pt" }}>
              {file.design.regionTypePlural} complete!
            </tspan>
          </text>
        </g>

        {/** Call to Action */}
        <Watermark x={width * 0.5} y={height - 60} height={30} />
      </MapmakerSvgContextProvider>
    </svg>
  );
}
