import { gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { Button } from "@mapmaker/ui";
import OrderLineItem from "./OrderLineItem";
import {
  OrderItemDetailsFragment,
  StickerOrderForStickerLineItemFragment,
} from "../../client/MapmakerApi";
import { StickerTokenIcon } from "../shared/icons/StickerTokenIcon";
import { useMapmakerAppConfig } from "../..";
import { getOutputTypeDetails } from "../project/shared/OutputTypeLibrary";
import "./StickerLineItem.css";

gql`
  fragment StickerOrderForStickerLineItem on StickerOrder {
    id
    outputType
    stickers {
      openingId
      quantity
      render {
        url
      }
    }
    file {
      id
      backupVersion
      name
    }
  }
`;

export type StickerLineItemType = Extract<
  OrderItemDetailsFragment,
  { __typename?: "StickerLineItem" }
>;

type StickerLineItemProps = {
  items: StickerLineItemType[];
  stickerOrder?: StickerOrderForStickerLineItemFragment;
};

export default function StickerLineItem({
  items,
  stickerOrder,
}: StickerLineItemProps) {
  const { gotoEditFile } = useMapmakerAppConfig();
  const mapId = stickerOrder?.file.id;
  const totalTokensUsed = items.reduce(
    (tokensUsed, item) => tokensUsed + item.tokensUsed,
    0
  );
  const originalUnitPrice = items.reduce(
    (sum, item) => sum + item.originalUnitPrice * item.quantity,
    0
  );
  const typeDetails = getOutputTypeDetails(stickerOrder?.outputType);

  return (
    <OrderLineItem
      className="sticker-line-item"
      name={`${
        stickerOrder?.stickers.length === 1
          ? typeDetails?.lineItemName
          : typeDetails?.lineItemNamePlural
      } for ${stickerOrder?.file.name}`}
      quantity={1}
      productLink={`/p/${mapId}`}
      originalUnitPrice={originalUnitPrice}
      attributes={undefined}
    >
      {totalTokensUsed > 0 && (
        <p>
          <StickerTokenIcon />
          {totalTokensUsed === 1
            ? " 1 Sticker Token was"
            : ` ${totalTokensUsed} Sticker Tokens were`}{" "}
          used for this order.
        </p>
      )}
      {/** If we don't have a sticker order just put a nice message up. There are a few reasons
       * this may happen, none of which are particularly alarming to me... right now :) */
      !stickerOrder && (
        <p>
          Error retrieving sticker images. Please{" "}
          <Link to="/contact">contact us</Link> if the problem persists.
        </p>
      )}
      <div className="stickers">
        {stickerOrder?.stickers.map(sticker => {
          const prettyFakeName = sticker.openingId.replace(/([A-Z])/g, " $1");
          return (
            <span
              key={sticker.render.url}
              className="sticker-thumbnail"
              title={prettyFakeName}
            >
              <img
                height="128"
                src={sticker.render.url}
                alt={`Sticker of ${sticker.openingId}`}
              />
              {sticker.quantity > 1 ? (
                <span className="qty">×{sticker.quantity}</span>
              ) : null}
            </span>
          );
        })}
      </div>
      <Button
        onClick={() => gotoEditFile(stickerOrder.file.id)}
        outlined
        size="small"
        style={{ marginTop: "4px", marginLeft: 0 }}
      >
        View This Map
      </Button>
    </OrderLineItem>
  );
}
