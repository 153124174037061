import { RegionCompletionSvgProps } from "@mapmaker/renderable";
import { RegionCompletionSvg } from "@mapmaker/svg";
import { Button } from "@mapmaker/ui";
import format from "date-fns/format";
import { useMemo, useState } from "react";
import {
  DropdownItemProps,
  Icon,
  ModalActions,
  ModalContent,
  Select,
} from "semantic-ui-react";
import { useMapmakerAppConfig } from "../../../../client";
import MapmakerModal from "../../../shared/modals/MapmakerModal";
import useTimelineBundle from "../../timeline/useTimelineBundle";
import { useProjectFile } from "../../useProjectState";
import { ShareRenderableButton } from "../shared/ShareRenderableButton";
import "./RegionCompletionSharingModal.css";

type RegionCompletionSharingModalProps = {
  open: boolean;
  onClose(): any;
};

export default function RegionCompletionSharingModal({
  open,
  onClose,
}: RegionCompletionSharingModalProps) {
  const file = useProjectFile();
  const { gotoEditFile } = useMapmakerAppConfig();

  const [background, setBackground] = useState<
    RegionCompletionSvgProps<"ref">["background"]
  >("contextual");

  const timeline = useTimelineBundle({
    filters: {
      mustCountTowardProgress: true,
    },
  });
  const [openingId, setOpeningId] = useState<string>(
    timeline[timeline.length - 1].openingId
  );
  const timelineIndex = timeline.findIndex(t => t.openingId === openingId);
  const opening = file.design.features[openingId];
  const timelineIsAccurate = useMemo<boolean>(
    () => timeline.every(opening => opening.range.accuracy === "ACCURATE"),
    [timeline]
  );
  return (
    <MapmakerModal open={open} onClose={onClose} title="Share Your Progress!">
      <ModalContent id="region-completion-sharing-modal">
        <div className="image-container">
          <RegionCompletionSvg
            // The key is important otherwise React may keep stale SVG nodes for a short time.
            key={openingId}
            file={file}
            openingId={openingId}
            background={background}
            responsive
          />
        </div>
        <div className="options">
          {!timelineIsAccurate && (
            <div className="timeline-message">
              <Icon name="info circle" size="big" />
              <p>
                This project's timeline has been estimated and may not be
                accurate.{" "}
                <span className="link" onClick={onClose}>
                  Edit your timeline.
                </span>
              </p>
            </div>
          )}
          <div className="main">
            <h2>Options</h2>
            <label>{file.design.regionType}</label>
            <Select
              value={openingId}
              fluid
              className="select state-select"
              onChange={(_, { value }) => setOpeningId(value as string)}
              options={timeline.flatMap<DropdownItemProps>((opening, i) => {
                const divider: DropdownItemProps[] = opening.isFirstOfYear
                  ? [
                      {
                        // Value gets used as the React key, that's the only reason we include it
                        // at all.
                        value: `year-${opening.year}`,
                        disabled: true,
                        label: (
                          <div className="year-divider">{opening.year}</div>
                        ),
                      },
                    ]
                  : [];
                return [
                  ...divider,
                  {
                    value: opening.openingId,
                    text: (
                      <div className="opening-option">
                        <span className="number">{i + 1}.</span>
                        <span className="name">
                          {file.design.features[opening.openingId].name}
                        </span>
                        <span className="date">
                          {format(new Date(...opening.range.first), "PPP")}
                        </span>
                      </div>
                    ),
                  },
                ];
              })}
            />
            <label>Style</label>
            <Select
              value={background}
              fluid
              className="select state-select"
              onChange={(_, { value }) =>
                setBackground(
                  value as RegionCompletionSvgProps<"ref">["background"]
                )
              }
              options={[
                {
                  value: "contextual",
                  text: "Default",
                },
                {
                  value: "chalkboard",
                  text: "Dark",
                },
                {
                  value: "wood",
                  text: "Wood",
                },
                {
                  value: "corkboard",
                  text: "Corkboard",
                },
              ]}
            />
          </div>
        </div>
      </ModalContent>
      <ModalActions>
        <Button onClick={() => onClose()}>Done</Button>{" "}
        <ShareRenderableButton
          shareText={`✅  ${opening.name} - ${timelineIndex + 1}/${
            file.design.regionCount
          } ${file.design.regionTypePlural} complete!`}
          renderableProps={{
            type: "region_completion",
            file: { FILE: file.id },
            openingId,
            background,
          }}
        />
      </ModalActions>
    </MapmakerModal>
  );
}
