import * as yup from "yup";
import { RenderableForm } from ".";

export const DebugSvgForm: RenderableForm = {
  initialValues: {},
  inputs: [
    {
      type: "design",
      id: "design",
      name: "Design",
      validation: yup.string().required(),
    },
  ],
};
