import { Link, useParams } from "react-router-dom";
import { MapmakerHelpPage } from "@mapmaker/frontend";
import { CardPage } from "@mapmaker/ui";
import styles from "./HelpPage.module.scss";

const helpUrlCreator = (slug: string | undefined | null) =>
  slug ? `/help/${slug}` : "/help";

export default function HelpPage() {
  const { slug = "help-root" } = useParams<{ slug: string }>();
  return (
    <CardPage
      footer={
        <div className={styles.footer}>
          Trouble finding what you need? <Link to="/contact">Contact Us!</Link>
        </div>
      }
    >
      <MapmakerHelpPage slug={slug} urlCreator={helpUrlCreator} />
    </CardPage>
  );
}
