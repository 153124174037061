import { getOpeningInput } from "@mapmaker/core";
import { useMemo } from "react";
import {
  MapmakerFileViewerPolicy,
  OpeningSuggestionStatus,
} from "../../../../../client/MapmakerApi";
import { useProjectFile } from "../../../useProjectState";
import {
  compareSuggestionsModified,
  compareSuggestionsSubmitted,
} from "./useSuggestion";
import { getSuggestionAcceptance } from "./useSuggestionAcceptance";

type UseProjectOpeningSuggestionsProps = {
  includeHidden?: boolean;
  includeCurrentlyInMap?: boolean;
  includeSubmitted?: boolean;
  openingId?: string;
  suggesterId?: string;
  suggesterEmail?: string;
};

/**
 * Convenient way to get data about the opening suggestions in the current project.
 */
export default function useProjectOpeningSuggestions({
  includeHidden = false,
  includeCurrentlyInMap = false,
  includeSubmitted = true,
  openingId,
  suggesterId,
}: UseProjectOpeningSuggestionsProps = {}) {
  const file = useProjectFile();
  const isOwner = file.viewerPolicy === MapmakerFileViewerPolicy.Owner;
  const openingSuggestions = useMemo(
    () =>
      file.openingSuggestions
        .filter(suggestion => {
          if (openingId && suggestion.openingId !== openingId) {
            return false;
          }
          if (suggesterId && suggestion.suggesterId !== suggesterId) {
            return false;
          }
          if (
            includeHidden === false &&
            suggestion.status === OpeningSuggestionStatus.Hidden
          ) {
            return false;
          }
          if (
            includeSubmitted === false &&
            suggestion.status === OpeningSuggestionStatus.Submitted
          ) {
            return false;
          }
          if (includeCurrentlyInMap === false) {
            const openingInput = getOpeningInput(file, suggestion.openingId);
            const inclusionStatus = getSuggestionAcceptance(
              suggestion,
              openingInput
            ).inclusionStatus;
            if (inclusionStatus === "all-in-map-exact") {
              return false;
            }
          }
          return true;
        })
        .sort(
          isOwner ? compareSuggestionsSubmitted : compareSuggestionsModified
        ),
    [
      file.openingSuggestions,
      includeHidden,
      includeCurrentlyInMap,
      includeSubmitted,
      suggesterId,
      openingId,
      isOwner,
    ]
  );

  return openingSuggestions;
}
