import { ReactNode, useState } from "react";
import clsx from "clsx";
import { Header, SemanticCOLORS, Icon } from "semantic-ui-react";
import useContextPanel from "./useContextPanel";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import "./ContextPanelSection.css";

// There are some extra props here which are used by the context panel when it generates separate
// buttons for each section.
export interface IContextPanelSectionProps {
  children: ReactNode;
  sectionId: string;
  icon: SemanticICONS;
  label: string;
  shortLabel?: string;
  active?: boolean;
  primary?: boolean;
  hideTitleBar?: boolean;
  initialContentOpen?: boolean;
  disabled?: boolean;
  onMenuButtonClick?: () => any;
  badgeColor?: SemanticCOLORS;
  badgeNumber?: number;
}

export default function ContextPanelSection({
  children,
  sectionId,
  label,
  primary = false,
  hideTitleBar = false,
  initialContentOpen = true,
}: IContextPanelSectionProps) {
  const { openSectionId, closeSection } = useContextPanel();
  const [contentOpen, setContentOpen] = useState(initialContentOpen);
  const open = openSectionId === sectionId;

  if (!children) {
    return null;
  }

  return (
    <div
      id="context-panel-section"
      className={clsx({
        [sectionId + "-section"]: true,
        primary,
        open: open,
        closed: !open,
        "hide-title-bar": hideTitleBar,
        "content-open": contentOpen,
      })}
    >
      <div className="title-bar" onClick={() => setContentOpen(!contentOpen)}>
        <Header sub className="section-label">
          {label}
        </Header>
        <Icon
          className={clsx({
            "toggle-btn": true,
            plus: !contentOpen,
            minus: contentOpen,
          })}
        />
        <div className="exit-btn" onClick={closeSection}>
          ✕
        </div>
      </div>
      <div
        className={clsx({
          "section-content": true,
        })}
      >
        {children}
      </div>
    </div>
  );
}
