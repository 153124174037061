import { BoundingBox, Viewbox } from "@mapmaker/core";

export function viewBoxForBBox(
  bbox: BoundingBox,
  displayWidth: number,
  displayHeight: number,
  displayPadding: number = 0
): Viewbox {
  const paddingX = (displayPadding / displayWidth) * bbox.width;
  const paddingY = (displayPadding / displayHeight) * bbox.height;

  const displayRatio = displayWidth / displayHeight;
  const bboxRatio = bbox.width / bbox.height;

  const minWidth = bbox.width + paddingX * 2;
  const minHeight = bbox.height + paddingY * 2;

  let viewWidth;
  let viewHeight;
  if (displayRatio > bboxRatio) {
    viewHeight = minHeight;
    viewWidth = viewHeight * displayRatio;
  } else {
    viewWidth = minWidth;
    viewHeight = viewWidth / displayRatio;
  }

  return [
    bbox.x - (viewWidth - bbox.width) / 2,
    bbox.y - (viewHeight - bbox.height) / 2,
    viewWidth,
    viewHeight,
  ];
}
