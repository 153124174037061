import useMeasure from "react-use-measure";
import { useMemo, useRef, useState } from "react";
import { ResizeObserver } from "@juggle/resize-observer";
import useInterval from "./useInterval";

type SvgScaleResult = [(element: HTMLElement) => void, number];

/*
Uses a combination of useM<easure and polling the element for it's height to return the current
scale.  If useMeasure worked better for SVG's we wouldn't need to poll.
*/
export default function useSvgScale(
  viewBox: number[],
  pollInterval: number = 1000
): SvgScaleResult {
  const [measureRef, measureBounds] = useMeasure({
    debounce: 300,
    polyfill: ResizeObserver,
  });
  const polledRef = useRef<Element>();
  const mergedRef = r => {
    measureRef(r);
    polledRef.current = r;
  };

  const [polledBounds, setPolledBounds] = useState<DOMRect>();
  useInterval(() => {
    const newBounds = polledRef.current?.getBoundingClientRect();
    if (newBounds) {
      if (
        newBounds.width !== polledBounds?.width ||
        newBounds.height !== polledBounds?.height
      ) {
        setPolledBounds(newBounds);
      }
    }
  }, pollInterval);

  const scale = useMemo(() => {
    const measureScale = Math.min(
      measureBounds.width / viewBox[2],
      measureBounds.height / viewBox[3]
    );
    const polledScale = Math.min(
      (polledBounds?.width || 0) / viewBox[2],
      (polledBounds?.height || 0) / viewBox[3]
    );
    return Math.max(measureScale, polledScale);
  }, [measureBounds, polledBounds, viewBox]);

  return [mergedRef, scale];
}
