import { getFeatures, OpeningFeature } from "@mapmaker/core";
import { DebugSvgProps } from "@mapmaker/renderable";
import React from "react";
import DebugOpening from "./DebugOpening";

/*
 * Renders an SVG showing useful information about a design.
 */
export default function DebugSvg({ design }: DebugSvgProps<"resource">) {
  const openings = getFeatures<OpeningFeature>(design, "OPENING");

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={[0, 0, design.width, design.height].join(" ")}
    >
      {/* Background */}
      <rect
        x={0}
        y={0}
        width={design.width}
        height={design.height}
        stroke="#000000"
        fill="#EEEEEE"
      />

      {Object.entries(openings).map(([openingId, opening]) => {
        return <DebugOpening key={openingId} opening={opening} />;
      })}
    </svg>
  );
}
