import { Link, LinkProps } from "react-router-dom";

type MaybeLinkProps = Omit<LinkProps, "to"> & {
  to?: LinkProps["to"];
};

export default function MaybeLink(props: MaybeLinkProps) {
  if (props.to) {
    return <Link {...props} to={props.to} />;
  } else {
    return <>{props.children}</>;
  }
}
