import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import PrintQuantitiesSelector from "./PrintQuantitiesSelector";
import ContextPanelSelect from "../../project/context/shared/ContextPanelSelect";
import { PrintSizeModeId } from "../../../lib/prints/printSizes";
import { setPackingMode, setPrintSizeModeId } from "./printSizeSettingsReducer";
import { PackingMode } from "../../../lib/prints/openingPacker";
import { IRectangularPrintState } from "./ReduxPrintStates";
import useRectangularPrintsSelector from "./useRectangularPrintsSelector";

export default function RectangularPrintSettings() {
  const dispatch = useDispatch();
  const { design, printService, printSizeSettings } = useSelector(
    (state: IRectangularPrintState) => ({
      design: state.design,
      printService: state.printService,
      printSizeSettings: state.printSizeSettings,
    })
  );
  const { singlePrintMode } = useRectangularPrintsSelector();

  const PackingModeSetting = useMemo(() => {
    if (singlePrintMode) {
      return null;
    }
    return (
      <ContextPanelSelect
        key="packingMode"
        options={[
          {
            value: PackingMode.SINGLE,
            text: `One ${design.regionType} per print`,
          },
          {
            value: PackingMode.MULTI,
            text: `Multiple ${design.regionTypePlural} per print`,
          },
        ]}
        value={printSizeSettings.packingMode}
        onChange={value => dispatch(setPackingMode(value))}
        disabled={false}
      />
    );
  }, [printSizeSettings.packingMode]);

  const PrintSizeModeSetting = useMemo(() => {
    return (
      <ContextPanelSelect
        key="printSizeMode"
        options={printService.printSizeModes
          .map(printSizeMode => {
            return {
              value: printSizeMode.id,
              text: printSizeMode.longName || printSizeMode.name,
            };
          })
          .filter(option => {
            if (singlePrintMode) {
              return true;
            } else {
              // Remove "FULL_SIZE" from options unless we're in single-print mode.
              return option.value !== PrintSizeModeId.FULL_SIZE;
            }
          })}
        value={printSizeSettings.printSizeModeId}
        onChange={value => dispatch(setPrintSizeModeId(value))}
        disabled={false}
      />
    );
  }, [printSizeSettings.printSizeModeId]);

  const OpeningListFilter = useMemo(() => {
    return singlePrintMode ? null : <PrintQuantitiesSelector />;
  }, []);

  // These are heavily memoized because otherwise they completely re-render on every option change
  // and can ruin local state like scroll position.
  return (
    <>
      {PrintSizeModeSetting}
      {PackingModeSetting}
      {OpeningListFilter}
    </>
  );
}
