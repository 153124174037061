import { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Checkbox, Icon, Popup } from "semantic-ui-react";
import "./ContextPanelCheckbox.css";

export default class ContextPanelCheckbox extends Component {
  _onToggle(e) {
    if (!this.props.disabled) {
      this.props.onChange(!this.props.checked);
    }
  }

  render() {
    return (
      <div
        id="context-panel-checkbox"
        className={classNames({
          disabled: this.props.disabled,
        })}
        onClick={this._onToggle.bind(this)}
      >
        <Checkbox
          label={this.props.label}
          checked={this.props.checked}
          disabled={this.props.disabled}
        />
        <Popup
          trigger={
            <Icon
              name="question circle"
              color="grey"
              onClick={e => {
                e.stopPropagation();
              }}
            />
          }
          on="hover"
        >
          {this.props.help}
        </Popup>
      </div>
    );
  }
}

ContextPanelCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  help: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

ContextPanelCheckbox.defaultProps = {
  disabled: false,
};
