import local from "./TBLDotComConfig.local";
import dev from "./TBLDotComConfig.dev";
import gamma from "./TBLDotComConfig.gamma";
import prod from "./TBLDotComConfig.prod";

/**
 * =======
 * WARNING
 * =======
 *
 * This config gets shipped to the client. Do not add any sensitive data here!
 */
export type TBLDotComStage = "local" | "dev" | "gamma" | "prod";
export type TBLDotComConfig = {
  stage: TBLDotComStage;
  tblApi: {
    url: string;
  };
  aws: {
    region: string;
    cognito: {
      userPoolId: string;
      appClientId: string;
      identityPoolId: string;
    };
  };
  shopify: {
    domain: string;
    storefrontAccessToken: string;
  };
};

function getStage(): TBLDotComStage {
  return (process.env.REACT_APP_STAGE || "local") as TBLDotComStage;
}

function getConfig(): TBLDotComConfig {
  switch (getStage()) {
    case "local":
      return local;
    case "dev":
      return dev;
    case "gamma":
      return gamma;
    case "prod":
      return prod;
    default:
      throw new Error(`Unknown stage ${getStage()}`);
  }
}

const config = getConfig();
try {
  /* @ts-ignore */
  window?.tbl_dot_com_config = config;
} catch (e) {}
export default config;
