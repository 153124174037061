import { UAParser } from "ua-parser-js";

let parsedUserAgent;

export default function useUserAgent() {
  if (!parsedUserAgent) {
    const uaParser = new UAParser();
    parsedUserAgent = uaParser.getResult();
  }
  return parsedUserAgent;
}
