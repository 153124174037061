import React, { CSSProperties, FunctionComponent } from "react";
import {
  MapmakerBusinessId,
  OpeningInput,
  OpeningFeature,
} from "@mapmaker/core";
import OpeningGroup from "./OpeningGroup";
import OpeningImages from "./OpeningImages";
import { MapmakerSvgContextProvider } from "../MapmakerSvgContext";
import { getLayerByPriority, LayerPriority } from "./openingUtils";
import SvgNode from "../SvgNode";
import { IOpeningImageProps } from "./OpeningImage";

type OpeningSvgProps = {
  businessId: MapmakerBusinessId;
  openingInput: OpeningInput;
  openingFeature: OpeningFeature;
  width?: number;
  height?: number;
  padding?: number;
  layer?: LayerPriority;
  fillStyle?: CSSProperties;
  strokeStyle?: CSSProperties;
  OpeningImageComponent?: FunctionComponent<IOpeningImageProps>;
};

export default function OpeningSvg({
  businessId,
  openingFeature,
  openingInput,
  width,
  height = width,
  padding = 0,
  layer = ["cut", "inner"],
  fillStyle,
  strokeStyle,
  OpeningImageComponent,
}: OpeningSvgProps) {
  const svgWidth = width;
  const svgHeight = height;
  const openingWidth = svgWidth - padding * 2;
  const openingHeight = svgHeight - padding * 2;
  const layerData = getLayerByPriority(openingFeature, layer);
  const scale = 1; //svgWidth / layerData.bbox.width;

  return (
    <svg
      id="opening-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={`${svgWidth}px`}
      height={`${svgHeight}px`}
    >
      <MapmakerSvgContextProvider
        value={{
          svgScale: scale,
          businessId: businessId,
        }}
      >
        <OpeningGroup
          opening={openingFeature}
          width={openingWidth}
          height={openingHeight}
          centered
          x={svgWidth / 2}
          y={svgHeight / 2}
          layer={layer}
        >
          <SvgNode
            className="fill"
            nodeData={layerData}
            style={{
              fill: openingInput.background ?? "#FFFFFF",
              ...(fillStyle ?? {}),
            }}
          />

          {/* User-defined background for opening. */}
          <OpeningImages
            opening={openingFeature}
            openingInput={openingInput}
            clipLayer={layer}
            OpeningImageComponent={OpeningImageComponent}
          />
          <SvgNode
            className="stroke"
            nodeData={layerData}
            vectorEffect="non-scaling-stroke"
            style={{
              fill: "none",
              ...(strokeStyle ?? {}),
            }}
          />
        </OpeningGroup>
      </MapmakerSvgContextProvider>
    </svg>
  );
}
