import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { gql } from "@apollo/client";
import { Button } from "@mapmaker/ui";
import { FaGift } from "@react-icons/all-files/fa/FaGift";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FaQuestionCircle } from "@react-icons/all-files/fa/FaQuestionCircle";
import { FaTag } from "@react-icons/all-files/fa/FaTag";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import clsx from "clsx";
import { ModalActions, ModalContent } from "semantic-ui-react";
import MapmakerModal from "../../shared/modals/MapmakerModal";
import {
  ProjectOutputType,
  StickerTokenVariantFragment,
  useStickerTokensModalQuery,
} from "../../../client/MapmakerApi";
import {
  getStickerTokensVariantMap,
  purchaseStickerTokensQuantity,
} from "../../../lib";
import MapmakerImage from "../../shared/images/MapmakerImage";
import {
  MoneyV2,
  StickerTokenIcon,
  StickerTokenIconGrayscale,
} from "../../shared";
import { useMapmakerAppConfig } from "../../../client";
import "./StickerTokensModal.css";

gql`
  fragment StickerTokenProduct on StorefrontProduct {
    id
    variants(first: 100) {
      edges {
        node {
          ...StickerTokenVariant
        }
      }
    }
  }

  fragment StickerTokenVariant on StorefrontProductVariant {
    id
    sku
    title
    priceV2 {
      amount
      currencyCode
    }
    selectedOptions {
      name
      value
    }
  }

  query stickerTokensModal(
    $designId: String!
    $scale: Float!
    $outputType: ProjectOutputType!
  ) {
    design: mapmakerDesign(id: $designId) {
      id
      name
      regionType
      regionTypePlural
      stickerInfo(scale: $scale, outputType: $outputType) {
        count
        tokens
        sizeClassGroups {
          count
          tokens
          sizeClass
        }
      }
    }
    stickerTokenProduct: storefrontProduct(handle: "sticker-tokens") {
      ...StickerTokenProduct
    }
  }
`;

type StickerTokensModalProps = {
  open: boolean;
  designId: string;
  outputType: ProjectOutputType;
  scale: number;
  showRemove?: boolean;
  freeTokens?: number;
  onSelect(variant: StickerTokenVariantFragment): any;
  onRemove?(): any;
  onClose(): any;
};

export default function StickerTokensModal({
  open,
  designId,
  outputType,
  scale,
  showRemove = false,
  freeTokens = 0,
  onSelect,
  onRemove,
  onClose,
}: StickerTokensModalProps) {
  const { defaultProjectOutputType } = useMapmakerAppConfig();
  const [selectedVariantId, setSelectedVariantId] = useState<string>();
  const [customSelected, setCustomSelected] = useState<boolean>();
  const { data } = useStickerTokensModalQuery({
    errorPolicy: "all",
    variables: {
      designId: designId ?? "NONE",
      outputType: outputType ?? defaultProjectOutputType,
      scale,
    },
  });

  const stickerTokenProduct = data?.stickerTokenProduct;
  const design = data?.design;

  const variants = useMemo(
    () => stickerTokenProduct?.variants.edges.map(edge => edge.node),
    [stickerTokenProduct?.variants]
  );
  const selectedVariant = useMemo(() => {
    return variants?.find(variant => variant.id === selectedVariantId);
  }, [variants, selectedVariantId]);

  // A map of each variant by the number of tokens it represents.
  const variantsByTokens = useMemo(
    () => getStickerTokensVariantMap(stickerTokenProduct),
    [variants]
  );

  type Preset = {
    label?: string;
    variant: StickerTokenVariantFragment;
  };
  const presets = useMemo<[Preset, Preset, Preset]>(() => {
    function preset(tokens: number, label?: string) {
      tokens = Math.min(tokens, 50);
      return {
        label,
        variant: variantsByTokens[tokens],
      };
    }
    if (!design || !variants) {
      return undefined;
    }
    if (design.stickerInfo.count <= 10) {
      return [preset(1), preset(5), preset(10)];
    } else if (design.stickerInfo.count > 50) {
      return [preset(5), preset(25), preset(50)];
    } else {
      return [
        preset(5),
        preset(Math.ceil(design.stickerInfo.count / 2), "HALF MAP"),
        preset(design.stickerInfo.count, "FULL MAP"),
      ];
    }
  }, [design, variantsByTokens]);

  useEffect(() => {
    if (presets && !selectedVariantId) {
      setSelectedVariantId(presets[0].variant.id);
    }
  }, [presets, setSelectedVariantId]);

  const onSelectPreset = (variantId: string) => {
    setCustomSelected(false);
    setSelectedVariantId(variantId);
  };

  const onSubmit = () => {
    if (selectedVariant) {
      onSelect(selectedVariant);
    }
    onClose();
  };

  return (
    <MapmakerModal title="Include Sticker Tokens" onClose={onClose} open={open}>
      <ModalContent id="sticker-tokens-modal">
        {freeTokens > 0 && (
          <div className="free-token-warning">
            <FaTag />
            There is a promotion on this item right now so 10 free Sticker
            Tokens will be added to your cart automatically. You only need to
            add more here if you want to purchase additional Sticker Tokens.
          </div>
        )}
        <div className="header">
          <StickerTokenIcon className="token-icon" size={60} />
          <div>
            <h2>Give Stickers as a Gift!</h2>
            <p>
              An easy way to pre-pay for custom photo map stickers for someone
              else. Sticker tokens are optional, the recipient will always be
              able to use any of our printing methods.
            </p>
          </div>
        </div>
        <div className="main">
          <div className="info">
            <h2>How does it work?</h2>
            <ul className="steps">
              <li>
                <MapmakerImage src="images/products/sticker-tokens/{businessId}/buy-tokens.png" />
                <div>
                  <h3>Buy</h3>
                  <p>
                    A unique code will be included in the box{" "}
                    <strong>and</strong> emailed to the purchaser.
                  </p>
                </div>
              </li>
              <li>
                <MapmakerImage src="images/products/sticker-tokens/{businessId}/receive-code.png" />
                <div>
                  <h3>Redeem</h3>
                  <p>
                    They enter the code and the tokens are added to their
                    account.
                  </p>
                </div>
              </li>
              <li>
                <MapmakerImage src="images/products/sticker-tokens/{businessId}/buy-stickers.png" />
                <div>
                  <h3>Order</h3>
                  <p>
                    The tokens can be used at any time to order custom photo map
                    stickers.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="buy-box">
            <h2>Tokens to Add</h2>
            {variants && (
              <div className="token-select">
                {presets.map(({ label, variant }) => (
                  <TokenSelectionOption
                    key={variant.id}
                    label={label}
                    variant={variant}
                    selected={
                      !customSelected && selectedVariantId === variant.id
                    }
                    onSelect={onSelectPreset}
                  />
                ))}
                <CustomTokenOption
                  variantsByTokens={variantsByTokens}
                  selected={customSelected}
                  onSelected={() => {
                    setSelectedVariantId(undefined);
                    setCustomSelected(true);
                  }}
                  onSelect={setSelectedVariantId}
                />
              </div>
            )}
          </div>
        </div>
        <div className="learn-more">
          <a href="/help/sticker-tokens" target="_blank">
            <FaQuestionCircle />
            Learn more about Sticker Tokens
          </a>
        </div>
      </ModalContent>
      <ModalActions>
        {showRemove ? (
          <Button
            onClick={() => {
              onRemove();
              onClose();
            }}
          >
            <FaTrash /> Remove Tokens
          </Button>
        ) : (
          <Button onClick={onClose}>Cancel</Button>
        )}
        <Button
          color="accent"
          type="submit"
          disabled={!selectedVariant}
          onClick={onSubmit}
        >
          {selectedVariant ? (
            <>
              <FaGift fontSize={16} />
              Include{" "}
              {purchaseStickerTokensQuantity(selectedVariant) === 1
                ? "1 Token"
                : `${purchaseStickerTokensQuantity(selectedVariant)} Tokens`}
            </>
          ) : (
            <>
              <FaPlus fontSize={10} /> Include Tokens
            </>
          )}
        </Button>
      </ModalActions>
    </MapmakerModal>
  );
}

type TokenSelectionOptionProps = {
  variant: StickerTokenVariantFragment;
  label?: string;
  selected: boolean;
  onSelect(id: string): any;
};

function TokenSelectionOption({
  variant,
  selected,
  label,
  onSelect,
}: TokenSelectionOptionProps) {
  const numTokens = purchaseStickerTokensQuantity(variant);
  const discount = 1 - parseFloat(variant.priceV2.amount) / numTokens / 2.5;
  return (
    <div
      tabIndex={2}
      className={clsx({ "token-option": true, selected })}
      onClick={() => onSelect(variant.id)}
    >
      {label && <div className="label">{label}</div>}
      {selected ? (
        <StickerTokenIcon size={26} />
      ) : (
        <StickerTokenIconGrayscale size={26} />
      )}
      <div className="tokens">
        {numTokens} Token{numTokens === 1 ? "" : "s"}
      </div>
      <div className="price">
        <MoneyV2 moneyV2={variant.priceV2} />
      </div>
      {discount > 0.02 && (
        <div className="discount">{Math.round(discount * 100)}% OFF</div>
      )}
    </div>
  );
}

type CustomTokenOptionProps = {
  variantsByTokens: Record<number, StickerTokenVariantFragment>;
  selected: boolean;
  onSelected(): any;
  onSelect(id: string): any;
};

function CustomTokenOption({
  variantsByTokens,
  selected,
  onSelected,
  onSelect,
}: CustomTokenOptionProps) {
  const [tokens, setTokens] = useState(5);
  const inputRef = useRef<HTMLInputElement>();
  const selectedVariant = variantsByTokens[tokens];
  const discount = selectedVariant
    ? 1 - parseFloat(selectedVariant.priceV2.amount) / tokens / 2.5
    : 0;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = parseInt(e.target.value);
    const variant = variantsByTokens[input];
    if (!variant) {
      setTokens(undefined);
      onSelect(undefined);
      return;
    }
    setTokens(input);
    onSelect(variant.id);
  };

  return (
    <div
      className={clsx({
        "token-option": true,
        "custom-option": true,
        selected,
      })}
      onClick={() => {
        inputRef.current.select();
        onSelected();
      }}
    >
      <div className="label">Custom</div>
      <div className="input-row">
        {selected ? (
          <StickerTokenIcon size={26} />
        ) : (
          <StickerTokenIconGrayscale size={26} />
        )}
        <FaTimes />
        <input
          ref={inputRef}
          onChange={onChange}
          value={tokens}
          type="number"
          min="0"
          step="1"
          max="50"
        />
      </div>
      <div className="tokens">
        {tokens === 0 ? (
          <>?</>
        ) : (
          <>
            {tokens} Token{tokens === 1 ? "" : "s"}
          </>
        )}
      </div>
      <div className="price">
        {variantsByTokens[tokens] ? (
          <MoneyV2 moneyV2={variantsByTokens[tokens].priceV2} />
        ) : (
          <span className="warning">Enter 1-50</span>
        )}
      </div>
      {discount > 0.02 && (
        <div className="discount">{Math.round(discount * 100)}% OFF</div>
      )}
    </div>
  );
}
