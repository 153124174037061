import { useState } from "react";
import { Icon, ModalActions, ModalContent } from "semantic-ui-react";
import MapmakerModal from "../../../shared/modals/MapmakerModal";
import {
  getBestPrintSize,
  prettyPrintSize,
} from "../../../../lib/prints/printSizes";
import { OpeningFeature } from "@mapmaker/core";
import OpeningWhatWillPrintModal from "./OpeningWhatWillPrintModal";
import { useProjectDesign, useProjectFile } from "../../useProjectState";
import { useMapmakerAppConfig, useMapmakerContext } from "../../../../client";
import LabelledValueTable from "../../../shared/tables/LabelledValueTable";
import { MAX_IMAGES_PER_OPENING } from "./editable/editableOpeningReducer";
import { Button } from "@mapmaker/ui";

type OpeningInfoModalProps = {
  openingFeature: OpeningFeature;
  onClose?(): any;
};

function AddPhotoButton() {
  return (
    <span
      style={{
        margin: "-2px 0.2rem 0 0.2rem",
        verticalAlign: "top",
        background: "var(--mm-grey)",
        color: "var(--mm-dark-white)",
        borderRadius: "3px",
        display: "inline-block",
        padding: "1px 4px",
        whiteSpace: "nowrap",
        fontSize: "0.8rem",
      }}
    >
      <Icon name="photo" /> ADD
    </span>
  );
}

export default function OpeningInfoModal({
  openingFeature,
  onClose,
}: OpeningInfoModalProps) {
  const design = useProjectDesign();
  const { isMobile } = useMapmakerContext();
  const { businessId } = useMapmakerAppConfig();
  const [whatWillPrintModalOpen, setWhatWillPrintModalOpen] = useState(false);

  return (
    <MapmakerModal
      open={true}
      title="How does this work?"
      onClose={onClose}
      size="small"
    >
      <ModalContent>
        <h4>The Basics</h4>
        <p>
          Fill in {openingFeature.name} with up to {MAX_IMAGES_PER_OPENING}{" "}
          photos by{" "}
          {isMobile ? (
            <>
              tapping the <AddPhotoButton /> button below.
            </>
          ) : (
            <>
              dragging image files on top of the {design.regionType} or by
              clicking the <AddPhotoButton /> button.
            </>
          )}{" "}
          When you are happy with how it looks you can continue filling other{" "}
          {design.regionTypePlural} or start printing.
        </p>
        {!openingFeature.outlined && (
          <>
            <h4>Do I fill in the dotted line or the solid line?</h4>
            The dotted line shows the exact size of the space in your map for a
            photo. The solid line is the area we recommend filling so that there
            is an extra safety margin when printing and cutting the photos.
            {businessId === "tbl" ? (
              <Button
                fluid
                onClick={() => setWhatWillPrintModalOpen(true)}
                style={{ marginTop: "10px", marginLeft: 0 }}
              >
                Understanding the buffer area
              </Button>
            ) : null}
            <OpeningWhatWillPrintModal
              open={whatWillPrintModalOpen}
              onClose={() => setWhatWillPrintModalOpen(false)}
            />
          </>
        )}
        <h4>Print Sizes for {openingFeature.name}</h4>
        <p>
          These sizes are just for reference. When you are ready to print follow
          the instructions for the printing method you choose.
        </p>
        <PrintSizes opening={openingFeature} />
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="accent">
          GOT IT
        </Button>
      </ModalActions>
    </MapmakerModal>
  );
}

interface IPrintSizesProps {
  opening: OpeningFeature;
}

function PrintSizes({ opening }: IPrintSizesProps) {
  const file = useProjectFile();
  const photoLayer = opening.layout.cut || opening.layout.inner;
  const openingBBox = photoLayer.bbox;
  const printSize = getBestPrintSize(
    openingBBox.width * file.outputScale,
    openingBBox.height * file.outputScale
  );

  return (
    <LabelledValueTable
      values={[
        [
          "Opening Size",
          prettyPrintSize(openingBBox, file.outputScale),
          `The size of ${opening.name} at the widest and tallest parts.`,
        ],
        [
          "Print Size",
          prettyPrintSize(printSize),
          "The size of paper we recommend using if you are printing this yourself.",
        ],
      ]}
    />
  );
}
