import ZoomPan from "zoom-pan";
import ProjectToolbar from "../ProjectToolbar";
import MapmakerProjectToastMessages from "../shared/toasts/MapmakerProjectToastMessages";
import MapmakerLayout from "../../layout/MapmakerLayout";
import MapmakerTopBar, {
  MapmakerTopBarMode,
} from "../../layout/MapmakerTopBar";
import ProjectTopBarButton from "../ProjectTopBarButton";
import { useProjectFile } from "../useProjectState";
import { MapmakerFileViewerPolicy } from "../../../client/MapmakerApi";
import { ToggleButton } from "@mapmaker/ui";
import { FaLightbulb } from "@react-icons/all-files/fa/FaLightbulb";
import {
  FullMapViewProvider,
  useFullMapViewContext,
} from "./FullMapViewContext";
import FullMapSVG from "./FullMapSVG";
import useProjectOpeningSuggestions from "../features/opening/suggestions/useOpeningSuggestions";
import { useGuestModeMessage } from "../help/GuestModeMessage";
import { useCollaboratorIntroMessage } from "../help/CollaboratorIntroMessage";
import "./FullMapView.css";

export default function FullMapView() {
  return (
    <FullMapViewProvider name="main" initialMode={"live"}>
      <FullMapViewWithContext />
    </FullMapViewProvider>
  );
}

function FullMapViewWithContext() {
  const { mode } = useFullMapViewContext();
  const topBarMode: MapmakerTopBarMode =
    mode === "suggestions" ? "suggestions" : "normal";

  // Each of these checks for a message that may be shown. They should all be on the "help" layer so
  // only one will be shown at any given time.
  useGuestModeMessage();
  useCollaboratorIntroMessage();

  return (
    <MapmakerLayout>
      <MapmakerLayout.TopBar>
        <MapmakerTopBar
          breadcrumbs={[]}
          mode={topBarMode}
          after={<ProjectTopBarButton mode={topBarMode} />}
        />
      </MapmakerLayout.TopBar>
      <MapmakerLayout.Main
        nav={<ProjectToolbar selected="home" />}
        className="full-map-view"
      >
        <MapmakerProjectToastMessages />
        <FullMapViewMenu />
        <ZoomPan maxScale={20}>
          <FullMapSVG />
        </ZoomPan>
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}

function FullMapViewMenu() {
  const file = useProjectFile();

  function GetMenu() {
    switch (file.viewerPolicy) {
      case MapmakerFileViewerPolicy.Owner:
        return <FullMapOwnerMenu />;
      case MapmakerFileViewerPolicy.Collaborator:
        return <FullMapCollaboratorMenu />;
      default:
        return null;
    }
  }

  return (
    <div id="full-map-view-menu">
      <GetMenu />
    </div>
  );
}

function FullMapOwnerMenu() {
  const { mode, setMode } = useFullMapViewContext();
  const openingSuggestions = useProjectOpeningSuggestions();

  if (openingSuggestions.length === 0) {
    return null;
  }

  return (
    <ToggleButton
      selected={mode !== "live"}
      onToggle={enabled => setMode(enabled ? "live-with-suggestions" : "live")}
    >
      <FaLightbulb />
    </ToggleButton>
  );
}

function FullMapCollaboratorMenu() {
  const { mode, setMode } = useFullMapViewContext();

  return (
    <ToggleButton
      selected={mode !== "live"}
      onToggle={enabled => setMode(enabled ? "suggestions" : "live")}
    >
      <FaLightbulb />
    </ToggleButton>
  );
}
