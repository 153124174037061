import { memo } from "react";

export interface IMoneyProps {
  amount: number;
  shorten?: boolean;
  valueIfZero?: string;
}

const Money = memo(({ amount, shorten = false, valueIfZero }: IMoneyProps) => {
  if (amount === 0 && valueIfZero) {
    return <>{valueIfZero}</>;
  }
  const hasCents = amount % 1 !== 0;
  const str = shorten && !hasCents ? amount.toFixed(0) : amount.toFixed(2);
  return <>${str}</>;
});

export default Money;
