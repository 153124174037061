import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import Hammer from "react-hammerjs";
import ZoomPanContext from "./ZoomPanContext";

type CSSStyles = { [key: string]: React.CSSProperties };

const Styles: CSSStyles = {
  main: {
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  frame: {
    height: "100%",
    width: "100%",
    transformOrigin: "0 0",
  },
  debugFrame: {
    border: "0px solid red",
  },
  debugBox: {
    position: "absolute",
    zIndex: 100000,
    margin: "10px",
    padding: "8px 16px",
    background: "#000000",
    opacity: "0.8",
    color: "#ffffff",
    fontSize: "9pt",
  },
  debugBoxLabel: {
    display: "inline-block",
    width: "70px",
  },
};

// Reasonable defaults
const PIXEL_STEP = 10;
const LINE_HEIGHT = 40;
const PAGE_HEIGHT = 800;

function normalizeWheel(event) {
  var sX = 0,
    sY = 0, // spinX, spinY
    pX = 0,
    pY = 0; // pixelX, pixelY

  // Legacy
  if ("detail" in event) {
    sY = event.detail;
  }
  if ("wheelDelta" in event) {
    sY = -event.wheelDelta / 120;
  }
  if ("wheelDeltaY" in event) {
    sY = -event.wheelDeltaY / 120;
  }
  if ("wheelDeltaX" in event) {
    sX = -event.wheelDeltaX / 120;
  }

  // side scrolling on FF with DOMMouseScroll
  if ("axis" in event && event.axis === event.HORIZONTAL_AXIS) {
    sX = sY;
    sY = 0;
  }

  pX = sX * PIXEL_STEP;
  pY = sY * PIXEL_STEP;

  if ("deltaY" in event) {
    pY = event.deltaY;
  }
  if ("deltaX" in event) {
    pX = event.deltaX;
  }

  if ((pX || pY) && event.deltaMode) {
    if (event.deltaMode == 1) {
      // delta in LINE units
      pX *= LINE_HEIGHT;
      pY *= LINE_HEIGHT;
    } else {
      // delta in PAGE units
      pX *= PAGE_HEIGHT;
      pY *= PAGE_HEIGHT;
    }
  }

  // Fall-back if spin cannot be determined
  if (pX && !sX) {
    sX = pX < 1 ? -1 : 1;
  }
  if (pY && !sY) {
    sY = pY < 1 ? -1 : 1;
  }

  return { spinX: sX, spinY: sY, pixelX: pX, pixelY: pY };
}

function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num;
}

interface IZoomPanProps {
  children: ReactNode;
  initialX?: number;
  initialY?: number;
  initialScale?: number;
  minScale?: number;
  maxScale?: number;
  enabled?: boolean;
  // Force focus to a specific area.
  focusArea?: any;
  debug?: boolean;
}

interface IZoomPanState {
  scale: number;
  x: number;
  y: number;
  unfocusedState: IZoomPanState;
  containerWidth: number;
  containerHeight: number;
  containerLeft: number;
  containerTop: number;
}

export default class ZoomPan extends React.Component<
  IZoomPanProps,
  IZoomPanState
> {
  static defaultProps = {
    enabled: true,
    initialX: 0,
    initialY: 0,
    initialScale: 1,
    maxScale: 10,
    minScale: 1,
    focusArea: null,
  };

  private panStartX: number = undefined;
  private panStartY: number = undefined;
  private movedWhileMouseDown: boolean = undefined;
  private pinchStartScale: number = undefined;

  constructor(props: IZoomPanProps) {
    super(props);
    this.state = {
      scale: props.initialScale,
      x: props.initialX,
      y: props.initialY,
      unfocusedState: null,
      containerWidth: 0,
      containerHeight: 0,
      containerLeft: 0,
      containerTop: 0,
    };
  }

  /*
   * Sets the viewbox state and clamps it to the expected bounds.
   */
  private setView(x, y, scale, unfocusedState = null, clampScale = true) {
    // Clamp values

    // if we are focused on something then there is no max scale.
    const maxScale = clampScale ? this.props.maxScale : Infinity;

    scale = clamp(scale, this.props.minScale, maxScale);
    x = clamp(
      x,
      -(this.state.containerWidth - this.state.containerWidth / scale),
      0
    );
    y = clamp(
      y,
      -(this.state.containerHeight - this.state.containerHeight / scale),
      0
    );

    // If X and Y are NaN there was probably a problem getting the width/height of the element
    // at some point.  Try again.
    if (isNaN(x) || isNaN(y)) {
      this.updateContainerSize();
      return;
    }

    this.setState({
      x,
      y,
      scale,
      unfocusedState,
    });
  }

  /**
   * Zoom while keeping the given point at the same location
   * pageX: Position of X point relative to the page
   * pageY: Position of Y point relative to the page
   * newScale: The new scale value to zoom to
   */
  _zoomAtPoint(pageX, pageY, newScale) {
    newScale = clamp(newScale, this.props.minScale, this.props.maxScale);

    var containerX =
      (pageX - this.state.containerLeft) / this.state.containerWidth;

    var oldFrameX =
      (this.state.containerWidth / this.state.scale) * containerX -
      this.state.x;
    var newFrameX = (this.state.containerWidth / newScale) * containerX;
    var newX = newFrameX - oldFrameX;

    var containerY =
      (pageY - this.state.containerTop) / this.state.containerHeight;
    var oldFrameY =
      (this.state.containerHeight / this.state.scale) * containerY -
      this.state.y;
    var newFrameY = (this.state.containerHeight / newScale) * containerY;
    var newY = newFrameY - oldFrameY;

    this.setView(newX, newY, newScale);
  }

  /**
   * Transforms a focus area from normalized coordinates (relative to the size of the content) to
   * absolute coordinates within the container space.
   */
  _getAbsoluteBBox(bbox) {
    return {
      x: (bbox.x * this.state.containerWidth + this.state.x) * this.state.scale,
      y:
        (bbox.y * this.state.containerHeight + this.state.y) * this.state.scale,
      width: bbox.width * this.state.containerWidth * this.state.scale,
      height: bbox.height * this.state.containerHeight * this.state.scale,
    };
  }

  /**
   * Center the view so it contains the entire bounding box scaled as large as allowed.
   * relativeBBox: An object with x, y, height, and width relative to the size of the content and normalized.
   * updateUnfocusedState: Whether or not to update the unfocused state to the current view state.
   */
  _focusOnArea(bbox, updateUnfocusedState) {
    if (!bbox) {
      return;
    }
    // Clone the bbox so we can modify it without side effects.
    let newX = -bbox.x * this.state.containerWidth;
    let newY = -bbox.y * this.state.containerHeight;

    let containerAspect =
      this.state.containerWidth / this.state.containerHeight;
    let bboxAspect =
      (bbox.width * this.state.containerWidth) /
      (bbox.height * this.state.containerHeight);

    let newScale;
    if (containerAspect < bboxAspect) {
      newScale = 1 / bbox.width;
      let percentageHeight = containerAspect / bboxAspect;
      newY +=
        ((this.state.containerHeight / newScale) * (1 - percentageHeight)) / 2;
    } else {
      newScale = 1 / bbox.height;
      let percentageWidth = bboxAspect / containerAspect;
      newX +=
        ((this.state.containerWidth / newScale) * (1 - percentageWidth)) / 2;
    }

    this.setView(
      newX,
      newY,
      newScale,
      !updateUnfocusedState
        ? this.state.unfocusedState
        : {
            x: this.state.x,
            y: this.state.y,
            scale: this.state.scale,
          },
      false
    );
  }

  _onWheel(event) {
    var wheelDelta = -normalizeWheel(event.nativeEvent).pixelY / 500;
    var newScale = this.state.scale * (1 + wheelDelta);

    this._zoomAtPoint(event.pageX, event.pageY, newScale);
  }

  _onPanStart(event) {
    this.panStartX = this.state.x;
    this.panStartY = this.state.y;
  }

  _onPanEnd(event) {
    this.panStartX = undefined;
    this.panStartY = undefined;
  }

  _onPan(event) {
    if (this.panStartX === undefined || this.panStartX === undefined) {
      return;
    }
    // The 1.1 multiplier makes panning feel a little more snappy on mobile devices which have some lag.
    this.setView(
      this.panStartX + (1.1 * event.deltaX) / this.state.scale,
      this.panStartY + (1.1 * event.deltaY) / this.state.scale,
      this.state.scale
    );
    this.movedWhileMouseDown = true;
  }

  _onPinchStart(event) {
    this.pinchStartScale = this.state.scale;
  }

  _onPinchEnd(event) {
    delete this.pinchStartScale;
  }

  _onPinch(event) {
    this._zoomAtPoint(
      event.center.x,
      event.center.y,
      this.pinchStartScale * event.scale
    );
  }

  // Renders a box showing where the focus area is.
  _renderDebugFocusArea() {
    if (this.props.focusArea) {
      let absoluteBBox = this._getAbsoluteBBox(this.props.focusArea);

      return (
        <div
          key="focus-area"
          style={{
            position: "absolute",
            left: `${absoluteBBox.x}px`,
            top: `${absoluteBBox.y}px`,
            width: `${absoluteBBox.width}px`,
            height: `${absoluteBBox.height}px`,
            border: "3px solid orange",
            pointerEvents: "none",
          }}
        />
      );
    } else {
      return null;
    }
  }

  _renderDebugLayer() {
    if (this.props.debug) {
      return [this._renderDebugFocusArea()];
    } else {
      return null;
    }
  }

  // Renders a debug information box. Should never be shown to end-users.
  _renderDebugBox() {
    function valueWithDelta(value, delta) {
      delta = delta >= 0 ? `+${delta.toFixed(2)}` : `-${delta.toFixed(2)}`;
      return [value, <span className="delta">{delta}</span>];
    }

    function stat(label, value) {
      return (
        <div>
          <label style={Styles.debugBoxLabel}>{label}</label>
          <span>{value}</span>
        </div>
      );
    }

    if (this.props.debug) {
      let fa = this.props.focusArea;

      return (
        <div style={Styles.debugBox}>
          {stat(
            "container",
            `${this.state.containerWidth} x ${this.state.containerHeight}`
          )}
          {stat("scale", this.state.scale.toFixed(2))}
          {stat("x", this.state.x.toFixed(2))}
          {stat("y", this.state.y.toFixed(2))}
          {stat(
            "focus area",
            fa
              ? `${fa.x.toFixed(3)}, ${fa.y.toFixed(3)} [${fa.width.toFixed(
                  3
                )} x ${fa.height.toFixed(3)}]`
              : "none"
          )}
        </div>
      );
    } else {
      return null;
    }
  }

  componentWillReceiveProps(nextProps) {
    // If the focus area has changed and is not null, set it immediately.
    if (nextProps.focusArea !== null) {
      // Split conditionals just for sanity's sake.
      var alreadyFocused = !!this.props.focusArea;
      var focusChanged =
        !alreadyFocused ||
        nextProps.focusArea.x != this.props.focusArea.x ||
        nextProps.focusArea.y != this.props.focusArea.y ||
        nextProps.focusArea.width != this.props.focusArea.width ||
        nextProps.focusArea.height != this.props.focusArea.height;
      if (focusChanged) {
        this._focusOnArea(
          nextProps.focusArea,
          false /* Formerly !alreadyFocused, this basically nullifies the unfocused area. */
        );
      }
    } else if (this.props.focusArea && this.state.unfocusedState) {
      // Unfocused, return to previous unfocused area.
      this.setView(
        this.state.unfocusedState.x,
        this.state.unfocusedState.y,
        this.state.unfocusedState.scale,
        null
      );
    } else if (this.props.focusArea) {
      // Unfocused, return to default focus area.
      this.setView(
        this.props.initialX,
        this.props.initialY,
        this.props.initialScale,
        null
      );
    }
  }

  private updateContainerSize() {
    const container = ReactDOM.findDOMNode(this) as Element;
    if (!container) {
      return;
    }
    const rect = container.getBoundingClientRect();

    // If the size has changed, set the state again.
    if (
      rect.width != this.state.containerWidth ||
      rect.height != this.state.containerHeight ||
      rect.x != this.state.containerLeft ||
      rect.y != this.state.containerTop
    ) {
      this.setState({
        containerWidth: rect.width,
        containerHeight: rect.height,
        containerLeft: rect.x,
        containerTop: rect.y,
      });
    }
  }

  _onMouseDown = event => {
    this.movedWhileMouseDown = false;
  };

  _stopEventIfMouseMoved = event => {
    if (this.movedWhileMouseDown) {
      event.stopPropagation();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    this.updateContainerSize();
  }

  componentDidMount() {
    this.updateContainerSize();
    ReactDOM.findDOMNode(this).addEventListener(
      "mousedown",
      this._onMouseDown,
      true
    );
    ReactDOM.findDOMNode(this).addEventListener(
      "click",
      this._stopEventIfMouseMoved,
      true
    );
  }

  componentWillunmount() {
    ReactDOM.findDOMNode(this).removeEventListener(
      "mousedown",
      this._onMouseDown,
      true
    );
    ReactDOM.findDOMNode(this).removeEventListener(
      "click",
      this._stopEventIfMouseMoved,
      true
    );
  }

  render() {
    return (
      <ZoomPanContext.Provider
        value={{
          scale: this.state.scale,
        }}
      >
        <Hammer
          direction="DIRECTION_ALL"
          onPanStart={this._onPanStart.bind(this)}
          onPanEnd={this._onPanEnd.bind(this)}
          onPan={this._onPan.bind(this)}
          onPinchStart={this._onPinchStart.bind(this)}
          onPinchEnd={this._onPinchEnd.bind(this)}
          onPinch={this._onPinch.bind(this)}
          options={{
            recognizers: {
              pinch: { enable: this.props.enabled },
              pan: { enable: this.props.enabled },
            },
          }}
        >
          <div
            style={Styles.main}
            onWheel={this.props.enabled ? this._onWheel.bind(this) : null}
            data-scale={this.state.scale}
          >
            {this._renderDebugBox()}
            <div
              style={{
                ...Styles.frame,
                transform:
                  "scale(" +
                  this.state.scale +
                  ")" +
                  "translate(" +
                  this.state.x +
                  "px , " +
                  this.state.y +
                  "px )" +
                  "",
              }}
            >
              {this.props.children}
            </div>
            {this._renderDebugLayer()}
          </div>
        </Hammer>
      </ZoomPanContext.Provider>
    );
  }
}
