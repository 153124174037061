import { gql } from "@apollo/client";
import {
  RenderableDesignResource,
  RefMap,
  ResourceMap,
  RenderableFileResource,
  RenderableOpeningSuggestionResource,
} from "@mapmaker/renderable";
import {
  GetRenderableDesignDocument,
  GetRenderableDesignQuery,
  GetRenderableDesignQueryVariables,
  GetRenderableFileDocument,
  GetRenderableFileQuery,
  GetRenderableFileQueryVariables,
  GetRenderableOpeningSuggestionDocument,
  GetRenderableOpeningSuggestionQuery,
  GetRenderableOpeningSuggestionQueryVariables,
} from "../../client/MapmakerApi";
import { MapmakerClient } from "../../client/useMapmakerApolloClient";

export default function createFetchResources(client: MapmakerClient) {
  return async (refs: RefMap): Promise<ResourceMap> => {
    return {
      DESIGN: await fetchDesigns(refs.DESIGN, client),
      FILE: await fetchFiles(refs.FILE, client),
      OPENING_SUGGESTION: await fetchOpeningSuggestions(
        refs.OPENING_SUGGESTION,
        client
      ),
    };
  };
}

/**
 * Fetch design resources
 */

gql`
  fragment RenderableDesign on MapmakerDesign {
    id
    businessId
    name
    width
    height
    regionType
    regionTypePlural
    regionCount
    features
  }
  query getRenderableDesign($designId: String!) {
    design: mapmakerDesign(id: $designId) {
      ...RenderableDesign
    }
  }
`;

async function fetchDesigns(
  designIds: string[],
  client: MapmakerClient
): Promise<Record<string, RenderableDesignResource>> {
  const designs = await Promise.all(
    designIds.map(designId => fetchDesign(designId, client))
  );
  return designs.reduce(
    (designMap, design) => ({ ...designMap, [design.id]: design }),
    {}
  );
}

async function fetchDesign(
  designId: string,
  client: MapmakerClient
): Promise<RenderableDesignResource> {
  try {
    const result = await client.query<
      GetRenderableDesignQuery,
      GetRenderableDesignQueryVariables
    >({
      query: GetRenderableDesignDocument,
      variables: {
        designId,
      },
    });
    if (!result.data.design) {
      throw new Error(`Could not find design '${designId}'.`);
    }
    return result.data.design;
  } catch (e) {
    throw new Error(`Could not find design '${designId}'.`);
  }
}

/**
 * Fetch file resources
 */

gql`
  fragment RenderableFile on MapmakerFile {
    id
    updatedAt
    name
    inputs
    design {
      ...RenderableDesign
    }
  }
  query getRenderableFile($fileId: String!) {
    file: mapmakerFile(id: $fileId) {
      ...RenderableFile
    }
  }
`;

async function fetchFiles(
  fileIds: string[],
  client: MapmakerClient
): Promise<Record<string, RenderableFileResource>> {
  const files = await Promise.all(
    fileIds.map(fileId => fetchFile(fileId, client))
  );
  return files.reduce((fileMap, file) => ({ ...fileMap, [file.id]: file }), {});
}

async function fetchFile(
  fileId: string,
  client: MapmakerClient
): Promise<RenderableFileResource> {
  try {
    const result = await client.query<
      GetRenderableFileQuery,
      GetRenderableFileQueryVariables
    >({
      query: GetRenderableFileDocument,
      variables: {
        fileId,
      },
    });
    return result.data.file;
  } catch (e) {
    throw Error(`Could not find project ${fileId}.`);
  }
}

/**
 * Fetch suggestion resources
 */

gql`
  fragment RenderableOpeningSuggestion on OpeningSuggestion {
    id
    fileId
    openingId
    input
  }
  query getRenderableOpeningSuggestion(
    $fileId: String!
    $openingId: String!
    $suggestionId: String!
  ) {
    suggestion: openingSuggestion(
      fileId: $fileId
      openingId: $openingId
      suggestionId: $suggestionId
    ) {
      ...RenderableOpeningSuggestion
    }
  }
`;

async function fetchOpeningSuggestions(
  suggestionIds: string[],
  client: MapmakerClient
): Promise<Record<string, RenderableOpeningSuggestionResource>> {
  const suggestions = await Promise.all(
    suggestionIds.map(suggestionId =>
      fetchOpeningSuggestion(suggestionId, client)
    )
  );
  return suggestions.reduce(
    (suggestionMap, suggestion) => ({
      ...suggestionMap,
      [[suggestion.fileId, suggestion.openingId, suggestion.id].join(
        ":"
      )]: suggestion,
    }),
    {}
  );
}

async function fetchOpeningSuggestion(
  suggestionIdBundle: string,
  client: MapmakerClient
): Promise<RenderableOpeningSuggestionResource> {
  try {
    const [fileId, openingId, suggestionId] = suggestionIdBundle.split(":");
    const result = await client.query<
      GetRenderableOpeningSuggestionQuery,
      GetRenderableOpeningSuggestionQueryVariables
    >({
      query: GetRenderableOpeningSuggestionDocument,
      variables: {
        fileId,
        openingId,
        suggestionId,
      },
    });
    return result.data.suggestion;
  } catch (e) {
    throw Error(`Could not find opening suggestion ${suggestionIdBundle}.`);
  }
}
