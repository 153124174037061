import { captureException } from "@sentry/core";
import { Component } from "react";
import ErrorPage from "../../lib/errors/ErrorPage";
import { PageNotFoundError, UserError } from "../../lib/errors/ErrorTypes";

type HelpPageErrorBoundaryState = {
  error?: any;
};

export default class HelpPageErrorBoundary extends Component<
  any,
  HelpPageErrorBoundaryState
> {
  constructor(props = {}) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  componentDidCatch(error: any) {
    if (error.capture) {
      captureException(error);
    }
  }

  render() {
    const error = this.state?.error;
    if (!error) {
      return this.props.children;
    }

    if (error instanceof PageNotFoundError) {
      return (
        <ErrorPage
          stretchHeight={false}
          action={{
            to: "/help",
            label: "Help Center",
          }}
        >
          {error.message}
        </ErrorPage>
      );
    } else if (error instanceof UserError) {
      return (
        <ErrorPage action="contact" stretchHeight={false}>
          {error.message}
        </ErrorPage>
      );
    } else {
      return (
        <ErrorPage action="contact" stretchHeight={false}>
          An unexpected error ocurred.
        </ErrorPage>
      );
    }
  }
}
