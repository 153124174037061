import { Button } from "@mapmaker/ui";
import React, { FormEvent, useState } from "react";
import { useAuthContext } from "../AWSAuthContext";
import "./ChangePasswordBox.css";
import "./shared/Inputs.css";

interface IChangePasswordBoxProps {}

export function ChangePasswordBox({}: IChangePasswordBoxProps) {
  const { changePassword } = useAuthContext();
  const [changing, setChanging] = useState(false);
  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  async function submitForm(event: FormEvent) {
    event.preventDefault();
    try {
      setError(undefined);
      setSuccess(false);
      setLoading(true);
      await changePassword(oldPassword, newPassword);
      setSuccess(true);
      setChanging(false);
    } catch (e) {
      if (e?.message.startsWith("Incorrect username")) {
        setError("Current password is incorrect.");
      } else {
        setError(e?.message ?? e);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div id="change-password-box">
      {changing ? (
        <form onSubmit={submitForm}>
          <input
            className="aws-auth-input"
            placeholder="old password"
            type="password"
            onChange={e => setOldPassword(e.target.value)}
          />
          <input
            className="aws-auth-input"
            placeholder="new password"
            type="password"
            onChange={e => setNewPassword(e.target.value)}
          />
          <div>
            <Button type="submit" color="accent" loading={loading}>
              Change
            </Button>
            <Button text outlined onClick={() => setChanging(false)}>
              Cancel
            </Button>
            <div className="error">{error}</div>
          </div>
        </form>
      ) : (
        <>
          <span
            onClick={() => {
              setChanging(true);
              setSuccess(false);
            }}
          >
            <span>••••••••••</span>
            <span className="edit">change</span>
          </span>
          {success && <div className="success">Password Updated</div>}
        </>
      )}
    </div>
  );
}
