import React from "react";
import { SVGNode as DesignSVGNode } from "@mapmaker/core";

export type SvgNodeProps = React.SVGProps<SVGElement> & {
  nodeData: DesignSVGNode;
};

export default function SvgNode({ nodeData, ...props }: SvgNodeProps) {
  if (!nodeData) {
    return null;
  }

  return React.createElement(
    nodeData.name,
    { ...props, d: nodeData.d },
    (nodeData.children || []).map((child, i) => {
      return <SvgNode key={i} nodeData={child} />;
    })
  );
}
