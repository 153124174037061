import { useRef, useCallback, WheelEvent, useEffect, useState } from "react";
import { getDefaultImageTransform, OpeningImageInput } from "@mapmaker/core";
import clsx from "clsx";
import Moveable, {
  OnDrag,
  OnResize,
  OnResizeStart,
  OnRotate,
} from "react-moveable";
import useKeyPress from "../../../../../lib/hooks/useKeypress";
import {
  OpeningImageTransform,
  transformImage,
} from "./editableOpeningImageReducer";
import {
  SvgParentPortal,
  useSvgContext,
} from "../../../../../lib/svg/useSvgContext";
import useOnClickOutside from "../../../../../lib/hooks/useOnClickOutside";
import { useEditableOpeningDispatch } from "./EditableOpeningStore";
import {
  adjustImageDepth,
  removeImage,
  setActiveImage,
} from "./editableOpeningReducer";
import useEditableOpening from "./useEditableOpening";
import ProjectOpeningImage from "../ProjectOpeningImage";
import { OpeningImageCSSTransform } from "@mapmaker/svg";
import "./EditableOpeningImage.css";

export interface IEditableOpeningImageProps {
  image: OpeningImageInput;
}

type LocalOpeningImageTransform = OpeningImageTransform & {
  dirty: boolean;
};

export default function EditableOpeningImage({
  image,
}: IEditableOpeningImageProps) {
  const dispatch = useEditableOpeningDispatch();
  const { scale: svgScale } = useSvgContext();
  const { activeImage, highlightedImageId, printLayer, zoomLevel } =
    useEditableOpening();
  const selected = image.id === activeImage?.id;
  const highlighted = image.id === highlightedImageId;
  const lowlighted = highlightedImageId && !highlighted;
  const interactionInProgressRef = useRef<boolean>(false);
  const ref = useRef<SVGGElement>();
  const svgImageRef = useRef<SVGImageElement>();
  const localTransformRef = useRef<LocalOpeningImageTransform>({
    dirty: false,
    x: image.x,
    y: image.y,
    width: image.width,
    height: image.height,
    rotation: image.rotation,
  });

  /**
   * Moveable interactions
   */
  const setImageTransformFromLocal = useCallback(() => {
    const local = localTransformRef.current;
    svgImageRef.current.setAttribute(
      "transform",
      OpeningImageCSSTransform({
        flipX: image.flipX,
        flipY: image.flipY,
        ...local,
      })
    );
    svgImageRef.current.setAttribute("width", local.width.toString());
    svgImageRef.current.setAttribute("height", local.height.toString());
    // X and Y in the svgImageRef are not the X/Y coordinates, they offset the image so it's center
    // is at X & Y.
    svgImageRef.current.setAttribute("x", (-local.width / 2).toString());
    svgImageRef.current.setAttribute("y", (-local.height / 2).toString());
  }, [image]);

  const commitLocalTransform = useCallback(() => {
    if (!localTransformRef.current.dirty) {
      return;
    }
    dispatch(transformImage(image.id, localTransformRef.current, 0));
    localTransformRef.current.dirty = false;
  }, [dispatch, image]);

  const onInteractionStart = useCallback(() => {
    interactionInProgressRef.current = true;
    function onInteractionEnd() {
      interactionInProgressRef.current = false;
      commitLocalTransform();
      document.removeEventListener("mouseup", onInteractionEnd);
    }
    document.addEventListener("mouseup", onInteractionEnd);
  }, [image]);

  // Commit the local changes to the file.
  const onInteractionEnd = useCallback(() => {
    commitLocalTransform();
  }, [image.id, dispatch]);

  useEffect(() => {
    // A bug in react-moveable causes the on<Action>End methods not to fire sometimes. This will
    // make sure any outstanding transformations get applied at the end.
    return () => {
      commitLocalTransform();
    };
  }, []);

  const onDrag = useCallback(
    ({ beforeDelta, isPinch }: OnDrag) => {
      if (isPinch) {
        // Dragging while pinching ruins everything :/
        return;
      }
      localTransformRef.current.x += beforeDelta[0];
      localTransformRef.current.y += beforeDelta[1];
      localTransformRef.current.dirty = true;
      setImageTransformFromLocal();
    },
    [image]
  );

  const onResizeStart = useCallback(
    (e: OnResizeStart) => {
      e.datas.initial = { ...image };
      onInteractionStart();
    },
    [image, onInteractionStart]
  );

  const onResize = useCallback(
    ({ width, height, direction, datas }: OnResize) => {
      const { initial } = datas as { initial: OpeningImageTransform };
      localTransformRef.current.width = width;
      localTransformRef.current.height = height;
      const diffW = width - initial.width;
      const diffH = height - initial.height;

      //localTransformRef.current.x = initial.x - (direction[0] * diffW) / 2;
      //localTransformRef.current.y = initial.y - (direction[1] * diffH) / 2;
      if (direction[0] === -1) {
        localTransformRef.current.x = initial.x - diffW;
      } else if (direction[0] === 0) {
        localTransformRef.current.x = initial.x - diffW / 2;
      }
      if (direction[1] === -1) {
        localTransformRef.current.y = initial.y - diffH;
      } else if (direction[1] === 0) {
        localTransformRef.current.y = initial.y - diffH / 2;
      }
      localTransformRef.current.dirty = true;
      setImageTransformFromLocal();
    },
    [image]
  );

  const onRotate = useCallback(
    ({ beforeDelta }: OnRotate) => {
      localTransformRef.current.rotation += beforeDelta;
      localTransformRef.current.dirty = true;
      setImageTransformFromLocal();
    },
    [image]
  );

  /**
   * Direct interactions
   */
  const selectImage = useCallback(() => {
    dispatch(setActiveImage(image.id));
  }, [dispatch, setActiveImage, image.id]);

  const deselectImage = useCallback(() => {
    dispatch(setActiveImage(null));
  }, [dispatch, setActiveImage]);

  const moveDepthUp = useCallback(() => {
    dispatch(adjustImageDepth(image.id, 1));
  }, []);

  // Deselect the image if there is a click on the SVG anywhere outside the image.
  useOnClickOutside(
    ref,
    useCallback(
      (e) => {
        if (
          !selected ||
          !(e.target instanceof SVGElement) ||
          interactionInProgressRef.current
        ) {
          return;
        } else {
          deselectImage();
        }
      },
      [selected, deselectImage]
    )
  );

  useKeyPress(
    "ArrowLeft",
    (e) => {
      if (e.shiftKey) {
        // Go to the next 45 degree step. We add 2 to make 47 to avoid tiny step changes which would
        // be confusing because it would seem like nothing changed.
        const target = image.rotation - 45 - (image.rotation % 45);
        dispatch(transformImage(image.id, { rotation: target }));
      } else {
        dispatch(
          transformImage(image.id, {
            rotation: image.rotation - (e.altKey ? 0.1 : 1),
          })
        );
      }
    },
    selected
  );

  useKeyPress(
    "ArrowRight",
    (e) => {
      if (e.shiftKey) {
        // Go to the next 45 degree step. We add 2 to make 47 to avoid tiny step changes which would
        // be confusing because it would seem like nothing changed.
        const target = image.rotation + 45 - (image.rotation % 45);
        dispatch(
          transformImage(image.id, {
            rotation: target,
          })
        );
      } else {
        dispatch(
          transformImage(image.id, {
            rotation: image.rotation + (e.altKey ? 0.1 : 1),
          })
        );
      }
    },
    selected
  );

  const translate = useCallback(
    (x: number = 0, y: number = 0) => {
      dispatch(
        transformImage(image.id, {
          x: image.x + x / svgScale,
          y: image.y + y / svgScale,
        })
      );
    },
    [dispatch, transformImage, image, svgScale]
  );

  const scale = useCallback(
    (delta: number) => {
      const deltaWidth = image.width * delta;
      const deltaHeight = image.height * delta;
      dispatch(
        transformImage(image.id, {
          width: image.width + deltaWidth,
          height: image.height + deltaHeight,
          x: image.x - deltaWidth / 2,
          y: image.y - deltaHeight / 2,
        })
      );
    },
    [dispatch, transformImage, image]
  );

  // Move
  useKeyPress("a", () => translate(-3, 0), selected);
  useKeyPress("A", () => translate(-30, 0), selected);
  useKeyPress("d", () => translate(3, 0), selected);
  useKeyPress("D", () => translate(30, 0), selected);
  useKeyPress("w", () => translate(0, -3), selected);
  useKeyPress("W", () => translate(0, -30), selected);
  useKeyPress("s", () => translate(0, 3), selected);
  useKeyPress("S", () => translate(0, 30), selected);
  // Resize
  useKeyPress("ArrowUp", (e) => scale(e.shiftKey ? 1 / 5 : 1 / 50), selected);
  useKeyPress(
    "ArrowDown",
    (e) => scale(e.shiftKey ? -1 / 6 : -1 / 51),
    selected
  );
  // Deselect
  useKeyPress("Backspace", () => dispatch(removeImage(image.id)), selected);
  useKeyPress("Escape", deselectImage, selected);
  // Re-center image
  useKeyPress(
    " ",
    () =>
      dispatch(
        transformImage(image.id, getDefaultImageTransform(printLayer, image))
      ),
    selected
  );
  // Settings
  const shiftKeyDown = useKeyPress("Shift");

  const moveableRef = useRef<Moveable>();

  // Effect occurs whenever the image is changed, which usually means when a local transform has
  // been committed.
  useEffect(() => {
    // Keep the control points up to date.
    moveableRef.current?.updateRect();
    // Set the local transform to match the new image transform.
    localTransformRef.current = {
      dirty: false,
      x: image.x,
      y: image.y,
      width: image.width,
      height: image.height,
      rotation: image.rotation,
    };
  }, [image]);

  // Effect occurs when zoom level is changed.
  useEffect(() => {
    // Keep the control points up to date.
    moveableRef.current?.updateRect();
  }, [zoomLevel]);

  useEffect(() => {
    const updateMoveable = () => moveableRef?.current.updateRect();
    window.addEventListener("resize", updateMoveable);
    return () => {
      window.removeEventListener("resize", updateMoveable);
    };
  }, []);

  return (
    <g
      ref={ref}
      id="editable-opening-image"
      onMouseDownCapture={selectImage}
      onTouchStartCapture={selectImage}
      onDoubleClick={moveDepthUp}
      className={clsx({
        highlighted,
        lowlighted,
        selected,
      })}
    >
      <ProjectOpeningImage
        imageRef={svgImageRef}
        image={image}
        svgScale={svgScale}
      />
      {selected && (
        <SvgParentPortal>
          {!lowlighted && (
            <Moveable
              ref={(r) => (moveableRef.current = r)}
              className="tbl-moveable"
              target={svgImageRef.current}
              origin={false}
              keepRatio={!shiftKeyDown}
              useResizeObserver
              useAccuratePosition
              // Position
              draggable
              onDragStart={onInteractionStart}
              onDrag={onDrag}
              onDragEnd={onInteractionEnd}
              // Size
              resizable
              onResizeStart={onResizeStart}
              onResize={onResize}
              onResizeEnd={onInteractionEnd}
              // Rotation
              rotatable
              onRotateStart={onInteractionStart}
              onRotate={onRotate}
              onRotateEnd={onInteractionEnd}
              // Pinch
              pinchable
              onPinchStart={onInteractionStart}
              onPinchEnd={onInteractionEnd}
            />
          )}
        </SvgParentPortal>
      )}
    </g>
  );
}
