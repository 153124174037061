import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { getServerConfig } from "./serverConfig";

const LOCAL_STORAGE_KEY = "systemTimeOffset";
let systemTimeStoredValue = localStorage.getItem(LOCAL_STORAGE_KEY);
let systemTimeOffset: number =
  systemTimeStoredValue === null ? null : parseInt(systemTimeStoredValue);

// If we don't have a stored server time, fetch one after 9 seconds. This prevents it from slowing
// down loading, but should speed up the first request to the API.  If the API gets called earlier,
// it will grab it then.
if (systemTimeOffset === null) {
  setTimeout(fetchServerTime, 9 * 1000);
}

export async function getServerDate(): Promise<Date> {
  return new Date(new Date().getTime() + (await getSystemTimeOffset()));
}

export async function getSystemTimeOffset(): Promise<number> {
  if (systemTimeOffset === null) {
    await fetchServerTime();
  }
  return systemTimeOffset;
}

export function useSystemTimeOffset() {
  const [systemTimeOffset, setSystemTimeOffset] = useState<null | number>(null);
  const [error, setError] = useState<null | string>(null);
  useEffect(() => {
    async function getSystemTime() {
      try {
        setSystemTimeOffset(await getSystemTimeOffset());
      } catch (e) {
        setError(e);
      }
    }
    getSystemTime();
  }, []);
  const loading = systemTimeOffset === null && error === null;
  return {
    loading,
    error,
    systemTimeOffset,
  };
}

export function clearSystemTimeOffset() {
  systemTimeOffset = null;
  localStorage.removeItem(LOCAL_STORAGE_KEY);
}

async function fetchServerTime() {
  if (systemTimeOffset !== null) {
    return systemTimeOffset;
  }
  try {
    // Get a brand new timestamp from the server.
    const config = await getServerConfig(true);
    systemTimeOffset = config.date.getTime() - config._retrievedAt.getTime();
    if (systemTimeOffset.toString() !== NaN.toString()) {
      localStorage.setItem(LOCAL_STORAGE_KEY, systemTimeOffset.toString());
      console.log("Setting systemTimeOffset: ", systemTimeOffset);
    }
  } catch (e) {
    console.log(e);
    // No need to start throwing errors and complicate things more. But we do want to record this
    // in case the server time strategy we're using stops working.
    Sentry.captureException(e);
    systemTimeOffset = 0;
  }
}
