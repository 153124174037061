import { Route, Routes } from "react-router-dom";
import Suspender from "react-suspender";
import { useProjectFile, useProjectUploads } from "../project/useProjectState";
import MapmakerPrintServiceSelector from "./MapmakerPrintServiceSelector";
import MapmakerDownloadPrints from "./other/MapmakerDownloadPrints";
import MapmakerOrderStickers from "./stickers/MapmakerOrderStickers";
import MapmakerWalgreensPrints from "./walgreens/MapmakerWalgreensPrints";

export default function ProjectOutputRouter() {
  const file = useProjectFile();
  const { uploading } = useProjectUploads();

  if (uploading) {
    return <Suspender />;
  }
  if (file.saving) {
    return <Suspender />;
  }

  return (
    <Routes>
      <Route path="walgreens" element={<MapmakerWalgreensPrints />} />
      <Route path="download" element={<MapmakerDownloadPrints />} />
      <Route path="stickers" element={<MapmakerOrderStickers />} />
      <Route path="*" element={<MapmakerPrintServiceSelector />} />
    </Routes>
  );
}
