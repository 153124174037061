export function cssColor(mapmakerUIColorOrString) {
    switch (mapmakerUIColorOrString) {
        case "accent":
            return "var(--mm-accent-color)";
        case "dark":
            return "var(--mm-light-black)";
        case "delete":
            return "var(--mm-delete-color)";
        case "normal":
            return "var(--mm-light-grey)";
        default:
            return mapmakerUIColorOrString;
    }
}
export function cssTextColor(mapmakerUIColorOrString) {
    switch (mapmakerUIColorOrString) {
        case "accent":
            return "var(--mm-accent-text-color)";
        case "dark":
            return "var(--mm-light-text-black)";
        case "delete":
            return "var(--mm-delete-text-color)";
        case "normal":
            return "var(--mm-light-text-grey)";
        default:
            return mapmakerUIColorOrString;
    }
}
