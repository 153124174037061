import type {TBLDotComConfig} from ".";

const config:TBLDotComConfig = {
  stage: "prod",
  tblApi: {
    url: "https://es488jaz33.execute-api.us-east-1.amazonaws.com/prod"
  },
  aws: {
    region: "us-east-1",
    cognito: {
      userPoolId: "us-east-1_cWJos8LSd",
      appClientId: "3fbhtb6qk60gluh5ad6eonciv9",
      identityPoolId: "us-east-1:671a6f8f-dbde-49c8-a816-0b9a6c42fdc4",
    },
  },
  shopify: {
    domain: "thunder-bunny-labs.myshopify.com",
    storefrontAccessToken: "7c5da4522c79b2b730bc4b065db24aba",
  },
};
export default config;