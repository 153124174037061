import { gql } from "@apollo/client";
import { ChangeEvent, ComponentType } from "react";
import {
  StorefrontCheckoutLineItemHeavyFragment,
  useStorefrontUpdateCheckoutLineItemsMutation,
} from "../../client/MapmakerApi";
import LineItem from "./LineItem";
import { multiplyMoneyV2 } from "../../lib/storefront";
import GiftNoteOptions from "./GiftNoteOptions";
import { useMapmakerAppConfig, useStorefrontCheckoutId } from "../..";
import StandardLineItemDetails from "./StandardLineItemDetails";
import "./StandardLineItem.css";

gql`
  fragment StorefrontCheckoutLineItemHeavy on StorefrontCheckoutLineItem {
    id
    title
    quantity
    unitPrice {
      amount
      currencyCode
    }
    customAttributes {
      key
      value
    }
    discountAllocations {
      ...StorefrontDiscountAllocation
    }

    variant {
      id
      title
      sku
      priceV2 {
        amount
        currencyCode
      }
      selectedOptions {
        name
        value
      }
      image {
        originalSrc
        transformedSrc(maxWidth: 512, crop: CENTER)
      }
      product {
        id
        handle
        tblProductType: metafield(namespace: "tbl", key: "productType") {
          key
          value
        }
      }
    }
  }
`;

export type StandardLineItemDetailsProps = {
  lineItem: StorefrontCheckoutLineItemHeavyFragment;
  exclude?: string[];
};

export type StandardLineItemDetailsComponent = ComponentType<
  StandardLineItemDetailsProps
>;

export interface IStandardLineItemProps {
  lineItem: StorefrontCheckoutLineItemHeavyFragment;
  DetailsComponent?: StandardLineItemDetailsComponent;
}

export default function StandardLineItem({
  lineItem,
  DetailsComponent = StandardLineItemDetails,
}: IStandardLineItemProps) {
  const checkoutId = useStorefrontCheckoutId();
  const { gotoProduct } = useMapmakerAppConfig();
  const [
    updateCheckoutLineItems,
    { loading: updateLoading },
  ] = useStorefrontUpdateCheckoutLineItemsMutation();
  async function onSetQuantity(e: ChangeEvent<HTMLSelectElement>) {
    await updateCheckoutLineItems({
      variables: {
        checkoutId: checkoutId as string,
        lineItems: [
          {
            id: lineItem.id,
            quantity: parseInt(e.target.value) || lineItem.quantity,
          },
        ],
      },
    });
  }

  return (
    <LineItem
      title={lineItem.title}
      onProductNameClick={() => gotoProduct(lineItem.variant.product.handle)}
      thumbnail={lineItem.variant?.image?.transformedSrc}
      price={multiplyMoneyV2(lineItem.variant?.priceV2, lineItem.quantity)}
      lineItemIds={[lineItem.id]}
      loading={updateLoading}
      discountAllocations={lineItem.discountAllocations}
    >
      <div id="standard-line-item">
        <div>
          <label>Qty</label>
          <select value={lineItem.quantity} onChange={onSetQuantity}>
            {Array(25)
              .fill(0)
              .map((_, i) => {
                const qty = i + 1;
                return (
                  <option key={qty} value={qty}>
                    {qty}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="details">
          <DetailsComponent lineItem={lineItem} />
        </div>
        <div className="gift-note">
          <GiftNoteOptions lineItem={lineItem} />
        </div>
      </div>
    </LineItem>
  );
}
