import { getClient } from "./MapmakerContext";
import {
  MapmakerFileHeavyFragment,
  UpdateFileHeavyDocument,
  UpdateMapmakerFileInput,
} from "./MapmakerApi";

export async function updateFile(
  input: UpdateMapmakerFileInput
): Promise<MapmakerFileHeavyFragment> {
  const client = getClient();

  const result = await client.mutate({
    mutation: UpdateFileHeavyDocument,
    variables: {
      input,
    },
  });
  // TODO - Handle errors?
  return {
    ...result.data.updateMapmakerFile?.file,
  };
}
