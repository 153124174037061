import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import styles from "./TinyText.module.scss";
export default function TinyText(_a) {
    var { fontSize, children } = _a, props = __rest(_a, ["fontSize", "children"]);
    return (_jsx("span", Object.assign({}, props, { className: clsx(styles.tinyText, props.className), style: {
            fontSize,
            lineHeight: fontSize,
        } }, { children: children })));
}
