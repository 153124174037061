import { OutputTypeDemonstration } from "@mapmaker/svg";
import { Icon, ModalContent } from "semantic-ui-react";
import { useMapmakerAppConfig } from "../../../client";
import MapmakerModal, {
  MapmakerModalProps,
} from "../../shared/modals/MapmakerModal";
import { useOrderStickersStore } from "./useOrderStickersStore";
import { getOutputTypeDetails } from "../../project/shared/OutputTypeLibrary";
import "./StickerOutputDescriptionModal.css";

export default function StickerOutputDescriptionModal({
  open,
  onClose,
}: Pick<MapmakerModalProps, "open" | "onClose">) {
  const {
    file,
    design,
    selectedOutputOption,
    otherOutputOptions,
  } = useOrderStickersStore();
  const { gotoContact, gotoEditFile, businessName } = useMapmakerAppConfig();

  // This modal is never shown if we have a custom scale/type.
  if (!selectedOutputOption) {
    return null;
  }

  const multipleOutputOptions = design.outputOptions.length > 1;
  const outputTypeDetails = getOutputTypeDetails(selectedOutputOption.type);

  return (
    <MapmakerModal
      className="sticker-output-description-modal"
      title="Sticker Details"
      open={open}
      onClose={onClose}
    >
      <ModalContent>
        <h3>What will these stickers work with?</h3>
        <div className="output-info">
          <OutputTypeDemonstration
            design={design}
            width={300}
            height={200}
            outputType={selectedOutputOption.type}
            highlightColor="var(--mm-black)"
            primaryColor="var(--mm-dark-grey)"
          />
          <div>
            <p>
              You are ordering {outputTypeDetails.description}. These stickers
              will fit with a <strong>{selectedOutputOption.name}</strong> from{" "}
              {businessName}.
            </p>
            {!multipleOutputOptions && (
              <p>
                If you are trying to create stickers for a different type of map
                please{" "}
                <span className="link" onClick={() => gotoContact()}>
                  contact us
                </span>
                .
              </p>
            )}
          </div>
        </div>
        {multipleOutputOptions && (
          <div className="multiple-output-options-warning">
            <Icon name="info circle" size="big" />
            <div>
              <h5>It doesn't have to be like this!</h5>
              <p>
                Your {design.name} project can also be used to create stickers
                for{" "}
                {otherOutputOptions.length === 1 ? (
                  <>
                    a <strong>{otherOutputOptions[0].name}</strong>.
                  </>
                ) : (
                  <>
                    {otherOutputOptions.slice(0, -1).map(option => (
                      <>
                        a <strong>{option.name}</strong>
                      </>
                    ))}
                    , or a{" "}
                    <strong>
                      {otherOutputOptions[otherOutputOptions.length - 1].name}
                    </strong>
                    .
                  </>
                )}{" "}
                If you would like to switch your output settings:
                <ol>
                  <li>
                    <span
                      className="link"
                      onClick={() => gotoEditFile(file.id)}
                    >
                      Go back to the main project editor.
                    </span>
                  </li>
                  <li>
                    Click the gear icon{" "}
                    <Icon name="setting" style={{ marginRight: 0 }} /> to open
                    the settings.
                  </li>
                  <li>
                    In the <strong>Project Output</strong> section select the
                    type of output you want.
                  </li>
                  <li>
                    Return to this page to create stickers with the new output
                    type.
                  </li>
                </ol>
              </p>
            </div>
          </div>
        )}
      </ModalContent>
    </MapmakerModal>
  );
}
