import { useCallback, useState } from "react";
import useLocalStorage from "react-use/lib/useLocalStorage";

/**
 * A dismissal always starts off as false, and can only move to true persistently. However it can
 * toggle back and forth between tru and false after being dismissed.
 *
 * This is mainly used for showing a user information one time, letting them dismiss it, and then
 * only showing it again if they take an action to show it.
 *
 * Be sure to add the key for any new dismissals from the list above.
 */

type RegisteredDismissal = 
  "mapmaker.timeline-editor-instructions-dismissed";


export function useDismissal(key: RegisteredDismissal):[dismissed:boolean, setDismissed:(value:boolean)=>any] {
  const [dismissedPersistent, setDismissedPersistent] = useLocalStorage(key, false);
  const [dismissedLocal, setDismissedLocal] = useState(dismissedPersistent);
  const setDismissed = useCallback((dismissed: boolean)=>{
    if(dismissed && !dismissedPersistent){
      setDismissedPersistent(true);
    }
    setDismissedLocal(dismissed);
  }, [dismissedPersistent, setDismissedPersistent, setDismissedLocal]);
  return [
    dismissedLocal, 
setDismissed
  ]
}