import { ReactNode, useState } from "react";
import { useAuthContext } from "@tbl/aws-auth";
import { captureException } from "@sentry/react";
import Suspender from "react-suspender";
import { Icon } from "semantic-ui-react";
import { useMapmakerAppConfig } from "../../client";
import MapmakerFileCard from "./MapmakerFileCard";
import MessagePage from "../shared/MessagePage";
import { useGetDesignsAndFilesQuery } from "../../client/MapmakerApi";
import useMapmakerModals from "../shared/modals/useMapmakerModals";
import MapmakerFinderModal from "./MapmakerFinderModal";
import { gql } from "@apollo/client";
import "./MapmakerFinder.css";

gql`
  query getDesignsAndFiles {
    designs: mapmakerDesigns {
      ...MapmakerDesignLite
    }
    files: mapmakerFiles {
      ...MapmakerFileLite
    }
    sharedFiles: sharedMapmakerFiles {
      ...MapmakerFileLite
    }
  }
`;

export type MapmakerFinderProps = {
  noMapsMessage?: ReactNode;
};

export default function MapmakerFinder({
  noMapsMessage = null,
}: MapmakerFinderProps) {
  const { isAuthenticated } = useAuthContext();
  const { setModalRoot } = useMapmakerModals();

  const {
    data: { files, sharedFiles } = {},
    loading,
    error,
  } = useGetDesignsAndFilesQuery({
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Suspender />;
  } else if (error) {
    captureException(error);
    return <MessagePage icon="warning circle">{error.message}</MessagePage>;
  }

  function HeaderMessage() {
    if (files.length === 0) {
      return <>{noMapsMessage}</>;
    } else if (!isAuthenticated) {
      return <GuestModeWarning />;
    } else {
      return null;
    }
  }

  const sortedFiles = files
    .slice(0)
    .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));

  const sortedSharedFiles = sharedFiles
    .slice(0)
    .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));

  return (
    <div id="mapmaker" className="mapmaker-finder">
      <div className="header-message-container">
        <div className="header-message">
          <HeaderMessage />
        </div>
      </div>
      <h2 className="page-title">Your Photo Map Projects</h2>
      <div className="mapmaker-file-grid" ref={setModalRoot}>
        {sortedFiles
          .filter(file => !file.deleted)
          .map(file => (
            <MapmakerFileCard file={file} key={file.id} />
          ))}
        <CreateProjectTile />
      </div>
      {sortedSharedFiles.length > 0 && (
        <>
          <h2 className="page-title">Projects Shared With You</h2>
          <div className="mapmaker-file-grid">
            {sortedSharedFiles.map(file => (
              <MapmakerFileCard file={file} key={file.id} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

function CreateProjectTile() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <MapmakerFinderModal open={open} onClose={() => setOpen(false)} />
      <div className="mapmaker-new-project-tile" onClick={() => setOpen(true)}>
        <div>
          <div className="plus-icon">+</div>
          <div className="label">New Project</div>
        </div>
      </div>
    </>
  );
}

function GuestModeWarning() {
  const { gotoSignUp } = useMapmakerAppConfig();
  return (
    <div id="guest-mode-warning">
      <Icon className="icon" name="info circle" size="huge" />
      <div>
        <h3>
          You are in <strong>Guest Mode</strong>
        </h3>
        You are not signed in. Your projects will only be accessible from this
        browser and will be deleted if you clear your browser data.{" "}
        <a href="#" onClick={() => gotoSignUp(window.location.pathname)}>
          Register for free
        </a>{" "}
        and your projects shown below will be saved to your new account and
        accessible from any device.
      </div>
    </div>
  );
}
