import { Dropdown } from "semantic-ui-react";
import "./ContextPanelSelect.css";

type ContextPanelSelectProps<T extends string> = {
  options: {
    value: T;
    text: string;
    image?: string;
  }[];
  value: T;
  onChange(value: T): any;
  disabled?: boolean;
};

export default function ContextPanelSelect<T extends string = string>({
  options,
  value,
  onChange,
  disabled = false,
}: ContextPanelSelectProps<T>) {
  function changed(_, data) {
    onChange(data.value);
  }

  return (
    <div id="context-panel-select">
      <Dropdown
        placeholder="Select Friend"
        fluid
        selection
        disabled={disabled}
        options={options}
        value={value}
        onChange={changed}
      />
    </div>
  );
}
