import prod from "./TBLDotComConfig.prod";
import type {TBLDotComConfig} from ".";

const config:TBLDotComConfig = {
  ...prod,
  tblApi: {
    url: "https://api-gamma.photomaps.com/gamma"
  },
  stage: "gamma",
};
export default config;