import MapmakerConfig from "@mapmaker/config";
import { OpeningImageTransform } from "@mapmaker/core";
import { btoa } from "isomorphic-base64";
import { DetailedHTMLProps, HTMLAttributes, ImgHTMLAttributes } from "react";

export function pow2ceil(v: number): number {
  if (v <= 0) {
    return 0;
  } else if (v <= 1) {
    return 1;
  } else if (v <= 2) {
    return 2;
  }
  var p = 2;
  while ((v >>= 1)) {
    p <<= 1;
    if (v === 2) {
      break;
    }
  }
  return p * 2;
}

function clamp(value: number, min: number, max: number): number {
  if (value < min) return min;
  if (value > max) return max;
  return value;
}

type GetResizedImageUrlOptions = {
  minEdgeSize: number;
  maxEdgeSize: number;
};

const GetResizedImageUrlOptionDefaults: GetResizedImageUrlOptions = {
  minEdgeSize: 64,
  maxEdgeSize: 4096,
};

// Edits info: https://sharp.pixelplumbing.com/api-resize
// Filter info: https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/appendix-d.html
export function getResizedImageProps(
  key: string,
  desiredWidth: number,
  desiredHeight: number,
  options: Partial<GetResizedImageUrlOptions> = {}
): Pick<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  "src" | "srcSet"
> {
  return {
    src: getResizedImageUrl(key, desiredWidth, desiredHeight, options),
    srcSet: getResizedImageSrcSet(key, desiredWidth, desiredHeight, options),
  };
}

// Edits info: https://sharp.pixelplumbing.com/api-resize
// Filter info: https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/appendix-d.html
export function getResizedImageUrl(
  key: string,
  desiredWidth: number,
  desiredHeight: number,
  options: Partial<GetResizedImageUrlOptions> = {}
): string {
  const { minEdgeSize, maxEdgeSize } = {
    ...GetResizedImageUrlOptionDefaults,
    ...options,
  };
  const edgeSize = clamp(
    pow2ceil(Math.max(desiredWidth, desiredHeight)),
    minEdgeSize, // Min edge size
    maxEdgeSize // Max edge size
  );

  // Images at 4096 x 4096 sometimes exceed the 6mb APIGateway limit, which causes them to fail.
  // Bumping the quality down seems to fix the issue.
  const quality = edgeSize > 3000 ? 75 : 100;

  const imageRequest = JSON.stringify({
    bucket: MapmakerConfig.imageServerBucket,
    key,
    edits: {
      resize: {
        width: edgeSize,
        height: edgeSize,
        fit: "inside",
      },
      // Only add the quality settings if the quality is not 100, so we don't have to re-cache every
      // existing image.
      ...(quality === 100
        ? {}
        : {
            jpeg: {
              quality,
            },
          }),
    },
  });

  return `${MapmakerConfig.userContentImageServerUrl}/${btoa(imageRequest)}`;
}

/**
 * Returns a srcset string at 1x and 2x the size. Specify the desired size at 1x.
 */
export function getResizedImageSrcSet(
  key: string,
  desiredWidth: number,
  desiredHeight: number,
  options: Partial<GetResizedImageUrlOptions> = {}
): string {
  const at1x = getResizedImageUrl(key, desiredWidth, desiredHeight, options);
  const at2x = getResizedImageUrl(
    key,
    desiredWidth * 2,
    desiredHeight * 2,
    options
  );
  return [`${at1x} 1x`, `${at2x} 2x`].join(",");
}

/*
 * Determines if one opening image is completely contained by another. Useful for determining if an
 * opening image is completely occluded by another.
 */
export function openingImageContains(
  container: OpeningImageTransform,
  contained: OpeningImageTransform
) {
  const bPoints = getCornerPoints(contained);
  return bPoints.every((point) => rectContainsPoint(container, point));
}

// Get the corner points adjusted for rotation
function getCornerPoints(rect: OpeningImageTransform) {
  const { x, y, width, height, rotation } = rect;
  const angle = (rotation * Math.PI) / 180;
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  const topLeft = {
    x: x + cos * -halfWidth - sin * -halfHeight + halfWidth,
    y: y + sin * -halfWidth + cos * -halfHeight + halfHeight,
  };
  const topRight = {
    x: x + cos * halfWidth - sin * -halfHeight + halfWidth,
    y: y + sin * halfWidth + cos * -halfHeight + halfHeight,
  };
  const bottomLeft = {
    x: x + cos * -halfWidth - sin * halfHeight + halfWidth,
    y: y + sin * -halfWidth + cos * halfHeight + halfHeight,
  };
  const bottomRight = {
    x: x + cos * halfWidth - sin * halfHeight + halfWidth,
    y: y + sin * halfWidth + cos * halfHeight + halfHeight,
  };
  return [topLeft, topRight, bottomLeft, bottomRight];
}

// determine if a rectangle contains a point, including the rotation of the rectangle.
function rectContainsPoint(
  rect: OpeningImageTransform,
  point: { x: number; y: number }
) {
  // Get the center of the rectangle
  var center = {
    x: rect.x + rect.width / 2,
    y: rect.y + rect.height / 2,
  };

  // Get the angle of rotation
  var angle = rect.rotation;

  // Calculate the distance between the point and the center of the rectangle
  var distance = Math.sqrt(
    (point.x - center.x) * (point.x - center.x) +
      (point.y - center.y) * (point.y - center.y)
  );

  // Get the original angle of the point from the center
  var originalAngle = Math.atan2(point.y - center.y, point.x - center.x);

  // Calculate the sine and cosine of the angle of rotation
  var sin = Math.sin(angle + originalAngle);
  var cos = Math.cos(angle + originalAngle);

  // Calculate the new coordinates of the point
  var newX = center.x + distance * cos;
  var newY = center.y + distance * sin;

  // Check if the new point is inside the rectangle
  return (
    newX >= rect.x &&
    newX <= rect.x + rect.width &&
    newY >= rect.y &&
    newY <= rect.y + rect.height
  );
}
