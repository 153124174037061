import { PropsWithChildren, HTMLAttributes, DetailedHTMLProps } from "react";
import "./MapmakerToolbar.css";

type MapmakerToolbarProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  PropsWithChildren<{
    color?: "dark" | "light";
  }>;

export default function MapmakerToolbar({
  color = "dark",
  children,
  ...props
}: MapmakerToolbarProps) {
  props.className = `mapmaker-toolbar color-${color} ${props?.className ?? ""}`;
  return <div {...props}>{children}</div>;
}
