import { SerializerProps } from "./shared";
import { useSanityImageUrlBuilder } from "./utils";
import "./ImageSerializer.css";

export type ImageSerializerProps = SerializerProps<{
  caption?: string;
  image: any;
}>;

export default function ImageSerializer({
  node: { image, caption },
}: ImageSerializerProps) {
  const builder = useSanityImageUrlBuilder();
  if (!image) {
    return null;
  }
  return (
    <div id="sanity-image">
      <img
        className="image"
        alt={caption}
        src={builder.image(image.asset).url()}
      />
      {caption && <div className="caption">{caption}</div>}
    </div>
  );
}
