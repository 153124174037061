import { Field, useFormikContext } from "formik";
import useUpdateEffect from "react-use/lib/useUpdateEffect";
import { RenderableInputProps } from ".";
import { useValidateRenderableInput } from "./renderableInputUtils";
import { useGetDesignQuery } from "../../../client/MapmakerApi";
import { GraphicFeature } from "@mapmaker/core";
import { useMemo } from "react";
import clsx from "clsx";
import "./RenderableInput.css";

export type RenderableDesignGraphicInputProps = RenderableInputProps<
  "design-graphic",
  string,
  {
    graphicName: string;
    designProperty: string;
  }
>;

export default function RenderableDesignGraphicInput({
  id,
  name,
  description,
  validation,
  graphicName,
  designProperty,
}: RenderableDesignGraphicInputProps) {
  const validate = useValidateRenderableInput(id, validation);
  const formik = useFormikContext();
  const designId = formik.values[designProperty]?.DESIGN;
  const { loading, data } = useGetDesignQuery({
    variables: {
      id: designId,
    },
    skip: !designId,
    onCompleted: value => {
      const graphic = value.design?.features[graphicName] as GraphicFeature;
      if (graphic?.options.length > 1 && !formik.values[id]) {
        formik.setFieldValue(id, graphic.options[0].id);
      }
    },
  });

  // When the file changes -clear any selection.
  useUpdateEffect(() => {
    if (!designId) {
      formik.setFieldValue(id, null);
    }
  }, [designId]);

  const graphic = useMemo(
    () => data?.design?.features[graphicName] as GraphicFeature,
    [data?.design]
  );

  return (
    <Field type="string" name={id} validate={validate}>
      {({ field, meta }) => (
        <div
          id="renderable-input"
          className={clsx({ error: meta.touched && meta.error, loading })}
        >
          <div className="above">
            <label>{name}</label>
          </div>
          <select type="text" {...field}>
            {!designId && <option disabled>-</option>}
            {loading && <option disabled>LOADING</option>}
            {graphic && (
              <>
                {graphic.options.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </>
            )}
          </select>
          {description && <div className="description">{description}</div>}
          {meta.touched && meta.error && meta.value && (
            <div className="error">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
}
