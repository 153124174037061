import * as Sentry from "@sentry/react";
import useLocalStorage from "react-use/lib/useLocalStorage";
import useLiveLocalStorage from "../../lib/hooks/useLiveLocalStorage";
import {
  FetchResult,
  gql,
  MutationResult,
  QueryHookOptions,
} from "@apollo/client";
import { useAuthContext } from "@tbl/aws-auth";
import {
  StorefrontAddCheckoutLineItemsMutationVariables,
  useStorefrontCreateCheckoutMutation,
  useStorefrontGetCheckoutHeavyQuery,
  useStorefrontAddCheckoutLineItemsMutation,
  useStorefrontGetCheckoutLightQuery,
  StorefrontCheckoutHeavyFragment,
  StorefrontCheckoutLightFragment,
  StorefrontAddCheckoutLineItemsMutation,
  StorefrontGetCheckoutHeavyQuery,
  Exact,
  StorefrontGetCheckoutLightQuery,
} from "../MapmakerApi";

gql`
  fragment StorefrontCheckoutLight on StorefrontCheckout {
    id
    completedAt
    lineItems(first: 100) {
      edges {
        node {
          id
          quantity
        }
      }
    }
  }

  query storefrontGetCheckoutLight($checkoutId: ID!) {
    storefrontCheckout: storefrontNode(id: $checkoutId) {
      id
      ... on StorefrontCheckout {
        ...StorefrontCheckoutLight
      }
    }
  }

  mutation storefrontCreateCheckout($input: StorefrontCheckoutCreateInput!) {
    storefrontCheckoutCreate(input: $input) {
      checkout {
        ...StorefrontCheckoutHeavy
      }
    }
  }

  mutation storefrontAddCheckoutLineItems(
    $checkoutId: ID!
    $lineItems: [StorefrontCheckoutLineItemInput!]!
  ) {
    storefrontCheckoutLineItemsAdd(
      checkoutId: $checkoutId
      lineItems: $lineItems
    ) {
      checkout {
        ...StorefrontCheckoutHeavy
      }
    }
  }
`;

const STOREFRONT_CHECKOUT_ID_KEY = "mapmaker.shopify.checkoutId";

// These were used until Feb. 2022, they can be safely removed in 2023.
const STOREFRONT_CHECKOUT_ID_KEY_PM = "pm.shopify.checkoutId";
const STOREFRONT_CHECKOUT_ID_KEY_TBL = "tbl.shopify.checkoutId";

/**
 * A very simple local storage value, but some extra logic to deal with merging TBL and PM
 * functionality.
 */
export function useStorefrontCheckoutId() {
  const [pmCheckoutId, , clearPmCheckoutId] = useLocalStorage<string>(
    STOREFRONT_CHECKOUT_ID_KEY_PM
  );
  const [tblCheckoutId, , clearTblCheckoutId] = useLocalStorage<string>(
    STOREFRONT_CHECKOUT_ID_KEY_TBL
  );
  const [checkoutId, setCheckoutId] = useLiveLocalStorage<string>(
    STOREFRONT_CHECKOUT_ID_KEY
  );
  if (pmCheckoutId) {
    clearPmCheckoutId();
    setCheckoutId(pmCheckoutId);
  }
  if (tblCheckoutId) {
    clearTblCheckoutId();
    setCheckoutId(tblCheckoutId);
  }
  return checkoutId ?? pmCheckoutId ?? tblCheckoutId;
}

export function useClearStorefrontCheckoutId() {
  const [, , clearCheckoutId] = useLiveLocalStorage<string>(
    STOREFRONT_CHECKOUT_ID_KEY
  );
  return clearCheckoutId;
}

function useStorefrontCheckoutIdFull() {
  return useLiveLocalStorage<string>(STOREFRONT_CHECKOUT_ID_KEY);
}

export function useStorefrontCheckoutLight(
  queryOptions?: Partial<
    QueryHookOptions<
      StorefrontGetCheckoutLightQuery,
      Exact<{
        checkoutId: string;
      }>
    >
  >
) {
  const checkoutId = useStorefrontCheckoutId();
  const clearCheckout = useClearStorefrontCheckoutId();
  const result = useStorefrontGetCheckoutLightQuery({
    ...queryOptions,
    skip: !checkoutId,
    variables: {
      checkoutId,
    },
    onCompleted: result => {
      if (
        !result.storefrontCheckout ||
        !!result.storefrontCheckout["completedAt"]
      ) {
        clearCheckout();
      }
    },
  });

  const checkout =
    (result.data?.storefrontCheckout as StorefrontCheckoutLightFragment) ??
    undefined;

  return {
    checkout,
    ...result,
  };
}

export function useStorefrontCheckoutHeavy(
  queryOptions?: Partial<
    QueryHookOptions<
      StorefrontGetCheckoutHeavyQuery,
      Exact<{
        checkoutId: string;
      }>
    >
  >
) {
  const checkoutId = useStorefrontCheckoutId();
  const clearCheckout = useClearStorefrontCheckoutId();
  const result = useStorefrontGetCheckoutHeavyQuery({
    ...queryOptions,
    skip: !checkoutId,
    variables: {
      checkoutId,
    },
    onCompleted: result => {
      const checkout =
        (result.storefrontCheckout as StorefrontCheckoutHeavyFragment) ??
        undefined;
      if (!checkout || !!checkout.completedAt) {
        clearCheckout();
      }
    },
  });

  const checkout =
    (result.data?.storefrontCheckout as StorefrontCheckoutHeavyFragment) ??
    undefined;

  if (checkout?.lineItems.edges.some(edge => !edge.node.variant)) {
    // Old item was in cart.
    clearCheckout();
  }

  return {
    checkout,
    ...result,
  };
}

export function useStorefrontAddCheckoutLineItems(): [
  (
    lineItems: StorefrontAddCheckoutLineItemsMutationVariables["lineItems"]
  ) => Promise<
    FetchResult<
      StorefrontAddCheckoutLineItemsMutation,
      Record<string, any>,
      Record<string, any>
    >
  >,
  MutationResult<StorefrontAddCheckoutLineItemsMutation>
] {
  const { user } = useAuthContext();
  const [checkoutId, setCheckoutId] = useStorefrontCheckoutIdFull();

  // Used when we need to create the checkout.
  const [
    createCheckoutMutation,
    createCheckoutStatus,
  ] = useStorefrontCreateCheckoutMutation({
    variables: {
      input: {
        email: user?.email,
      },
    },
  });

  // Used once we have a checkout.
  const [
    addCheckoutLineItemsMutation,
    addCheckoutLineItemsStatus,
  ] = useStorefrontAddCheckoutLineItemsMutation();

  const createCheckout = async () => {
    if (checkoutId) {
      return checkoutId;
    }

    const result = await createCheckoutMutation();
    const newCheckout = result.data?.storefrontCheckoutCreate?.checkout;
    if (newCheckout) {
      setCheckoutId(newCheckout.id);
      return newCheckout.id;
    } else {
      Sentry.captureException("Error creating checkout.", {
        extra: {
          storefrontApiResult: result.data,
          storefrontApiErrors: result.errors,
        },
      });
      throw new Error(
        "Error communicating with shop. Refresh the page or contact us if the problem persists."
      );
    }
  };

  // The method exposed to consumers, accepts line items and adds them to the cart, handling
  // creation if necessary.
  const addMethod = async (
    lineItems: StorefrontAddCheckoutLineItemsMutationVariables["lineItems"]
  ) => {
    const validCheckoutId = checkoutId ?? (await createCheckout());
    return await addCheckoutLineItemsMutation({
      variables: {
        checkoutId: validCheckoutId,
        lineItems,
      },
    });
  };
  return [
    addMethod,
    {
      ...addCheckoutLineItemsStatus,
      loading:
        createCheckoutStatus.loading || addCheckoutLineItemsStatus.loading,
      error: createCheckoutStatus.error || addCheckoutLineItemsStatus.error,
      called: createCheckoutStatus.called || addCheckoutLineItemsStatus.called,
    },
  ];
}
