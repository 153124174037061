import MapmakerModal from "../../shared/modals/MapmakerModal";
import { useState } from "react";
import { Icon, ModalActions, ModalContent } from "semantic-ui-react";
import useOpening from "../features/opening/useOpening";
import {
  getOpeningImageDate,
  getOpeningImageDateAccuracy,
} from "@mapmaker/core";
import clsx from "clsx";
import useOpeningImage from "../features/opening/useOpeningImage";
import { getResizedImageProps } from "@mapmaker/svg/dist/components/opening/imageUtils";
import TimelineDateInput from "./TimelineDateInput";
import { format } from "date-fns";
import { OpeningStickerPreviewSvg } from "@mapmaker/svg";
import { useProjectFile } from "../useProjectState";
import MapmakerButton from "../../shared/buttons/MapmakerButton";
import "./TimelineEditorOpeningModal.css";

type TimelineEditorOpeningModalProps = {
  openingId: string;
  open: boolean;
  onClose?(): any;
};

export default function TimelineEditorOpeningModal({
  openingId,
  onClose,
  open,
}: TimelineEditorOpeningModalProps) {
  const file = useProjectFile();
  const { openingFeature: opening, openingInput } = useOpening(openingId);
  const hasMultiplePhotos = openingInput.images.length > 1;
  const [mode, setMode] = useState<"opening" | "image">("opening");

  return (
    <MapmakerModal
      key="timeline-editor-opening-modal"
      open={open}
      title={`${opening.name} timeline`}
      onClose={onClose}
    >
      <ModalContent id="timeline-editor-opening-modal">
        {hasMultiplePhotos && (
          <div className="explanation">
            <Icon className="icon" name="info circle" size="huge" />
            <p>
              There are {openingInput.images.length} images in {opening.name}.
              {mode === "opening" ? (
                <>
                  You can use the calendar below to set the date for all of them
                  as a group, or you can set the dates individually on each
                  image.
                </>
              ) : (
                <>
                  You can set dates for them each individually, or click the
                  button below to set the date once for all images.
                </>
              )}
            </p>
            <MapmakerButton
              className="button"
              onClick={() => setMode(mode === "image" ? "opening" : "image")}
            >
              {mode === "opening" ? "Set Dates Individually" : "Set Date Once"}
            </MapmakerButton>
          </div>
        )}
        {mode === "opening" ? (
          <div className="opening-mode">
            <div className="svg">
              <OpeningStickerPreviewSvg
                file={file}
                openingId={openingId}
                responsive
              />
            </div>
            <div className="opening-input">
              <h3>When did you visit {opening.name}?</h3>
              <div className="datepicker">
                <TimelineDateInput openingId={opening.id} inline />
              </div>
            </div>
          </div>
        ) : (
          <div className="image-mode">
            <div className="image-list">
              {openingInput.images.map(image => (
                <OpeningImageRow
                  key={image.id}
                  openingId={openingId}
                  imageId={image.id}
                />
              ))}
            </div>
          </div>
        )}
      </ModalContent>
      <ModalActions>
        <MapmakerButton color="accent" onClick={onClose}>
          Done
        </MapmakerButton>
      </ModalActions>
    </MapmakerModal>
  );
}

type OpeningImageRowProps = {
  imageId: string;
  openingId: string;
};

function OpeningImageRow({ openingId, imageId }: OpeningImageRowProps) {
  const { image } = useOpeningImage(openingId, imageId);
  const accuracy = getOpeningImageDateAccuracy(image);

  return (
    <div id="opening-image-row">
      <div className="image">
        <img
          className="thumbnail"
          {...getResizedImageProps(image.id, 128, 128)}
        />
        <div className={clsx("current-date", accuracy)}>
          {format(new Date(...getOpeningImageDate(image)), "PPP")}
        </div>
      </div>
      <div className="info">
        <div className="inferred">
          <div className="date">
            <div className="label">Uploaded</div>
            <div className="value">
              <SafeDate dateString={image.createdTime} />
            </div>
          </div>
          <div className="date">
            <div className="label">File Timestamp</div>
            <div className="value">
              <SafeDate dateString={image.timeTakenExif} />
            </div>
          </div>
        </div>
        <div className="explicit">
          <div className="date">
            <div className="label">Your Date</div>
            <div className="value">
              <TimelineDateInput
                openingId={openingId}
                openingImageId={imageId}
                onlyUserDate
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type SafeDateProps = {
  dateString: string;
};

function SafeDate({ dateString }: SafeDateProps) {
  if (dateString) {
    return <>{format(new Date(dateString), "PPP")}</>;
  } else {
    return <span className="unknown">Unknown</span>;
  }
}
