import { DetailedHTMLProps, HTMLAttributes, LegacyRef } from "react";
import "./OpeningViewLayout.css";

type DivProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "id" | "ref"
> & {
  innerRef?: LegacyRef<HTMLDivElement>;
};

export default function OpeningViewLayout({
  innerRef,
  children,
  ...props
}: DivProps) {
  return (
    <div id="opening-view-layout" ref={innerRef} {...props}>
      {children}
    </div>
  );
}

OpeningViewLayout.Main = function({ innerRef, children, ...props }: DivProps) {
  return (
    <div id="opening-view-layout-main" ref={innerRef} {...props}>
      {children}
    </div>
  );
};
