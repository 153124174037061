import MapmakerConfig from "@mapmaker/config";
import { Button } from "@mapmaker/ui";
import { FocusEvent, useState } from "react";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { ModalActions, ModalContent } from "semantic-ui-react";
import { useMapmakerAppConfig } from "../../../client";
import MapmakerModal from "../../shared/modals/MapmakerModal";
import "./GiftNoteModal.css";

type GiftNoteModalProps = {
  open: boolean;
  initialGiftNote: string;
  onChange(giftNote: string): any;
  onClose(): any;
};

export default function GiftNoteModal({
  initialGiftNote,
  onChange,
  onClose,
  open,
}: GiftNoteModalProps) {
  const [note, setNote] = useState(initialGiftNote);
  const { businessId } = useMapmakerAppConfig();

  const onNoteChange = (e: FocusEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const onSubmit = () => {
    onChange(note);
    onClose();
  };

  return (
    <MapmakerModal
      title="Add a Gift Note"
      size="tiny"
      onClose={onClose}
      open={open}
    >
      <ModalContent id="gift-note-modal">
        <div className="note">
          <div className="input">
            <img
              className="bow"
              src={`${MapmakerConfig.staticUrl}/images/products/gift-bow-${businessId}.svg`}
            />
            <textarea
              defaultValue={initialGiftNote}
              placeholder="Type your message here"
              onChange={onNoteChange}
              rows={6}
            />
          </div>
        </div>
        <p className="info">
          We'll include this note in the package. Our products are given as
          gifts very often so we never include pricing in the box, but we do
          include full instructions so the recipient will have no problem
          getting started.
        </p>
      </ModalContent>
      <ModalActions>
        <Button type="submit" onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button color="accent" type="submit" onClick={onSubmit}>
          <FaPlus fontSize={10} /> Add Note
        </Button>
      </ModalActions>
    </MapmakerModal>
  );
}
