import { Rectangle } from "maxrects-packer";
import { MapmakerFile, StickerOrder, StickerOrderItem } from "@mapmaker/core";

export const enum BinShrinkMode {
  None = "NONE",
  Both = "BOTH",
  Height = "HEIGHT",
  Width = "WIDTH",
}

export type NestingOptions = {
  width: number;
  minWidth?: number;
  height: number;
  minHeight?: number;
  border?: number;
  padding?: number;
  shrinkMode?: BinShrinkMode;
  shrinkModeSinglePage?: BinShrinkMode;
  // Center the content in the resulting SVG
  center?: boolean;
};

export type OpeningRectangle = Rectangle & {
  openingId: string;
  stickerOrderId: string;
  scale: number;
  alreadyRotated: boolean;
};

export type NestableStickerOrder = Pick<StickerOrder, "id" | "outputType"> & {
  file: Pick<MapmakerFile, "id">;
  stickers: Pick<StickerOrderItem, "openingId" | "quantity" | "scale">[];
};

export * from "./StickerNester";
