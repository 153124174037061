import clsx from "clsx";
import OpeningImagePreview from "./OpeningImagePreview";
import {
  setActiveImage,
  setHighlightedImage,
} from "./editable/editableOpeningReducer";
import MapmakerTileBar from "../../shared/tilebar/MapmakerTilebar";
import { getPrintDPIFromOpeningImage } from "../../../printing/PrintDPI";
import { useProjectFile } from "../../useProjectState";
import { useMapmakerAppConfig } from "../../../../client";
import useEditableOpening from "./editable/useEditableOpening";
import { useEditableOpeningDispatch } from "./editable/EditableOpeningStore";
import useProjectOpeningSuggestions from "./suggestions/useOpeningSuggestions";
import "./OpeningImagesBar.css";
import useLatest from "../../../../lib/hooks/useLatest";

export interface IOpeningImagesBarProps {
  openingId: string;
}

export default function OpeningImagesBar() {
  const dispatch = useEditableOpeningDispatch();
  const { gotoEditFile } = useMapmakerAppConfig();
  const file = useProjectFile();
  const {
    openingId,
    openingInput,
    uploadImage,
    activeImage,
    highlightedImageId,
  } = useEditableOpening();
  const reversedImages = [...openingInput.images].reverse();
  const openingSuggestions = useProjectOpeningSuggestions({
    openingId,
  });
  const highlightedImageIdRef = useLatest(highlightedImageId);

  return (
    <MapmakerTileBar id="opening-images-bar">
      <OpeningImagePreview.ViewSuggestions
        count={openingSuggestions.length}
        onClick={() =>
          gotoEditFile(
            file.id,
            "collaborate",
            openingId,
            /* Go straight to the suggestion if there is only 1 */
            openingSuggestions.length === 1
              ? openingSuggestions[0].id
              : undefined
          )
        }
      />
      <OpeningImagePreview.Add onClick={uploadImage} />
      {reversedImages.map(image => {
        const selected = image.id === activeImage?.id;
        function toggle() {
          if (selected) {
            dispatch(setActiveImage(null));
          } else {
            dispatch(setHighlightedImage(null));
            dispatch(setActiveImage(image.id));
          }
        }

        return (
          <OpeningImagePreview
            key={image.id}
            className={clsx({
              "image-thumbnail": true,
              selected,
            })}
            image={image}
            srcWidth={64}
            srcHeight={64}
            printDpi={getPrintDPIFromOpeningImage(image, file.outputScale)}
            onClick={toggle}
            onMouseEnter={() =>
              !selected && dispatch(setHighlightedImage(image.id))
            }
            onMouseLeave={() => {
              // Short timeout avoids the annoying flicker when mousing between all thumbnails.
              setTimeout(() => {
                if (highlightedImageIdRef.current === image.id) {
                  dispatch(setHighlightedImage(null));
                }
              }, 100);
            }}
          />
        );
      })}
    </MapmakerTileBar>
  );
}
