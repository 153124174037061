import React from "react";
import {
  RenderableComponentType,
  RenderablePropsWithResources,
  RenderableType,
} from "@mapmaker/renderable";
import AllOpeningsPrintSvg from "../components/opening/AllOpeningsSvg";
import CutSvg from "../components/laser/CutSvg";
import DebugSvg from "../components/debug/DebugSvg";
import FilePreviewSvg from "../components/filePreview/FilePreviewSvg";
import OpeningsPrintSvg from "../components/opening/OpeningsPrintSvg";
import OpeningStickerPreviewSvg from "../components/opening/OpeningStickerPreviewSvg";
import OpeningSuggestionSvg from "../components/opening/OpeningSuggestionSvg";
import OutputTypeDemonstration from "../components/outputType/OutputTypeDemonstration";
import PhotomapsDotComProductSvg from "../components/product/pm/PhotomapsDotComProductSvg";
import RegionCompletionSvg from "../components/social/RegionCompletionSvg";
import SimpleMessage from "../components/other/SimpleMessage";
import TblProductSvg from "../components/product/tbl/TblProductSvg";

const RenderableComponentsMap: Record<RenderableType, RenderableComponentType> =
  {
    // An error in one property likely means that component does not have props which implement
    // RenderableComponentProps
    all_openings: AllOpeningsPrintSvg,
    cut: CutSvg,
    debug: DebugSvg,
    file_preview: FilePreviewSvg,
    opening_sticker_preview: OpeningStickerPreviewSvg,
    openings_print: OpeningsPrintSvg,
    opening_suggestion: OpeningSuggestionSvg,
    output_type_demonstration: OutputTypeDemonstration,
    photomaps_product: PhotomapsDotComProductSvg,
    region_completion: RegionCompletionSvg,
    simple_message: SimpleMessage,
    tbl_product: TblProductSvg,
  };

export default function RenderableComponent(
  props: RenderablePropsWithResources
) {
  const Component = RenderableComponentsMap[props.type];
  return <Component {...props} />;
}
