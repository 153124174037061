import { ComponentType } from "react";
import { SchemaOf } from "yup";
import RenderableDesignGraphicInput, {
  RenderableDesignGraphicInputProps,
} from "./RenderableDesignGraphicInput";
import RenderableDesignInput, {
  RenderableDesignInputProps,
} from "./RenderableDesignInput";
import RenderableFileInput, {
  RenderableFileInputProps,
} from "./RenderableFileInput";
import RenderableFileOpeningInput, {
  RenderableFileOpeningInputProps,
} from "./RenderableFileOpeningInput";
import RenderableOpeningSuggestionInput, {
  RenderableOpeningSuggestionInputProps,
} from "./RenderableOpeningSuggestionInput";
import RenderableNumberInput, {
  RenderableNumberInputProps,
} from "./RenderableNumberInput";
import RenderableRectangularPrintLayoutInput, {
  RenderableRectangularPrintLayoutInputProps,
} from "./RenderableRectangularPrintLayoutInput";
import RenderableSelectInput, {
  RenderableSelectInputProps,
} from "./RenderableSelectInput";
import RenderableStringInput, {
  RenderableStringInputProps,
} from "./RenderableStringInput";
import RenderableRectangularPrintOptionsInput, {
  RenderableRectangularPrintOptionsInputProps,
} from "./RenderableRectangularPrintOptionsInput";

/**
 * Input Types
 *
 * How to add a new input type (eg. a color input).
 *  Step 1. Create the input type component, who's properties use RenderableInputProps<"new_type", ...>
 *  Step 2. Add the component prop type to the RenderableInput union below
 *  Step 3. Add the type and component to the RenderableInputComponentMap here
 *  Step 4. Use it by adding an input to a renderable form with the type of "new_type"
 */

export type RenderableInput =
  | RenderableDesignInputProps
  | RenderableDesignGraphicInputProps
  | RenderableFileInputProps
  | RenderableFileOpeningInputProps
  | RenderableOpeningSuggestionInputProps
  | RenderableNumberInputProps
  | RenderableRectangularPrintLayoutInputProps
  | RenderableRectangularPrintOptionsInputProps
  | RenderableSelectInputProps
  | RenderableStringInputProps;

const RenderableInputComponentMap: Record<
  RenderableInput["type"],
  ComponentType<RenderableInput>
> = {
  design: RenderableDesignInput,
  "design-graphic": RenderableDesignGraphicInput,
  file: RenderableFileInput,
  "file-opening": RenderableFileOpeningInput,
  number: RenderableNumberInput,
  opening_suggestion: RenderableOpeningSuggestionInput,
  "rectangular-print-layout": RenderableRectangularPrintLayoutInput,
  "rectangular-print-options": RenderableRectangularPrintOptionsInput,
  select: RenderableSelectInput,
  string: RenderableStringInput,
};

export type RenderableInputProps<
  Type extends string,
  ValueType,
  Custom extends object = {}
> = Custom & {
  type: Type;
  id: string;
  name: string;
  description?: string;
  validation?: SchemaOf<ValueType>;
};

type RenderableInputsProps = {
  inputs: RenderableInput[];
};

export default function RenderableInputs({ inputs }: RenderableInputsProps) {
  return (
    <>
      {inputs.map((input) => {
        const Component = RenderableInputComponentMap[input.type];
        return <Component key={input.id} {...input} />;
      })}
    </>
  );
}
