import clsx from "clsx";
import { HiPlus } from "@react-icons/all-files/hi/HiPlus";
import { addMessage } from "../../shared/messageReducer";
import { useProjectDispatch } from "../projectStore";
import useProjectState from "../useProjectState";
import "./FeatureExitButton.css";
import { Button } from "@mapmaker/ui";

export interface IFeatureExitButtonProps {
  disabled?: boolean;
  onClick(): any;
  onClickWhileDisabled?(): any;
}

export default function FeatureExitButton({
  disabled = false,
  onClick,
  onClickWhileDisabled = () => {},
}: IFeatureExitButtonProps) {
  return (
    <Button
      onClick={onClick}
      onClickWhileDisabled={onClickWhileDisabled}
      size="small"
      outlined
      id="feature-exit-button"
    >
      <HiPlus
        className="icon"
        onClick={disabled ? onClickWhileDisabled : onClick}
      />
    </Button>
  );
}

/*
type EditorFeatureExitButtonProps = {
  onClick: () => any;
};

export function EditorFeatureExitButton({
  onClick,
}: EditorFeatureExitButtonProps) {
  const dispatch = useProjectDispatch();
  const { uploads } = useProjectState();
  return (
    <FeatureExitButton
      disabled={uploads.length > 0}
      onClick={onClick}
      onClickWhileDisabled={() =>
        dispatch(
          addMessage({
            id: "opening-editor-images-uploading",
            type: "info",
            content:
              "Some files are still uploading. Please wait until they are complete to return to the main project view.",
          })
        )
      }
    />
  );
}
*/
