import { produce } from "immer";
import { getInputs, OpeningInput } from "@mapmaker/core";
import { MapmakerFileHeavyFragment } from "../../../client/MapmakerApi";

export type PrintQuantitiesState = { [key: string]: number };

export const SET_OPENING_PRINT_QUANTITIES =
  "print_quantity.set_opening_print_quantities";
export const SET_ALL_PRINT_QUANTITIES =
  "print_quantity.set_all_print_quantities";

export function setOpeningPrintQuantity(openingId: string, quantity: number) {
  return {
    type: SET_OPENING_PRINT_QUANTITIES,
    openingIds: [openingId],
    quantity,
  };
}

export function setOpeningPrintQuantities(
  openingIds: string[],
  quantity: number,
  setOthersToZero: boolean = false
) {
  return {
    type: SET_OPENING_PRINT_QUANTITIES,
    openingIds,
    quantity,
    setOthersToZero,
  };
}

export function setAllPrintQuantities(quantity: number) {
  return {
    type: SET_ALL_PRINT_QUANTITIES,
    quantity,
  };
}

function getInitialState(file: MapmakerFileHeavyFragment) {
  const openings = getInputs<OpeningInput>(file, "OPENING");
  return Object.entries(openings).reduce(
    (quantities, [openingId, openingInput]) => {
      if ((openingInput?.enabled ?? openings[openingId].enabled) === false) {
        return quantities;
      }
      // Maybe fancier logic in the future for "already printed" photos?
      if (openingInput.images.length > 0) {
        quantities[openingId] = 1;
      }
      return quantities;
    },
    {}
  );
}

export default function createPrintQuantitiesReducer(
  file: MapmakerFileHeavyFragment
) {
  return produce(
    (draft: PrintQuantitiesState, action): PrintQuantitiesState => {
      if (draft === undefined) {
        return getInitialState(file);
      }

      switch (action.type) {
        case SET_ALL_PRINT_QUANTITIES:
          Object.keys(draft).forEach(openingId => {
            draft[openingId] = action.quantity;
          });
          break;
        case SET_OPENING_PRINT_QUANTITIES:
          if (action.setOthersToZero) {
            Object.keys(draft).forEach(openingId => {
              draft[openingId] = 0;
            });
          }
          action.openingIds.forEach(openingId => {
            draft[openingId] = Math.min(Math.max(action.quantity, 0), 999);
          });
          break;
        default:
          break;
      }
    }
  );
}
