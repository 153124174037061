import { PropsWithChildren } from "react";
import { LinkButton } from "@mapmaker/ui";
import CenteredPage from "../../components/shared/CenteredPage";
import "./ErrorPage.css";

export type ErrorPageProps = {
  message?: string;
  action?:
    | "contact"
    | {
        to: string;
        label: string;
      };
  stretchHeight?: boolean;
};

export default function ErrorPage({
  message,
  action,
  children,
}: PropsWithChildren<ErrorPageProps>) {
  if (action === "contact") {
    action = {
      to: "/contact",
      label: "Contact Us",
    };
  }

  return (
    <CenteredPage className="mapmaker-error-page">
      <h1>Uh oh!</h1>
      <p className="message">{message ?? children}</p>
      {action && (
        <div style={{ textAlign: "center", marginTop: "1em" }}>
          <LinkButton to={action?.to} color="accent">
            {action?.label}
          </LinkButton>
        </div>
      )}
    </CenteredPage>
  );
}
