import { MouseEvent } from "react";
import { gql } from "@apollo/client";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import useCopyToClipboard from "react-use/lib/useCopyToClipboard";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import { FaExclamationTriangle } from "@react-icons/all-files/fa/FaExclamationTriangle";
import { useAuthContext } from "@tbl/aws-auth";
import LineItem from "./LineItem";
import {
  addMoneyV2,
  EmptyMoneyV2,
  multiplyMoneyV2,
  getAttributeValueByKey,
  StickerOrderLineItemGroup,
} from "../../lib/storefront";
import MoneyV2 from "../shared/storefront/MoneyV2";
import { StickerTokenIcon } from "../shared/icons/StickerTokenIcon";
import {
  ProjectOutputOption,
  StickerOrderForLineItemFragment,
} from "../../client/MapmakerApi";
import { useMapmakerAppConfig } from "../..";
import {
  getOutputTypeDetails,
  getSelectedOutputOption,
} from "../project/shared/OutputTypeLibrary";
import "./StickersLineItem.css";

gql`
  fragment StickerOrderForLineItem on StickerOrder {
    id
    outputType
    stickers {
      openingId
      quantity
      render {
        url
      }
    }
    file {
      id
      backupVersion
      name
      outputType
      outputScale
      design {
        id
        outputOptions {
          type
          name
          scale
        }
      }
    }
  }
`;

type IStickersLineItemProps = {
  group: StickerOrderLineItemGroup;
  stickerOrder?: StickerOrderForLineItemFragment;
};

export default function StickersLineItem({
  group,
  stickerOrder,
}: IStickersLineItemProps) {
  const { isAuthenticated } = useAuthContext();
  const { gotoEditFile } = useMapmakerAppConfig();
  const outputTypeDetails = getOutputTypeDetails(stickerOrder?.outputType);
  const [copyState, copy] = useCopyToClipboard();

  const selectedOutputOption = getSelectedOutputOption(
    stickerOrder?.outputType,
    stickerOrder?.file.outputScale,
    stickerOrder?.file.design.outputOptions
  );

  const totalPrice = group.lineItems.reduce(
    (sum, lineItem) =>
      addMoneyV2(
        sum,
        multiplyMoneyV2(lineItem.variant?.priceV2, lineItem.quantity)
      ),
    EmptyMoneyV2
  );
  const allAllocations = group.lineItems.flatMap(
    lineItem => lineItem.discountAllocations
  );

  // We check the length to avoid calling reduce on an empty array with no initial value.
  const totalDiscountAllocation = allAllocations.length
    ? allAllocations.reduce((sum, allocation) => ({
        ...sum,
        allocatedAmount: addMoneyV2(
          sum.allocatedAmount,
          allocation.allocatedAmount
        ),
      }))
    : undefined;

  const title = outputTypeDetails
    ? stickerOrder?.stickers.length === 1
      ? outputTypeDetails.lineItemName
      : outputTypeDetails.lineItemNamePlural
    : "Photo Map Stickers";

  // This is useful for admins who sometimes need to copy the ID easily.
  function copyStickerId(e: MouseEvent) {
    if (e.shiftKey) {
      copy(stickerOrder.id);
    }
  }

  return (
    <LineItem
      title={title}
      price={totalPrice}
      discountAllocations={totalDiscountAllocation && [totalDiscountAllocation]}
      lineItemIds={group.lineItems.map(lineItem => lineItem.id)}
    >
      <div id="stickers-line-item" onClick={copyStickerId}>
        {stickerOrder && (
          <strong>
            From project{" "}
            <span
              className="link"
              onClick={() => gotoEditFile(stickerOrder.file.id)}
            >
              {stickerOrder.file.name}
            </span>
          </strong>
        )}
        {group.lineItems.map(lineItem => {
          const isFree = parseFloat(lineItem.variant?.priceV2.amount) === 0;
          if (isFree) {
            // Must be paying with tokens
            const numStickers = lineItem.quantity;
            const numTokens = parseFloat(
              getAttributeValueByKey(lineItem.customAttributes, "Tokens Used")
            );
            return (
              <div key={lineItem.id}>
                {numStickers}
                {numStickers === 1 ? " Sticker" : " Stickers"} purchased with{" "}
                {numTokens}
                {numTokens === 1 ? " token. " : " tokens. "}
                <StickerTokenIcon />
              </div>
            );
          } else {
            return (
              <div key={lineItem.id}>
                {lineItem.quantity} ×{" "}
                {lineItem.variant.title.replace(/sticker(s)/i, "")} @{" "}
                <MoneyV2 moneyV2={lineItem.variant?.priceV2} />
                /ea.
              </div>
            );
          }
        })}
        {stickerOrder ? (
          <StickersThumbnails
            stickerOrder={stickerOrder}
            selectedOutputOption={selectedOutputOption}
          />
        ) : (
          <div className="no-sticker-info">
            <FaExclamationTriangle />
            {isAuthenticated ? (
              <p>
                This sticker order is from a project that was created with a
                different account or as a guest. Your current account does not
                have access to view the project. If you're confused why this is
                please{" "}
                <Link to="/contact/mapmaker/general-technical-question">
                  contact us
                </Link>
                .
              </p>
            ) : (
              <p>
                You do not have access to the project that was used to create
                these stickers. You probably just need to{" "}
                <Link to="/account/login">log in</Link>. If you're confused why
                you can't view these stickers please{" "}
                <Link to="/contact/mapmaker/general-technical-question">
                  contact us
                </Link>
                .
              </p>
            )}
          </div>
        )}
        {copyState.value && (
          <span
            style={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
              color: "var(--mm-success-color)",
            }}
          >
            <FaCheckCircle /> Copied
          </span>
        )}
      </div>
    </LineItem>
  );
}

interface IStickersLineItemDetailsProps {
  stickerOrder: StickerOrderForLineItemFragment;
  selectedOutputOption: Pick<ProjectOutputOption, "name">;
}

function StickersThumbnails({
  stickerOrder,
  selectedOutputOption,
}: IStickersLineItemDetailsProps) {
  return (
    <div id="sticker-thumbnails">
      {stickerOrder.stickers.map(sticker => {
        return (
          <span key={sticker.render.url} className="sticker-thumbnail">
            <img
              height="64"
              src={sticker.render.url}
              alt={`Sticker of ${sticker.openingId}`}
            />
            {sticker.quantity > 1 ? (
              <span className="qty">×{sticker.quantity}</span>
            ) : null}
          </span>
        );
      })}
      {selectedOutputOption && (
        <div className="note">
          <Icon name="info circle" />
          Stickers are not shown to scale. Actual sizes will fit a{" "}
          <strong>{selectedOutputOption.name}</strong>.
        </div>
      )}
    </div>
  );
}
