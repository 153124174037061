import { gql } from "@apollo/client";
import React, { ComponentType, ReactNode, useState } from "react";
import useDebounce from "react-use/lib/useDebounce";
import { subtractMoneyV2 } from "../../lib/storefront";
import {
  StorefrontCheckoutHeavyFragment,
  useStorefrontUpdateCheckoutAttributesMutation,
} from "../../client/MapmakerApi";
import MoneyV2 from "../shared/storefront/MoneyV2";
import "./CartSummary.css";
import { useStorefrontCheckoutHeavy } from "../..";
import { Button } from "@mapmaker/ui";

gql`
  mutation storefrontUpdateCheckoutAttributes(
    $checkoutId: ID!
    $input: StorefrontCheckoutAttributesUpdateV2Input!
  ) {
    storefrontCheckoutAttributesUpdateV2(
      checkoutId: $checkoutId
      input: $input
    ) {
      checkout {
        ...StorefrontCheckoutHeavy
      }
    }
  }
`;

export type CartSummaryPublicProps = {
  FinePrintComponent?: ComponentType<{
    checkout: StorefrontCheckoutHeavyFragment;
    freeShipping: boolean;
  }>;
  isFreeShipping?(
    checkout: StorefrontCheckoutHeavyFragment,
    totalPrice: number,
    totalQuantity: number
  ): boolean;
};

type CartSummaryProps = CartSummaryPublicProps & {
  disabled?: boolean;
};

export default function CartSummary({
  isFreeShipping = () => false,
  FinePrintComponent,
  disabled = false,
}: CartSummaryProps) {
  const { checkout } = useStorefrontCheckoutHeavy();
  const [
    updateCheckoutAttributes,
    { error: updateNoteError },
  ] = useStorefrontUpdateCheckoutAttributesMutation();

  // Update the checkout note either 1 second after the user stops typing, or when there is a blur
  // on the textarea.
  const [checkoutNote, setCheckoutNote] = useState<string>();

  const updateCheckoutNote = (note: string | undefined) =>
    checkout &&
    updateCheckoutAttributes({
      variables: {
        checkoutId: checkout.id,
        input: {
          note,
        },
      },
    });

  useDebounce(
    (note: string) => {
      if (checkoutNote !== undefined) {
        updateCheckoutNote(checkoutNote);
      }
    },
    2000,
    [updateCheckoutAttributes, checkout, checkoutNote]
  );

  if (!checkout) {
    return null;
  }

  const totalPrice = parseFloat(checkout.lineItemsSubtotalPrice.amount);
  const totalDiscountValue = subtractMoneyV2(
    checkout.lineItemsSubtotalPrice,
    checkout.subtotalPriceV2
  );
  const totalQty = checkout.lineItems.edges.reduce(
    (totalQty, { node: lineItem }) => totalQty + lineItem.quantity,
    0
  );
  const freeShipping = isFreeShipping(checkout, totalPrice, totalQty);

  function onCustomerNoteBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
    setCheckoutNote(e.target.value);
    updateCheckoutNote(e.target.value);
  }

  function onCustomerNoteChange(e: React.FocusEvent<HTMLTextAreaElement>) {
    setCheckoutNote(e.target.value);
  }

  return (
    <div id="cart-summary">
      <h3>Order Summary</h3>
      <div className="row">
        <span className="label">Products</span>
        <span className="value">
          <MoneyV2 moneyV2={checkout.lineItemsSubtotalPrice} />
        </span>
      </div>
      {/* We may need to loop through all the checkout-level discountApplications. But for now just 
          showing the total seems to be pretty safe. */}
      {parseFloat(totalDiscountValue.amount) > 0 && (
        <div className="row discount">
          <div className="label">Discounts</div>
          <div className="value">
            <MoneyV2 moneyV2={totalDiscountValue} />
          </div>
        </div>
      )}
      {freeShipping ? (
        <div className="row">
          <span className="label">Shipping</span>
          <span className="value">FREE*</span>
        </div>
      ) : null}
      <div className="row subtotal">
        <span className="label">Subtotal</span>
        <span className="value">
          <MoneyV2 moneyV2={checkout.subtotalPriceV2} />
        </span>
      </div>
      <div className="row customer-note">
        <textarea
          id="customer-note-input"
          placeholder="Add a note about this order."
          rows={4}
          defaultValue={checkout.note ?? undefined}
          onBlur={onCustomerNoteBlur}
          onChange={onCustomerNoteChange}
        />
        <div className="error-message">
          {updateNoteError ? "There was a problem saving your note." : null}
        </div>
      </div>
      <div className="call-to-action">
        {disabled || checkout?.lineItems.edges.length === 0 ? (
          <Button size="large" disabled fluid>
            CHECKOUT
          </Button>
        ) : (
          <a href={checkout?.webUrl}>
            <Button size="large" color="accent" fluid>
              CHECKOUT
            </Button>
          </a>
        )}
      </div>
      {FinePrintComponent && (
        <div className="fine-print">
          <FinePrintComponent checkout={checkout} freeShipping={freeShipping} />
        </div>
      )}
    </div>
  );
}
