import { BoundingBox } from "@mapmaker/core";

/* @ts-ignore */
export const log = console.log;

export function containBboxes(...bboxes: BoundingBox[]): BoundingBox {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  for (let bbox of bboxes) {
    // This let's us pass in null/undefined without issue.
    if (!bbox) {
      continue;
    }
    minX = Math.min(minX, bbox.x);
    minY = Math.min(minY, bbox.y);
    maxX = Math.max(maxX, bbox.x + bbox.width);
    maxY = Math.max(maxY, bbox.y + bbox.height);
  }
  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}
