import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import AuthBoxLayout from "./AuthBoxLayout";
import { useAuthContext } from "../AWSAuthContext";
import { AuthBoxState, useAuthBoxContext } from "./AuthBox";
import FormErrorMessage from "./shared/FormErrorMessage";
import TextField from "./shared/TextField";
import { Button } from "@mapmaker/ui";

interface ResetPasswordFormValues {
  email: string;
  verificationCode: string;
  newPassword: string;
}

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required("Enter the email address associated with your account"),
  verificationCode: Yup.string().required(
    "Enter your 6-digit verification code"
  ),
  newPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Enter a password"),
});

export default function AuthBoxResetPassword({
  children,
}: PropsWithChildren<{}>) {
  const { resetPassword } = useAuthContext();
  const {
    authenticationDetails,
    presetVerificationCode,
    setAuthenticationDetails,
  } = useAuthBoxContext();
  const [sendResetPasswordError, setSendResetPasswordError] =
    useState<string>();

  async function onSubmit(values: ResetPasswordFormValues) {
    try {
      await resetPassword(
        values.email,
        values.verificationCode,
        values.newPassword
      );
      setAuthenticationDetails({ email: values.email });
    } catch (e) {
      setSendResetPasswordError(e.message);
    }
  }

  return (
    <Formik
      initialValues={{
        email: authenticationDetails.email ?? "",
        verificationCode: presetVerificationCode,
        newPassword: "",
      }}
      validationSchema={ResetPasswordSchema}
      onSubmit={onSubmit}
    >
      <AuthBoxResetPasswordForm
        sendResetPasswordError={sendResetPasswordError}
        message={children}
      />
    </Formik>
  );
}

type AuthBoxResetPasswordFormProps = {
  sendResetPasswordError: string;
  message?: ReactNode;
};

function AuthBoxResetPasswordForm({
  sendResetPasswordError,
  message,
}: AuthBoxResetPasswordFormProps) {
  const { sendPasswordReset } = useAuthContext();
  const { setAuthBoxState, authenticationDetails } = useAuthBoxContext();
  const { isSubmitting, isValid, dirty } =
    useFormikContext<ResetPasswordFormValues>();
  const [resendStatus, setResendStatus] = useState<
    "NOT_SENT" | "SENDING" | "SENT"
  >("NOT_SENT");
  const [resendError, setResendError] = useState<string>();

  const gotoSignIn = useCallback(() => {
    setAuthBoxState(AuthBoxState.SIGN_IN);
  }, [setAuthBoxState]);

  const gotoSignUp = useCallback(() => {
    setAuthBoxState(AuthBoxState.SIGN_UP);
  }, [setAuthBoxState]);

  const resendVerificationCode = useCallback(async () => {
    setResendError(undefined);
    setResendStatus("SENDING");
    try {
      await sendPasswordReset(authenticationDetails.email);
      setResendStatus("SENT");
    } catch (e) {
      setResendError(e.message);
      setResendStatus("NOT_SENT");
    }
  }, [setAuthBoxState]);

  const Alternatives = (
    <>
      <span className="link" onClick={gotoSignUp}>
        Register
      </span>{" "}
      /{" "}
      <span className="link" onClick={gotoSignIn}>
        Log In
      </span>
    </>
  );

  return (
    <AuthBoxLayout>
      <AuthBoxLayout.Header title="Reset Password" alternative={Alternatives} />
      <AuthBoxLayout.Form>
        <Form>
          {message || (
            <p>
              A security code has been sent to{" "}
              <strong>{authenticationDetails.email}</strong>. Enter the code
              here to reset your password.
            </p>
          )}
          <TextField
            name="email"
            label="Email"
            placeholder="bob@example.com"
            autoComplete="email"
            autoFocus={!authenticationDetails.email}
          />
          <TextField
            name="verificationCode"
            label="Verification Code"
            placeholder="123456"
            autoComplete="one-time-code"
            autoFocus={!!authenticationDetails.email}
          />
          <TextField
            name="newPassword"
            label="New Password"
            type="password"
            placeholder="choose a password"
            autoComplete="new-password"
          />
          <div className="buttons">
            <Button
              color="accent"
              type="submit"
              fluid
              disabled={!dirty || !isValid}
              loading={isSubmitting}
            >
              Reset Password
            </Button>
            <FormErrorMessage message={sendResetPasswordError} />
          </div>
          <div style={{ textAlign: "center", marginTop: "1em" }}>
            <span onClick={resendVerificationCode} className="link">
              Resend Code
            </span>
            {resendStatus === "SENT" ? (
              <div style={{ marginTop: "1em" }}>
                Verification code sent to{" "}
                <strong>{authenticationDetails.email}</strong>.
              </div>
            ) : null}
            <FormErrorMessage message={resendError} />
          </div>
        </Form>
      </AuthBoxLayout.Form>
    </AuthBoxLayout>
  );
}
