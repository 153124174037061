import type { IPrintService } from "../PrintServices";
import { MapmakerPrintServiceId } from "../PrintServiceIds";
import MapmakerConfig from "@mapmaker/config";

const StickersPrintService: IPrintService = {
  id: MapmakerPrintServiceId.STICKERS,
  name: "Order Stickers",
  bannerText: "Most Popular",
  image: `${MapmakerConfig.staticUrl}/images/printing-services/STICKERS-illustration.png`,
  imageSmall: `${MapmakerConfig.staticUrl}/images/printing-services/STICKERS-illustration-no-padding.png`,
  directive: "Order Stickers",
  description:
    "Perfectly sized stickers delivered straight to your door!",
    detailedDescription: (
      <p>
        Perfectly sized custom stickers delivered straight to your door! Simply peel them off and apply them to your map. No cutting required!
      </p>
    ),
  printSizeModes: [],
};
export default StickersPrintService;
