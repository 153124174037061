import { useProjectFile } from "../../useProjectState";
import SelectableSetting from "./SelectableSetting";
import SettingSection from "./SettingSection";
import { getPrintServicesForOutputType } from "../../../printing/PrintServices";
import { usePreferredPrintingService } from "../../../printing/shared/usePreferredPrintingService";
import { MapmakerPrintServiceId } from "../../../printing";
import "./PrintServiceSettings.css";

export default function PrintServiceSettings() {
  const file = useProjectFile();
  const [
    preferredPrintingService,
    setPreferredPrintingService,
  ] = usePreferredPrintingService(file.outputType);
  const availablePrintServices = getPrintServicesForOutputType(file.outputType);

  function selectService(id: MapmakerPrintServiceId) {
    setPreferredPrintingService(id);
  }

  if (availablePrintServices.length <= 1) {
    return null;
  }

  return (
    <SettingSection
      id="print-service-settings"
      label="Printing"
      description={`Choose your preference for printing ${file.design.regionType} photos.`}
    >
      {availablePrintServices.map(service => (
        <SelectableSetting
          className="option"
          selected={preferredPrintingService.id === service.id}
          onSelect={() => selectService(service.id)}
        >
          <img className="icon" src={service.imageSmall} />
          <div>
            <h4>{service.name}</h4>
            <div className="content">{service.description}</div>
          </div>
        </SelectableSetting>
      ))}
    </SettingSection>
  );
}
