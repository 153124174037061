import { Message, removeMessage } from "./messageReducer";
import { useProjectDispatch } from "../project/projectStore";
import { IconType } from "@react-icons/all-files";
import { FaExclamationTriangle } from "@react-icons/all-files/fa/FaExclamationTriangle";
import { FaInfo } from "@react-icons/all-files/fa/FaInfo";
import { FaInfoCircle } from "@react-icons/all-files/fa/FaInfoCircle";
import { MdClose } from "@react-icons/all-files/md/MdClose";
import "./ToastMessages.css";

export interface IToastMessagesProps {
  messages?: Message[];
}

export default function ToastMessages({ messages = [] }: IToastMessagesProps) {
  if (messages.length === 0) {
    return null;
  }

  return (
    <div id="toast-messages">
      {messages.map((message, i) => (
        <ToastMessage key={message.id ?? i} message={message} />
      ))}
    </div>
  );
}

const TypeIconMap: { [key: string]: IconType } = {
  info: FaInfoCircle,
  warning: FaInfo,
  error: FaExclamationTriangle,
};

interface IToastMessageProps {
  message: Message;
}

function ToastMessage({ message }: IToastMessageProps) {
  const dispatch = useProjectDispatch();
  function deleteMessage() {
    dispatch(removeMessage(message.id));
  }
  const Icon = message.icon ?? TypeIconMap[message.type];
  return (
    <div id="toast-message" className={message.type}>
      <div className="icon">
        <Icon className="icon" />
      </div>
      <div className="main">
        <h3 className="title">{message.title}</h3>
        <div className="content">{message.content}</div>
      </div>
      <span className="close" onClick={deleteMessage}>
        <MdClose fontSize="20px" />
      </span>
    </div>
  );
}
