import MapmakerLayout from "../../layout/MapmakerLayout";
import MapmakerTopBar from "../../layout/MapmakerTopBar";
import ProjectToolbar from "../ProjectToolbar";
import TimelineEditor from "./TimelineEditor";

export default function TimelineView() {
  return (
    <MapmakerLayout>
      <MapmakerLayout.TopBar>
        <MapmakerTopBar breadcrumbs={[{ label: "Timeline" }]} />
      </MapmakerLayout.TopBar>
      <MapmakerLayout.Main nav={<ProjectToolbar selected="timeline" />}>
        <TimelineEditor />
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}
