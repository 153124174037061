import { Icon } from "semantic-ui-react";
import { WalgreensStore } from "../../../client/MapmakerApi";
import "./WalgreensStoreDirectionsLink.css";

interface IWalgreensStoreDirectionsLinkProps {
  store: WalgreensStore;
  label?: string;
}

export default function WalgreensStoreDirectionsLink({
  store,
  label = "DIRECTIONS",
}: IWalgreensStoreDirectionsLinkProps) {
  const address = `${store.street} ${store.city}, ${store.state} ${store.zip}`;
  const directionLink = `http://maps.google.com/maps?daddr=${encodeURI(
    address
  )}`;

  return (
    <a
      id="walgreens-store-directions-link"
      href={directionLink}
      target="_blank"
    >
      <Icon name="marker" />
      {label}
    </a>
  );
}
