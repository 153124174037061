import { Link } from "react-router-dom";
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import TBLLogoText from "./TBLLogoText";
import "./NavFooter.scss";

export default function Footer() {
  return (
    <Segment as="footer" id="nav-footer" inverted vertical>
      <Container textAlign="center">
        <Grid className="lists" divided inverted stackable>
          <Grid.Column width={4}>
            <Header inverted as="h4">
              Help
            </Header>
            <List link inverted>
              <List.Item>
                <Link to="/help">General Help</Link>
              </List.Item>
              <List.Item>
                <Link to="/begin">Photo Map Instructions</Link>
              </List.Item>
              <List.Item>
                <Link to="/contact">Contact Us</Link>
              </List.Item>
              <List.Item>
                <Link to="/refunds">Refunds</Link>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={4}>
            <Header inverted as="h4">
              Resources
            </Header>
            <List link inverted>
              <List.Item>
                <Link to="/about">Our Story</Link>
              </List.Item>
              <List.Item>
                <Link to="/terms">Terms and Conditions</Link>
              </List.Item>
              <List.Item>
                <Link to="/privacy">Privacy Policy</Link>
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={8}>
            <Image src="/images/footer/made-in-mi.png" width="300" centered />
          </Grid.Column>
        </Grid>

        <Divider inverted section />
        <List inverted className="bottom-list">
          <List.Item>
            <TBLLogoText />
          </List.Item>
          <List.Item className="copyright">
            © {new Date().getFullYear()} Thunder Bunny Labs
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
}
