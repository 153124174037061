import * as Sentry from "@sentry/react";
import React, {
  useContext,
  createContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
  ReactElement,
} from "react";
import { CognitoIdentityCredentials } from "aws-sdk/global";
import {
  CognitoIdentityClient,
  GetCredentialsForIdentityCommand,
} from "@aws-sdk/client-cognito-identity";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  CognitoUserAttribute,
  UserData,
} from "amazon-cognito-identity-js";
import { promisify } from "./utils/promisify";
import {
  PasswordResetRequiredException,
  PasswordResetRequiredExceptionName,
  UserNotConfirmedException,
  UserNotConfirmedExceptionName,
} from "./AWSAuthErrors";
import { userAttributesToObject } from "./utils/cognitoHelpers";
import createSigV4Fetch from "./client/sigv4Fetch";

export type Credentials = CognitoIdentityCredentials;

export type AuthenticatedUser = {
  email: string;
  isTblAccount: boolean;
};

interface IUserAttributesObject {
  email: string;
  email_verified: boolean;
  sub: string;
}

export enum AuthState {
  Initializing = "initializing",
  Error = "error",
  Unauthenticated = "unauthenticated",
  Authenticated = "authenticated",
}

export type AuthContext = {
  state: AuthState;
  user: AuthenticatedUser;
  // Note that the IdentityID may be expired. currentCredentials should be used for anything which requires current credentials.
  identityId: string;
  isAuthenticated: boolean;
  authorizedFetch: typeof fetch;
  currentCredentials(): Promise<Credentials>;
  signIn(username: string, password: string);
  signOut(): void;
  signUp(username: string, password: string): void;
  verifyEmail(email: string, password: string, verificationCode: string): void;
  resendEmailVerification(email: string): void;
  sendPasswordReset(email: string): void;
  resetPassword(
    email: string,
    verificationCode: string,
    newPassword: string
  ): void;
  changePassword(oldPassword: string, newPassword: string): void;
};

const Context = createContext<AuthContext>({
  state: AuthState.Initializing,
} as AuthContext);

type AuthContextProviderProps = {
  config: AuthConfigOptions;
  children: ReactElement;
};

type AuthConfigOptions = {
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  identityPoolId: string;
  // REQUIRED - Amazon Cognito Region
  region: string;
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: string;
  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: string;
};

export function AWSAuthContextProvider({
  config,
  children,
}: AuthContextProviderProps) {
  const [state, setState] = useState<AuthState>(AuthState.Initializing);
  const [user, setUser] = useState<AuthenticatedUser>();
  const [credentials, setCredentials] = useState<Credentials>();

  const userPool = useMemo(() => {
    return new CognitoUserPool({
      UserPoolId: config.userPoolId,
      ClientId: config.userPoolWebClientId,
    });
  }, []);

  const getCredentials = useMemo(() => {
    return function (userSession: CognitoUserSession = null) {
      const Logins = {};
      let IdentityId: string = undefined;
      if (userSession) {
        Logins[
          `cognito-idp.${config.region}.amazonaws.com/${config.userPoolId}`
        ] = userSession.getIdToken().getJwtToken();
      } else {
        IdentityId =
          window.localStorage.getItem(
            `aws.cognito.identity-id.${config.identityPoolId}`
          ) || undefined;
      }
      return new CognitoIdentityCredentials(
        {
          IdentityId,
          IdentityPoolId: config.identityPoolId,
          Logins,
        },
        { region: config.region }
      );
    };
  }, [config]);

  /**
   * Should always return the current non-expired Cognito identity credentials.
   */
  const currentCredentials = useCallback(async () => {
    try {
      await credentials.getPromise();
    } catch (e) {
      // Refreshes the credentials if needed.
      await doLogin(userPool.getCurrentUser());
    }
    return credentials;
  }, [credentials]);

  const asyncAuthenticateUser = useCallback(
    async function asyncAuthenticateUser(
      cognitoUser: CognitoUser,
      username: string,
      password: string
    ): Promise<CognitoUserSession> {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });
      return new Promise(function (resolve, reject) {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: async (session) => {
            /**
             * This code was adapted from code meant to work with AWS Amplify. The purpose is so that
             * if an unauthenticated user has registers, they keep the same IdentityID as they had
             * before. To be completely honest I'm not really sure how it works, so I left the
             * original comment below but it references a lot of Amplify stuff.
             */
            /*
             * This call to GetCredentialsForIdentityCommand links the newly authenticated user with the anonymous identity in the Identity Pool.
             * When https://github.com/aws-amplify/amplify-js/blob/e11e938184fbb935374ca8f19b96365a7d6af7ce/packages/core/src/Credentials.ts#L354
             * is called further down the chain inside `baseOnSuccess`, it will return the same IdentityId instead of creating a new one.
             * Thus, the same value of UserId will be used for Pinpoint before and after registration.
             * In the case when the user is already registered and is signing in (i.e. they already have their account in User Pool linked to an Identity),
             * this call to GetCredentialsForIdentityCommand will not make any additional links. Instead it will mark the new Identity as disabled and
             * will merge it into the old one - https://docs.aws.amazon.com/cognito/latest/developerguide/authentication-flow.html (under Merging Identities).
             * There's also this thread that covers that https://forums.aws.amazon.com/thread.jspa?threadID=238042
             */
            if (session) {
              const cognitoClient = new CognitoIdentityClient({
                region: config.region,
              });

              await cognitoClient.send(
                new GetCredentialsForIdentityCommand({
                  IdentityId: credentials.identityId,
                  Logins: {
                    [`cognito-idp.${config.region}.amazonaws.com/${config.userPoolId}`]:
                      session.getIdToken().getJwtToken(),
                  },
                })
              );
            }
            resolve(session);
          },
          onFailure: reject,
          newPasswordRequired: reject,
        });
      });
    },
    [config, credentials]
  );

  /**
   * Used internally to get a user session and an identity ID
   */
  const doLogin = useCallback(async (cognitoUser: CognitoUser) => {
    try {
      if (!cognitoUser) {
        const unauthenticatedCredentials = getCredentials();
        await unauthenticatedCredentials.getPromise();
        setCredentials(unauthenticatedCredentials);
        setUser(null);
        setState(AuthState.Unauthenticated);
      } else {
        const session = await asyncGetSession(cognitoUser);
        const authenticatedCredentials = getCredentials(session);
        await authenticatedCredentials.getPromise();

        const userData: UserData = await promisify(
          cognitoUser.getUserData,
          cognitoUser
        )();
        const attributes = userAttributesToObject<IUserAttributesObject>(
          userData.UserAttributes
        );
        // We need to set the user here
        setCredentials(authenticatedCredentials);
        setUser({
          email: attributes.email,
          isTblAccount: attributes.email.endsWith("@thunderbunnylabs.com"),
        });
        setState(AuthState.Authenticated);
      }
    } catch (e) {
      Sentry.captureException(e);
      setUser(null);
      setState(AuthState.Error);
    }
  }, []);

  // Auto sign in
  useEffect(() => {
    async function autoLogin() {
      const cognitoUser = userPool.getCurrentUser();
      await doLogin(cognitoUser);
    }
    autoLogin();
  }, []);

  /**
   * Sign in an unauthenticated user
   */
  async function signIn(username: string, password: string): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    });

    try {
      await asyncAuthenticateUser(cognitoUser, username, password);
      await doLogin(cognitoUser);
    } catch (e) {
      if (e.name === UserNotConfirmedExceptionName) {
        throw new UserNotConfirmedException();
      } else if (e.name === PasswordResetRequiredExceptionName) {
        throw new PasswordResetRequiredException();
      } else {
        throw e;
      }
    }
  }

  /**
   * Sign out of the application and clear the cached user
   */
  async function signOut() {
    const cognitoUser = await userPool.getCurrentUser();
    if (cognitoUser) {
      // Needed to prevent this issue: https://github.com/aws-amplify/amplify-js/issues/5696
      window.localStorage.removeItem(
        `aws.cognito.identity-id.${config.identityPoolId}`
      );
      cognitoUser.signOut();
      await doLogin(userPool.getCurrentUser());
    }
  }

  /**
   * Returns the new user on success, and throws an error if they were not registered for any reason.
   */
  async function signUp(
    username: string,
    password: string,
    attributeList: CognitoUserAttribute[] = []
  ) {
    const result = await promisify(userPool.signUp, userPool)(
      username,
      password,
      attributeList,
      null
    );
    doLogin(result.user);
  }

  /**
   * Verify an email address for a new user
   */
  async function verifyEmail(
    email: string,
    password: string,
    verificationCode: string
  ) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    // The only non-error result here is a success message.
    await promisify(cognitoUser.confirmRegistration, cognitoUser)(
      verificationCode,
      true
    );
    if (password) {
      await asyncAuthenticateUser(cognitoUser, email, password);
      await doLogin(cognitoUser);
    }
  }

  /**
   * Re-sends the email verification code to a user.
   */
  async function resendEmailVerification(email: string): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    // The only non-error result here is a success message.
    await promisify(cognitoUser.resendConfirmationCode, cognitoUser)();
  }

  async function sendPasswordReset(email: string): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    // The only non-error result here is a success message.
    await asyncForgotPassword(cognitoUser);
  }

  async function resetPassword(
    email: string,
    verificationCode: string,
    newPassword: string
  ): Promise<void> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    // Throws error for any non-success state
    await asyncConfirmPassword(cognitoUser, verificationCode, newPassword);
    // Log in automatically
    await asyncAuthenticateUser(cognitoUser, email, newPassword);
    await doLogin(cognitoUser);
  }

  async function changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    const cognitoUser = await userPool.getCurrentUser();
    await asyncGetSession(cognitoUser);
    await asyncChangePassword(cognitoUser, oldPassword, newPassword);
  }

  const authorizedFetch = useMemo(() => {
    return async (url: string, options: RequestInit = {}) => {
      const credentials = await currentCredentials();
      const fetch = createSigV4Fetch(credentials);
      return fetch(url, options);
    };
  }, [credentials, currentCredentials]);

  return (
    <Context.Provider
      value={{
        state,
        user,
        identityId: credentials?.identityId,
        isAuthenticated: state === AuthState.Authenticated,
        authorizedFetch,
        currentCredentials,
        signIn,
        signOut,
        signUp,
        resendEmailVerification,
        verifyEmail,
        sendPasswordReset,
        resetPassword,
        changePassword,
      }}
    >
      {children}
    </Context.Provider>
  );
}

/**
 * Some helper methods to deal with the weird callback structure of Cognito methods.
 */
async function asyncGetSession(
  cognitoUser: CognitoUser
): Promise<CognitoUserSession> {
  return await promisify<CognitoUserSession>(
    cognitoUser.getSession,
    cognitoUser
  )();
}

async function asyncForgotPassword(cognitoUser: CognitoUser): Promise<any> {
  return new Promise(function (resolve, reject) {
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
    });
  });
}

async function asyncConfirmPassword(
  cognitoUser: CognitoUser,
  verificationCode: string,
  newPassword: string
): Promise<any> {
  return new Promise(function (resolve, reject) {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });
}

async function asyncChangePassword(
  cognitoUser: CognitoUser,
  oldPassword: string,
  newPassword: string
): Promise<any> {
  return new Promise<void>(function (resolve, reject) {
    cognitoUser.changePassword(oldPassword, newPassword, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
}

export function useAuthContext() {
  return useContext<AuthContext>(Context);
}
