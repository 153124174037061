import { Feature, FeatureInput, getFeature, getInput } from "@mapmaker/core";
import { useProjectFile } from "../useProjectState";

// Single feature hook
export default function useFeature<
  F extends Feature = Feature,
  I extends FeatureInput = FeatureInput
>(featureId: string, type: Feature["type"]) {
  const file = useProjectFile();
  const feature = getFeature<F>(file.design, featureId, type);
  const featureInput = getInput<I>(file, featureId, type as I["type"]);
  return {
    feature: feature,
    featureInput: featureInput,
  };
}
