import { ReactNode, useContext, createContext, useMemo } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import { Credentials } from "@tbl/aws-auth";
import { NormalizedCacheObject } from "@apollo/client/cache";
import { MapmakerAppConfig } from "./MapmakerAppConfig";
import useMapmakerApolloClient, {
  MapmakerClient,
} from "./useMapmakerApolloClient";
import {
  MapmakerAccountFragment,
  MapmakerAuthenticatedAccountFragment,
  useGetAccountQuery,
} from "./MapmakerApi";
import useLiveLocalStorage from "../lib/hooks/useLiveLocalStorage";
import { MapmakerModalProvider } from "../components/shared/modals/useMapmakerModals";

type MapmakerContextProviderProps = Pick<
  MapmakerContextState,
  "appConfig" | "identityId" | "currentCredentials"
> & {
  children: ReactNode;
};

type MapmakerContextState = {
  appConfig: MapmakerAppConfig;
  apolloClient?: MapmakerClient;
  isMobile?: boolean;
  identityId: string;
  account?: MapmakerAccountFragment;
  authenticatedAccount?: MapmakerAccountFragment &
    MapmakerAuthenticatedAccountFragment;
  refetchAccountData: () => any;
  currentCredentials: () => Promise<Credentials>;
};
const MapmakerContext = createContext<MapmakerContextState>(
  {} as MapmakerContextState
);

let clientRef: { current: ApolloClient<NormalizedCacheObject> } = {
  current: null,
};
export function getClient(): ApolloClient<NormalizedCacheObject> {
  return clientRef.current;
}

export function MapmakerContextProvider({
  appConfig,
  identityId,
  currentCredentials,
  children,
}: MapmakerContextProviderProps) {
  const client = useMapmakerApolloClient({
    businessId: appConfig.businessId,
    currentCredentials,
  });
  clientRef.current = client;
  const { width } = useWindowSize();
  const [lastLoadedAccount, setLastLoadedAccount] = useLiveLocalStorage(
    "mapmaker.lastLoadedAccount"
  );

  /* Loads account details. Note consumers of this should handle the case where the account is empty
  because it hasn't been loaded yet. */
  const { data, refetch: refetchAccountData } = useGetAccountQuery({
    client,
    skip: !identityId,
    onCompleted: result => setLastLoadedAccount(result.account),
  });
  const account = useMemo<MapmakerAccountFragment>(() => {
    if (data?.account) {
      return data.account;
    } else if (lastLoadedAccount) {
      return lastLoadedAccount as MapmakerAccountFragment;
    } else {
      return undefined;
    }
  }, [data, lastLoadedAccount]);

  return (
    <ApolloProvider client={client}>
      <MapmakerContext.Provider
        value={{
          appConfig,
          apolloClient: client,
          isMobile: width <= 767,
          identityId,
          account,
          authenticatedAccount:
            account?.__typename === "MapmakerAuthenticatedAccount"
              ? account
              : undefined,
          refetchAccountData,
          currentCredentials,
        }}
      >
        <MapmakerModalProvider>{children}</MapmakerModalProvider>
      </MapmakerContext.Provider>
    </ApolloProvider>
  );
}

export function useMapmakerContext() {
  return useContext(MapmakerContext);
}
