import { Grid, Header, Icon } from "semantic-ui-react";
import { Button } from "@mapmaker/ui";
import WalgreensStoreDirectionsLink from "./WalgreensStoreDirectionsLink";
import { WalgreensStore } from "../../../client/MapmakerApi";
import { prettyPromiseTime } from "./walgreensLib";
import "./WalgreensStoreListItem.css";

interface IWalgreensStoreListItemProps {
  store: WalgreensStore;
  onSelect: (store: WalgreensStore) => any;
}

export default function WalgreensStoreListItem({
  store,
  onSelect,
}: IWalgreensStoreListItemProps) {
  function selected() {
    onSelect(store);
  }

  return (
    <Grid id="walgreens-store-list-item">
      <Grid.Row>
        <Grid.Column computer={16} mobile={16} className="head">
          <Header sub onClick={selected}>
            {store.city}
          </Header>
          <span className="distance">
            {store.distance} {store.distanceUnit} away
          </span>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <p>
            <label>HOURS</label>
            {store.openTime} - {store.closeTime}
          </p>
          <p>
            <label>PICKUP TIME</label>
            {prettyPromiseTime(store.promiseTime)}
          </p>
        </Grid.Column>
        <Grid.Column computer={6} mobile={16} className="address">
          <p className="case-fix">{store.street.toLowerCase()}</p>
          <p>
            <span className="case-fix">{store.city.toLowerCase()}</span>,{" "}
            {store.state} {store.zip}
          </p>
          <WalgreensStoreDirectionsLink store={store} />
        </Grid.Column>
        <Grid.Column computer={4} mobile={16} className="select">
          <Button outlined size="small" onClick={selected}>
            Pick Up Here <Icon name="caret right" />
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
