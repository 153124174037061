import React, { useMemo } from "react";
import "./FormErrorMessage.css";

interface IFormErrorMessageProps {
  message: string | React.ReactNode;
}

export default function FormErrorMessage({ message }: IFormErrorMessageProps) {
  const MessageContent = useMemo(() => {
    return () => {
      if (typeof message === "string") {
        return <div id="form-error-message">{message}</div>;
      } else if (message) {
        return <>{message}</>;
      } else {
        return null;
      }
    };
  }, [message]);

  return (
    <div
      id="form-error-message"
      className={`error ${message ? "visible" : "hidden"}`}
    >
      <MessageContent />
    </div>
  );
}
