import { useAuthContext } from "@tbl/aws-auth";
import { useLocation } from "react-router-dom";
import { StickerTokenIcon } from "..";
import { useMapmakerAppConfig } from "../..";
import StickerTokenRedemptionForm from "./StickerTokenRedemptionForm";
import "./StickerTokenRedemption.css";

type RedeemStickerTokensProps = {
  code?: string;
};

export default function RedeemStickerTokens({
  code,
}: RedeemStickerTokensProps) {
  const { isAuthenticated } = useAuthContext();
  const { pathname } = useLocation();
  const { gotoSignIn, gotoStickerTokenHelp } = useMapmakerAppConfig();

  if (!isAuthenticated) {
    gotoSignIn(pathname);
    return null;
  }

  return (
    <div id="sticker-token-redemption">
      <div className="above">
        <StickerTokenIcon size="8rem" />
        <h2>Redeem a Sticker Token Code</h2>
      </div>
      <StickerTokenRedemptionForm
        className="redemption-form"
        placeholder="Enter code"
        code={code}
        inline={false}
      />
      <div className="below">
        Enter your code above and click the Redeem button. For more information
        see our{" "}
        <span className="link" onClick={gotoStickerTokenHelp}>
          Sticker Token FAQ
        </span>{" "}
        page.
      </div>
    </div>
  );
}
