import StickerTokenTransactionList from "../tokens/StickerTokenTransactionList";
import StickerTokenRedemptionForm from "../tokens/StickerTokenRedemptionForm";
import StickerTokenBalance from "../tokens/StickerTokenBalance";
import { useMapmakerAppConfig } from "../..";
import "./AccountStickerTokens.css";

export default function AccountStickerTokens() {
  const { gotoStickerTokenHelp } = useMapmakerAppConfig();
  return (
    <div id="account-sticker-tokens">
      <div className="header">
        <StickerTokenBalance />
        <StickerTokenRedemptionForm
          className="redemption-form"
          placeholder="Enter code"
        />
      </div>
      <h3>Sticker Tokens</h3>
      <p>
        Sticker tokens can be used to purchase stickers for your map. If you
        have a coupon code for sticker tokens you can enter it above. From time
        to time we may offer promotions to earn sticker tokens. See more
        information on our{" "}
        <span className="link" onClick={gotoStickerTokenHelp}>
          Sticker Token FAQ
        </span>{" "}
        page.
      </p>
      <h3>Transactions History</h3>
      <StickerTokenTransactionList />
    </div>
  );
}
