import { Button, IButtonProps } from "@mapmaker/ui";
import { MouseEvent, PropsWithChildren, ReactNode } from "react";
import { ModalActions, ModalContent } from "semantic-ui-react";
import MapmakerModal from "./MapmakerModal";
import "./MapmakerConfirmModal.css";

type MapmakerConfirmModalProps = {
  open?: boolean;
  confirmLabel?: ReactNode;
  confirmProps?: IButtonProps;
  onConfirm(): any;
  cancelLabel?: ReactNode;
  cancelProps?: IButtonProps;
  onClose?(): any;
  loading?: boolean;
};

export function MapmakerConfirmModal({
  open = true,
  confirmLabel = "Yes",
  confirmProps = {},
  onConfirm,
  cancelLabel = "Cancel",
  cancelProps = {},
  onClose = () => {},
  loading = false,
  children,
}: PropsWithChildren<MapmakerConfirmModalProps>) {
  async function closeClick(e: MouseEvent) {
    e.stopPropagation();
    onClose();
  }

  async function confirmClick(e: MouseEvent) {
    e.stopPropagation();
    await onConfirm();
    onClose();
  }

  return (
    <MapmakerModal
      open={open}
      closeIcon={true}
      onClose={closeClick}
      closeOnDocumentClick
    >
      <ModalContent id="mapmaker-confirm-modal">{children}</ModalContent>
      <ModalActions>
        <Button onClick={closeClick} disabled={loading} {...cancelProps}>
          {cancelLabel}
        </Button>{" "}
        <Button
          color="accent"
          onClick={confirmClick}
          loading={loading}
          {...confirmProps}
        >
          {confirmLabel}
        </Button>
      </ModalActions>
    </MapmakerModal>
  );
}
