import { Button, IButtonProps } from "@mapmaker/ui";
import { InitialFileProperties } from "@mapmaker/core";
import { useCallback } from "react";
import { useMapmakerAppConfig } from "../../../client";

type CreateMapmakerProjectButtonProps = {
  designId: string;
  initialFileProperties?: InitialFileProperties;
} & Omit<IButtonProps, "onClick">;

export default function CreateMapmakerProjectButton({
  designId,
  initialFileProperties = {},
  children,
  ...buttonProps
}: CreateMapmakerProjectButtonProps) {
  const { gotoNewFile } = useMapmakerAppConfig();
  const onClick = useCallback(() => {
    gotoNewFile(designId, initialFileProperties);
  }, [designId, initialFileProperties]);
  return (
    <Button onClick={onClick} {...buttonProps}>
      {children}
    </Button>
  );
}
