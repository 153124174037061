import { MapmakerBusinessId } from "..";

const BusinessNameMap: { [businessId in MapmakerBusinessId]: string } = {
  pm: "photomaps.com",
  tbl: "Thunder Bunny Labs",
};

export function getBusinessName(businessId: MapmakerBusinessId): string {
  return BusinessNameMap[businessId];
}
