import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

export type ContactUsSerializerProps = PropsWithChildren<{
  mark: {
    category: string;
  };
}>;

export default function ContactUsSerializer({
  mark: { category },
  children,
  ...extra
}: ContactUsSerializerProps) {
  return (
    <Link to={["/contact", category].filter(x => !!x).join("/")}>
      {children}
    </Link>
  );
}
