import { produce, original } from "immer";
import loadImage from "blueimp-load-image";
// Enable Exif data from the image loader
import "blueimp-load-image/js/load-image-meta";
import "blueimp-load-image/js/load-image-exif";
import {
  SVGNode,
  MapmakerDesign,
  OpeningInput,
  OpeningImageInput,
  getDefaultImageTransform,
} from "@mapmaker/core";
import { clamp } from "../../../../../lib/math";
import { addMessage } from "../../../../shared/messageReducer";
import { captureException } from "@sentry/react";
import { getExifDataForOpeningImage } from "../../../../../lib/exif/exifUtils";
import { EditableOpeningState } from "./EditableOpeningStore";
import { AnyAction, Reducer } from "redux";
import editableOpeningImageReducer from "./editableOpeningImageReducer";
import { trackGtmEvent } from "../../../../../lib/gtm";
import {
  beginUpload,
  uploadCompleted,
  uploadFailed,
} from "../../../uploadsReducer";
import { saveFile } from "../../../fileReducer";

const ADD_IMAGES = "editable_opening.add_images";
const REMOVE_IMAGE = "editable_opening.remove_image";
const SET_ACTIVE_IMAGE = "editable_opening.set_active_image";
const SET_HIGHLIGHTED_IMAGE = "editable_opening.set_highlighted_image";
const ADJUST_IMAGE_DEPTH = "editable_opening.adjust_image_depth";
const SET_BACKGROUND = "editable_opening.set_background";
const SET_ZOOM_LEVEL = "editable_opening.set_zoom_level";

function addImagesToOpening(images: OpeningImageInput[]) {
  return {
    type: ADD_IMAGES,
    images,
  };
}

export const MAX_IMAGES_PER_OPENING = 10;
const MAX_FILE_SIZE = 20 * 1024 * 1024;
const MAX_FILE_SIZE_READABLE = "20MB";
// All manually tested.
const SUPPORTED_FILE_TYPES = [
  "image/bmp",
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/heic",
];
const SUPPORTED_FILE_TYPES_READABLE = ".jpeg, .jpg, or .png";

async function getHeicByHeaders(file: File) {
  try {
    async function getBase64(file: File) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
    function base64ToUint8Array(string) {
      var raw = atob(string.split(";base64,")[1]);
      var rawLength = raw.length;
      var array = new Uint8Array(new ArrayBuffer(rawLength));
      for (var i = 0; i < rawLength; i += 1) {
        array[i] = raw.charCodeAt(i);
      }
      return array;
    }
    function isHeic(buffer: Uint8Array) {
      if (!buffer || buffer.length < 24) {
        return false;
      }

      return (
        buffer[20] === 0x68 &&
        buffer[21] === 0x65 &&
        buffer[22] === 0x69 &&
        buffer[23] === 0x63
      );
    }
    const uint8 = base64ToUint8Array(await getBase64(file));
    return isHeic(uint8);
  } catch (e) {
    return e.message;
  }
}

export function addImagesToOpeningFromFiles(
  files: File[],
  printLayer: SVGNode,
  design: Pick<MapmakerDesign, "regionType" | "regionTypePlural">,
  upload: any,
  getUploadKey: (extension: string) => string,
  projectDispatch: any
) {
  return async (dispatch, getState) => {
    const { openingInputState } = getState() as EditableOpeningState;
    const currentImageCount = openingInputState.images.length;

    const addWarning = content => {
      projectDispatch(addMessage({ type: "warning", content }));
    };
    const addError = content => {
      projectDispatch(addMessage({ type: "error", content }));
    };

    /*
    if (MapmakerConfig.stage !== "prod") {
      try {
        const file = files[0];
        alert(
          [
            `File Name: ${file.name}`,
            `File Type: ${file.type}`,
            `File Size: ${file.size}`,
            `HEIC Headers: ${await getHeicByHeaders(file)}`,
          ].join("\n")
        );
      } catch (e) {
        alert(e.message);
      }
    }
    */

    // Limit total images.
    if (currentImageCount >= MAX_IMAGES_PER_OPENING) {
      addWarning(
        `You can only add ${MAX_IMAGES_PER_OPENING} images to each ${design.regionType}.`
      );
      files = [];
    } else if (currentImageCount + files.length > MAX_IMAGES_PER_OPENING) {
      addWarning(
        `Not all images were added. You can only add ${MAX_IMAGES_PER_OPENING} images to each ${design.regionType}.`
      );
      // Only take the files that we can.
      files = files.slice(0, MAX_IMAGES_PER_OPENING - currentImageCount);
    }

    // Make sure the files are acceptable
    const originalFileLength = files.length;
    files = files.filter(file => file.size < MAX_FILE_SIZE);
    if (files.length != originalFileLength) {
      addWarning(
        `Files too large. The maximum file size is ${MAX_FILE_SIZE_READABLE}.`
      );
    }

    // Make sure the files are acceptable
    const originalFileLength2 = files.length;
    files = files.filter(file => SUPPORTED_FILE_TYPES.includes(file.type));
    if (files.length != originalFileLength2) {
      addWarning(`Please select a ${SUPPORTED_FILE_TYPES_READABLE} image.`);
    }

    const newImageStates = await Promise.all(
      files.map<Promise<OpeningImageInput>>(async file => {
        try {
          const isHeic =
            file.type === "image/heic" || file.name.includes(".HEIC");
          const blob = isHeic ? await heicToJpeg(file) : file;

          const clientImage = await loadImage(blob, {
            // Max size here serves 2 purposes.
            // 1. It shrinks down the source JPEGs so hopefully we don't hit the 6MB cloudfront
            //    limit on our image server.
            // 2. It keeps the canvas memory down, which can cause practically silent failures on
            //    iOS.
            maxWidth: 4096,
            maxHeight: 4096,
            canvas: true,
            meta: true,
          });

          const canvas = clientImage.image as HTMLCanvasElement;
          const key = getUploadKey("jpeg");
          const dataUrl = canvas.toDataURL("image/jpeg");

          // Track this upload at the project level.
          projectDispatch(
            beginUpload({
              key,
              dataUrl,
            })
          );
          // Do the upload and call out to the imageAdded method when complete.
          upload(key, dataUrl)
            .then(() => {
              projectDispatch(uploadCompleted(key));
              projectDispatch(saveFile());
            })
            .catch(e => {
              // Something went wrong on the upload, so we need to clear this out.
              addError("Upload error. Please try again.");
              dispatch(removeImage(key));
              captureException(e);
              projectDispatch(uploadFailed(key));
            });

          // TODO - More intelligent placement, especially when handling multiple photos
          const transform = getDefaultImageTransform(printLayer, {
            imageHeight: canvas.height,
            imageWidth: canvas.width,
          });
          return {
            createdTime: new Date().toISOString(),
            id: key,
            imageWidth: canvas.width,
            imageHeight: canvas.height,
            flipX: false,
            flipY: false,
            // Transform calculated above
            ...transform,
            // Adds any useful stuff we see in the exif data (time, location)
            ...getExifDataForOpeningImage(clientImage.exif),
          };
        } catch (e) {
          console.log(e);
          console.log(file);
          addError(
            `There was an error adding file ${file.name}. Please ensure you are uploading a valid .jpeg or .png file.`
          );
          return null;
        }
      })
    );
    const validNewImageStates = newImageStates.filter(x => !!x);
    if (validNewImageStates.length > 0) {
      dispatch(addImagesToOpening(validNewImageStates));
    }
  };
}

async function heicToJpeg(file: File): Promise<Blob> {
  /* I don't think heic2any actually lazy loads the chunk, instead it gets bundled with the Mapmaker
  chunk. This lib is 1.15mb which is equal to the rest of the Map Maker. Need to find a way
  to lazy load this. */
  const heic2any = (await import("heic2any")).default;
  return (await heic2any({
    blob: file,
    toType: "image/jpeg",
  })) as Blob;
}

export function removeImage(imageId: string) {
  return {
    type: REMOVE_IMAGE,
    imageId,
  };
}

export function setActiveImage(imageId: string) {
  return {
    type: SET_ACTIVE_IMAGE,
    imageId,
  };
}

export function setHighlightedImage(imageId: string) {
  return {
    type: SET_HIGHLIGHTED_IMAGE,
    imageId,
  };
}

export function adjustImageDepth(imageId: string, depthAdjustment: number) {
  return {
    type: ADJUST_IMAGE_DEPTH,
    imageId,
    depthAdjustment,
  };
}

export function setBackground(background: string) {
  return {
    type: SET_BACKGROUND,
    background,
  };
}

export const OpeningEditorZoomLevels = ["2%", "10%", "25%", "50%"];

export function setZoomLevel(zoomLevel: number) {
  return {
    type: SET_ZOOM_LEVEL,
    zoomLevel,
  };
}

export default function createEditableOpeningReducer(
  openingId: string,
  initialOpeningInput: OpeningInput
): Reducer<EditableOpeningState, AnyAction> {
  return produce(
    (draft: EditableOpeningState, action: AnyAction): EditableOpeningState => {
      if (draft === undefined) {
        return {
          openingId,
          openingInputState: initialOpeningInput,
          highlightedImageId: null,
          activeImageId: null,
          zoomLevel: 1,
        };
      }

      const reduceForImage = imageId => {
        const images = (original(draft) as EditableOpeningState)
          .openingInputState.images;
        const imageIndex = images.findIndex(image => image.id === imageId);
        const currentState = images[imageIndex];
        const newState = editableOpeningImageReducer(currentState, action);
        if (newState !== currentState) {
          draft.openingInputState.images[imageIndex] = newState;
        }
      };

      if (action.__IMAGE_ID__) {
        reduceForImage(action.__IMAGE_ID__);
      }

      switch (action.type) {
        case ADD_IMAGES:
          draft.openingInputState.images.push(...action.images);
          // Automatically select the final image from this batch.
          draft.activeImageId =
            draft.openingInputState.images[
              draft.openingInputState.images.length - 1
            ].id;
          trackGtmEvent({
            event: "mapmaker.add-images-to-project",
            openingId: draft.openingId,
            count: action.images.length,
          });
          break;
        case REMOVE_IMAGE:
          draft.openingInputState.images = draft.openingInputState.images.filter(
            image => image.id !== action.imageId
          );
          if (draft.activeImageId === action.imageId) {
            draft.activeImageId = null;
          }
          trackGtmEvent({
            event: "mapmaker.remove-image-from-project",
            openingId: draft.openingId,
          });
          break;
        case SET_ACTIVE_IMAGE:
          draft.activeImageId = action.imageId;
          break;
        case SET_HIGHLIGHTED_IMAGE:
          draft.highlightedImageId = action.imageId;
          break;
        case ADJUST_IMAGE_DEPTH:
          const currIndex = draft.openingInputState.images.findIndex(
            image => image.id === action.imageId
          );
          const targetIndex = clamp(
            currIndex + action.depthAdjustment,
            0,
            draft.openingInputState.images.length - 1
          );
          const imageToMove = draft.openingInputState.images.splice(
            currIndex,
            1
          );
          draft.openingInputState.images.splice(targetIndex, 0, ...imageToMove);
          break;
        case SET_BACKGROUND:
          draft.openingInputState.background = action.background;
          break;
        case SET_ZOOM_LEVEL:
          draft.zoomLevel = action.zoomLevel;
          break;
        default:
          break;
      }
    }
  );
}
