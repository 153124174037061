import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import {
  multiplyMoneyV2,
  purchaseStickerTokensQuantity,
} from "../../lib/storefront";
import { StorefrontCheckoutLineItemHeavyFragment } from "../../client/MapmakerApi";
import { StickerTokenIcon } from "../shared/icons/StickerTokenIcon";
import LineItem from "./LineItem";
import { useMapmakerAppConfig } from "../..";
import "./PurchaseStickerTokensLineItem.css";

type IPurchaseStickerTokensLineItemProps = {
  lineItem: StorefrontCheckoutLineItemHeavyFragment;
  willBeMailed: boolean;
};

export default function PurchaseStickerTokensLineItem({
  lineItem,
  willBeMailed,
}: IPurchaseStickerTokensLineItemProps) {
  const { gotoProduct } = useMapmakerAppConfig();
  if (!lineItem.variant) {
    throw new Error("Variant not found for purchase sticker tokens line item.");
  }

  const tokenQuantity = purchaseStickerTokensQuantity(lineItem.variant);
  const prettyQuantity =
    tokenQuantity === 1 ? "1 Sticker Token" : `${tokenQuantity} Sticker Tokens`;

  return (
    <LineItem
      title={
        lineItem.quantity === 1
          ? prettyQuantity
          : `${lineItem.quantity} ✕ ${prettyQuantity}`
      }
      onProductNameClick={() => gotoProduct(lineItem.variant.product.handle)}
      thumbnail={<StickerTokenIcon size={100} style={{ padding: "1rem" }} />}
      price={multiplyMoneyV2(lineItem.variant?.priceV2, lineItem.quantity)}
      lineItemIds={[lineItem.id]}
      discountAllocations={lineItem.discountAllocations}
    >
      <div id="purchase-sticker-tokens-line-item">
        {willBeMailed ? (
          <p>
            {lineItem.quantity === 1
              ? "A unique code"
              : `${lineItem.quantity} unique codes`}{" "}
            redeemable for {prettyQuantity} will be included in the package with
            this order. The same {lineItem.quantity === 1 ? "code" : "codes"}{" "}
            will also be emailed to the <i>buyers</i> email address.
          </p>
        ) : (
          <p>
            After placing your order{" "}
            {lineItem.quantity === 1
              ? "a unique code"
              : `${lineItem.quantity} unique codes`}{" "}
            redeemable for {prettyQuantity} will be emailed to you. You can use
            the {lineItem.quantity === 1 ? "code" : "codes"} to redeem the
            tokens on your own account or you can give{" "}
            {lineItem.quantity === 1 ? "it" : "them"} to someone else to redeem
            on their account.
          </p>
        )}
        <p className="note">
          <Icon name="info circle" />
          For more information about sticker tokens see{" "}
          <Link to={`/help/sticker-tokens`}>this page</Link>{" "}
        </p>
      </div>
    </LineItem>
  );
}
