import {
  GraphicFeature,
  GraphicInput,
  getGraphicForOutputType,
} from "@mapmaker/core";
import { useProjectFile } from "../../useProjectState";
import useFeature from "../useFeature";

export function useGraphic(graphicId) {
  const { feature: graphic, featureInput: graphicInput } = useFeature<
    GraphicFeature,
    GraphicInput
  >(graphicId, "GRAPHIC");

  const { outputType } = useProjectFile();

  if (!graphic) {
    return {
      graphic: null,
      graphicInput: null,
    };
  }

  const usableGraphic = getGraphicForOutputType(graphic, outputType);

  return {
    graphic: usableGraphic,
    graphicInput: graphicInput,
  };
}
