import type {TBLDotComConfig} from ".";

const config:TBLDotComConfig = {
  stage: "dev",
  tblApi: {
    url: "https://api-dev.photomaps.com/dev"
  },
  aws: {
    region: "us-east-1",
    cognito: {
      userPoolId: "us-east-1_71jLMefNO",
      appClientId: "2i74canrdip80v5n9fi1ntr9c3",
      identityPoolId: "us-east-1:ec1ec0e2-3c4d-4f9a-b2bb-99bf6dba54fb",
    },
  },
  shopify: {
    domain: "thunder-bunny-labs.myshopify.com",
    storefrontAccessToken: "7c5da4522c79b2b730bc4b065db24aba",
  },
};
export default config;