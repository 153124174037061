import React, { useCallback } from "react";
import { AuthBoxState, useAuthBoxContext } from "../AuthBox";
import "./UserNotFoundMessage.css";

export interface IUserNotFoundMessageProps {
  email: string;
}

export default function UserNotFoundMessage({
  email,
}: IUserNotFoundMessageProps) {
  const {
    userNotFoundExplanation,
    setAuthBoxState,
    setAuthenticationDetails,
  } = useAuthBoxContext();

  const gotoSignUp = useCallback(() => {
    setAuthenticationDetails({ email });
    setAuthBoxState(AuthBoxState.SIGN_UP);
  }, [setAuthBoxState]);

  return (
    <div id="user-not-found-message">
      <p className="error-message">
        No account found for <strong>{email}</strong>
      </p>
      <p className="register-message">
        Would you like to{" "}
        <span className="link" onClick={gotoSignUp}>
          register
        </span>{" "}
        instead?
      </p>
      {userNotFoundExplanation}
    </div>
  );
}
