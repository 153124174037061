import MapmakerConfig from "@mapmaker/config";

export type MapmakerServerConfig = {
  date: Date;
  _retrievedAt: Date;
};

let serverConfigFetch: Promise<MapmakerServerConfig> = null;
let serverConfigCached = null;

export async function getServerConfig(
  ignoreCache: boolean = false
): Promise<MapmakerServerConfig> {
  if (!ignoreCache && serverConfigCached) {
    return serverConfigCached;
  }
  if (!serverConfigFetch) {
    serverConfigFetch = fetchServerConfig();
  }
  serverConfigCached = await serverConfigFetch;
  return serverConfigCached;
}

async function fetchServerConfig(): Promise<MapmakerServerConfig> {
  const response = await fetch(`${MapmakerConfig.apiGateway.url}/config`, {
    cache: "no-cache",
  });
  const config = await response.json();
  return {
    ...config,
    date: config.date ? new Date(config.date) : new Date(),
    _retrievedAt: new Date(),
  } as MapmakerServerConfig;
}
