import { Button, IButtonProps } from "@mapmaker/ui";
import { useState } from "react";
import RegionCompletionSharingModal from "./RegionCompletionSharingModal";

type CreateRegionCompletionSharableButtonProps = IButtonProps;

export default function CreateRegionCompletionSharableButton({
  children,
  ...props
}: CreateRegionCompletionSharableButtonProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <RegionCompletionSharingModal
        open={open}
        onClose={() => setOpen(false)}
      />
      <Button {...props} onClick={() => setOpen(true)}>
        {children}
      </Button>
    </>
  );
}
