import React from 'react';

export default class BorderGuide extends React.Component<any> {
  static defaultProps = {
    x: 0,
    y: 0,
    dpi: 72,
    borderWidth: 4,
  };

  _sizeMessage() {
    const width = Math.round((10 * this.props.width) / this.props.dpi) / 10;
    const height = Math.round((10 * this.props.height) / this.props.dpi) / 10;
    return `RECTANGLE SHOULD BE ${width}" x ${height}" WHEN PRINTED`;
  }

  render() {
    return (
      <g id="border-guide">
        <defs>
          <filter x="-0.05" y="0" width="1.1" height="1" id="solid">
            <feFlood floodColor="#FFFFFF" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <rect
          x={this.props.x + this.props.borderWidth / 2}
          y={this.props.y + this.props.borderWidth / 2}
          width={this.props.width - this.props.borderWidth}
          height={this.props.height - this.props.borderWidth}
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: this.props.borderWidth,
            strokeDasharray: '5 5',
          }}
        />
        <text
          x={this.props.x + this.props.width / 2}
          y={this.props.y + this.props.height}
          textAnchor="middle"
          filter="URL(#solid)"
          style={{
            fontSize: '9px',
            fontFamily: 'Verdana, Geneva, sans-serif',
          }}
        >
          {this._sizeMessage()}
        </text>
      </g>
    );
  }
}
/*
BorderGuide.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  dpi: PropTypes.number,
  borderWidth: PropTypes.number,
};
*/
