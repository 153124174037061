import { Button } from "@mapmaker/ui";
import { AuthBox, AuthBoxState, useAuthContext } from "@tbl/aws-auth";
import { useState } from "react";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaRegSurprise } from "@react-icons/all-files/fa/FaRegSurprise";
import { useSearchParams } from "react-router-dom";
import { useMapmakerAppConfig } from "../../client";
import CenteredPage from "../shared/CenteredPage";
import "./MapmakerProjectNotFoundPage.css";

type ProjectReferrerCode = "collaborator-invite";

export default function MapmakerProjectNotFoundPage() {
  const { isAuthenticated } = useAuthContext();
  const [authBoxState, setAuthBoxState] = useState<AuthBoxState>(
    AuthBoxState.SIGN_UP
  );
  const [searchParams] = useSearchParams();
  const referrerCode = searchParams.get("r") as ProjectReferrerCode;
  const email = searchParams.get("e");

  function onAuthBoxStateChange(authBoxState: AuthBoxState) {
    setAuthBoxState(authBoxState);
  }

  if (isAuthenticated) {
    return <AuthenticatedProjectNotFoundPage />;
  } else if (referrerCode !== "collaborator-invite") {
    return <UnauthenticatedProjectNotFoundPage />;
  }

  return (
    <div id="mapmaker-project-not-found-page">
      <div className="auth-box-container">
        <div className="invite-message">
          <div className="header">
            <FaEnvelope fontSize={110} color="var(--mm-accent-color)" />
            <div>
              <h1>You're Invited!</h1>
              <h3>let's get started</h3>
            </div>
          </div>
          {authBoxState === AuthBoxState.SIGN_UP && (
            <p>
              This project is private so you'll need an account to contribute.
              Don't worry it's fast, free, and we never send spam!
            </p>
          )}
        </div>
        <AuthBox
          termsAndConditionsUrl="/terms"
          onAuthBoxStateChange={onAuthBoxStateChange}
          authBoxState={authBoxState}
          email={email}
          noHeader={
            authBoxState === AuthBoxState.SIGN_IN ||
            authBoxState === AuthBoxState.SIGN_UP
          }
        />
        <div className="alternatives">
          {authBoxState === AuthBoxState.SIGN_IN && (
            <>
              <p>Don't have an account yet?</p>
              <Button
                outlined
                onClick={() => setAuthBoxState(AuthBoxState.SIGN_UP)}
              >
                Register Instead
              </Button>
            </>
          )}
          {authBoxState === AuthBoxState.SIGN_UP && (
            <>
              <p>Already have an account?</p>
              <Button
                outlined
                onClick={() => setAuthBoxState(AuthBoxState.SIGN_IN)}
              >
                Log In Instead
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function AuthenticatedProjectNotFoundPage() {
  const { user } = useAuthContext();
  return (
    <CenteredPage>
      <FaRegSurprise fontSize={140} />
      <div style={{ maxWidth: "600px", marginTop: "2rem" }}>
        <h2>We could not find this project</h2>
        <p>
          You may have a bad link or you may not have permission to view the
          project.
        </p>
        <p>
          If you were invited to this project make sure the owner used the
          correct email address when inviting you. You are logged in as{" "}
          <strong>{user?.email ?? "unknown"}</strong>
        </p>
      </div>
    </CenteredPage>
  );
}

function UnauthenticatedProjectNotFoundPage() {
  const { gotoSignIn } = useMapmakerAppConfig();
  return (
    <CenteredPage>
      <FaRegSurprise fontSize={140} />
      <div style={{ maxWidth: "600px", marginTop: "2rem" }}>
        <h2>We could not find this project</h2>
        <p>
          If this is your project you will need to{" "}
          <span className="link" onClick={() => gotoSignIn()}>
            log in
          </span>{" "}
          to view it.
        </p>
      </div>
    </CenteredPage>
  );
}
