import React from "react";
import { getFeaturesInOrder, MapmakerBusinessId } from "@mapmaker/core";
import FeatureNode from "../FeatureNode";
import { MapmakerSvgContextProvider } from "../MapmakerSvgContext";
import {
  RenderableDesign,
  RenderableFile,
  RenderablePropMode,
} from "@mapmaker/renderable";

/*
 * Renders an image that looks like the full product.
 */
export type FullProductSvgProps<Mode extends RenderablePropMode> = {
  design: RenderableDesign[Mode];
  file: RenderableFile[Mode];
  width: number;
  height: number;
};

export default function FullProductSvg({
  file,
  width,
  height,
}: FullProductSvgProps<"resource">) {
  const viewBox = [0, 0, file.design.width, file.design.height];
  const svgScale = (width || file.design.width) / file.design.width;
  const sortedFeatures = getFeaturesInOrder(file.design);

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox.join(" ")}
      width={width}
      height={height}
    >
      <MapmakerSvgContextProvider
        value={{
          svgScale,
          businessId: file.design.businessId as MapmakerBusinessId,
        }}
      >
        {sortedFeatures.map(feature => {
          return (
            <FeatureNode
              key={feature.id}
              feature={feature}
              input={file.inputs[feature.id]}
              svgScale={svgScale}
            />
          );
        })}
      </MapmakerSvgContextProvider>
    </svg>
  );
}
