import React from "react";
import { OutputTypeDemonstrationProps } from "@mapmaker/renderable";
import { getOpeningList } from "@mapmaker/core";
import SvgNode from "../SvgNode";
import { getLayerByPriority } from "../opening";

export default function VehicleMapDemonstration({
  design,
  width,
  height,
  primaryColor,
  highlightColor = primaryColor,
  lowlightColor = primaryColor,
  highlightedOpeningIds = Object.keys(design.features),
  backgroundColor = "#FFFFFF",
}: Omit<OutputTypeDemonstrationProps<"resource">, "outputType">) {
  const viewBox = [0, 0, 101, 56.25];

  const scale = Math.min(width / viewBox[2], height / viewBox[3]);
  const strokeWidth = 1 / scale;
  const openings = getOpeningList(design);
  const mapScale = Math.min(
    (0.4 * viewBox[2]) / design.width,
    (0.4 * viewBox[3]) / design.height
  );

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox.join(" ")}
      width={width}
      height={height}
    >
      <path
        d="M72.26,46.98c-.02-.24-.03-.48-.03-.72,0,4.71,3.82,8.52,8.52,8.52s8.52-3.82,8.52-8.52c0,.24-.01,.48-.03,.72h5.03c1.05,0,1.96-.59,2.45-1.44v.03h2.08c.66,0,1.19-.53,1.19-1.19v-5.18c0-.66-.53-1.19-1.19-1.19h-1.7v-3.38c0-1.55-1.4-2.23-2.83-2.83l-13.78-5.74h-.04l-5.51-10.86h10.33c5.05,0,2.11-3.88,.77-5.87l-4.39-6.49c-.87-1.29-1.27-2.83-2.83-2.83H5.78c-1.55,0-2.83,1.27-2.83,2.83V39.13l-.07-1.12H1.19c-.66,0-1.19,.53-1.19,1.19v5.18c0,.66,.53,1.19,1.19,1.19H3.34v-.02c.49,.86,1.4,1.44,2.45,1.44h10.82s0,.1,0,.14c0,4.71,3.82,8.52,8.52,8.52s8.52-3.82,8.52-8.52c0-.05,0-.1,0-.14h38.63Z"
        style={{ fill: backgroundColor }}
      />
      <g
        style={{
          fill: "none",
          stroke: primaryColor,
          strokeMiterlimit: 10,
          strokeWidth,
        }}
      >
        <path d="M33.94,47.28h38.62c-.02-.24-.03-.48-.03-.72,0-4.71,3.82-8.52,8.52-8.52s8.52,3.82,8.52,8.52c0,.24-.01,.48-.03,.72h5.03c1.55,0,2.83-1.27,2.83-2.83v-9.54c0-1.55-1.39-2.23-2.83-2.83h0l-13.78-5.74-14.75,.03V15.49h19.52c5.05,0,2.11-3.88,.77-5.87l-4.39-6.49c-.87-1.29-1.27-2.83-2.83-2.83H6.08c-1.55,0-2.83,1.27-2.83,2.83V44.46c0,1.55,1.27,2.83,2.83,2.83h10.81" />
        <polyline points="80.8 26.44 75.24 15.49 75.1 15.23" />
        <path d="M97.41,38.3h1.7c.66,0,1.19,.53,1.19,1.19v5.18c0,.66-.53,1.19-1.19,1.19h-2.08" />
        <path d="M3.64,45.86H1.49c-.66,0-1.19-.53-1.19-1.19v-5.18c0-.66,.53-1.19,1.19-1.19h1.7" />
        <path d="M45.88,47.49V16.46c0-.31,.13-.59,.33-.8,.21-.21,.49-.33,.8-.33h12.45c.31,0,.59,.13,.8,.33,.21,.21,.33,.49,.33,.8v31.03" />
        <rect
          x="64.73"
          y="4.62"
          width="12.33"
          height="6.35"
          rx="1.19"
          ry="1.19"
        />
        <rect
          x="28.17"
          y="4.62"
          width="12.33"
          height="6.35"
          rx="1.19"
          ry="1.19"
        />
        <rect
          x="7.41"
          y="4.62"
          width="12.33"
          height="6.35"
          rx="1.19"
          ry="1.19"
        />
        <path d="M81.05,38.04c-4.71,0-8.52,3.82-8.52,8.52s3.82,8.52,8.52,8.52,8.52-3.82,8.52-8.52-3.82-8.52-8.52-8.52h0Z" />
        <path d="M81.05,42.81c-2.07,0-3.75,1.68-3.75,3.75s1.68,3.75,3.75,3.75,3.75-1.68,3.75-3.75-1.68-3.75-3.75-3.75h0Z" />
        <path d="M57.87,30.28c-.63,0-1.15,.51-1.15,1.15s.51,1.15,1.15,1.15,1.15-.51,1.15-1.15-.51-1.15-1.15-1.15h0Z" />
        <path d="M25.41,38.9c-4.71,0-8.52,3.82-8.52,8.52s3.82,8.52,8.52,8.52,8.52-3.82,8.52-8.52-3.82-8.52-8.52-8.52h0Z" />
        <path d="M25.41,43.67c-2.07,0-3.75,1.68-3.75,3.75s1.68,3.75,3.75,3.75,3.75-1.68,3.75-3.75-1.68-3.75-3.75-3.75h0Z" />
      </g>
      <g
        transform={`translate(${25 - (design.width * mapScale) / 2}, ${25 -
          (design.height * mapScale) / 2}) scale(${mapScale})`}
      >
        {openings
          .filter(opening => opening.enabled !== false)
          .map(opening => {
            return (
              <SvgNode
                key={opening.id}
                nodeData={getLayerByPriority(opening, [
                  "cut",
                  "print",
                  "inner",
                ])}
                style={{
                  fill: highlightedOpeningIds.includes(opening.id)
                    ? highlightColor
                    : lowlightColor,
                }}
              />
            );
          })}
      </g>
    </svg>
  );
}
