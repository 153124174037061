import { produce } from "immer";

export type Upload = {
  key: string;
  dataUrl: string;
  status: "IN_PROGRESS" | "COMPLETED" | "FAILED";
};

export type UploadsState = Upload[];

const BEGIN_UPLOAD = "uploads.begin_upload";
const UPLOAD_COMPLETED = "uploads.upload_completed";
const UPLOAD_FAILED = "uploads.upload_failed";

export function beginUpload(upload: Pick<Upload, "key" | "dataUrl">) {
  return {
    type: BEGIN_UPLOAD,
    upload,
  };
}

export function uploadCompleted(key: string) {
  return {
    type: UPLOAD_COMPLETED,
    key,
  };
}

export function uploadFailed(key: string) {
  return {
    type: UPLOAD_FAILED,
    key,
  };
}

const uploadReducer = produce(
  (draft: UploadsState, action): UploadsState => {
    if (draft === undefined) {
      return [];
    }
    switch (action.type) {
      case BEGIN_UPLOAD:
        draft.push({
          ...action.upload,
          status: "IN_PROGRESS",
        });
        break;
      case UPLOAD_COMPLETED:
        for (let upload of draft) {
          if (upload.key === action.key) {
            upload.status = "COMPLETED";
          }
        }
        break;
      case UPLOAD_FAILED:
        for (let upload of draft) {
          if (upload.key === action.key) {
            upload.status = "FAILED";
          }
        }
        break;
    }
  }
);
export default uploadReducer;
