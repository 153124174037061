import { OpeningSuggestionFragment } from "../../../client/MapmakerApi";
import OpeningSuggestionListItem from "./OpeningSuggestionListItem";
import "./OpeningSuggestionList.css";

type OpeningSuggestionListProps = {
  suggestions: OpeningSuggestionFragment[];
  onClick(suggestion: OpeningSuggestionFragment): any;
};

export function OpeningSuggestionList({
  suggestions,
  onClick,
}: OpeningSuggestionListProps) {
  return (
    <div id="opening-suggestion-list">
      {suggestions.map(suggestion => {
        return (
          <OpeningSuggestionListItem
            key={suggestion.id}
            suggestion={suggestion}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
}
