import React from "react";
import { getGraphicFeature, MapmakerDesign, Viewbox } from "@mapmaker/core";
import Graphic from "../../graphics/Graphic";
import Frame from "../tbl/Frame";
import { FrameId, getFrame } from "../tbl/FrameLibrary";
import {
  HangerType,
  FrameHangerType,
  GrommetType,
  PhotomapsDotComProductSvgProps,
  RenderableDesignResource,
} from "@mapmaker/renderable";

/*
 * Renders an image that looks like the full product.
 */

export default function PhotomapsDotComProductSvg({
  design,
  backgroundSelection,
  backgroundUrl,
  hanger,
  width,
  height,
}: PhotomapsDotComProductSvgProps<"resource">) {
  const viewBox = getViewbox(design, { hanger });
  const svgScale = (width || design.width) / design.width;
  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox.join(" ")}
      width={width}
      height={height}
    >
      <g
        style={{
          filter: `drop-shadow(0 12pt 16pt RGBA(0,0,0,0.8))`,
        }}
      >
        {backgroundUrl ? (
          <image
            x={0}
            y={0}
            width={design.width}
            height={design.height}
            href={backgroundUrl}
          />
        ) : (
          <Graphic
            feature={getGraphicFeature(design, "background")}
            input={{
              modifiedAt: new Date().toDateString(),
              type: "GRAPHIC",
              selectedOption: backgroundSelection,
            }}
            svgScale={svgScale}
          />
        )}
      </g>
      <Hanger hanger={hanger} design={design} />
    </svg>
  );
}

type HangerProps = {
  hanger: HangerType;
  design: RenderableDesignResource;
};

function Hanger({ hanger, design }: HangerProps) {
  // @ts-ignore
  console.log(hanger);
  switch (hanger) {
    case "none":
      return null;
    case "frame-black":
    case "frame-white":
    case "frame-grey":
      const frameId = hanger.split("-")[1];
      return (
        <Frame
          frame={getFrame(frameId)}
          width={design.width}
          height={design.height}
        />
      );
    case "hanger-bars-pine":
      const frameHangerType = hanger.split("-")[2] as FrameHangerType;
      return (
        <FrameHanger
          type={frameHangerType}
          width={design.width}
          height={design.height}
        />
      );
    case "grommets-corners":
      const grommetType = hanger.split("-")[1] as GrommetType;
      return (
        <Grommets
          type={grommetType}
          width={design.width}
          height={design.height}
        />
      );
    default:
      return null;
  }
}

type FrameHangerProps = {
  type: FrameHangerType;
  x?: number;
  y?: number;
  width: number;
  height: number;
};

const hangerBarHeight = 72;
const overhang = 16;
const ropeThickness = 8;
const woodColor = "#D9B88C";
const ropeColor = "#222222";

function FrameHanger({ type, x = 0, y = 0, width, height }: FrameHangerProps) {
  if (type === "none" || !type) {
    return null;
  }
  const styles = {
    filter: `drop-shadow(0 10pt 8pt #000000)`,
  };
  return (
    <>
      <line
        x1={x + width * 0.1}
        y1={y}
        x2={x + width * 0.5}
        y2={y - height * 0.15}
        stroke={ropeColor}
        strokeWidth={ropeThickness}
        style={styles}
      />
      <line
        x1={x + width * 0.5}
        y1={y - height * 0.15}
        x2={x + width * 0.9}
        y2={y}
        stroke={ropeColor}
        strokeWidth={ropeThickness}
        style={styles}
      />
      <rect
        x={x - overhang}
        y={y - hangerBarHeight / 2}
        width={width + overhang * 2}
        height={hangerBarHeight}
        fill={woodColor}
        style={styles}
      />
      <rect
        x={x - overhang}
        y={y + height - hangerBarHeight / 2}
        width={width + overhang * 2}
        height={hangerBarHeight}
        fill={woodColor}
        style={styles}
      />
    </>
  );
}

type GrommetsProps = {
  type: GrommetType;
  width: number;
  height: number;
};

const GROMMET_INSET = 1 * 72;

function Grommets({ type, width, height }: GrommetsProps) {
  if (type === "none") {
    return null;
  }
  // Implicit "corners" type for now
  return (
    <>
      <Grommet x={GROMMET_INSET} y={GROMMET_INSET} />
      <Grommet x={width - GROMMET_INSET} y={GROMMET_INSET} />
      <Grommet x={width - GROMMET_INSET} y={height - GROMMET_INSET} />
      <Grommet x={GROMMET_INSET} y={height - GROMMET_INSET} />
    </>
  );
}

type GrommetProps = {
  x: number;
  y: number;
};

const GROMMET_OUTSIDE_DIAMETER = 0.75 * 72;
const GROMMET_INSIDE_DIAMETER = 0.35 * 72;
const GROMMET_COLOR_LIGHT = "#CCCCCC";
const GROMMET_COLOR_DARK = "#787878";

function Grommet({ x, y }: GrommetProps) {
  return (
    <>
      <defs>
        <radialGradient id="grommet-gradient">
          <stop offset="70%" stopColor={GROMMET_COLOR_LIGHT} />
          <stop offset="90%" stopColor={GROMMET_COLOR_DARK} />
        </radialGradient>
      </defs>
      <circle
        cx={x}
        cy={y}
        r={GROMMET_OUTSIDE_DIAMETER / 2}
        fill="url(#grommet-gradient)"
      />
      <circle
        cx={x}
        cy={y}
        r={GROMMET_INSIDE_DIAMETER / 2}
        fill="#FFFFFF"
        stroke={GROMMET_COLOR_DARK}
      />
    </>
  );
}

type GetViewboxOptions = {
  hanger: HangerType;
};

const buffer = 1 * 72;
function getViewbox(
  { width, height }: Pick<MapmakerDesign, "width" | "height">,
  { hanger }: GetViewboxOptions
): Viewbox {
  if (!hanger) {
    return [-buffer, -buffer, width + buffer * 2, height + buffer * 2];
  }
  if (hanger.startsWith("hanger-bars-")) {
    return [
      -buffer,
      -(height * 0.15) - buffer,
      width + buffer * 2,
      height * 1.15 + buffer * 2 + hangerBarHeight,
    ];
  } else if (hanger.startsWith("frame-")) {
    const { thickness = 0 } = getFrame(hanger.split("-")[1]) ?? {};
    const effectiveThickness = thickness * 72 + buffer;
    return [
      -effectiveThickness,
      -effectiveThickness,
      width + effectiveThickness * 2,
      height + effectiveThickness * 2,
    ];
  } else {
    return [-buffer, -buffer, width + buffer * 2, height + buffer * 2];
  }
}
