import MoneyV2Range from "./MoneyV2Range";
import { StorefrontSelectedOption } from "../../../client/MapmakerApi";
import {
  variantCanMatch,
  moneyV2Range,
  IProductWithVariants,
  VariantWithPrice,
  VariantWithOptions,
} from "../../../lib/storefront";

export interface IVariantPriceRangeProps {
  product: IProductWithVariants<VariantWithPrice & VariantWithOptions>;
  selectedOptions: StorefrontSelectedOption[] | undefined;
}

export default function VariantPriceRange({
  product,
  selectedOptions = [],
}: IVariantPriceRangeProps) {
  const variants = product.variants.edges.map(edge => edge.node);
  const possibleVariants = variants.filter(variant =>
    variantCanMatch(variant, selectedOptions)
  );
  const priceRange = moneyV2Range(
    possibleVariants.map(variant => variant.priceV2)
  );
  return (
    <MoneyV2Range
      min={priceRange.minVariantPrice}
      max={priceRange.maxVariantPrice}
      shorten
    />
  );
}
