import { ProjectOutputType } from "@mapmaker/core";
import { ProjectOutputOption } from "../../../client/MapmakerApi";

export type OutputTypeDetails = {
  type: ProjectOutputType;
  name: string;
  description: string;
  stickerName: string;
  stickersName: string;
  lineItemName: string;
  lineItemNamePlural: string;
};

const OutputTypeLibrary: Record<
  ProjectOutputType,
  Omit<OutputTypeDetails, "type">
> = {
  MATBOARD_WALL_MAP: {
    name: "Matboard Wall Map",
    description: "stickers for a 3-layer mat board photo map",
    stickerName: "Sticker",
    stickersName: "Stickers",
    lineItemName: "Custom Photo Map Sticker",
    lineItemNamePlural: "Custom Photo Map Stickers",
  },
  MATBOARD_WALL_MAP_SINGLE: {
    name: "Matboard Wall Map",
    description: "a sticker for a 3-layer mat board photo map",
    stickerName: "Sticker",
    stickersName: "Stickers",
    lineItemName: "Custom Photo Map Sticker",
    lineItemNamePlural: "Custom Photo Map Stickers",
  },
  VEHICLE_MAP: {
    name: "RV Photo Map",
    description:
      "weatherproof stickers for a photo map which sticks to a vehicle",
    stickerName: "RV Sticker",
    stickersName: "RV Stickers",
    lineItemName: "Sticker for an RV Photo Map",
    lineItemNamePlural: "Stickers for an RV Photo Map",
  },
  VINYL_WALL_MAP: {
    name: "Wall Hanging Photo Map",
    description: "indoor stickers for a photo map which hangs on the wall",
    stickerName: "Wall Map Sticker",
    stickersName: "Wall Map Stickers",
    lineItemName: "Sticker for a Wall Hanging Photo Map",
    lineItemNamePlural: "Stickers for a Wall Hanging Photo Map",
  },
};

export function getOutputTypeDetails(type: ProjectOutputType) {
  if (!type) {
    return undefined;
  }
  return {
    type,
    ...OutputTypeLibrary[type],
  };
}

export function getSelectedOutputOption<
  T extends Pick<ProjectOutputOption, "scale" | "type">
>(type: ProjectOutputType, scale: number, outputOptions: T[]): T {
  if (!outputOptions) {
    return undefined;
  }
  return outputOptions.find(
    option => option.type === type && option.scale === scale
  );
}
