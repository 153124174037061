import * as yup from "yup";
import { RenderableForm } from ".";

export const PhotomapsDotComProductSvgForm: RenderableForm = {
  initialValues: {},
  inputs: [
    {
      type: "design",
      id: "design",
      name: "Design",
      validation: yup.string().required(),
    },
    {
      type: "design-graphic",
      id: "backgroundSelection",
      name: "Style",
      validation: yup.string().required(),
      designProperty: "design",
      graphicName: "background",
    },
    {
      type: "select",
      id: "hanger",
      name: "Frame Hanger",
      validation: yup.string().required(),
      options: [
        {
          label: "None",
          value: "none",
        },
        {
          label: "Pine",
          value: "pine",
        },
      ],
    },
    {
      type: "select",
      id: "frame",
      name: "Frame",
      description: "A TBL style frame",
      validation: yup.string().required(),
      options: [
        {
          label: "None",
          value: "none",
        },
        {
          label: "Black",
          value: "black",
        },
        {
          label: "Grey",
          value: "grey",
        },
        {
          label: "White",
          value: "white",
        },
      ],
    },
    {
      type: "select",
      id: "grommets",
      name: "Grommets",
      validation: yup.string().required(),
      options: [
        {
          label: "None",
          value: "none",
        },
        {
          label: "Corners",
          value: "corners",
        },
      ],
    },
    {
      type: "select",
      id: "hanger",
      name: "Hanger",
      validation: yup.string().required(),
      options: [
        {
          label: "None",
          value: "none",
        },
        {
          label: "Hanger Bars - Pine",
          value: "hanger-bars-pine",
        },
        {
          label: "Frame - Black",
          value: "frame-black",
        },
        {
          label: "Frame - White",
          value: "frame-white",
        },
        {
          label: "Frame - Grey",
          value: "frame-grey",
        },
        {
          label: "Grommets",
          value: "grommets-corners",
        },
      ],
    },
  ],
};
