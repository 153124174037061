import { useCallback } from "react";
import { SchemaOf } from "yup";

export function useValidateRenderableInput<T>(
  id: string,
  validation: SchemaOf<T>
): (value: T) => Promise<null | string> {
  return useCallback(
    async value => {
      if (!validation) {
        return null;
      }
      try {
        await validation.validate(value);
        return null;
      } catch (e) {
        return e.errors[0];
      }
    },
    [validation, id]
  );
}
