import { memo, useEffect } from "react";
import { Form, Message, Icon, Label } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { useFormikContext, Formik, FormikHelpers } from "formik";
import { useAuthContext } from "@tbl/aws-auth";
import {
  ContactQuestion,
  ContactField,
  TextField,
  FileField,
  SelectField,
} from "./MapmakerContactCenter";
import clsx from "clsx";
import { Button, LoadingSpinner } from "@mapmaker/ui";
import { FileUpload, useUploadFiles } from "../../../lib/hooks/useUploadFiles";
import useUserAgent from "../../../lib/hooks/useUserAgent";
import { gql } from "@apollo/client";
import { useCreateTicketMutation } from "../../../client/MapmakerApi";
import "./ContactQuestionForm.css";

type FormValues = { [key: string]: string };

interface IContactQuestionFormProps {
  question: ContactQuestion;
}

gql`
  mutation createTicket($input: CreateTicketInput) {
    createTicket(input: $input) {
      created
    }
  }
`;

export default function ContactQuestionForm({
  question,
}: IContactQuestionFormProps) {
  const userAgent = useUserAgent();
  const { user, identityId } = useAuthContext();
  const [createTicket] = useCreateTicketMutation();

  const initialValues: FormValues = {
    email: user?.email || "",
  };

  const onSubmit = async (
    values: FormValues,
    { setSubmitting, setStatus }: FormikHelpers<FormValues>
  ) => {
    const fields = question.fields
      ?.map(field => {
        const value = values[field.label];
        if (!value) {
          return null;
        } else {
          return {
            type: field.type,
            label: field.label,
            value: Array.isArray(value) ? undefined : value,
            values: Array.isArray(value) ? value : undefined,
          };
        }
      })
      .filter(x => !!x);
    const result = await createTicket({
      variables: {
        input: {
          name: values.name,
          email: values.email,
          subject: question.subject || question.label,
          message: values.message,
          fields,
          // By default we tag the ticker with the question ID
          tags: [question.id, ...(question.tags ?? [])],
          userId: identityId,
          userAgent: `${userAgent.browser.name} ${userAgent.browser.version}`,
        },
      },
    });
    setSubmitting(false);
    setStatus(result.data?.createTicket?.created ? "SUBMITTED" : "ERROR");
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <ContactFormWithContext question={question} />
    </Formik>
  );
}

function ContactFormWithContext({ question }: IContactQuestionFormProps) {
  const {
    handleChange,
    handleSubmit,
    values,
    status,
    isSubmitting,
  } = useFormikContext<FormValues>();
  const isUploading = status === "UPLOADING";

  return (
    <Form
      id="contact-question-form"
      loading={isSubmitting}
      success={status === "SUBMITTED"}
      error={status === "ERROR"}
      onSubmit={handleSubmit}
    >
      {question.deflection ? (
        <Message className="deflection" content={question.deflection} />
      ) : null}
      <Form.Field>
        <label>Your Email</label>
        <input
          type="email"
          name="email"
          placeholder="you@example.com"
          onChange={handleChange}
          value={values.email}
        />
      </Form.Field>
      <Form.Field>
        <label>Name</label>
        <input name="name" placeholder="Jane Doe" onChange={handleChange} />
      </Form.Field>
      {(question.fields || []).map(field => {
        return <ContactQuestionField key={field.label} field={field} />;
      })}
      {question.prompt !== false && (
        <Form.TextArea
          name="message"
          label={question.prompt || "Message"}
          onChange={handleChange}
          rows={8}
        />
      )}
      <div className="buttons">
        <Button
          type="submit"
          color="accent"
          disabled={isUploading || isSubmitting}
        >
          {isUploading ? "Uploading Files" : "Submit"}
        </Button>
      </div>
      <Message
        error
        header="Error Sending Message"
        content={
          <>
            Please email us directly at{" "}
            <a
              href="mailto:contact@thunderbunnylabs.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact@thunderbunnylabs.com
            </a>
            .
          </>
        }
      />
      <Message
        success
        header="Sent!"
        content="Thank you for your message, we almost always respond within 1 business day."
      />
    </Form>
  );
}

interface IContactQuestionFieldProps {
  field: ContactField;
}

function ContactQuestionField({ field }: IContactQuestionFieldProps) {
  switch (field.type) {
    case "text":
      return <TextFieldComponent textField={field as TextField} />;
    case "select":
      return <SelectFieldComponent selectField={field as SelectField} />;
    case "file":
      return <FileFieldComponent fileField={field as FileField} />;
    default:
      return null;
  }
}

/**
 * Text Field
 */
interface ITextFieldComponentProps {
  textField: TextField;
}

function TextFieldComponent({ textField }: ITextFieldComponentProps) {
  const { handleChange } = useFormikContext();
  return (
    <Form.Field>
      <label>{textField.label}</label>
      <div className="secondary">{textField.secondary}</div>
      <input
        name={textField.label}
        placeholder={textField.placeholder}
        onChange={handleChange}
      />
    </Form.Field>
  );
}

/**
 * File Field
 */

interface IFileFieldComponentProps {
  fileField: FileField;
}

function FileFieldComponent({ fileField }: IFileFieldComponentProps) {
  const { setStatus, setFieldValue } = useFormikContext();
  const {
    uploads,
    results,
    uploadFiles,
    removeUpload,
    uploadInProgress,
  } = useUploadFiles("contact");
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    preventDropOnDocument: true,

    onDrop: async (files: File[]) => {
      await uploadFiles(files);
    },
  });

  useEffect(() => {
    setFieldValue(fileField.label, results);
  }, [setFieldValue, fileField.label, results]);

  useEffect(() => {
    setStatus(uploadInProgress ? "UPLOADING" : "READY");
  }, [setStatus, uploadInProgress]);

  interface IUploadItemProps {
    upload: FileUpload;
  }

  const UploadItem = memo(({ upload }: IUploadItemProps) => {
    function UploadItemIcon() {
      switch (upload.status) {
        case "error":
          return <Icon name="warning circle" color="red" />;
        case "uploading":
          return (
            <LoadingSpinner
              size="tiny"
              inline
              style={{ margin: "-4px 0.5em -3px 0" }}
            />
          );
        case "complete":
          return <Icon name="check circle" color="green" />;
      }
    }

    return (
      <Label className="upload-item">
        <UploadItemIcon />
        {upload.filename}
        <Icon
          name="close"
          className="close"
          onClick={() => removeUpload(upload.key)}
        />
      </Label>
    );
  });

  return (
    <Form.Field className="file-field">
      <label>{fileField.label}</label>
      <div className="secondary">{fileField.secondary}</div>
      <input name={fileField.label} multiple {...getInputProps()} />
      {uploads.map(upload => (
        <UploadItem key={upload.key} upload={upload} />
      ))}
      <div
        {...getRootProps({
          className: clsx({
            "drop-target": true,
            "drag-active": isDragActive,
          }),
        })}
      >
        <p>
          <Icon name="upload" /> Click or drop files to upload.
        </p>
      </div>
    </Form.Field>
  );
}

/**
 * Select Field
 */

interface ISelectFieldComponentProps {
  selectField: SelectField;
}

function SelectFieldComponent({ selectField }: ISelectFieldComponentProps) {
  const { handleChange } = useFormikContext();
  return (
    <Form.Field>
      <label>{selectField.label}</label>
      <div className="secondary">{selectField.secondary}</div>
      <input name={selectField.label} onChange={handleChange} />
    </Form.Field>
  );
}
