import { produce } from "immer";
import { PackingMode } from "../../../lib/prints/openingPacker";
import { PrintSizeModeId } from "../../../lib/prints/printSizes";
import { IPrintService } from "../PrintServices";
import { LocalItem } from "../../../lib/browser/typedLocalStorage";
import { getFeatures } from "@mapmaker/core";
import {
  MapmakerDesignHeavyFragment,
  MapmakerFileHeavyFragment,
  ProjectOutputType,
} from "../../../client/MapmakerApi";

export type PrintSizeSettingsState = {
  packingMode: PackingMode;
  printSizeModeId: PrintSizeModeId;
};

const SET_PACKING_MODE = "print_size_settings.set_packing_mode";
const SET_PRINT_SIZE_MODE_ID = "print_size_settings.set_print_size_mode_id";

const localItem = LocalItem<PrintSizeSettingsState>(
  "mapmaker.printSizeSettings",
  1
);

export function setPackingMode(packingMode: PackingMode) {
  return {
    type: SET_PACKING_MODE,
    packingMode,
  };
}

export function setPrintSizeModeId(printSizeModeId: PrintSizeModeId) {
  return {
    type: SET_PRINT_SIZE_MODE_ID,
    printSizeModeId,
  };
}

// Makes sure the settings are valid for the given print service.  gets defaults if not.
function getSafeSettings(
  settings: PrintSizeSettingsState,
  service: IPrintService
): PrintSizeSettingsState {
  if (
    service.printSizeModes.find(
      printSizeMode => printSizeMode.id === settings.printSizeModeId
    )
  ) {
    return settings;
  } else {
    return {
      ...settings,
      printSizeModeId: service.printSizeModes[0].id,
    };
  }
}

export default function createPrintSizeSettingsReducer(
  file: MapmakerFileHeavyFragment,
  printService: IPrintService
) {
  const { design } = file;
  return produce(
    (draft: PrintSizeSettingsState, action): PrintSizeSettingsState => {
      if (draft === undefined) {
        const localSettings = localItem.get();
        if (localSettings !== null) {
          return getSafeSettings(localSettings, printService);
        } else {
          const singlePrintMode =
            file.outputType === ProjectOutputType.MatboardWallMapSingle;
          const printSizeModeId = singlePrintMode
            ? PrintSizeModeId.FULL_SIZE
            : printService.printSizeModes[0].id;
          return {
            packingMode: PackingMode.MULTI,
            printSizeModeId,
          };
        }
      }

      switch (action.type) {
        case SET_PACKING_MODE:
          draft.packingMode = action.packingMode;
          localItem.put(draft);
          break;
        case SET_PRINT_SIZE_MODE_ID:
          draft.printSizeModeId = action.printSizeModeId;
          localItem.put(draft);
          break;
        default:
          break;
      }
    }
  );
}
