import { Button } from "@mapmaker/ui";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FaMinus } from "@react-icons/all-files/fa/FaMinus";
import "./OpeningEditorViewToolbar.css";
import useEditableOpening from "./editable/useEditableOpening";
import { useEditableOpeningDispatch } from "./editable/EditableOpeningStore";
import {
  OpeningEditorZoomLevels,
  setZoomLevel,
} from "./editable/editableOpeningReducer";

type OpeningEditorViewToolbarProps = {};

export default function OpeningEditorViewToolbar({}: OpeningEditorViewToolbarProps) {
  const { zoomLevel } = useEditableOpening();
  const dispatch = useEditableOpeningDispatch();

  function zoomIn() {
    dispatch(setZoomLevel(Math.max(0, zoomLevel - 1)));
  }

  function zoomOut() {
    dispatch(
      setZoomLevel(Math.min(OpeningEditorZoomLevels.length - 1, zoomLevel + 1))
    );
  }

  return (
    <div id="opening-editor-view-toolbar">
      <Button
        size="small"
        outlined
        group="vertical"
        onClick={zoomIn}
        disabled={zoomLevel === 0}
      >
        <FaPlus />
      </Button>
      <Button
        size="small"
        outlined
        group="vertical"
        onClick={zoomOut}
        disabled={zoomLevel === OpeningEditorZoomLevels.length - 1}
      >
        <FaMinus />
      </Button>
    </div>
  );
}
