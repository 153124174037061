import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  ReactNode,
  useCallback,
  useState,
} from "react";
import MapmakerConfig from "@mapmaker/config";

type SafeImageProps = Omit<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  "onerror"
> & {
  fallback?: ReactNode;
};

/**
 * Displays an image, but falls back to the property provided as 'fallback' if it is not found.
 *  If fallback is not included - Default "Image not Found" image.
 *  If fallback is a string - It is treated as the source for the fallback image.
 *  Otherwise - Fallback is rendered as an element.
 */
export default function SafeImage({
  fallback = `${MapmakerConfig.staticUrl}/images/fallback/image-not-found.svg`,
  ...imgProps
}: SafeImageProps) {
  const [error, setError] = useState(false);
  const onError = useCallback(() => {
    setError(true);
  }, [setError]);

  if (error || !imgProps.src) {
    if (typeof fallback === "string") {
      return (
        <img
          {...imgProps}
          style={{ ...imgProps.style, objectFit: "contain" }}
          src={fallback}
        />
      );
    } else {
      return <>{fallback}</>;
    }
  } else {
    return <img onError={onError} {...imgProps} />;
  }
}
