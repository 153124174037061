import { useEffect, Fragment } from "react";
import { Grid, Header, Icon } from "semantic-ui-react";
import WalgreensStoreDirectionsLink from "./WalgreensStoreDirectionsLink";
import useWalgreensCheckout from "./useWalgreensCheckout";
import { prettyPromiseTime } from "./walgreensLib";
import { useDispatch } from "react-redux";
import {
  clearCustomerInformation,
  clearSelectedStore,
  setSelectedStore,
} from "./walgreensCheckoutReducer";
import useInterval from "../../../lib/hooks/useInterval";
import "./WalgreensOrderInformation.css";
import {
  useWalgreensStoresLazyQuery,
  useWalgreensStoresQuery,
} from "../../../client/MapmakerApi";

interface IWalgreensOrderInformationProps {
  readOnly?: boolean;
}

export default function WalgreensOrderInformation({
  readOnly = false,
}: IWalgreensOrderInformationProps) {
  const dispatch = useDispatch();
  const {
    checkout,
    products,
    productDetailsInput,
    orderProductDetailsInput,
    subtotal,
  } = useWalgreensCheckout();

  // This is to get the latest print time for the store.
  const { refetch: refreshStores } = useWalgreensStoresQuery({
    variables: {
      input: { productDetails: productDetailsInput, zip: checkout.zip },
    },
  });

  // Refresh every 60 seconds to update the Promise Time displayed to the user.
  useInterval(async () => {
    const stores = (await refreshStores()).data.walgreensStores;
    const selectedStore = stores.find(
      store => store.storeNum === checkout.selectedStore.storeNum
    );
    if (selectedStore) {
      dispatch(setSelectedStore(selectedStore, checkout.zip));
    }
  }, 60 * 1000);

  function renderPrices() {
    function _row(className, column1, column2, column3) {
      return (
        <>
          <Grid.Column width={2} className={className}>
            {column1}
          </Grid.Column>
          <Grid.Column width={10} className={className}>
            {column2}
          </Grid.Column>
          <Grid.Column width={4} className={className + " right"}>
            {column3}
          </Grid.Column>
        </>
      );
    }

    return (
      <>
        {_row(
          "head",
          <Header sub>qty</Header>,
          <Header sub>Size</Header>,
          <Header sub>Price</Header>
        )}
        {orderProductDetailsInput.map(productDetail => {
          const product = products.find(
            product => product.productId === productDetail.productId
          );
          const productQty = productDetail.imageDetails.reduce(
            (qty, imageDetail) => qty + imageDetail.qty,
            0
          );
          const total = product.productPrice * productQty;

          return (
            <Fragment key={product.productId}>
              {_row(
                "product",
                productQty,
                <span>
                  {product.productSize} print{" "}
                  <span className="item-price">
                    (${product.productPrice.toFixed(2)}/ea)
                  </span>
                </span>,
                "$" + total.toFixed(2)
              )}
            </Fragment>
          );
        })}
        <Grid.Column width={16}>
          <div className="total-divider" />
        </Grid.Column>
        {_row(
          "total",
          "",
          <>
            <p>
              <strong>Subtotal</strong>
            </p>
            <p className="tax-note">Tax calculated in store</p>
          </>,
          <strong>${subtotal.toFixed(2)}</strong>
        )}
      </>
    );
  }

  function renderEditLink(editAction, icon) {
    if (readOnly || !editAction) {
      return null;
    } else {
      return <Icon name={icon} color="blue" onClick={editAction} />;
    }
  }

  function renderSegmentHeader(
    segmentName,
    editAction = null,
    icon = "pencil"
  ) {
    return (
      <Header sub>
        {segmentName}
        {renderEditLink(editAction, icon)}
      </Header>
    );
  }

  return (
    <Grid id="walgreens-order-information" padded={false}>
      <Grid.Column computer={6} tablet={16} mobile={16}>
        {renderSegmentHeader("Pickup Location", () =>
          dispatch(clearSelectedStore())
        )}
        <p className="capitalize">
          {checkout.selectedStore.street.toLowerCase()}
        </p>
        <p className="capitalize">
          {checkout.selectedStore.city.toLowerCase()},{" "}
          {checkout.selectedStore.state} {checkout.selectedStore.zip}
        </p>
        <p>
          <WalgreensStoreDirectionsLink
            store={checkout.selectedStore}
            label="DIRECTIONS"
          />
        </p>
      </Grid.Column>
      <Grid.Column computer={6} tablet={16} mobile={16}>
        {renderSegmentHeader("Customer Information", () =>
          dispatch(clearCustomerInformation())
        )}
        <p>
          {checkout.customerInformation.firstName}{" "}
          {checkout.customerInformation.lastName}
        </p>
        <p>{checkout.customerInformation.email}</p>
        <p>{checkout.customerInformation.phone}</p>
      </Grid.Column>
      <Grid.Column computer={4} tablet={16}>
        {renderSegmentHeader("Pickup Time")}
        <p>{prettyPromiseTime(checkout.selectedStore.promiseTime)}</p>
      </Grid.Column>
      {renderPrices()}
    </Grid>
  );
}
