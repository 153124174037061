import {
  StorefrontMoneyV2,
  StorefrontProductPriceRange,
} from "../../client/MapmakerApi";

export const EmptyMoneyV2: StorefrontMoneyV2 = {
  amount: "0.00",
  // @ts-ignore
  currencyCode: "USD",
};

function ensureSameCurrency(
  ...moneys: (StorefrontMoneyV2 | StorefrontMoneyV2[])[]
) {
  const flatMoneys = moneys.flat(1);
  const same = flatMoneys.every(
    money => money.currencyCode === flatMoneys[0].currencyCode
  );
  if (!same) {
    throw new Error("Money has different currency type.");
  }
}

export function moneyV2ToString(
  money: StorefrontMoneyV2,
  shorten: Boolean = true
): string {
  const amount = parseFloat(money.amount);
  const hasCents = amount % 1 !== 0;
  const amountStr =
    shorten && !hasCents ? amount.toFixed(0) : amount.toFixed(2);
  if (money.currencyCode === "USD") {
    return "$" + amountStr;
  } else {
    return `${amountStr}{moneyV2.currencyCode}`;
  }
}

export function moneyRangeToString(
  min: StorefrontMoneyV2,
  max: StorefrontMoneyV2
): string {
  ensureSameCurrency(min, max);
  if (moneyV2Equals(min, max)) {
    return moneyV2ToString(min);
  } else {
    return `${moneyV2ToString(min)} - ${moneyV2ToString(max)}`;
  }
}

export function lessThan(a: StorefrontMoneyV2, b: StorefrontMoneyV2): boolean {
  ensureSameCurrency(a, b);
  return parseFloat(a.amount) < parseFloat(b.amount);
}

export function greaterThan(
  a: StorefrontMoneyV2,
  b: StorefrontMoneyV2
): boolean {
  ensureSameCurrency(a, b);
  return parseFloat(a.amount) > parseFloat(b.amount);
}

export function moneyV2Equals(
  a: StorefrontMoneyV2,
  b: StorefrontMoneyV2
): boolean {
  if (!a || !b) {
    return !a && !b;
  }
  return a.amount === b.amount && a.currencyCode === b.currencyCode;
}

export function sumMoneyV2(moneys: StorefrontMoneyV2[]): StorefrontMoneyV2 {
  ensureSameCurrency(moneys);
  return moneys.reduce((sum, money) => addMoneyV2(sum, money), EmptyMoneyV2);
}

export function addMoneyV2(
  a: StorefrontMoneyV2,
  b: StorefrontMoneyV2 = EmptyMoneyV2
): StorefrontMoneyV2 {
  ensureSameCurrency(a, b);
  const totalAmount = parseFloat(a.amount) + parseFloat(b.amount);
  return {
    currencyCode: a.currencyCode,
    amount: totalAmount.toFixed(precision(a)),
  };
}

export function subtractMoneyV2(
  a: StorefrontMoneyV2,
  b: StorefrontMoneyV2 = EmptyMoneyV2
): StorefrontMoneyV2 {
  ensureSameCurrency(a, b);
  return addMoneyV2(a, multiplyMoneyV2(b, -1));
}

export function multiplyMoneyV2(
  money: StorefrontMoneyV2 = EmptyMoneyV2,
  multiplyBy: number = 1
): StorefrontMoneyV2 {
  if (multiplyBy === 1) {
    return money;
  }
  const totalAmount = parseFloat(money.amount) * multiplyBy;
  return {
    currencyCode: money.currencyCode,
    amount: totalAmount.toFixed(precision(money)),
  };
}

export function moneyV2Range(
  moneys: StorefrontMoneyV2[]
): StorefrontProductPriceRange {
  if (moneys.length === 0) {
    throw new Error("Cannot create price range from a set of 0 prices.");
  }
  ensureSameCurrency(moneys);
  const range: StorefrontProductPriceRange = {
    minVariantPrice: moneys[0],
    maxVariantPrice: moneys[0],
  };
  for (let money of moneys) {
    if (lessThan(money, range.minVariantPrice)) {
      range.minVariantPrice = money;
    }
    if (greaterThan(money, range.maxVariantPrice)) {
      range.maxVariantPrice = money;
    }
  }
  return range;
}

function precision(money: StorefrontMoneyV2): number {
  return (money.amount.toString().split(".")[1] || "").length;
}
