import clsx from "clsx";
import { useAuthContext } from "@tbl/aws-auth";
import { gql } from "@apollo/client";
import { MouseEvent, useState } from "react";
import { Button, SimpleButton } from "@mapmaker/ui";
import { FaClock } from "@react-icons/all-files/fa/FaClock";
import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FaMapPin } from "@react-icons/all-files/fa/FaMapPin";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { FaStar } from "@react-icons/all-files/fa/FaStar";
import { FaPencilAlt } from "@react-icons/all-files/fa/FaPencilAlt";
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";
import { HiOutlineArrowLeft } from "@react-icons/all-files/hi/HiOutlineArrowLeft";
import { MdCallMerge } from "@react-icons/all-files/md/MdCallMerge";
import { useProjectDispatch } from "../projectStore";
import {
  OpeningSuggestionFragment,
  OpeningSuggestionStatus,
  useDeleteOpeningSuggestionMutation,
} from "../../../client/MapmakerApi";
import TimeAgo from "../../shared/TimeAgo";
import { MapmakerConfirmModal } from "../../shared/modals/MapmakerConfirmModal";
import { deleteOpeningSuggestion as deleteOpeningSuggestionFromProjectStore } from "../fileReducer";
import OpeningSuggestionThumbnail from "../features/opening/suggestions/OpeningSuggestionThumbnail";
import {
  AddSuggestionButton,
  MergeSuggestionButton,
  OverwriteSuggestionButton,
} from "../features/opening/suggestions/AcceptSuggestionButton";
import useSuggestionAcceptance from "../features/opening/suggestions/useSuggestionAcceptance";
import useOpening from "../features/opening/useOpening";
import { useMapmakerAppConfig } from "../../../client";
import { useProjectFile } from "../useProjectState";
import { useAddToastMessage } from "../shared/toasts/useProjectToastMessages";
import HideSuggestionButton from "../features/opening/suggestions/HideSuggestionButton";
import SubmitSuggestionButton from "../features/opening/suggestions/SubmitSuggestionButton";
import "./OpeningSuggestionListItem.css";

type OpeningSuggestionListItemProps = {
  suggestion: OpeningSuggestionFragment;
  onClick?(suggestion: OpeningSuggestionFragment): any;
  mode?: "list" | "standalone";
};

export default function OpeningSuggestionListItem({
  suggestion,
  onClick = () => {},
  mode = "list",
}: OpeningSuggestionListItemProps) {
  const { identityId } = useAuthContext();
  const file = useProjectFile();
  const toast = useAddToastMessage();
  const { gotoEditFile } = useMapmakerAppConfig();
  const isSuggester = suggestion.suggesterId === identityId;
  const isFileOwner = suggestion.fileOwnerId === identityId;
  const { openingFeature } = useOpening(suggestion.openingId);
  const { inclusionStatus, strategiesAvailable } = useSuggestionAcceptance(
    suggestion
  );

  function OwnerActions() {
    if (inclusionStatus === "all-in-map-exact") {
      return (
        <HideSuggestionButton suggestion={suggestion} outlined size="small" />
      );
    } else if (suggestion.status === OpeningSuggestionStatus.Hidden) {
      return (
        <HideSuggestionButton suggestion={suggestion} outlined size="small" />
      );
    } else if (isFileOwner) {
      return (
        <>
          {strategiesAvailable.add && (
            <AddSuggestionButton
              suggestion={suggestion}
              outlined
              size="small"
              alignContent="left"
              onClick={e => e.stopPropagation()}
              onAccept={() =>
                toast.addToastInfo(
                  {
                    title: "Suggestion Accepted",
                    content: (
                      <>
                        The photos suggested by{" "}
                        <strong>{suggestion.name}</strong> have been added to{" "}
                        <strong>{openingFeature.name}</strong>
                      </>
                    ),
                    icon: FaPlus,
                  },
                  undefined,
                  6
                )
              }
            />
          )}
          {strategiesAvailable.overwrite && (
            <OverwriteSuggestionButton
              suggestion={suggestion}
              outlined
              size="small"
              alignContent="left"
              onClick={e => e.stopPropagation()}
              onAccept={() =>
                toast.addToastInfo(
                  {
                    title: "Photos Replaced",
                    content: (
                      <>
                        The photos in <strong>{openingFeature.name}</strong>{" "}
                        have been replaced with photos suggested by{" "}
                        <strong>{suggestion.name}</strong>.
                      </>
                    ),
                    icon: HiOutlineArrowLeft,
                  },
                  undefined,
                  6
                )
              }
            />
          )}
          {strategiesAvailable.merge && (
            <MergeSuggestionButton
              suggestion={suggestion}
              outlined
              size="small"
              alignContent="left"
              onClick={e => e.stopPropagation()}
              onAccept={() =>
                toast.addToastInfo(
                  {
                    title: "Photos Merged",
                    content: (
                      <>
                        <p>
                          The photos suggested by{" "}
                          <strong>{suggestion.name}</strong> have been combined
                          with the photos that were already in{" "}
                          <strong>{openingFeature.name}</strong>.
                        </p>
                        <p>
                          You will probably want to adjust how they look so they
                          don't overlap each other.
                        </p>
                        <Button
                          onClick={() => {
                            toast.clearAllToasts();
                            gotoEditFile(file.id, openingFeature.id);
                          }}
                        >
                          Edit {openingFeature.name}
                        </Button>
                      </>
                    ),
                    icon: MdCallMerge,
                  },
                  undefined,
                  6
                )
              }
            />
          )}
          <HideSuggestionButton
            suggestion={suggestion}
            outlined
            size="small"
            alignContent="left"
          />
        </>
      );
    }
    return null;
  }

  function SuggesterActions() {
    if (suggestion.status === OpeningSuggestionStatus.Draft) {
      return (
        <>
          <Button
            outlined
            size="small"
            alignContent="left"
            onClick={() => {
              gotoEditFile(
                file.id,
                "collaborate",
                openingFeature.id,
                suggestion.id
              );
            }}
          >
            <FaPencilAlt /> Edit
          </Button>
          <SubmitSuggestionButton
            outlined
            size="small"
            alignContent="left"
            suggestion={suggestion}
          />
        </>
      );
    } else if (suggestion.status === OpeningSuggestionStatus.Submitted) {
      switch (inclusionStatus) {
        case "all-in-map-exact":
        case "all-in-map-modified":
          return (
            <div className="suggester-status accepted">
              <FaCheckCircle /> ACCEPTED
            </div>
          );
        case "partial-in-map":
          return (
            <div className="suggester-status partially-accepted">
              <FaCheckCircle /> PARTIALLY ACCEPTED
            </div>
          );
        case "none-in-map":
          return (
            <div className="suggester-status">
              <FaCheckCircle /> SUBMITTED
            </div>
          );
      }
    }
    return null;
  }

  function onContainerClick(e: MouseEvent) {
    if (e.target instanceof Element && e.target.tagName === "BUTTON") {
      // A child button in this was clicked so don't fire the container callback.
      return;
    }
    if (
      !isFileOwner &&
      suggestion.status === OpeningSuggestionStatus.Submitted
    ) {
      toast.addToastInfo(
        "This suggestion has already been submitted.",
        "suggestion-list.already-submitted-note"
      );
      return;
    }
    onClick(suggestion);
  }

  const disabled =
    isSuggester && suggestion.status === OpeningSuggestionStatus.Submitted;

  return (
    <div
      id="opening-suggestion-list-item"
      className={clsx(suggestion.status, {
        disabled,
        hidden: suggestion.status === OpeningSuggestionStatus.Hidden,
        [`${mode}-mode`]: true,
      })}
      onClick={onContainerClick}
    >
      <div className="delete">
        {isSuggester && <DeleteButton suggestion={suggestion} />}
      </div>
      {mode === "list" && (
        <div className="thumbnail">
          <OpeningSuggestionThumbnail
            width={220}
            height={180}
            padding={2}
            openingSuggestion={suggestion}
          />
        </div>
      )}
      <div className="info">
        <div className="title">
          <span className="pre-title">Suggested by</span>
          <h3 className="title">{isSuggester ? "You" : suggestion.name}</h3>
        </div>
        <div className="details">
          <div className="opening">
            <FaMapPin />{" "}
            <SimpleButton
              onClick={e => {
                if (isFileOwner) {
                  e.stopPropagation();
                  gotoEditFile(file.id, "collaborate", suggestion.openingId);
                }
              }}
              style={{ display: "inline-block" }}
            >
              {openingFeature.name}
            </SimpleButton>
          </div>
          <div className="time">
            {suggestion.submittedAt ? (
              <div>
                <FaClock className="icon" /> Submitted{" "}
                <TimeAgo date={new Date(suggestion.submittedAt)} />
              </div>
            ) : (
              <div>
                <FaClock className="icon" /> Modified{" "}
                <TimeAgo date={new Date(suggestion.input.modifiedAt)} />
              </div>
            )}
          </div>
          {suggestion.note && (
            <blockquote className="note">{suggestion.note}</blockquote>
          )}
        </div>
        {inclusionStatus === "all-in-map-exact" && (
          <div className="already-in-map">
            <FaStar
              color="var(--mm-accent-color)"
              style={{ marginBottom: "-1px" }}
            />{" "}
            Currently in Project
          </div>
        )}
        {inclusionStatus === "partial-in-map" && (
          <div className="already-in-map">
            <FaStar style={{ marginBottom: "-1px" }} /> Some Photos In Project
          </div>
        )}
        <div className="actions">
          {isFileOwner ? <OwnerActions /> : <SuggesterActions />}
        </div>
      </div>
    </div>
  );
}

gql`
  mutation deleteOpeningSuggestion($input: DeleteOpeningSuggestionInput!) {
    deleteOpeningSuggestion(input: $input) {
      success
    }
  }
`;

type DeleteButtonProps = {
  suggestion: OpeningSuggestionFragment;
};

function DeleteButton({ suggestion }: DeleteButtonProps) {
  const [
    deleteOpeningSuggestion,
    { loading },
  ] = useDeleteOpeningSuggestionMutation();
  const projectDispatch = useProjectDispatch();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const isDraft = suggestion.status === OpeningSuggestionStatus.Draft;

  function onClick(e: MouseEvent) {
    e.stopPropagation();
    setConfirmModalOpen(true);
  }

  async function confirmClick() {
    // Optimistic...
    await deleteOpeningSuggestion({
      variables: {
        input: {
          fileId: suggestion.fileId,
          id: suggestion.id,
          openingId: suggestion.openingId,
        },
      },
    });
    projectDispatch(deleteOpeningSuggestionFromProjectStore(suggestion.id));
  }

  // If we don't catch the onClick event, it will bubble up to this suggestion and cause the
  // onClick event to fire while the modal is still open.
  return (
    <>
      <MapmakerConfirmModal
        open={confirmModalOpen}
        onConfirm={confirmClick}
        onClose={() => setConfirmModalOpen(false)}
        confirmLabel="Delete"
        confirmProps={{
          color: "delete",
        }}
        loading={loading}
      >
        <OpeningSuggestionThumbnail
          width={300}
          height={300}
          openingSuggestion={suggestion}
          strokeStyle={{
            strokeWidth: 1,
            stroke: "var(--mm-grey)",
          }}
        />
        {isDraft ? (
          <>
            <h2>Delete this suggestion draft?</h2>
            <p>This cannot be undone.</p>
          </>
        ) : (
          <>
            <h2>Delete this suggestion?</h2>
            <p>
              The project owner will no longer be able to view the suggestion.
              However if they have already included it in their project it will
              remain in the project.
            </p>
          </>
        )}
      </MapmakerConfirmModal>
      <button onClick={onClick}>
        <FaTrash />
      </button>
    </>
  );
}
