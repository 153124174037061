import { ReactNode, useState } from "react";
import { Modal, Tab, Image } from "semantic-ui-react";
import MapmakerModal from "../../../shared/modals/MapmakerModal";
import "./OpeningWhatWillPrintModal.css";

interface IWWPSectionProps {
  onNext?: () => any;
  onPrev?: () => any;
  image: string;
  children: ReactNode;
}

function WWPSection({
  onNext = null,
  onPrev = null,
  image,
  children,
}: IWWPSectionProps) {
  function renderNextButton() {
    if (onNext) {
      return (
        <a className="next" href="#" onClick={onNext}>
          next &gt;
        </a>
      );
    }
  }

  function renderPrevButton() {
    if (onPrev) {
      return (
        <a className="prev" href="#" onClick={onPrev}>
          &lt; previous
        </a>
      );
    }
  }

  // TODO - Link to images that actually work. Perhaps this whole thing should be some sort of
  // plugin that is different for each site?
  return (
    <div id="wwp-section">
      <Image src={`/images/whatwillprint/${image}`} />
      <div className="text">{children}</div>
      {renderPrevButton()}
      {renderNextButton()}
    </div>
  );
}

export interface IOpeningWhatWillPrintModalProps {
  open: boolean;
  onClose: () => any;
}

export default function OpeningWhatWillPrintModal({
  open,
  onClose,
}: IOpeningWhatWillPrintModalProps) {
  const [tabIndex, setTabIndex] = useState(0);

  const nextTab = () => setTabIndex(tabIndex + 1);
  const prevTab = () => setTabIndex(tabIndex - 1);
  const handleTabChange = (e, event) => setTabIndex(event.activeIndex);

  return (
    <MapmakerModal
      open={open}
      dimmer="inverted"
      onClose={onClose}
      title="What will print?"
      className="opening-what-will-print-modal"
    >
      <Modal.Content>
        <Modal.Description>
          <Tab
            activeIndex={tabIndex}
            menu={{ secondary: true, pointing: true }}
            onTabChange={handleTabChange}
            panes={[
              {
                menuItem: "Edit",
                render: () => (
                  <WWPSection image="mapmaker.png" onNext={nextTab}>
                    When aligning your photo in the Map Maker be sure it covers
                    the entire opening. The area shown in grayscale will also be
                    printed, but once placed in your map it will be covered up
                    by the mat board.
                  </WWPSection>
                ),
              },
              {
                menuItem: "Print",
                render: () => (
                  <WWPSection
                    image="print.png"
                    onPrev={prevTab}
                    onNext={nextTab}
                  >
                    <p>
                      This is what will actually be printed on to physical
                      paper. Note that the area shown in grayscale while editing
                      is printed in full color. That gives you a little room for
                      error when you cut the photo and place it in your map.
                    </p>
                  </WWPSection>
                ),
              },
              {
                menuItem: "On Map",
                render: () => (
                  <WWPSection
                    image="closeup-background.png"
                    onPrev={prevTab}
                    onNext={nextTab}
                  >
                    <p>
                      After cutting the photo out it should line up nicely with
                      the background of your map. Use tape, glue, or sticky tack
                      to attach it.
                    </p>
                    <p>
                      <strong>Note:</strong> If your map does not have a printed
                      background then we reccomend using tape to tape it to the
                      back on the cut out piece of mat board.
                    </p>
                  </WWPSection>
                ),
              },
              {
                menuItem: "Result",
                render: () => (
                  <WWPSection image="closeup-finished.png" onPrev={prevTab}>
                    <p>
                      The final product! Notice how the areas shown in grayscale
                      while editing are hidden behind the white mat board.
                    </p>
                  </WWPSection>
                ),
              },
            ]}
          />
        </Modal.Description>
      </Modal.Content>
    </MapmakerModal>
  );
}
