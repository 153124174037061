import React from "react";
import BorderGuide from "../printing/BorderGuide";
import {
  OpeningFeature,
  OpeningInput,
  MapmakerBusinessId,
} from "@mapmaker/core";
import OpeningGroup from "./OpeningGroup";
import { getLayerByPriority, LayerPriority } from "./openingUtils";
import SvgNode from "../SvgNode";
import OpeningImages from "./OpeningImages";
import { MapmakerSvgContextProvider } from "../MapmakerSvgContext";
import { OpeningsPrintSvgProps } from "@mapmaker/renderable";

export default function OpeningsPrintSvg({
  file,
  printOptions,
  printLayout,
  svgWidth = printLayout.width * (300 / 72),
  svgHeight = printLayout.height * (300 / 72),
}: OpeningsPrintSvgProps<"resource">) {
  const {
    backgroundColor,
    borderColor = "#000000",
    borderWidth = 1,
    includeBuffer = true,
    printingGuides = false,
  } = printOptions;

  function renderBackground() {
    if (backgroundColor !== null) {
      return (
        <rect x={0} y={0} width="100%" height="100%" fill={backgroundColor} />
      );
    } else {
      return null;
    }
  }

  function renderPrintingGuides() {
    if (printingGuides) {
      return (
        <BorderGuide
          x={0}
          y={0}
          width={printLayout.width}
          height={printLayout.height}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={`0 0 ${printLayout.width} ${printLayout.height}`}
      width={svgWidth}
      height={svgHeight}
    >
      <MapmakerSvgContextProvider
        value={{
          svgScale: svgWidth / printLayout.width,
          businessId: file.design.businessId as MapmakerBusinessId,
        }}
      >
        {renderBackground()}
        {printLayout.printOpenings.map((printOpening, i) => {
          const opening = file.design.features[
            printOpening.openingId
          ] as OpeningFeature;
          const openingInput = file.inputs[
            printOpening.openingId
          ] as OpeningInput;
          const alignmentLayerPriority: LayerPriority = includeBuffer
            ? opening.outlined
              ? ["cut"] // TBL Outlined opening
              : ["print", "outer", "cut"] // TBL normal
            : ["cut", "inner"]; // TBL no buffer, or any PM

          const outermostLayer = getLayerByPriority(
            opening,
            alignmentLayerPriority
          );
          const imageClipLayer = opening.outlined
            ? getLayerByPriority(opening, ["print"])
            : getLayerByPriority(opening, alignmentLayerPriority);

          return (
            <OpeningGroup
              key={opening.id}
              opening={opening}
              x={printOpening.x}
              y={printOpening.y}
              rotation={printOpening.rotation}
              scale={printOpening.scale}
              layer={alignmentLayerPriority}
            >
              {/* User-defined background for opening. */}
              {openingInput.background ? (
                <SvgNode
                  nodeData={imageClipLayer}
                  style={{ fill: openingInput.background }}
                />
              ) : null}
              <OpeningImages
                opening={opening}
                openingInput={openingInput}
                clipLayer={imageClipLayer}
              />
              <SvgNode
                nodeData={outermostLayer}
                style={{
                  fill: "none",
                  stroke: borderColor,
                  strokeWidth: borderWidth,
                }}
              />
            </OpeningGroup>
          );
        })}
        {renderPrintingGuides()}
      </MapmakerSvgContextProvider>
    </svg>
  );
}
