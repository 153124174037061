import { SimpleMessageProps } from "@mapmaker/renderable";
import React from "react";

export default function SimpleMessage({
  width,
  height,
  message,
}: SimpleMessageProps<"resource">) {
  return (
    <div
      style={{
        width: width + "px",
        height: height + "px",
        background: "#E8E8E8",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "3px solid",
        color: "#666666",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          fontSize: height / 12 + "px",
          padding: "2%",
        }}
      >
        {message}
      </div>
    </div>
  );
}
