import React from "react";
import { getOpeningsWithInputs, MapmakerBusinessId } from "@mapmaker/core";
import { MapmakerSvgContextProvider } from "../MapmakerSvgContext";
import SvgNode from "../SvgNode";
import { getLayerByPriority } from "./openingUtils";
import OpeningImages from "./OpeningImages";
import { AllOpeningsSvgProps } from "@mapmaker/renderable";

/*
 * Renders an SVG showing the photos in every opening of the blueprint,
 * and optionally borders around those openings.
 */
export default function AllOpeningsSvg({
  file,
  width,
  height,
  backgroundImage,
  backgroundColor,
  layer = ["cut", "inner"],
}: AllOpeningsSvgProps<"resource">) {
  const viewBox = [0, 0, file.design.width, file.design.height];

  // Only render the background if we don't have it set to transparent.
  function Background() {
    if (backgroundImage) {
      return (
        <image
          x={viewBox[0]}
          y={viewBox[1]}
          width="100%"
          height="100%"
          xlinkHref={backgroundImage}
        />
      );
    } else if (backgroundColor) {
      return (
        <rect
          x={viewBox[0]}
          y={viewBox[1]}
          width="100%"
          height="100%"
          fill={backgroundColor}
        />
      );
    } else {
      return null;
    }
  }

  const openings = getOpeningsWithInputs(file.design, file);

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox.join(" ")}
      width={width ?? file.design.width}
      height={height ?? file.design.height}
    >
      <MapmakerSvgContextProvider
        value={{
          svgScale: (width || file.design.width) / file.design.width,
          businessId: file.design.businessId as MapmakerBusinessId,
        }}
      >
        <Background />
        {openings.map(({ feature: opening, input: openingInput }) => {
          const clipLayer = getLayerByPriority(opening, layer);

          if (!clipLayer) {
            return null;
          }

          return (
            <React.Fragment key={opening.id}>
              {/* User-defined background for opening. */}
              {openingInput?.background ? (
                <SvgNode
                  nodeData={clipLayer}
                  style={{ fill: openingInput?.background }}
                />
              ) : null}
              <OpeningImages
                opening={opening}
                openingInput={openingInput}
                clipLayer={clipLayer}
              />
            </React.Fragment>
          );
        })}
      </MapmakerSvgContextProvider>
    </svg>
  );
}
