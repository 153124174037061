import React, { Ref, useEffect, useMemo, useState } from "react";
import { OpeningImageInput } from "@mapmaker/core";
import { getResizedImageUrl } from "./imageUtils";
import useLoadedUrl from "./useLoadedUrl";
import { useMapmakerSvgContext } from "../MapmakerSvgContext";
import { useOpeningGroupContext } from "./OpeningGroupContext";

export interface IOpeningImageProps {
  image: OpeningImageInput;
  imageRef?: Ref<SVGImageElement>;
  dataUrl?: string;
  doNotLoadRemote?: boolean;
  clipPath?: string;
  borderColor?: string;
  borderWidth?: number;
  svgScale?: number;
  isOccluded?: boolean;
}

export default function OpeningImage({
  image,
  imageRef,
  dataUrl = null,
  clipPath = null,
  borderColor = "#000000",
  borderWidth = 0,
  doNotLoadRemote = false,
  isOccluded = false,
}: IOpeningImageProps) {
  // Eventually we should be able to remove this once we've migrated to using the context
  // everywhere.
  const { svgScale, onImageLoadError } = useMapmakerSvgContext();
  const { scale: groupScale = 1 } = useOpeningGroupContext();

  const cssTransform = OpeningImageCSSTransform(image);
  const { width, height } = image;
  // The actual amount of pixels the image will take up on the client's screen. If it is occluded we
  // load a very low-res version.
  const clientWidth = Math.round(
    width * svgScale * groupScale * (isOccluded ? 1 / 8 : 1)
  );
  const clientHeight = Math.round(
    height * svgScale * groupScale * (isOccluded ? 1 / 8 : 1)
  );
  const [shouldHaveLoadedByNow, setShouldHaveLoadedByNow] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (!setTimeout) {
      return;
    }
    // @ts-ignore
    const timeoutId = setTimeout(() => {
      setShouldHaveLoadedByNow(true);
    }, 1500);
    return () => {
      // @ts-ignore
      clearTimeout(timeoutId);
    };
  }, []);

  function drawBorder() {
    if (borderWidth > 0) {
      return (
        <rect
          width={width}
          height={height}
          transform={cssTransform}
          x={-width / 2}
          y={-height / 2}
          style={{
            stroke: borderColor,
            strokeWidth: borderWidth + "px",
          }}
        />
      );
    } else {
      return null;
    }
  }

  function drawLoading() {
    if (!loadedUrl && !dataUrl && shouldHaveLoadedByNow) {
      const w = image.width;
      const h = image.height;
      const x = image.x + w / 2;
      const y = image.y + h / 2;
      const r = image.rotation * (Math.PI / 180);
      return (
        <>
          <linearGradient
            id="loadingGradient"
            gradientTransform="rotate(45)"
            x1="-50%"
            x2="50%"
            y1="0%"
            y2="0%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" stopColor="#F0F0F0" />
            <stop offset="47%" stopColor="#F0F0F0" />
            <stop offset="50%" stopColor="#FFFFFF" />
            <stop offset="53%" stopColor="#F0F0F0" />
            <stop offset="100%" stopColor="#F0F0F0" />
            <animate
              attributeName="x1"
              dur="1200ms"
              from="-50%"
              to="150%"
              repeatCount="indefinite"
            />
            <animate
              attributeName="x2"
              dur="1200ms"
              from="50%"
              to="250%"
              repeatCount="indefinite"
            />
          </linearGradient>
          {/* In order to preserve the global gradient we have to draw the path directly instead of 
              using the cssTransform. */}
          <path
            d={`
                M 
                  ${x + (w / 2) * Math.cos(r) + (h / 2) * Math.sin(r)}
                  ${y + (w / 2) * Math.sin(r) - (h / 2) * Math.cos(r)}
                L 
                  ${x + (w / 2) * Math.cos(r) - (h / 2) * Math.sin(r)}
                  ${y + (w / 2) * Math.sin(r) + (h / 2) * Math.cos(r)}
                L 
                  ${x - (w / 2) * Math.cos(r) - (h / 2) * Math.sin(r)}
                  ${y - (w / 2) * Math.sin(r) + (h / 2) * Math.cos(r)}
                L 
                  ${x - (w / 2) * Math.cos(r) + (h / 2) * Math.sin(r)}
                  ${y - (w / 2) * Math.sin(r) - (h / 2) * Math.cos(r)}
                Z
            `}
            width={width}
            height={height}
            x={image.x}
            y={image.y}
            fill="url(#loadingGradient)"
            stroke="#FFFFFF"
            strokeWidth={3 / svgScale}
          />
        </>
      );
    } else {
      return null;
    }
  }

  const currentUrl = doNotLoadRemote
    ? undefined
    : getResizedImageUrl(image.id, clientWidth, clientHeight);

  const loadedUrl = useLoadedUrl(currentUrl, {
    onError: () => {
      if (onImageLoadError) {
        onImageLoadError(currentUrl, !!dataUrl);
      }
    },
    retries: 5,
  });

  return (
    <g clipPath={clipPath}>
      {drawLoading()}
      {drawBorder()}
      <image
        ref={imageRef}
        style={{
          userSelect: "none",
        }}
        xlinkHref={dataUrl || loadedUrl}
        onDragStart={(e) => e.preventDefault()}
        preserveAspectRatio="none"
        // These need to be up-to-date whenever the position changes. For performance we don't
        // always flush the props back through immediately when editing.
        width={width}
        height={height}
        x={-width / 2}
        y={-height / 2}
        transform={cssTransform}
      />
    </g>
  );
}

export function OpeningImageCSSTransform({
  x,
  y,
  width,
  height,
  rotation,
  flipX,
  flipY,
}: Pick<
  OpeningImageInput,
  "x" | "y" | "height" | "width" | "rotation" | "flipX" | "flipY"
>): string {
  return `    
      translate(${x + width / 2}, ${y + height / 2})
      rotate(${rotation}) 
      scale(${flipX ? -1 : 1}, ${flipY ? -1 : 1})
    `;
}
