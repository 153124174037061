import { createStore, applyMiddleware, Store, AnyAction } from "redux";
import {
  createDispatchHook,
  createSelectorHook,
  ReactReduxContextValue,
} from "react-redux";
import { createContext } from "react";
import thunk from "redux-thunk";
import { OpeningInput } from "@mapmaker/core";
import createEditableOpeningReducer from "./editableOpeningReducer";

export type EditableOpeningState = {
  openingId: string;
  openingInputState: OpeningInput;
  activeImageId: string;
  highlightedImageId: string;
  zoomLevel: number;
};

export function createEditableOpeningStore(
  openingId: string,
  initialOpeningInput: OpeningInput
): Store<EditableOpeningState> {
  return createStore(
    createEditableOpeningReducer(openingId, initialOpeningInput),
    applyMiddleware(thunk)
  );
}

export const EditableOpeningContext = createContext<
  ReactReduxContextValue<EditableOpeningState, AnyAction | any>
>({ store: null, subscription: null });

export const useEditableOpeningDispatch = createDispatchHook(
  EditableOpeningContext
);
export const useEditableOpeningSelector = createSelectorHook(
  EditableOpeningContext
);

export function useEditableOpeningState(): EditableOpeningState {
  return useEditableOpeningSelector(state => state) as EditableOpeningState;
}
