import { ReactNode } from "react";
import "./Centered.scss";

export interface ICenteredProps {
  children: ReactNode;
  height?: string;
}

export default function Centered({ children, height }: ICenteredProps) {
  return (
    <div id="centered-block" style={{ height }}>
      <span className="content">{children}</span>
    </div>
  );
}
