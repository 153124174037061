import { ReactNode } from "react";
import { Header } from "semantic-ui-react";
import { IGroupHeaderProps } from "./RectangularPrintList";
import { useSelector } from "react-redux";
import { IBasePrintState } from "./ReduxPrintStates";
import { prettyPrintDimensions } from "../../../lib/prints/printSizes";
import "./RectangularPrintListGroupHeader.css";

interface IRectangularPrintListGroupHeaderProps extends IGroupHeaderProps {
  printPrice?: number;
}

export default function RectangularPrintListGroupHeader({
  paperSize,
  printLayouts,
  printPrice = null,
}: IRectangularPrintListGroupHeaderProps) {
  const design = useSelector((state: IBasePrintState) => state.design);

  const totalOpenings = printLayouts.reduce(
    (acc, printLayout) => acc + printLayout.printOpenings.length,
    0
  );
  const openingCountText =
    totalOpenings == 1
      ? `1 ${design.regionTypePlural}`
      : `${totalOpenings} ${design.regionTypePlural}`;
  const printCountText =
    printLayouts.length == 1 ? "1 print" : `${printLayouts.length} prints`;

  return (
    <div className="rectangular-print-list-group-header">
      <div className="divider" />
      <div className="banner">
        <div className="header">{paperSize.name}</div>
        <div className="subheader">
          {printPrice
            ? `${printCountText} - $${printPrice.toFixed(2)}/print`
            : `${openingCountText} on ${printCountText}`}
        </div>
      </div>
    </div>
  );
}
