import DownloadPrintService from "./other/DownloadPrintService";
import WalgreensPrintService from "./walgreens/WalgreensPrintService";
import StickersPrintService from "./stickers/StickersPrintService";
import { PrintSizeMode } from "../../lib/prints/printSizes";
import { MapmakerPrintServiceId } from "./PrintServiceIds";
import { ReactNode } from "react";
import { ProjectOutputType, MapmakerDesign } from "../../client/MapmakerApi";
import { getFeaturesList } from "@mapmaker/core";

export interface IPrintService {
  id: MapmakerPrintServiceId;
  name: string;
  image: string;
  imageSmall: string;
  directive: string;
  description: string;
  detailedDescription: ReactNode;
  bannerText?: string;
  printSizeModes: PrintSizeMode[];
}

const PRINTING_SERVICE_MAP = {
  [MapmakerPrintServiceId.DOWNLOAD]: DownloadPrintService,
  [MapmakerPrintServiceId.WALGREENS]: WalgreensPrintService,
  [MapmakerPrintServiceId.STICKERS]: StickersPrintService,
};

export function getPrintingService(
  serviceId: MapmakerPrintServiceId
): IPrintService {
  if (serviceId in PRINTING_SERVICE_MAP) {
    return PRINTING_SERVICE_MAP[serviceId];
  } else {
    return null;
  }
}

/**
 * Used to filter out some print services on the fly if we're not ready for them.
 */
export function canPrint(
  printServiceId: MapmakerPrintServiceId,
  design: Pick<MapmakerDesign, "businessId" | "features">
) {
  if (printServiceId === MapmakerPrintServiceId.STICKERS) {
    if (design.businessId === "tbl") {
      if (getFeaturesList(design, "OPENING").length <= 1) {
        // Single opening designs for TBL do not have any graphics on the background to place the
        // stickers.
        return false;
      }
    }
  }
  return true;
}

const OutputTypePrintServices: Record<
  ProjectOutputType,
  MapmakerPrintServiceId[]
> = {
  MATBOARD_WALL_MAP: [
    MapmakerPrintServiceId.STICKERS,
    MapmakerPrintServiceId.WALGREENS,
    MapmakerPrintServiceId.DOWNLOAD,
  ],
  MATBOARD_WALL_MAP_SINGLE: [
    MapmakerPrintServiceId.WALGREENS,
    MapmakerPrintServiceId.DOWNLOAD,
  ],
  VEHICLE_MAP: [MapmakerPrintServiceId.STICKERS],
  VINYL_WALL_MAP: [
    MapmakerPrintServiceId.STICKERS,
    MapmakerPrintServiceId.DOWNLOAD,
  ],
};

export function getPrintServiceIdsForOutputType(
  outputType: ProjectOutputType
): MapmakerPrintServiceId[] {
  return OutputTypePrintServices[outputType];
}

export function getPrintServicesForOutputType(
  outputType: ProjectOutputType
): IPrintService[] {
  return getPrintServiceIdsForOutputType(outputType).map(id =>
    getPrintingService(id)
  );
}
