import { AnyAction } from "redux";
import { MapmakerFileHeavyFragment } from "../../client/MapmakerApi";

export const FILE_LOADED_FROM_SERVER = "serverFile.file_loaded_from_server";

export function fileLoadedFromServer(file: MapmakerFileHeavyFragment) {
  return {
    type: FILE_LOADED_FROM_SERVER,
    file,
  };
}

// TODO - This should be updatable when we reload from the server.
export default function serverFileReducer(
  state: MapmakerFileHeavyFragment,
  action: AnyAction
): MapmakerFileHeavyFragment {
  if (action.type === FILE_LOADED_FROM_SERVER) {
    return action.file;
  }
  return state;
}
