import * as yup from "yup";
import { RenderableForm } from ".";

export const RegionCompletionSvgForm: RenderableForm = {
  initialValues: {},
  inputs: [
    {
      type: "file",
      id: "file",
      name: "Project",
      validation: yup.string().required(),
    },
    {
      type: "file-opening",
      id: "openingId",
      name: "Region",
      validation: yup.string().required(),
      fileProperty: "file",
      multiple: false,
    },
    {
      type: "select",
      id: "background",
      name: "Background",
      validation: yup.string().required(),
      options: [
        {
          label: "Contextual",
          value: "contextual",
        },
        {
          label: "Chalkboard",
          value: "chalkboard",
        },
        {
          label: "Wood",
          value: "wood",
        },
        {
          label: "Corkboard",
          value: "corkboard",
        },
        {
          label: "None",
          value: "none",
        },
      ],
    },
  ],
};
