import * as React from "react";
import { useAuthBoxContext } from "./AuthBox";
import "./AuthBoxLayout.css";

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type ChildrenProps = DivProps & {
  children?: React.ReactNode;
};

export default function AuthBoxLayout({ children, ...props }: ChildrenProps) {
  return (
    <div id="auth-box" {...props}>
      {children}
    </div>
  );
}

type AuthBoxLayoutHeaderProps = ChildrenProps & {
  title: string;
  alternative?: React.ReactNode;
};

AuthBoxLayout.Header = function AuthBoxLayoutHeader({
  title,
  alternative = null,
  children,
  ...props
}: AuthBoxLayoutHeaderProps) {
  const { noHeader } = useAuthBoxContext();
  if (noHeader) {
    return null;
  }
  return (
    <div id="auth-box-header" {...props}>
      <div className="header-row">
        <h3>{title}</h3>
        <div className="alternative">{alternative}</div>
      </div>
      {children}
    </div>
  );
};

type AuthBoxMessage = {
  message: string;
};

type AuthBoxMessagesProps = DivProps & {
  messages: AuthBoxMessage[];
};

AuthBoxLayout.Messages = function AuthBoxLayoutMessages({
  messages,
  ...props
}: AuthBoxMessagesProps) {
  <div id="auth-box-messages" {...props}>
    {messages.map((message, i) => {
      return (
        <div key={i} className="auth-box-message">
          {message.message}
        </div>
      );
    })}
  </div>;
};

AuthBoxLayout.Form = function AuthBoxLayoutForm({
  children,
  ...props
}: ChildrenProps) {
  return (
    <div id="auth-box-form" {...props}>
      {children}
    </div>
  );
};
