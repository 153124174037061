import { useCallback, useEffect, useState } from "react";
import useLocalStorage from "react-use/lib/useLocalStorage";

export default function useLiveLocalStorage<T>(
  id: string,
  defaultValue?: T
): [T | undefined, (newValue: T) => any, () => void] {
  const key: string = `${id}`;
  const [storedValue, setStoredValue, remove] = useLocalStorage<T>(
    key,
    defaultValue
  );
  const [returnValue, setReturnValue] = useState<T>(
    storedValue ?? defaultValue
  );

  // When the local value is updated, set the return value.
  useEffect(() => setReturnValue(storedValue ?? defaultValue), [
    storedValue,
    defaultValue,
  ]);

  useEffect(() => {
    const onStorageEvent = (e: StorageEvent) => {
      if (e.key === key) {
        setReturnValue(JSON.parse(window.localStorage.getItem(key) ?? ""));
      }
    };
    window.addEventListener("storage", onStorageEvent);
    return () => {
      window.removeEventListener("storage", onStorageEvent);
    };
  }, [key]);

  const setValue = useCallback(
    (newValue: T) => {
      setStoredValue(newValue);
      const event = new StorageEvent("storage", {
        key,
        newValue: JSON.stringify(newValue),
        oldValue: JSON.stringify(storedValue),
      });
      window.dispatchEvent(event);
    },
    [setStoredValue, key, storedValue]
  );

  return [returnValue, setValue, remove];
}
