import {
  StorefrontAttribute,
  StorefrontAttributeInput,
} from "../../client/MapmakerApi";

export function getAttributeByKey(
  attributes: StorefrontAttribute[],
  key: string
): StorefrontAttribute | undefined {
  return attributes.find(attribute => attribute.key === key);
}

export function getAttributeValueByKey(
  attributes: StorefrontAttribute[],
  key: string,
  defaultValue: string = ""
): string {
  return getAttributeByKey(attributes, key)?.value ?? defaultValue;
}

export function attributesToMap(
  attributes: StorefrontAttribute[]
): Record<string, string> {
  return Object.fromEntries(
    attributes.map(attribute => [attribute.key, attribute.value])
  );
}

export function attributeToAttributeInput(
  attribute: StorefrontAttribute
): StorefrontAttributeInput {
  return {
    key: attribute.key,
    value: attribute.value ?? "",
  };
}

/**
 * Updates the value of an attribute in a list, adding it if necessary or removing it if the new
 * value is empty.
 */
export function updateAttributesWithValue(
  attributes: StorefrontAttribute[],
  key: string,
  newValue: string | undefined | null
): StorefrontAttributeInput[] {
  const exists = !!getAttributeByKey(attributes, key);
  const attributeInputs = attributes.map(attributeToAttributeInput);
  if (exists) {
    if (newValue) {
      return attributeInputs.map(attribute => {
        if (attribute.key === key) {
          return {
            key,
            value: newValue,
          };
        } else {
          return attribute;
        }
      });
    } else {
      // Remove it
      return attributeInputs.filter(
        (attribute: { key: string }) => attribute.key !== key
      );
    }
  } else if (newValue) {
    return [...attributeInputs, { key, value: newValue }];
  } else {
    return attributeInputs;
  }
}
