import { getPrintUrl } from "@mapmaker/config";
import React from "react";

type MatImageFillFilterProps = {
  id: string;
  url: string;
  fill?: string;
};

export function MatImageFillFilter({
  id,
  url,
  fill = null,
}: MatImageFillFilterProps) {
  return (
    <pattern
      id={id}
      x="0"
      y="0"
      width="1"
      height="1"
      patternContentUnits="objectBoundingBox"
    >
      {fill ? <rect x="0" y="0" width="1" height="1" fill={fill} /> : null}
      <image
        href={url}
        x="0"
        y="0"
        width="1"
        height="1"
        preserveAspectRatio="none"
      />
    </pattern>
  );
}

type MatGenericFillFilterProps = {
  id: string;
  layer: string;
  size?: string;
};

export function MatGenericImageFillFilter({
  id,
  layer,
  size = null,
}: MatGenericFillFilterProps) {
  const filterId = `${id}-${layer}`;
  const url = getPrintUrl(id, layer, size);
  return <MatImageFillFilter id={filterId} url={url} />;
}
