import { Feature, FeatureInput, FeatureType } from "@mapmaker/core";
import React, { FunctionComponent } from "react";
import Graphic from "./graphics/Graphic";
import Opening from "./opening/Opening";

export interface IFeatureNodeProps<
  F extends Feature = Feature,
  I extends FeatureInput = FeatureInput
> {
  feature: F;
  input?: I;
  svgScale?: number;
}

export type FeatureComponent<
  F extends Feature = any,
  I extends FeatureInput = any
> = FunctionComponent<IFeatureNodeProps<F, I>>;

type FeatureTypeToNodeMap = {
  [key in FeatureType]: FeatureComponent;
};

const FeatureTypeMap: FeatureTypeToNodeMap = {
  GRAPHIC: Graphic,
  OPENING: Opening,
};

export default function FeatureNode({
  feature,
  input,
  svgScale,
}: IFeatureNodeProps) {
  const FeatureComponent = FeatureTypeMap[feature.type];
  return (
    <FeatureComponent
      feature={feature}
      input={input}
      svgScale={svgScale || 1}
    />
  );
}
