import type { MapmakerConfig } from ".";

const config: MapmakerConfig = {
  stage: "prod",
  dataStage: "prod",
  apiGateway: {
    url: "https://api.photomaps.com",
  },
  contentUrl: "https://content.photomaps.com",
  userContentUrl: "https://user-content-direct-prod.photomaps.com",
  userContentImageServerUrl: "https://user-content-prod.photomaps.com",
  staticUrl: "https://static-prod.photomaps.com",
  imageServerBucket: "mapmaker-user-content-prod",
  internalBucket: "mapmaker-internal-prod",
  walgreens: {
    production: true,
    apiKey: "DyvrlukqmWDQQW3XKGmqVKtHl6YFHejX",
    affiliateId: "THUNDRBUNY",
    publisherId: "8972131",
  },
};
export default config;
