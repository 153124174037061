import { FaUsers } from "@react-icons/all-files/fa/FaUsers";
import { MapmakerFileViewerPolicy } from "../../../client/MapmakerApi";
import useDelayed from "../../../lib/hooks/useDelayed";
import { useAddToastMessage } from "../shared/toasts/useProjectToastMessages";
import { useProjectFile } from "../useProjectState";
import { useMapmakerContext } from "../../../client/MapmakerContext";

export function useCollaboratorIntroMessage() {
  const toast = useAddToastMessage();
  const file = useProjectFile();

  useDelayed(() => {
    // No need to show if they aren't a collaborator.
    if (file.viewerPolicy !== MapmakerFileViewerPolicy.Collaborator) {
      return;
    }
    // No need to show if they've already created suggestions.
    if (file.openingSuggestions.length > 0) {
      return;
    }

    toast.addToastMessage({
      id: "collaborator-intro-message",
      type: "info",
      title: "Help fill this Photo Map!",
      icon: FaUsers,
      content: <CollaboratorIntroMessage />,
      duration: 25,
      persistance: "session",
      layer: "help",
    });
  }, 1500);
}

function CollaboratorIntroMessage() {
  const file = useProjectFile();
  const { isMobile } = useMapmakerContext();

  return (
    <div id="not-logged-in-message">
      <p>
        You can collaborate on this project. Help fill it in by adding your
        photos to any {file.design.regionType}.
      </p>
      <p>
        {isMobile ? "Tap on" : "Click"} any {file.design.regionType} in the map
        to get started by creating a "suggestion".
      </p>
    </div>
  );
}
