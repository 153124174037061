import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import Badge from "./Badge";
export default function NumberBadge(_a) {
    var { count = 0, hideIfZero = true, maxCount = 99 } = _a, props = __rest(_a, ["count", "hideIfZero", "maxCount"]);
    if (count === 0 && hideIfZero) {
        return null;
    }
    return _jsx(Badge, Object.assign({}, props, { children: count > maxCount ? `${maxCount}+` : count }));
}
