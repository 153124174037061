import { createContext, PropsWithChildren, useContext } from "react";
import { NavigationFragment } from "../../../client/MapmakerApi";

export type SanityUrlCreator = (slug?: string | null) => string;

export type SanityContextValue = {
  urlCreator: SanityUrlCreator;
  showHidden: boolean;
  navigation: NavigationFragment;
  selectedTargetSlug?: string;
};

const SanityContext = createContext<SanityContextValue>(
  {} as SanityContextValue
);

export function SanityContextProvider({
  children,
  ...value
}: PropsWithChildren<SanityContextValue>) {
  return (
    <SanityContext.Provider value={value}>{children}</SanityContext.Provider>
  );
}

export function useSanityContext(): SanityContextValue {
  return useContext<SanityContextValue>(SanityContext);
}
