import CMSPage from "./StorefrontCMSPage";
import { gql } from "@apollo/client";
import { useTermsOfServiceQuery } from "../../client/MapmakerApi";

gql`
  query termsOfService {
    storefrontShop {
      termsOfService {
        title
        body
      }
    }
  }
`;

export default function TermsOfServicePage() {
  const { data, loading } = useTermsOfServiceQuery();
  return (
    <CMSPage page={data?.storefrontShop.termsOfService} loading={loading} />
  );
}
