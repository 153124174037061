import { useMemo, useState } from "react";
import clsx from "clsx";
import SwipeableViews from "react-swipeable-views";
import { useProductPage } from "../ProductPageContext";
import useUpdateEffect from "react-use/lib/useUpdateEffect";
import "./ProductPageThumbnails.css";

export default function ProductPageThumbnails() {
  const productPageContext = useProductPage();
  const { product, selectedVariant, plugin } = productPageContext;
  const allVariantsHaveSameImage = product.variants.edges.every(
    ({ node: variant }) =>
      variant.image.id === product.variants.edges[0].node.image.id
  );

  const [hasChangedThumbnails, setHasChangedThumbnails] = useState(false);

  const [selectedThumbnail, setSelectedThumbnail] = useState(0);
  // Removes any thumbnails which are also variant thumbnails. Variant thumbnails will be shown when
  // the variants is clicked, so we don't need them clogging up the thumbnails.
  const listingImages = useMemo(() => {
    if (!product) {
      return [];
    }
    const withoutVariants = product.images.edges.filter(({ node: image }) => {
      if (allVariantsHaveSameImage) {
        return true;
      }
      return !product?.variants.edges.some(
        ({ node: variant }) => image.id === variant.image?.id
      );
    });
    const images = (withoutVariants.length > 0
      ? withoutVariants
      : product.images.edges
    ).map(edge => edge.node);

    if (selectedVariant?.image && hasChangedThumbnails) {
      return [selectedVariant.image, ...images];
    } else {
      return images;
    }
  }, [product, hasChangedThumbnails, selectedVariant]);

  // When a new variant is chosen, display that image.
  useUpdateEffect(() => {
    // We don't switch it if the variant's image is showing already
    if (selectedVariant?.image?.id !== product.images.edges[0].node.id) {
      setHasChangedThumbnails(true);
      setSelectedThumbnail(0);
    }
  }, [selectedVariant]);

  const allImages = [...plugin.images(productPageContext), ...listingImages];

  return (
    <div
      className={clsx({
        container: true,
      })}
    >
      <SwipeableViews
        className="mainImage"
        enableMouseEvents
        index={selectedThumbnail}
        onChangeIndex={setSelectedThumbnail}
      >
        {allImages.map((thumbnail, i) => {
          return (
            <img
              key={i + thumbnail.mediumSrc}
              className="productImage"
              src={thumbnail.mediumSrc}
              alt={thumbnail.altText ?? ""}
            />
          );
        })}
      </SwipeableViews>
      <div
        className={clsx({
          thumbnails: true,
        })}
      >
        {allImages.map((thumbnail, i) => {
          return (
            <img
              key={i + thumbnail.tinySrc}
              className={clsx({
                selected: selectedThumbnail === i,
              })}
              src={thumbnail.tinySrc}
              alt={thumbnail.altText ?? ""}
              onClick={() => setSelectedThumbnail(i)}
            />
          );
        })}
      </div>
    </div>
  );
}
