import { Button } from "@mapmaker/ui";
import { Form, Formik, useFormikContext } from "formik";
import { useSandboxContext } from "./SandboxContext";
import { useMemo } from "react";
import { RenderableType } from "@mapmaker/renderable";
import RenderableInputs from "./inputs";
import {
  getFormFromType,
  getRenderableFormTypeOptions,
  RenderableForm,
} from "./inputs/forms";
import { useMapmakerAppConfig } from "../..";
import "./SandboxPanel.css";

export default function SandboxPanel() {
  const { businessId } = useMapmakerAppConfig();
  const { renderableType, setProps, setRenderableType } = useSandboxContext();

  const form = useMemo(() => getFormFromType(renderableType), [renderableType]);

  function onSubmit(values) {
    setProps(values);
  }

  return (
    <div id="sandbox-panel">
      <section>
        <h3>Map Maker Sandbox</h3>
        <div className="content">
          <select
            className="render-type-select"
            defaultValue={renderableType}
            onChange={e =>
              setRenderableType(e.currentTarget.value as RenderableType)
            }
          >
            <option value="" disabled>
              Render Type
            </option>
            {getRenderableFormTypeOptions(businessId).map(
              ({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              )
            )}
          </select>
          {form && (
            <Formik
              key={renderableType}
              initialValues={{
                ...(form.initialValues ?? {}),
              }}
              onSubmit={onSubmit}
              validateOnMount={true}
            >
              <RenderablePropsForm form={form} />
            </Formik>
          )}
        </div>
      </section>
    </div>
  );
}

type RenderablePropsFormProps = {
  form: RenderableForm;
};

function RenderablePropsForm({ form }: RenderablePropsFormProps) {
  const { isValid } = useFormikContext();
  return (
    <Form id="renderable-props-form">
      <RenderableInputs inputs={form.inputs} />
      <Button color="accent" type="submit" disabled={!isValid}>
        Render
      </Button>
    </Form>
  );
}
