import { useAuthContext } from "@tbl/aws-auth";
import { useSelector, useDispatch } from "react-redux";
import ContextPanelCheckbox from "../../project/context/shared/ContextPanelCheckbox";
import { setDownloadFileFormat } from "../other/downloadOptionsReducer";
import { DownloadPrintsStore } from "../other/downloadPrintsStore";
import {
  setPrintingGuides,
  setDrawBorder,
  setIncludeBuffer,
  setBackgroundColor,
} from "./printOptionsReducer";
import { IRectangularPrintState } from "./ReduxPrintStates";

export default function OpeningPrintSettingsAdvanced() {
  const dispatch = useDispatch();
  const { user } = useAuthContext();
  const { design, format, printOptions, allowBufferArea } = useSelector(
    (state: DownloadPrintsStore) => ({
      design: state.design,
      format: state.downloadOptions?.format,
      printOptions: state.printOptions,
      allowBufferArea: state.allowBufferArea,
    })
  );

  function PrintingGuides() {
    return (
      <ContextPanelCheckbox
        key="printingGuides"
        label="Printing Guides"
        help={`Adds a rectangle around the edge along so that you can determine if the photo has been printed at the correct size.`}
        checked={printOptions.printingGuides}
        onChange={value => dispatch(setPrintingGuides(value))}
      />
    );
  }

  function DrawBorderSetting() {
    return (
      <ContextPanelCheckbox
        key="drawBorder"
        label="Draw border"
        help={`Draws a thin black border showing where to cut the image. This can help see where the border of the ${design.regionType} is if your image is white near the edges.`}
        checked={printOptions.borderWidth == 2}
        onChange={value => dispatch(setDrawBorder(value))}
      />
    );
  }

  function IncludeBufferSetting() {
    if (!allowBufferArea) {
      return null;
    }
    return (
      <ContextPanelCheckbox
        key="includeBuffer"
        label="Include buffer area"
        help={`The buffer area adds extra room on the border of the ${design.regionType}. Without the buffer the photo would have to be aligned perfectly in your map which can be tricky. We highly recommend including the buffer area when printing your photos.`}
        checked={printOptions.includeBuffer}
        disabled={!printOptions.clipImage && printOptions.borderWidth == 0}
        onChange={value => {
          dispatch(setIncludeBuffer(value));
        }}
      />
    );
  }

  function TransparentBgSetting() {
    if (!user?.isTblAccount) {
      return null;
    }
    return (
      <ContextPanelCheckbox
        key="transparentBg"
        label="Transparent"
        help={`Create the image with a transparent background.`}
        checked={format === "png"}
        onChange={transparent => {
          dispatch(setBackgroundColor(transparent ? null : "#FFFFFF"));
          dispatch(setDownloadFileFormat(transparent ? "png" : "jpeg"));
        }}
      />
    );
  }

  return (
    <>
      <p>
        We do not recommend changing any of these settings unless you know what
        you are doing.
      </p>
      <PrintingGuides />
      <DrawBorderSetting />
      <IncludeBufferSetting />
      <TransparentBgSetting />
    </>
  );
}
