import * as Yup from "yup";
import gql from "graphql-tag";
import { Icon } from "semantic-ui-react";
import clsx from "clsx";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { HTMLProps, ReactNode, useState } from "react";
import MapmakerButton from "../shared/buttons/MapmakerButton";
import MaskedInput from "react-text-mask";
import { useRedeemStickerTokenVoucherMutation } from "../../client/MapmakerApi";
import { useMapmakerAppConfig } from "../../client";
import "./StickerTokenRedemptionForm.css";

const RedemptionCodeSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}$/, "Invalid code.")
    .required("Enter your code in the input box."),
});

gql`
  mutation redeemStickerTokenVoucher(
    $input: RedeemStickerTokenVoucherRequest!
  ) {
    redeemStickerTokenVoucher(input: $input) {
      transaction {
        tokens
      }
      account {
        id
        ... on MapmakerAuthenticatedAccount {
          stickerTokenBalance
        }
      }
    }
  }
`;

interface IStickerTokenRedemptionFormProps
  extends Omit<HTMLProps<HTMLDivElement>, "id"> {
  header?: ReactNode;
  placeholder?: string;
  code?: string;
  inline?: boolean;
}

export default function StickerTokenRedemptionForm({
  header = null,
  placeholder = "Enter your code",
  code = "",
  inline = false,
  ...props
}: IStickerTokenRedemptionFormProps) {
  const [redeemCode] = useRedeemStickerTokenVoucherMutation();
  const [redemptionValue, setRedemptionValue] = useState<number>(null);
  const { gotoFindMap } = useMapmakerAppConfig();

  return (
    <div id="sticker-token-redemption-form" {...props}>
      <Formik
        initialValues={{
          code,
        }}
        isInitialValid={!!code}
        validationSchema={RedemptionCodeSchema}
        onSubmit={async (values, formik) => {
          // Submit the code
          try {
            setRedemptionValue(null);
            formik.setSubmitting(true);
            const result = await redeemCode({
              variables: {
                input: {
                  voucherId: values.code,
                },
              },
            });
            formik.resetForm();
            setRedemptionValue(
              result.data.redeemStickerTokenVoucher.transaction.tokens
            );
          } catch (e) {
            console.log("ERROR");
            console.log(e);
            console.log(JSON.stringify(e));
            console.log(
              e.message ===
                "You redeemed this code for 5 tokens on May 13th, 2021"
            );
            formik.setErrors({
              code: e.message,
            });
          } finally {
            formik.setSubmitting(false);
          }
        }}
      >
        {({
          isValid,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <div
              className={clsx("input-bar", {
                inline,
                success: redemptionValue !== null,
              })}
            >
              {redemptionValue !== null ? (
                /* Successful redemption message */
                <div className="input-container">
                  <div className="success">
                    <Icon name="check circle" />
                    {redemptionValue}{" "}
                    {redemptionValue === 1 ? "token" : "tokens"} added!
                  </div>
                  <div className="message another">
                    <span className="another-button" onClick={gotoFindMap}>
                      Make Some Stickers
                    </span>
                    {" | "}
                    <span
                      className="another-button"
                      onClick={() => {
                        setRedemptionValue(null),
                          setFieldValue("code", "", false);
                      }}
                    >
                      Have another code?
                    </span>
                  </div>
                </div>
              ) : (
                /* Input Field */
                <div className="input-container">
                  {header && <div className="header">{header}</div>}
                  <Field
                    type="string"
                    name="code"
                    render={({ field }) => (
                      <MaskedInput
                        {...field}
                        mask={[
                          /[A-Za-z1-9]/,
                          /[A-Za-z1-9]/,
                          /[A-Za-z1-9]/,
                          /[A-Za-z1-9]/,
                          "-",
                          /[A-Za-z1-9]/,
                          /[A-Za-z1-9]/,
                          /[A-Za-z1-9]/,
                          /[A-Za-z1-9]/,
                          "-",
                          /[A-Za-z1-9]/,
                          /[A-Za-z1-9]/,
                          /[A-Za-z1-9]/,
                          /[A-Za-z1-9]/,
                        ]}
                        id="code"
                        alwaysShowMask={true}
                        placeholder={placeholder}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={clsx("text-input", {
                          error: errors.code && touched.code,
                          touched: touched.code,
                        })}
                      />
                    )}
                  />
                  {errors.code && touched.code && (
                    <div className="message error">
                      <ErrorMessage name="code" />
                    </div>
                  )}
                </div>
              )}
              <MapmakerButton
                color="accent"
                disabled={!isValid}
                className="submit"
                type="submit"
                loading={isSubmitting}
              >
                Redeem
              </MapmakerButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
