import * as yup from "yup";
import { RenderableForm } from ".";

export const CutSvgForm: RenderableForm = {
  initialValues: {
    shrinkBorder: 0,
  },
  inputs: [
    {
      type: "design",
      id: "design",
      name: "Design",
      validation: yup.string().required(),
    },
    {
      type: "select",
      id: "layer",
      name: "Layer",
      options: [
        {
          label: "Cut",
          value: "cut",
        },
        {
          label: "Print",
          value: "print",
        },
        {
          label: "Inner",
          value: "inner",
        },
        {
          label: "Outer",
          value: "outer",
        },
      ],
    },
    ,
    {
      type: "number",
      id: "shrinkBorder",
      name: "Shrink Border (pt)",
    },
  ],
};
