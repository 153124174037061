import { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import MapmakerConfig from "@mapmaker/config";
import { useMapmakerAppConfig } from "../../../client";

export type MapmakerImageProps = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {
  pmSrc?: string;
  tblSrc?: string;
};

export default function MapmakerImage({
  pmSrc,
  tblSrc,
  src,
  ...props
}: MapmakerImageProps) {
  const { businessId } = useMapmakerAppConfig();

  function getSource() {
    if (tblSrc && businessId === "tbl") {
      return tblSrc;
    } else if (pmSrc && businessId === "pm") {
      return pmSrc;
    }
    return src.replace("{businessId}", businessId);
  }

  return <img src={`${MapmakerConfig.staticUrl}/${getSource()}`} {...props} />;
}
