import React from "react";
import classNames from "classnames";
import { Icon, Label, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import useContextPanel from "./useContextPanel";
import "./ContextPanelCompactButton.css";

export interface IContextPanelCompactButtonProps {
  label: string;
  shortLabel: string;
  sectionId: string;
  icon: SemanticICONS;
  iconLoading: boolean;
  onMenuButtonClick?: () => any;
  primary: boolean;
  disabled: boolean;
  badgeNumber?: number;
  badgeColor: SemanticCOLORS;
}

export default function ContextPanelCompactButton({
  label,
  shortLabel,
  sectionId,
  icon,
  iconLoading = false,
  onMenuButtonClick = null,
  primary = false,
  disabled,
  badgeNumber = null,
  badgeColor = null,
}: IContextPanelCompactButtonProps) {
  const { openSection } = useContextPanel();

  function onClick() {
    if (onMenuButtonClick) {
      // Custom button action
      onMenuButtonClick();
    } else {
      openSection(sectionId);
    }
  }

  function ButtonBadge() {
    if (badgeNumber) {
      return (
        <Label
          className="badge"
          color={badgeColor}
          size="tiny"
          floating
          circular
        >
          {badgeNumber}
        </Label>
      );
    } else {
      return null;
    }
  }

  return (
    <div
      id="context-panel-compact-button"
      className={classNames({
        primary,
        disabled,
      })}
      onClick={onClick}
    >
      <ButtonBadge />
      <Icon name={icon} loading={iconLoading} size="big" />
      <div className="name">{shortLabel || label}</div>
    </div>
  );
}
