import useOpeningImage from "./useOpeningImage";
import MapmakerToolbarButton from "../../shared/toolbar/MapmakerToolbarButton";
import RemoveOpeningImageModal from "./RemoveOpeningImageModal";
import OpeningImageRotator from "./OpeningImageRotator";
import OpeningImageScaler from "./OpeningImageScaler";
import OpeningImageInfoModal from "./OpeningImageInfoModal";
import PrintQualityIcon from "../../../printing/PrintQualityIcon";
import useEditableOpening from "./editable/useEditableOpening";
import { flipImage } from "./editable/editableOpeningImageReducer";
import {
  adjustImageDepth,
  removeImage,
} from "./editable/editableOpeningReducer";
import { useEditableOpeningDispatch } from "./editable/EditableOpeningStore";
import MapmakerToolbarDivider from "../../shared/toolbar/MapmakerToolbarDivider";
import { useEditableOpeningImage } from "./editable/useEditableOpeningImage";

export default function OpeningImageToolbar() {
  const { openingInput, activeImage } = useEditableOpening();
  const { image, depth, printDpi } = useEditableOpeningImage(activeImage?.id);
  const dispatch = useEditableOpeningDispatch();
  const flip = () => dispatch(flipImage(activeImage.id, !image.flipX));
  const moveUp = () => dispatch(adjustImageDepth(activeImage.id, 1));
  const moveDown = () => dispatch(adjustImageDepth(activeImage.id, -1));

  return (
    <>
      <MapmakerToolbarDivider />
      <MapmakerToolbarButton
        mode="modal"
        disabled={!activeImage}
        icon="info"
        tooltip="Image Info"
        badge={activeImage && <PrintQualityIcon printDpi={printDpi} />}
      >
        <OpeningImageInfoModal imageId={activeImage?.id} />
      </MapmakerToolbarButton>
      <MapmakerToolbarButton
        icon="angle up"
        disabled={!activeImage || depth + 1 === openingInput.images.length}
        tooltip="Move photo up one layer"
        action={moveUp}
      />
      <MapmakerToolbarButton
        icon="angle down"
        disabled={!activeImage || depth === 0}
        tooltip="Move photo down one layer"
        action={moveDown}
      />
      {/* Removed these when we started using the control box for manipulation.
      <MapmakerToolbarButton
      disabled={!activeImage}
      icon="zoom-in"
      tooltip="Scale photo"
      mode="popover"
      >
      <OpeningImageScaler imageId={activeImage?.id} />
      </MapmakerToolbarButton>
      <MapmakerToolbarButton
      disabled={!activeImage}
      icon="sync"
      tooltip="Rotate photo"
      mode="popover"
      >
      <OpeningImageRotator imageId={activeImage?.id} />
      </MapmakerToolbarButton>
      */}
      <MapmakerToolbarButton
        disabled={!activeImage}
        icon="exchange"
        tooltip="Flip photo"
        action={flip}
      />
      <MapmakerToolbarButton
        disabled={!activeImage}
        mode="modal"
        icon="trash"
        tooltip="Remove photo"
      >
        <RemoveOpeningImageModal
          onRemove={() => dispatch(removeImage(activeImage?.id))}
        />
      </MapmakerToolbarButton>
    </>
  );
}
