import React from "react";
import { moneyV2ToString } from "../../..";
import { StorefrontMoneyV2 } from "../../../client/MapmakerApi";

export interface IMoneyProps {
  moneyV2?: StorefrontMoneyV2;
  shorten?: boolean;
}

const MoneyV2 = React.memo(({ moneyV2, shorten = false }: IMoneyProps) => {
  if (!moneyV2) {
    return null;
  }
  return <>{moneyV2ToString(moneyV2, shorten)}</>;
});

export default MoneyV2;
