import BlockContent, {
  BlockContentProps,
} from "@sanity/block-content-to-react";
import AsideSerializer from "./serializers/AsideSerializer";
import ContactUsSerializer from "./serializers/ContactUsSerializer";
import ImageSerializer from "./serializers/ImageSerializer";
import InternalLinkSerializer from "./serializers/InternalLinkSerializer";
import ScreenshotSerializer from "./serializers/ScreenshotSerializer";
import YouTubeSerializer from "./serializers/YouTubeSerializer";
import "./SanityContent.css";

export interface ISanityContentProps {
  content: any;
}

const Serializers: BlockContentProps["serializers"] = {
  types: {
    youtube: YouTubeSerializer,
    aside: AsideSerializer,
    contentImage: ImageSerializer,
    screenshot: ScreenshotSerializer,
  },
  marks: {
    internalLink: InternalLinkSerializer,
    contactUs: ContactUsSerializer,
  },
};

export default function SanityContent({ content }: ISanityContentProps) {
  return (
    <div id="sanity-content">
      <BlockContent blocks={content} serializers={Serializers} />
    </div>
  );
}
