import {
  useContext,
  createContext,
  ReactSVGElement,
  Children,
  cloneElement,
  ReactElement,
  useRef,
  PropsWithChildren,
} from "react";
import { Viewbox } from "@mapmaker/core";
import useSvgScale from "../hooks/useSvgScale";
import {
  MapmakerSvgContextProvider,
  MapmakerSvgContextValue,
} from "@mapmaker/svg";
import { useMapmakerAppConfig } from "../../client";
import { createPortal } from "react-dom";

export interface SvgState {
  scale: number;
  viewbox: Viewbox;
  svgElement?: SVGSVGElement;
  svgParentElement?: HTMLElement;
}

const initialState: SvgState = {
  scale: 0,
  viewbox: [0, 0, 0, 0],
};

const Context = createContext<SvgState>(initialState);

type SvgContextProviderProps = {
  containerId?: string;
  viewbox: Viewbox;
  flex?: boolean;
  onImageLoadError?: MapmakerSvgContextValue["onImageLoadError"];
  children: ReactElement<ReactSVGElement>;
};

export function SvgContextProvider({
  containerId,
  viewbox,
  flex = false,
  onImageLoadError,
  children,
}: SvgContextProviderProps) {
  const { businessId } = useMapmakerAppConfig();
  const [ref, scale] = useSvgScale(viewbox);
  const svgRef = useRef<SVGSVGElement>();
  const svgParentRef = useRef<HTMLElement>();
  const mergedRef = (element: HTMLElement) => {
    ref(element);
    svgParentRef.current = element;
  };

  // Until the SVG has rendered once we won't know it's width.  Since these SVGs may require a
  // valid scale to be drawn properly, we'll first render a fake SVG with the same props but no
  // children until we have the actual width of the element.
  function getPlaceholderSvg() {
    const svg = Children.only(children);
    return cloneElement(svg, { ref: svgRef }, []);
  }

  return (
    <Context.Provider
      value={{ viewbox, scale, svgParentElement: svgParentRef.current }}
    >
      {/* The components in @mapmaker/svg all use the MapmakerSvgContextProvider to determine scale,
          so we need to keep that up in sync when in the browser. */}
      <MapmakerSvgContextProvider
        value={{
          svgScale: scale,
          onImageLoadError,
          businessId,
        }}
      >
        <div
          id={containerId}
          ref={mergedRef}
          style={{ height: "100%", display: flex ? "flex" : undefined }}
        >
          {scale === 0 ? getPlaceholderSvg() : children}
        </div>
      </MapmakerSvgContextProvider>
    </Context.Provider>
  );
}

export function useSvgContext() {
  return useContext(Context);
}

// Useful if an svg node to be drawn at the root of the SVG.
export function SvgPortal({ children }: PropsWithChildren<{}>) {
  const { svgElement } = useSvgContext();
  return createPortal(children, svgElement);
}

// Useful if an HTML element need to be draw as a sibling of the SVG.
export function SvgParentPortal({ children }: PropsWithChildren<{}>) {
  const { svgParentElement } = useSvgContext();
  return createPortal(children, svgParentElement);
}
