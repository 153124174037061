import { useEffect, useRef } from "react";

export default function useBeforeUnload(
  handler = (e: BeforeUnloadEvent) => {}
) {
  const handlerRef = useRef(handler);
  handlerRef.current = handler;

  useEffect(() => {
    const handleBeforeunload = event => {
      let returnValue;

      if (typeof handlerRef.current === "function") {
        returnValue = handlerRef.current(event);
      }

      if (event.defaultPrevented) {
        event.returnValue = "";
      }

      if (typeof returnValue === "string") {
        event.returnValue = returnValue;
        return returnValue;
      }
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, []);
}
