import React from "react";
import {
  getContentImageUrl,
  GraphicFeature,
  GraphicInput,
} from "@mapmaker/core";
import { FeatureComponent } from "../FeatureNode";
import useLoadedUrl from "../opening/useLoadedUrl";

/*
 * Renders a path (usually a compound path) of a layer with the openings cut out (eg. a piece of
 * matboard which would be cut).
 */
const Graphic: FeatureComponent<GraphicFeature, GraphicInput> = ({
  feature: graphic,
  input,
  svgScale = 1,
}) => {
  const optionId = input?.selectedOption || graphic.default;
  const selectedOption = graphic.options.find(option => option.id === optionId);
  const bbox = selectedOption?.bbox;
  const displayWidth = svgScale * bbox?.width || 1;

  const url = useLoadedUrl(
    getContentImageUrl(
      selectedOption?.imageKey,
      /* This could be improved... */
      displayWidth <= 512
        ? 512
        : displayWidth <= 1028
        ? 1028
        : displayWidth <= 2048
        ? 2048
        : displayWidth <= 4096
        ? 4096
        : 8192
    )
  );

  if (!selectedOption) {
    return null;
  }

  return (
    <image
      x={bbox.x}
      y={bbox.y}
      width={bbox.width}
      height={bbox.height}
      href={url}
    />
  );
};
export default Graphic;
