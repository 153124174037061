import { MapmakerDesign } from "../types";
import {
  FeatureType,
  FeatureBase,
  Feature,
  GraphicFeature,
  OpeningFeature,
} from "../types/Features";

function compareFeaturesByDepth(a: Feature, b: Feature) {
  const aDepth = a.depth || 0;
  const bDepth = b.depth || 0;
  return aDepth > bDepth ? 1 : aDepth < bDepth ? -1 : 0;
}

export function getFeaturesInOrder(
  design: Pick<MapmakerDesign, "features">
): Feature[] {
  return Object.values(design.features).sort(compareFeaturesByDepth);
}

export function getFeature<T extends Feature>(
  design: Pick<MapmakerDesign, "features">,
  featureId: string,
  type: T["type"]
): T {
  const feature = design.features[featureId];
  if (feature?.type === type) {
    return feature as T;
  } else {
    return null;
  }
}

export function getFeatures<T extends FeatureBase>(
  design: Pick<MapmakerDesign, "features">,
  type: FeatureType
): { [key: string]: T } {
  return Object.entries(design.features)
    .filter(([, feature]) => feature.type === type)
    .reduce((features, [featureId, feature]) => {
      features[featureId] = feature;
      return features;
    }, {});
}

export function getFeaturesList<T extends FeatureBase>(
  design: Pick<MapmakerDesign, "features">,
  type: FeatureType
): T[] {
  return Object.values(design.features ?? {})
    .filter(feature => feature.type === type)
    .map<T>(feature => (feature as unknown) as T);
}

export function getGraphicFeature(
  design: Pick<MapmakerDesign, "features">,
  id: string
): GraphicFeature {
  return getFeature<GraphicFeature>(design, id, "GRAPHIC");
}

export function getOpeningFeature(
  design: Pick<MapmakerDesign, "features">,
  id: string
): OpeningFeature {
  return getFeature<OpeningFeature>(design, id, "OPENING");
}
