import React, { useContext, useEffect, useState } from "react";
import { AuthState, useAuthContext } from "../AWSAuthContext";
import AuthBoxResetPassword from "./AuthBoxResetPassword";
import AuthBoxSendPasswordResetCode from "./AuthBoxSendPasswordResetCode";
import AuthBoxSignIn from "./AuthBoxSignIn";
import AuthBoxSignUp from "./AuthBoxSignUp";
import AuthBoxVerifyEmail from "./AuthBoxVerifyEmail";

export enum AuthBoxState {
  SIGN_IN = "sign-in",
  SIGN_UP = "sign-up",
  VERIFY_EMAIL = "verify-email",
  SEND_PASSWORD_RESET_CODE = "send-password-reset-code",
  RESET_PASSWORD = "reset-password",
  RESET_PASSWORD_REQUIRED = "reset-password-required",
}

type AuthenticationDetails = {
  email: string;
  password?: string;
};

export type AuthBoxContext = {
  termsAndConditionsUrl: string;
  userNotFoundExplanation?: React.ReactNode;
  authenticationDetails: AuthenticationDetails;
  authBoxState: AuthBoxState;
  presetVerificationCode?: string;
  noHeader?: boolean;
  setAuthBoxState(state: AuthBoxState): void;
  setAuthenticationDetails(authenticationDetails: AuthenticationDetails): void;
  complete(): void;
};

const Context = React.createContext<AuthBoxContext>({} as AuthBoxContext);

export function useAuthBoxContext(): AuthBoxContext {
  return useContext(Context);
}

interface IAuthBoxProps {
  authBoxState?: AuthBoxState;
  email?: string;
  termsAndConditionsUrl: string;
  userNotFoundExplanation?: React.ReactNode;
  onAuthBoxStateChange(authBoxState: AuthBoxState): void;
  onAuthenticated?(): void;
  presetVerificationCode?: string | null;
  noHeader?: boolean;
}

export function AuthBox({
  authBoxState = AuthBoxState.SIGN_IN,
  onAuthBoxStateChange,
  email = "",
  termsAndConditionsUrl,
  userNotFoundExplanation,
  onAuthenticated = () => {},
  presetVerificationCode,
  noHeader = false,
}: IAuthBoxProps) {
  const { state: appAuthState } = useAuthContext();
  /*
  const [authBoxState, setAuthBoxState] = useState<AuthBoxState>(
    initialAuthState
  );
  */
  function setAuthBoxState(state: AuthBoxState) {
    onAuthBoxStateChange(state);
  }

  const [authenticationDetails, setAuthenticationDetails] = useState<
    AuthenticationDetails
  >({
    email,
    password: "",
  });

  // Once we sign in the auth box should inform the parent that we're all done.
  useEffect(() => {
    if (appAuthState === AuthState.Authenticated) {
      onAuthenticated();
    }
  }, [appAuthState]);

  // Once we sign in the auth box should inform the parent that we're all done.
  useEffect(() => {
    if (appAuthState === AuthState.Authenticated) {
      onAuthenticated();
    }
  }, [appAuthState]);

  if (
    appAuthState === AuthState.Initializing ||
    appAuthState === AuthState.Authenticated
  ) {
    return null;
  }

  // Called when we have completed authenticating
  function complete() {
    onAuthenticated();
  }

  return (
    <Context.Provider
      value={{
        termsAndConditionsUrl,
        userNotFoundExplanation,
        authenticationDetails,
        authBoxState,
        presetVerificationCode,
        noHeader,
        setAuthBoxState,
        setAuthenticationDetails,
        complete,
      }}
    >
      <AuthBoxWithContext />
    </Context.Provider>
  );
}

function AuthBoxWithContext() {
  const { authBoxState, authenticationDetails } = useAuthBoxContext();
  switch (authBoxState) {
    case AuthBoxState.SIGN_IN:
      return <AuthBoxSignIn key={AuthBoxState.SIGN_IN} />;
    case AuthBoxState.SIGN_UP:
      return <AuthBoxSignUp key={AuthBoxState.SIGN_UP} />;
    case AuthBoxState.VERIFY_EMAIL:
      return <AuthBoxVerifyEmail key={AuthBoxState.VERIFY_EMAIL} />;
    case AuthBoxState.SEND_PASSWORD_RESET_CODE:
      return (
        <AuthBoxSendPasswordResetCode
          key={AuthBoxState.SEND_PASSWORD_RESET_CODE}
        />
      );
    case AuthBoxState.RESET_PASSWORD:
      return <AuthBoxResetPassword key={AuthBoxState.RESET_PASSWORD} />;
    case AuthBoxState.RESET_PASSWORD_REQUIRED:
      return (
        <AuthBoxResetPassword key={AuthBoxState.RESET_PASSWORD_REQUIRED}>
          <p>
            You must reset your password before logging in. An email has been
            sent to <strong>{authenticationDetails.email}</strong> with a
            verification code.
          </p>
        </AuthBoxResetPassword>
      );
  }
}
