import { Field } from "formik";
import { RenderableInputProps } from ".";
import { useValidateRenderableInput } from "./renderableInputUtils";
import { RectangularPrintOptions } from "../../../client/MapmakerApi";

export type RenderableRectangularPrintOptionsInputProps = RenderableInputProps<
  "rectangular-print-options",
  RectangularPrintOptions
>;

/**
 * NOTE - This is just a stub. In order to use the renders with Print Layouts we'll need to improve it.
 * A specialized tool to make a rectangular print layout.
 */
export default function RenderableRectangularPrintOptionsInput({
  name,
  id,
  validation,
}: RenderableRectangularPrintOptionsInputProps) {
  const validate = useValidateRenderableInput(id, validation);

  return (
    <>
      <Field type="string" name={id + ".backgroundColor"} validate={validate}>
        {({ field, meta }) => (
          <div
            id="renderable-input"
            className={meta.touched && meta.error ? "error" : undefined}
          >
            <div className="above">
              <label>Background Color</label>
            </div>
            <input type="text" {...field} />
            {meta.touched && meta.error && meta.value && (
              <div className="error">{meta.error}</div>
            )}
          </div>
        )}
      </Field>
      <Field type="string" name={id + ".borderColor"} validate={validate}>
        {({ field, meta }) => (
          <div
            id="renderable-input"
            className={meta.touched && meta.error ? "error" : undefined}
          >
            <div className="above">
              <label>Border Color</label>
            </div>
            <input type="text" {...field} />
            {meta.touched && meta.error && meta.value && (
              <div className="error">{meta.error}</div>
            )}
          </div>
        )}
      </Field>
      <Field type="number" name={id + ".borderWidth"} validate={validate}>
        {({ field, meta }) => (
          <div
            id="renderable-input"
            className={meta.touched && meta.error ? "error" : undefined}
          >
            <div className="above">
              <label>Border Width</label>
            </div>
            <input type="number" {...field} />
            {meta.touched && meta.error && meta.value && (
              <div className="error">{meta.error}</div>
            )}
          </div>
        )}
      </Field>
      <Field type="checkbox" name={id + ".clipImage"} validate={validate}>
        {({ field, meta }) => (
          <div
            id="renderable-input"
            className={meta.touched && meta.error ? "error" : undefined}
          >
            <div className="inline">
              <input type="checkbox" {...field} />
              <label>Clip Image</label>
            </div>
            {meta.touched && meta.error && meta.value && (
              <div className="error">{meta.error}</div>
            )}
          </div>
        )}
      </Field>
      <Field type="checkbox" name={id + ".includeBuffer"} validate={validate}>
        {({ field, meta }) => (
          <div
            id="renderable-input"
            className={meta.touched && meta.error ? "error" : undefined}
          >
            <div className="inline">
              <input type="checkbox" {...field} />
              <label>Include Buffer</label>
            </div>

            {meta.touched && meta.error && meta.value && (
              <div className="error">{meta.error}</div>
            )}
          </div>
        )}
      </Field>
      <Field type="checkbox" name={id + ".printingGuides"} validate={validate}>
        {({ field, meta }) => (
          <div
            id="renderable-input"
            className={meta.touched && meta.error ? "error" : undefined}
          >
            <div className="inline">
              <input type="checkbox" {...field} />
              <label>Printing Guides</label>
            </div>
            {meta.touched && meta.error && meta.value && (
              <div className="error">{meta.error}</div>
            )}
          </div>
        )}
      </Field>
    </>
  );
}
