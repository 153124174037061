import { RenderablePropsWithRefs } from "@mapmaker/renderable";
import { useEffect } from "react";
import {
  MapmakerFileAccessPolicy,
  ProjectOutputType,
} from "../client/MapmakerApi";
import { MapmakerPrintServiceId } from "../components";

type CustomEvent<Name extends string, Extra extends object = {}> = {
  event: Name;
} & Extra;

type CreateNewProject = CustomEvent<
  "mapmaker.create-project",
  {
    designId: string;
    outputScale: number;
    outputType: ProjectOutputType;
  }
>;

type ShowToastMessage = CustomEvent<
  "mapmaker.show-toast-message",
  { id: string; duration: number; type: string }
>;

type SetPreferredPrintService = CustomEvent<
  "mapmaker.set-preferred-printing-service",
  {
    service: MapmakerPrintServiceId;
  }
>;

type WalgreensSubmitOrder = CustomEvent<
  "mapmaker.place-walgreens-order",
  {
    numPrints: number;
    numOpenings: number;
    subtotal: number;
  }
>;

type AddImagesToProject = CustomEvent<
  "mapmaker.add-images-to-project",
  {
    openingId: string;
    count: number;
  }
>;

type RemoveImageFromProject = CustomEvent<
  "mapmaker.remove-image-from-project",
  {
    openingId: string;
  }
>;

type ToggleOptionalOpening = CustomEvent<
  "mapmaker.toggle-optional-opening",
  {
    openingId: string;
    enabled: boolean;
  }
>;

type ChangeProjectAccessPolicy = CustomEvent<
  "mapmaker.change-project-access-policy",
  {
    policy: MapmakerFileAccessPolicy;
  }
>;

type AddStickersToCart = CustomEvent<
  "mapmaker.add-stickers-to-cart",
  {
    numStickers: number;
  }
>;

type ShareRenderableClick = CustomEvent<
  "mapmaker.share-renderable-click",
  {
    url: string;
    renderableProps: RenderablePropsWithRefs;
    download: boolean;
  }
>;

type ShareRenderableCompleted = CustomEvent<
  "mapmaker.share-renderable-completed",
  {
    url: string;
    renderableProps: RenderablePropsWithRefs;
    download: boolean;
  }
>;

type SendFileAccessInvite = CustomEvent<"mapmaker.send-file-access-invite">;

type SubmitSuggestion = CustomEvent<
  "mapmaker.submit-suggestion",
  {
    openingId: string;
  }
>;

type AcceptSuggestion = CustomEvent<
  "mapmaker.accept-suggestion",
  {
    openingId: string;
    strategy: "add" | "overwrite" | "merge";
  }
>;

type ManuallyToggleSuggestionHidden = CustomEvent<
  "mapmaker.manually-toggle-suggestion-hidden",
  {
    openingId: string;
    hidden: boolean;
  }
>;

type GTMCustomEvent =
  | AcceptSuggestion
  | AddImagesToProject
  | AddStickersToCart
  | SetPreferredPrintService
  | CreateNewProject
  | ChangeProjectAccessPolicy
  | ManuallyToggleSuggestionHidden
  | WalgreensSubmitOrder
  | RemoveImageFromProject
  | SendFileAccessInvite
  | ShareRenderableClick
  | ShareRenderableCompleted
  | ShowToastMessage
  | SubmitSuggestion
  | ToggleOptionalOpening;

type GtmMapmakerEventContext = {
  fileId?: string;
  designId?: string;
};

let CurrentContext: GtmMapmakerEventContext = {};

export function trackGtmEvent(event: GTMCustomEvent) {
  try {
    // @ts-ignore
    window.dataLayer.push({
      ...CurrentContext,
      ...event,
    });
  } catch (e) {
    // Ignore everything. Don;t want to mess something up and have the user confused.
  }
}

export function useGtmContext(context: GtmMapmakerEventContext) {
  useEffect(() => {
    CurrentContext = {
      ...CurrentContext,
      ...context,
    };
    () => {
      for (let key in context) {
        delete CurrentContext[key];
      }
    };
  });
}
