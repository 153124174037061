import clsx from "clsx";
import { HTMLProps, PropsWithChildren } from "react";
import "./MapmakerTilebar.css";

export interface IMapmakerTilebarProps extends HTMLProps<HTMLDivElement> {}

export default function MapmakerTilebar({
  children,
  ...props
}: PropsWithChildren<IMapmakerTilebarProps>) {
  return (
    <div {...props} className={clsx("mapmaker-tilebar", props.className)}>
      {children}
    </div>
  );
}
