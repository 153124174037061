import { ComponentType, Fragment } from "react";
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";
import { Card, SemanticWIDTHSNUMBER } from "semantic-ui-react";
import { RectangularPrintLayoutInput } from "../../../client/MapmakerApi";
import useRectangularPrintsSelector from "./useRectangularPrintsSelector";
import { PaperSize } from "../../../lib/prints/printSizes";

export interface IGroupHeaderProps {
  paperSize: PaperSize;
  printLayouts: RectangularPrintLayoutInput[];
}

export interface ICardProps {
  printLayout: RectangularPrintLayoutInput;
}

export type RectangularPrintListSlots = {
  "group-header": ComponentType<IGroupHeaderProps>;
  card: ComponentType<ICardProps>;
};

export interface IRectangularPrintListProps {
  slots: RectangularPrintListSlots;
}

export default function RectangularPrintList({
  slots,
}: IRectangularPrintListProps) {
  const GroupHeaderComponent = slots["group-header"];
  const CardComponent = slots["card"];
  const { printSizeGroups } = useRectangularPrintsSelector();
  const [ref, { width }] = useMeasure({ polyfill: ResizeObserver });

  return (
    <div className="list-content" ref={ref}>
      {printSizeGroups.map(({ paperSize, printLayouts }) => {
        return (
          <Fragment key={paperSize.id}>
            <GroupHeaderComponent
              paperSize={paperSize}
              printLayouts={printLayouts}
            />
            <Card.Group
              itemsPerRow={Math.ceil((width + 1) / 400) as SemanticWIDTHSNUMBER}
            >
              {printLayouts.map((printLayout, i) => (
                <CardComponent
                  key={printLayout.printOpenings[0].openingId}
                  printLayout={printLayout}
                />
              ))}
            </Card.Group>
          </Fragment>
        );
      })}
    </div>
  );
}
