import React from "react";
import { FrameType } from "./FrameLibrary";

export type FrameProps = {
  width: number;
  height: number;
  frame?: Pick<FrameType, "color" | "thickness">;
};

export default function Frame({ width, height, frame }: FrameProps) {
  if (!frame) {
    return null;
  }
  /*    fx1                           fx2
   * fy1 *-----------------------------*
   *     |    x1                  x2   |
   *     | y1 *-------------------*    |
   *     |    |                   |    |
   *     |    |                   |    |
   *     |    |                   |    |
   *     | y2 *-------------------*    |
   *     |                             |
   * fy2 *-----------------------------*
   */
  const x1 = 0;
  const x2 = width;
  const y1 = 0;
  const y2 = height;
  const fx1 = -frame.thickness * 72;
  const fx2 = width + frame.thickness * 72;
  const fy1 = -frame.thickness * 72;
  const fy2 = height + frame.thickness * 72;

  return (
    <>
      <filter id="frameShadow">
        <feDropShadow
          dx={20}
          dy={20}
          stdDeviation={30}
          floodColor="#000000"
          floodOpacity={0.5}
        />
        <feDropShadow
          dx={0}
          dy={0}
          stdDeviation={5}
          floodColor="#000000"
          floodOpacity={0.5}
        />
      </filter>
      <g filter={"url(#frameShadow)"}>
        {/* Left */}
        <polygon
          points={`${fx1},${fy1} ${x1},${y1} ${x1},${y2} ${fx1},${fy2}`}
          fill={frame.color}
        />
        {/* Right */}
        <polygon
          points={`${fx2},${fy1} ${x2},${y1} ${x2},${y2} ${fx2},${fy2}`}
          fill={frame.color}
        />
        {/* Top */}
        <polygon
          points={`${fx1},${fy1} ${fx2},${fy1} ${x2},${y1} ${x1},${y1}`}
          fill={frame.color}
        />
        {/* Bottom */}
        <polygon
          points={`${fx1},${fy2} ${fx2},${fy2} ${x2},${y2} ${x1},${y2}`}
          fill={frame.color}
        />
      </g>
    </>
  );
}
