import { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import { TinyText } from "@mapmaker/ui";
import { getResizedImageUrl, useLoadedUrl } from "@mapmaker/svg";
import clsx from "clsx";
import { Icon } from "semantic-ui-react";
import PrintQualityIcon from "../../../printing/PrintQualityIcon";
import { getPrintDPIQualityGroup } from "../../../printing/PrintDPI";
import { useProjectFile, useProjectUpload } from "../../useProjectState";
import { MapmakerFileViewerPolicy } from "../../../../client/MapmakerApi";
import { OpeningImageInput } from "@mapmaker/core";
import "./OpeningImagePreview.css";

export interface IOpeningImagePreviewProps {
  image: OpeningImageInput;
  srcWidth: number;
  srcHeight: number;
  printDpi: number;
}

export default function OpeningImagePreview({
  image,
  srcWidth,
  srcHeight,
  printDpi,
  ...props
}: IOpeningImagePreviewProps &
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  const { dataUrl, status } = useProjectUpload(image.id);
  const previewUrl =
    dataUrl ?? getResizedImageUrl(image.id, srcWidth, srcHeight);
  const loadedUrl = useLoadedUrl(previewUrl);
  const quality = getPrintDPIQualityGroup(printDpi);

  return (
    <div
      id="opening-image-preview"
      className={clsx(props.className, {})}
      {...props}
    >
      <div className={`badge ${quality.severity}`}>
        <PrintQualityIcon printDpi={printDpi} style={{ color: "#FFF" }} />
      </div>
      {status === "IN_PROGRESS" && (
        <TinyText className="uploading">uploading</TinyText>
      )}
      <img src={loadedUrl} width="100%" height="100%" />
    </div>
  );
}

type OpeningImagePreviewAddProps = {
  onClick(): any;
};

OpeningImagePreview.Add = function({ onClick }: OpeningImagePreviewAddProps) {
  return (
    <div id="opening-image-preview-add" onClick={onClick}>
      <div>
        <Icon name="photo" size="large" />
        <span className="label">ADD</span>
      </div>
    </div>
  );
};

type OpeningImagePreviewViewSuggestionsProps = {
  count: number;
  onClick(): any;
};

OpeningImagePreview.ViewSuggestions = function({
  count,
  onClick,
}: OpeningImagePreviewViewSuggestionsProps) {
  const { viewerPolicy } = useProjectFile();
  if (count === 0 || viewerPolicy !== MapmakerFileViewerPolicy.Owner) {
    return null;
  }
  return (
    <div id="opening-image-preview-view-suggestions" onClick={onClick}>
      <TinyText className="label" fontSize="6.5pt">
        VIEW
      </TinyText>
      <span className="number">{count}</span>
      <TinyText className="label" fontSize="6.5pt">
        SUGGESTIONS
      </TinyText>
    </div>
  );
};
