import { OpeningImageInput } from "@mapmaker/core";
import { ReactNode } from "react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import { clamp } from "../../lib/math";

export type PrintDPIQuality = "terrible" | "bad" | "ok" | "good" | "great";
export type PrintQualitySeverity = "error" | "warning" | "ok";

export type PrintDPIQualityGroup = {
  id: PrintDPIQuality;
  name: string;
  minDpi: number;
  icon: SemanticICONS;
  severity: PrintQualitySeverity;
  tips: ReactNode;
};

export type PrintDPIBreakdown = {
  score: number;
  quality: PrintDPIQualityGroup;
};

const QualityGroups: PrintDPIQualityGroup[] = [
  {
    id: "terrible",
    name: "Very Blurry",
    severity: "error",
    icon: "warning sign",
    minDpi: 0,
    tips:
      "This image will be extremely blurry when printed. We do not recommend ordering stickers like this. To improve the quality you can scale down the image here or replace it by uploading a higher quality version.",
  },
  {
    id: "bad",
    name: "Blurry",
    severity: "warning",
    icon: "warning sign",
    minDpi: 72,
    tips:
      "This image will be blurry when printed. We do not recommend ordering stickers like this. To improve the quality you can scale down the image here or replace it by uploading a higher quality version.",
  },
  {
    id: "ok",
    name: "Not Great",
    severity: "warning",
    icon: "check circle",
    minDpi: 144,
    tips:
      "When printed this image will look somewhat blurry from up close. It is acceptable to print like this but to improve the quality you can scale down the image here or replace it by uploading a higher quality version.",
  },
  {
    id: "good",
    name: "Good",
    severity: "ok",
    icon: "check circle",
    minDpi: 200,
    tips: "This image will look good when printed.",
  },
  {
    id: "great",
    name: "Great!",
    severity: "ok",
    icon: "check circle",
    minDpi: 300,
    tips: "This image will look very good when printed.",
  },
];
const QualityGroupsReverseOrder = QualityGroups.reverse();

const MinDPIForScore =
  QualityGroupsReverseOrder[QualityGroupsReverseOrder.length - 2].minDpi;
const MaxDPIForScore = QualityGroupsReverseOrder[0].minDpi;

/**
 * A score from 0 (horrible) to 1 ()
 */
export function getPrintDPIQualityScore(dpi: number): number {
  const score = (dpi - MinDPIForScore) / (MaxDPIForScore - MinDPIForScore);
  return clamp(score, 0, 1);
}

export function getPrintDPIQualityGroup(dpi: number): PrintDPIQualityGroup {
  if (dpi < 0) {
    dpi = 0;
  }
  return QualityGroupsReverseOrder.find(group => dpi >= group.minDpi);
}

export function getPrintDPIFromOpeningImage(
  openingImage: OpeningImageInput,
  outputScale: number
): number {
  return getPrintDPI(
    openingImage.imageWidth,
    openingImage.imageHeight,
    openingImage.width,
    openingImage.height,
    outputScale
  );
}

export function getPrintDPI(
  imageWidth: number,
  imageHeight: number,
  displayWidth: number,
  displayHeight: number,
  outputScale: number
): number {
  const horizontalDpi = (imageWidth / (displayWidth * outputScale)) * 72;
  const verticalDpi = (imageHeight / (displayHeight * outputScale)) * 72;
  return Math.min(horizontalDpi, verticalDpi);
}
