import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import useDevice from "../lib/hooks/useDevice";
export default function ResponsiveImage(_a) {
    var { mobile, desktop, alt } = _a, props = __rest(_a, ["mobile", "desktop", "alt"]);
    const { isMobile } = useDevice();
    const activeProps = isMobile ? mobile : desktop;
    const finalProps = Object.assign(Object.assign({}, props), activeProps);
    return _jsx("img", Object.assign({ alt: alt }, finalProps));
}
