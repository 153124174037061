import { Children, ReactElement, ReactNode, useMemo } from "react";
import ContextPanelCompactButton from "./ContextPanelCompactButton";
import useContextPanel from "./useContextPanel";
import { IContextPanelSectionProps } from "./ContextPanelSection";
import ContextPanelCompactSection from "./ContextPanelCompactSection";
import "./ContextPanelCompact.css";

export interface IContextPanelCompactProps {
  children: ReactNode;
}

export default function ContextPanelCompact({
  children,
}: IContextPanelCompactProps) {
  const { openSectionId } = useContextPanel();

  const openSection = useMemo(
    () =>
      Children.toArray(children).find(
        (child: any) => child?.props?.sectionId === openSectionId
      ) as ReactElement<IContextPanelSectionProps>,
    [openSectionId, children]
  );

  const content = useMemo(() => {
    if (openSection) {
      return <ContextPanelCompactSection {...openSection.props} />;
    } else {
      return (
        <div id="context-panel-compact-menu">
          {Children.map(children, child => {
            if (!child) {
              return null;
            } else {
              return (
                <ContextPanelCompactButton {...(child as ReactElement).props} />
              );
            }
          })}
        </div>
      );
    }
  }, [openSection, children]);

  return <div id="context-panel-compact">{content}</div>;
}
