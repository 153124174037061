import { RenderablePropsWithRefs, RenderableType } from "@mapmaker/renderable";
import {
  createContext,
  PropsWithChildren,
  Reducer,
  useContext,
  useMemo,
  useReducer,
} from "react";

export type SandboxContextValue = SandboxContextState & SandboxContextActions;

type RenderablePropsWithoutType = Omit<RenderablePropsWithRefs, "type">;

type SandboxContextState = {
  renderableType?: RenderableType;
  initialProps?: RenderablePropsWithoutType;
  props?: RenderablePropsWithoutType;
  fullProps?: RenderablePropsWithRefs;
};

type SandboxContextActions = {
  setRenderableType(renderableType: RenderableType): any;
  setProps(props: RenderablePropsWithRefs): any;
};

type SandboxContextReducerAction =
  | {
      type: "set-renderable-type";
      renderableType?: RenderableType;
    }
  | {
      type: "set-props";
      props?: RenderablePropsWithoutType;
    };

function sandboxContextReducer(
  state: SandboxContextState,
  action: SandboxContextReducerAction
): SandboxContextState {
  switch (action.type) {
    case "set-renderable-type":
      return {
        ...state,
        renderableType: action.renderableType,
        props: undefined,
      };
    case "set-props":
      console.log(action.props);
      return {
        ...state,
        props: action.props,
      };
  }
}

const SandboxContext = createContext<SandboxContextValue>(
  {} as SandboxContextValue
);

type SandboxContextProviderProps = PropsWithChildren<{
  sandboxBasePath: string;
}>;

export function SandboxContextProvider({
  children,
}: SandboxContextProviderProps) {
  const [state, dispatch] = useReducer<
    Reducer<SandboxContextState, SandboxContextReducerAction>
  >(sandboxContextReducer, {
    renderableType: "all_openings",
  });

  const actionCreators = useMemo<SandboxContextActions>(
    () => ({
      setRenderableType: (renderableType: RenderableType) => {
        dispatch({
          type: "set-renderable-type",
          renderableType,
        });
      },
      setProps: (props: RenderablePropsWithoutType) => {
        dispatch({
          type: "set-props",
          props,
        });
      },
    }),
    [dispatch]
  );

  return (
    <SandboxContext.Provider
      value={{
        ...state,
        ...actionCreators,
        fullProps:
          state.props && state.renderableType
            ? ({
                ...state.props,
                type: state.renderableType,
              } as RenderablePropsWithRefs)
            : undefined,
      }}
    >
      {children}
    </SandboxContext.Provider>
  );
}

export function useSandboxContext(): SandboxContextValue {
  return useContext<SandboxContextValue>(SandboxContext);
}
