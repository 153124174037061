import {
  RenderablePropMode,
  RenderableProps,
  RenderablePropsWithImmutableRefs,
  RenderablePropValue,
} from ".";
import {
  RenderableDesignResource,
  RenderablePropsWithRefs,
  RenderablePropsWithResources,
  RenderableRefKeyStrings,
  RenderableRef,
  RenderableRefKey,
  RenderableFileResource,
  RenderableJSONValue,
  RenderableResource,
  RenderableOpeningSuggestionResource,
  RenderableFileImmutableRef,
  RenderableOpeningSuggestionImmutableRef,
} from "./core";

export type RefMap = {
  DESIGN: string[];
  FILE: string[];
  OPENING_SUGGESTION: string[];
};

export type ResourceMap = {
  DESIGN: Record<string, RenderableDesignResource>;
  FILE: Record<string, RenderableFileResource>;
  OPENING_SUGGESTION: Record<string, RenderableOpeningSuggestionResource>;
};

export type FetchResourcesFunction = (refs: RefMap) => Promise<ResourceMap>;

/*
 * A helper method to easily walk a RenderableProps tree and replace the {REF:"..."} objects with
 * their actual resources. Callers must supply their own resolvers for each resource type.
 */
export async function resolveRenderableRefs(
  props: RenderablePropsWithRefs,
  fetchResources: FetchResourcesFunction
): Promise<[RenderablePropsWithResources, RenderablePropsWithImmutableRefs]> {
  const allRefs = collectRefs(props);
  const refMap = mapRefs(allRefs);
  const resourceMap = await fetchAllResources(refMap, fetchResources);
  const propsWithResources = replaceRefs(
    props,
    createResourceRefReplacer(resourceMap)
  );
  const propsWithImmutableRefs = replaceRefs(
    props,
    createImmutableRefReplacer(resourceMap)
  );
  return [propsWithResources, propsWithImmutableRefs];
}

function collectRefs(value: any): RenderableRef[] {
  if (isRef(value)) {
    return [value];
  } else if (isObject(value)) {
    return Object.values(value).flatMap(collectRefs);
  } else if (Array.isArray(value)) {
    return value.flatMap(collectRefs);
  } else {
    return [];
  }
}

function mapRefs(refs: RenderableRef[]): RefMap {
  const map: RefMap = {
    DESIGN: [],
    FILE: [],
    OPENING_SUGGESTION: [],
  };
  refs.forEach(ref => {
    const [type, id] = Object.entries(ref)[0];
    if (!map[type].includes(id)) {
      map[type].push(id);
    }
  });
  return map;
}

async function fetchAllResources(
  refMap: RefMap,
  fetchResources: FetchResourcesFunction
): Promise<ResourceMap> {
  const resourceMap = await fetchResources(refMap);
  ensureRefsResolved(refMap.DESIGN, resourceMap.DESIGN, "design");
  ensureRefsResolved(refMap.FILE, resourceMap.FILE, "file");
  ensureRefsResolved(
    refMap.OPENING_SUGGESTION,
    resourceMap.OPENING_SUGGESTION,
    "opening_suggestion"
  );
  return resourceMap;
}

type RefReplacer<Mode extends RenderablePropMode> = (
  ref: RenderableRef
) => RenderablePropValue[Mode];

function replaceRefs<Mode extends RenderablePropMode>(
  props: RenderablePropsWithRefs,
  replacer: RefReplacer<Mode>
): RenderableProps<Mode> {
  const propsWithResources = {};
  Object.entries(props).forEach(
    ([key, value]) =>
      (propsWithResources[key] = replaceRefsValue(value, replacer))
  );
  return propsWithResources as RenderableProps<Mode>;
}

function replaceRefsValue<Mode extends RenderablePropMode>(
  value: RenderableJSONValue<"ref">,
  replacer: RefReplacer<Mode>
): RenderableJSONValue<Mode> {
  if (isRef(value)) {
    return replacer(value as RenderableRef);
  } else if (isObject(value)) {
    const valueWithResources = {};
    Object.entries(value).forEach(
      ([key, value]) =>
        (valueWithResources[key] = replaceRefsValue(value, replacer))
    );
    return valueWithResources;
  } else if (Array.isArray(value)) {
    return value.map(item => replaceRefsValue(item, replacer));
  } else {
    return value as RenderableJSONValue<Mode>;
  }
}

/* When given a ref, replaces it with a resource from a resource map. */
function createResourceRefReplacer(
  resourceMap: ResourceMap
): RefReplacer<"resource"> {
  return (ref: RenderableRef) => {
    const [type, key] = Object.entries(ref)[0];
    return resourceMap[type][key];
  };
}

/* When given a ref, adds a timestamp if necessary (used for cacheable resources that can change). */
function createImmutableRefReplacer(
  resourceMap: ResourceMap
): RefReplacer<"immutable_ref"> {
  return (ref: RenderableRef) => {
    if (ref["FILE"]) {
      const file = resourceMap.FILE[ref["FILE"]] as RenderableFileResource;
      return {
        ...ref,
        updatedAt: file.updatedAt,
      } as RenderableFileImmutableRef;
    } else if (ref["OPENING_SUGGESTION"]) {
      const suggestion = resourceMap.OPENING_SUGGESTION[
        ref["OPENING_SUGGESTION"]
      ] as RenderableOpeningSuggestionResource;
      return {
        ...ref,
        submittedAt: suggestion.submittedAt,
      } as RenderableOpeningSuggestionImmutableRef;
    } else {
      return ref as RenderablePropValue["immutable_ref"];
    }
  };
}

/**
 * Utilities
 */
const isObject = value =>
  !!(value && typeof value === "object" && !Array.isArray(value));

const isRef = value =>
  isObject(value) &&
  Object.keys(value).length === 1 &&
  RenderableRefKeyStrings.includes(Object.keys(value)[0] as RenderableRefKey);

function ensureRefsResolved(
  ids: string[],
  result: Record<string, RenderableResource>,
  resourceTypeName: string = "resource"
) {
  const missing = ids.filter(id => !result[id]);

  if (missing.length === 1) {
    throw new Error(
      `Could not resolve resource. ${resourceTypeName} with id '${missing[0]}' was not found.`
    );
  } else if (missing.length > 1) {
    throw new Error(
      `Could not resolve resources. ${resourceTypeName}s with ids ${missing
        .map(id => `'${id}'`)
        .join(", ")} was not found.`
    );
  }
}
