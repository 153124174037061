/**
 * Some utilities for parsing Exif data, which is useful to scrape some info from uploaded images.
 */

import { OpeningImageInput } from "@mapmaker/core";

type ExifDataFields = Pick<
  OpeningImageInput,
  "timeTakenExif" | "gpsLatExif" | "gpsLongExif"
>;

export function getExifDataForOpeningImage(exif: any): Partial<ExifDataFields> {
  try {
    const gpsInfo = exif?.get("GPSInfo");
    return {
      timeTakenExif: getExifDateTime(exif),
      gpsLongExif: parseDMS(
        gpsInfo?.get("GPSLatitude"),
        gpsInfo?.get("GPSLatitudeRef")
      ),
      gpsLatExif: parseDMS(
        gpsInfo?.get("GPSLongitude"),
        gpsInfo?.get("GPSLongitudeRef")
      ),
    };
  } catch (e) {
    return {};
  }
}

export function parseDMS(dms: string, direction: string) {
  if (!dms) {
    return undefined;
  }
  try {
    const parts = dms.split(/[^\d\w\.]+/);
    return convertDMSToDD(parts[0], parts[1], parts[2], direction);
  } catch {
    return undefined;
  }
}

function convertDMSToDD(
  degrees: string,
  minutes: string,
  seconds: string,
  direction: string
): number {
  let dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);

  if (direction === "S" || direction === "W") {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
}

// Tries to determine when the photo was taken based off Exif data.
function getExifDateTime(exif: any): string | undefined {
  try {
    const datetime = parseExifTime(exif.get("DateTime"));
    if (datetime) {
      return datetime.toISOString();
    }
    const datetimeOriginal = parseExifTime(exif.get("DateTimeOriginal"));
    return datetimeOriginal?.toISOString();
  } catch (e) {
    return undefined;
  }
}

function parseExifTime(exifDatetime: string): Date | undefined {
  if (!exifDatetime) {
    return undefined;
  }
  try {
    const [y, m, d, h, min, s] = exifDatetime.split(/\D/).map(x => parseInt(x));
    // Exif data does not include timezone data, so we use UTC. Consumers can deal with it as they
    // wish. Keep in mind if we're using this to tell a user when they took the photo, then the
    // correct time to show is the time it was where they took the photo - not translated to their
    // current timezone they are viewing from.
    return new Date(y, m - 1, d, h, min, s);
  } catch (e) {
    return undefined;
  }
}
