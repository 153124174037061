import { gql } from "@apollo/client";
import { ProjectOutputType } from "@mapmaker/core";
import { useMemo } from "react";
import {
  useGetStickerProductsQuery,
  StickerVariantForPricingFragment,
  StickerSizeClass,
} from "../MapmakerApi";

gql`
  fragment StickerVariantForPricing on StorefrontProductVariant {
    id
    title
    priceV2 {
      currencyCode
      amount
    }
    stickerSizeClass: metafield(
      namespace: "mapmaker"
      key: "sticker.sizeClass"
    ) {
      value
    }
    stickerOutputType: metafield(
      namespace: "mapmaker"
      key: "sticker.outputType"
    ) {
      value
    }
    stickerTokens: metafield(namespace: "mapmaker", key: "sticker.tokens") {
      value
    }
    tokenRedemptionVariant: metafield(
      namespace: "mapmaker"
      key: "sticker.tokenRedemptionVariant"
    ) {
      value
    }
  }

  query getStickerProducts {
    stickers: storefrontCollectionByHandle(handle: "sticker-products") {
      products(first: 10, sortKey: PRICE) {
        edges {
          node {
            id
            variants(first: 20) {
              edges {
                node {
                  ...StickerVariantForPricing
                }
              }
            }
          }
        }
      }
    }
  }
`;

export type ShopifyStickerVariant = {
  name: string;
  shopifyVariantId: string;
  tokens: number;
  price: number;
  sizeClass: StickerSizeClass;
  outputType: ProjectOutputType;
};

// All the variants for a single output type.
export type ShopifyStickerSet = {
  variants: ShopifyStickerVariant[];
  tokenRedemptionVariantId: string;
};

export type ShopifyStickerSetMap = {
  [key in ProjectOutputType]?: ShopifyStickerSet;
};

export function useShopifyStickerData() {
  const { data, ...rest } = useGetStickerProductsQuery();
  const stickerSetMap: ShopifyStickerSetMap = useMemo(() => {
    if (!data) {
      return {};
    } else {
      return data?.stickers?.products.edges.reduce<ShopifyStickerSetMap>(
        (stickerSetMap, edge) => {
          edge.node.variants.edges.forEach(({ node: variant }) => {
            const metadata = getShopifyMetadata(variant);

            if (!metadata) {
              return;
            }

            if (!stickerSetMap[metadata.outputType]) {
              stickerSetMap[metadata.outputType] = {
                tokenRedemptionVariantId: undefined,
                variants: [],
              };
            }

            if (metadata.tokenRedemptionVariant) {
              stickerSetMap[metadata.outputType].tokenRedemptionVariantId =
                variant.id;
            } else {
              stickerSetMap[metadata.outputType].variants.push({
                shopifyVariantId: variant.id,
                name: variant.title,
                price: parseFloat(variant.priceV2.amount),
                ...metadata,
              } as ShopifyStickerVariant);
              stickerSetMap[metadata.outputType].variants.sort((a, b) =>
                a.price < b.price ? -1 : a.price > b.price ? 1 : 0
              );
            }
          });
          return stickerSetMap;
        },
        {}
      );
    }
  }, [data]);

  return {
    stickerSetMap,
    ...rest,
  };
}

type ParsedShopifyMetadata = {
  outputType: ProjectOutputType;
  tokenRedemptionVariant: boolean;
  sizeClass?: StickerSizeClass;
  tokens?: number;
};

function getShopifyMetadata(
  variant: StickerVariantForPricingFragment
): ParsedShopifyMetadata {
  const outputType = variant.stickerOutputType.value as ProjectOutputType;
  if (!variant.stickerOutputType?.value) {
    return null;
  }
  if (variant.tokenRedemptionVariant?.value === "true") {
    return {
      outputType,
      tokenRedemptionVariant: true,
    };
  } else {
    if (variant.stickerTokens?.value || variant.stickerSizeClass?.value) {
      return {
        //    price: parseFloat(variant.priceV2.amount),
        tokenRedemptionVariant: false,
        outputType,
        sizeClass: variant.stickerSizeClass?.value as StickerSizeClass,
        tokens: parseFloat(variant.stickerTokens.value),
      };
    }
  }
  return null;
}
