import { gql } from "@apollo/client";
import {
  RenderablePropsWithResources,
  resolveRenderableRefs,
  RenderOutput,
} from "@mapmaker/renderable";
import { RenderableComponent } from "@mapmaker/svg";
import { LoadingSpinner } from "@mapmaker/ui";
import clsx from "clsx";
import { Component, useEffect, useRef, useState } from "react";
import Suspender from "react-suspender";
import { Icon } from "semantic-ui-react";
import { useMapmakerContext } from "../..";
import { useRenderMutation } from "../../client/MapmakerApi";
import useOnClickOutside from "../../lib/hooks/useOnClickOutside";
import createFetchResources from "./fetchResources";
import { useSandboxContext } from "./SandboxContext";
import "./SandboxDisplay.css";

export default function SandboxDisplay() {
  return (
    <div id="sandbox-display">
      <ErrorBoundary>
        <SandboxDisplaySafe />
      </ErrorBoundary>
    </div>
  );
}

function SandboxDisplaySafe() {
  const { fullProps, renderableType } = useSandboxContext();
  const { apolloClient } = useMapmakerContext();
  const [error, setError] = useState<string>();
  const [propsWithResources, setPropsWithResources] =
    useState<RenderablePropsWithResources>();

  useEffect(() => {
    if (!fullProps) {
      setPropsWithResources(undefined);
      return;
    }
    const resolve = async () => {
      setPropsWithResources(undefined);
      try {
        const [propsWithResources] = await resolveRenderableRefs(
          fullProps,
          createFetchResources(apolloClient)
        );
        setPropsWithResources(propsWithResources);
      } catch (e) {
        setError(e.message);
      }
    };
    resolve();
  }, [fullProps]);

  if (!fullProps) {
    return <h1>Select Options</h1>;
  }

  if (error) {
    return (
      <>
        <h1>Something went wrong.</h1>
        <p style={{ color: "var(--mm-dark-white" }}>{error}</p>
      </>
    );
  }

  if (!propsWithResources) {
    return <Suspender />;
  }

  return (
    <>
      <DownloadBox />
      <RenderableComponent
        {...{ type: renderableType, ...propsWithResources }}
      />
    </>
  );
}

gql`
  mutation render($request: RenderRequest!) {
    render(request: $request) {
      url
    }
  }
`;

function DownloadBox() {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>();
  useOnClickOutside(ref, () => setOpen(false));
  const { fullProps } = useSandboxContext();
  const [render, { loading }] = useRenderMutation();

  async function onRenderClick(output: RenderOutput) {
    const renderResult = await render({
      variables: {
        request: {
          props: fullProps,
          output,
        },
      },
    });
    window.open(renderResult.data.render.url);
    setOpen(false);
  }

  return (
    <div className={clsx({ "download-box": true, open, loading })} ref={ref}>
      <div className="download-button">
        <Icon name="download" onClick={() => setOpen(!open)} />
      </div>
      {open && (
        <div className="menu">
          <ul>
            <li onClick={() => onRenderClick({ format: "png" })}>PNG</li>
            <li onClick={() => onRenderClick({ format: "jpeg" })}>JPEG</li>
            <li onClick={() => onRenderClick({ format: "svg" })}>SVG</li>
          </ul>
          <div className="spinner">
            <LoadingSpinner />
            <span>Rendering</span>
          </div>
        </div>
      )}
    </div>
  );
}

class ErrorBoundary extends Component<{}, { error: any }> {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Something went wrong.</h1>
          <p style={{ color: "var(--mm-dark-white" }}>
            {this.state.error?.message ?? "Unknown Error"}
          </p>
        </>
      );
    }

    return this.props["children"];
  }
}
