import { createContext, PropsWithChildren, useContext, useState } from "react";
import useSessionStorage from "react-use/lib/useSessionStorage";
import {
  MapmakerFileViewerPolicy,
  OpeningSuggestionFragment,
} from "../../../client/MapmakerApi";
import { useProjectFile } from "../useProjectState";

type FullMapViewContextValue = {
  mode: FullMapViewMode;
  setMode(mode: FullMapViewMode): any;
  suggestions?: OpeningSuggestionFragment[];
};
type FullMapViewMode = "live" | "live-with-suggestions" | "suggestions";

const FullMapViewContext = createContext<FullMapViewContextValue>({
  mode: "live",
  setMode: () => {},
});

type FullMapViewProviderProps = {
  // Name used to store settings for this view.
  name: string;
  initialMode?: FullMapViewMode;
  suggestions?: OpeningSuggestionFragment[];
};

export function FullMapViewProvider({
  name,
  initialMode = "live",
  suggestions,
  children,
}: PropsWithChildren<FullMapViewProviderProps>) {
  const file = useProjectFile();
  const [savedModes, setSaveModes] = useSessionStorage<
    Partial<Record<MapmakerFileViewerPolicy, FullMapViewMode>>
  >("mapmaker.full-map-view-modes", {});
  const [mode, setModeDirect] = useState<FullMapViewMode>(
    savedModes[name] ?? initialMode
  );

  const setMode = function(mode: FullMapViewMode) {
    setSaveModes({
      ...savedModes,
      [file.viewerPolicy]: mode,
    });
    setModeDirect(mode);
  };

  return (
    <FullMapViewContext.Provider
      value={{
        mode,
        setMode,
        suggestions: suggestions ?? file.openingSuggestions,
      }}
    >
      {children}
    </FullMapViewContext.Provider>
  );
}

export function useFullMapViewContext(): FullMapViewContextValue {
  return useContext(FullMapViewContext);
}
