import React from "react";
import { useOpeningGroupContext } from "./OpeningGroupContext";

type OpeningDropShadowFilterProps = {
  dx?: number;
  dy?: number;
  blur?: number;
  color?: string;
  opacity?: number;
};

export default function OpeningDropShadowFilter({
  dx = 0,
  dy = 2,
  blur = 5,
  color = "#000000",
  opacity = 0.5,
}: OpeningDropShadowFilterProps) {
  const { scale } = useOpeningGroupContext();
  return (
    <feDropShadow
      dx={dx / scale}
      dy={dy / scale}
      stdDeviation={blur / scale}
      floodColor={color}
      floodOpacity={opacity}
    />
  );
}
