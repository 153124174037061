import {
  PropsWithChildren,
  useMemo,
  ButtonHTMLAttributes,
  MouseEventHandler,
} from "react";
import clsx from "clsx";
import { Loader } from "semantic-ui-react";
import "./MapmakerButton.css";

export interface IMapmakerButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "size"> {
  color?: "normal" | "accent" | "delete";
  size?: "large" | "medium" | "small";
  disabled?: boolean;
  loading?: boolean;
  fluid?: boolean;
  onClickWhileDisabled?: MouseEventHandler<HTMLButtonElement>;
}

export default function MapmakerButton({
  children,
  size = "medium",
  color = "normal",
  disabled = false,
  loading = false,
  fluid = false,
  onClickWhileDisabled = () => {},
  ...props
}: PropsWithChildren<IMapmakerButtonProps>) {
  const onClick = useMemo<MouseEventHandler<HTMLButtonElement>>(
    () => (disabled || loading ? onClickWhileDisabled : props.onClick),
    [disabled, loading, props.onClick, onClickWhileDisabled]
  );
  return (
    <button
      {...props}
      id="mapmaker-button"
      className={clsx(props.className, {
        [`color-${color}`]: true,
        [`size-${size}`]: true,
        disabled,
        loading,
        fluid,
      })}
      onClick={onClick}
    >
      <Loader className="button-loader" active size="tiny" inline />
      {children}
    </button>
  );
}
