import dev from "./MapmakerConfig.dev";
import type { MapmakerConfig } from ".";

const config:MapmakerConfig = {
  ...dev,
  stage: "local",
  isLocal: true,
  apiGateway: {
    // If you are debugging on a device just set the "Port Forwarding" options in Chrome. No need to
    // change this URLs.
    url: "http://localhost:4032",
  },
};

export default config;