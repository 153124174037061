import React from "react";
import { getOpeningsWithInputs } from "@mapmaker/core";
import { RenderableFileResource } from "@mapmaker/renderable";
import { getLayerByPriority, OpeningGroup, OpeningImages } from "../../opening";
import SvgNode from "../../SvgNode";
import { useMapmakerSvgContext } from "../../MapmakerSvgContext";

type MinimapProps = {
  file: RenderableFileResource;
  width: number;
  height: number;
  x?: number;
  y?: number;
  rotation?: number;
  completeColor?: string;
  incompleteColor?: string;
  openingIds?: string[];
  regionOutlineColor?: string;
};

/**
 * A small view of a file showing progress.
 */
export default function Minimap({
  file,
  height,
  width,
  x = 0,
  y = 0,
  rotation = 0,
  completeColor,
  incompleteColor = "#FFFFFF",
  regionOutlineColor = "#999999",
  openingIds,
}: MinimapProps) {
  const openings = getOpeningsWithInputs(file.design, file, {
    mustHaveImages: false,
    mustHaveInput: false,
  });
  const { svgScale } = useMapmakerSvgContext();
  const scale = Math.min(
    width / file.design.width,
    height / file.design.height
  );
  const cx = file.design.width / 2;
  const cy = file.design.height / 2;
  return (
    <g
      transform={`
    translate(${x - cx * scale} ${y - cy * scale})
    rotate(${rotation}, ${cx}, ${cy})`}
    >
      {openings.map(
        ({ feature: opening, featureId: openingId, input: openingInput }) => {
          const clipLayer = getLayerByPriority(opening, ["cut", "inner"]);
          const complete =
            openingInput?.images?.length > 0 &&
            (!openingIds || openingIds.includes(openingId));

          if (!clipLayer) {
            return null;
          }
          if (opening.enabled === false && !openingInput?.enabled) {
            return null;
          }

          return (
            <OpeningGroup
              key={openingId}
              opening={opening}
              openingInput={openingInput}
              scale={scale}
              x={clipLayer.bbox.x * scale}
              y={clipLayer.bbox.y * scale}
            >
              <SvgNode
                nodeData={clipLayer}
                style={{
                  fill: "none",
                  stroke: regionOutlineColor,
                  strokeWidth: 10 / svgScale,
                }}
              />
              {/* User-defined background for opening. */}
              {complete ? (
                completeColor ? (
                  <SvgNode
                    nodeData={clipLayer}
                    style={{ fill: completeColor }}
                  />
                ) : (
                  <OpeningImages
                    opening={opening}
                    openingInput={openingInput}
                    clipLayer={clipLayer}
                  />
                )
              ) : (
                <SvgNode
                  nodeData={clipLayer}
                  style={{ fill: incompleteColor }}
                />
              )}
            </OpeningGroup>
          );
        }
      )}
    </g>
  );
}
