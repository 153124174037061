import { Draft, original } from "immer";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useImmerReducer } from "use-immer";
import { FeatureInputMap, MapmakerBusinessId } from "@mapmaker/core";
import {
  ProjectOutputType,
  StickerTokenProductFragment,
  StickerTokenVariantFragment,
  StorefrontAttributeInput,
  StorefrontCheckoutLineItemInput,
  StorefrontMoneyV2,
  StorefrontProductPageProductFragment,
  StorefrontProductPageVariantFragment,
  StorefrontSelectedOption,
} from "../../client/MapmakerApi";
import {
  addMoneyV2,
  attributesToMap,
  getDefaultVariantSelection,
  getStickerTokensVariantFromProduct,
  getStickerTokensVariantMap,
  getVariantForSelectedOptions,
  IProductWithVariants,
  selectedOptionsToMap,
} from "../../lib";
import {
  getAvailableAttributes,
  getDefaultAttributes,
  getPlugin,
  GIFT_NOTE_KEY,
  ProductPlugin,
} from "./ProductPlugin";
import { useMapmakerAppConfig } from "../../client";

export type PersonalizationOption = {
  id: string;
  required?: boolean;
};

export type PersonalizationOptionGetter = (
  type: string
) => PersonalizationOption[];

type ProductPageState = Readonly<{
  product: StorefrontProductPageProductFragment;
  selectedVariant?: StorefrontProductPageVariantFragment;
  quantity: number;
  selectedOptions: StorefrontSelectedOption[];
  customAttributes: StorefrontAttributeInput[];
  addingToCart: boolean;
  addToCartError?: Error;
  showErrors: boolean;
  stickerTokenAddon?: StickerTokenVariantFragment;
  freeStickerTokens: number;
  // Mapmaker variables are allowed at both the product and variant level and will use the currently
  // selected variant with the product as a fallback.
  mapmakerBlueprintId?: string;
  mapmakerBackground?: string;
  mapmakerOutputScale?: number;
  mapmakerOutputType?: ProjectOutputType;
  mapmakerDefaultInputs?: FeatureInputMap;
}>;

export type ProductPageContextValue = ProductPageState & {
  dispatch: Dispatch<ProductPageAction>;
  plugin: ProductPlugin;
  giftNote: string;
  selectedOptionsMap: Record<string, string>;
  customAttributesMap: Record<string, string>;
  checkoutLineItems: StorefrontCheckoutLineItemInput[];
  canBeAddedToCart: boolean;
  totalPrice: StorefrontMoneyV2;
  setStickerTokenAddon(variant: StickerTokenVariantFragment);
  setSelectedOption(name: string, value: string);
  setCustomAttribute(key: string, value: string);
  setGiftNote(giftNote: string): any;
};

const ProductPageContext = createContext<ProductPageContextValue>(
  {} as ProductPageContextValue
);

export type ProductPageAction =
  | {
      type: "setProduct";
      product: StorefrontProductPageProductFragment;
    }
  | {
      type: "setProductLoading";
    }
  | {
      type: "setProductError";
      error: Error;
    }
  | {
      type: "setQuantity";
      quantity: number;
    }
  | {
      type: "setShowErrors";
      showErrors: boolean;
    }
  | {
      type: "setAddingToCart";
    }
  | {
      type: "setAddToCartError";
      error: Error;
    }
  | {
      type: "setStickerTokenAddon";
      variant: StickerTokenVariantFragment;
    }
  | {
      type: "setSelectedOptions";
      selectedOptions: StorefrontSelectedOption[];
    }
  | {
      type: "setCustomAttribute";
      key: string;
      value: string | null;
    };

function createReducer(plugins: Record<string, Partial<ProductPlugin>>) {
  return function(draft: Draft<ProductPageState>, action: ProductPageAction) {
    const previous = original(draft) as ProductPageState;

    // Used to clean up any custom attributes which don't apply to the current variant.
    function removeInvalidAttributes(
      plugin: ProductPlugin,
      selectedOptions: StorefrontSelectedOption[],
      customAttributes: StorefrontAttributeInput[]
    ): StorefrontAttributeInput[] {
      const availableAttributes = getAvailableAttributes(
        plugin,
        selectedOptions,
        customAttributes
      );

      const result = customAttributes.filter(customAttribute =>
        availableAttributes.some(o => o.key === customAttribute.key)
      );
      return result;
    }

    switch (action.type) {
      case "setProduct":
        draft.product = action.product;
        draft.selectedVariant =
          getDefaultVariantSelection(action.product) ?? undefined;
        draft.selectedOptions = draft.selectedVariant?.selectedOptions ?? [];
        break;
      case "setQuantity":
        draft.quantity = action.quantity;
        break;
      case "setSelectedOptions":
        draft.selectedOptions = action.selectedOptions;
        const newVariant = getVariantForSelectedOptions<
          IProductWithVariants<StorefrontProductPageVariantFragment>,
          StorefrontProductPageVariantFragment
        >(draft.product, action.selectedOptions);
        draft.selectedVariant = newVariant;

        draft.customAttributes = removeInvalidAttributes(
          getPlugin(
            plugins,
            newVariant.personalizationType?.value ??
              draft.product.personalizationType?.value
          ),
          action.selectedOptions,
          previous.customAttributes
        );
        break;
      case "setCustomAttribute":
        const attributeMap = attributesToMap(previous.customAttributes);
        const newAttributeMap = {
          ...attributeMap,
          [action.key]: action.value,
        };
        const newAttributes = Object.entries(newAttributeMap)
          .map(([key, value]) => ({ key, value }))
          .filter(attr => !!attr.value);
        const cleanNewAttributes = removeInvalidAttributes(
          getPlugin(
            plugins,
            draft.selectedVariant.personalizationType?.value ??
              draft.product.personalizationType?.value
          ),
          previous.selectedOptions,
          newAttributes
        );
        draft.customAttributes = cleanNewAttributes;
        break;
      case "setStickerTokenAddon":
        draft.stickerTokenAddon = action.variant;
        break;
      case "setAddingToCart":
        draft.addToCartError = undefined;
        draft.addingToCart = true;
        break;
      case "setAddToCartError":
        draft.addingToCart = false;
        draft.addToCartError = action.error;
        break;
      case "setShowErrors":
        draft.showErrors = action.showErrors;
        break;
      default:
        throw new Error();
    }
  };
}

type ProductPageContextProviderProps = PropsWithChildren<{
  product: StorefrontProductPageProductFragment;
  stickerTokensProduct: StickerTokenProductFragment;
  plugins: Record<string, Partial<ProductPlugin>>;
}>;

export function ProductPageContextProvider({
  product,
  stickerTokensProduct,
  plugins,
  children,
}: ProductPageContextProviderProps) {
  const { businessId } = useMapmakerAppConfig();
  const initialSelectedVariant = product.variants.edges[0].node;
  const initialPlugin = getPlugin(
    plugins,
    initialSelectedVariant.personalizationType?.value ??
      product.personalizationType?.value
  );
  const reducer = useMemo(() => createReducer(plugins), [plugins]);
  const [state, dispatch] = useImmerReducer<
    ProductPageState,
    ProductPageAction
  >(reducer, {
    product,
    quantity: 1,
    addingToCart: false,
    showErrors: false,
    selectedVariant: initialSelectedVariant,
    selectedOptions: initialSelectedVariant.selectedOptions,
    customAttributes: getDefaultAttributes(
      initialPlugin,
      initialSelectedVariant.selectedOptions
    ),
    freeStickerTokens: parseInt(product.freeStickerTokens?.value ?? "0"),
  });

  const mapmakerAttributes = useMemo(() => {
    function safeJsonParse<T>(
      value: string | undefined,
      defaultValue: T | undefined = undefined
    ) {
      if (!value) {
        return defaultValue;
      } else {
        try {
          return JSON.parse(value) as T;
        } catch {
          return defaultValue;
        }
      }
    }

    function safeFloatParse(
      value: string | undefined,
      defaultValue: number | undefined = undefined
    ): number | undefined {
      if (value === undefined) {
        return defaultValue;
      } else {
        try {
          return parseFloat(value);
        } catch {
          return defaultValue;
        }
      }
    }

    const mapmakerBlueprintId =
      state.selectedVariant?.mapmakerBlueprintId?.value ??
      state.product.mapmakerBlueprintId?.value ??
      state.product.variants.edges[0]?.node.mapmakerBlueprintId?.value;
    const mapmakerBackground =
      state.selectedVariant?.mapmakerBackground?.value ??
      state.product.mapmakerBackground?.value ??
      state.product.variants.edges[0]?.node.mapmakerBackground?.value;
    const mapmakerOutputType = (state.selectedVariant?.mapmakerOutputType
      ?.value ??
      state.product.mapmakerOutputType?.value ??
      state.product.variants.edges[0]?.node.mapmakerOutputType
        ?.value) as ProjectOutputType;
    const mapmakerOutputScale = safeFloatParse(
      state.selectedVariant?.mapmakerOutputScale?.value ??
        state.product.mapmakerOutputScale?.value ??
        state.product.variants.edges[0]?.node.mapmakerOutputScale?.value
    );
    const mapmakerDefaultInputs = safeJsonParse<FeatureInputMap>(
      state.selectedVariant?.mapmakerDefaultInputs?.value ??
        state.product.mapmakerDefaultInputs?.value ??
        state.product.variants.edges[0]?.node.mapmakerDefaultInputs?.value
    );

    return {
      mapmakerBlueprintId: qualifyBlueprintId(mapmakerBlueprintId, businessId),
      mapmakerBackground,
      mapmakerOutputType,
      mapmakerOutputScale,
      mapmakerDefaultInputs,
    };
  }, [state.selectedVariant, state.product]);

  const personalizationType =
    state.selectedVariant?.personalizationType?.value ??
    state.product.personalizationType?.value;

  const plugin = useMemo(() => getPlugin(plugins, personalizationType), [
    state.selectedVariant,
  ]);

  const selectedOptionsMap = useMemo(
    () => selectedOptionsToMap(state.selectedOptions),
    [state.selectedOptions]
  );

  const customAttributesMap = useMemo(
    () => attributesToMap(state.customAttributes),
    [state.customAttributes]
  );

  const setSelectedOption = useCallback(
    (name: string, value: string) => {
      const newOptionsMap = {
        ...selectedOptionsMap,
        [name]: value,
      };
      dispatch({
        type: "setSelectedOptions",
        selectedOptions: Object.entries(newOptionsMap).map(([name, value]) => ({
          name,
          value,
        })),
      });
    },
    [selectedOptionsMap]
  );

  const canBeAddedToCart = useMemo(() => {
    return (
      state.selectedVariant?.availableForSale &&
      plugin.validate({
        customAttributes: state.customAttributes,
        selectedOptions: state.selectedOptions,
        availableAttributes: getAvailableAttributes(
          plugin,
          state.selectedOptions,
          state.customAttributes
        ),
      })
    );
  }, [
    plugin,
    state.selectedOptions,
    state.customAttributes,
    state.selectedVariant,
  ]);

  const setCustomAttribute = useCallback(
    (key: string, value: string | null) =>
      dispatch({
        type: "setCustomAttribute",
        key,
        value,
      }),
    [dispatch]
  );

  const giftNote = customAttributesMap[GIFT_NOTE_KEY];
  const setGiftNote = useCallback((giftNote: string) => {
    setCustomAttribute(GIFT_NOTE_KEY, giftNote || null);
  }, []);

  const setStickerTokenAddon = useCallback(
    (variant?: StickerTokenVariantFragment) => {
      dispatch({
        type: "setStickerTokenAddon",
        variant,
      });
    },
    []
  );

  const checkoutLineItems = useMemo(() => {
    const items: StorefrontCheckoutLineItemInput[] = [];
    if (state.selectedVariant) {
      items.push({
        quantity: state.quantity,
        variantId: state.selectedVariant.id,
        customAttributes: state.customAttributes,
      });
    }
    if (state.stickerTokenAddon) {
      items.push({
        quantity: 1,
        variantId: state.stickerTokenAddon.id,
      });
    }
    if (state.freeStickerTokens) {
      const variant = getStickerTokensVariantFromProduct(
        stickerTokensProduct,
        state.freeStickerTokens
      );
      items.push({
        quantity: state.quantity,
        variantId: variant.id,
      });
    }
    return items;
  }, [
    stickerTokensProduct,
    state.quantity,
    state.selectedVariant,
    state.customAttributes,
    state.stickerTokenAddon,
    state.freeStickerTokens,
  ]);

  const totalPrice = useMemo(() => {
    if (state.selectedVariant && state.stickerTokenAddon) {
      return addMoneyV2(
        state.selectedVariant.priceV2,
        state.stickerTokenAddon.priceV2
      );
    } else if (state.selectedVariant) {
      return state.selectedVariant.priceV2;
    } else {
      return null;
    }
  }, [state.selectedVariant, state.stickerTokenAddon]);

  return (
    <ProductPageContext.Provider
      value={{
        dispatch,
        ...state,
        ...mapmakerAttributes,
        plugin,
        canBeAddedToCart,
        selectedOptionsMap,
        customAttributesMap,
        checkoutLineItems,
        giftNote,
        totalPrice,
        setSelectedOption,
        setCustomAttribute,
        setGiftNote,
        setStickerTokenAddon,
      }}
    >
      {children}
    </ProductPageContext.Provider>
  );
}

function qualifyBlueprintId(
  id: string | undefined,
  businessId: MapmakerBusinessId
): string | undefined {
  if (!id) {
    return undefined;
  } else if (!id.startsWith(`${businessId}.`)) {
    return `${businessId}.${id}`;
  } else {
    return id;
  }
}

export function useProductPage() {
  return useContext<ProductPageContextValue>(ProductPageContext);
}
