import * as Sentry from "@sentry/react";
import { produce, Draft } from "immer";
import deepEqual from "fast-deep-equal";
import { MapmakerProjectStore } from "./projectStore";
import { updateFile } from "../../client/saveUtils";
import { addMessage } from "../shared/messageReducer";
import {
  MapmakerFileViewerPolicy,
  MapmakerFileHeavyFragment,
  ProjectOutputOption,
  MapmakerFileAccessPolicy,
  MapmakerFileAccessFragment,
  OpeningSuggestionFragment,
} from "../../client/MapmakerApi";
import {
  fileLoadedFromServer,
  FILE_LOADED_FROM_SERVER,
} from "./serverFileReducer";
import {
  FeatureInput,
  FeatureInputMap,
  mapOpeningImages,
  TimezoneIndependentDate,
} from "@mapmaker/core";
import { trackGtmEvent } from "../../lib/gtm";
import { UploadsState } from "./uploadsReducer";

export type MapmakerFileState = MapmakerFileHeavyFragment & {
  isDirty: boolean;
  saving: boolean;
  updateErrorCounter: number;
};

function getInitialFileState(
  file: MapmakerFileHeavyFragment
): MapmakerFileState {
  return {
    ...file,
    isDirty: false,
    saving: false,
    updateErrorCounter: 0,
  };
}

const UPDATE_FILE_START = "file.update_file_start";
const UPDATE_FILE_FAILED = "file.update_file_failed";
const ADD_FILE_ACCESS = "file.update_file_accesses";
const DELETE_FILE_ACCESS = "file.delete_file_accesses";
const ADD_OPENING_SUGGESTION = "file.add_opening_suggestion";
const UPDATE_OPENING_SUGGESTION = "file.update_opening_suggestion";
const DELETE_OPENING_SUGGESTION = "file.delete_opening_suggestion";
const UPDATE_FILE_NAME = "file.update_file_name";
const UPDATE_ACCESS_POLICY = "file.update_access_policy";
const SET_OUTPUT_OPTION = "file.project_output_type";
const UPDATE_FEATURE_INPUT = "file.update_feature_input";
const UPDATE_TIME_TAKEN_USER = "file.update_time_taken_user";

export function saveFile(force: boolean = false) {
  return async function save(dispatch, getState) {
    const state = getState() as MapmakerProjectStore;
    if (state.file.viewerPolicy != MapmakerFileViewerPolicy.Owner) {
      return;
    }

    console.info(`Begin save. File Updated at: ${state.file.updatedAt}`);

    // If we're already saving something bail out now.
    if (state.file.saving) {
      console.info("Skipping save because save already in progress.");
      return;
    }

    // If any images are still uploading we don't want to save them yet. Saving an incomplete image
    // causes problems if the upload fails.
    const incompleteUploads = state.uploads.filter(
      (upload) => upload.status !== "COMPLETED"
    );

    const inputsToSave = removeUploadingImages(
      state.file.inputs,
      incompleteUploads
    );

    const featureInputsHaveChanged = !deepEqual(
      inputsToSave,
      state.serverFile.inputs
    );

    // If the state equals what the server has then we don't need to update.
    if (!force && !state.file.isDirty) {
      console.info("Skipping save because no changes were detected.");
      return;
    }

    dispatch(updateFileStart());

    try {
      const updatedFile = await updateFile({
        fileId: state.file.id,
        fileUpdatedAt: state.file.updatedAt,
        name: state.file.name,
        outputType: state.file.outputType,
        outputScale: state.file.outputScale,
        accessPolicy: state.file.accessPolicy,
        inputs: featureInputsHaveChanged ? inputsToSave : undefined,
      });
      console.info(`File ${state.file.id} saved.`);
      dispatch(fileLoadedFromServer(updatedFile));
    } catch (e) {
      console.warn(
        `Save file failed. State's File updated at: ${state.file.updatedAt}`
      );
      Sentry.captureException(e);
      dispatch(
        addMessage({
          type: "error",
          content: `File failed to save: ${e.message}`,
        })
      );
      dispatch(updateFileFailed());
    }
  };
}

function removeUploadingImages(
  inputs: FeatureInputMap,
  uploads: UploadsState
): FeatureInputMap {
  const mapped = mapOpeningImages(
    { inputs },
    (image) => {
      const upload = uploads.find((upload) => upload.key === image.id);
      if (upload && upload.status !== "COMPLETED") {
        return;
      }
      return image;
    },
    "ALLOW_DELETION"
  );
  return mapped.inputs;
}

function updateFileStart() {
  return {
    type: UPDATE_FILE_START,
  };
}

function updateFileFailed() {
  return {
    type: UPDATE_FILE_FAILED,
  };
}

export function updateFileName(name: string) {
  return {
    type: UPDATE_FILE_NAME,
    name,
  };
}

export function setOutputOption(outputOption: ProjectOutputOption) {
  return {
    type: SET_OUTPUT_OPTION,
    outputOption,
  };
}

export function updateAccessPolicy(accessPolicy: MapmakerFileAccessPolicy) {
  return {
    type: UPDATE_ACCESS_POLICY,
    accessPolicy,
  };
}

export function addFileAccess(fileAccess: MapmakerFileAccessFragment) {
  return {
    type: ADD_FILE_ACCESS,
    fileAccess,
  };
}

export function deleteFileAccess(
  fileAccess: Pick<MapmakerFileAccessFragment, "id">
) {
  return {
    type: DELETE_FILE_ACCESS,
    fileAccess,
  };
}

export function addOpeningSuggestion(
  openingSuggestion: OpeningSuggestionFragment
) {
  return {
    type: ADD_OPENING_SUGGESTION,
    openingSuggestion,
  };
}

export function updateOpeningSuggestion(
  openingSuggestion: MapmakerFileHeavyFragment["openingSuggestions"][0]
) {
  return {
    type: UPDATE_OPENING_SUGGESTION,
    openingSuggestion,
  };
}

export function deleteOpeningSuggestion(openingSuggestionId: string) {
  return {
    type: DELETE_OPENING_SUGGESTION,
    openingSuggestionId,
  };
}

export function updateFeatureInput(
  featureId: string,
  featureInput: FeatureInput
) {
  return {
    type: UPDATE_FEATURE_INPUT,
    featureId,
    featureInput,
  };
}

export function updateOpeningImageTimeTakenUser(
  openingId: string,
  imageId: string,
  timeTakenUser: TimezoneIndependentDate
) {
  return {
    type: UPDATE_TIME_TAKEN_USER,
    openingId,
    imageId,
    timeTakenUser,
  };
}

export type MapmakerFileCreatedCallback = (fileId: string) => any;

export default function createFileReducer(file: MapmakerFileHeavyFragment) {
  return produce((draft: Draft<MapmakerFileState>, action) => {
    if (draft === undefined) {
      return getInitialFileState(file);
    }
    switch (action.type) {
      // Whenever the file is reloaded from the server we want to update most of the state right
      // away to avoid drift. However, we don't want to overwrite changes that were/are being made
      // by the user at that moment.
      case FILE_LOADED_FROM_SERVER:
        draft.saving = false;
        draft.updateErrorCounter = 0;
        draft.id = action.file.id;
        draft.name = action.file.name;
        draft.accessPolicy = action.file.accessPolicy;
        draft.viewerPolicy = action.file.viewerPolicy;
        draft.fileAccesses = action.file.fileAccesses;
        // TODO - Merge inputs in case things were changed elsewhere?
        //draft.inputs = action.file.inputs;
        draft.createdAt = action.file.createdAt;
        draft.updatedAt = action.file.updatedAt;
        console.info(`Setting local file's updatedAt to: ${draft.updatedAt}`);
        break;
      case UPDATE_FILE_START:
        draft.isDirty = false;
        draft.saving = true;
        break;
      case UPDATE_FILE_FAILED:
        draft.isDirty = true;
        draft.saving = false;
        draft.updateErrorCounter++;
        break;
      case UPDATE_FILE_NAME:
        draft.isDirty = true;
        draft.name = action.name;
        break;
      case SET_OUTPUT_OPTION:
        draft.isDirty = true;
        draft.outputType = action.outputOption.type;
        draft.outputScale = action.outputOption.scale;
        Object.entries(action.outputOption.inputs).forEach(
          ([featureId, featureInput]) => {
            draft.inputs[featureId] = featureInput as FeatureInput;
          }
        );
        break;
      case UPDATE_ACCESS_POLICY:
        draft.isDirty = true;
        draft.accessPolicy = action.accessPolicy;
        trackGtmEvent({
          event: "mapmaker.change-project-access-policy",
          policy: action.accessPolicy,
        });
        break;
      case ADD_FILE_ACCESS:
        draft.fileAccesses.push(action.fileAccess);
        break;
      case DELETE_FILE_ACCESS:
        draft.fileAccesses = draft.fileAccesses.filter(
          (fileAccess) => fileAccess.id !== action.fileAccess.id
        );
        break;
      case ADD_OPENING_SUGGESTION:
        draft.openingSuggestions.push(action.openingSuggestion);
        break;
      case UPDATE_OPENING_SUGGESTION:
        draft.openingSuggestions = draft.openingSuggestions.map(
          (suggestion) => {
            if (suggestion.id === action.openingSuggestion.id) {
              return action.openingSuggestion;
            } else {
              return suggestion;
            }
          }
        );
        break;
      case DELETE_OPENING_SUGGESTION:
        draft.openingSuggestions = draft.openingSuggestions.filter(
          (suggestion) => suggestion.id !== action.openingSuggestionId
        );
        break;
      case UPDATE_FEATURE_INPUT:
        draft.isDirty = true;
        draft.inputs[action.featureId] = { ...action.featureInput };
        draft.inputs[action.featureId].modifiedAt = new Date().toISOString();
        break;
      case UPDATE_TIME_TAKEN_USER:
        const feature = draft.inputs[action.openingId];
        if (feature.type === "OPENING") {
          draft.isDirty = true;
          feature.modifiedAt = new Date().toISOString();
          feature.images.forEach((image) => {
            if (image.id === action.imageId || !action.imageId) {
              image.timeTakenUser = action.timeTakenUser;
            }
          });
        }
        break;
      default:
        return draft;
    }
  });
}
