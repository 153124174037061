import { ReactNode, useContext, useState, createContext } from "react";
import "./MapmakerModalFix.css";

type MapmakerModalContext = {
  modalRoot?: HTMLElement;
  setModalRoot(root: HTMLElement): any;
  onModalChange?(open: boolean): any;
};

const MapmakerModalContext = createContext<MapmakerModalContext>(
  {} as MapmakerModalContext
);

type MapmakerModalProviderProps = {
  children: ReactNode;
  onModalChange?(open: boolean): any;
};

export const MapmakerModalProvider = function({
  onModalChange,
  children,
}: MapmakerModalProviderProps) {
  const [modalRoot, setModalRootDirect] = useState<HTMLElement>();

  function setModalRoot(modalRoot: HTMLElement) {
    if (modalRoot) {
      modalRoot.classList.add("mapmaker-modal-fix");
    }
    setModalRootDirect(modalRoot);
  }

  return (
    <MapmakerModalContext.Provider
      value={{
        onModalChange,
        modalRoot,
        setModalRoot,
      }}
    >
      {children}
    </MapmakerModalContext.Provider>
  );
};

export default function useMapmakerModals() {
  return useContext(MapmakerModalContext);
}
