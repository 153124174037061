import React from "react";
import { CutSvgProps } from "@mapmaker/renderable";
import { getAllOpeningsLayerPath } from "../opening/openingLib";

export default function CutSvg({
  design,
  layer,
  shrinkBorder = 0,
}: CutSvgProps<"resource">) {
  function getViewBox() {
    return [0, 0, design.width, design.height];
  }

  const cutPath = getAllOpeningsLayerPath(design, layer, shrinkBorder);
  const style = {
    fill: "#CCCCCC",
    fillRule: "evenodd",
    clipRule: "evenodd",
    stroke: "#000000",
    strokeWidth: "1pt",
  } as React.CSSProperties;
  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={getViewBox().join(" ")}
      width={design.width}
      height={design.height}
    >
      {/* The python Blender script relies on this having an ID of "cuts". */}
      <path id="cuts" d={cutPath} style={style}></path>
    </svg>
  );
}
