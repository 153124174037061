import { PropsWithChildren } from "react";
import { Link, Navigate } from "react-router-dom";
import Suspender from "react-suspender";
import { LinkButton } from "@mapmaker/ui";
import { gql } from "@apollo/client";
import ErrorPage from "../../lib/errors/ErrorPage";
import {
  ProductPageContextProvider,
  useProductPage,
} from "./ProductPageContext";
import "./MapmakerProductPage.css";
import { useStorefrontGetProductByHandleQuery } from "../../client/MapmakerApi";
import { useMapmakerTitle } from "../../lib/hooks/useMapmakerTitle";
import ProductPageThumbnails from "./thumbnails/ProductPageThumbnails";
import ProductBuyBox from "./ProductBuyBox";
import { ProductPlugin } from "./ProductPlugin";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { FaTag } from "@react-icons/all-files/fa/FaTag";
import isSameDay from "date-fns/isSameDay";

gql`
  fragment StorefrontProductPageProduct on StorefrontProduct {
    id
    title
    descriptionHtml
    handle
    tags
    priceRange {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    options {
      id
      name
      values
    }
    images(first: 30) {
      edges {
        node {
          ...StorefrontProductImage
        }
      }
    }
    variants(first: 100) {
      edges {
        node {
          ...StorefrontProductPageVariant
        }
      }
    }
    hideQuantity: metafield(namespace: "photomaps", key: "hideQuantity") {
      key
      value
    }
    personalizationType: metafield(
      namespace: "photomaps"
      key: "personalizationType"
    ) {
      key
      value
    }
    freeStickerTokens: metafield(
      namespace: "photomaps"
      key: "freeStickerTokens"
    ) {
      key
      value
    }
    mapmakerBlueprintId: metafield(namespace: "mapmaker", key: "blueprintId") {
      key
      value
    }
    mapmakerBackground: metafield(namespace: "mapmaker", key: "background") {
      key
      value
    }
    mapmakerOutputType: metafield(namespace: "mapmaker", key: "outputType") {
      key
      value
    }
    mapmakerOutputScale: metafield(namespace: "mapmaker", key: "outputScale") {
      key
      value
    }
    mapmakerDefaultInputs: metafield(
      namespace: "mapmaker"
      key: "defaultInputs"
    ) {
      key
      value
    }
  }

  fragment StorefrontProductPageVariant on StorefrontProductVariant {
    id
    sku
    title
    priceV2 {
      amount
      currencyCode
    }
    availableForSale
    image {
      ...StorefrontProductImage
    }
    selectedOptions {
      name
      value
    }
    personalizationType: metafield(
      namespace: "photomaps"
      key: "personalizationType"
    ) {
      key
      value
    }
    mapmakerBlueprintId: metafield(namespace: "mapmaker", key: "blueprintId") {
      key
      value
    }
    mapmakerBackground: metafield(namespace: "mapmaker", key: "background") {
      key
      value
    }
    mapmakerOutputType: metafield(namespace: "mapmaker", key: "outputType") {
      key
      value
    }
    mapmakerOutputScale: metafield(namespace: "mapmaker", key: "outputScale") {
      key
      value
    }
    mapmakerDefaultInputs: metafield(
      namespace: "mapmaker"
      key: "defaultInputs"
    ) {
      key
      value
    }
  }

  fragment StorefrontProductImage on StorefrontImage {
    id
    altText
    tinySrc: transformedSrc(maxWidth: 256)
    mediumSrc: transformedSrc(maxWidth: 1024)
    largeSrc: transformedSrc(maxWidth: 2048)
  }

  query storefrontGetProductByHandle($handle: String!) {
    product: storefrontProductByHandle(handle: $handle) {
      ...StorefrontProductPageProduct
    }
    stickerTokenProduct: storefrontProduct(handle: "sticker-tokens") {
      ...StickerTokenProduct
    }
  }
`;

type MapmakerProductPageProps = {
  handle: string;
  plugins?: Record<string, Partial<ProductPlugin>>;
};

export default function MapmakerProductPage({
  handle,
  plugins = {},
  children,
}: PropsWithChildren<MapmakerProductPageProps>) {
  const { loading, data, error } = useStorefrontGetProductByHandleQuery({
    variables: {
      handle: handle as string,
    },
  });

  if (!handle) {
    return <Navigate to="../" />;
  } else if (loading) {
    return <Suspender />;
  } else if (error || !data?.product) {
    // Before Apr. 2022 our most popular products had separate pages for light and dark. So those
    // URL's are floating around quite a bit still. Try to redirect in that case to the unified
    // handle.
    if (/(-light|-dark)$/i.test(handle)) {
      return (
        <Navigate to={`/product/${handle.replace(/(-light|-dark)$/i, "")}`} />
      );
    }
    return (
      <ErrorPage>
        <div style={{ textAlign: "center" }}>
          <div style={{ marginBottom: "1rem" }}>
            {error?.message ?? "Could not find product."}
          </div>
          <LinkButton to="/shop" color="accent">
            Browse Our Products
          </LinkButton>
        </div>
      </ErrorPage>
    );
  }

  return (
    <ProductPageContextProvider
      product={data.product}
      stickerTokensProduct={data.stickerTokenProduct}
      plugins={plugins}
    >
      <ProductPageWithContext>{children}</ProductPageWithContext>
    </ProductPageContextProvider>
  );
}

function getSaleName() {
  const isBlackFriday = isSameDay(new Date(2022, 10, 25), new Date());
  const isCyberMonday = isSameDay(new Date(2022, 10, 28), new Date());

  if (isBlackFriday) {
    return "BLACK FRIDAY";
  } else if (isCyberMonday) {
    return "CYBER MONDAY";
  } else {
    return "HOLIDAY";
  }
}

function ProductPageWithContext({ children }: PropsWithChildren<{}>) {
  const { product, freeStickerTokens } = useProductPage();
  useMapmakerTitle(product.title);

  return (
    <>
      {freeStickerTokens > 0 && (
        <div id="product-page-promotion">
          <div className="promotion-container">
            <h3 className="title">
              <FaTag /> {getSaleName()} SALE!
            </h3>
            <div className="description">
              This week only get{" "}
              {freeStickerTokens === 1
                ? "1 FREE Sticker Tokens"
                : `${freeStickerTokens} FREE Sticker Tokens`}{" "}
              with the purchase of this {product.title}. Sticker Tokens are a
              great gift addition, or you can to use them on yourself!
            </div>
            <div className="fine-print">
              <div className="">Offer ends 11/30/2022</div>
              <Link to="/help/sticker-token-promotion">
                Details
                <FaChevronRight fontSize={8} />
              </Link>
            </div>
          </div>
        </div>
      )}
      <div id="mapmaker-product-page">
        <div className="above-the-fold">
          <section className="images">
            <ProductPageThumbnails />
          </section>
          <section className="options">
            <ProductBuyBox />
          </section>
        </div>
      </div>
      {children}
    </>
  );
}
