import { getOpeningFeature } from "@mapmaker/core";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ZoomPan from "zoom-pan";
import { useMapmakerAppConfig } from "../../../../../client";
import {
  OpeningSuggestionFragment,
  OpeningSuggestionStatus,
} from "../../../../../client/MapmakerApi";
import { SvgContextProvider } from "../../../../../lib/svg/useSvgContext";
import { viewBoxForBBox } from "../../../../../lib/svg/ViewboxUtils";
import MapmakerLayout from "../../../../layout/MapmakerLayout";
import MapmakerTopBar from "../../../../layout/MapmakerTopBar";
import ProjectToolbar from "../../../ProjectToolbar";
import OpeningSuggestionListItem from "../../../collaboration/OpeningSuggestionListItem";
import { useProjectDesign, useProjectFile } from "../../../useProjectState";
import { ReadOnlyOpeningDirect } from "../ReadOnlyOpening";
import useOpening from "../useOpening";
import FeatureExitButton from "../../../shared/FeatureExitButton";
import "./OpeningSuggestionReviewView.css";

export default function OpeningSuggestionReviewView() {
  const { suggestionId } = useParams<{ suggestionId: string }>();
  const file = useProjectFile();
  const suggestion = useMemo(
    () =>
      file.openingSuggestions.find(
        suggestion => suggestion.id === suggestionId
      ),
    [file]
  );
  return <OpeningSuggestionReviewViewWithContext suggestion={suggestion} />;
}

type OpeningSuggestionReviewViewWithContextProps = {
  suggestion: OpeningSuggestionFragment;
};

export function OpeningSuggestionReviewViewWithContext({
  suggestion,
}: OpeningSuggestionReviewViewWithContextProps) {
  const { gotoEditFile } = useMapmakerAppConfig();
  const design = useProjectDesign();
  const file = useProjectFile();
  const openingFeature = getOpeningFeature(design, suggestion.openingId);
  const { openingInput } = useOpening(suggestion.openingId);
  const isHidden = suggestion.status === OpeningSuggestionStatus.Hidden;
  const suggestionOpeningInput = {
    ...openingInput,
    ...suggestion.input,
  };
  const bbox = openingFeature.outlined
    ? openingFeature.layout.cut.bbox
    : openingFeature.layout.print?.bbox ||
      openingFeature.layout.outer?.bbox ||
      openingFeature.layout.cut.bbox;
  const viewbox = viewBoxForBBox(bbox, "3%");
  const [showCurrent, setShowCurrent] = useState(false);

  return (
    <MapmakerLayout>
      <MapmakerLayout.TopBar>
        <MapmakerTopBar
          breadcrumbs={[
            {
              label: "Collaborate",
              onClick: () => gotoEditFile(file.id, "collaborate"),
            },
            { label: "Review Suggestion" },
          ]}
        />
      </MapmakerLayout.TopBar>
      <MapmakerLayout.Main
        style={{ overflowY: "hidden" }}
        nav={<ProjectToolbar selected="collaborate" />}
        className="opening-suggestion-review-view"
      >
        <MapmakerLayout.MainContextPanel>
          {isHidden && <div className="hidden-banner">HIDDEN</div>}
          <OpeningSuggestionListItem
            mode="standalone"
            suggestion={suggestion}
            onClick={() => {}}
          />
        </MapmakerLayout.MainContextPanel>
        <MapmakerLayout.MainContent>
          {/*
          {openingInput.images.length > 0 && (
            <div
              className="view-toggle"
              onMouseOver={() => setShowCurrent(true)}
              onTouchStart={() => setShowCurrent(true)}
              onMouseLeave={() => setShowCurrent(false)}
              onTouchEnd={() => setShowCurrent(false)}
            >
              <FaEye className="icon" /> Current
            </div>
          )}
          */}
          <ZoomPan>
            <SvgContextProvider viewbox={viewbox} flex>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewbox.join(" ")}
                width="100%"
                height="100%"
              >
                <g opacity={showCurrent ? 0 : 1}>
                  <ReadOnlyOpeningDirect
                    openingInput={suggestionOpeningInput}
                    openingFeature={openingFeature}
                  />
                </g>
                <g opacity={showCurrent ? 1 : 0}>
                  <ReadOnlyOpeningDirect
                    openingInput={openingInput}
                    openingFeature={openingFeature}
                  />
                </g>
              </svg>
            </SvgContextProvider>
          </ZoomPan>
          <FeatureExitButton
            onClick={() => gotoEditFile(file.id, "collaborate")}
          />
        </MapmakerLayout.MainContent>
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}
