import { produce } from "immer";
import { RectangularPrintOptions } from "@mapmaker/core";
import { LocalItem } from "../../../lib/browser/typedLocalStorage";

type SetOptionsAction = {
  type: string;
  options: Partial<RectangularPrintOptions>;
};

const localItem = LocalItem<RectangularPrintOptions>(
  "mapmaker.rectangularPrintOptions",
  1
);

const SET_OPTIONS = "print_options.set_options";

export function setBackgroundColor(value: string): SetOptionsAction {
  return {
    type: SET_OPTIONS,
    options: {
      backgroundColor: value,
    },
  };
}

export function setDrawBorder(value: boolean): SetOptionsAction {
  return {
    type: SET_OPTIONS,
    options: {
      borderWidth: value ? 2 : 0,
    },
  };
}

export function setIncludeBuffer(value: boolean): SetOptionsAction {
  return {
    type: SET_OPTIONS,
    options: {
      includeBuffer: value,
    },
  };
}

export function setPrintingGuides(value: boolean): SetOptionsAction {
  return {
    type: SET_OPTIONS,
    options: {
      printingGuides: value,
    },
  };
}

const initialState: RectangularPrintOptions = {
  backgroundColor: "#FFFFFF",
  borderColor: "#000000",
  borderWidth: 2,
  clipImage: true,
  includeBuffer: true,
  printingGuides: false,
};

export default function createPrintOptionsReducer(allowBufferArea = true) {
  return produce(
    (draft: RectangularPrintOptions, action): RectangularPrintOptions => {
      if (draft === undefined) {
        const localOptions = localItem.get();
        const initial = localOptions || {
          ...initialState,
          includeBuffer: allowBufferArea,
        };
        if (!allowBufferArea) {
          initial.includeBuffer = false;
        }
        return initial;
      }

      switch (action.type) {
        case SET_OPTIONS:
          Object.keys(action.options).forEach((key) => {
            draft[key] = action.options[key];
          });
          localItem.put(draft);
          break;
        default:
          break;
      }
    }
  );
}
