import {
  FileAccessRole,
  MapmakerFileAccessPolicy,
} from "../../../client/MapmakerApi";
import { useProjectFile } from "../useProjectState";

export default function useSharingStatus() {
  const file = useProjectFile();
  const hasCollaborators = file.fileAccesses.some(
    fileAccess => fileAccess.role === FileAccessRole.Collaborator
  );
  const everyoneCanCollaborate =
    file.accessPolicy === MapmakerFileAccessPolicy.PublicCollaborators;

  const nobodyCanCollaborate = !hasCollaborators && !everyoneCanCollaborate;

  return {
    hasCollaborators,
    everyoneCanCollaborate,
    nobodyCanCollaborate,
  };
}
