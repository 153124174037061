import { getClient, useMapmakerContext } from "./MapmakerContext";
import { v4 as uuidv4 } from "uuid";
import { captureException } from "@sentry/react";
import { PresignedPost, PresignedImageUploadDocument } from "./MapmakerApi";

// A slight buffer so that the presigned URL doesn't expire between when we check it and when we start the upload.
const EXPIRATION_BUFFER = 60 * 1000;
let cachedPresignedImageUpload = null;
let cachedPresignedImageUploadIdentityId = null;

function setPresignedImageUpload(
  presignedImageUpload: PresignedPost,
  identityId: string
) {
  cachedPresignedImageUpload = presignedImageUpload;
  cachedPresignedImageUploadIdentityId = identityId;
}

async function getNewPresignedImageUpload() {
  const client = getClient();
  const { data: { presignedImageUpload = null } = {} } = await client.mutate({
    mutation: PresignedImageUploadDocument,
  });
  return presignedImageUpload;
}

async function getPresignedImageUpload(identityId: string) {
  if (
    identityId !== cachedPresignedImageUploadIdentityId ||
    !cachedPresignedImageUpload ||
    new Date(cachedPresignedImageUpload.expires).getTime() - EXPIRATION_BUFFER <
      new Date().getTime()
  ) {
    const presignedImageUpload = await getNewPresignedImageUpload();
    setPresignedImageUpload(presignedImageUpload, identityId);
  }
  return cachedPresignedImageUpload;
}

export function useUploadBase64Image() {
  const { identityId } = useMapmakerContext();

  function getUploadKey(extension: string): string {
    return `${identityId}/uploads/${uuidv4()}.${extension}`;
  }

  async function upload(key: string, dataUrl: string) {
    try {
      const mimeType = dataUrl
        .split(",")[0]
        .match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0];
      const presignedPost = await getPresignedImageUpload(identityId);
      const doUpload = new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(presignedPost.fields).forEach(fieldKey => {
          formData.append(fieldKey, presignedPost.fields[fieldKey]);
        });
        formData.append("Key", key);
        formData.append("Content-Type", mimeType);
        formData.append("acl", "public-read");

        // Actual file has to be appended last.
        formData.append("file", dataURItoBlob(dataUrl));

        const xhr = new XMLHttpRequest();
        xhr.open("POST", presignedPost.url, true);
        xhr.send(formData);
        xhr.onload = function() {
          this.status === 204
            ? resolve(this.responseText)
            : reject(this.responseText);
        };
      });

      await doUpload;
      return key;
    } catch (e) {
      console.log(e);
      console.log("Current Date: ", new Date());
      console.log(
        "Current Timezone",
        Intl.DateTimeFormat().resolvedOptions().timeZone
      );
      captureException(e);
      throw new Error("There was an issue uploading your image.");
    }
  }

  return {
    upload,
    getUploadKey,
  };
}

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}
