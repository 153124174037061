import clsx from "clsx";
import {
  Fragment,
  KeyboardEvent,
  FocusEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
} from "semantic-ui-react";
import { useMapmakerAppConfig } from "../../client";
import { saveFile, updateFileName } from "../project/fileReducer";
import { useProjectDispatch } from "../project/projectStore";
import { useProjectFile } from "../project/useProjectState";
import "./MapmakerTopBar.css";

export type MapmakerBreadcrumb = {
  label: string;
  onClick?(): void;
};

export type MapmakerTopBarMode = "suggestions" | "normal";

export interface IMapmakerTopBarProps {
  breadcrumbs: MapmakerBreadcrumb[];
  before?: ReactNode;
  after?: ReactNode;
  mode?: MapmakerTopBarMode;
}

export default function MapmakerTopBar({
  breadcrumbs,
  before = null,
  after = null,
  mode = "normal",
}: IMapmakerTopBarProps) {
  const file = useProjectFile();
  const dispatch = useProjectDispatch();
  const { gotoEditFile } = useMapmakerAppConfig();

  function onChangeFilename(fileName) {
    if (fileName != file.name) {
      dispatch(updateFileName(fileName));
      dispatch(saveFile(true));
    }
  }

  const fileNameBreadcrumb = useMemo(() => {
    if (
      breadcrumbs.length === 0 &&
      file.viewerPolicy === "OWNER" &&
      mode === "normal"
    ) {
      return (
        <EditableBreadcrumb
          label={file.name}
          onChange={onChangeFilename}
          disabled={file.saving}
        />
      );
    } else if (breadcrumbs.length > 0) {
      return (
        <BreadcrumbSection
          onClick={() => gotoEditFile(file.id, file.designId)}
          style={{ flexShrink: 10 }}
        >
          {file.name}
        </BreadcrumbSection>
      );
    } else {
      return <BreadcrumbSection>{file.name}</BreadcrumbSection>;
    }
  }, [file, breadcrumbs, onChangeFilename]);

  return (
    <div id="mapmaker-top-bar" className={`mode-${mode}`}>
      <div className="secondary">{before}</div>
      <Breadcrumb size="large">
        {fileNameBreadcrumb}
        {breadcrumbs.map((breadcrumb, i) => {
          return (
            <Fragment key={i}>
              <BreadcrumbDivider />
              <BreadcrumbSection
                onClick={breadcrumb.onClick}
                style={{ flexShrink: i + 1 === breadcrumbs.length ? 0 : 10 }}
              >
                {breadcrumb.label}
              </BreadcrumbSection>
            </Fragment>
          );
        })}
      </Breadcrumb>
      <div className="secondary">{after}</div>
    </div>
  );
}

export function EditableBreadcrumb({ label, onChange, disabled }) {
  const [input, setInput] = useState<string>(label);

  /* If the label changes from higher up we need to update it here. */
  useEffect(() => {
    if (label != input) {
      setInput(label);
    }
  }, [label]);

  function onBlur(e: FocusEvent<HTMLInputElement>) {
    if (input.trim() === "") {
      setInput(label);
    } else {
      onChange(input);
    }
  }

  function onKeypress(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  }

  return (
    <div key="editable-breadcrumb" className="editable-breadcrumb">
      <input
        value={input}
        onKeyPress={onKeypress}
        onChange={e => setInput(e.target.value)}
        onBlur={onBlur}
        maxLength={64}
        disabled={disabled}
      />
      {/** If there is no input we just use a "." */}
      <div className="ghost-input">{input}.</div>
    </div>
  );
}
