import {
  getOpeningImagesDateRange,
  getTimezoneIndependentDate,
  OpeningInput,
  TimezoneIndependentDate,
} from "@mapmaker/core";
import DatePicker from "react-datepicker";
import { setTimeTakenUser } from "../features/opening/editable/editableOpeningImageReducer";
import useOpening from "../features/opening/useOpening";
import {
  updateFeatureInput,
  updateOpeningImageTimeTakenUser,
} from "../fileReducer";
import { useProjectDispatch } from "../projectStore";
import "./TimelineDateInput.css";

type DateInputProps = {
  openingId: string;
  openingImageId?: string;
  inline?: boolean;
  onlyUserDate?: boolean;
};

export default function TimelineDateInput({
  openingId,
  openingImageId,
  inline,
  onlyUserDate = false,
}: DateInputProps) {
  const { openingInput } = useOpening(openingId);
  const range = getOpeningImagesDateRange(openingInput);
  const projectDispatch = useProjectDispatch();
  const openingImage = openingImageId
    ? openingInput.images.find(i => i.id === openingImageId)
    : undefined;
  const selectedDate = onlyUserDate
    ? openingImage.timeTakenUser
      ? new Date(...openingImage.timeTakenUser)
      : null
    : new Date(...range.first);
  console.log(selectedDate);
  function onDateChange(date: Date) {
    const timezoneIndependentDate = getTimezoneIndependentDate(date);
    projectDispatch(
      updateOpeningImageTimeTakenUser(
        openingId,
        openingImageId,
        timezoneIndependentDate
      )
    );
  }

  return (
    <DatePicker
      selected={selectedDate}
      placeholderText="Unknown"
      onChange={onDateChange}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      inline={inline}
      dateFormat="PPP"
      isClearable
    />
  );
}
