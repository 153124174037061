import { saveFile, updateFeatureInput } from "../../fileReducer";
import { getContentImageUrl } from "@mapmaker/core";
import SelectableSetting from "./SelectableSetting";
import SettingSection from "./SettingSection";
import { useGraphic } from "../../features/graphic/useGraphic";
import { useProjectDispatch } from "../../projectStore";
import "./BackgroundSettings.css";

export default function BackgroundSettings() {
  const dispatch = useProjectDispatch();

  const { graphic: background, graphicInput: backgroundInput } = useGraphic(
    "background"
  );

  async function selectBackground(selectedOption: string) {
    dispatch(
      updateFeatureInput("background", {
        ...backgroundInput,
        selectedOption: selectedOption,
      })
    );
    dispatch(saveFile());
  }

  // No need for options if there are no choices.
  if (!background || background.options.length === 1) {
    return null;
  }

  return (
    <SettingSection
      id="background-settings"
      label="Map Background"
      description="Select the background design of your map."
    >
      {background.options.map(option => {
        const selected = option.id === backgroundInput?.selectedOption;
        return (
          <SelectableSetting
            key={option.id}
            className="option"
            selected={selected}
            onSelect={() => selectBackground(option.id)}
          >
            <img
              title={option.name}
              src={getContentImageUrl(option.imageKey, 256, 256)}
            />
            <div className="name">{option.name}</div>
          </SelectableSetting>
        );
      })}
    </SettingSection>
  );
}
