type FormattedAddressProps = {
  address?: string[] | null;
};

export default function FormattedAddress({ address }: FormattedAddressProps) {
  if (!address) {
    return null;
  }
  return (
    <>
      {address.map((line, i) => (
        <div key={i}>{line}</div>
      ))}
    </>
  );
}
