import React from "react";
import { getOpeningList } from "@mapmaker/core";
import { OutputTypeDemonstrationProps } from "@mapmaker/renderable";
import { getLayerByPriority } from "../opening";
import SvgNode from "../SvgNode";
import Frame from "../product/tbl/Frame";
import { getFrame } from "../product/tbl/FrameLibrary";

export default function MatboardWallMapDemonstration({
  design,
  highlightedOpeningIds = Object.keys(design.features),
  width,
  height,
  primaryColor,
  highlightColor = primaryColor,
  lowlightColor = primaryColor,
  backgroundColor = "#FFFFFF",
}: Omit<OutputTypeDemonstrationProps<"resource">, "outputType">) {
  const viewBox = [
    -175 - 5,
    -175 - 5,
    design.width + 175 * 2 + 10,
    design.height + 175 * 2 + 10,
  ];

  const scale = Math.min(width / viewBox[2], height / viewBox[3]);
  const strokeWidth = 1 / scale;
  const openings = getOpeningList(design);

  return (
    <svg
      id="print-view-svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox.join(" ")}
      width={width}
      height={height}
    >
      {/* Frame */}
      <Frame
        width={design.width}
        height={design.height}
        frame={{
          color: primaryColor,
          thickness: 1.75,
        }}
      />
      {/* Map Background */}
      <rect
        x={0}
        y={0}
        width={design.width}
        height={design.height}
        fill={backgroundColor}
      />
      {/* Map */}
      {openings
        .filter(opening => opening.enabled !== false)
        .map(opening => {
          return (
            <SvgNode
              key={opening.id}
              nodeData={getLayerByPriority(opening, ["cut", "print", "inner"])}
              style={{
                fill: highlightedOpeningIds.includes(opening.id)
                  ? highlightColor
                  : lowlightColor,
                stroke: "#FFFFFF",
                strokeWidth: strokeWidth / 2,
              }}
            />
          );
        })}
    </svg>
  );
}
