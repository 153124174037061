import { StickerLineItemType } from "./StickerLineItem";

type GroupedLineItems<T extends { __typename?: string }> = {
  stickerGroups: { [key: string]: StickerLineItemType[] };
  items: T[];
};

export default function useGroupedLineItems<T extends { __typename?: string }>(
  lineItems?: T[]
): GroupedLineItems<T> {
  if (!lineItems) {
    return {
      stickerGroups: {},
      items: [],
    };
  }

  const stickerItems = (lineItems.filter(
    item => item.__typename === "StickerLineItem"
  ) as any) as StickerLineItemType[];
  const items = lineItems.filter(item => item.__typename !== "StickerLineItem");

  const stickerGroups = stickerItems.reduce<{
    [key: string]: StickerLineItemType[];
  }>((groups, item) => {
    if (!groups[item.stickerOrderId]) {
      groups[item.stickerOrderId] = [];
    }
    groups[item.stickerOrderId].push(item);
    return groups;
  }, {});
  return {
    stickerGroups,
    items,
  };
}
