import { viewBoxForBBox } from "../../../../lib/svg/ViewboxUtils";
import { SvgContextProvider } from "../../../../lib/svg/useSvgContext";
import useOpening from "./useOpening";
import useMapmakerModals from "../../../shared/modals/useMapmakerModals";
import ReadOnlyOpening from "./ReadOnlyOpening";
import MapmakerProjectToastMessages from "../../shared/toasts/MapmakerProjectToastMessages";
import MapmakerLayout from "../../../layout/MapmakerLayout";
import MapmakerTopBar from "../../../layout/MapmakerTopBar";
import ProjectTopBarButton from "../../ProjectTopBarButton";
import OpeningViewLayout from "./OpeningViewLayout";
import { useMapmakerAppConfig } from "../../../../client";
import { useProjectFile } from "../../useProjectState";
import FeatureExitButton from "../../shared/FeatureExitButton";

type ReadOnlyOpeningViewProps = {
  openingId: string;
};

export default function ReadOnlyOpeningView({
  openingId,
}: ReadOnlyOpeningViewProps) {
  const { gotoEditFile } = useMapmakerAppConfig();
  const file = useProjectFile();
  const { setModalRoot } = useMapmakerModals();
  const { openingFeature: opening } = useOpening(openingId);
  const bbox = opening.outlined
    ? opening.layout.cut.bbox
    : opening.layout.print?.bbox ||
      opening.layout.outer?.bbox ||
      opening.layout.cut.bbox;
  const viewbox = viewBoxForBBox(bbox, "3%");

  return (
    <MapmakerLayout>
      <MapmakerLayout.TopBar>
        <MapmakerTopBar
          breadcrumbs={[{ label: opening.name }]}
          after={<ProjectTopBarButton />}
        />
      </MapmakerLayout.TopBar>
      <MapmakerLayout.Main style={{ overflowY: "hidden" }}>
        <OpeningViewLayout>
          <OpeningViewLayout.Main innerRef={setModalRoot}>
            <MapmakerProjectToastMessages />
            <FeatureExitButton onClick={() => gotoEditFile(file.id)} />
            <SvgContextProvider viewbox={viewbox} flex>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewbox.join(" ")}
                width="100%"
                height="100%"
              >
                <ReadOnlyOpening openingId={openingId} />
              </svg>
            </SvgContextProvider>
          </OpeningViewLayout.Main>
        </OpeningViewLayout>
      </MapmakerLayout.Main>
    </MapmakerLayout>
  );
}
