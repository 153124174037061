import { BlogPostCardFragment } from "../../client/MapmakerApi";
import BlogPostCard from "./BlogPostCard";
import "./BlogPostCardList.css";

type BlogPostCardListProps = {
  posts: BlogPostCardFragment[];
};

export default function BlogPostCardList({ posts }: BlogPostCardListProps) {
  return (
    <div id="blog-post-card-list">
      {posts.map(post => (
        <BlogPostCard key={post._id} post={post} />
      ))}
    </div>
  );
}
