import { produce } from "immer";

export type DownloadOptionsState = {
  format: "jpeg" | "png";
};

const SET_FILE_FORMAT = "download_options.set_file_format";

export function setDownloadFileFormat(format: string) {
  return {
    type: SET_FILE_FORMAT,
    format,
  };
}

export default produce(
  (draft: DownloadOptionsState, action): DownloadOptionsState => {
    if (draft === undefined) {
      return {
        format: "jpeg",
      };
    }

    switch (action.type) {
      case SET_FILE_FORMAT:
        draft.format = action.format;
        break;
      default:
        break;
    }
  }
);
