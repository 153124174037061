import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Children, cloneElement, useMemo, } from "react";
import clsx from "clsx";
import Breadcrumb from "./Breadcrumb";
import styles from "./Breadcrumbs.module.scss";
export default function Breadcrumbs(_a) {
    var { className, children } = _a, divProps = __rest(_a, ["className", "children"]);
    const childrenWithInjectedProps = useMemo(() => {
        const numBreadcrumbs = Children.toArray(children).filter(x => !!x).length;
        return Children.map(children, (child, i) => {
            if (!child) {
                return null;
            }
            /* @ts-ignore */
            if (child.type !== Breadcrumb) {
                console.log(child);
                throw Error("Only Breadcrumb elements can be children of Breadcrumbs.");
            }
            const last = i + 1 === numBreadcrumbs;
            /* @ts-ignore */
            return cloneElement(child, {
                last,
            });
        });
    }, [children]);
    return (_jsx("div", Object.assign({ className: clsx(styles.container, className) }, divProps, { children: childrenWithInjectedProps })));
}
