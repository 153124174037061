import { HTMLProps, useEffect } from "react";
import { StickerTokenIcon } from "../shared/icons/StickerTokenIcon";
import StickerTokenAmount from "./StickerTokenAmount";
import "./StickerTokenBalance.css";
import { useMapmakerContext } from "../..";

export default function StickerTokenBalance(props: HTMLProps<HTMLDivElement>) {
  const { authenticatedAccount, refetchAccountData } = useMapmakerContext();
  useEffect(() => {
    refetchAccountData();
  }, []);

  return (
    <div {...props} id="sticker-token-balance">
      <div className="text">
        <div className="label">You have</div>
        <div className="balance">
          <StickerTokenAmount
            amount={authenticatedAccount?.stickerTokenBalance ?? 0}
          />
        </div>
      </div>
      <div className="icon">
        <StickerTokenIcon size="3.5em" />
      </div>
    </div>
  );
}
