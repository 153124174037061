import { OpeningSuggestionStatus } from "@mapmaker/core";
import { useMemo } from "react";
import { OpeningSuggestionFragment } from "../../../../../client/MapmakerApi";
import { useProjectFile } from "../../../useProjectState";

export default function useSuggestion(
  suggestionId: string
): OpeningSuggestionFragment {
  const file = useProjectFile();
  const suggestion = useMemo(
    () =>
      file.openingSuggestions.find(
        suggestion => suggestion.id === suggestionId
      ),
    [file.openingSuggestions]
  );
  return suggestion;
}

export function useSuggestionsForOpening(
  openingId: string
): OpeningSuggestionFragment[] {
  const file = useProjectFile();
  const suggestion = useMemo(
    () =>
      file.openingSuggestions
        .filter(suggestion => suggestion.openingId === openingId)
        .sort(compareSuggestionsSubmitted),
    [file.openingSuggestions]
  );
  return suggestion;
}

const StatusSort: Record<OpeningSuggestionStatus, number> = {
  DRAFT: 1,
  SUBMITTED: 1,
  HIDDEN: 3,
};

export function compareSuggestionsSubmitted(
  a: OpeningSuggestionFragment,
  b: OpeningSuggestionFragment
): number {
  const aStatus = StatusSort[a.status];
  const bStatus = StatusSort[b.status];
  if (aStatus != bStatus) {
    return aStatus - bStatus;
  }
  const aDate = a.submittedAt ?? a.input.modifiedAt;
  const bDate = b.submittedAt ?? b.input.modifiedAt;
  if (aDate < bDate) {
    return 1;
  } else if (aDate > bDate) {
    return -1;
  }
  return 0;
}

export function compareSuggestionsModified(
  a: OpeningSuggestionFragment,
  b: OpeningSuggestionFragment
): number {
  const aStatus = StatusSort[a.status];
  const bStatus = StatusSort[b.status];
  if (aStatus != bStatus) {
    return aStatus - bStatus;
  }
  const aDate = a.input.modifiedAt;
  const bDate = b.input.modifiedAt;
  if (aDate < bDate) {
    return 1;
  } else if (aDate > bDate) {
    return -1;
  }
  return 0;
}
