import { ReactNode } from "react";
import { Icon, SemanticICONS } from "semantic-ui-react";
import { Link, Navigate, useLocation } from "react-router-dom";
import ContactQuestionForm from "./ContactQuestionForm";
import { Breadcrumbs, Breadcrumb } from "@mapmaker/ui";
import "./MapmakerContactCenter.css";

export interface IContactNode {
  id: string;
  label: string;
  icon?: SemanticICONS;
  description?: string;
}

export type ContactNode = ContactCategory | ContactQuestion;

export type ContactCategory = IContactNode & {
  nodeType: "category";
  children: ContactNode[];
};

export type ContactQuestion = IContactNode & {
  nodeType: "question";
  deflection?: string | ReactNode;
  fields?: ContactField[];
  prompt?: string | false;
  subject?: string;
  tags?: string[];
};

export type ContactField = TextField | FileField | SelectField;

type IContactField = {
  type: "text" | "file" | "select";
  label: string;
  secondary?: string;
};

export type TextField = IContactField & {
  placeholder?: string;
};
export type FileField = IContactField;
export type SelectField = IContactField & {
  options: {
    id: string;
    value: string;
  };
};

function getContactNodeFromPath(
  root: ContactCategory,
  path: string[]
): IContactNode | null {
  try {
    let currCat: ContactCategory = root;
    let currNode: IContactNode | null = root;
    for (let i = 0; i < path.length; i++) {
      const child: IContactNode | null =
        currCat.children.find(c => c.id === path[i]) || null;
      currCat = child as ContactCategory;
      currNode = child;
    }
    return currNode;
  } catch (e) {
    return null;
  }
}

type ContactTree = {
  categories: ContactCategory[];
  selected: IContactNode;
  selectedCategory: ContactCategory | null;
  selectedQuestion: ContactQuestion | null;
};

function getContactTree(root: ContactCategory, path: string = ""): ContactTree {
  const pathParts: string[] = path.split("/") || [];
  const nodes = pathParts
    .map((part, i) => {
      const pathToNode = pathParts.slice(0, i + 1);
      return getContactNodeFromPath(root, pathToNode);
    })
    .filter(x => x !== null);

  if (nodes.length === 0 || nodes.length !== pathParts.length) {
    // There is no slug, or some of the slug parts didn't match a node properly.
    return {
      categories: [],
      selectedCategory: root,
      selected: root,
      selectedQuestion: null,
    };
  } else {
    const selected = nodes[nodes.length - 1];
    const selectedIsCategory = !!(selected as any).children;
    return {
      categories: [
        root,
        ...(nodes.slice(0, nodes.length - 1) as ContactCategory[]),
      ],
      selected: selected as IContactNode,
      selectedCategory: selectedIsCategory
        ? (selected as ContactCategory)
        : null,
      selectedQuestion: !selectedIsCategory
        ? (selected as ContactQuestion)
        : null,
    };
  }
}

type MapmakerContactCenterProps = {
  root: ContactCategory;
  slug?: string;
};

export default function MapmakerContactCenter({
  root,
  slug,
}: MapmakerContactCenterProps) {
  const { pathname } = useLocation();
  const {
    categories,
    selected,
    selectedCategory,
    selectedQuestion,
  } = getContactTree(root, slug);
  if (slug && categories.length === 0) {
    return <Navigate to={"./"} replace />;
  }

  return (
    <div id="mapmaker-contact-center">
      <Breadcrumbs className="breadcrumbs">
        {[...categories].map((category, i) => {
          const url =
            "/" +
            pathname
              .split("/")
              .slice(1, i + 2)
              .join("/");
          return (
            <Breadcrumb key={i} to={url}>
              {category.label}
            </Breadcrumb>
          );
        })}
        <Breadcrumb>{selected.label}</Breadcrumb>
      </Breadcrumbs>
      {selectedCategory ? (
        <div className="option-grid">
          {selectedCategory.children.map(child => (
            <Link
              key={child.id}
              to={[slug, child.id].filter(x => !!x).join("/")}
              className="option"
            >
              <Icon
                className="option-icon"
                name={child.icon || "help circle"}
                size="big"
                verticalAlign="middle"
              />
              <div className="option-info">
                <h3>{child.label}</h3>
                <p>{child.description}</p>
                {child.nodeType === "category" && (
                  <ul>
                    {child.children.map(grandchild => (
                      <li>
                        <Link
                          to={[slug, child.id, grandchild.id]
                            .filter(x => !!x)
                            .join("/")}
                        >
                          {grandchild.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </Link>
          ))}
        </div>
      ) : null}
      {selectedQuestion ? (
        <ContactQuestionForm question={selectedQuestion} />
      ) : null}
    </div>
  );
}
