import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { useCallback } from "react";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import styles from "./Breadcrumb.module.scss";
export default function Breadcrumb({ to, last, onClick: onClickProp, children, }) {
    const navigate = useNavigate();
    const onClick = useCallback(e => {
        if (onClickProp) {
            onClickProp(e);
        }
        if (!last && to) {
            navigate(to);
        }
    }, [last, to, navigate]);
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: clsx({ [styles.breadcrumb]: true, [styles.last]: last }), onClick: onClick }, { children: children })), !last && _jsx(MdChevronRight, { className: styles.divider })] }));
}
