import React from "react";
import RectangularPrintCard, {
  IRectangularPrintCardProps,
} from "../shared/RectangularPrintCard";
import ImageDownloadButton from "./ImageDownloadButton";

export default function DownloadPrintCard({
  printLayout,
}: IRectangularPrintCardProps) {
  return (
    <RectangularPrintCard
      printLayout={printLayout}
      ActionButton={ImageDownloadButton}
    />
  );
}
