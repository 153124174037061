import { addKeysToValues } from "../objectUtils";
// Needed for Safari < 12.
import fromEntries from "object.fromentries";
// @ts-ignore
fromEntries.shim();

// Modes for choosing a proper print size.
export enum PrintSizeModeId {
  A0 = "A0",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  A5 = "A5",
  A6 = "A6",
  PHOTO_4x6 = "photo_4x6",
  PHOTO_5x7 = "photo_5x7",
  PHOTO_8x10 = "photo_8x10",
  PHOTO_11x14 = "photo_11x14",
  PHOTO_11x17 = "photo_11x17",
  PHOTO_16x20 = "photo_16x20",
  PHOTO_24x36 = "photo_24x36",
  LETTER = "letter",
  LEGAL = "legal",
  STANDARD_PHOTO = "standard_photo",
  WALGREENS_CHEAP = "walgreens_cheap",
  FULL_SIZE = "full_size",
}

export enum PaperSizeId {
  A0 = "A0",
  A1 = "A1",
  A2 = "A2",
  A3 = "A3",
  A4 = "A4",
  A5 = "A5",
  A6 = "A6",
  PHOTO_4x6 = "photo_4x6",
  PHOTO_5x7 = "photo_5x7",
  PHOTO_8x10 = "photo_8x10",
  PHOTO_11x14 = "photo_11x14",
  PHOTO_11x17 = "photo_11x17",
  PHOTO_16x20 = "photo_16x20",
  PHOTO_24x36 = "photo_24x36",
  LETTER = "letter",
  LEGAL = "legal",
  FULL_SIZE = "full_size",
}
export type PaperOrientation = "portrait" | "landscape";
export type PaperSize = {
  id: PaperSizeId;
  width: number;
  height: number;
  name: string;
  shortName: string;
  filename: string;
  orientation?: PaperOrientation;
};
type PaperSizeLibrary = {
  [key in PaperSizeId]: PaperSize;
};

const mmToPt = (millimeters) => Math.round((millimeters / 25.4) * 72);
const inToPt = (inches) => Math.round(inches * 72);

export const PaperSizes: PaperSizeLibrary = Object.fromEntries(
  Object.entries({
    [PaperSizeId.PHOTO_4x6]: [inToPt(4), inToPt(6), "4×6"],
    [PaperSizeId.PHOTO_5x7]: [inToPt(5), inToPt(7), "5×7"],
    [PaperSizeId.PHOTO_8x10]: [inToPt(8), inToPt(10), "8×10"],
    [PaperSizeId.PHOTO_11x14]: [inToPt(11), inToPt(14), "11×14"],
    [PaperSizeId.PHOTO_11x17]: [inToPt(11), inToPt(17), "11×17"],
    [PaperSizeId.PHOTO_16x20]: [inToPt(16), inToPt(20), "16×20"],
    [PaperSizeId.PHOTO_24x36]: [inToPt(24), inToPt(36), "24×36"],
    [PaperSizeId.LETTER]: [
      inToPt(8.5),
      inToPt(11),
      `Letter - 8.5 × 11 in`,
      `Letter`,
      "portrait",
    ],
    [PaperSizeId.LEGAL]: [
      inToPt(8.5),
      inToPt(14),
      `Legal - 8.5 × 14 in`,
      `Legal`,
      "portrait",
    ],
    [PaperSizeId.A0]: [mmToPt(841), mmToPt(1189), "A0", null, "portrait"],
    [PaperSizeId.A1]: [mmToPt(594), mmToPt(841), "A1", null, "portrait"],
    [PaperSizeId.A2]: [mmToPt(420), mmToPt(594), "A2", null, "portrait"],
    [PaperSizeId.A3]: [mmToPt(297), mmToPt(420), "A3", null, "portrait"],
    [PaperSizeId.A4]: [mmToPt(210), mmToPt(297), "A4", null, "portrait"],
    [PaperSizeId.A5]: [mmToPt(148), mmToPt(210), "A5", null, "portrait"],
    [PaperSizeId.A6]: [mmToPt(105), mmToPt(148), "A6", null, "portrait"],
  }).map(([id, [width, height, name, shortName, orientation = null]]) => [
    id,
    {
      id,
      width,
      height,
      name,
      shortName: shortName || name,
      filename: ((shortName as string) || (name as string)).replace("×", "x"),
      orientation,
    },
  ])
) as PaperSizeLibrary;

export const idToSize = (id: PaperSizeId): PaperSize => PaperSizes[id];
const sizeToGroup = (id: PaperSizeId) => {
  const size = idToSize(id);
  return {
    name: size.name,
    longName: size.name,
    sizes: [size],
  };
};

export type PrintSizeModeLibrary = {
  [key in PrintSizeModeId]?: PrintSizeMode;
};

export type PrintSizeMode = {
  id: PrintSizeModeId;
  name: string;
  longName?: string;
  sizes: PaperSize[];
};

export type PrintSize = {
  width: number;
  height: number;
};
export type PrintSizeIn = [number, number, string?];
export type PrintSizePt = [number, number];

// List of print sizes
// [shortSide, longSide, orientation (default 'both')]
export const PrintSizeModes: PrintSizeModeLibrary = addKeysToValues({
  [PrintSizeModeId.STANDARD_PHOTO]: {
    name: "Standard Photo Sizes",
    longName: "Standard Photo Sizes (4×6, 5×7, etc...)",
    sizes: [
      PaperSizeId.PHOTO_4x6,
      PaperSizeId.PHOTO_5x7,
      PaperSizeId.PHOTO_8x10,
      PaperSizeId.PHOTO_11x14,
      PaperSizeId.PHOTO_16x20,
      PaperSizeId.PHOTO_24x36,
    ].map(idToSize),
  },
  [PrintSizeModeId.WALGREENS_CHEAP]: {
    name: "Standard Without 5×7",
    longName: "Standard Photo Sizes without 5×7",
    sizes: [
      PaperSizeId.PHOTO_4x6,
      PaperSizeId.PHOTO_8x10,
      PaperSizeId.PHOTO_11x14,
      PaperSizeId.PHOTO_16x20,
      PaperSizeId.PHOTO_24x36,
    ].map(idToSize),
  },
  [PrintSizeModeId.LETTER]: sizeToGroup(PaperSizeId.LETTER),
  [PrintSizeModeId.LEGAL]: sizeToGroup(PaperSizeId.LEGAL),
  [PrintSizeModeId.A0]: sizeToGroup(PaperSizeId.A0),
  [PrintSizeModeId.A1]: sizeToGroup(PaperSizeId.A1),
  [PrintSizeModeId.A2]: sizeToGroup(PaperSizeId.A2),
  [PrintSizeModeId.A3]: sizeToGroup(PaperSizeId.A3),
  [PrintSizeModeId.A4]: sizeToGroup(PaperSizeId.A4),
  [PrintSizeModeId.A5]: sizeToGroup(PaperSizeId.A5),
  [PrintSizeModeId.A6]: sizeToGroup(PaperSizeId.A6),
  [PrintSizeModeId.PHOTO_4x6]: sizeToGroup(PaperSizeId.PHOTO_4x6),
  [PrintSizeModeId.PHOTO_5x7]: sizeToGroup(PaperSizeId.PHOTO_5x7),
  [PrintSizeModeId.PHOTO_8x10]: sizeToGroup(PaperSizeId.PHOTO_8x10),
  [PrintSizeModeId.PHOTO_11x14]: sizeToGroup(PaperSizeId.PHOTO_11x14),
  [PrintSizeModeId.PHOTO_11x17]: sizeToGroup(PaperSizeId.PHOTO_11x17),
  [PrintSizeModeId.PHOTO_16x20]: sizeToGroup(PaperSizeId.PHOTO_16x20),
  [PrintSizeModeId.PHOTO_24x36]: sizeToGroup(PaperSizeId.PHOTO_24x36),
  /* A special group for printing at the size of the design. */
  [PrintSizeModeId.FULL_SIZE]: {
    id: "full_size",
    name: "Full Size",
    sizes: [],
  },
});

export const idToMode = (id: PrintSizeModeId): PrintSizeMode =>
  PrintSizeModes[id];
export const idsToModes = (ids: PrintSizeModeId[]): PrintSizeMode[] =>
  ids.map((id) => PrintSizeModes[id]);

// Creates an array of all possible print sizes as [widthPt, heightPt] elements. If both orientations
// are acceptable then 2 sizes are added to the list.
function normalizeSizes(sizes: PaperSize[]): PrintSizePt[] {
  return sizes.reduce((normalizedSizes, size) => {
    const longSide = Math.max(size.width, size.height);
    const shortSide = Math.min(size.width, size.height);
    if (size.orientation != "portrait") {
      // Add landscape
      normalizedSizes.push([longSide, shortSide]);
    }
    if (size.orientation != "landscape") {
      // Add portrait
      normalizedSizes.push([shortSide, longSide]);
    }
    return normalizedSizes;
  }, []);
}

// Returns the best fit for the given print size.
export function getBestPrintSize(
  widthPt: number,
  heightPt: number,
  printSizeModeId: PrintSizeModeId = PrintSizeModeId.STANDARD_PHOTO,
  minPadding: number = 18
): PrintSize {
  const sizes = idToMode(printSizeModeId).sizes;
  const normalizedSizes = normalizeSizes(sizes);
  const size = normalizedSizes.find((size) => {
    return (
      widthPt + minPadding * 2 <= size[0] &&
      heightPt + minPadding * 2 <= size[1]
    );
  });

  if (size) {
    return {
      width: size[0],
      height: size[1],
    };
  } else {
    return null;
  }
}

function closeEnough(
  a: number,
  b: number,
  tolerance: number = (72 * 1) / 8
): boolean {
  return Math.abs(a - b) <= tolerance;
}

export function getPaperSize(width: number, height: number): PaperSize {
  const exact = Object.values(PaperSizes).find((paperSize) => {
    return (
      (paperSize.width === width && paperSize.height === height) ||
      (paperSize.width === height && paperSize.height === width)
    );
  });
  if (exact) {
    return exact;
  }
  const close = Object.values(PaperSizes).find((paperSize) => {
    return (
      (closeEnough(paperSize.width, width) &&
        closeEnough(paperSize.height, height)) ||
      (closeEnough(paperSize.width, height) &&
        closeEnough(paperSize.height, width))
    );
  });

  return close;
}

export function prettyPrintSize(
  printSize: PrintSize,
  scale: number = 1
): string {
  return prettyPrintDimensions(
    printSize.width * scale,
    printSize.height * scale
  );
}

const prettyDimension = (dimension) => parseFloat(dimension.toFixed(1));

export function prettyPrintDimensions(
  width,
  height,
  minFirst: boolean = false
): string {
  const prettyWidth = prettyDimension(width / 72);
  const prettyHeight = prettyDimension(height / 72);
  if (minFirst && width > height) {
    return `${prettyHeight} × ${prettyWidth} in.`;
  } else {
    return `${prettyWidth} × ${prettyHeight} in.`;
  }
}
