import { MapmakerDesignLiteFragment, ProjectOutputType } from "./MapmakerApi";
import { InitialFileProperties, MapmakerBusinessId } from "@mapmaker/core";
import { MapmakerPrintServiceId } from "../components/printing/PrintServiceIds";
import { useMapmakerContext } from "./MapmakerContext";
import { ComponentType } from "react";

export type MapmakerAppConfig = {
  businessId: MapmakerBusinessId;
  businessName: string;
  /* A string with {TITLE} to indicate where the page-specific title should go. */
  pageTitleFormat?: string;
  /* Printing */
  printServiceIds: MapmakerPrintServiceId[];
  primaryPrintServiceId: MapmakerPrintServiceId;
  stickersConfig: MapmakerOrderStickersConfig;
  downloadConfig: MapmakerDownloadConfig;
  defaultProjectOutputType: ProjectOutputType;
  /* Sanity */
  sanity: MapmakerSanityConfig;
  // Warning message shown before selecting a size from a multi-size group of designs.
  MultiSizeGroupWarningComponent?: ComponentType<{
    design: MapmakerDesignLiteFragment;
    onAccept(): any;
    onDecline(): any;
  }>;
  // Links
  createProjectSharingLink: (fileId: string) => string;
  // Navigation
  gotoSignUp: (redirectTo?: string) => any;
  gotoSignIn: (redirectTo?: string) => any;
  gotoFindMap: () => any;
  gotoNewFile: (
    designId: string,
    initialFileProperties?: InitialFileProperties
  ) => any;
  gotoEditFile: (fileId: string, ...path: string[]) => any;
  gotoSelectPrintService: (fileId: string) => any;
  gotoCreatePrints: (serviceId: MapmakerPrintServiceId, fileId: string) => any;
  onMapmakerFileCreated: (fileId: string) => any;
  gotoPrintSizingHelp?: () => any;
  gotoContact: (category?: "bug") => any;
  gotoChangeEmail: () => any;
  gotoCart: () => any;
  gotoProduct: (handle: string) => any;
  gotoStickerTokenRedeem: () => any;
  gotoStickerTokenHelp: () => any;
  gotoStickerTokenBalance: () => any;
  gotoBlogPosts?: () => any;
  gotoBlogPost?: (slug: string) => any;
};

export type MapmakerSanityConfig = {
  dataset: string;
  projectId: string;
};

export type MapmakerOrderStickersConfig = {
  shippingCost: string;
  freeShippingThreshold: number;
};

export type MapmakerDownloadConfig = {
  bufferArea: boolean;
};

export function useMapmakerAppConfig(): MapmakerAppConfig {
  return useMapmakerContext().appConfig;
}
