import { FunctionComponent, ReactNode, useMemo } from "react";
import { Mat } from "@mapmaker/svg";
import { viewBoxFor } from "../../../lib/svg/ViewboxUtils";
import { useProjectDesign } from "../useProjectState";
import { SvgContextProvider } from "../../../lib/svg/useSvgContext";
import { Feature, getFeaturesInOrder } from "@mapmaker/core";
import { useAddToastMessage } from "../shared/toasts/useProjectToastMessages";
import GraphicSvgNode from "./GraphicSvgNode";
import { OpeningSvgNode } from "./OpeningSvgNode";
import clsx from "clsx";
import { useFullMapViewContext } from "./FullMapViewContext";

export type FullMapSVGFeatureRendererProps = {
  feature: Feature;
};

export interface IFullMapSVGProps {
  width?: string | number;
  height?: string | number;
}

export default function FullMapSVG({
  width = "100%",
  height = "100%",
}: IFullMapSVGProps) {
  const design = useProjectDesign();
  const viewbox = viewBoxFor(0, 0, design.width, design.height, "1%");
  const { mode } = useFullMapViewContext();
  const { addToastWarning } = useAddToastMessage();

  // If we don't pass in features we'll show everything.
  const featuresToShow = useMemo(() => getFeaturesInOrder(design), [design]);

  return (
    <SvgContextProvider
      viewbox={viewbox}
      flex
      onImageLoadError={() =>
        addToastWarning(
          {
            title: "Some Images Failed to Load",
            content:
              "Please reload the page, and contact us if the problem persists.",
          },
          "full-map.images-failed-to-load"
        )
      }
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewbox.join(" ")}
        width={width}
        height={height}
        id={"full-map-svg"}
        className={`mode-${mode}`}
      >
        <rect
          id="content-area"
          style={{
            fill: "none",
            strokeWidth: 0,
          }}
          x={viewbox[0]}
          y={viewbox[1]}
          width={viewbox[2]}
          height={viewbox[3]}
        />

        <rect
          style={{
            fill: "#FFF",
          }}
          x={0}
          y={0}
          width={design.width}
          height={design.height}
        />

        {/*
          Only draw the outer if the map does not have a background graphic.
        */}
        {!design.features["background"] && (
          <Mat design={design} layer="outer" fillColor="#CCCCCC" />
        )}

        {/* Draw all the features. */}
        {featuresToShow.map(feature => {
          switch (feature.type) {
            case "GRAPHIC":
              return <GraphicSvgNode key={feature.id} graphicId={feature.id} />;
            case "OPENING":
              return <OpeningSvgNode key={feature.id} openingId={feature.id} />;
          }
        })}
      </svg>
    </SvgContextProvider>
  );
}
