import { Credentials } from "../AWSAuthContext";
import sigV4Client from "./sigv4Client";

export default function createSigV4Fetch(
  credentials: Credentials,
  identityIdHeader: string | null = null
) {
  return async function sigV4Fetch(
    uri: string,
    options: RequestInit
  ): Promise<Response> {
    const newHeaders: Record<string, any> = {
      ...options.headers,
      /* @ts-ignore */
      "Content-Type": options.headers["content-type"],
      Accept: "application/json",
    };
    delete newHeaders["content-type"];
    delete newHeaders["accept"];

    // This value is only used when developing locally.
    if (identityIdHeader) {
      newHeaders[identityIdHeader] = credentials.identityId;
      newHeaders[`cognito-identity-id.authenticated`] =
        /* @ts-ignore */
        Object.values(credentials.params["Logins"]).length > 0;
    }

    const signedRequest = sigV4Client
      .newClient({
        accessKey: credentials.accessKeyId,
        secretKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: "us-east-1",
        endpoint: uri,
      })
      .signRequest({
        method: options.method,
        path: "",
        headers: newHeaders,
        queryParams: null,
        body: JSON.parse(options.body?.toString() ?? "{}"),
        currentDate: new Date(),
      });

    return await fetch(signedRequest.url, {
      headers: signedRequest.headers,
      method: options.method,
      body: options.body,
    });
  };
}
