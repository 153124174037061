import { ApolloError, gql } from "@apollo/client";
import { Button } from "@mapmaker/ui";
import { Formik, useFormikContext } from "formik";
import { useState } from "react";
import { FaEnvelopeOpenText } from "@react-icons/all-files/fa/FaEnvelopeOpenText";
import { FaExclamationTriangle } from "@react-icons/all-files/fa/FaExclamationTriangle";
import { FaRegCheckCircle } from "@react-icons/all-files/fa/FaRegCheckCircle";
import { HiPlus } from "@react-icons/all-files/hi/HiPlus";
import useLocalStorage from "react-use/lib/useLocalStorage";
import {
  FormField,
  ModalActions,
  ModalContent,
  TextArea,
  Form as SemanticForm,
} from "semantic-ui-react";
import {
  AddMapmakerFileAccessMutation,
  FileAccessRole,
  useAddMapmakerFileAccessMutation,
} from "../../../client/MapmakerApi";
import { trackGtmEvent } from "../../../lib/gtm";
import MapmakerModal from "../../shared/modals/MapmakerModal";
import { addFileAccess } from "../fileReducer";
import { useProjectDispatch } from "../projectStore";
import { SharingLink } from "../shared/SharingLink";
import { useProjectDesign, useProjectFile } from "../useProjectState";
import "./AddFileAccessModal.css";

gql`
  mutation addMapmakerFileAccess($input: AddMapmakerFileAccessInput!) {
    addMapmakerFileAccess(input: $input) {
      fileAccess {
        ...MapmakerFileAccess
      }
    }
  }
`;

type AddFileAccessModalProps = {
  onClose?(): any;
  open?: boolean;
};

export default function AddFileAccessModal({
  onClose,
  ...props
}: AddFileAccessModalProps) {
  const file = useProjectFile();
  const dispatch = useProjectDispatch();
  const [
    addMapmakerFileAccess,
    { data, error, reset },
  ] = useAddMapmakerFileAccessMutation({
    onCompleted: result => {
      dispatch(addFileAccess(result.addMapmakerFileAccess.fileAccess));
      trackGtmEvent({
        event: "mapmaker.send-file-access-invite",
      });
    },
  });
  const [defaultNote, setDefaultNote] = useLocalStorage(
    "addFileAccess.localNote"
  );

  const onSubmit = async (values: FormValues) => {
    try {
      setDefaultNote(values.note);
      await addMapmakerFileAccess({
        variables: {
          input: {
            fileId: file.id,
            granteeEmail: values.email,
            role: FileAccessRole.Collaborator,
            note: values.note,
          },
        },
      });
    } catch (e) {}
  };

  function resetAndClose() {
    reset();
    onClose();
  }

  return (
    <Formik
      initialValues={{
        email: "",
        note: defaultNote,
      }}
      onSubmit={onSubmit}
    >
      <AddFileAccessModalWithFormikContext
        {...props}
        result={data}
        error={error}
        reset={reset}
        onClose={resetAndClose}
      />
    </Formik>
  );
}

type FormValues = {
  email: string;
  note: string;
};

type AddFileAccessModalWithFormikContextProps = AddFileAccessModalProps & {
  result: AddMapmakerFileAccessMutation;
  error: ApolloError;
  reset(): any;
};

function AddFileAccessModalWithFormikContext({
  onClose,
  open = true,
  error,
  result,
  reset,
}: AddFileAccessModalWithFormikContextProps) {
  const design = useProjectDesign();
  const [noteOpen, setNoteOpen] = useState(false);

  const { handleChange, values, submitForm, isSubmitting } = useFormikContext<
    FormValues
  >();

  return (
    <MapmakerModal
      className="add-file-access-modal"
      open={open}
      onClose={onClose}
      title="Add a Collaborator"
      size="tiny"
    >
      <ModalContent>
        {result ? (
          <div className="success">
            <FaRegCheckCircle size="100px" color="var(--mm-accent-color)" />
            <h3>
              Invitation sent to <b>{values.email}</b>
            </h3>
            <p>
              You will be notified when they add photos to your project. You can
              also share this link with them directly.
            </p>
            <SharingLink />
          </div>
        ) : (
          <div className="unfinished-form">
            <FaEnvelopeOpenText size="100px" color="var(--mm-dark-grey)" />

            <p>
              A collaborator can upload their photos into any of the{" "}
              {design.regionTypePlural} in this project. You can then choose if
              you want to add it to the project or not.
            </p>
            <SemanticForm
              id="add-file-access-form"
              onSubmit={submitForm}
              loading={isSubmitting}
            >
              <FormField>
                <label>Their Email</label>
                <input
                  name="email"
                  type="email"
                  placeholder="you@example.com"
                  onChange={handleChange}
                  value={values.email}
                  tabIndex={1}
                />
              </FormField>
              {!noteOpen && !values.note ? (
                <span className="add-note" onClick={() => setNoteOpen(true)}>
                  <HiPlus
                    style={{ marginBottom: "-2px", marginRight: "2px" }}
                  />
                  Include a note
                </span>
              ) : (
                <>
                  <label className="textarea-label" htmlFor="note">
                    Note <span className="subtle">optional</span>
                  </label>
                  <TextArea
                    name="note"
                    placeholder="This message will be included with the invitation."
                    onChange={handleChange}
                    rows={5}
                    tabIndex={2}
                    defaultValue={values.note}
                    onBlur={e => setNoteOpen(e.target.value != "")}
                  />
                </>
              )}
            </SemanticForm>

            {error && (
              <div className="error">
                <FaExclamationTriangle size="24px" />
                <p>{error.message}</p>
              </div>
            )}
          </div>
        )}
      </ModalContent>
      <ModalActions>
        {result ? (
          <>
            <Button onClick={reset}>Add Another</Button>{" "}
            <Button color="accent" onClick={onClose}>
              Done
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose} tabIndex={4} disabled={isSubmitting}>
              Cancel
            </Button>{" "}
            <Button
              /* ID for GTM tracking. Do not change. */
              id="send-invite-button"
              color="accent"
              type="submit"
              form="add-file-access-form"
              tabIndex={3}
              disabled={isSubmitting}
            >
              Send Invite
            </Button>
          </>
        )}
      </ModalActions>
    </MapmakerModal>
  );
}
