import { ChangePasswordBox, useAuthContext } from "@tbl/aws-auth";
import { PropsWithChildren } from "react";
import { useMapmakerAppConfig } from "../../client";
import "./AccountGeneralSettings.css";

export default function AccountGeneralSettings() {
  const { identityId, user } = useAuthContext();
  const { gotoChangeEmail } = useMapmakerAppConfig();

  return (
    <div id="account-general-settings">
      <LabelValue label="Email">
        <span>{user.email}</span>
        <span className="link change" onClick={gotoChangeEmail}>
          change
        </span>
      </LabelValue>
      <LabelValue label="Password">
        <ChangePasswordBox />
      </LabelValue>
      <LabelValue label="User ID">
        <span>{identityId.split(":")[1]}</span>
      </LabelValue>
    </div>
  );
}

type LabelValueProps = {
  label: string;
};

function LabelValue({ label, children }: PropsWithChildren<LabelValueProps>) {
  return (
    <div className="label-value">
      <h4 className="label">{label}</h4>
      <div className="value">{children}</div>
    </div>
  );
}
